import styled from "styled-components";

export const Container = styled.div`
  min-height: ${({ minH }) => minH || "calc(100vh - 122px)"};
  padding: ${({ padding }) => padding || "50px 50px 50px 330px"};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "#eeeeee")};
  border-radius: ${({ brRadius }) => (brRadius ? brRadius : "0")};
 

  @media (max-width: 991.98px) {
    padding: ${({ paddings }) => paddings || "15px"};
  }
`;
