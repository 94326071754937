import React from "react";

const uploadManually = ({
  wSize = "20",
  hSize = "19",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width="138"
      height="94"
      viewBox="0 0 138 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M138 90.3623H0V90.4313H138V90.3623Z" fill="#EBEBEB" />
      <path
        d="M65.4129 78.0528H12.1201C11.7026 78.0521 11.3024 77.8857 11.0075 77.5902C10.7125 77.2948 10.5469 76.8943 10.5469 76.4768V1.56216C10.5505 1.14706 10.7178 0.750162 11.0123 0.457668C11.3069 0.165174 11.705 0.000711083 12.1201 0H65.4129C65.8309 0 66.2317 0.166038 66.5273 0.461588C66.8228 0.757138 66.9889 1.15799 66.9889 1.57596V76.4768C66.9889 76.8948 66.8228 77.2957 66.5273 77.5912C66.2317 77.8868 65.8309 78.0528 65.4129 78.0528ZM12.1201 0.0552002C11.7209 0.0559313 11.3383 0.215022 11.0563 0.497553C10.7743 0.780083 10.6159 1.16297 10.6159 1.56216V76.4768C10.6159 76.876 10.7743 77.2589 11.0563 77.5414C11.3383 77.824 11.7209 77.9831 12.1201 77.9838H65.4129C65.8124 77.9831 66.1952 77.8241 66.4777 77.5416C66.7601 77.2592 66.9191 76.8763 66.9199 76.4768V1.56216C66.9191 1.16271 66.7601 0.779836 66.4777 0.497384C66.1952 0.214932 65.8124 0.0559298 65.4129 0.0552002H12.1201Z"
        fill="#EBEBEB"
      />
      <path
        d="M125.114 78.0528H71.8181C71.4004 78.0521 71 77.8858 70.7046 77.5904C70.4092 77.295 70.2429 76.8946 70.2422 76.4768V1.56216C70.2465 1.14681 70.4144 0.749913 70.7094 0.457496C71.0044 0.165078 71.4028 0.000703328 71.8181 0H125.114C125.528 0.00143644 125.926 0.166221 126.22 0.458637C126.514 0.751053 126.681 1.14754 126.684 1.56216V76.4768C126.684 76.8939 126.519 77.2939 126.225 77.5893C125.93 77.8847 125.531 78.0513 125.114 78.0528ZM71.8181 0.0552002C71.4187 0.0559298 71.0358 0.214932 70.7534 0.497384C70.4709 0.779836 70.3119 1.16271 70.3112 1.56216V76.4768C70.3119 76.8763 70.4709 77.2592 70.7534 77.5416C71.0358 77.8241 71.4187 77.9831 71.8181 77.9838H125.114C125.513 77.9831 125.896 77.8241 126.179 77.5416C126.461 77.2592 126.62 76.8763 126.621 76.4768V1.56216C126.62 1.16271 126.461 0.779836 126.179 0.497384C125.896 0.214932 125.513 0.0559298 125.114 0.0552002H71.8181Z"
        fill="#EBEBEB"
      />
      <path
        d="M55.3166 92.9893H48.1406V93.0583H55.3166V92.9893Z"
        fill="#EBEBEB"
      />
      <path
        d="M29.4082 92.2412H14.4766V92.3102H29.4082V92.2412Z"
        fill="#EBEBEB"
      />
      <path
        d="M47.8659 95.5537H40.6016V95.6227H47.8659V95.5537Z"
        fill="#EBEBEB"
      />
      <path
        d="M81.9905 95.0898H71.2734V95.1588H81.9905V95.0898Z"
        fill="#EBEBEB"
      />
      <path
        d="M87.3958 95.0898H84.4453V95.1588H87.3958V95.0898Z"
        fill="#EBEBEB"
      />
      <path
        d="M124.176 93.9258H109.117V93.9948H124.176V93.9258Z"
        fill="#EBEBEB"
      />
      <path
        d="M62.1873 10.0049H21.2344V53.221H62.1873V10.0049Z"
        fill="#E6E6E6"
      />
      <path
        d="M66.574 8.60547H18.5804C18.472 8.60547 18.3681 8.6485 18.2915 8.72511C18.2149 8.80171 18.1719 8.90561 18.1719 9.01395C18.1719 9.06746 18.1825 9.12045 18.203 9.16985C18.2236 9.21926 18.2537 9.2641 18.2917 9.30182C18.3297 9.33953 18.3747 9.36936 18.4242 9.38958C18.4738 9.40981 18.5268 9.42003 18.5804 9.41967H66.574C66.6816 9.41967 66.7848 9.37692 66.8609 9.30084C66.937 9.22475 66.9797 9.12155 66.9797 9.01395C66.9801 8.96044 66.9699 8.90738 66.9496 8.85784C66.9294 8.8083 66.8996 8.76324 66.8619 8.72528C66.8242 8.68731 66.7793 8.65718 66.7299 8.63661C66.6805 8.61605 66.6275 8.60547 66.574 8.60547Z"
        fill="#E6E6E6"
      />
      <path
        d="M63.9193 10.0049H22.0391V53.221H63.9193V10.0049Z"
        fill="#F0F0F0"
      />
      <path
        d="M62.1873 53.2207H21.2344V54.8353H62.1873V53.2207Z"
        fill="#E6E6E6"
      />
      <path
        d="M66.8177 53.2207H24.9375V54.8353H66.8177V53.2207Z"
        fill="#F0F0F0"
      />
      <path
        d="M61.4844 50.7897V12.4395L24.47 12.4395V50.7897H61.4844Z"
        fill="#FAFAFA"
      />
      <path
        d="M32.875 50.7895L47.1552 12.4365H53.8952L39.6177 50.7895H32.875Z"
        fill="white"
      />
      <path
        d="M41.0703 50.7895L55.3506 12.4365H57.9781L43.6978 50.7895H41.0703Z"
        fill="white"
      />
      <path
        d="M61.4844 50.7867V12.4365H61.1753V50.7867H61.4844Z"
        fill="#E6E6E6"
      />
      <g opacity="0.6">
        <path
          d="M23.47 46.9136H18.3281L21.0992 7.87891H26.241L23.47 46.9136Z"
          fill="#F0F0F0"
        />
        <path
          d="M25.4685 46.9136H21.4609L24.232 7.87891H28.2395L25.4685 46.9136Z"
          fill="#E6E6E6"
        />
        <path
          d="M28.6106 46.9136H23.4688L26.2398 7.87891H31.3817L28.6106 46.9136Z"
          fill="#F0F0F0"
        />
        <path
          d="M30.6091 46.9136H26.6016L29.3754 7.87891H33.3801L30.6091 46.9136Z"
          fill="#E6E6E6"
        />
        <path
          d="M33.7591 46.9136H28.6172L31.3882 7.87891H36.5329L33.7591 46.9136Z"
          fill="#F0F0F0"
        />
      </g>
      <g opacity="0.6">
        <path
          d="M63.0727 46.9136H65.979L64.4141 7.87891H61.5078L63.0727 46.9136Z"
          fill="#F0F0F0"
        />
        <path
          d="M61.9427 46.9136H64.2059L62.641 7.87891H60.375L61.9427 46.9136Z"
          fill="#E6E6E6"
        />
        <path
          d="M60.1743 46.9136H63.0806L61.5157 7.87891H58.6094L60.1743 46.9136Z"
          fill="#F0F0F0"
        />
        <path
          d="M59.0286 46.9136H61.2918L59.7269 7.87891H57.4609L59.0286 46.9136Z"
          fill="#E6E6E6"
        />
        <path
          d="M57.2602 46.9136H60.1665L58.6016 7.87891H55.6953L57.2602 46.9136Z"
          fill="#F0F0F0"
        />
      </g>
      <path
        d="M117.758 7.87988H89.4375V45.5318H117.758V7.87988Z"
        fill="#E6E6E6"
      />
      <path
        d="M119.908 7.87988H90.3203V45.5318H119.908V7.87988Z"
        fill="#F5F5F5"
      />
      <path
        d="M94.1569 9.41616L91.5234 42.0049L116.074 43.9888L118.707 11.4L94.1569 9.41616Z"
        fill="white"
      />
      <path
        d="M118.102 11.3517L115.469 43.9404L116.074 43.9893L118.707 11.4006L118.102 11.3517Z"
        fill="#E6E6E6"
      />
      <path
        d="M121.474 59.5967H105.656V90.3624H121.474V59.5967Z"
        fill="#F5F5F5"
      />
      <path
        d="M121.47 59.5967H120.164V90.3624H121.47V59.5967Z"
        fill="#F0F0F0"
      />
      <path
        d="M70.454 73.4902H106.875V60.4713L70.454 60.4713V73.4902Z"
        fill="#E6E6E6"
      />
      <path
        d="M72.2055 61.9014L121.469 61.9014V59.594L72.2055 59.594V61.9014Z"
        fill="#F0F0F0"
      />
      <path
        d="M72.2004 59.5967H56.3828V90.3624H72.2004V59.5967Z"
        fill="#F5F5F5"
      />
      <path
        d="M56.9623 69.3975H57.0341C58.0435 69.3975 59.0116 69.7985 59.7253 70.5122C60.4391 71.226 60.8401 72.1941 60.8401 73.2035V90.3624H53.1562V73.2035C53.1562 72.1941 53.5572 71.226 54.271 70.5122C54.9848 69.7985 55.9529 69.3975 56.9623 69.3975Z"
        fill="#E6E6E6"
      />
      <path
        d="M72.2117 59.5967H70.9062V90.3624H72.2117V59.5967Z"
        fill="#F0F0F0"
      />
      <path
        d="M90.0129 59.5974H86.6016L87.4296 52.9541H90.8437L90.0129 59.5974Z"
        fill="#F0F0F0"
      />
      <path
        d="M90.7657 59.5974H87.3516L88.1823 52.9541H91.5937L90.7657 59.5974Z"
        fill="#F5F5F5"
      />
      <path
        d="M91.177 56.2744H87.7656L88.1796 52.9541H91.591L91.177 56.2744Z"
        fill="#F0F0F0"
      />
      <path
        d="M78.6434 55.4756H102.782C102.83 55.4756 102.877 55.4655 102.92 55.446C102.963 55.4265 103.001 55.3981 103.033 55.3626C103.064 55.3271 103.087 55.2854 103.101 55.2401C103.115 55.1949 103.119 55.1472 103.114 55.1003L101.253 40.2183C101.243 40.1376 101.204 40.0633 101.143 40.0094C101.082 39.9556 101.004 39.9258 100.922 39.9258H76.7832C76.7368 39.9267 76.691 39.9372 76.6488 39.9567C76.6066 39.9761 76.5689 40.0041 76.5381 40.0388C76.5072 40.0736 76.4839 40.1143 76.4696 40.1585C76.4552 40.2027 76.4502 40.2494 76.4548 40.2956L78.3095 55.1996C78.3238 55.2776 78.3651 55.348 78.4262 55.3985C78.4873 55.4489 78.5642 55.4763 78.6434 55.4756Z"
        fill="#F0F0F0"
      />
      <path
        d="M79.0011 55.4756H103.14C103.187 55.4756 103.234 55.4655 103.277 55.446C103.32 55.4265 103.359 55.3981 103.39 55.3626C103.422 55.3271 103.445 55.2854 103.459 55.2401C103.473 55.1949 103.477 55.1472 103.471 55.1003L101.611 40.2183C101.601 40.1376 101.562 40.0633 101.501 40.0094C101.44 39.9556 101.361 39.9258 101.28 39.9258H77.1409C77.0934 39.9259 77.0466 39.936 77.0033 39.9554C76.96 39.9749 76.9214 40.0032 76.8898 40.0387C76.8583 40.0741 76.8346 40.1158 76.8203 40.161C76.806 40.2063 76.8015 40.254 76.8069 40.3011L78.6616 55.1996C78.6761 55.2785 78.7182 55.3497 78.7804 55.4003C78.8427 55.4509 78.9209 55.4776 79.0011 55.4756Z"
        fill="#F5F5F5"
      />
      <path
        d="M102.692 54.6475L100.95 40.7177C100.947 40.6953 100.936 40.6748 100.919 40.6601C100.901 40.6454 100.879 40.6375 100.857 40.6377H77.6726C77.6593 40.6376 77.6461 40.6403 77.634 40.6457C77.6218 40.6511 77.6109 40.659 77.602 40.669C77.5932 40.6789 77.5865 40.6906 77.5825 40.7033C77.5785 40.716 77.5772 40.7294 77.5788 40.7426L79.3203 54.6723C79.3237 54.6947 79.335 54.7152 79.3523 54.7299C79.3695 54.7446 79.3915 54.7526 79.4142 54.7523H102.598C102.611 54.7524 102.625 54.7497 102.637 54.7443C102.649 54.7389 102.66 54.731 102.669 54.7211C102.678 54.7112 102.684 54.6995 102.688 54.6868C102.692 54.6741 102.694 54.6607 102.692 54.6475Z"
        fill="#E6E6E6"
      />
      <path
        d="M100.212 54.7639L94.0847 40.6465H88.7109L94.8381 54.7639H100.212Z"
        fill="#EBEBEB"
      />
      <path
        d="M93.812 54.7639L87.6848 40.6465H85.75L91.8772 54.7639H93.812Z"
        fill="#EBEBEB"
      />
      <path
        d="M93.8672 59.5967H88.3085V59.5277C88.3085 59.3813 88.3667 59.2409 88.4702 59.1374C88.5737 59.0338 88.7141 58.9757 88.8605 58.9757H93.3152C93.4616 58.9757 93.602 59.0338 93.7055 59.1374C93.809 59.2409 93.8672 59.3813 93.8672 59.5277V59.5967Z"
        fill="#F5F5F5"
      />
      <path
        d="M88.9531 59.5967H85.183V59.5277C85.183 59.3813 85.2411 59.2409 85.3446 59.1374C85.4482 59.0338 85.5886 58.9757 85.735 58.9757H88.3984C88.5448 58.9757 88.6852 59.0338 88.7887 59.1374C88.8922 59.2409 88.9504 59.3813 88.9504 59.5277V59.5967H88.9531Z"
        fill="#F0F0F0"
      />
      <path
        d="M100.015 59.5958H82.8309L82.3672 58.2158H97.779L100.015 59.5958Z"
        fill="#F5F5F5"
      />
      <path
        d="M84.7159 59.5958H81.8758L81.4922 58.2158H84.3322L84.7159 59.5958Z"
        fill="#F0F0F0"
      />
      <path
        d="M79.25 59.5938H74.3593C74.3593 59.2878 74.4808 58.9943 74.6972 58.778C74.9135 58.5616 75.207 58.4401 75.513 58.4401H78.0963C78.4023 58.4401 78.6957 58.5616 78.9121 58.778C79.1284 58.9943 79.25 59.2878 79.25 59.5938Z"
        fill="#F0F0F0"
      />
      <path
        d="M24.6199 90.3623H29.4141L29.4141 68.0808H24.6199L24.6199 90.3623Z"
        fill="#E0E0E0"
      />
      <path
        d="M45.9092 90.3626H26.5285L24.1328 64.0322H43.5135L45.9092 90.3626Z"
        fill="#E6E6E6"
      />
      <path
        d="M46.912 90.3626H27.5313L25.1328 64.0322H44.5163L46.912 90.3626Z"
        fill="#F0F0F0"
      />
      <path
        d="M30.1888 87.4476L28.3203 66.9463H41.8747L43.7404 87.4476H30.1888Z"
        fill="white"
      />
      <path
        d="M68.9902 102.828C98.545 102.828 122.504 101.429 122.504 99.7034C122.504 97.9779 98.545 96.5791 68.9902 96.5791C39.4354 96.5791 15.4766 97.9779 15.4766 99.7034C15.4766 101.429 39.4354 102.828 68.9902 102.828Z"
        fill="#F5F5F5"
      />
      <path
        d="M27.3462 33.6707L27.0703 33.6797L27.1904 37.3568L27.4662 37.3478L27.3462 33.6707Z"
        fill="#4160AC"
      />
      <path
        d="M27.2446 30.4969L26.9688 30.5059L27.0259 32.2548L27.3017 32.2458L27.2446 30.4969Z"
        fill="#4160AC"
      />
      <path
        d="M30.9753 57.096H83.6526C83.9342 57.0974 84.2132 57.0417 84.4726 56.9324C84.7321 56.823 84.9668 56.6623 85.1625 56.4599C85.3582 56.2574 85.511 56.0175 85.6115 55.7545C85.712 55.4915 85.7583 55.2108 85.7475 54.9294L84.5745 18.986C84.5511 18.4069 84.306 17.859 83.8899 17.4557C83.4737 17.0524 82.9184 16.8246 82.3389 16.8194H29.6615C29.3798 16.818 29.1008 16.8737 28.8412 16.983C28.5815 17.0923 28.3467 17.253 28.1507 17.4554C27.9548 17.6578 27.8018 17.8977 27.701 18.1607C27.6001 18.4237 27.5535 18.7045 27.5639 18.986L28.7369 54.9239C28.7596 55.5042 29.0047 56.0535 29.4215 56.4579C29.8382 56.8623 30.3946 57.0908 30.9753 57.096Z"
        fill="#4160AC"
      />
      <path
        d="M31.1445 57.096H83.8219C84.1036 57.0973 84.3826 57.0417 84.6422 56.9324C84.9019 56.8231 85.1367 56.6624 85.3327 56.46C85.5286 56.2576 85.6816 56.0177 85.7824 55.7547C85.8833 55.4917 85.9299 55.2109 85.9195 54.9294L84.7409 18.986C84.7169 18.4067 84.4712 17.8588 84.0546 17.4555C83.638 17.0523 83.0824 16.8245 82.5026 16.8194H29.8308C29.5492 16.818 29.2702 16.8737 29.0108 16.983C28.7513 17.0924 28.5166 17.2531 28.3209 17.4555C28.1252 17.658 27.9724 17.8979 27.8719 18.1609C27.7714 18.4239 27.7251 18.7046 27.7359 18.986L28.9089 54.9239C28.9309 55.5039 29.1754 56.0532 29.5917 56.4577C30.008 56.8622 30.5641 57.0908 31.1445 57.096Z"
        fill="#4160AC"
      />
      <path
        opacity="0.5"
        d="M31.1445 57.096H83.8219C84.1036 57.0973 84.3826 57.0417 84.6422 56.9324C84.9019 56.8231 85.1367 56.6624 85.3327 56.46C85.5286 56.2576 85.6816 56.0177 85.7824 55.7547C85.8833 55.4917 85.9299 55.2109 85.9195 54.9294L84.7409 18.986C84.7169 18.4067 84.4712 17.8588 84.0546 17.4555C83.638 17.0523 83.0824 16.8245 82.5026 16.8194H29.8308C29.5492 16.818 29.2702 16.8737 29.0108 16.983C28.7513 17.0924 28.5166 17.2531 28.3209 17.4555C28.1252 17.658 27.9724 17.8979 27.8719 18.1609C27.7714 18.4239 27.7251 18.7046 27.7359 18.986L28.9089 54.9239C28.9309 55.5039 29.1754 56.0532 29.5917 56.4577C30.008 56.8622 30.5641 57.0908 31.1445 57.096Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M82.3702 17.9014H29.6901H29.5134C28.1638 18.0118 28.357 20.0597 29.7177 20.0597H82.4861C83.844 20.0597 83.9048 18.0118 82.5468 17.9014C82.4889 17.9041 82.4282 17.9014 82.3702 17.9014Z"
        fill="#4160AC"
      />
      <path
        d="M31.0174 18.9861C31.0197 19.0504 31.0089 19.1145 30.9857 19.1745C30.9625 19.2346 30.9275 19.2893 30.8826 19.3355C30.8378 19.3816 30.784 19.4182 30.7247 19.4431C30.6653 19.4679 30.6015 19.4805 30.5372 19.4801C30.4045 19.4795 30.2772 19.4279 30.1816 19.3359C30.086 19.2439 30.0295 19.1186 30.0238 18.9861C30.0212 18.9215 30.0317 18.8571 30.0547 18.7968C30.0777 18.7364 30.1127 18.6813 30.1576 18.6349C30.2025 18.5884 30.2564 18.5516 30.3159 18.5266C30.3755 18.5015 30.4395 18.4888 30.5041 18.4893C30.6374 18.4892 30.7655 18.541 30.8613 18.6337C30.9571 18.7264 31.0131 18.8528 31.0174 18.9861Z"
        fill="#FAFAFA"
      />
      <path
        d="M32.7127 18.9861C32.7154 19.0505 32.7049 19.1148 32.6818 19.175C32.6588 19.2352 32.6237 19.2901 32.5788 19.3363C32.5339 19.3825 32.48 19.4191 32.4205 19.4439C32.3609 19.4686 32.297 19.4809 32.2325 19.4801C32.1 19.4789 31.9731 19.427 31.8776 19.3351C31.7822 19.2433 31.7255 19.1184 31.7191 18.9861C31.7165 18.9215 31.727 18.8571 31.75 18.7968C31.773 18.7364 31.808 18.6813 31.8529 18.6349C31.8978 18.5884 31.9517 18.5516 32.0112 18.5266C32.0708 18.5015 32.1348 18.4888 32.1994 18.4893C32.3327 18.4892 32.4608 18.541 32.5566 18.6337C32.6525 18.7264 32.7084 18.8528 32.7127 18.9861Z"
        fill="#FAFAFA"
      />
      <path
        d="M34.266 19.3422C34.4551 19.1498 34.4472 18.8353 34.2482 18.6397C34.0493 18.4442 33.7347 18.4416 33.5456 18.634C33.3565 18.8264 33.3644 19.1409 33.5633 19.3365C33.7622 19.5321 34.0768 19.5346 34.266 19.3422Z"
        fill="#FAFAFA"
      />
      <path
        d="M32.1581 53.508H82.5612C82.6632 53.5088 82.7644 53.4889 82.8585 53.4495C82.9526 53.4101 83.0377 53.352 83.1087 53.2787C83.1796 53.2054 83.235 53.1184 83.2713 53.0231C83.3077 52.9278 83.3243 52.8261 83.3202 52.7242L82.3542 23.1618C82.3431 22.9528 82.2532 22.7557 82.1027 22.6103C81.9521 22.4649 81.7521 22.3818 81.5428 22.378H31.1424C31.0404 22.3771 30.9393 22.397 30.8452 22.4364C30.7511 22.4759 30.666 22.534 30.595 22.6073C30.524 22.6806 30.4687 22.7675 30.4323 22.8628C30.3959 22.9581 30.3793 23.0599 30.3834 23.1618L31.3467 52.7159C31.3558 52.9264 31.4447 53.1255 31.5955 53.2726C31.7463 53.4198 31.9475 53.504 32.1581 53.508Z"
        fill="white"
      />
      <g opacity="0.3">
        <path
          d="M69.1562 49.8515H68.2344V49.5893H68.8857L68.8637 48.938L69.1259 48.9297L69.1562 49.8515Z"
          fill="#4160AC"
        />
        <path
          d="M66.7605 49.8512H65.2949V49.5752H66.7605V49.8512ZM63.8293 49.8512H62.3776V49.5752H63.8431L63.8293 49.8512ZM60.8982 49.8512H59.4327V49.5752H60.8982V49.8512ZM57.9671 49.8512H56.5015V49.5752H57.9616L57.9671 49.8512ZM55.0387 49.8512H53.5704V49.5752H55.0387V49.8512ZM52.1076 49.8512H50.6421V49.5752H52.1076V49.8512ZM49.1765 49.8512H47.7109V49.5752H49.1765V49.8512Z"
          fill="#4160AC"
        />
        <path
          d="M46.2537 49.8515H45.3401L45.3125 48.938L45.5719 48.9297L45.594 49.5893H46.2537V49.8515Z"
          fill="#4160AC"
        />
        <path
          d="M45.2694 47.4714L45.2225 46.0058H45.4985L45.5454 47.4714H45.2694ZM45.1756 44.5403L45.1259 43.0747H45.3881L45.435 44.5403H45.1756ZM45.079 41.6092L45.0321 40.1436H45.2915L45.3412 41.6092H45.079ZM44.9824 38.6808L44.9355 37.2152H45.1977L45.2446 38.6808H44.9824ZM44.8886 35.7497L44.8389 34.2841H45.1011L45.148 35.7497H44.8886ZM44.792 32.8158L44.745 31.353H45.0045L45.0542 32.8186L44.792 32.8158ZM44.6954 29.8874L44.6484 28.4219H44.9106L44.9576 29.8874H44.6954Z"
          fill="#4160AC"
        />
        <path
          d="M44.5929 26.9542L44.5625 26.0352H45.4816V26.2974H44.833L44.8523 26.946L44.5929 26.9542Z"
          fill="#4160AC"
        />
        <path
          d="M66.0054 26.2974H64.5398V26.0352H66.0054V26.2974ZM63.0743 26.2974H61.6087V26.0352H63.0743V26.2974ZM60.1432 26.2974H58.6776V26.0352H60.1432V26.2974ZM57.212 26.2974H55.7465V26.0352H57.212V26.2974ZM54.2809 26.2974H52.8154V26.0352H54.2809V26.2974ZM51.3498 26.2974H49.8842V26.0352H51.3498V26.2974ZM48.4187 26.2974H46.9531V26.0352H48.4187V26.2974Z"
          fill="#4160AC"
        />
        <path
          d="M68.1399 26.9542L68.1178 26.2974H67.4609V26.0352H68.3717L68.4021 26.946L68.1399 26.9542Z"
          fill="#4160AC"
        />
        <path
          d="M68.8181 47.4714L68.7712 46.0058H69.0307L69.0803 47.4714H68.8181ZM68.7353 44.5403L68.6884 43.0747H68.9506L68.9975 44.5403H68.7353ZM68.6415 41.6119L68.5918 40.1464H68.854L68.9009 41.6119H68.6415ZM68.5449 38.6808L68.498 37.2152H68.7574L68.8071 38.6808H68.5449ZM68.4483 35.7497L68.4014 34.2841H68.6636L68.7105 35.7497H68.4483ZM68.3545 32.8186L68.3048 31.353H68.5808L68.6277 32.8186H68.3545ZM68.2579 29.8874L68.2109 28.4219H68.4704L68.5201 29.8874H68.2579Z"
          fill="#4160AC"
        />
      </g>
      <path
        d="M62.0536 41.676H52.4185C51.5248 41.6689 50.6682 41.318 50.0264 40.6962C49.3845 40.0744 49.0067 39.2294 48.9712 38.3364C48.9398 37.5341 49.2081 36.7488 49.7239 36.1335C50.2397 35.5182 50.9661 35.1169 51.7616 35.0078C51.9701 34.2104 52.4375 33.5048 53.0904 33.0018C53.7433 32.4988 54.5447 32.2268 55.3689 32.2285C56.0473 32.2296 56.714 32.4053 57.3047 32.7389C57.8955 33.0725 58.3903 33.5526 58.7416 34.1329C59.0446 34.039 59.3601 33.9915 59.6773 33.9922C60.4992 33.9998 61.2898 34.3081 61.9 34.8588C62.5101 35.4095 62.8976 36.1645 62.9893 36.9812C63.4102 37.1823 63.7687 37.4939 64.0263 37.8828C64.2839 38.2716 64.4311 38.7232 64.4521 39.1892C64.4647 39.5116 64.412 39.8332 64.2971 40.1347C64.1821 40.4362 64.0073 40.7112 63.7832 40.9433C63.5591 41.1754 63.2903 41.3598 62.9931 41.4852C62.6958 41.6106 62.3763 41.6746 62.0536 41.6732V41.676ZM55.3689 32.8716C54.6528 32.8673 53.9576 33.1131 53.4033 33.5665C52.8491 34.02 52.4705 34.6528 52.3329 35.3556L52.286 35.6012L52.0376 35.6178C51.3613 35.6591 50.7281 35.9636 50.2737 36.4661C49.8192 36.9686 49.5795 37.629 49.606 38.306C49.6344 39.0325 49.9417 39.7201 50.4639 40.2259C50.9861 40.7317 51.6832 41.0168 52.4102 41.0219H62.0454C62.2814 41.024 62.5154 40.978 62.7331 40.8869C62.9508 40.7957 63.1478 40.6613 63.3119 40.4917C63.4761 40.3221 63.6041 40.1209 63.6881 39.9003C63.7721 39.6798 63.8104 39.4444 63.8007 39.2086C63.7831 38.8333 63.6566 38.4714 63.4365 38.1669C63.2164 37.8625 62.9123 37.6289 62.5615 37.4946L62.3628 37.4173V37.2048C62.3276 36.5139 62.0302 35.8625 61.5311 35.3834C61.0321 34.9044 60.369 34.6338 59.6773 34.627C59.3479 34.6262 59.0216 34.69 58.7168 34.8146L58.4408 34.925L58.3056 34.6656C58.0267 34.1273 57.6053 33.6758 57.0875 33.3604C56.5697 33.0451 55.9752 32.8779 55.3689 32.8771V32.8716Z"
        fill="#4160AC"
      />
      <path
        d="M59.2724 38.5516L56.6145 35.9766L54.125 38.5516H55.5823L55.6706 41.2123H57.9034L57.8151 38.5516H59.2724Z"
        fill="#4160AC"
      />
      <path
        d="M101.296 28.0977C101.55 28.4647 101.755 28.7739 101.964 29.1161C102.174 29.4583 102.373 29.7895 102.566 30.1345C102.952 30.819 103.311 31.5145 103.64 32.2432C103.978 32.9699 104.276 33.7143 104.534 34.4733C104.809 35.2521 105.027 36.0501 105.185 36.8607L105.29 37.4899L105.304 37.57L105.321 37.6693C105.321 37.7659 105.343 37.8487 105.345 37.9453C105.351 38.0538 105.351 38.1625 105.345 38.271L105.332 38.4449L105.301 38.6381C105.278 38.7752 105.243 38.91 105.196 39.0411C105.146 39.1868 105.081 39.3273 105.003 39.4606C104.848 39.7308 104.641 39.9673 104.393 40.1561C104.029 40.4333 103.601 40.6154 103.148 40.686C102.851 40.7347 102.549 40.7495 102.249 40.7302C102.003 40.7188 101.759 40.6902 101.517 40.6446C101.091 40.5625 100.669 40.4547 100.256 40.3217C99.5001 40.0684 98.7624 39.7641 98.0479 39.4109C97.3432 39.0711 96.6593 38.6895 96 38.2683L96.7949 36.5074C98.166 36.8781 99.5552 37.1784 100.957 37.4071C101.267 37.454 101.579 37.4807 101.893 37.4872C102.093 37.5044 102.294 37.4819 102.486 37.4209C102.503 37.4071 102.439 37.3878 102.257 37.5093C102.106 37.6198 101.991 37.7724 101.926 37.9481C101.908 37.9911 101.895 38.0354 101.884 38.0806L101.868 38.1689C101.869 38.1845 101.869 38.2002 101.868 38.2158V38.1799V38.122L101.744 37.6583C101.343 36.3628 100.824 35.1072 100.192 33.9075C99.8806 33.2837 99.5383 32.6682 99.1906 32.0583C99.0167 31.7519 98.8373 31.4511 98.6579 31.1502C98.4785 30.8494 98.2853 30.5347 98.1252 30.2836L101.296 28.0977Z"
        fill="#FFB573"
      />
      <path
        d="M97.0983 36.6562L95.9005 35.4639L94.7109 38.1162C94.7109 38.1162 95.837 38.7427 96.8389 38.2873L97.0983 36.6562Z"
        fill="#FFB573"
      />
      <path
        d="M94.1254 34.5117L93.2422 36.7528L94.7022 38.1163L95.8946 35.4639L94.1254 34.5117Z"
        fill="#FFB573"
      />
      <path
        d="M98.1668 28.9852L75.1875 24.9375L71.1398 47.9168L94.119 51.9645L98.1668 28.9852Z"
        fill="#4160AC"
      />
      <path
        opacity="0.6"
        d="M98.1668 28.9852L75.1875 24.9375L71.1398 47.9168L94.119 51.9645L98.1668 28.9852Z"
        fill="white"
      />
      <path
        d="M96.8594 29.9056L76.1172 26.252L72.4635 46.9942L93.2057 50.6479L96.8594 29.9056Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M85.8739 36.8492C87.633 35.617 88.0602 33.192 86.828 31.4329C85.5958 29.6738 83.1708 29.2467 81.4117 30.4789C79.6526 31.7111 79.2255 34.136 80.4577 35.8951C81.6899 37.6542 84.1148 38.0814 85.8739 36.8492Z"
        fill="#4160AC"
      />
      <path
        opacity="0.2"
        d="M88.5076 39.3252L81.5551 44.1938L78.3591 39.6288L73.1123 43.3024L72.4609 46.998L93.2023 50.6495L93.8537 46.9566L88.5076 39.3252Z"
        fill="#4160AC"
      />
      <path
        d="M89.6443 19.7368C89.6443 19.9217 89.556 20.0735 89.4346 20.0763C89.3131 20.0791 89.2138 19.93 89.211 19.7479C89.2082 19.5657 89.3021 19.4111 89.4208 19.4111C89.5394 19.4111 89.6416 19.5547 89.6443 19.7368Z"
        fill="#263238"
      />
      <path
        d="M89.5711 20.0713C89.3628 20.637 89.0787 21.1718 88.7266 21.661C88.8539 21.7478 88.9986 21.8058 89.1507 21.8311C89.3027 21.8564 89.4584 21.8483 89.607 21.8073L89.5711 20.0713Z"
        fill="#FF5652"
      />
      <path
        d="M90.2973 18.7042C90.2686 18.7099 90.2388 18.7039 90.2145 18.6876C90.1065 18.6057 89.9801 18.5516 89.8463 18.5299C89.7125 18.5082 89.5755 18.5198 89.4472 18.5634C89.4205 18.5744 89.3905 18.5743 89.3638 18.5632C89.3372 18.5521 89.316 18.5308 89.3051 18.5041C89.2941 18.4774 89.2942 18.4474 89.3053 18.4207C89.3164 18.3941 89.3377 18.3729 89.3644 18.3619C89.527 18.3079 89.7001 18.2932 89.8695 18.3191C90.0389 18.345 90.1997 18.4108 90.3387 18.511C90.362 18.5272 90.378 18.5519 90.3832 18.5798C90.3883 18.6077 90.3823 18.6366 90.3663 18.66C90.3498 18.6831 90.3251 18.6989 90.2973 18.7042Z"
        fill="#263238"
      />
      <path
        d="M94.9693 20.4385C94.8534 22.1331 94.9859 25.5528 96.2803 26.5105C96.2803 26.5105 95.9629 27.8905 92.8911 28.2796C89.5156 28.7019 91.0667 26.8058 91.0667 26.8058C92.8552 26.1324 92.6371 24.7717 92.1541 23.5269L94.9693 20.4385Z"
        fill="#FFB573"
      />
      <path
        d="M103.333 93.7217H103.314C103.273 93.7004 103.24 93.6669 103.219 93.6258C103.198 93.5847 103.191 93.5382 103.198 93.4927C103.199 93.4564 103.208 93.4207 103.224 93.3883C103.24 93.3558 103.263 93.3274 103.292 93.305C103.551 93.0842 104.307 93.1808 104.341 93.1835C104.351 93.1855 104.36 93.1901 104.368 93.1969C104.376 93.2036 104.381 93.2124 104.385 93.2222C104.388 93.232 104.388 93.2425 104.385 93.2523C104.382 93.2621 104.376 93.2708 104.368 93.2774C104.191 93.4209 103.606 93.8156 103.333 93.7217ZM104.197 93.2691C103.94 93.2498 103.515 93.247 103.369 93.3767C103.352 93.3901 103.339 93.4069 103.329 93.426C103.32 93.445 103.315 93.4659 103.314 93.4871C103.314 93.5893 103.352 93.6086 103.366 93.6169C103.501 93.6859 103.918 93.4761 104.197 93.2691Z"
        fill="#4160AC"
      />
      <path
        d="M103.952 93.2002C103.676 93.1091 103.4 92.9518 103.358 92.7917C103.347 92.7527 103.35 92.7111 103.366 92.6739C103.382 92.6367 103.411 92.6062 103.446 92.5874C103.48 92.5643 103.519 92.5487 103.559 92.5415C103.6 92.5344 103.641 92.5359 103.681 92.546C104.015 92.615 104.363 93.1781 104.379 93.2002C104.385 93.2083 104.388 93.2179 104.388 93.2278C104.388 93.2376 104.385 93.2472 104.379 93.2554C104.375 93.264 104.368 93.2713 104.36 93.2762C104.352 93.2811 104.342 93.2834 104.332 93.283C104.202 93.2732 104.074 93.2454 103.952 93.2002ZM103.698 92.662H103.659C103.633 92.6552 103.606 92.6541 103.58 92.6589C103.554 92.6637 103.529 92.6741 103.507 92.6896C103.449 92.7282 103.457 92.7586 103.46 92.7724C103.504 92.9186 103.935 93.1229 104.225 93.1753C104.098 92.9602 103.916 92.783 103.698 92.662Z"
        fill="#4160AC"
      />
      <path
        d="M86.5753 97.4947C86.2827 97.4947 86.0012 97.4533 85.9046 97.3236C85.8802 97.2888 85.8672 97.2474 85.8672 97.2049C85.8672 97.1625 85.8802 97.121 85.9046 97.0862C85.9235 97.0553 85.9484 97.0284 85.9778 97.0071C86.0071 96.9858 86.0404 96.9705 86.0757 96.962C86.4538 96.8572 87.2515 97.3098 87.2846 97.3291C87.2941 97.3351 87.3017 97.3438 87.3061 97.3542C87.3105 97.3646 87.3117 97.376 87.3094 97.3871C87.3079 97.3978 87.3032 97.4078 87.2957 97.4157C87.2883 97.4236 87.2786 97.4291 87.268 97.4312C87.0393 97.4717 86.8076 97.4929 86.5753 97.4947ZM86.2137 97.0531C86.1789 97.0486 86.1436 97.0486 86.1088 97.0531C86.087 97.0581 86.0664 97.0675 86.0483 97.0808C86.0303 97.0941 86.0152 97.111 86.0039 97.1304C85.9653 97.1966 85.9791 97.2298 86.0039 97.2491C86.1088 97.3954 86.6857 97.3981 87.1079 97.3402C86.8318 97.1863 86.5278 97.0888 86.2137 97.0531Z"
        fill="#4160AC"
      />
      <path
        d="M87.2645 97.4283H87.2424C86.9664 97.3068 86.4144 96.8128 86.4641 96.5561C86.4641 96.4954 86.5165 96.4209 86.6656 96.4043C86.719 96.399 86.773 96.4046 86.8242 96.4207C86.8754 96.4368 86.9229 96.4632 86.9636 96.4981C87.2534 96.7355 87.3169 97.3482 87.3169 97.3758C87.3179 97.3849 87.3164 97.3941 87.3125 97.4024C87.3086 97.4107 87.3025 97.4177 87.2948 97.4227C87.2858 97.4284 87.2749 97.4304 87.2645 97.4283ZM86.7125 96.5036H86.6683C86.5717 96.5036 86.5662 96.5533 86.5634 96.5644C86.5386 96.7189 86.9084 97.1164 87.1872 97.2737C87.1626 97.0148 87.0585 96.7699 86.8891 96.5726C86.8385 96.5309 86.7753 96.5076 86.7097 96.5064L86.7125 96.5036Z"
        fill="#4160AC"
      />
      <path
        d="M89.7611 97.3761H87.4841L87.2578 92.1045H89.5348L89.7611 97.3761Z"
        fill="#FFB573"
      />
      <path
        d="M106.79 92.5682L104.679 93.1478L101.977 88.2046L104.088 87.625L106.79 92.5682Z"
        fill="#FFB573"
      />
      <path
        d="M104.365 92.9182L106.645 91.7507C106.685 91.731 106.731 91.7255 106.775 91.735C106.819 91.7445 106.859 91.7685 106.888 91.8031L108.21 93.3984C108.241 93.4377 108.264 93.4836 108.275 93.5327C108.287 93.5819 108.287 93.633 108.276 93.6823C108.266 93.7316 108.244 93.7778 108.213 93.8175C108.182 93.8572 108.142 93.8894 108.097 93.9118C107.296 94.3037 106.885 94.4638 105.889 94.9688C105.273 95.2835 103.44 96.6469 102.516 96.9008C101.591 97.1548 101.232 96.2578 101.589 96.0728C102.757 95.4722 103.656 94.4532 104.106 93.219C104.152 93.09 104.244 92.9828 104.365 92.9182Z"
        fill="#263238"
      />
      <path
        d="M87.4947 97.1143H89.9787C90.0236 97.1144 90.0672 97.1297 90.1023 97.1578C90.1374 97.1859 90.1619 97.225 90.1719 97.2688L90.6218 99.2947C90.6332 99.3436 90.6332 99.3944 90.6219 99.4433C90.6106 99.4922 90.5882 99.5379 90.5565 99.5768C90.5248 99.6157 90.4846 99.6468 90.439 99.6678C90.3934 99.6887 90.3435 99.6989 90.2934 99.6976C89.3964 99.6838 88.0992 99.6314 86.9814 99.6314C85.6621 99.6314 84.525 99.7031 82.9794 99.7031C82.0437 99.7031 81.7843 98.7565 82.1762 98.6709C83.9564 98.2817 85.4109 98.2403 86.951 97.2909C87.1125 97.1831 87.3007 97.1219 87.4947 97.1143Z"
        fill="#263238"
      />
      <path
        d="M97.289 30.7279C97.5264 31.3379 97.7886 31.9341 98.0535 32.4943C98.5062 33.4631 98.9588 34.3077 99.2873 34.89C99.5798 35.4172 99.773 35.718 99.773 35.718L104.741 33.2506C104.741 33.2506 102.737 27.4905 100.645 26.8474C98.5062 26.2209 96.2154 27.9817 97.289 30.7279Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M99.4867 31.2959C99.0534 31.6133 98.7442 32.4717 98.5234 33.4791C98.7994 34.0311 99.0754 34.5141 99.2797 34.8894C99.5722 35.4166 99.7654 35.7174 99.7654 35.7174L100.334 35.4414C100.323 34.0177 100.035 32.6098 99.4867 31.2959Z"
        fill="black"
      />
      <path
        d="M86.7734 34.4005C86.7734 35.1733 86.7928 36.0262 86.8342 36.9673C86.9473 39.5203 87.2261 42.7026 87.7588 46.6577H99.9359C100.021 44.7892 98.8457 35.6674 100.651 26.8574C99.3033 26.5993 97.9408 26.4279 96.5714 26.3441C94.847 26.2447 93.1182 26.2447 91.3937 26.3441C90.2119 26.4657 89.041 26.677 87.8912 26.9761C87.8912 26.9761 86.7707 28.243 86.7734 34.4005Z"
        fill="#263238"
      />
      <path
        d="M89.9062 30.7856C89.7253 31.1644 89.5235 31.533 89.3018 31.8896C89.1003 32.2153 88.8961 32.5299 88.6863 32.8335C88.2668 33.4407 87.828 34.0258 87.367 34.5971C86.4392 35.7568 85.4113 36.8328 84.2952 37.8125C83.6999 38.3343 83.062 38.8051 82.388 39.2201L82.112 39.3802L82.0402 39.4189L81.9409 39.4685L81.7394 39.5651C81.4802 39.6874 81.208 39.78 80.928 39.8411C80.4322 39.9408 79.9244 39.9668 79.421 39.9184C78.5809 39.8184 77.7646 39.5728 77.0088 39.1925C76.6591 39.0212 76.3207 38.8276 75.9958 38.6129C75.6667 38.3996 75.3523 38.1645 75.0547 37.9091L76.0317 36.2531C77.2268 36.5678 78.5737 36.8493 79.501 36.7168C79.6928 36.6985 79.8797 36.6462 80.053 36.5623C80.1101 36.5344 80.1638 36.5001 80.2131 36.4601L80.238 36.4353H80.2545L80.2932 36.4049L80.4615 36.289C80.9341 35.9291 81.3822 35.5381 81.8029 35.1188C82.7039 34.2069 83.5421 33.235 84.3117 32.2097C84.7064 31.6936 85.0873 31.1637 85.4544 30.631C85.6338 30.355 85.8132 30.1011 85.9788 29.8389C86.1444 29.5767 86.31 29.309 86.3955 29.1406L89.9062 30.7856Z"
        fill="#FFB573"
      />
      <path
        d="M76.5174 36.4827L74.417 35.4863L74.2266 38.3043C74.2266 38.3043 75.8605 38.5803 76.7244 37.9482L76.5174 36.4827Z"
        fill="#FFB573"
      />
      <path
        d="M71.7734 35.9666L72.5821 38.1138L74.2298 38.3043L74.4203 35.4863L71.7734 35.9666Z"
        fill="#FFB573"
      />
      <path
        d="M91.4124 29.2927C91.3406 31.4703 86.8584 36.4825 86.8584 36.4825L82.125 33.3968C82.125 33.3968 83.6347 30.5816 85.5667 28.5475C88.5172 25.4839 91.498 26.6817 91.4124 29.2927Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M87.2578 92.1045L87.3737 94.8231H89.6535L89.5376 92.1045H87.2578Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M104.088 87.625L101.977 88.2074L103.37 90.7548L105.482 90.1725L104.088 87.625Z"
        fill="black"
      />
      <path
        d="M95.7751 19.2205C95.684 21.4782 95.7475 22.8085 94.6325 23.987C92.9544 25.7617 90.1502 24.7874 89.5651 22.5491C89.0379 20.5315 89.2753 17.1781 91.464 16.2342C91.9455 16.0233 92.4731 15.9393 92.9963 15.9899C93.5196 16.0406 94.0211 16.2244 94.4533 16.5237C94.8854 16.8231 95.2338 17.2281 95.4651 17.7001C95.6965 18.1721 95.8032 18.6956 95.7751 19.2205Z"
        fill="#FFB573"
      />
      <path
        d="M91.2682 20.2976C90.454 19.718 89.6563 18.5229 89.7198 17.6342C88.9663 17.6038 88.4612 16.3618 88.8752 15.4565C89.1347 16.0389 90.0317 15.6111 90.3822 15.1502C90.1945 14.7058 90.2249 13.9689 91.5138 13.4473C91.4061 13.8226 91.4613 14.4547 92.7558 13.9993C94.3262 13.4473 97.0862 13.8171 96.2775 15.6856C97.3815 15.8871 98.8195 18.1696 97.5996 19.3702C96.3797 20.5708 96.1285 22.5111 95.0852 22.7098C94.0419 22.9085 91.3289 22.9775 91.2682 20.2976Z"
        fill="#263238"
      />
      <path
        d="M95.5469 15.6115C95.9859 15.4288 96.4736 15.3994 96.9313 15.5281C97.3891 15.6568 97.79 15.9361 98.0695 16.3208C97.2754 15.9417 96.4222 15.7018 95.5469 15.6115Z"
        fill="#263238"
      />
      <path
        d="M91.8692 20.2017C91.8466 20.7033 91.6594 21.1834 91.3366 21.5679C90.906 22.0703 90.434 21.7777 90.3374 21.1843C90.2519 20.6516 90.3733 19.7463 90.9088 19.4621C91.4442 19.1778 91.8886 19.5973 91.8692 20.2017Z"
        fill="#FFB573"
      />
      <path
        d="M92.0796 46.6582C92.0796 46.6582 91.006 63.8889 93.0429 70.2424C96.2666 80.3054 102.018 90.5864 102.018 90.5864L106.131 89.3499C106.131 89.3499 101.607 78.4976 100.12 70.0299C98.7837 62.415 99.9374 52.8875 99.9374 46.6665L92.0796 46.6582Z"
        fill="#4160AC"
      />
      <path
        opacity="0.2"
        d="M91.912 50.5086C93.0601 50.1746 93.9571 50.3016 94.189 51.3228C94.7962 53.9917 92.9746 61.0325 92.0665 64.2534C91.6912 59.6166 91.7767 54.0966 91.912 50.5086Z"
        fill="black"
      />
      <path
        d="M100.852 89.4076C100.835 89.4076 101.925 90.68 101.925 90.68L106.419 89.3276L105.847 87.8096L100.852 89.4076Z"
        fill="#263238"
      />
      <path
        d="M87.7713 46.6582C87.7713 46.6582 84.3406 61.1951 84.0978 68.1393C83.8218 75.928 86.5459 94.2958 86.5459 94.2958H90.4099C90.4099 94.2958 90.8846 75.7845 91.0557 68.6913C91.2434 60.9633 95.7808 46.6582 95.7808 46.6582H87.7713Z"
        fill="#4160AC"
      />
      <path
        d="M85.7899 92.7607C85.7733 92.7607 86.0079 94.3615 86.0079 94.3615H90.6999L90.8269 92.9319L85.7899 92.7607Z"
        fill="#263238"
      />
      <path
        d="M89.4598 25.8117C89.9842 25.6295 93.9613 25.1244 96.8483 25.279C98.5043 25.3673 99.4261 25.555 100.1 25.8724C100.773 26.1898 99.5669 27.8375 99.0094 28.039C99.2357 28.6683 97.9799 29.4714 97.265 29.8523C96.989 30.443 94.1545 30.9894 92.4985 29.7198C91.2262 28.7428 87.9721 26.3278 89.4598 25.8117Z"
        fill="#4160AC"
      />
    </svg>
  );
};

export default uploadManually;
