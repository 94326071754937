import React from "react";

const AccountCreateLogo = ({
  bgColor = "#ffffff",
  color = "#666666",
  width = "64",
  height = "64",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1054_12394)">
        <path
          d="M43.864 43.841C40.6756 48.3234 35.492 51.0002 29.9998 51.0002C25.6609 51.0002 21.5301 49.3654 18.3689 46.3986C17.5623 45.6437 16.2976 45.6828 15.5418 46.4875C14.7859 47.2932 14.8259 48.5588 15.6306 49.3146C19.5379 52.9807 24.6404 55.0002 29.9998 55.0002C36.784 55.0002 43.1853 51.6955 47.1238 46.1594C47.7635 45.259 47.5535 44.0109 46.6531 43.3703C45.7517 42.7297 44.5037 42.9416 43.864 43.841Z"
          fill="#58D255"
        />
        <path
          d="M62.4141 1.58594C61.6328 0.804688 60.3672 0.804688 59.5859 1.58594L49.0381 12.1337C43.7582 7.5329 37.0241 5 30 5C14.0098 5 1 18.0093 1 34C1 49.9902 14.0098 63 30 63C45.9902 63 59 49.9902 59 34C59 29.1162 57.7627 24.2876 55.4219 20.0356C54.8896 19.0684 53.6758 18.7148 52.7051 19.248C51.7373 19.7808 51.3848 20.9971 51.918 21.9644C53.9346 25.627 55 29.7891 55 34C55 47.7852 43.7852 59 30 59C16.2148 59 5 47.7852 5 34C5 20.2148 16.2148 9 30 9C35.9618 9 41.6717 11.1315 46.1907 14.9811L29.9346 31.2373L20.3457 22.52C19.5273 21.7773 18.2637 21.8389 17.5205 22.6548C16.7773 23.4722 16.8369 24.7368 17.6543 25.48L28.6543 35.4795C29.0371 35.8271 29.5186 36 30 36C30.5127 36 31.0244 35.8037 31.4141 35.4141L62.4141 4.41406C63.1953 3.6333 63.1953 2.3667 62.4141 1.58594Z"
          fill="#58D255"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={width} height={height} fill={bgColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AccountCreateLogo;
