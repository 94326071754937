import React from "react";

const noRental = ({
  wSize = "500",
  hSize = "500",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox={`0 0 500 500`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M500 382.4H0V382.65H500V382.4Z" fill="#EBEBEB" />
      <path d="M449.899 398.49H416.779V398.74H449.899V398.49Z" fill="#EBEBEB" />
      <path d="M331.22 401.21H322.53V401.46H331.22V401.21Z" fill="#EBEBEB" />
      <path d="M415.78 389.21H396.59V389.46H415.78V389.21Z" fill="#EBEBEB" />
      <path d="M95.65 390.89H52.46V391.14H95.65V390.89Z" fill="#EBEBEB" />
      <path d="M110.89 390.89H104.56V391.14H110.89V390.89Z" fill="#EBEBEB" />
      <path d="M225.15 395.11H131.47V395.36H225.15V395.11Z" fill="#EBEBEB" />
      <path
        d="M237 337.8H43.91C42.3973 337.797 40.9475 337.195 39.8788 336.124C38.8102 335.053 38.21 333.603 38.21 332.09V60.66C38.2231 59.156 38.8292 57.718 39.8964 56.6582C40.9637 55.5985 42.4059 55.0026 43.91 55H237C238.514 55 239.967 55.6016 241.038 56.6724C242.108 57.7433 242.71 59.1956 242.71 60.71V332.09C242.71 333.604 242.108 335.057 241.038 336.128C239.967 337.198 238.514 337.8 237 337.8ZM43.91 55.2C42.4636 55.2026 41.0774 55.7791 40.0556 56.8027C39.0338 57.8264 38.46 59.2136 38.46 60.66V332.09C38.46 333.536 39.0338 334.924 40.0556 335.947C41.0774 336.971 42.4636 337.547 43.91 337.55H237C238.447 337.547 239.834 336.971 240.858 335.948C241.881 334.924 242.457 333.537 242.46 332.09V60.66C242.457 59.2127 241.881 57.8255 240.858 56.8021C239.834 55.7787 238.447 55.2026 237 55.2H43.91Z"
        fill="#EBEBEB"
      />
      <path
        d="M453.31 337.8H260.21C258.696 337.797 257.246 337.195 256.175 336.125C255.105 335.054 254.503 333.604 254.5 332.09V60.66C254.516 59.1551 255.124 57.7171 256.193 56.6576C257.262 55.5981 258.705 55.0025 260.21 55H453.31C454.812 55.0052 456.252 55.6022 457.317 56.6617C458.382 57.7212 458.987 59.1578 459 60.66V332.09C459 333.601 458.401 335.05 457.335 336.121C456.268 337.191 454.821 337.795 453.31 337.8ZM260.21 55.2C258.763 55.2026 257.375 55.7787 256.352 56.8021C255.329 57.8255 254.753 59.2127 254.75 60.66V332.09C254.753 333.537 255.329 334.924 256.352 335.948C257.375 336.971 258.763 337.547 260.21 337.55H453.31C454.757 337.547 456.145 336.971 457.168 335.948C458.191 334.924 458.767 333.537 458.77 332.09V60.66C458.767 59.2127 458.191 57.8255 457.168 56.8021C456.145 55.7787 454.757 55.2026 453.31 55.2H260.21Z"
        fill="#EBEBEB"
      />
      <path d="M433.42 115.98H66.5801V332.45H433.42V115.98Z" fill="#EBEBEB" />
      <path d="M428 120.97H71V326.97H428V120.97Z" fill="#FAFAFA" />
      <path
        d="M71 264.97V326.97H428V256.97H418V235.97H412V221.97H404V200.97H402V221.97H394V235.97H388V256.97H377V262.97H372V285.97H371V252.97H368V273.97H365V223.97H363V244.97H355V237.97H352V208.97H350.22H346V179.97H344V183.97H303V171.97H296V125.97H293V171.97H283V183.97H279V243.97H258V225.97H265V222.97H258V217.97H238V171.97H233V164.97H209V171.97H206V235.97H195V264.97H189V241.97H184V235.97H173V214.97H167V200.97H160V179.97H157V200.97H150V214.97H144V235.97H133V241.97H128V264.97H121V203.97H118V224.97H109V264.97H105V187.97H102V158.97H99V169.38L81 177.8V187.97H73V264.97H71Z"
        fill="#F0F0F0"
      />
      <path d="M321.14 187.11H318.31V189.94H321.14V187.11Z" fill="#F5F5F5" />
      <path d="M321.14 191.86H318.31V194.69H321.14V191.86Z" fill="#F5F5F5" />
      <path d="M321.14 210.86H318.31V213.69H321.14V210.86Z" fill="#F5F5F5" />
      <path d="M321.14 215.61H318.31V218.44H321.14V215.61Z" fill="#F5F5F5" />
      <path d="M321.14 220.36H318.31V223.19H321.14V220.36Z" fill="#F5F5F5" />
      <path d="M321.14 234.61H318.31V237.44H321.14V234.61Z" fill="#F5F5F5" />
      <path d="M321.14 239.36H318.31V242.19H321.14V239.36Z" fill="#F5F5F5" />
      <path d="M316.64 206.11H313.81V208.94H316.64V206.11Z" fill="#F5F5F5" />
      <path d="M316.64 210.86H313.81V213.69H316.64V210.86Z" fill="#F5F5F5" />
      <path d="M316.64 215.61H313.81V218.44H316.64V215.61Z" fill="#F5F5F5" />
      <path d="M316.64 220.36H313.81V223.19H316.64V220.36Z" fill="#F5F5F5" />
      <path d="M316.64 225.11H313.81V227.94H316.64V225.11Z" fill="#F5F5F5" />
      <path d="M316.64 234.61H313.81V237.44H316.64V234.61Z" fill="#F5F5F5" />
      <path d="M316.64 239.36H313.81V242.19H316.64V239.36Z" fill="#F5F5F5" />
      <path d="M312.14 187.11H309.31V189.94H312.14V187.11Z" fill="#F5F5F5" />
      <path d="M312.14 191.86H309.31V194.69H312.14V191.86Z" fill="#F5F5F5" />
      <path d="M312.14 196.61H309.31V199.44H312.14V196.61Z" fill="#F5F5F5" />
      <path d="M312.14 201.36H309.31V204.19H312.14V201.36Z" fill="#F5F5F5" />
      <path d="M312.14 206.11H309.31V208.94H312.14V206.11Z" fill="#F5F5F5" />
      <path d="M312.14 210.86H309.31V213.69H312.14V210.86Z" fill="#F5F5F5" />
      <path d="M312.14 215.61H309.31V218.44H312.14V215.61Z" fill="#F5F5F5" />
      <path d="M312.14 220.36H309.31V223.19H312.14V220.36Z" fill="#F5F5F5" />
      <path d="M312.14 225.11H309.31V227.94H312.14V225.11Z" fill="#F5F5F5" />
      <path d="M312.14 229.86H309.31V232.69H312.14V229.86Z" fill="#F5F5F5" />
      <path d="M312.14 239.36H309.31V242.19H312.14V239.36Z" fill="#F5F5F5" />
      <path d="M307.64 187.11H304.81V189.94H307.64V187.11Z" fill="#F5F5F5" />
      <path d="M307.64 191.86H304.81V194.69H307.64V191.86Z" fill="#F5F5F5" />
      <path d="M307.64 196.61H304.81V199.44H307.64V196.61Z" fill="#F5F5F5" />
      <path d="M307.64 201.36H304.81V204.19H307.64V201.36Z" fill="#F5F5F5" />
      <path d="M307.64 206.11H304.81V208.94H307.64V206.11Z" fill="#F5F5F5" />
      <path d="M307.64 210.86H304.81V213.69H307.64V210.86Z" fill="#F5F5F5" />
      <path d="M307.64 215.61H304.81V218.44H307.64V215.61Z" fill="#F5F5F5" />
      <path d="M307.64 258.36H304.81V261.19H307.64V258.36Z" fill="#F5F5F5" />
      <path d="M303.14 187.11H300.31V189.94H303.14V187.11Z" fill="#F5F5F5" />
      <path d="M303.14 191.86H300.31V194.69H303.14V191.86Z" fill="#F5F5F5" />
      <path d="M303.14 196.61H300.31V199.44H303.14V196.61Z" fill="#F5F5F5" />
      <path d="M303.14 201.36H300.31V204.19H303.14V201.36Z" fill="#F5F5F5" />
      <path d="M303.14 206.11H300.31V208.94H303.14V206.11Z" fill="#F5F5F5" />
      <path d="M303.14 210.86H300.31V213.69H303.14V210.86Z" fill="#F5F5F5" />
      <path d="M303.14 215.61H300.31V218.44H303.14V215.61Z" fill="#F5F5F5" />
      <path d="M303.14 220.36H300.31V223.19H303.14V220.36Z" fill="#F5F5F5" />
      <path d="M303.14 248.86H300.31V251.69H303.14V248.86Z" fill="#F5F5F5" />
      <path d="M303.14 253.61H300.31V256.44H303.14V253.61Z" fill="#F5F5F5" />
      <path d="M303.14 258.36H300.31V261.19H303.14V258.36Z" fill="#F5F5F5" />
      <path d="M303.14 263.11H300.31V265.94H303.14V263.11Z" fill="#F5F5F5" />
      <path d="M303.14 282.11H300.31V284.94H303.14V282.11Z" fill="#F5F5F5" />
      <path d="M298.64 187.11H295.81V189.94H298.64V187.11Z" fill="#F5F5F5" />
      <path d="M298.64 191.86H295.81V194.69H298.64V191.86Z" fill="#F5F5F5" />
      <path d="M298.64 196.61H295.81V199.44H298.64V196.61Z" fill="#F5F5F5" />
      <path d="M298.64 201.36H295.81V204.19H298.64V201.36Z" fill="#F5F5F5" />
      <path d="M298.64 206.11H295.81V208.94H298.64V206.11Z" fill="#F5F5F5" />
      <path d="M298.64 210.86H295.81V213.69H298.64V210.86Z" fill="#F5F5F5" />
      <path d="M298.64 215.61H295.81V218.44H298.64V215.61Z" fill="#F5F5F5" />
      <path d="M298.64 220.36H295.81V223.19H298.64V220.36Z" fill="#F5F5F5" />
      <path d="M298.64 225.11H295.81V227.94H298.64V225.11Z" fill="#F5F5F5" />
      <path d="M298.64 229.86H295.81V232.69H298.64V229.86Z" fill="#F5F5F5" />
      <path d="M298.64 244.11H295.81V246.94H298.64V244.11Z" fill="#F5F5F5" />
      <path d="M298.64 248.86H295.81V251.69H298.64V248.86Z" fill="#F5F5F5" />
      <path d="M298.64 253.61H295.81V256.44H298.64V253.61Z" fill="#F5F5F5" />
      <path d="M298.64 258.36H295.81V261.19H298.64V258.36Z" fill="#F5F5F5" />
      <path d="M298.64 263.11H295.81V265.94H298.64V263.11Z" fill="#F5F5F5" />
      <path d="M298.64 282.11H295.81V284.94H298.64V282.11Z" fill="#F5F5F5" />
      <path d="M294.14 187.11H291.31V189.94H294.14V187.11Z" fill="#F5F5F5" />
      <path d="M294.14 191.86H291.31V194.69H294.14V191.86Z" fill="#F5F5F5" />
      <path d="M294.14 196.61H291.31V199.44H294.14V196.61Z" fill="#F5F5F5" />
      <path d="M294.14 201.36H291.31V204.19H294.14V201.36Z" fill="#F5F5F5" />
      <path d="M294.14 206.11H291.31V208.94H294.14V206.11Z" fill="#F5F5F5" />
      <path d="M294.14 210.86H291.31V213.69H294.14V210.86Z" fill="#F5F5F5" />
      <path d="M294.14 215.61H291.31V218.44H294.14V215.61Z" fill="#F5F5F5" />
      <path d="M294.14 220.36H291.31V223.19H294.14V220.36Z" fill="#F5F5F5" />
      <path d="M294.14 225.11H291.31V227.94H294.14V225.11Z" fill="#F5F5F5" />
      <path d="M294.14 229.86H291.31V232.69H294.14V229.86Z" fill="#F5F5F5" />
      <path d="M294.14 234.61H291.31V237.44H294.14V234.61Z" fill="#F5F5F5" />
      <path d="M294.14 239.36H291.31V242.19H294.14V239.36Z" fill="#F5F5F5" />
      <path d="M294.14 244.11H291.31V246.94H294.14V244.11Z" fill="#F5F5F5" />
      <path d="M294.14 248.86H291.31V251.69H294.14V248.86Z" fill="#F5F5F5" />
      <path d="M294.14 253.61H291.31V256.44H294.14V253.61Z" fill="#F5F5F5" />
      <path d="M294.14 258.36H291.31V261.19H294.14V258.36Z" fill="#F5F5F5" />
      <path d="M294.14 263.11H291.31V265.94H294.14V263.11Z" fill="#F5F5F5" />
      <path d="M294.14 267.86H291.31V270.69H294.14V267.86Z" fill="#F5F5F5" />
      <path d="M289.64 187.11H286.81V189.94H289.64V187.11Z" fill="#F5F5F5" />
      <path d="M289.64 191.86H286.81V194.69H289.64V191.86Z" fill="#F5F5F5" />
      <path d="M289.64 196.61H286.81V199.44H289.64V196.61Z" fill="#F5F5F5" />
      <path d="M289.64 201.36H286.81V204.19H289.64V201.36Z" fill="#F5F5F5" />
      <path d="M289.64 206.11H286.81V208.94H289.64V206.11Z" fill="#F5F5F5" />
      <path d="M289.64 210.86H286.81V213.69H289.64V210.86Z" fill="#F5F5F5" />
      <path d="M289.64 215.61H286.81V218.44H289.64V215.61Z" fill="#F5F5F5" />
      <path d="M289.64 220.36H286.81V223.19H289.64V220.36Z" fill="#F5F5F5" />
      <path d="M289.64 225.11H286.81V227.94H289.64V225.11Z" fill="#F5F5F5" />
      <path d="M289.64 229.86H286.81V232.69H289.64V229.86Z" fill="#F5F5F5" />
      <path d="M289.64 244.11H286.81V246.94H289.64V244.11Z" fill="#F5F5F5" />
      <path d="M289.64 248.86H286.81V251.69H289.64V248.86Z" fill="#F5F5F5" />
      <path d="M289.64 253.61H286.81V256.44H289.64V253.61Z" fill="#F5F5F5" />
      <path d="M289.64 258.36H286.81V261.19H289.64V258.36Z" fill="#F5F5F5" />
      <path d="M285.14 187.11H282.31V189.94H285.14V187.11Z" fill="#F5F5F5" />
      <path d="M285.14 191.86H282.31V194.69H285.14V191.86Z" fill="#F5F5F5" />
      <path d="M285.14 196.61H282.31V199.44H285.14V196.61Z" fill="#F5F5F5" />
      <path d="M285.14 201.36H282.31V204.19H285.14V201.36Z" fill="#F5F5F5" />
      <path d="M285.14 206.11H282.31V208.94H285.14V206.11Z" fill="#F5F5F5" />
      <path d="M285.14 210.86H282.31V213.69H285.14V210.86Z" fill="#F5F5F5" />
      <path d="M285.14 215.61H282.31V218.44H285.14V215.61Z" fill="#F5F5F5" />
      <path d="M285.14 220.36H282.31V223.19H285.14V220.36Z" fill="#F5F5F5" />
      <path d="M285.14 225.11H282.31V227.94H285.14V225.11Z" fill="#F5F5F5" />
      <path d="M285.14 229.86H282.31V232.69H285.14V229.86Z" fill="#F5F5F5" />
      <path d="M285.14 244.11H282.31V246.94H285.14V244.11Z" fill="#F5F5F5" />
      <path d="M285.14 248.86H282.31V251.69H285.14V248.86Z" fill="#F5F5F5" />
      <path d="M285.14 253.61H282.31V256.44H285.14V253.61Z" fill="#F5F5F5" />
      <path d="M234.48 174.77H231.65V177.6H234.48V174.77Z" fill="#F5F5F5" />
      <path d="M234.48 179.52H231.65V182.35H234.48V179.52Z" fill="#F5F5F5" />
      <path d="M234.48 184.27H231.65V187.1H234.48V184.27Z" fill="#F5F5F5" />
      <path d="M234.48 189.02H231.65V191.85H234.48V189.02Z" fill="#F5F5F5" />
      <path d="M234.48 193.77H231.65V196.6H234.48V193.77Z" fill="#F5F5F5" />
      <path d="M234.48 198.52H231.65V201.35H234.48V198.52Z" fill="#F5F5F5" />
      <path d="M234.48 203.27H231.65V206.1H234.48V203.27Z" fill="#F5F5F5" />
      <path d="M234.48 208.02H231.65V210.85H234.48V208.02Z" fill="#F5F5F5" />
      <path d="M234.48 222.27H231.65V225.1H234.48V222.27Z" fill="#F5F5F5" />
      <path d="M234.48 227.02H231.65V229.85H234.48V227.02Z" fill="#F5F5F5" />
      <path d="M229.98 174.77H227.15V177.6H229.98V174.77Z" fill="#F5F5F5" />
      <path d="M229.98 179.52H227.15V182.35H229.98V179.52Z" fill="#F5F5F5" />
      <path d="M229.98 184.27H227.15V187.1H229.98V184.27Z" fill="#F5F5F5" />
      <path d="M229.98 189.02H227.15V191.85H229.98V189.02Z" fill="#F5F5F5" />
      <path d="M229.98 193.77H227.15V196.6H229.98V193.77Z" fill="#F5F5F5" />
      <path d="M229.98 198.52H227.15V201.35H229.98V198.52Z" fill="#F5F5F5" />
      <path d="M229.98 203.27H227.15V206.1H229.98V203.27Z" fill="#F5F5F5" />
      <path d="M229.98 208.02H227.15V210.85H229.98V208.02Z" fill="#F5F5F5" />
      <path d="M229.98 227.02H227.15V229.85H229.98V227.02Z" fill="#F5F5F5" />
      <path d="M225.48 174.77H222.65V177.6H225.48V174.77Z" fill="#F5F5F5" />
      <path d="M225.48 179.52H222.65V182.35H225.48V179.52Z" fill="#F5F5F5" />
      <path d="M225.48 184.27H222.65V187.1H225.48V184.27Z" fill="#F5F5F5" />
      <path d="M225.48 189.02H222.65V191.85H225.48V189.02Z" fill="#F5F5F5" />
      <path d="M225.48 193.77H222.65V196.6H225.48V193.77Z" fill="#F5F5F5" />
      <path d="M225.48 198.52H222.65V201.35H225.48V198.52Z" fill="#F5F5F5" />
      <path d="M225.48 203.27H222.65V206.1H225.48V203.27Z" fill="#F5F5F5" />
      <path d="M225.48 208.02H222.65V210.85H225.48V208.02Z" fill="#F5F5F5" />
      <path d="M220.98 174.77H218.15V177.6H220.98V174.77Z" fill="#F5F5F5" />
      <path d="M220.98 179.52H218.15V182.35H220.98V179.52Z" fill="#F5F5F5" />
      <path d="M220.98 184.27H218.15V187.1H220.98V184.27Z" fill="#F5F5F5" />
      <path d="M220.98 189.02H218.15V191.85H220.98V189.02Z" fill="#F5F5F5" />
      <path d="M220.98 193.77H218.15V196.6H220.98V193.77Z" fill="#F5F5F5" />
      <path d="M220.98 198.52H218.15V201.35H220.98V198.52Z" fill="#F5F5F5" />
      <path d="M220.98 208.02H218.15V210.85H220.98V208.02Z" fill="#F5F5F5" />
      <path d="M220.98 212.77H218.15V215.6H220.98V212.77Z" fill="#F5F5F5" />
      <path d="M220.98 227.02H218.15V229.85H220.98V227.02Z" fill="#F5F5F5" />
      <path d="M220.98 231.77H218.15V234.6H220.98V231.77Z" fill="#F5F5F5" />
      <path d="M220.98 236.52H218.15V239.35H220.98V236.52Z" fill="#F5F5F5" />
      <path d="M220.98 241.27H218.15V244.1H220.98V241.27Z" fill="#F5F5F5" />
      <path d="M220.98 246.02H218.15V248.85H220.98V246.02Z" fill="#F5F5F5" />
      <path d="M220.98 250.77H218.15V253.6H220.98V250.77Z" fill="#F5F5F5" />
      <path d="M220.98 255.52H218.15V258.35H220.98V255.52Z" fill="#F5F5F5" />
      <path d="M216.48 174.77H213.65V177.6H216.48V174.77Z" fill="#F5F5F5" />
      <path d="M216.48 179.52H213.65V182.35H216.48V179.52Z" fill="#F5F5F5" />
      <path d="M216.48 184.27H213.65V187.1H216.48V184.27Z" fill="#F5F5F5" />
      <path d="M216.48 189.02H213.65V191.85H216.48V189.02Z" fill="#F5F5F5" />
      <path d="M216.48 193.77H213.65V196.6H216.48V193.77Z" fill="#F5F5F5" />
      <path d="M216.48 198.52H213.65V201.35H216.48V198.52Z" fill="#F5F5F5" />
      <path d="M216.48 212.77H213.65V215.6H216.48V212.77Z" fill="#F5F5F5" />
      <path d="M216.48 217.52H213.65V220.35H216.48V217.52Z" fill="#F5F5F5" />
      <path d="M216.48 222.27H213.65V225.1H216.48V222.27Z" fill="#F5F5F5" />
      <path d="M216.48 227.02H213.65V229.85H216.48V227.02Z" fill="#F5F5F5" />
      <path d="M216.48 231.77H213.65V234.6H216.48V231.77Z" fill="#F5F5F5" />
      <path d="M216.48 236.52H213.65V239.35H216.48V236.52Z" fill="#F5F5F5" />
      <path d="M216.48 241.27H213.65V244.1H216.48V241.27Z" fill="#F5F5F5" />
      <path d="M216.48 246.02H213.65V248.85H216.48V246.02Z" fill="#F5F5F5" />
      <path d="M216.48 250.77H213.65V253.6H216.48V250.77Z" fill="#F5F5F5" />
      <path d="M216.48 255.52H213.65V258.35H216.48V255.52Z" fill="#F5F5F5" />
      <path d="M216.48 260.27H213.65V263.1H216.48V260.27Z" fill="#F5F5F5" />
      <path d="M216.48 269.77H213.65V272.6H216.48V269.77Z" fill="#F5F5F5" />
      <path d="M211.98 174.77H209.15V177.6H211.98V174.77Z" fill="#F5F5F5" />
      <path d="M211.98 179.52H209.15V182.35H211.98V179.52Z" fill="#F5F5F5" />
      <path d="M211.98 184.27H209.15V187.1H211.98V184.27Z" fill="#F5F5F5" />
      <path d="M211.98 189.02H209.15V191.85H211.98V189.02Z" fill="#F5F5F5" />
      <path d="M211.98 193.77H209.15V196.6H211.98V193.77Z" fill="#F5F5F5" />
      <path d="M211.98 198.52H209.15V201.35H211.98V198.52Z" fill="#F5F5F5" />
      <path d="M211.98 212.77H209.15V215.6H211.98V212.77Z" fill="#F5F5F5" />
      <path d="M211.98 217.52H209.15V220.35H211.98V217.52Z" fill="#F5F5F5" />
      <path d="M211.98 222.27H209.15V225.1H211.98V222.27Z" fill="#F5F5F5" />
      <path d="M211.98 227.02H209.15V229.85H211.98V227.02Z" fill="#F5F5F5" />
      <path d="M211.98 241.27H209.15V244.1H211.98V241.27Z" fill="#F5F5F5" />
      <path d="M211.98 246.02H209.15V248.85H211.98V246.02Z" fill="#F5F5F5" />
      <path d="M211.98 250.77H209.15V253.6H211.98V250.77Z" fill="#F5F5F5" />
      <path d="M211.98 255.52H209.15V258.35H211.98V255.52Z" fill="#F5F5F5" />
      <path d="M211.98 260.27H209.15V263.1H211.98V260.27Z" fill="#F5F5F5" />
      <path d="M211.98 269.77H209.15V272.6H211.98V269.77Z" fill="#F5F5F5" />
      <path d="M211.98 274.52H209.15V277.35H211.98V274.52Z" fill="#F5F5F5" />
      <path d="M171.14 218.94H168.31V221.77H171.14V218.94Z" fill="#F5F5F5" />
      <path d="M171.14 223.69H168.31V226.52H171.14V223.69Z" fill="#F5F5F5" />
      <path d="M171.14 228.44H168.31V231.27H171.14V228.44Z" fill="#F5F5F5" />
      <path d="M171.14 233.19H168.31V236.02H171.14V233.19Z" fill="#F5F5F5" />
      <path d="M171.14 237.94H168.31V240.77H171.14V237.94Z" fill="#F5F5F5" />
      <path d="M171.14 242.69H168.31V245.52H171.14V242.69Z" fill="#F5F5F5" />
      <path d="M171.14 247.44H168.31V250.27H171.14V247.44Z" fill="#F5F5F5" />
      <path d="M171.14 261.69H168.31V264.52H171.14V261.69Z" fill="#F5F5F5" />
      <path d="M171.14 266.44H168.31V269.27H171.14V266.44Z" fill="#F5F5F5" />
      <path d="M171.14 271.19H168.31V274.02H171.14V271.19Z" fill="#F5F5F5" />
      <path d="M166.64 218.94H163.81V221.77H166.64V218.94Z" fill="#F5F5F5" />
      <path d="M166.64 223.69H163.81V226.52H166.64V223.69Z" fill="#F5F5F5" />
      <path d="M166.64 228.44H163.81V231.27H166.64V228.44Z" fill="#F5F5F5" />
      <path d="M166.64 233.19H163.81V236.02H166.64V233.19Z" fill="#F5F5F5" />
      <path d="M166.64 237.94H163.81V240.77H166.64V237.94Z" fill="#F5F5F5" />
      <path d="M166.64 242.69H163.81V245.52H166.64V242.69Z" fill="#F5F5F5" />
      <path d="M166.64 247.44H163.81V250.27H166.64V247.44Z" fill="#F5F5F5" />
      <path d="M162.14 218.94H159.31V221.77H162.14V218.94Z" fill="#F5F5F5" />
      <path d="M162.14 223.69H159.31V226.52H162.14V223.69Z" fill="#F5F5F5" />
      <path d="M162.14 228.44H159.31V231.27H162.14V228.44Z" fill="#F5F5F5" />
      <path d="M162.14 233.19H159.31V236.02H162.14V233.19Z" fill="#F5F5F5" />
      <path d="M162.14 237.94H159.31V240.77H162.14V237.94Z" fill="#F5F5F5" />
      <path d="M162.14 242.69H159.31V245.52H162.14V242.69Z" fill="#F5F5F5" />
      <path d="M162.14 247.44H159.31V250.27H162.14V247.44Z" fill="#F5F5F5" />
      <path d="M157.64 218.94H154.81V221.77H157.64V218.94Z" fill="#F5F5F5" />
      <path d="M157.64 223.69H154.81V226.52H157.64V223.69Z" fill="#F5F5F5" />
      <path d="M157.64 228.44H154.81V231.27H157.64V228.44Z" fill="#F5F5F5" />
      <path d="M157.64 233.19H154.81V236.02H157.64V233.19Z" fill="#F5F5F5" />
      <path d="M157.64 237.94H154.81V240.77H157.64V237.94Z" fill="#F5F5F5" />
      <path d="M157.64 242.69H154.81V245.52H157.64V242.69Z" fill="#F5F5F5" />
      <path d="M157.64 247.44H154.81V250.27H157.64V247.44Z" fill="#F5F5F5" />
      <path d="M157.64 252.19H154.81V255.02H157.64V252.19Z" fill="#F5F5F5" />
      <path d="M157.64 256.94H154.81V259.77H157.64V256.94Z" fill="#F5F5F5" />
      <path d="M157.64 261.69H154.81V264.52H157.64V261.69Z" fill="#F5F5F5" />
      <path d="M157.64 266.44H154.81V269.27H157.64V266.44Z" fill="#F5F5F5" />
      <path d="M153.14 218.94H150.31V221.77H153.14V218.94Z" fill="#F5F5F5" />
      <path d="M153.14 223.69H150.31V226.52H153.14V223.69Z" fill="#F5F5F5" />
      <path d="M153.14 228.44H150.31V231.27H153.14V228.44Z" fill="#F5F5F5" />
      <path d="M153.14 233.19H150.31V236.02H153.14V233.19Z" fill="#F5F5F5" />
      <path d="M153.14 237.94H150.31V240.77H153.14V237.94Z" fill="#F5F5F5" />
      <path d="M153.14 242.69H150.31V245.52H153.14V242.69Z" fill="#F5F5F5" />
      <path d="M153.14 247.44H150.31V250.27H153.14V247.44Z" fill="#F5F5F5" />
      <path d="M153.14 252.19H150.31V255.02H153.14V252.19Z" fill="#F5F5F5" />
      <path d="M153.14 256.94H150.31V259.77H153.14V256.94Z" fill="#F5F5F5" />
      <path d="M153.14 261.69H150.31V264.52H153.14V261.69Z" fill="#F5F5F5" />
      <path d="M153.14 266.44H150.31V269.27H153.14V266.44Z" fill="#F5F5F5" />
      <path d="M153.14 271.19H150.31V274.02H153.14V271.19Z" fill="#F5F5F5" />
      <path d="M153.14 285.44H150.31V288.27H153.14V285.44Z" fill="#F5F5F5" />
      <path d="M153.14 290.19H150.31V293.02H153.14V290.19Z" fill="#F5F5F5" />
      <path d="M148.64 218.94H145.81V221.77H148.64V218.94Z" fill="#F5F5F5" />
      <path d="M148.64 223.69H145.81V226.52H148.64V223.69Z" fill="#F5F5F5" />
      <path d="M148.64 228.44H145.81V231.27H148.64V228.44Z" fill="#F5F5F5" />
      <path d="M148.64 242.69H145.81V245.52H148.64V242.69Z" fill="#F5F5F5" />
      <path d="M148.64 247.44H145.81V250.27H148.64V247.44Z" fill="#F5F5F5" />
      <path d="M148.64 252.19H145.81V255.02H148.64V252.19Z" fill="#F5F5F5" />
      <path d="M148.64 256.94H145.81V259.77H148.64V256.94Z" fill="#F5F5F5" />
      <path d="M148.64 261.69H145.81V264.52H148.64V261.69Z" fill="#F5F5F5" />
      <path d="M148.64 266.44H145.81V269.27H148.64V266.44Z" fill="#F5F5F5" />
      <path d="M148.64 271.19H145.81V274.02H148.64V271.19Z" fill="#F5F5F5" />
      <path d="M148.64 285.44H145.81V288.27H148.64V285.44Z" fill="#F5F5F5" />
      <path d="M148.64 290.19H145.81V293.02H148.64V290.19Z" fill="#F5F5F5" />
      <path d="M148.64 294.94H145.81V297.77H148.64V294.94Z" fill="#F5F5F5" />
      <path d="M101.48 190.44H98.6504V193.27H101.48V190.44Z" fill="#F5F5F5" />
      <path d="M101.48 195.19H98.6504V198.02H101.48V195.19Z" fill="#F5F5F5" />
      <path d="M101.48 199.94H98.6504V202.77H101.48V199.94Z" fill="#F5F5F5" />
      <path d="M101.48 204.69H98.6504V207.52H101.48V204.69Z" fill="#F5F5F5" />
      <path d="M101.48 209.44H98.6504V212.27H101.48V209.44Z" fill="#F5F5F5" />
      <path d="M101.48 214.19H98.6504V217.02H101.48V214.19Z" fill="#F5F5F5" />
      <path d="M101.48 218.94H98.6504V221.77H101.48V218.94Z" fill="#F5F5F5" />
      <path d="M101.48 223.69H98.6504V226.52H101.48V223.69Z" fill="#F5F5F5" />
      <path d="M101.48 228.44H98.6504V231.27H101.48V228.44Z" fill="#F5F5F5" />
      <path d="M101.48 233.19H98.6504V236.02H101.48V233.19Z" fill="#F5F5F5" />
      <path d="M101.48 237.94H98.6504V240.77H101.48V237.94Z" fill="#F5F5F5" />
      <path d="M101.48 242.69H98.6504V245.52H101.48V242.69Z" fill="#F5F5F5" />
      <path d="M101.48 247.44H98.6504V250.27H101.48V247.44Z" fill="#F5F5F5" />
      <path d="M101.48 252.19H98.6504V255.02H101.48V252.19Z" fill="#F5F5F5" />
      <path d="M101.48 256.94H98.6504V259.77H101.48V256.94Z" fill="#F5F5F5" />
      <path d="M101.48 261.69H98.6504V264.52H101.48V261.69Z" fill="#F5F5F5" />
      <path d="M101.48 266.44H98.6504V269.27H101.48V266.44Z" fill="#F5F5F5" />
      <path d="M101.48 271.19H98.6504V274.02H101.48V271.19Z" fill="#F5F5F5" />
      <path d="M101.48 275.94H98.6504V278.77H101.48V275.94Z" fill="#F5F5F5" />
      <path d="M101.48 280.69H98.6504V283.52H101.48V280.69Z" fill="#F5F5F5" />
      <path d="M101.48 285.44H98.6504V288.27H101.48V285.44Z" fill="#F5F5F5" />
      <path d="M101.48 290.19H98.6504V293.02H101.48V290.19Z" fill="#F5F5F5" />
      <path d="M101.48 294.94H98.6504V297.77H101.48V294.94Z" fill="#F5F5F5" />
      <path d="M96.9804 190.44H94.1504V193.27H96.9804V190.44Z" fill="#F5F5F5" />
      <path d="M96.9804 195.19H94.1504V198.02H96.9804V195.19Z" fill="#F5F5F5" />
      <path d="M96.9804 199.94H94.1504V202.77H96.9804V199.94Z" fill="#F5F5F5" />
      <path d="M96.9804 204.69H94.1504V207.52H96.9804V204.69Z" fill="#F5F5F5" />
      <path d="M96.9804 209.44H94.1504V212.27H96.9804V209.44Z" fill="#F5F5F5" />
      <path d="M96.9804 214.19H94.1504V217.02H96.9804V214.19Z" fill="#F5F5F5" />
      <path d="M96.9804 218.94H94.1504V221.77H96.9804V218.94Z" fill="#F5F5F5" />
      <path d="M96.9804 223.69H94.1504V226.52H96.9804V223.69Z" fill="#F5F5F5" />
      <path d="M96.9804 228.44H94.1504V231.27H96.9804V228.44Z" fill="#F5F5F5" />
      <path d="M96.9804 233.19H94.1504V236.02H96.9804V233.19Z" fill="#F5F5F5" />
      <path d="M96.9804 237.94H94.1504V240.77H96.9804V237.94Z" fill="#F5F5F5" />
      <path d="M96.9804 242.69H94.1504V245.52H96.9804V242.69Z" fill="#F5F5F5" />
      <path d="M96.9804 247.44H94.1504V250.27H96.9804V247.44Z" fill="#F5F5F5" />
      <path d="M96.9804 252.19H94.1504V255.02H96.9804V252.19Z" fill="#F5F5F5" />
      <path d="M96.9804 256.94H94.1504V259.77H96.9804V256.94Z" fill="#F5F5F5" />
      <path d="M96.9804 261.69H94.1504V264.52H96.9804V261.69Z" fill="#F5F5F5" />
      <path d="M96.9804 266.44H94.1504V269.27H96.9804V266.44Z" fill="#F5F5F5" />
      <path d="M96.9804 271.19H94.1504V274.02H96.9804V271.19Z" fill="#F5F5F5" />
      <path d="M96.9804 275.94H94.1504V278.77H96.9804V275.94Z" fill="#F5F5F5" />
      <path d="M92.4804 190.44H89.6504V193.27H92.4804V190.44Z" fill="#F5F5F5" />
      <path d="M92.4804 195.19H89.6504V198.02H92.4804V195.19Z" fill="#F5F5F5" />
      <path d="M92.4804 199.94H89.6504V202.77H92.4804V199.94Z" fill="#F5F5F5" />
      <path d="M92.4804 204.69H89.6504V207.52H92.4804V204.69Z" fill="#F5F5F5" />
      <path d="M92.4804 209.44H89.6504V212.27H92.4804V209.44Z" fill="#F5F5F5" />
      <path d="M92.4804 214.19H89.6504V217.02H92.4804V214.19Z" fill="#F5F5F5" />
      <path d="M92.4804 218.94H89.6504V221.77H92.4804V218.94Z" fill="#F5F5F5" />
      <path d="M92.4804 223.69H89.6504V226.52H92.4804V223.69Z" fill="#F5F5F5" />
      <path d="M92.4804 228.44H89.6504V231.27H92.4804V228.44Z" fill="#F5F5F5" />
      <path d="M92.4804 233.19H89.6504V236.02H92.4804V233.19Z" fill="#F5F5F5" />
      <path d="M92.4804 237.94H89.6504V240.77H92.4804V237.94Z" fill="#F5F5F5" />
      <path d="M92.4804 242.69H89.6504V245.52H92.4804V242.69Z" fill="#F5F5F5" />
      <path d="M92.4804 247.44H89.6504V250.27H92.4804V247.44Z" fill="#F5F5F5" />
      <path d="M92.4804 252.19H89.6504V255.02H92.4804V252.19Z" fill="#F5F5F5" />
      <path d="M92.4804 256.94H89.6504V259.77H92.4804V256.94Z" fill="#F5F5F5" />
      <path d="M92.4804 261.69H89.6504V264.52H92.4804V261.69Z" fill="#F5F5F5" />
      <path d="M92.4804 266.44H89.6504V269.27H92.4804V266.44Z" fill="#F5F5F5" />
      <path d="M92.4804 271.19H89.6504V274.02H92.4804V271.19Z" fill="#F5F5F5" />
      <path d="M92.4804 275.94H89.6504V278.77H92.4804V275.94Z" fill="#F5F5F5" />
      <path d="M87.9804 190.44H85.1504V193.27H87.9804V190.44Z" fill="#F5F5F5" />
      <path d="M87.9804 195.19H85.1504V198.02H87.9804V195.19Z" fill="#F5F5F5" />
      <path d="M87.9804 199.94H85.1504V202.77H87.9804V199.94Z" fill="#F5F5F5" />
      <path d="M87.9804 204.69H85.1504V207.52H87.9804V204.69Z" fill="#F5F5F5" />
      <path d="M87.9804 209.44H85.1504V212.27H87.9804V209.44Z" fill="#F5F5F5" />
      <path d="M87.9804 214.19H85.1504V217.02H87.9804V214.19Z" fill="#F5F5F5" />
      <path d="M87.9804 218.94H85.1504V221.77H87.9804V218.94Z" fill="#F5F5F5" />
      <path d="M87.9804 223.69H85.1504V226.52H87.9804V223.69Z" fill="#F5F5F5" />
      <path d="M87.9804 228.44H85.1504V231.27H87.9804V228.44Z" fill="#F5F5F5" />
      <path d="M87.9804 233.19H85.1504V236.02H87.9804V233.19Z" fill="#F5F5F5" />
      <path d="M87.9804 237.94H85.1504V240.77H87.9804V237.94Z" fill="#F5F5F5" />
      <path d="M87.9804 242.69H85.1504V245.52H87.9804V242.69Z" fill="#F5F5F5" />
      <path d="M87.9804 247.44H85.1504V250.27H87.9804V247.44Z" fill="#F5F5F5" />
      <path d="M87.9804 252.19H85.1504V255.02H87.9804V252.19Z" fill="#F5F5F5" />
      <path d="M87.9804 256.94H85.1504V259.77H87.9804V256.94Z" fill="#F5F5F5" />
      <path d="M83.4804 190.44H80.6504V193.27H83.4804V190.44Z" fill="#F5F5F5" />
      <path d="M83.4804 195.19H80.6504V198.02H83.4804V195.19Z" fill="#F5F5F5" />
      <path d="M83.4804 199.94H80.6504V202.77H83.4804V199.94Z" fill="#F5F5F5" />
      <path d="M83.4804 204.69H80.6504V207.52H83.4804V204.69Z" fill="#F5F5F5" />
      <path d="M83.4804 209.44H80.6504V212.27H83.4804V209.44Z" fill="#F5F5F5" />
      <path d="M83.4804 214.19H80.6504V217.02H83.4804V214.19Z" fill="#F5F5F5" />
      <path d="M83.4804 218.94H80.6504V221.77H83.4804V218.94Z" fill="#F5F5F5" />
      <path d="M83.4804 223.69H80.6504V226.52H83.4804V223.69Z" fill="#F5F5F5" />
      <path d="M83.4804 228.44H80.6504V231.27H83.4804V228.44Z" fill="#F5F5F5" />
      <path d="M83.4804 233.19H80.6504V236.02H83.4804V233.19Z" fill="#F5F5F5" />
      <path d="M83.4804 237.94H80.6504V240.77H83.4804V237.94Z" fill="#F5F5F5" />
      <path d="M83.4804 242.69H80.6504V245.52H83.4804V242.69Z" fill="#F5F5F5" />
      <path d="M83.4804 247.44H80.6504V250.27H83.4804V247.44Z" fill="#F5F5F5" />
      <path d="M78.9804 190.44H76.1504V193.27H78.9804V190.44Z" fill="#F5F5F5" />
      <path d="M78.9804 195.19H76.1504V198.02H78.9804V195.19Z" fill="#F5F5F5" />
      <path d="M78.9804 199.94H76.1504V202.77H78.9804V199.94Z" fill="#F5F5F5" />
      <path d="M78.9804 204.69H76.1504V207.52H78.9804V204.69Z" fill="#F5F5F5" />
      <path d="M78.9804 209.44H76.1504V212.27H78.9804V209.44Z" fill="#F5F5F5" />
      <path d="M78.9804 214.19H76.1504V217.02H78.9804V214.19Z" fill="#F5F5F5" />
      <path d="M78.9804 218.94H76.1504V221.77H78.9804V218.94Z" fill="#F5F5F5" />
      <path d="M78.9804 223.69H76.1504V226.52H78.9804V223.69Z" fill="#F5F5F5" />
      <path d="M78.9804 228.44H76.1504V231.27H78.9804V228.44Z" fill="#F5F5F5" />
      <path d="M78.9804 233.19H76.1504V236.02H78.9804V233.19Z" fill="#F5F5F5" />
      <path d="M78.9804 237.94H76.1504V240.77H78.9804V237.94Z" fill="#F5F5F5" />
      <path d="M78.9804 242.69H76.1504V245.52H78.9804V242.69Z" fill="#F5F5F5" />
      <path d="M409.64 225.11H406.81V227.94H409.64V225.11Z" fill="#F5F5F5" />
      <path d="M409.64 229.86H406.81V232.69H409.64V229.86Z" fill="#F5F5F5" />
      <path d="M409.64 234.61H406.81V237.44H409.64V234.61Z" fill="#F5F5F5" />
      <path d="M409.64 239.36H406.81V242.19H409.64V239.36Z" fill="#F5F5F5" />
      <path d="M409.64 244.11H406.81V246.94H409.64V244.11Z" fill="#F5F5F5" />
      <path d="M409.64 248.86H406.81V251.69H409.64V248.86Z" fill="#F5F5F5" />
      <path d="M409.64 253.61H406.81V256.44H409.64V253.61Z" fill="#F5F5F5" />
      <path d="M409.64 258.36H406.81V261.19H409.64V258.36Z" fill="#F5F5F5" />
      <path d="M409.64 263.11H406.81V265.94H409.64V263.11Z" fill="#F5F5F5" />
      <path d="M409.64 267.86H406.81V270.69H409.64V267.86Z" fill="#F5F5F5" />
      <path d="M409.64 272.61H406.81V275.44H409.64V272.61Z" fill="#F5F5F5" />
      <path d="M409.64 277.36H406.81V280.19H409.64V277.36Z" fill="#F5F5F5" />
      <path d="M409.64 282.11H406.81V284.94H409.64V282.11Z" fill="#F5F5F5" />
      <path d="M409.64 286.86H406.81V289.69H409.64V286.86Z" fill="#F5F5F5" />
      <path d="M409.64 291.61H406.81V294.44H409.64V291.61Z" fill="#F5F5F5" />
      <path d="M409.64 296.36H406.81V299.19H409.64V296.36Z" fill="#F5F5F5" />
      <path d="M409.64 301.11H406.81V303.94H409.64V301.11Z" fill="#F5F5F5" />
      <path d="M409.64 305.86H406.81V308.69H409.64V305.86Z" fill="#F5F5F5" />
      <path d="M409.64 310.61H406.81V313.44H409.64V310.61Z" fill="#F5F5F5" />
      <path d="M409.64 315.36H406.81V318.19H409.64V315.36Z" fill="#F5F5F5" />
      <path d="M405.14 225.11H402.31V227.94H405.14V225.11Z" fill="#F5F5F5" />
      <path d="M405.14 229.86H402.31V232.69H405.14V229.86Z" fill="#F5F5F5" />
      <path d="M405.14 234.61H402.31V237.44H405.14V234.61Z" fill="#F5F5F5" />
      <path d="M405.14 239.36H402.31V242.19H405.14V239.36Z" fill="#F5F5F5" />
      <path d="M405.14 244.11H402.31V246.94H405.14V244.11Z" fill="#F5F5F5" />
      <path d="M405.14 248.86H402.31V251.69H405.14V248.86Z" fill="#F5F5F5" />
      <path d="M405.14 253.61H402.31V256.44H405.14V253.61Z" fill="#F5F5F5" />
      <path d="M405.14 258.36H402.31V261.19H405.14V258.36Z" fill="#F5F5F5" />
      <path d="M405.14 263.11H402.31V265.94H405.14V263.11Z" fill="#F5F5F5" />
      <path d="M405.14 267.86H402.31V270.69H405.14V267.86Z" fill="#F5F5F5" />
      <path d="M405.14 272.61H402.31V275.44H405.14V272.61Z" fill="#F5F5F5" />
      <path d="M405.14 277.36H402.31V280.19H405.14V277.36Z" fill="#F5F5F5" />
      <path d="M405.14 282.11H402.31V284.94H405.14V282.11Z" fill="#F5F5F5" />
      <path d="M405.14 286.86H402.31V289.69H405.14V286.86Z" fill="#F5F5F5" />
      <path d="M405.14 291.61H402.31V294.44H405.14V291.61Z" fill="#F5F5F5" />
      <path d="M405.14 296.36H402.31V299.19H405.14V296.36Z" fill="#F5F5F5" />
      <path d="M405.14 301.11H402.31V303.94H405.14V301.11Z" fill="#F5F5F5" />
      <path d="M405.14 305.86H402.31V308.69H405.14V305.86Z" fill="#F5F5F5" />
      <path d="M400.64 225.11H397.81V227.94H400.64V225.11Z" fill="#F5F5F5" />
      <path d="M400.64 229.86H397.81V232.69H400.64V229.86Z" fill="#F5F5F5" />
      <path d="M400.64 234.61H397.81V237.44H400.64V234.61Z" fill="#F5F5F5" />
      <path d="M400.64 239.36H397.81V242.19H400.64V239.36Z" fill="#F5F5F5" />
      <path d="M400.64 244.11H397.81V246.94H400.64V244.11Z" fill="#F5F5F5" />
      <path d="M400.64 248.86H397.81V251.69H400.64V248.86Z" fill="#F5F5F5" />
      <path d="M400.64 253.61H397.81V256.44H400.64V253.61Z" fill="#F5F5F5" />
      <path d="M400.64 258.36H397.81V261.19H400.64V258.36Z" fill="#F5F5F5" />
      <path d="M400.64 263.11H397.81V265.94H400.64V263.11Z" fill="#F5F5F5" />
      <path d="M400.64 267.86H397.81V270.69H400.64V267.86Z" fill="#F5F5F5" />
      <path d="M400.64 272.61H397.81V275.44H400.64V272.61Z" fill="#F5F5F5" />
      <path d="M400.64 277.36H397.81V280.19H400.64V277.36Z" fill="#F5F5F5" />
      <path d="M400.64 282.11H397.81V284.94H400.64V282.11Z" fill="#F5F5F5" />
      <path d="M400.64 286.86H397.81V289.69H400.64V286.86Z" fill="#F5F5F5" />
      <path d="M400.64 291.61H397.81V294.44H400.64V291.61Z" fill="#F5F5F5" />
      <path d="M400.64 296.36H397.81V299.19H400.64V296.36Z" fill="#F5F5F5" />
      <path d="M400.64 301.11H397.81V303.94H400.64V301.11Z" fill="#F5F5F5" />
      <path d="M400.64 305.86H397.81V308.69H400.64V305.86Z" fill="#F5F5F5" />
      <path d="M426.48 259.77H423.65V262.6H426.48V259.77Z" fill="#F5F5F5" />
      <path d="M426.48 264.52H423.65V267.35H426.48V264.52Z" fill="#F5F5F5" />
      <path d="M426.48 269.27H423.65V272.1H426.48V269.27Z" fill="#F5F5F5" />
      <path d="M426.48 274.02H423.65V276.85H426.48V274.02Z" fill="#F5F5F5" />
      <path d="M426.48 278.77H423.65V281.6H426.48V278.77Z" fill="#F5F5F5" />
      <path d="M426.48 283.52H423.65V286.35H426.48V283.52Z" fill="#F5F5F5" />
      <path d="M426.48 288.27H423.65V291.1H426.48V288.27Z" fill="#F5F5F5" />
      <path d="M426.48 293.02H423.65V295.85H426.48V293.02Z" fill="#F5F5F5" />
      <path d="M426.48 297.77H423.65V300.6H426.48V297.77Z" fill="#F5F5F5" />
      <path d="M426.48 302.52H423.65V305.35H426.48V302.52Z" fill="#F5F5F5" />
      <path d="M426.48 307.27H423.65V310.1H426.48V307.27Z" fill="#F5F5F5" />
      <path d="M426.48 312.02H423.65V314.85H426.48V312.02Z" fill="#F5F5F5" />
      <path d="M426.48 316.77H423.65V319.6H426.48V316.77Z" fill="#F5F5F5" />
      <path d="M421.98 259.77H419.15V262.6H421.98V259.77Z" fill="#F5F5F5" />
      <path d="M421.98 264.52H419.15V267.35H421.98V264.52Z" fill="#F5F5F5" />
      <path d="M421.98 269.27H419.15V272.1H421.98V269.27Z" fill="#F5F5F5" />
      <path d="M421.98 274.02H419.15V276.85H421.98V274.02Z" fill="#F5F5F5" />
      <path d="M421.98 278.77H419.15V281.6H421.98V278.77Z" fill="#F5F5F5" />
      <path d="M421.98 283.52H419.15V286.35H421.98V283.52Z" fill="#F5F5F5" />
      <path d="M421.98 288.27H419.15V291.1H421.98V288.27Z" fill="#F5F5F5" />
      <path d="M421.98 293.02H419.15V295.85H421.98V293.02Z" fill="#F5F5F5" />
      <path d="M421.98 297.77H419.15V300.6H421.98V297.77Z" fill="#F5F5F5" />
      <path d="M421.98 302.52H419.15V305.35H421.98V302.52Z" fill="#F5F5F5" />
      <path d="M421.98 307.27H419.15V310.1H421.98V307.27Z" fill="#F5F5F5" />
      <path d="M421.98 312.02H419.15V314.85H421.98V312.02Z" fill="#F5F5F5" />
      <path d="M421.98 316.77H419.15V319.6H421.98V316.77Z" fill="#F5F5F5" />
      <path d="M417.48 259.77H414.65V262.6H417.48V259.77Z" fill="#F5F5F5" />
      <path d="M417.48 264.52H414.65V267.35H417.48V264.52Z" fill="#F5F5F5" />
      <path d="M417.48 269.27H414.65V272.1H417.48V269.27Z" fill="#F5F5F5" />
      <path d="M417.48 274.02H414.65V276.85H417.48V274.02Z" fill="#F5F5F5" />
      <path d="M417.48 278.77H414.65V281.6H417.48V278.77Z" fill="#F5F5F5" />
      <path d="M417.48 283.52H414.65V286.35H417.48V283.52Z" fill="#F5F5F5" />
      <path d="M417.48 288.27H414.65V291.1H417.48V288.27Z" fill="#F5F5F5" />
      <path d="M417.48 293.02H414.65V295.85H417.48V293.02Z" fill="#F5F5F5" />
      <path d="M417.48 297.77H414.65V300.6H417.48V297.77Z" fill="#F5F5F5" />
      <path d="M417.48 302.52H414.65V305.35H417.48V302.52Z" fill="#F5F5F5" />
      <path d="M417.48 307.27H414.65V310.1H417.48V307.27Z" fill="#F5F5F5" />
      <path d="M417.48 312.02H414.65V314.85H417.48V312.02Z" fill="#F5F5F5" />
      <path d="M417.48 316.77H414.65V319.6H417.48V316.77Z" fill="#F5F5F5" />
      <path d="M417.48 321.52H414.65V324.35H417.48V321.52Z" fill="#F5F5F5" />
      <path d="M392.48 261.19H389.65V264.02H392.48V261.19Z" fill="#F5F5F5" />
      <path d="M392.48 265.94H389.65V268.77H392.48V265.94Z" fill="#F5F5F5" />
      <path d="M392.48 270.69H389.65V273.52H392.48V270.69Z" fill="#F5F5F5" />
      <path d="M392.48 275.44H389.65V278.27H392.48V275.44Z" fill="#F5F5F5" />
      <path d="M392.48 280.19H389.65V283.02H392.48V280.19Z" fill="#F5F5F5" />
      <path d="M392.48 284.94H389.65V287.77H392.48V284.94Z" fill="#F5F5F5" />
      <path d="M392.48 289.69H389.65V292.52H392.48V289.69Z" fill="#F5F5F5" />
      <path d="M392.48 294.44H389.65V297.27H392.48V294.44Z" fill="#F5F5F5" />
      <path d="M392.48 299.19H389.65V302.02H392.48V299.19Z" fill="#F5F5F5" />
      <path d="M392.48 303.94H389.65V306.77H392.48V303.94Z" fill="#F5F5F5" />
      <path d="M392.48 318.19H389.65V321.02H392.48V318.19Z" fill="#F5F5F5" />
      <path d="M387.98 261.19H385.15V264.02H387.98V261.19Z" fill="#F5F5F5" />
      <path d="M387.98 265.94H385.15V268.77H387.98V265.94Z" fill="#F5F5F5" />
      <path d="M387.98 270.69H385.15V273.52H387.98V270.69Z" fill="#F5F5F5" />
      <path d="M387.98 275.44H385.15V278.27H387.98V275.44Z" fill="#F5F5F5" />
      <path d="M387.98 280.19H385.15V283.02H387.98V280.19Z" fill="#F5F5F5" />
      <path d="M387.98 284.94H385.15V287.77H387.98V284.94Z" fill="#F5F5F5" />
      <path d="M387.98 289.69H385.15V292.52H387.98V289.69Z" fill="#F5F5F5" />
      <path d="M387.98 294.44H385.15V297.27H387.98V294.44Z" fill="#F5F5F5" />
      <path d="M387.98 299.19H385.15V302.02H387.98V299.19Z" fill="#F5F5F5" />
      <path d="M387.98 313.44H385.15V316.27H387.98V313.44Z" fill="#F5F5F5" />
      <path d="M387.98 318.19H385.15V321.02H387.98V318.19Z" fill="#F5F5F5" />
      <path d="M383.48 261.19H380.65V264.02H383.48V261.19Z" fill="#F5F5F5" />
      <path d="M383.48 265.94H380.65V268.77H383.48V265.94Z" fill="#F5F5F5" />
      <path d="M383.48 270.69H380.65V273.52H383.48V270.69Z" fill="#F5F5F5" />
      <path d="M383.48 275.44H380.65V278.27H383.48V275.44Z" fill="#F5F5F5" />
      <path d="M383.48 280.19H380.65V283.02H383.48V280.19Z" fill="#F5F5F5" />
      <path d="M383.48 284.94H380.65V287.77H383.48V284.94Z" fill="#F5F5F5" />
      <path d="M383.48 289.69H380.65V292.52H383.48V289.69Z" fill="#F5F5F5" />
      <path d="M383.48 294.44H380.65V297.27H383.48V294.44Z" fill="#F5F5F5" />
      <path d="M383.48 308.69H380.65V311.52H383.48V308.69Z" fill="#F5F5F5" />
      <path d="M383.48 313.44H380.65V316.27H383.48V313.44Z" fill="#F5F5F5" />
      <path d="M383.48 318.19H380.65V321.02H383.48V318.19Z" fill="#F5F5F5" />
      <path d="M341.64 189.02H338.81V191.85H341.64V189.02Z" fill="#F5F5F5" />
      <path d="M341.64 193.77H338.81V196.6H341.64V193.77Z" fill="#F5F5F5" />
      <path d="M341.64 198.52H338.81V201.35H341.64V198.52Z" fill="#F5F5F5" />
      <path d="M341.64 203.27H338.81V206.1H341.64V203.27Z" fill="#F5F5F5" />
      <path d="M341.64 208.02H338.81V210.85H341.64V208.02Z" fill="#F5F5F5" />
      <path d="M341.64 212.77H338.81V215.6H341.64V212.77Z" fill="#F5F5F5" />
      <path d="M341.64 217.52H338.81V220.35H341.64V217.52Z" fill="#F5F5F5" />
      <path d="M341.64 222.27H338.81V225.1H341.64V222.27Z" fill="#F5F5F5" />
      <path d="M341.64 227.02H338.81V229.85H341.64V227.02Z" fill="#F5F5F5" />
      <path d="M341.64 231.77H338.81V234.6H341.64V231.77Z" fill="#F5F5F5" />
      <path d="M341.64 236.52H338.81V239.35H341.64V236.52Z" fill="#F5F5F5" />
      <path d="M341.64 241.27H338.81V244.1H341.64V241.27Z" fill="#F5F5F5" />
      <path d="M341.64 246.02H338.81V248.85H341.64V246.02Z" fill="#F5F5F5" />
      <path d="M341.64 250.77H338.81V253.6H341.64V250.77Z" fill="#F5F5F5" />
      <path d="M341.64 255.52H338.81V258.35H341.64V255.52Z" fill="#F5F5F5" />
      <path d="M341.64 260.27H338.81V263.1H341.64V260.27Z" fill="#F5F5F5" />
      <path d="M341.64 265.02H338.81V267.85H341.64V265.02Z" fill="#F5F5F5" />
      <path d="M341.64 274.52H338.81V277.35H341.64V274.52Z" fill="#F5F5F5" />
      <path d="M341.64 279.27H338.81V282.1H341.64V279.27Z" fill="#F5F5F5" />
      <path d="M341.64 284.02H338.81V286.85H341.64V284.02Z" fill="#F5F5F5" />
      <path d="M341.64 288.77H338.81V291.6H341.64V288.77Z" fill="#F5F5F5" />
      <path d="M341.64 293.52H338.81V296.35H341.64V293.52Z" fill="#F5F5F5" />
      <path d="M337.14 189.02H334.31V191.85H337.14V189.02Z" fill="#F5F5F5" />
      <path d="M337.14 193.77H334.31V196.6H337.14V193.77Z" fill="#F5F5F5" />
      <path d="M337.14 198.52H334.31V201.35H337.14V198.52Z" fill="#F5F5F5" />
      <path d="M337.14 203.27H334.31V206.1H337.14V203.27Z" fill="#F5F5F5" />
      <path d="M337.14 208.02H334.31V210.85H337.14V208.02Z" fill="#F5F5F5" />
      <path d="M337.14 212.77H334.31V215.6H337.14V212.77Z" fill="#F5F5F5" />
      <path d="M337.14 217.52H334.31V220.35H337.14V217.52Z" fill="#F5F5F5" />
      <path d="M337.14 222.27H334.31V225.1H337.14V222.27Z" fill="#F5F5F5" />
      <path d="M337.14 227.02H334.31V229.85H337.14V227.02Z" fill="#F5F5F5" />
      <path d="M337.14 231.77H334.31V234.6H337.14V231.77Z" fill="#F5F5F5" />
      <path d="M337.14 236.52H334.31V239.35H337.14V236.52Z" fill="#F5F5F5" />
      <path d="M337.14 241.27H334.31V244.1H337.14V241.27Z" fill="#F5F5F5" />
      <path d="M337.14 246.02H334.31V248.85H337.14V246.02Z" fill="#F5F5F5" />
      <path d="M337.14 250.77H334.31V253.6H337.14V250.77Z" fill="#F5F5F5" />
      <path d="M337.14 255.52H334.31V258.35H337.14V255.52Z" fill="#F5F5F5" />
      <path d="M337.14 260.27H334.31V263.1H337.14V260.27Z" fill="#F5F5F5" />
      <path d="M337.14 288.77H334.31V291.6H337.14V288.77Z" fill="#F5F5F5" />
      <path d="M337.14 293.52H334.31V296.35H337.14V293.52Z" fill="#F5F5F5" />
      <path d="M332.64 189.02H329.81V191.85H332.64V189.02Z" fill="#F5F5F5" />
      <path d="M332.64 193.77H329.81V196.6H332.64V193.77Z" fill="#F5F5F5" />
      <path d="M332.64 198.52H329.81V201.35H332.64V198.52Z" fill="#F5F5F5" />
      <path d="M332.64 203.27H329.81V206.1H332.64V203.27Z" fill="#F5F5F5" />
      <path d="M332.64 208.02H329.81V210.85H332.64V208.02Z" fill="#F5F5F5" />
      <path d="M332.64 212.77H329.81V215.6H332.64V212.77Z" fill="#F5F5F5" />
      <path d="M332.64 217.52H329.81V220.35H332.64V217.52Z" fill="#F5F5F5" />
      <path d="M332.64 222.27H329.81V225.1H332.64V222.27Z" fill="#F5F5F5" />
      <path d="M332.64 227.02H329.81V229.85H332.64V227.02Z" fill="#F5F5F5" />
      <path d="M332.64 231.77H329.81V234.6H332.64V231.77Z" fill="#F5F5F5" />
      <path d="M332.64 236.52H329.81V239.35H332.64V236.52Z" fill="#F5F5F5" />
      <path d="M332.64 241.27H329.81V244.1H332.64V241.27Z" fill="#F5F5F5" />
      <path d="M332.64 246.02H329.81V248.85H332.64V246.02Z" fill="#F5F5F5" />
      <path d="M332.64 250.77H329.81V253.6H332.64V250.77Z" fill="#F5F5F5" />
      <path d="M332.64 255.52H329.81V258.35H332.64V255.52Z" fill="#F5F5F5" />
      <path d="M332.64 260.27H329.81V263.1H332.64V260.27Z" fill="#F5F5F5" />
      <path d="M332.64 269.77H329.81V272.6H332.64V269.77Z" fill="#F5F5F5" />
      <path d="M332.64 274.52H329.81V277.35H332.64V274.52Z" fill="#F5F5F5" />
      <path d="M332.64 279.27H329.81V282.1H332.64V279.27Z" fill="#F5F5F5" />
      <path d="M332.64 284.02H329.81V286.85H332.64V284.02Z" fill="#F5F5F5" />
      <path d="M332.64 293.52H329.81V296.35H332.64V293.52Z" fill="#F5F5F5" />
      <path d="M357.98 248.86H355.15V251.69H357.98V248.86Z" fill="#F5F5F5" />
      <path d="M357.98 253.61H355.15V256.44H357.98V253.61Z" fill="#F5F5F5" />
      <path d="M357.98 258.36H355.15V261.19H357.98V258.36Z" fill="#F5F5F5" />
      <path d="M357.98 263.11H355.15V265.94H357.98V263.11Z" fill="#F5F5F5" />
      <path d="M357.98 267.86H355.15V270.69H357.98V267.86Z" fill="#F5F5F5" />
      <path d="M357.98 272.61H355.15V275.44H357.98V272.61Z" fill="#F5F5F5" />
      <path d="M357.98 277.36H355.15V280.19H357.98V277.36Z" fill="#F5F5F5" />
      <path d="M357.98 286.86H355.15V289.69H357.98V286.86Z" fill="#F5F5F5" />
      <path d="M357.98 291.61H355.15V294.44H357.98V291.61Z" fill="#F5F5F5" />
      <path d="M357.98 296.36H355.15V299.19H357.98V296.36Z" fill="#F5F5F5" />
      <path d="M357.98 301.11H355.15V303.94H357.98V301.11Z" fill="#F5F5F5" />
      <path d="M357.98 305.86H355.15V308.69H357.98V305.86Z" fill="#F5F5F5" />
      <path d="M357.98 310.61H355.15V313.44H357.98V310.61Z" fill="#F5F5F5" />
      <path d="M357.98 315.36H355.15V318.19H357.98V315.36Z" fill="#F5F5F5" />
      <path d="M357.98 320.11H355.15V322.94H357.98V320.11Z" fill="#F5F5F5" />
      <path d="M353.48 248.86H350.65V251.69H353.48V248.86Z" fill="#F5F5F5" />
      <path d="M353.48 253.61H350.65V256.44H353.48V253.61Z" fill="#F5F5F5" />
      <path d="M353.48 258.36H350.65V261.19H353.48V258.36Z" fill="#F5F5F5" />
      <path d="M353.48 263.11H350.65V265.94H353.48V263.11Z" fill="#F5F5F5" />
      <path d="M353.48 267.86H350.65V270.69H353.48V267.86Z" fill="#F5F5F5" />
      <path d="M353.48 272.61H350.65V275.44H353.48V272.61Z" fill="#F5F5F5" />
      <path d="M353.48 286.86H350.65V289.69H353.48V286.86Z" fill="#F5F5F5" />
      <path d="M353.48 291.61H350.65V294.44H353.48V291.61Z" fill="#F5F5F5" />
      <path d="M353.48 296.36H350.65V299.19H353.48V296.36Z" fill="#F5F5F5" />
      <path d="M353.48 301.11H350.65V303.94H353.48V301.11Z" fill="#F5F5F5" />
      <path d="M353.48 305.86H350.65V308.69H353.48V305.86Z" fill="#F5F5F5" />
      <path d="M353.48 310.61H350.65V313.44H353.48V310.61Z" fill="#F5F5F5" />
      <path d="M353.48 315.36H350.65V318.19H353.48V315.36Z" fill="#F5F5F5" />
      <path d="M353.48 320.11H350.65V322.94H353.48V320.11Z" fill="#F5F5F5" />
      <path d="M348.98 248.86H346.15V251.69H348.98V248.86Z" fill="#F5F5F5" />
      <path d="M348.98 253.61H346.15V256.44H348.98V253.61Z" fill="#F5F5F5" />
      <path d="M348.98 258.36H346.15V261.19H348.98V258.36Z" fill="#F5F5F5" />
      <path d="M348.98 263.11H346.15V265.94H348.98V263.11Z" fill="#F5F5F5" />
      <path d="M348.98 267.86H346.15V270.69H348.98V267.86Z" fill="#F5F5F5" />
      <path d="M348.98 272.61H346.15V275.44H348.98V272.61Z" fill="#F5F5F5" />
      <path d="M348.98 282.11H346.15V284.94H348.98V282.11Z" fill="#F5F5F5" />
      <path d="M348.98 286.86H346.15V289.69H348.98V286.86Z" fill="#F5F5F5" />
      <path d="M348.98 291.61H346.15V294.44H348.98V291.61Z" fill="#F5F5F5" />
      <path d="M348.98 296.36H346.15V299.19H348.98V296.36Z" fill="#F5F5F5" />
      <path d="M348.98 301.11H346.15V303.94H348.98V301.11Z" fill="#F5F5F5" />
      <path d="M348.98 305.86H346.15V308.69H348.98V305.86Z" fill="#F5F5F5" />
      <path d="M348.98 310.61H346.15V313.44H348.98V310.61Z" fill="#F5F5F5" />
      <path d="M348.98 315.36H346.15V318.19H348.98V315.36Z" fill="#F5F5F5" />
      <path d="M348.98 320.11H346.15V322.94H348.98V320.11Z" fill="#F5F5F5" />
      <path d="M254.14 222.27H251.31V225.1H254.14V222.27Z" fill="#F5F5F5" />
      <path d="M254.14 227.02H251.31V229.85H254.14V227.02Z" fill="#F5F5F5" />
      <path d="M254.14 231.77H251.31V234.6H254.14V231.77Z" fill="#F5F5F5" />
      <path d="M254.14 236.52H251.31V239.35H254.14V236.52Z" fill="#F5F5F5" />
      <path d="M254.14 241.27H251.31V244.1H254.14V241.27Z" fill="#F5F5F5" />
      <path d="M254.14 246.02H251.31V248.85H254.14V246.02Z" fill="#F5F5F5" />
      <path d="M254.14 250.77H251.31V253.6H254.14V250.77Z" fill="#F5F5F5" />
      <path d="M254.14 255.52H251.31V258.35H254.14V255.52Z" fill="#F5F5F5" />
      <path d="M254.14 260.27H251.31V263.1H254.14V260.27Z" fill="#F5F5F5" />
      <path d="M254.14 265.02H251.31V267.85H254.14V265.02Z" fill="#F5F5F5" />
      <path d="M254.14 269.77H251.31V272.6H254.14V269.77Z" fill="#F5F5F5" />
      <path d="M254.14 274.52H251.31V277.35H254.14V274.52Z" fill="#F5F5F5" />
      <path d="M254.14 279.27H251.31V282.1H254.14V279.27Z" fill="#F5F5F5" />
      <path d="M254.14 288.77H251.31V291.6H254.14V288.77Z" fill="#F5F5F5" />
      <path d="M254.14 293.52H251.31V296.35H254.14V293.52Z" fill="#F5F5F5" />
      <path d="M254.14 298.27H251.31V301.1H254.14V298.27Z" fill="#F5F5F5" />
      <path d="M254.14 303.02H251.31V305.85H254.14V303.02Z" fill="#F5F5F5" />
      <path d="M254.14 312.52H251.31V315.35H254.14V312.52Z" fill="#F5F5F5" />
      <path d="M254.14 317.27H251.31V320.1H254.14V317.27Z" fill="#F5F5F5" />
      <path d="M254.14 322.02H251.31V324.85H254.14V322.02Z" fill="#F5F5F5" />
      <path d="M249.64 222.27H246.81V225.1H249.64V222.27Z" fill="#F5F5F5" />
      <path d="M249.64 227.02H246.81V229.85H249.64V227.02Z" fill="#F5F5F5" />
      <path d="M249.64 231.77H246.81V234.6H249.64V231.77Z" fill="#F5F5F5" />
      <path d="M249.64 236.52H246.81V239.35H249.64V236.52Z" fill="#F5F5F5" />
      <path d="M249.64 241.27H246.81V244.1H249.64V241.27Z" fill="#F5F5F5" />
      <path d="M249.64 246.02H246.81V248.85H249.64V246.02Z" fill="#F5F5F5" />
      <path d="M249.64 250.77H246.81V253.6H249.64V250.77Z" fill="#F5F5F5" />
      <path d="M249.64 255.52H246.81V258.35H249.64V255.52Z" fill="#F5F5F5" />
      <path d="M249.64 260.27H246.81V263.1H249.64V260.27Z" fill="#F5F5F5" />
      <path d="M249.64 265.02H246.81V267.85H249.64V265.02Z" fill="#F5F5F5" />
      <path d="M249.64 269.77H246.81V272.6H249.64V269.77Z" fill="#F5F5F5" />
      <path d="M249.64 274.52H246.81V277.35H249.64V274.52Z" fill="#F5F5F5" />
      <path d="M249.64 279.27H246.81V282.1H249.64V279.27Z" fill="#F5F5F5" />
      <path d="M249.64 293.52H246.81V296.35H249.64V293.52Z" fill="#F5F5F5" />
      <path d="M249.64 298.27H246.81V301.1H249.64V298.27Z" fill="#F5F5F5" />
      <path d="M249.64 307.77H246.81V310.6H249.64V307.77Z" fill="#F5F5F5" />
      <path d="M249.64 312.52H246.81V315.35H249.64V312.52Z" fill="#F5F5F5" />
      <path d="M249.64 317.27H246.81V320.1H249.64V317.27Z" fill="#F5F5F5" />
      <path d="M249.64 322.02H246.81V324.85H249.64V322.02Z" fill="#F5F5F5" />
      <path d="M245.14 222.27H242.31V225.1H245.14V222.27Z" fill="#F5F5F5" />
      <path d="M245.14 227.02H242.31V229.85H245.14V227.02Z" fill="#F5F5F5" />
      <path d="M245.14 231.77H242.31V234.6H245.14V231.77Z" fill="#F5F5F5" />
      <path d="M245.14 236.52H242.31V239.35H245.14V236.52Z" fill="#F5F5F5" />
      <path d="M245.14 241.27H242.31V244.1H245.14V241.27Z" fill="#F5F5F5" />
      <path d="M245.14 246.02H242.31V248.85H245.14V246.02Z" fill="#F5F5F5" />
      <path d="M245.14 250.77H242.31V253.6H245.14V250.77Z" fill="#F5F5F5" />
      <path d="M245.14 255.52H242.31V258.35H245.14V255.52Z" fill="#F5F5F5" />
      <path d="M245.14 260.27H242.31V263.1H245.14V260.27Z" fill="#F5F5F5" />
      <path d="M245.14 265.02H242.31V267.85H245.14V265.02Z" fill="#F5F5F5" />
      <path d="M245.14 269.77H242.31V272.6H245.14V269.77Z" fill="#F5F5F5" />
      <path d="M245.14 274.52H242.31V277.35H245.14V274.52Z" fill="#F5F5F5" />
      <path d="M245.14 279.27H242.31V282.1H245.14V279.27Z" fill="#F5F5F5" />
      <path d="M245.14 284.02H242.31V286.85H245.14V284.02Z" fill="#F5F5F5" />
      <path d="M245.14 293.52H242.31V296.35H245.14V293.52Z" fill="#F5F5F5" />
      <path d="M245.14 303.02H242.31V305.85H245.14V303.02Z" fill="#F5F5F5" />
      <path d="M245.14 307.77H242.31V310.6H245.14V307.77Z" fill="#F5F5F5" />
      <path d="M245.14 312.52H242.31V315.35H245.14V312.52Z" fill="#F5F5F5" />
      <path d="M245.14 317.27H242.31V320.1H245.14V317.27Z" fill="#F5F5F5" />
      <path d="M245.14 322.02H242.31V324.85H245.14V322.02Z" fill="#F5F5F5" />
      <path d="M272.48 249.36H269.65V252.19H272.48V249.36Z" fill="#F5F5F5" />
      <path d="M272.48 254.11H269.65V256.94H272.48V254.11Z" fill="#F5F5F5" />
      <path d="M272.48 258.86H269.65V261.69H272.48V258.86Z" fill="#F5F5F5" />
      <path d="M272.48 263.61H269.65V266.44H272.48V263.61Z" fill="#F5F5F5" />
      <path d="M272.48 268.36H269.65V271.19H272.48V268.36Z" fill="#F5F5F5" />
      <path d="M272.48 273.11H269.65V275.94H272.48V273.11Z" fill="#F5F5F5" />
      <path d="M272.48 287.36H269.65V290.19H272.48V287.36Z" fill="#F5F5F5" />
      <path d="M272.48 292.11H269.65V294.94H272.48V292.11Z" fill="#F5F5F5" />
      <path d="M272.48 296.86H269.65V299.69H272.48V296.86Z" fill="#F5F5F5" />
      <path d="M272.48 301.61H269.65V304.44H272.48V301.61Z" fill="#F5F5F5" />
      <path d="M272.48 306.36H269.65V309.19H272.48V306.36Z" fill="#F5F5F5" />
      <path d="M272.48 311.11H269.65V313.94H272.48V311.11Z" fill="#F5F5F5" />
      <path d="M272.48 315.86H269.65V318.69H272.48V315.86Z" fill="#F5F5F5" />
      <path d="M267.98 249.36H265.15V252.19H267.98V249.36Z" fill="#F5F5F5" />
      <path d="M267.98 254.11H265.15V256.94H267.98V254.11Z" fill="#F5F5F5" />
      <path d="M267.98 258.86H265.15V261.69H267.98V258.86Z" fill="#F5F5F5" />
      <path d="M267.98 263.61H265.15V266.44H267.98V263.61Z" fill="#F5F5F5" />
      <path d="M267.98 268.36H265.15V271.19H267.98V268.36Z" fill="#F5F5F5" />
      <path d="M267.98 273.11H265.15V275.94H267.98V273.11Z" fill="#F5F5F5" />
      <path d="M267.98 287.36H265.15V290.19H267.98V287.36Z" fill="#F5F5F5" />
      <path d="M267.98 292.11H265.15V294.94H267.98V292.11Z" fill="#F5F5F5" />
      <path d="M267.98 296.86H265.15V299.69H267.98V296.86Z" fill="#F5F5F5" />
      <path d="M267.98 301.61H265.15V304.44H267.98V301.61Z" fill="#F5F5F5" />
      <path d="M267.98 306.36H265.15V309.19H267.98V306.36Z" fill="#F5F5F5" />
      <path d="M267.98 311.11H265.15V313.94H267.98V311.11Z" fill="#F5F5F5" />
      <path d="M267.98 320.61H265.15V323.44H267.98V320.61Z" fill="#F5F5F5" />
      <path d="M118.48 228.44H115.65V231.27H118.48V228.44Z" fill="#F5F5F5" />
      <path d="M118.48 233.19H115.65V236.02H118.48V233.19Z" fill="#F5F5F5" />
      <path d="M118.48 237.94H115.65V240.77H118.48V237.94Z" fill="#F5F5F5" />
      <path d="M118.48 242.69H115.65V245.52H118.48V242.69Z" fill="#F5F5F5" />
      <path d="M118.48 247.44H115.65V250.27H118.48V247.44Z" fill="#F5F5F5" />
      <path d="M118.48 252.19H115.65V255.02H118.48V252.19Z" fill="#F5F5F5" />
      <path d="M118.48 256.94H115.65V259.77H118.48V256.94Z" fill="#F5F5F5" />
      <path d="M118.48 261.69H115.65V264.52H118.48V261.69Z" fill="#F5F5F5" />
      <path d="M118.48 266.44H115.65V269.27H118.48V266.44Z" fill="#F5F5F5" />
      <path d="M118.48 271.19H115.65V274.02H118.48V271.19Z" fill="#F5F5F5" />
      <path d="M118.48 275.94H115.65V278.77H118.48V275.94Z" fill="#F5F5F5" />
      <path d="M118.48 285.44H115.65V288.27H118.48V285.44Z" fill="#F5F5F5" />
      <path d="M118.48 290.19H115.65V293.02H118.48V290.19Z" fill="#F5F5F5" />
      <path d="M118.48 294.94H115.65V297.77H118.48V294.94Z" fill="#F5F5F5" />
      <path d="M118.48 299.69H115.65V302.52H118.48V299.69Z" fill="#F5F5F5" />
      <path d="M118.48 304.44H115.65V307.27H118.48V304.44Z" fill="#F5F5F5" />
      <path d="M118.48 309.19H115.65V312.02H118.48V309.19Z" fill="#F5F5F5" />
      <path d="M118.48 313.94H115.65V316.77H118.48V313.94Z" fill="#F5F5F5" />
      <path d="M118.48 318.69H115.65V321.52H118.48V318.69Z" fill="#F5F5F5" />
      <path d="M113.98 228.44H111.15V231.27H113.98V228.44Z" fill="#F5F5F5" />
      <path d="M113.98 233.19H111.15V236.02H113.98V233.19Z" fill="#F5F5F5" />
      <path d="M113.98 237.94H111.15V240.77H113.98V237.94Z" fill="#F5F5F5" />
      <path d="M113.98 242.69H111.15V245.52H113.98V242.69Z" fill="#F5F5F5" />
      <path d="M113.98 247.44H111.15V250.27H113.98V247.44Z" fill="#F5F5F5" />
      <path d="M113.98 252.19H111.15V255.02H113.98V252.19Z" fill="#F5F5F5" />
      <path d="M113.98 256.94H111.15V259.77H113.98V256.94Z" fill="#F5F5F5" />
      <path d="M113.98 261.69H111.15V264.52H113.98V261.69Z" fill="#F5F5F5" />
      <path d="M113.98 266.44H111.15V269.27H113.98V266.44Z" fill="#F5F5F5" />
      <path d="M113.98 271.19H111.15V274.02H113.98V271.19Z" fill="#F5F5F5" />
      <path d="M113.98 275.94H111.15V278.77H113.98V275.94Z" fill="#F5F5F5" />
      <path d="M113.98 280.69H111.15V283.52H113.98V280.69Z" fill="#F5F5F5" />
      <path d="M113.98 285.44H111.15V288.27H113.98V285.44Z" fill="#F5F5F5" />
      <path d="M113.98 290.19H111.15V293.02H113.98V290.19Z" fill="#F5F5F5" />
      <path d="M113.98 294.94H111.15V297.77H113.98V294.94Z" fill="#F5F5F5" />
      <path d="M113.98 299.69H111.15V302.52H113.98V299.69Z" fill="#F5F5F5" />
      <path d="M113.98 304.44H111.15V307.27H113.98V304.44Z" fill="#F5F5F5" />
      <path d="M113.98 309.19H111.15V312.02H113.98V309.19Z" fill="#F5F5F5" />
      <path d="M113.98 313.94H111.15V316.77H113.98V313.94Z" fill="#F5F5F5" />
      <path d="M113.98 318.69H111.15V321.52H113.98V318.69Z" fill="#F5F5F5" />
      <path d="M140.64 246.94H137.81V249.77H140.64V246.94Z" fill="#F5F5F5" />
      <path d="M140.64 251.69H137.81V254.52H140.64V251.69Z" fill="#F5F5F5" />
      <path d="M140.64 256.44H137.81V259.27H140.64V256.44Z" fill="#F5F5F5" />
      <path d="M140.64 261.19H137.81V264.02H140.64V261.19Z" fill="#F5F5F5" />
      <path d="M140.64 265.94H137.81V268.77H140.64V265.94Z" fill="#F5F5F5" />
      <path d="M140.64 270.69H137.81V273.52H140.64V270.69Z" fill="#F5F5F5" />
      <path d="M140.64 275.44H137.81V278.27H140.64V275.44Z" fill="#F5F5F5" />
      <path d="M140.64 280.19H137.81V283.02H140.64V280.19Z" fill="#F5F5F5" />
      <path d="M140.64 284.94H137.81V287.77H140.64V284.94Z" fill="#F5F5F5" />
      <path d="M140.64 289.69H137.81V292.52H140.64V289.69Z" fill="#F5F5F5" />
      <path d="M136.14 246.94H133.31V249.77H136.14V246.94Z" fill="#F5F5F5" />
      <path d="M136.14 251.69H133.31V254.52H136.14V251.69Z" fill="#F5F5F5" />
      <path d="M136.14 256.44H133.31V259.27H136.14V256.44Z" fill="#F5F5F5" />
      <path d="M136.14 261.19H133.31V264.02H136.14V261.19Z" fill="#F5F5F5" />
      <path d="M136.14 265.94H133.31V268.77H136.14V265.94Z" fill="#F5F5F5" />
      <path d="M136.14 270.69H133.31V273.52H136.14V270.69Z" fill="#F5F5F5" />
      <path d="M136.14 275.44H133.31V278.27H136.14V275.44Z" fill="#F5F5F5" />
      <path d="M136.14 280.19H133.31V283.02H136.14V280.19Z" fill="#F5F5F5" />
      <path d="M136.14 284.94H133.31V287.77H136.14V284.94Z" fill="#F5F5F5" />
      <path d="M162.92 116.68H158.16V332.45H162.92V116.68Z" fill="#EBEBEB" />
      <path d="M252.38 116.68H247.62V332.45H252.38V116.68Z" fill="#EBEBEB" />
      <path d="M341.84 116.68H337.08V332.45H341.84V116.68Z" fill="#EBEBEB" />
      <path
        d="M413.93 349.26C414.09 348.26 417.59 324.08 410.57 315.75C409.873 314.871 408.989 314.158 407.983 313.662C406.976 313.165 405.872 312.899 404.75 312.88C390.6 312.55 388.66 323.88 388.65 324.03L390.32 324.29C390.38 323.88 392.06 314.29 404.71 314.57C405.593 314.581 406.461 314.79 407.252 315.181C408.043 315.572 408.736 316.136 409.28 316.83C415.81 324.6 412.28 348.76 412.28 349.01L413.93 349.26Z"
        fill="#E0E0E0"
      />
      <path
        d="M414.79 348.16L416.48 348.1C416.48 347.88 415.92 325.85 426.77 321.3C437.41 316.85 442.19 326.01 442.39 326.4L443.9 325.64C443.84 325.53 438.23 314.64 426.12 319.75C414.19 324.74 414.76 347.2 414.79 348.16Z"
        fill="#E0E0E0"
      />
      <path
        d="M414.741 349.89C414.741 349.54 414.841 314.7 421.631 304.51C428.161 294.72 438.681 299.33 439.121 299.51L439.821 297.97C439.701 297.92 427.561 292.56 420.231 303.55C413.151 314.16 413.051 348.41 413.051 349.87L414.741 349.89Z"
        fill="#E0E0E0"
      />
      <path
        d="M402.73 313.72C402.73 313.72 408.91 316 407.07 321.37C405.23 326.74 385.69 335.6 385.69 335.6C385.69 335.6 377.51 328.1 385.02 315.9C392.53 303.7 402.56 309.87 402.73 313.72Z"
        fill="#E0E0E0"
      />
      <path
        d="M421.941 302.67C421.941 302.67 417.551 302.2 417.941 299.53C418.331 296.86 434.941 289.98 444.841 301.1C444.841 301.1 438.541 311.94 429.411 312.1C420.281 312.26 421.941 302.67 421.941 302.67Z"
        fill="#E0E0E0"
      />
      <path
        d="M426.439 320.52C426.439 320.52 423.439 318.08 426.679 316.89C429.919 315.7 445.979 316.03 446.149 331.89C446.149 331.89 427.479 335 425.149 328.95C422.819 322.9 426.439 320.52 426.439 320.52Z"
        fill="#E0E0E0"
      />
      <path
        d="M399.41 381.61H428.38L431.51 349.89H396.28L399.41 381.61Z"
        fill="#EBEBEB"
      />
      <path d="M394.741 353.38H433.061V346.38H394.741V353.38Z" fill="#E0E0E0" />
      <path
        d="M86.0704 349.26C85.9104 348.26 82.4104 324.08 89.4304 315.75C90.128 314.871 91.0118 314.158 92.0182 313.662C93.0246 313.165 94.1284 312.899 95.2504 312.88C109.4 312.55 111.34 323.88 111.35 324.03L109.68 324.29C109.62 323.88 107.94 314.29 95.2904 314.57C94.4081 314.581 93.5396 314.79 92.7487 315.181C91.9578 315.572 91.2647 316.136 90.7204 316.83C84.1904 324.6 87.7204 348.76 87.7204 349.01L86.0704 349.26Z"
        fill="#E0E0E0"
      />
      <path
        d="M85.2096 348.16L83.5196 348.1C83.5196 347.88 84.0796 325.85 73.2296 321.3C62.5896 316.85 57.8096 326.01 57.6096 326.4L56.0996 325.64C56.1596 325.53 61.7696 314.64 73.8796 319.75C85.8096 324.74 85.2396 347.2 85.2096 348.16Z"
        fill="#E0E0E0"
      />
      <path
        d="M85.2597 349.89C85.2597 349.54 85.1597 314.7 78.3697 304.51C71.8397 294.72 61.3197 299.33 60.8797 299.51L60.1797 297.97C60.2997 297.92 72.4397 292.56 79.7697 303.55C86.8497 314.18 86.9997 348.43 86.9997 349.89H85.2597Z"
        fill="#E0E0E0"
      />
      <path
        d="M97.2701 313.72C97.2701 313.72 91.0901 316 92.9301 321.37C94.7701 326.74 114.31 335.6 114.31 335.6C114.31 335.6 122.49 328.1 114.98 315.9C107.47 303.7 97.4401 309.87 97.2701 313.72Z"
        fill="#E0E0E0"
      />
      <path
        d="M78.0604 302.67C78.0604 302.67 82.4504 302.2 82.0604 299.53C81.6704 296.86 65.0004 290 55.1904 301.1C55.1904 301.1 61.4904 311.94 70.6204 312.1C79.7504 312.26 78.0604 302.67 78.0604 302.67Z"
        fill="#E0E0E0"
      />
      <path
        d="M73.5596 320.52C73.5596 320.52 76.5596 318.08 73.3196 316.89C70.0796 315.7 54.0196 316.03 53.8496 331.89C53.8496 331.89 72.5196 335 74.8496 328.95C77.1796 322.9 73.5596 320.52 73.5596 320.52Z"
        fill="#E0E0E0"
      />
      <path
        d="M100.59 381.61H71.6102L68.4902 349.89H103.72L100.59 381.61Z"
        fill="#EBEBEB"
      />
      <path d="M105.26 346.39H66.9404V353.39H105.26V346.39Z" fill="#E0E0E0" />
      <path
        d="M250 427.56C357.083 427.56 443.89 422.492 443.89 416.24C443.89 409.988 357.083 404.92 250 404.92C142.918 404.92 56.1104 409.988 56.1104 416.24C56.1104 422.492 142.918 427.56 250 427.56Z"
        fill="#F5F5F5"
      />
      <path
        d="M258.57 111.53H110.1C105.582 111.53 101.92 115.193 101.92 119.71V351.79C101.92 356.308 105.582 359.97 110.1 359.97H258.57C263.088 359.97 266.75 356.308 266.75 351.79V119.71C266.75 115.193 263.088 111.53 258.57 111.53Z"
        fill="#F67C36"
      />
      <g opacity="0.7">
        <path
          d="M261.71 122.711V348.801C261.71 349.472 261.577 350.138 261.32 350.758C261.063 351.379 260.685 351.943 260.21 352.417C259.734 352.892 259.17 353.268 258.549 353.525C257.927 353.781 257.262 353.912 256.59 353.911H112.07C110.722 353.9 109.432 353.357 108.483 352.4C107.533 351.443 107 350.149 107 348.801V122.711C107 122.039 107.133 121.373 107.39 120.753C107.647 120.132 108.025 119.568 108.5 119.094C108.976 118.619 109.54 118.243 110.161 117.987C110.783 117.73 111.448 117.599 112.12 117.601H148.46C149.016 117.6 149.56 117.77 150.016 118.088C150.472 118.407 150.819 118.858 151.01 119.381L151.7 121.261C151.894 121.784 152.242 122.236 152.7 122.556C153.157 122.876 153.702 123.049 154.26 123.051H215.68C216.402 123.048 217.094 122.759 217.604 122.247C218.114 121.736 218.4 121.043 218.4 120.321C218.4 119.963 218.471 119.608 218.608 119.277C218.745 118.947 218.947 118.646 219.2 118.394C219.454 118.141 219.755 117.941 220.086 117.805C220.417 117.669 220.772 117.599 221.13 117.601H256.64C257.988 117.611 259.278 118.154 260.227 119.111C261.177 120.069 261.71 121.362 261.71 122.711Z"
          fill="white"
        />
      </g>
      <path d="M261.71 145.101H106.95V207.921H261.71V145.101Z" fill="white" />
      <path
        opacity="0.2"
        d="M261.71 207.92H106.95V236.38H261.71V207.92Z"
        fill="black"
      />
      <path
        d="M134.79 169.651L147.89 156.011H175.55L189.99 169.651H134.79Z"
        fill="#F67C36"
      />
      <path
        opacity="0.1"
        d="M147.89 156.011L164.15 170.561L134.79 169.651L147.89 156.011Z"
        fill="black"
      />
      <path d="M198.54 170.931H197.87V188.921H198.54V170.931Z" fill="#F67C36" />
      <path d="M135.91 188.78H132.4V207.92H135.91V188.78Z" fill="#F67C36" />
      <path
        opacity="0.3"
        d="M135.91 188.78H132.4V207.92H135.91V188.78Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M135.91 190.561H133.68V207.921H135.91V190.561Z"
        fill="white"
      />
      <path d="M177.59 185.82H134.53V207.92H177.59V185.82Z" fill="#F67C36" />
      <path
        opacity="0.8"
        d="M177.59 185.82H134.53V207.92H177.59V185.82Z"
        fill="white"
      />
      <path
        opacity="0.05"
        d="M156.64 185.82H134.53V207.92H156.64V185.82Z"
        fill="black"
      />
      <path d="M191.53 189.54H176.78V207.92H191.53V189.54Z" fill="#F67C36" />
      <path
        opacity="0.4"
        d="M191.53 189.54H176.78V207.92H191.53V189.54Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M191.53 189.54V196.64L183.01 200.29V207.92H176.78V189.54H191.53Z"
        fill="black"
      />
      <path d="M197.59 190.37H196.47V207.91H197.59V190.37Z" fill="#F67C36" />
      <path d="M194.98 190.37H193.86V207.91H194.98V190.37Z" fill="#F67C36" />
      <path d="M192.37 190.37H191.25V207.91H192.37V190.37Z" fill="#F67C36" />
      <path d="M189.76 190.37H188.64V207.91H189.76V190.37Z" fill="#F67C36" />
      <path d="M187.16 190.37H186.04V207.91H187.16V190.37Z" fill="#F67C36" />
      <path
        d="M197.59 183.59H183.4V184.1H185.25V184.61H183.4V184.74H185.25V185.26H183.4V185.39H185.25V185.91H183.4V186H185.25V186.51H183.4V186.65H185.25V187.16H183.4V187.3H185.25V187.81H183.4V188H185.25V188.51H183.4V188.65H185.25V188.93H185.64V188.65H190.86V188.93H191.25V188.65H196.47V188.93H196.86V184.1H197.59V183.59ZM185.59 184.1H190.81V184.61H185.59V184.1ZM185.59 184.74H190.81V185.26H185.59V184.74ZM185.59 185.39H190.81V185.91H185.59V185.39ZM185.59 186.04H190.81V186.55H185.59V186.04ZM185.59 186.69H190.81V187.2H185.59V186.69ZM185.59 187.34H190.81V187.85H185.59V187.34ZM185.59 188.5V188H190.81V188.51L185.59 188.5ZM196.42 188.5H191.2V188H196.42V188.5ZM196.42 187.85H191.2V187.34H196.42V187.85ZM196.42 187.2H191.2V186.69H196.42V187.2ZM196.42 186.55H191.2V186H196.42V186.55ZM196.42 185.91H191.2V185.39H196.42V185.91ZM196.42 185.26H191.2V184.74H196.42V185.26ZM196.42 184.61H191.2V184.1H196.42V184.61Z"
        fill="#F67C36"
      />
      <g opacity="0.2">
        <path
          d="M197.59 183.59H183.4V184.1H185.25V184.61H183.4V184.74H185.25V185.26H183.4V185.39H185.25V185.91H183.4V186H185.25V186.51H183.4V186.65H185.25V187.16H183.4V187.3H185.25V187.81H183.4V188H185.25V188.51H183.4V188.65H185.25V188.93H185.64V188.65H190.86V188.93H191.25V188.65H196.47V188.93H196.86V184.1H197.59V183.59ZM185.59 184.1H190.81V184.61H185.59V184.1ZM185.59 184.74H190.81V185.26H185.59V184.74ZM185.59 185.39H190.81V185.91H185.59V185.39ZM185.59 186.04H190.81V186.55H185.59V186.04ZM185.59 186.69H190.81V187.2H185.59V186.69ZM185.59 187.34H190.81V187.85H185.59V187.34ZM185.59 188.5V188H190.81V188.51L185.59 188.5ZM196.42 188.5H191.2V188H196.42V188.5ZM196.42 187.85H191.2V187.34H196.42V187.85ZM196.42 187.2H191.2V186.69H196.42V187.2ZM196.42 186.55H191.2V186H196.42V186.55ZM196.42 185.91H191.2V185.39H196.42V185.91ZM196.42 185.26H191.2V184.74H196.42V185.26ZM196.42 184.61H191.2V184.1H196.42V184.61Z"
          fill="black"
        />
      </g>
      <path d="M183.96 170.931H134.53V188.781H183.96V170.931Z" fill="#F67C36" />
      <path
        opacity="0.8"
        d="M183.96 170.931H134.53V188.781H183.96V170.931Z"
        fill="white"
      />
      <path
        opacity="0.05"
        d="M163.66 170.931H134.53V188.781H163.66V170.931Z"
        fill="black"
      />
      <path d="M182.25 173.471H153.79V188.781H182.25V173.471Z" fill="#F67C36" />
      <path
        opacity="0.1"
        d="M153.79 173.471V188.781H182.25V173.471H153.79ZM181.1 187.791H154.8V174.641H181.1V187.791Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M164.54 173.471H153.79V188.781H164.54V173.471Z"
        fill="black"
      />
      <path d="M179.87 174.641H155.76V187.611H179.87V174.641Z" fill="#F67C36" />
      <path
        opacity="0.5"
        d="M179.87 174.641H155.76V187.611H179.87V174.641Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M162.38 174.641L158.91 187.611H155.92L159.39 174.641H162.38Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M179.87 174.641V181.801L178.31 187.611H175.32L178.8 174.641H179.87Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M164.15 174.641L160.68 187.611H159.82L163.29 174.641H164.15Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M177.95 174.641L174.48 187.611H174.05L177.52 174.641H177.95Z"
        fill="white"
      />
      <path
        opacity="0.05"
        d="M163.78 174.641H155.76V187.611H163.78V174.641Z"
        fill="black"
      />
      <path d="M153.07 193.36H144.77V200.52H153.07V193.36Z" fill="black" />
      <path d="M144.26 193.36H136.49V200.52H144.26V193.36Z" fill="black" />
      <path d="M144.26 193.36H136.49V200.52H144.26V193.36Z" fill="#F67C36" />
      <path d="M153.07 193.36H144.77V200.52H153.07V193.36Z" fill="#F67C36" />
      <path
        opacity="0.5"
        d="M153.07 193.36H144.77V200.52H153.07V193.36Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M144.26 193.36H136.49V200.52H144.26V193.36Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M141.77 193.36L139.86 200.52H136.87L138.79 193.36H141.77Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M143.55 193.36L141.63 200.52H140.77L142.69 193.36H143.55Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M153.06 194.08V200.52H151.34L153.06 194.08Z"
        fill="white"
      />
      <path
        d="M177.95 200.52V193.36H179.47V192.11H168.24V201.79H179.47V200.52H177.95Z"
        fill="#F67C36"
      />
      <path d="M178.28 193.36H170.51V200.52H178.28V193.36Z" fill="black" />
      <path d="M178.28 193.36H170.51V200.52H178.28V193.36Z" fill="#F67C36" />
      <path
        opacity="0.5"
        d="M178.28 193.36H170.51V200.52H178.28V193.36Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M175.79 193.36L173.88 200.52H170.89L172.81 193.36H175.79Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M177.56 193.36L175.65 200.52H174.79L176.71 193.36H177.56Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M169.3 192.11H168.24V201.78H169.3V192.11Z"
        fill="black"
      />
      <path d="M164.15 193.36H158.7V207.92H164.15V193.36Z" fill="#F67C36" />
      <path
        opacity="0.1"
        d="M163.65 193.36H158.7V207.92H163.65V193.36Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M163.08 194.34H161.99V198.92H163.08V194.34Z"
        fill="white"
      />
      <path d="M167.73 190.391H164.93V207.921H167.73V190.391Z" fill="#F67C36" />
      <path
        opacity="0.3"
        d="M167.73 190.391H164.93V207.921H167.73V190.391Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M167.73 190.391H166.33V207.921H167.73V190.391Z"
        fill="white"
      />
      <path d="M188.87 190.391H186.07V207.921H188.87V190.391Z" fill="#F67C36" />
      <path
        opacity="0.3"
        d="M188.87 190.391H186.07V207.921H188.87V190.391Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M188.87 190.391H187.47V207.921H188.87V190.391Z"
        fill="white"
      />
      <path d="M200.07 188.78H133.89V190.6H200.07V188.78Z" fill="#F67C36" />
      <path
        opacity="0.3"
        d="M200.07 188.78H133.89V190.6H200.07V188.78Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M188.87 188.78H166.33V190.6H188.87V188.78Z"
        fill="white"
      />
      <path d="M199.22 169.65H133.89V171.47H199.22V169.65Z" fill="#F67C36" />
      <path
        opacity="0.3"
        d="M199.22 169.65H133.89V171.47H199.22V169.65Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M199.22 169.65H166.33V171.47H199.22V169.65Z"
        fill="white"
      />
      <path d="M147.18 176.521H134.5V183.68H147.18V176.521Z" fill="black" />
      <path d="M147.18 176.521H134.5V183.68H147.18V176.521Z" fill="#F67C36" />
      <path
        opacity="0.5"
        d="M147.18 176.521H134.5V183.68H147.18V176.521Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M134.5 176.521V182.831L136.19 176.521H134.5Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M137.969 176.521L136.049 183.68H135.189L137.109 176.521H137.969Z"
        fill="white"
      />
      <path
        d="M199.02 207.471H130.57L129.93 207.921H199.66L199.02 207.471Z"
        fill="#F67C36"
      />
      <path
        d="M237.69 156.011H208.5V172.911H237.69V156.011Z"
        stroke="#F67C36"
        stroke-miterlimit="10"
      />
      <path
        d="M208.5 167.64H222.43V159.44"
        stroke="#F67C36"
        stroke-miterlimit="10"
      />
      <path
        d="M228.63 156.011V160.111"
        stroke="#F67C36"
        stroke-miterlimit="10"
      />
      <path
        d="M228.63 163.54V167.64H237.69"
        stroke="#F67C36"
        stroke-miterlimit="10"
      />
      <path d="M235.59 168.74V171.9" stroke="#F67C36" stroke-miterlimit="10" />
      <path d="M233.16 168.74V171.9" stroke="#F67C36" stroke-miterlimit="10" />
      <path d="M230.729 168.74V171.9" stroke="#F67C36" stroke-miterlimit="10" />
      <path
        d="M237.69 176.271H208.5V188.831H237.69V176.271Z"
        stroke="#F67C36"
        stroke-miterlimit="10"
      />
      <path
        d="M216.46 188.83V200.09H237.69V188.83"
        stroke="#F67C36"
        stroke-miterlimit="10"
      />
      <path d="M235.59 190.44V193.61" stroke="#F67C36" stroke-miterlimit="10" />
      <path d="M233.16 190.44V193.61" stroke="#F67C36" stroke-miterlimit="10" />
      <path
        d="M230.729 190.44V193.61"
        stroke="#F67C36"
        stroke-miterlimit="10"
      />
      <path d="M228.25 188.83V196.65" stroke="#F67C36" stroke-miterlimit="10" />
      <path
        d="M218.13 176.351V186.061"
        stroke="#F67C36"
        stroke-miterlimit="10"
      />
      <path
        d="M228.25 176.561V186.061"
        stroke="#F67C36"
        stroke-miterlimit="10"
      />
      <path
        opacity="0.7"
        d="M204.52 215.32H165.4C163.837 215.32 162.57 216.587 162.57 218.15V226.37C162.57 227.933 163.837 229.2 165.4 229.2H204.52C206.083 229.2 207.35 227.933 207.35 226.37V218.15C207.35 216.587 206.083 215.32 204.52 215.32Z"
        fill="white"
      />
      <path
        d="M169.77 224.441L170.13 223.641C170.653 224.038 171.292 224.252 171.95 224.251C172.82 224.251 173.18 223.941 173.18 223.531C173.18 222.341 169.89 223.121 169.89 221.071C169.89 220.171 170.6 219.421 172.12 219.421C172.767 219.409 173.404 219.578 173.96 219.911L173.64 220.701C173.175 220.428 172.648 220.279 172.11 220.271C171.26 220.271 170.91 220.601 170.91 221.021C170.91 222.201 174.19 221.431 174.19 223.461C174.19 224.351 173.47 225.111 171.95 225.111C171.169 225.134 170.402 224.898 169.77 224.441Z"
        fill="#F67C36"
      />
      <path
        d="M179.36 224.17V225H175.21V219.46H179.21V220.33H176.21V221.76H178.88V222.61H176.21V224.14L179.36 224.17Z"
        fill="#F67C36"
      />
      <path
        d="M180.5 219.49H181.5V224.16H184.4V225H180.5V219.49Z"
        fill="#F67C36"
      />
      <path
        d="M189.38 224.17V225H185.229V219.46H189.229V220.33H186.229V221.76H188.91V222.61H186.229V224.14L189.38 224.17Z"
        fill="#F67C36"
      />
      <path
        d="M190.1 222.26C190.132 221.678 190.334 221.118 190.68 220.648C191.025 220.179 191.501 219.82 192.047 219.617C192.593 219.413 193.187 219.373 193.756 219.501C194.325 219.63 194.844 219.921 195.25 220.34L194.59 220.97C194.405 220.761 194.177 220.594 193.921 220.482C193.666 220.37 193.389 220.315 193.11 220.32C192.842 220.301 192.574 220.337 192.322 220.426C192.069 220.515 191.837 220.655 191.641 220.838C191.445 221.02 191.289 221.241 191.182 221.487C191.075 221.733 191.02 221.997 191.02 222.265C191.02 222.533 191.075 222.798 191.182 223.044C191.289 223.289 191.445 223.51 191.641 223.693C191.837 223.875 192.069 224.015 192.322 224.104C192.574 224.193 192.842 224.229 193.11 224.21C193.389 224.214 193.667 224.157 193.922 224.043C194.178 223.929 194.405 223.761 194.59 223.55L195.25 224.18C194.844 224.599 194.325 224.891 193.756 225.019C193.187 225.148 192.593 225.108 192.047 224.904C191.501 224.7 191.025 224.342 190.68 223.872C190.334 223.403 190.132 222.843 190.1 222.26Z"
        fill="#F67C36"
      />
      <path
        d="M197.3 220.36H195.46V219.49H200.16V220.36H198.32V225H197.32L197.3 220.36Z"
        fill="#F67C36"
      />
      <path
        opacity="0.2"
        d="M261.71 122.711V132.271H107V122.711C107 122.039 107.133 121.373 107.39 120.753C107.647 120.132 108.025 119.568 108.5 119.094C108.976 118.619 109.54 118.243 110.161 117.987C110.783 117.73 111.448 117.599 112.12 117.601H148.46C149.016 117.6 149.56 117.77 150.016 118.088C150.472 118.407 150.819 118.858 151.01 119.381L151.7 121.261C151.894 121.784 152.242 122.236 152.7 122.556C153.157 122.876 153.702 123.049 154.26 123.051H215.68C216.402 123.048 217.094 122.759 217.604 122.247C218.114 121.736 218.4 121.043 218.4 120.321C218.4 119.963 218.471 119.608 218.608 119.277C218.745 118.947 218.947 118.646 219.2 118.394C219.454 118.141 219.755 117.941 220.086 117.805C220.417 117.669 220.772 117.599 221.13 117.601H256.64C257.988 117.611 259.278 118.154 260.227 119.111C261.177 120.069 261.71 121.362 261.71 122.711Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M119.98 138.84L116.17 135.271L112.36 138.84H113.34V141.921H119.01V138.84H119.98Z"
        fill="black"
      />
      <path
        d="M206 141.19H134.71C134.369 141.191 134.032 141.125 133.716 140.996C133.401 140.866 133.114 140.676 132.873 140.435C132.631 140.194 132.439 139.908 132.308 139.593C132.178 139.279 132.11 138.941 132.11 138.6C132.109 138.258 132.175 137.92 132.306 137.603C132.436 137.287 132.627 137 132.869 136.759C133.111 136.517 133.398 136.325 133.714 136.195C134.03 136.065 134.369 135.999 134.71 136H206C206.341 136 206.679 136.067 206.994 136.198C207.309 136.329 207.595 136.521 207.835 136.762C208.076 137.004 208.267 137.29 208.396 137.606C208.526 137.921 208.592 138.259 208.59 138.6C208.59 138.94 208.523 139.277 208.393 139.591C208.263 139.905 208.072 140.191 207.832 140.431C207.591 140.672 207.306 140.863 206.992 140.993C206.677 141.123 206.34 141.19 206 141.19Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M221.08 135.8H217.69C217.082 135.8 216.59 136.292 216.59 136.9V140.29C216.59 140.897 217.082 141.39 217.69 141.39H221.08C221.687 141.39 222.18 140.897 222.18 140.29V136.9C222.18 136.292 221.687 135.8 221.08 135.8Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M231.08 135.8H227.69C227.082 135.8 226.59 136.292 226.59 136.9V140.29C226.59 140.897 227.082 141.39 227.69 141.39H231.08C231.687 141.39 232.18 140.897 232.18 140.29V136.9C232.18 136.292 231.687 135.8 231.08 135.8Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M241.08 135.8H237.69C237.082 135.8 236.59 136.292 236.59 136.9V140.29C236.59 140.897 237.082 141.39 237.69 141.39H241.08C241.687 141.39 242.18 140.897 242.18 140.29V136.9C242.18 136.292 241.687 135.8 241.08 135.8Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M247.91 137.93V139.26L248.6 139.33C248.692 139.769 248.865 140.186 249.11 140.56L248.67 141.09L249.61 142.03L250.14 141.59C250.515 141.835 250.932 142.008 251.37 142.1L251.44 142.79H252.77L252.83 142.1C253.269 142.012 253.687 141.839 254.06 141.59L254.6 142.03L255.54 141.09L255.1 140.56C255.345 140.186 255.518 139.769 255.61 139.33L256.3 139.26V137.93L255.61 137.87C255.52 137.429 255.347 137.008 255.1 136.63L255.54 136.1L254.6 135.16L254.06 135.6C253.687 135.352 253.269 135.179 252.83 135.09L252.77 134.4H251.44L251.37 135.09C250.932 135.182 250.515 135.355 250.14 135.6L249.61 135.16L248.67 136.1L249.11 136.63C248.863 137.008 248.69 137.429 248.6 137.87L247.91 137.93ZM252.1 136.79C252.459 136.788 252.809 136.893 253.108 137.091C253.407 137.288 253.641 137.57 253.779 137.901C253.918 138.231 253.955 138.596 253.887 138.947C253.818 139.299 253.646 139.623 253.394 139.877C253.141 140.131 252.818 140.304 252.467 140.375C252.116 140.446 251.751 140.41 251.42 140.274C251.089 140.137 250.805 139.905 250.606 139.607C250.407 139.309 250.3 138.959 250.3 138.6C250.3 138.122 250.489 137.663 250.827 137.324C251.164 136.985 251.622 136.793 252.1 136.79Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M255.17 337.551C254.316 337.551 253.497 337.211 252.893 336.608C252.289 336.004 251.95 335.185 251.95 334.331V243.651C251.95 242.797 252.289 241.978 252.893 241.374C253.497 240.77 254.316 240.431 255.17 240.431C256.024 240.431 256.843 240.77 257.447 241.374C258.051 241.978 258.39 242.797 258.39 243.651V334.331C258.39 335.185 258.051 336.004 257.447 336.608C256.843 337.211 256.024 337.551 255.17 337.551Z"
        fill="black"
      />
      <path
        d="M255.17 256.101C254.398 256.101 253.658 256.407 253.112 256.953C252.566 257.499 252.26 258.239 252.26 259.011V273.011C252.26 273.782 252.566 274.523 253.112 275.068C253.658 275.614 254.398 275.921 255.17 275.921C255.942 275.921 256.682 275.614 257.227 275.068C257.773 274.523 258.08 273.782 258.08 273.011V259.001C258.077 258.231 257.769 257.493 257.224 256.949C256.678 256.406 255.94 256.101 255.17 256.101Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M248 239.561H114.17V281.231H248V239.561Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M174.189 278.24V242.57H117.169V278.24H174.189Z"
        fill="black"
      />
      <path
        opacity="0.4"
        d="M248 282.561H114.17V324.231H248V282.561Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M248 325.561H114.17V338.251H248V325.561Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M261.71 340.36V348.8C261.71 349.472 261.577 350.138 261.32 350.758C261.063 351.379 260.685 351.943 260.21 352.417C259.734 352.892 259.17 353.268 258.549 353.524C257.927 353.78 257.262 353.912 256.59 353.91H112.07C110.722 353.9 109.432 353.357 108.483 352.4C107.533 351.442 107 350.149 107 348.8V340.36H261.71Z"
        fill="black"
      />
      <g opacity="0.2">
        <path
          d="M161.7 265.92V265H160.91V256H161.26V255.06H156.49L149 248H134.7L128 255.09H127.54V256H127.87V258.61V262.31V265H126.77V274.66H125.82L125.49 274.89H161.49L161.16 274.66H160.42V265.95L161.7 265.92ZM157.15 264.97V264.9H159.84V265L157.15 264.97ZM153.38 262.56H154.05V262.82H153.38V262.56ZM156.94 262.82H154.25V262.56H156.94V262.82ZM156.94 262.89V263.15H154.25V262.89H156.94ZM156.94 263.22V263.49H154.25V263.22H156.94ZM156.94 263.56V263.82H154.25V263.56H156.94ZM156.94 263.89V264.16H154.25V263.89H156.94ZM156.94 264.23V264.49H154.25V264.23H156.94ZM156.94 264.56V264.83H154.25V264.56H156.94ZM156.94 264.9V265H154.25V264.93L156.94 264.9ZM153.38 262.9H154.05V263.16H153.38V262.9ZM153.38 263.23H154.05V263.5H153.38V263.23ZM153.38 263.57H154.05V263.83H153.38V263.57ZM153.38 263.9H154.05V264.17H153.38V263.9ZM153.38 264.24H154.05V264.5H153.38V264.24ZM153.38 264.57H154.05V264.84H153.38V264.57ZM153.38 264.91H154.05V265H153.38V264.91ZM159.84 262.83H157.15V262.57H159.84V262.83ZM159.84 262.9V263.16H157.15V262.9H159.84ZM159.84 263.23V263.5H157.15V263.23H159.84ZM159.84 263.57V263.83H157.15V263.57H159.84ZM159.84 263.9V264.17H157.15V263.9H159.84ZM159.84 264.24V264.5H157.15V264.24H159.84ZM159.84 264.57V264.84H157.15V264.57H159.84ZM160.57 256.04V265H160V262.59H160.38V262.32H153.38V256L160.57 256.04ZM157.73 274.64V265.93H158.49V274.64H157.73ZM159.84 274.64H159.07V265.93H159.84V274.64Z"
          fill="black"
        />
        <path d="M174.19 274.63H117.17V278.23H174.19V274.63Z" fill="black" />
      </g>
      <path
        opacity="0.2"
        d="M174.189 321.24V285.57H117.169V321.24H174.189Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M174.19 328.561H117.17V338.251H174.19V328.561Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M169.11 316.28V304.15H169.85V303.13H157.93V302.67H157.11L155.35 301.32H153.6V293.58H155.01V293.27V292.64H154.19L150.68 289.95H129.42L125.99 292.64H124.8V293.58H126.34V301.32H124.63L122.91 302.67H121.72V303.6H124.34V316.28H117.17V321.23H174.19V316.28H169.11Z"
        fill="black"
      />
      <g opacity="0.2">
        <path
          d="M218.35 248.281H181.21C180.967 248.281 180.725 248.232 180.5 248.139C180.275 248.045 180.071 247.908 179.899 247.735C179.727 247.562 179.591 247.357 179.498 247.132C179.406 246.906 179.359 246.664 179.36 246.421C179.36 245.929 179.555 245.457 179.902 245.109C180.248 244.76 180.719 244.563 181.21 244.561H218.35C218.595 244.561 218.837 244.609 219.062 244.702C219.288 244.796 219.493 244.933 219.666 245.105C219.838 245.278 219.975 245.483 220.069 245.709C220.162 245.934 220.21 246.176 220.21 246.421C220.21 246.665 220.162 246.907 220.069 247.132C219.975 247.358 219.838 247.563 219.666 247.736C219.493 247.908 219.288 248.045 219.062 248.139C218.837 248.232 218.595 248.281 218.35 248.281Z"
          fill="black"
        />
        <path
          d="M232.92 258.001H180.49C180.341 258.001 180.193 257.971 180.056 257.914C179.918 257.856 179.793 257.772 179.688 257.666C179.583 257.56 179.5 257.434 179.444 257.296C179.387 257.158 179.359 257.01 179.36 256.861C179.359 256.711 179.387 256.563 179.444 256.425C179.5 256.287 179.583 256.161 179.688 256.055C179.793 255.949 179.918 255.865 180.056 255.808C180.193 255.75 180.341 255.721 180.49 255.721H232.92C233.07 255.721 233.217 255.75 233.355 255.808C233.493 255.865 233.618 255.949 233.723 256.055C233.828 256.161 233.911 256.287 233.967 256.425C234.023 256.563 234.052 256.711 234.05 256.861C234.052 257.01 234.023 257.158 233.967 257.296C233.911 257.434 233.828 257.56 233.723 257.666C233.618 257.772 233.493 257.856 233.355 257.914C233.217 257.971 233.07 258.001 232.92 258.001Z"
          fill="black"
        />
        <path
          d="M232.92 263.541H180.49C180.341 263.541 180.193 263.511 180.056 263.454C179.918 263.396 179.793 263.312 179.688 263.206C179.583 263.1 179.5 262.974 179.444 262.836C179.387 262.698 179.359 262.55 179.36 262.401C179.359 262.251 179.387 262.103 179.444 261.965C179.5 261.827 179.583 261.701 179.688 261.595C179.793 261.489 179.918 261.405 180.056 261.348C180.193 261.29 180.341 261.261 180.49 261.261H232.92C233.07 261.261 233.217 261.29 233.355 261.348C233.493 261.405 233.618 261.489 233.723 261.595C233.828 261.701 233.911 261.827 233.967 261.965C234.023 262.103 234.052 262.251 234.05 262.401C234.052 262.55 234.023 262.698 233.967 262.836C233.911 262.974 233.828 263.1 233.723 263.206C233.618 263.312 233.493 263.396 233.355 263.454C233.217 263.511 233.07 263.541 232.92 263.541Z"
          fill="black"
        />
        <path
          d="M232.92 269H180.49C180.191 269 179.903 268.881 179.691 268.669C179.479 268.458 179.36 268.17 179.36 267.87C179.359 267.721 179.387 267.573 179.444 267.435C179.5 267.297 179.583 267.171 179.688 267.065C179.793 266.959 179.918 266.875 180.056 266.817C180.193 266.76 180.341 266.73 180.49 266.73H232.92C233.07 266.73 233.217 266.76 233.355 266.817C233.493 266.875 233.618 266.959 233.723 267.065C233.828 267.171 233.911 267.297 233.967 267.435C234.023 267.573 234.052 267.721 234.05 267.87C234.05 268.019 234.021 268.166 233.964 268.303C233.908 268.44 233.824 268.565 233.719 268.669C233.614 268.774 233.49 268.858 233.353 268.914C233.216 268.971 233.069 269 232.92 269Z"
          fill="black"
        />
        <path
          d="M232.92 274.55H180.49C180.341 274.55 180.193 274.521 180.056 274.463C179.918 274.406 179.793 274.322 179.688 274.216C179.583 274.11 179.5 273.984 179.444 273.846C179.387 273.708 179.359 273.56 179.36 273.41C179.36 273.111 179.479 272.823 179.691 272.611C179.903 272.399 180.191 272.28 180.49 272.28H232.92C233.22 272.28 233.508 272.399 233.719 272.611C233.931 272.823 234.05 273.111 234.05 273.41C234.052 273.56 234.023 273.708 233.967 273.846C233.911 273.984 233.828 274.11 233.723 274.216C233.618 274.322 233.493 274.406 233.355 274.463C233.217 274.521 233.07 274.55 232.92 274.55Z"
          fill="black"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M218.35 292.281H181.21C180.967 292.281 180.725 292.232 180.5 292.139C180.275 292.045 180.071 291.908 179.899 291.735C179.727 291.562 179.591 291.357 179.498 291.132C179.406 290.906 179.359 290.664 179.36 290.421C179.36 289.929 179.555 289.457 179.902 289.109C180.248 288.76 180.719 288.563 181.21 288.561H218.35C218.595 288.561 218.837 288.609 219.062 288.702C219.288 288.796 219.493 288.933 219.666 289.105C219.838 289.278 219.975 289.483 220.069 289.709C220.162 289.934 220.21 290.176 220.21 290.421C220.21 290.665 220.162 290.907 220.069 291.132C219.975 291.358 219.838 291.563 219.666 291.736C219.493 291.908 219.288 292.046 219.062 292.139C218.837 292.232 218.595 292.281 218.35 292.281Z"
          fill="black"
        />
        <path
          d="M232.92 302.001H180.49C180.341 302.001 180.193 301.971 180.056 301.914C179.918 301.856 179.793 301.772 179.688 301.666C179.583 301.56 179.5 301.434 179.444 301.296C179.387 301.158 179.359 301.01 179.36 300.861C179.359 300.711 179.387 300.563 179.444 300.425C179.5 300.287 179.583 300.161 179.688 300.055C179.793 299.949 179.918 299.865 180.056 299.808C180.193 299.75 180.341 299.721 180.49 299.721H232.92C233.07 299.721 233.217 299.75 233.355 299.808C233.493 299.865 233.618 299.949 233.723 300.055C233.828 300.161 233.911 300.287 233.967 300.425C234.023 300.563 234.052 300.711 234.05 300.861C234.052 301.01 234.023 301.158 233.967 301.296C233.911 301.434 233.828 301.56 233.723 301.666C233.618 301.772 233.493 301.856 233.355 301.914C233.217 301.971 233.07 302.001 232.92 302.001Z"
          fill="black"
        />
        <path
          d="M232.92 307.541H180.49C180.341 307.541 180.193 307.511 180.056 307.454C179.918 307.396 179.793 307.312 179.688 307.206C179.583 307.1 179.5 306.974 179.444 306.836C179.387 306.698 179.359 306.55 179.36 306.401C179.359 306.251 179.387 306.103 179.444 305.965C179.5 305.827 179.583 305.701 179.688 305.595C179.793 305.489 179.918 305.405 180.056 305.348C180.193 305.29 180.341 305.261 180.49 305.261H232.92C233.07 305.261 233.217 305.29 233.355 305.348C233.493 305.405 233.618 305.489 233.723 305.595C233.828 305.701 233.911 305.827 233.967 305.965C234.023 306.103 234.052 306.251 234.05 306.401C234.052 306.55 234.023 306.698 233.967 306.836C233.911 306.974 233.828 307.1 233.723 307.206C233.618 307.312 233.493 307.396 233.355 307.454C233.217 307.511 233.07 307.541 232.92 307.541Z"
          fill="black"
        />
        <path
          d="M232.92 313H180.49C180.191 313 179.903 312.881 179.691 312.669C179.479 312.458 179.36 312.17 179.36 311.87C179.359 311.721 179.387 311.573 179.444 311.435C179.5 311.297 179.583 311.171 179.688 311.065C179.793 310.959 179.918 310.875 180.056 310.817C180.193 310.76 180.341 310.73 180.49 310.73H232.92C233.07 310.73 233.217 310.76 233.355 310.817C233.493 310.875 233.618 310.959 233.723 311.065C233.828 311.171 233.911 311.297 233.967 311.435C234.023 311.573 234.052 311.721 234.05 311.87C234.05 312.019 234.021 312.166 233.964 312.303C233.908 312.44 233.824 312.565 233.719 312.669C233.614 312.774 233.49 312.858 233.353 312.914C233.216 312.971 233.069 313 232.92 313Z"
          fill="black"
        />
        <path
          d="M232.92 318.55H180.49C180.341 318.55 180.193 318.521 180.056 318.463C179.918 318.406 179.793 318.322 179.688 318.216C179.583 318.11 179.5 317.984 179.444 317.846C179.387 317.708 179.359 317.56 179.36 317.41C179.36 317.111 179.479 316.823 179.691 316.611C179.903 316.399 180.191 316.28 180.49 316.28H232.92C233.22 316.28 233.508 316.399 233.719 316.611C233.931 316.823 234.05 317.111 234.05 317.41C234.052 317.56 234.023 317.708 233.967 317.846C233.911 317.984 233.828 318.11 233.723 318.216C233.618 318.322 233.493 318.406 233.355 318.463C233.217 318.521 233.07 318.55 232.92 318.55Z"
          fill="black"
        />
      </g>
      <path
        d="M346.59 215.61C346.59 215.61 344.86 219.11 343.59 219.61C342.32 220.11 330.43 202.47 330.43 202.47C330.102 201.556 329.622 200.704 329.01 199.95C328.16 199.04 323.59 197.39 321.77 197.46C319.95 197.53 318.77 200.64 318.15 202.05C317.53 203.46 317.98 203.87 320.7 204.7C323.42 205.53 324.97 206.7 326.7 205.24C326.7 205.24 336.64 227.96 342.15 228.52C349.35 229.26 353.82 218.21 353.82 218.21L346.59 215.61Z"
        fill="#E4897B"
      />
      <path
        d="M351.52 198.16C351.52 198.16 346.17 214.06 344.65 218.44C344.65 218.44 350.3 224.97 354.98 225.11L360.15 209.77L351.52 198.16Z"
        fill="#F67C36"
      />
      <path
        opacity="0.2"
        d="M351.52 198.16C351.52 198.16 346.17 214.06 344.65 218.44C344.65 218.44 350.3 224.97 354.98 225.11L360.15 209.77L351.52 198.16Z"
        fill="white"
      />
      <path
        d="M365.58 417.23L364.49 412.29L364.3 403.38H356.85C356.05 400.64 353.85 402.17 353.85 402.17L352.85 410.39C350.96 412.08 346.53 413.86 340.64 415.14C339.33 415.43 339.74 418.6 342.95 418.6C346.16 418.6 353.17 418.36 355.49 418.36C359.26 418.36 361.49 418.54 364.49 418.59C364.573 418.6 364.657 418.6 364.74 418.59C364.884 418.556 365.021 418.494 365.141 418.407C365.261 418.32 365.363 418.21 365.441 418.084C365.519 417.958 365.572 417.817 365.596 417.671C365.619 417.524 365.614 417.374 365.58 417.23Z"
        fill="#263238"
      />
      <path
        d="M408.58 417.23L407.49 412.29L405.83 403.38H398.38C398.353 403.046 398.233 402.726 398.034 402.455C397.835 402.185 397.565 401.976 397.253 401.85C396.942 401.725 396.602 401.688 396.271 401.745C395.941 401.801 395.632 401.949 395.38 402.17L395.83 410.39C393.94 412.08 389.51 413.86 383.62 415.14C382.31 415.43 382.72 418.6 385.93 418.6C389.14 418.6 396.15 418.36 398.47 418.36C402.24 418.36 404.47 418.54 407.47 418.59C407.553 418.601 407.637 418.601 407.72 418.59C407.866 418.558 408.005 418.498 408.128 418.412C408.25 418.326 408.355 418.217 408.435 418.09C408.515 417.964 408.569 417.822 408.594 417.675C408.619 417.527 408.614 417.376 408.58 417.23Z"
        fill="#263238"
      />
      <path
        d="M407.61 408.6H394.37C394.37 408.6 380.53 338.11 379.42 330.32C378.75 325.58 377.36 279.8 377.19 277.62C376.41 279.8 365.03 325.53 365.03 325.53C365.03 325.53 367.18 380.73 365.86 406.53H352.26C352.26 406.53 346.56 353.96 347.83 325.53C349.75 282.62 358.83 243.8 359.88 238.05H400.44C406.24 264.99 400.52 331.05 400.52 331.05C400.52 331.05 405.86 382.85 407.61 408.6Z"
        fill="#263238"
      />
      <path
        d="M359.43 240.24L400.11 236.86L400.51 241.7L359.27 244.63L359.43 240.24Z"
        fill="black"
      />
      <path
        d="M386.857 235.756L385.402 235.877L386.117 244.477L387.572 244.356L386.857 235.756Z"
        fill="#263238"
      />
      <path
        d="M366.26 237.494L364.805 237.615L365.519 246.216L366.974 246.095L366.26 237.494Z"
        fill="#263238"
      />
      <path
        d="M377.903 237.859L370.379 238.484L370.829 243.906L378.353 243.28L377.903 237.859Z"
        fill="white"
      />
      <path
        d="M365.64 181.6C375.05 176.01 390.48 182.32 390.48 182.32C390.86 184.17 400.08 202.79 400.43 238.08L359.43 240.24C359.43 240.24 355.43 205.24 351.52 198.16C351.52 198.16 353.15 189 365.64 181.6Z"
        fill="#F67C36"
      />
      <path
        opacity="0.4"
        d="M400.43 238.08L359.43 240.24C359.43 240.24 355.43 205.24 351.52 198.16C351.52 198.16 353.15 189.02 365.64 181.6C375.05 176.01 390.48 182.32 390.48 182.32C394.459 192.446 397.202 203.016 398.65 213.8C399.775 221.845 400.37 229.956 400.43 238.08Z"
        fill="white"
      />
      <path
        d="M360.57 180.58C361.231 181.481 361.582 182.572 361.57 183.69C361.308 184.534 360.812 185.286 360.14 185.86C361.254 186.578 362.515 187.036 363.83 187.2L368.94 184.87C368.94 184.87 371.48 184.32 372.13 183.42C372.67 182.68 373.25 181.95 373.73 181.17C373.97 180.762 374.18 180.338 374.36 179.9C373.02 179.863 371.742 179.333 370.77 178.41C368.56 175.92 365 165.63 365 165.63L356.25 175C356.25 175 356.45 175.2 356.76 175.56C358.169 177.123 359.443 178.803 360.57 180.58Z"
        fill="#E4897B"
      />
      <path
        opacity="0.2"
        d="M366.07 172.44C366.07 172.44 365.96 178.31 360.57 180.58C359.442 178.81 358.168 177.137 356.76 175.58C357.09 175.24 357.31 175.03 357.31 175.03L366.07 172.44Z"
        fill="black"
      />
      <path
        d="M342.14 156.49C342.14 156.49 343.19 166.79 349.44 169.24C355.69 171.69 350.92 153.24 350.92 153.24L342.14 156.49Z"
        fill="#263238"
      />
      <path
        d="M366.74 173.18C366.057 175.095 364.765 176.733 363.062 177.844C361.359 178.954 359.338 179.476 357.31 179.33C354.686 179.179 352.214 178.043 350.39 176.15C350.14 175.89 349.9 175.61 349.67 175.32C345.52 169.93 341.96 159.55 347.34 153.93C348.523 152.683 350.005 151.759 351.645 151.246C353.285 150.733 355.029 150.648 356.711 150.998C358.394 151.349 359.958 152.124 361.257 153.25C362.555 154.375 363.545 155.814 364.13 157.43C366.74 164.31 368.6 168.23 366.74 173.18Z"
        fill="#E4897B"
      />
      <path
        d="M354.78 163.88C354.919 164.076 354.976 164.318 354.939 164.556C354.901 164.793 354.773 165.006 354.58 165.15C354.21 165.32 353.73 165 353.49 164.45C353.25 163.9 353.34 163.33 353.69 163.18C354.04 163.03 354.53 163.33 354.78 163.88Z"
        fill="#263238"
      />
      <path
        d="M348.61 166.65C348.748 166.848 348.804 167.091 348.764 167.328C348.725 167.566 348.594 167.778 348.4 167.92C348.04 168.08 347.55 167.77 347.31 167.22C347.07 166.67 347.16 166.1 347.51 165.95C347.86 165.8 348.37 166.13 348.61 166.65Z"
        fill="#263238"
      />
      <path
        d="M350.69 166C350.788 167.961 350.613 169.927 350.17 171.84C350.667 171.94 351.179 171.93 351.671 171.811C352.164 171.692 352.624 171.466 353.02 171.15L350.69 166Z"
        fill="#DE5753"
      />
      <path
        d="M345.13 165C345.194 165.01 345.259 165.001 345.318 164.974C345.376 164.947 345.426 164.904 345.46 164.85C345.698 164.476 346.019 164.164 346.4 163.938C346.78 163.712 347.209 163.579 347.65 163.55C347.743 163.55 347.832 163.513 347.898 163.447C347.963 163.381 348 163.292 348 163.2C348 163.107 347.963 163.018 347.898 162.952C347.832 162.886 347.743 162.85 347.65 162.85C347.094 162.876 346.553 163.035 346.071 163.314C345.589 163.592 345.181 163.981 344.88 164.45C344.828 164.526 344.807 164.619 344.822 164.711C344.837 164.802 344.886 164.884 344.96 164.94L345.13 165Z"
        fill="#263238"
      />
      <path
        d="M354.9 160.86C354.964 160.826 355.016 160.774 355.05 160.71C355.064 160.667 355.069 160.621 355.064 160.576C355.06 160.531 355.047 160.487 355.026 160.447C355.004 160.407 354.975 160.371 354.94 160.343C354.904 160.314 354.864 160.293 354.82 160.28C354.286 160.114 353.72 160.077 353.169 160.173C352.617 160.269 352.097 160.494 351.65 160.83C351.586 160.896 351.55 160.984 351.55 161.075C351.55 161.167 351.586 161.255 351.65 161.32C351.715 161.384 351.803 161.42 351.895 161.42C351.986 161.42 352.074 161.384 352.14 161.32C352.501 161.065 352.917 160.896 353.354 160.829C353.791 160.761 354.238 160.796 354.66 160.93C354.746 160.935 354.83 160.911 354.9 160.86Z"
        fill="#263238"
      />
      <path
        d="M348.89 149.46L356.27 154.46C356.875 154.505 357.441 154.776 357.855 155.218C358.27 155.661 358.504 156.243 358.51 156.85C358.51 159.77 361.51 163.14 364.08 163.95C364.08 163.95 363.71 162.83 364.74 162.72C365.77 162.61 367.74 168.45 367.02 171.89C367.02 171.89 368.79 168.34 368.02 161.73C367.26 155.6 364.4 147.29 361.9 146.53C356.2 144.79 348.89 149.46 348.89 149.46Z"
        fill="#263238"
      />
      <path
        d="M369.53 161.62C369.467 163.472 368.672 165.223 367.32 166.49C365.51 168.16 363.5 166.81 363.06 164.49C362.69 162.49 363.14 159.09 365.42 158.31C365.918 158.142 366.451 158.103 366.969 158.195C367.486 158.288 367.972 158.509 368.382 158.839C368.792 159.169 369.111 159.596 369.312 160.082C369.513 160.569 369.588 161.097 369.53 161.62Z"
        fill="#E4897B"
      />
      <path
        d="M354.22 172.74C354.911 173.358 355.556 174.027 356.15 174.74C356.29 174.66 356.44 174.58 356.57 174.49C358.49 173.35 359 172.18 358.98 171.24C358.963 170.779 358.832 170.329 358.6 169.93C358.481 169.699 358.33 169.487 358.15 169.3C357.279 170.522 356.157 171.545 354.86 172.3L354.22 172.74Z"
        fill="#263238"
      />
      <path
        d="M354.86 172.34L355.45 172.92C357.25 171.83 358.37 170.82 358.6 169.92C358.482 169.689 358.33 169.477 358.15 169.29C357.285 170.531 356.163 171.571 354.86 172.34Z"
        fill="white"
      />
      <path
        d="M356.57 174.5C358.49 173.36 359 172.19 358.98 171.25C358.261 171.697 357.627 172.269 357.11 172.94C356.773 173.392 356.584 173.937 356.57 174.5Z"
        fill="#DE5753"
      />
      <path
        d="M342.14 156.491C342.14 157.351 344.48 159.35 347.73 159.31C350.98 159.27 346.86 157.9 346.86 157.9C346.86 157.9 353.11 157.2 352.93 156.45C352.75 155.7 350.62 155.45 350.62 155.45C350.62 155.45 356.09 155.451 359.3 152.831C362.51 150.211 353.05 147.331 348.89 149.441C344.73 151.551 342.27 153.871 342.14 156.491Z"
        fill="#263238"
      />
      <path
        d="M355 170.73C355 170.73 357.36 167.58 358.4 167.4C359.44 167.22 364.5 175.4 364.5 175.4C364.5 175.4 365.25 177.28 360.64 178.93C356.03 180.58 356.12 179.48 354.99 177.5C353.86 175.52 352.11 174.41 351.91 173.1C351.71 171.79 354.6 171.19 354.6 171.19L354.92 171.46C354.92 171.46 353.23 172.32 353.45 173.3C353.67 174.28 355.19 177.25 356.81 177.44C358.43 177.63 357.74 175.97 357.74 175.97L359.02 175.02C359.02 175.02 360.14 176.19 361.08 175.66C362.02 175.13 361.27 172.41 360.23 170.93C359.19 169.45 359.02 169.13 358.12 169.27C357.127 169.679 356.218 170.269 355.44 171.01L355 170.73Z"
        fill="#263238"
      />
      <path
        d="M348.855 167.605L345.58 168.869C345.374 168.948 345.272 169.18 345.351 169.386L345.891 170.785C345.971 170.991 346.202 171.094 346.408 171.014L349.683 169.751C349.889 169.671 349.992 169.44 349.912 169.234L349.372 167.834C349.293 167.628 349.061 167.526 348.855 167.605Z"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M355.306 165.111L352.031 166.375C351.825 166.454 351.723 166.686 351.802 166.892L352.342 168.291C352.422 168.497 352.653 168.6 352.859 168.52L356.134 167.257C356.34 167.177 356.443 166.946 356.363 166.74L355.823 165.34C355.744 165.134 355.512 165.031 355.306 165.111Z"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M349.65 168.55C349.897 168.152 350.267 167.845 350.703 167.676C351.14 167.506 351.62 167.483 352.07 167.61"
        stroke="#263238"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M367.05 185.26L370.39 176.79L376.05 178.78L372.94 190.08L366.91 186.26L364.48 192.54L358.64 186.53L361.15 181.69L367.05 185.26Z"
        fill="#F67C36"
      />
      <path
        opacity="0.2"
        d="M367.05 185.26L370.39 176.79L376.05 178.78L372.94 190.08L366.91 186.26L364.48 192.54L358.64 186.53L361.15 181.69L367.05 185.26Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M398.65 213.8C383.4 203.2 390.48 182.32 390.48 182.32C394.46 192.447 397.203 203.017 398.65 213.8Z"
        fill="black"
      />
      <path
        d="M317.46 202C313.85 202.77 304.12 220.62 302.9 220.48C299.31 220.05 299.49 210.48 297.49 211.48C296.864 211.85 296.349 212.381 295.997 213.018C295.645 213.654 295.47 214.373 295.49 215.1C295.49 215.1 296.03 224.02 299.49 227.33C305.56 233.17 317.49 213.91 317.49 213.91L317.46 202Z"
        fill="#F67C36"
      />
      <path
        opacity="0.7"
        d="M317.46 202V214C317.46 214 305.81 232.86 299.68 227.59L299.49 227.42C296.05 224.11 295.49 215.19 295.49 215.19C295.47 214.463 295.645 213.744 295.997 213.108C296.349 212.471 296.864 211.94 297.49 211.57C299.39 210.57 299.32 219.15 302.38 220.39C302.545 220.462 302.72 220.509 302.9 220.53C304.12 220.66 313.85 202.81 317.46 202Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M317.46 202V214C317.46 214 305.81 232.86 299.68 227.59C302.88 226.59 302.38 221.59 302.38 220.39C302.545 220.462 302.72 220.509 302.9 220.53C304.12 220.66 313.85 202.81 317.46 202Z"
        fill="black"
      />
      <path
        d="M309.29 253.75C309.29 253.75 299.1 308.07 296.38 330.37C292.82 359.47 278.3 402 278.3 402L284.11 403.5C284.11 403.5 304.59 367.33 308.58 334.79C324.85 301.53 326.16 260.57 326.16 260.57L309.29 253.75Z"
        fill="#EEC1BB"
      />
      <path
        d="M267.48 415.2C268.426 414.767 269.442 414.509 270.48 414.44C270.48 414.44 274.06 412.34 277.6 404.17H281.6C282.272 404.176 282.926 404.39 283.472 404.782C284.018 405.174 284.429 405.725 284.65 406.36C284.719 406.536 284.76 406.721 284.77 406.91C284.731 407.872 284.628 408.831 284.46 409.78C283.874 412.944 283.096 416.07 282.13 419.14L281.46 418.97L282.69 413.03C283.09 411.03 280.97 412.96 279.99 415.03C279.01 417.1 277.5 419.11 273.1 418.11C267.94 416.86 265.87 416.12 267.48 415.2Z"
        fill="#2E353A"
      />
      <path
        d="M319.87 252.88C319.87 252.88 321.14 308.14 323.09 330.52C325.63 359.72 320.22 404.37 320.22 404.37L326.22 404.64C326.22 404.64 338.77 365 336 332.35C345 296.44 337.85 256.1 337.85 256.1L319.87 252.88Z"
        fill="#EEC1BB"
      />
      <path
        d="M310.89 417C311.78 416.468 312.759 416.102 313.78 415.92C313.78 415.92 317.1 413.44 319.78 404.92L323.73 404.47C324.401 404.406 325.074 404.549 325.661 404.879C326.248 405.21 326.719 405.713 327.01 406.32C327.091 406.488 327.148 406.666 327.18 406.85C327.24 407.813 327.24 408.778 327.18 409.74C326.938 412.95 326.504 416.142 325.88 419.3L325.19 419.2L325.76 413.2C325.95 411.15 324.05 413.32 323.3 415.45C322.55 417.58 321.3 419.78 316.78 419.25C311.53 418.62 309.4 418.11 310.89 417Z"
        fill="#2E353A"
      />
      <path
        d="M307 245.52L286.2 359.62C286.2 359.62 333.54 365.27 349.59 361.97C349.59 361.97 353.52 279.71 336.77 247.25L307 245.52Z"
        fill="#F67C36"
      />
      <path
        opacity="0.2"
        d="M307 245.52L286.2 359.62C286.2 359.62 333.54 365.27 349.59 361.97C349.59 361.97 353.52 279.71 336.77 247.25L307 245.52Z"
        fill="white"
      />
      <path
        d="M278.3 402C278.3 402 273.3 412.4 270.46 414.41C270.46 414.41 274.3 416.7 278.3 411.61C283.46 405.02 284.73 406.86 284.73 406.86C284.625 405.723 284.417 404.599 284.11 403.5C283.33 401.41 278.3 402 278.3 402Z"
        fill="#EEC1BB"
      />
      <path
        d="M320.83 402C320.83 402 319.42 405.85 317.54 410.08C316.678 412.273 315.401 414.28 313.78 415.99C313.78 415.99 317.04 417.05 318.78 414.99C320.52 412.93 323.56 405.55 326.94 406.4C326.759 405.799 326.507 405.222 326.19 404.68C325.71 403.8 320.83 402 320.83 402Z"
        fill="#EEC1BB"
      />
      <path
        d="M327.419 188.48C327.419 188.48 322.419 174.72 335.039 172.29C347.659 169.86 353.909 180.18 350.429 190.22C350.429 190.22 355.049 192.11 352.729 195.65C350.409 199.19 355.279 197.86 356.129 201.47C356.979 205.08 352.459 204.37 354.979 205.66C357.499 206.95 362.039 211.24 354.789 214.9C347.539 218.56 328.789 220.11 325.169 214.57C321.549 209.03 322.629 204.73 325.639 202.66C328.649 200.59 324.419 202.4 324.639 197.86C324.859 193.32 326.979 194.86 326.829 193.01C326.679 191.16 325.879 189 327.419 188.48Z"
        fill="#263238"
      />
      <path
        d="M348.68 222.73C346.79 226 345.25 228.84 343.95 231.41C340.769 237.489 338.27 243.901 336.5 250.53L308.3 242.69C311.71 222.4 314.72 211.51 316.49 206.12C318.39 200.38 319.34 200.37 319.34 200.37C319.34 200.37 325.84 201.2 331.34 202.5C335.592 203.617 339.759 205.034 343.81 206.74C347.67 208.312 351.41 210.164 355 212.28C353.94 213.97 352.95 215.59 352 217.11C350.8 219.12 349.7 221 348.68 222.73Z"
        fill="#F67C36"
      />
      <path
        opacity="0.8"
        d="M355 212.28C354.007 213.847 353.084 215.344 352.23 216.77L352.02 217.11C351.3 218.3 350.62 219.44 349.97 220.54C349.54 221.3 349.1 222.02 348.7 222.73C348.56 222.97 348.41 223.21 348.29 223.44C346.57 226.44 345.16 229.04 343.97 231.44C342.41 234.49 341.2 237.16 340.19 239.68C339.87 240.46 339.57 241.23 339.29 241.99C338.95 242.94 338.63 243.88 338.29 244.82C337.55 247.01 337.2 248.14 336.49 250.56L308.28 242.72C311.7 222.44 314.71 211.54 316.48 206.15C318.38 200.42 319.33 200.4 319.33 200.4C319.33 200.4 325.83 201.23 331.33 202.53C335.58 203.643 339.744 205.06 343.79 206.77C347.656 208.332 351.402 210.174 355 212.28Z"
        fill="white"
      />
      <path
        d="M338.27 246.89L308.01 242.02L306.8 246.86L338.82 251.61L338.27 246.89Z"
        fill="#263238"
      />
      <path
        d="M315.999 244.81L315.829 247.13C315.803 247.568 315.938 248 316.209 248.345C316.48 248.689 316.868 248.923 317.299 249L320.909 249.59H321.069C321.522 249.622 321.97 249.487 322.329 249.21C322.699 248.907 322.939 248.474 322.999 248L323.169 245.68C323.205 245.228 323.068 244.778 322.786 244.422C322.504 244.066 322.098 243.83 321.649 243.76L318.039 243.18C317.792 243.14 317.538 243.152 317.295 243.216C317.053 243.28 316.826 243.394 316.629 243.55C316.446 243.708 316.297 243.901 316.188 244.117C316.08 244.334 316.016 244.569 315.999 244.81ZM316.759 247.19L316.819 246.43L317.819 246.58C317.973 246.587 318.123 246.536 318.241 246.439C318.359 246.341 318.436 246.203 318.459 246.051C318.481 245.9 318.447 245.745 318.362 245.618C318.277 245.49 318.148 245.398 317.999 245.36L316.999 245.21H316.859V244.86C316.868 244.74 316.902 244.622 316.959 244.516C317.016 244.409 317.094 244.315 317.189 244.24C317.361 244.101 317.579 244.033 317.799 244.05H317.869L321.479 244.63C321.698 244.663 321.896 244.777 322.033 244.949C322.17 245.122 322.237 245.34 322.219 245.56L322.049 247.88C322.041 248.001 322.007 248.118 321.95 248.225C321.893 248.332 321.815 248.426 321.719 248.5C321.624 248.578 321.513 248.634 321.394 248.665C321.275 248.696 321.151 248.701 321.029 248.68L317.419 248.09C317.214 248.05 317.03 247.936 316.901 247.771C316.773 247.606 316.708 247.399 316.719 247.19H316.759Z"
        fill="white"
      />
      <path
        d="M319.56 194.21L322.71 224.5C322.733 224.664 322.721 224.83 322.673 224.988C322.626 225.146 322.544 225.292 322.435 225.416C322.326 225.54 322.191 225.638 322.04 225.704C321.889 225.771 321.725 225.804 321.56 225.8H302.56C302.205 225.791 301.866 225.655 301.603 225.416C301.341 225.177 301.173 224.853 301.13 224.5L297.98 194.21C297.957 194.047 297.97 193.88 298.017 193.721C298.064 193.563 298.145 193.416 298.255 193.292C298.364 193.167 298.498 193.068 298.649 193C298.801 192.933 298.964 192.899 299.13 192.9H318.13C318.485 192.912 318.825 193.051 319.087 193.291C319.35 193.531 319.517 193.857 319.56 194.21Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M318.56 194.21L321.71 224.5C321.733 224.664 321.721 224.83 321.673 224.988C321.626 225.146 321.544 225.292 321.435 225.416C321.326 225.54 321.191 225.638 321.04 225.704C320.889 225.771 320.725 225.804 320.56 225.8H302.56C302.205 225.791 301.866 225.655 301.603 225.416C301.341 225.177 301.173 224.853 301.13 224.5L297.98 194.21C297.957 194.047 297.97 193.88 298.017 193.721C298.064 193.563 298.145 193.416 298.255 193.292C298.364 193.167 298.498 193.068 298.649 193C298.801 192.933 298.964 192.899 299.13 192.9H317.13C317.485 192.912 317.825 193.051 318.087 193.291C318.35 193.531 318.517 193.857 318.56 194.21Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M310.17 205.26C310.076 205.254 309.983 205.254 309.89 205.26C308.55 205.26 307.61 206.68 307.79 208.42C307.97 210.16 309.21 211.59 310.55 211.59C311.89 211.59 312.83 210.17 312.65 208.42C311.951 208.231 311.332 207.821 310.885 207.251C310.439 206.682 310.188 205.984 310.17 205.26Z"
        fill="white"
      />
      <path
        d="M301 205.68C301 205.68 295.37 209.17 295.52 211.09C295.6 212.09 295.79 215.02 296.85 215.44C298.63 216.15 303.59 212.1 303.48 211.56C303.37 211.02 302.89 206.07 301 205.68Z"
        fill="#EEC1BB"
      />
      <path
        d="M338.31 202.82C338.31 202.82 349.71 206.92 347.49 212.55C345.27 218.18 334 211.2 334 211.2L328.73 222.13L332.05 210.76C332.05 210.76 324.72 213.4 325.05 208.18C325.38 202.96 330.55 201.75 338.31 202.82Z"
        fill="white"
      />
      <path
        d="M344.65 196.61C344.003 197.427 343.446 198.312 342.99 199.25C341.9 201.48 340.99 204.74 342.91 207.61C342.91 207.61 340.27 209.99 333.44 209.33C333.44 209.33 330.64 206.42 332.64 204.11C332.64 204.11 333.64 204.31 334.57 203.01C335.242 202.013 335.692 200.885 335.89 199.7C336.122 198.568 336.272 197.422 336.34 196.27L344.65 196.61Z"
        fill="#EEC1BB"
      />
      <path
        opacity="0.2"
        d="M344.65 196.61C344.003 197.427 343.446 198.312 342.99 199.25C341.992 200.506 340.705 201.504 339.24 202.158C337.774 202.812 336.173 203.105 334.57 203.01C335.242 202.013 335.692 200.885 335.89 199.7C336.122 198.568 336.272 197.422 336.34 196.27L344.65 196.61Z"
        fill="black"
      />
      <path
        d="M336.11 176.31C329.02 177.07 327.11 181.12 327.42 188.48C327.75 197.68 330.48 203.95 339.13 201.05C350.89 197.1 347.89 175 336.11 176.31Z"
        fill="#EEC1BB"
      />
      <path
        d="M332 187.59C331.544 188.709 330.957 189.77 330.25 190.75C330.496 191.024 330.813 191.226 331.165 191.332C331.518 191.437 331.894 191.444 332.25 191.35L332 187.59Z"
        fill="#D4827D"
      />
      <path
        d="M336.24 186.54C336.24 187.12 336.64 187.54 337.03 187.54C337.42 187.54 337.69 187.01 337.65 186.42C337.61 185.83 337.24 185.42 336.85 185.42C336.46 185.42 336.19 186 336.24 186.54Z"
        fill="#263238"
      />
      <path
        d="M337 185.44L335.68 185.05C335.68 185.05 336.43 186.06 337 185.44Z"
        fill="#263238"
      />
      <path
        d="M329 187C329.05 187.59 329.41 188 329.8 188C330.19 188 330.46 187.47 330.41 186.88C330.36 186.29 330.01 185.88 329.62 185.88C329.23 185.88 328.92 186.45 329 187Z"
        fill="#263238"
      />
      <path
        d="M329.75 185.93L328.43 185.54C328.43 185.54 329.16 186.55 329.75 185.93Z"
        fill="#263238"
      />
      <path
        d="M335.78 181.64C336.333 181.509 336.909 181.508 337.462 181.636C338.015 181.765 338.531 182.019 338.97 182.38"
        stroke="#263238"
        strokeWidth="0.72468"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M330.59 181.891C330.093 181.82 329.586 181.863 329.107 182.017C328.629 182.171 328.192 182.432 327.83 182.781"
        stroke="#263238"
        strokeWidth="0.72468"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M333.5 194C333.922 194.766 334.29 195.562 334.6 196.38L335.05 196.28C337.05 195.85 337.89 194.98 338.17 194.14C338.29 193.728 338.314 193.293 338.24 192.87C338.202 192.628 338.131 192.393 338.03 192.17C336.886 192.992 335.581 193.561 334.2 193.84C333.77 194 333.5 194 333.5 194Z"
        fill="#263238"
      />
      <path
        d="M334.2 193.87L334.54 194.57C336.46 194.15 337.76 193.57 338.24 192.9C338.202 192.658 338.131 192.423 338.03 192.2C336.887 193.022 335.581 193.592 334.2 193.87Z"
        fill="white"
      />
      <path
        d="M335.05 196.31C337.05 195.88 337.89 195.01 338.17 194.17C337.393 194.347 336.655 194.662 335.99 195.1C335.555 195.392 335.225 195.816 335.05 196.31Z"
        fill="#DE5753"
      />
      <path
        d="M345.15 187.42C345.15 187.42 341.21 181.95 341.22 177.42C341.22 177.42 326.55 173.2 327.4 187.54C327.4 187.54 324.66 175.02 336.82 173.42C348.98 171.82 350.82 189.66 345.13 195.66C345.13 195.64 347.6 190.85 345.15 187.42Z"
        fill="#263238"
      />
      <path
        d="M344.15 189.05C344.131 188.22 344.442 187.417 345.013 186.816C345.585 186.214 346.371 185.863 347.2 185.84C349.42 185.84 350.95 190.24 345.61 191.92C344.84 192.16 344.31 191.44 344.15 189.05Z"
        fill="#EEC1BB"
      />
      <path
        d="M342.06 177.29C342.06 177.29 334.95 176.29 333.81 178.35C332.67 180.41 327.21 179.5 327.1 185.1C327.1 185.1 324.38 177.84 331.79 175.59C339.2 173.34 342.06 177.29 342.06 177.29Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M351.37 236.53L351.92 236.94L351.35 236.55L351.37 236.53Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M352.22 216.77L352.01 217.11C351.29 218.3 350.61 219.44 349.96 220.54C349.53 221.3 349.09 222.02 348.69 222.73C348.55 222.97 348.4 223.21 348.28 223.44C346.56 226.44 345.15 229.04 343.96 231.44C342.4 234.49 341.19 237.16 340.18 239.68C336.56 234.25 337.08 218.97 337.08 218.97H339.6C339.6 218.97 343.04 225.8 344.6 226.13C346.16 226.46 351.3 215.55 351.3 215.55L352.22 216.77Z"
        fill="black"
      />
      <path
        d="M341.65 221L339.88 217.57C339.911 216.779 339.768 215.99 339.46 215.26C339.02 214.67 329.99 213.72 328.74 213.67C327.49 213.62 328.32 220.06 328.74 220.67C329.16 221.28 336.38 221.4 337.37 220.67L338.82 223.95L341.65 221Z"
        fill="#EEC1BB"
      />
      <path
        d="M355 212.28C359.47 215.55 357 239.7 350 240.88C343.21 242.02 337.34 222.51 337.34 222.51L341.62 219.58C341.62 219.58 346.95 231.77 348.38 231.27C350.85 230.41 349.93 220.54 349.93 220.54C350.292 218.847 350.756 217.177 351.32 215.54C352.14 213.37 355 212.28 355 212.28Z"
        fill="#F67C36"
      />
      <path
        opacity="0.7"
        d="M355 212.28C359.47 215.55 357 239.7 350 240.88C343.21 242.02 337.34 222.51 337.34 222.51L341.62 219.58C341.62 219.58 346.95 231.77 348.38 231.27C350.85 230.41 349.93 220.54 349.93 220.54C350.292 218.847 350.756 217.177 351.32 215.54C352.14 213.37 355 212.28 355 212.28Z"
        fill="white"
      />
      <path
        d="M386.48 199.33C386.48 199.33 386.15 209.82 384.56 213.28C382.97 216.74 361.42 205.68 361.42 205.68C360.535 204.911 359.569 204.241 358.54 203.68C357.26 203.09 347.82 203.52 346.41 204.68C345 205.84 347.64 211.46 347.64 211.46C347.64 211.46 356.85 212.95 358.64 212.76C359.212 212.658 359.757 212.435 360.236 212.106C360.716 211.776 361.119 211.348 361.42 210.85C361.42 210.85 377.42 220.21 383.15 220.35C388.88 220.49 391.96 217.1 393.86 204.18L386.48 199.33Z"
        fill="#E4897B"
      />
      <path
        d="M390.48 182.32C385.8 179.78 384.56 203.68 384.56 203.68L394.85 209.44C394.85 209.44 400 187.47 390.48 182.32Z"
        fill="#F67C36"
      />
      <path
        opacity="0.2"
        d="M390.48 182.32C385.8 179.78 384.56 203.68 384.56 203.68L394.85 209.44C394.85 209.44 400 187.47 390.48 182.32Z"
        fill="white"
      />
    </svg>
  );
};

export default noRental;
