import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Dropdown, Row } from "antd";
import { IoIosArrowDown, IoIosClose } from "react-icons/io";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import {
  BenchMarkSvg,
  HeaderSquareSvg,
  HeaderStyleSvg,
  MenuSvg,
  PropDetailSvg,
} from "../../../assets/svgs";
import {
  StyledHeaderDropdownContentWrappeer,
  StyledHeaderDropdownMenuItem,
} from "../header/Header";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";

const TopSearchSection = () => {
  const { width } = useWindowSize();
  const navigate = useNavigate();

  const mainWidth = 1224;
  const mainHeight = 774;

  const imgMobWidth = 323.978;
  const imgMobHeight = 501.613;

  const imgLapWidth = 1057.53;
  const imgLapHeight = 726.189;

  const imgDashWidth = 406.813;
  const imgDashHeight = 228.257;

  const [wSize, setWSize] = useState(1224);
  const [hSize, setHSize] = useState("calc(100vh - 110px)");

  const [svgMobWidth, setsvgMobWidth] = useState(323.978);
  const [svgMobHeight, setsvgMobHeight] = useState(501.613);

  const [svgLapWidth, setsvgLapWidth] = useState(1057.53);
  const [svgLapHeight, setsvgLapHeight] = useState(726.189);

  const [svgDashWidth, setsvgDashWidth] = useState(1057.53);
  const [svgDashHeight, setsvgDashHeight] = useState(726.189);

  useEffect(() => {
    const totalWidth = 1920;
    const newWidth = (100 * width) / totalWidth;
    setWSize(newWidth);

    if (width < 1550) {
      setHSize("auto");
    } else {
      setHSize("calc(100vh - 110px)");
    }
    // setWSize((newWidth * mainWidth) / 100);
    // setHSize((newWidth * mainHeight) / 100);

    // setsvgMobWidth((newWidth * imgMobWidth) / 100);
    // setsvgMobHeight((newWidth * imgMobHeight) / 100);

    // setsvgLapWidth((newWidth * imgLapWidth) / 100);
    // setsvgLapHeight((newWidth * imgLapHeight) / 100);

    // setsvgDashWidth((newWidth * imgDashWidth) / 100);
    // setsvgDashHeight((newWidth * imgDashHeight) / 100);
  }, [width]);

  const menu = (
    <>
      <StyledHeaderDropdownContentWrappeer>
        <StyledHeaderDropdownMenuItem key="6">
          <Link to="/login">Login</Link>
        </StyledHeaderDropdownMenuItem>
        <Divider />
        <StyledHeaderDropdownMenuItem key="4">
          <Link to="/" className="nav-link need__help">
            Need a Help?
          </Link>
        </StyledHeaderDropdownMenuItem>
      </StyledHeaderDropdownContentWrappeer>
    </>
  );

  const [openSideBar, setopenSider] = useState(true);

  const openSider = (e) => {
    e.preventDefault();
    let x = document.getElementsByClassName("openSiderMenu");
    if (x.length > 0) {
      document.body.classList.remove("openSiderMenu");
      setopenSider(true);
    } else {
      document.body.classList.add("openSiderMenu");
      setopenSider(false);
    }
  };

  const createNewAccount = () => {
    // navigate("/owners-page");
    window.open("https://admin.avenew.app/registration");
  };

  return (
    <MainContainer
      openSideBar={openSideBar}
      className="sticky-header-sidebar"
      containterHeight={hSize}
      paddingBottom="50px"
    >
      <SidebarContainer className="sidebar-panel">
        <SideMainWrap>
          <LeftSidehWrap
            style={{ flexDirection: "column", paddingBottom: "20px" }}
          >
            <span> Home</span>
            <span> Pricing</span>
            <span> Contact Us</span>
          </LeftSidehWrap>
          <StyledButton
            paddingBtn="4px 10px"
            onClick={() => createNewAccount()}
          >
            Create an account
          </StyledButton>
          <SideBarLogin>
            <Link to="/login">Login</Link>
            <Divider />
            <Link to="/" className="nav-link need__help">
              Need a Help?
            </Link>
          </SideBarLogin>
        </SideMainWrap>
      </SidebarContainer>
      <HeaderWrap>
        <SearchWrap>
          <Row justify="space-between" style={{ width: "100%" }}>
            <Col xs={20} sm={20} md={20} lg={0} xl={0} xxl={0}>
              <LeftSidehWrap>
                <Link to="/" className="nav-link">
                  <img
                    src="/images/avenewLogoSearch.png"
                    alt="Avenew Logo"
                    className="avenew_logo"
                  />
                </Link>
              </LeftSidehWrap>
            </Col>
            <Col
              xs={4}
              sm={4}
              md={4}
              lg={0}
              xl={0}
              xxl={0}
              className="menu__header"
              onClick={openSider}
            >
              <MenuSvg />
            </Col>
            <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12}>
              <LeftSidehWrap>
                <Link to="/" className="nav-link">
                  <img
                    src="/images/avenewLogoSearch.png"
                    alt="Avenew Logo"
                    className="avenew_logo"
                  />
                </Link>
                <span> Home</span>
                <span> Pricing</span>
                <span> Contact Us</span>
              </LeftSidehWrap>
            </Col>
            <Col
              className="right__header"
              xs={0}
              sm={0}
              md={0}
              lg={12}
              xl={12}
              xxl={12}
              // style={{
              //   alignItems: "center",
              //   display: "flex",
              //   justifyContent: "end",
              // }}
            >
              <RightSidehWrap>
                <h1>Find property</h1>
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomRight"
                  overlayClassName="header_dropdown"
                >
                  <img
                    src="/images/userProfileIcon.svg"
                    className="user__icon"
                  />
                </Dropdown>
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomRight"
                  overlayClassName="header_dropdown"
                >
                  <IoIosArrowDown className="header__arrow" />
                </Dropdown>
                <StyledButton onClick={() => createNewAccount()}>
                  Create an account
                </StyledButton>
              </RightSidehWrap>
            </Col>
          </Row>
        </SearchWrap>
      </HeaderWrap>
      <div className="circle__svg">
        <HeaderStyleSvg />
      </div>
      <LaptopSvg wSize={wSize}>
        <DemoWrap>
          <Row>
            <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
              <h3>
                All the resources required to develop a profitable real estate
                firm.
              </h3>
              <H2Style marginBottom="20px" marginBottomSm="20px">
                Software for managing properties, constructed with you in mind.
              </H2Style>
              <StyledButton style={{ marginLeft: "0px", color: "#ffffff" }}>
                Request a demo
              </StyledButton>
            </Col>
          </Row>
        </DemoWrap>
        <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
          <div className="laptop__svg__mob">
            <img style={{ width: "100%" }} src="./images/OwnersGroup.png" />
          </div>
        </Col>
        <div className="laptop__svg">
          <BenchMarkSvg />
        </div>
      </LaptopSvg>
      <div className="square__svg">
        <HeaderSquareSvg />
      </div>
    </MainContainer>
  );
};

export default TopSearchSection;

const SideBarLogin = styled.div`
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;

  a {
    font-weight: 700;
    font-size: 16px;
    line-height: 0;
    color: #2b353f;
  }
  .ant-divider-horizontal {
    margin: 15px 30px;
    background: #dddddd;
    width: 75%;
    min-width: 75%;
  }
`;
const SideMainWrap = styled.div`
  height: calc(100vh - 250px);
  position: relative;
`;

const SidebarContainer = styled.div`
  padding: 15px;
  width: 280px;
  background: #ecf2ff;
  box-shadow: 2px 0px 14px rgba(0, 0, 0, 0.06);
  display: block;
  padding: 80px 15px 15px 15px;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  transition: ease-in-out 0.5s;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;

  @media (max-width: 1550px) {
    width: 225px;
    padding: 15px;
  }

  @media (max-width: 991.98px) {
    z-index: 111;
    margin-top: 90px;
    padding: 15px;
  }

  // scrollbar css
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0em;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 5px;
  }
`;

// HEADER ALL WRAPPER
export const StyledHeaderTop = styled.div`
  height: 80px;
  background-color: #aaaaaa;
  background-color: ${({ theme }) => theme.colors.primary};
  background-size: cover;
  background-position: 100% 100%;
  display: flex;
  padding: 0 30px;
  position: fixed;
  width: 100%;
  z-index: 10;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (max-width: 1550px) {
    height: 65px;
  }

  @media (max-width: 991.98px) {
    padding: 0 15px;
    width: 100%;
  }
`;

export const HeaderWrap = styled.div`
  @media (max-width: 1400px) {
    padding: 0 30px;
  }
  @media (max-width: 991px) {
    padding: 0 15px;
  }
`;

export const LaptopSvg = styled.div`
  width: 100%;
  position: relative;

  .laptop__svg svg {
    position: absolute;
    top: 0;
    right: 0;
    zoom: ${({ wSize }) => wSize || "100"}%;
  }

  @media (max-width: 991px) {
    .laptop__svg__mob {
      margin: 40px 0 0 0;
    }

    .laptop__svg svg,
    .laptop__svg {
      display: none;
    }
  }
`;
export const MainContainer = styled.div`
  padding-top: 1px;
  width: 100%;
  background-color: #ecf2ff;
  position: relative;
  height: ${({ containterHeight }) =>
    containterHeight || "calc(100vh - 110px)"};

  .circle__svg svg {
    position: absolute;
    top: 100px;
  }

  .square__svg svg {
    position: absolute;
    bottom: -110px;
    left: 80px;
  }

  .mob__image {
    display: flex;
    margin: auto;
    @media (max-width: 991px) {
      padding: 30px 0;
    }
  }

  @media (max-width: 1550px) {
    padding-bottom: ${({ paddingBottom }) => paddingBottom || 0};
    .circle__svg svg {
      display: none;
    }
    .square__svg svg {
      display: none;
    }
  }
  @media (max-width: 991px) {
    padding-bottom: 0;
  }
`;

const SearchWrap = styled.div`
  display: flex;
  width: 1400px;
  padding: 0 20px;
  background: #ffffff;
  padding: 15px;
  margin: 50px auto 80px;
  align-items: center;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);

  .right__header {
    align-items: center;
    justify-content: end;
    display: flex;
  }

  .menu__header {
    display: none;
  }

  @media (max-width: 1400px) {
    margin: 40px auto 50px;
    width: 100%;
  }
  @media (max-width: 991px) {
    margin: 20px auto 30px;
    padding: 5px 10px;

    .right__header {
      display: none;
    }
    .menu__header {
      display: flex;
      justify-content: end;
      align-items: center;
    }
  }
`;

export const H2Style = styled.h2`
  font-weight: 700;
  color: ${({ fColor }) => fColor || "#2b353f"};
  font-size: ${({ fontSize }) => fontSize || "50px"};
  line-height: ${({ lineHeight }) => lineHeight || "70px"};
  margin-top: ${({ marginTop }) => marginTop || 0};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  padding-top: ${({ paddingtop }) => paddingtop || 0};
  padding-bottom: ${({ paddingBottom }) => paddingBottom || 0};

  @media screen and (max-width: 1440px) {
    font-size: 30px;
    line-height: ${({ lineHeight }) => lineHeight || "40px"};
    margin-top: ${({ marginTopSm }) => marginTopSm || 0};
    margin-bottom: ${({ marginBottomSm }) => marginBottomSm || 0};
    padding-top: ${({ paddingtopSm }) => paddingtopSm || 0};
    padding-bottom: ${({ paddingBottomSm }) => paddingBottomSm || 0};
  }
`;

const DemoWrap = styled.div`
  width: 1400px;
  margin: 0 auto;
  padding: 0 60px;

  h3 {
    color: #848687;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    margin: 50px 0 0;
  }
  svg {
    margin-top: -25px;
  }

  @media screen and (max-width: 1400px) {
    width: auto;
    padding: 0 45px;

    svg {
      margin-left: -150px;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 0 15px;

    h3 {
      margin: 0;
      color: #848687;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 10px;
    }
    span {
      font-size: 14px !important;
    }
  }
`;

const LeftSidehWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  img {
    cursor: pointer;
  }

  .avenew_logo {
    width: 265px;
    height: 64px;
  }
  span {
    color: #2b353f;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }

  @media (max-width: 1550px) {
    .avenew_logo {
      width: 210px;
      height: 50px;
    }
  }
  @media (max-width: 991px) {
    .avenew_logo {
      width: 165px;
      height: 40px;
    }
  }
`;
const RightSidehWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  h1 {
    color: #2b353f;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    cursor: pointer;
  }

  .header__arrow {
    font-size: 18px;
    color: #000;
    margin-left: 15px;
    margin-right: 30px;
    cursor: pointer;
  }
  .user__icon {
    cursor: pointer;
    margin-left: 24px;
    display: inline-block;
  }
`;

export const StyledButton = styled(Button)`
  height: 50px;
  padding: 4px 30px;

  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.third};
  border-color: ${({ theme }) => theme.colors.third};
  text-transform: uppercase;

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.third}90;
    background: ${({ theme }) => theme.colors.third}90;
  }
  @media (max-width: 1550px) {
    height: 40px;
    span {
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.5px;
    }
  }
  @media screen and (max-width: 769px) {
    padding: ${({ paddingBtn }) => paddingBtn || "4px 20px"};
  }
`;
