import React from "react";

const camera = (props) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 3.0625C10 2.55469 9.57031 2.125 9.0625 2.125H7.34375L7.08984 1.5C6.95312 1.12891 6.60156 0.875 6.21094 0.875H3.76953C3.37891 0.875 3.02734 1.12891 2.89062 1.5L2.65625 2.125H0.9375C0.410156 2.125 0 2.55469 0 3.0625V8.6875C0 9.21484 0.410156 9.625 0.9375 9.625H9.0625C9.57031 9.625 10 9.21484 10 8.6875V3.0625ZM7.34375 5.875C7.34375 7.18359 6.28906 8.21875 5 8.21875C3.69141 8.21875 2.65625 7.18359 2.65625 5.875C2.65625 4.58594 3.69141 3.53125 5 3.53125C6.28906 3.53125 7.34375 4.58594 7.34375 5.875ZM6.71875 5.875C6.71875 4.9375 5.9375 4.15625 5 4.15625C4.04297 4.15625 3.28125 4.9375 3.28125 5.875C3.28125 6.83203 4.04297 7.59375 5 7.59375C5.9375 7.59375 6.71875 6.83203 6.71875 5.875Z"
        fill="white"
      />
    </svg>
  );
};

export default camera;
