import {
  BackTop,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Slider,
} from "antd";
import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  DashboardFilter,
  StyledButton,
  StyledFormItem,
  StyledFormItemd,
} from "../dashboard/Dashboard.styles";
import { TiFilter } from "react-icons/ti";
import { FaHeart } from "react-icons/fa";
import StarRatings from "react-star-ratings";
import { BathSvg, BedSvg, Sqft } from "../../assets/svgs";
import { MdKeyboardArrowUp } from "react-icons/md";
import Footer from "../../components/footer/Footer";
import { useLocation } from "react-router-dom";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { ApplicationsCardTop } from "../applications/Applications";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { applicationsData } from "../../components/featuredProperties/Cards/dummy.data";
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
//   InfoWindow,
// } from "react-google-maps";
import { MapData } from "./mapData";

const AnyReactComponent = ({ text }) => <div>{text}</div>;
function CategoryOfProperty() {
  const [form] = Form.useForm();
  const [minPrice, setMinPrice] = useState("0");

  const { Option } = Select;

  const onValueChange = (value) => {
    // console.log(value);
  };

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const style = {
    height: 40,
    width: 40,
    lineHeight: "40px",
    borderRadius: "50%",
    backgroundColor: "#4160AC90",
    color: "#fff",
    textAlign: "center",
    fontSize: 40,
  };

  const onFinish = (value) => {
    // console.log(value);
  };

  const onPriceChange = (value) => {
    setMinPrice(value);
  };

  const location = useLocation();
  const { width } = useWindowSize();

  const [showData, setGutterView] = useState(20);

  useEffect(() => {
    if (width < 770) {
      setGutterView(0);
    } else {
      setGutterView(20);
    }
  }, [width, location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [viewModal, setViewModal] = useState(false);
  const [inputValue, setInputValue] = useState([0, 1250]);
  const [noOfBeds, setNoOfBeds] = useState("01");
  const [noOfBaths, setNoOfBaths] = useState("01");

  const onChange = (newValue) => {
    setInputValue(newValue);
  };

  // const Map = () => {
  //   const [selectedUnit, setSelectedUnit] = useState(null);

  //   return (
  //     <GoogleMap
  //       defaultZoom={10}
  //       defaultCenter={{ lat: 33.750675, lng: -84.339466 }}
  //     >
  //       {MapData.map((unit) => (
  //         <Marker
  //           key={unit.id}
  //           position={{ lat: unit.position[0], lng: unit.position[1] }}
  //           onClick={() => {
  //             setSelectedUnit(unit);
  //           }}
  //           icon={{
  //             url: "./images/mapIcon.png",
  //           }}
  //           // label={unit.Price}
  //           label={{
  //             text: unit.Price,
  //             color: "white",
  //             fontWeight: "400",
  //             fontSize: "16px",
  //             lineHeight: "22px",
  //           }}
  //         />
  //       ))}

  //       {selectedUnit && (
  //         <InfoWindow
  //           position={{
  //             lat: selectedUnit.position[0],
  //             lng: selectedUnit.position[1],
  //           }}
  //           onCloseClick={() => {
  //             setSelectedUnit(null);
  //           }}
  //         >
  //           <ApplicationsCard>
  //             <ApplicationsCardTop>
  //               <img src={selectedUnit.img} />
  //               <MobCardDetails>
  //                 <Row>
  //                   <Col>
  //                     <StarRatings
  //                       rating={Number(selectedUnit.rating)}
  //                       starRatedColor="#F67C36"
  //                       starDimension="15px"
  //                       starSpacing="3px"
  //                       // changeRating={this.changeRating}
  //                       numberOfStars={5}
  //                       name="rating"
  //                     />
  //                   </Col>
  //                 </Row>
  //                 <Row>
  //                   <Col>
  //                     <h2>{selectedUnit.title}</h2>
  //                   </Col>
  //                 </Row>
  //                 <Row>
  //                   <Col>
  //                     <p>${Number(selectedUnit.Price).toFixed(2)}</p>
  //                   </Col>
  //                 </Row>
  //               </MobCardDetails>
  //             </ApplicationsCardTop>
  //             <BedBathBox>
  //               <Row justify="space-between">
  //                 <Col className="card__bottom">
  //                   <p>
  //                     <BedSvg className="icon" />
  //                   </p>
  //                   <span>Bed : {selectedUnit.bed}</span>
  //                 </Col>
  //                 <Col className="card__bottom">
  //                   <p>
  //                     <BathSvg />
  //                   </p>
  //                   <span>Bath : {selectedUnit.bath}</span>
  //                 </Col>
  //                 <Col className="card__bottom">
  //                   <p>
  //                     <Sqft />
  //                   </p>
  //                   <span>Sq ft : {selectedUnit.sqft}</span>
  //                 </Col>
  //               </Row>
  //             </BedBathBox>
  //           </ApplicationsCard>
  //         </InfoWindow>
  //       )}
  //     </GoogleMap>
  //   );
  // };

  // const WrappedMap = withScriptjs(withGoogleMap(Map));

  return (
    <>
      <CategoryOfPropertyTop>
        <CategoryOfPropertyWrapper>
          <Row>
            <Col
              className="sell__property"
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
            >
              <Form
                onFinish={onFinish}
                form={form}
                layout="vertical"
                autoComplete="off"
                validateMessages={validateMessages}
              >
                <StyledFormItem smwh="100">
                  <StyledFormItemd
                    wh="29"
                    bgColor="#fff"
                    ftColor="#2B353F"
                    name="location"
                    label="Where you want?"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder="Enter city, or zipcode" />
                  </StyledFormItemd>
                  <StyledFormItemd
                    wh="12"
                    ftColor="#2B353F"
                    bgColor="#fff"
                    name="minPrice"
                    label="Min Price"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Min Price"
                      onChange={onValueChange}
                      suffixIcon={<IoIosArrowDown />}
                    >
                      <Option value="15.00">$15.00</Option>
                      <Option value="20.00">$20.00</Option>
                      <Option value="25.00">$25.00</Option>
                    </Select>
                  </StyledFormItemd>
                  <StyledFormItemd
                    wh="12"
                    ftColor="#2B353F"
                    name="maxPrice"
                    label="Max Price"
                    bgColor="#fff"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Max Price"
                      onChange={onValueChange}
                      suffixIcon={<IoIosArrowDown />}
                    >
                      <Option value="150.00">$150.00</Option>
                      <Option value="200.00">$200.00</Option>
                      <Option value="250.00">$250.00</Option>
                    </Select>
                  </StyledFormItemd>
                  <StyledFormItemd
                    wh="12"
                    ftColor="#2B353F"
                    name="beds"
                    label="Beds"
                    bgColor="#fff"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Beds"
                      onChange={onValueChange}
                      suffixIcon={<IoIosArrowDown />}
                    >
                      <Option value="1 Bed">1 Bed</Option>
                      <Option value="2 Bed">2 Bed</Option>
                      <Option value="3 Bed">3 Bed</Option>
                    </Select>
                  </StyledFormItemd>
                  <StyledFormItemd
                    wh="12"
                    ftColor="#2B353F"
                    name="baths"
                    label="Baths"
                    bgColor="#fff"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Baths"
                      onChange={onValueChange}
                      suffixIcon={<IoIosArrowDown />}
                    >
                      <Option value="1 Bath">1 Bath</Option>
                      <Option value="2 Bath">2 Bath</Option>
                      <Option value="3 Bath">3 Bath</Option>
                    </Select>
                  </StyledFormItemd>

                  <StyledFormItemd wh="13" sWh="17%">
                    <StyledButton htmlType="submit">Find Property</StyledButton>
                  </StyledFormItemd>
                </StyledFormItem>
              </Form>
            </Col>
          </Row>
        </CategoryOfPropertyWrapper>
      </CategoryOfPropertyTop>
      <MapWrapMani>
        <MapContentWrap>
          <Row gutter={[showData, showData]} justify="space-between">
            <Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12}>
              <LeftPenalWrap>
                <Row justify="space-between">
                  <Col>
                    <span>Show 75 Property</span>
                  </Col>
                  <Col>
                    <FilterButton icon={<TiFilter />}>Filter</FilterButton>
                  </Col>
                </Row>
              </LeftPenalWrap>

              <Row gutter={[20, 20]}>
                {[1, 2, 3].map((id) => (
                  <Col>
                    <CardWrap>
                      <UnitCard key={id}>
                        <img src="/images/property1.png" />
                        <div className="top-left">NEW</div>
                        <div className="top-right">
                          <FaHeart />
                        </div>
                        <PriceWrap>
                          <Row>
                            <Col>
                              <StarRatings
                                rating={4.3}
                                starRatedColor="#F67C36"
                                starDimension="20px"
                                starSpacing="5px"
                                // changeRating={this.changeRating}
                                numberOfStars={5}
                                name="rating"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h2>Merrick in Spring Way</h2>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p>$569.00</p>
                            </Col>
                          </Row>
                          <Row gutter={[15, 15]}>
                            <Col className="card__bottom">
                              <p>
                                <BedSvg className="icon" />
                              </p>
                              <span>Bed : 2</span>
                            </Col>
                            <Col className="card__bottom">
                              <p>
                                <BathSvg />
                              </p>
                              <span>Bath : 2</span>
                            </Col>
                            <Col span={24} className="card__bottom">
                              <p>
                                <Sqft />
                              </p>
                              <span>Sq ft : 1500</span>
                            </Col>
                          </Row>
                        </PriceWrap>
                      </UnitCard>
                    </CardWrap>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12}>
              <MapWrap>
                {/* <WrappedMap
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places`}
                  // &key=${process.env.local}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `100%` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                /> */}
                {/* <GoogleMapReact
                  bootstrapURLKeys={{ key: "xxxxxxxx" }}
                  defaultCenter={Markers.position}
                  defaultZoom={Markers.zoom}
                  yesIWantToUseGoogleMapApiInternals={true}
                  onGoogleApiLoaded={({ map, maps }) =>
                    renderMarkers(map, maps)
                  }
                  // onChildMouseUp={onCircleInteraction3}
                  // onDragEnd={(e) => onMarkerDragEnd(e.nativeEvent)}
                  // onClick = {changeMarkerPosition}
                >
                  <AnyReactComponent
                    lat={37.152686}
                    lng={-83.760888}
                    name="My Marker"
                    color="blue"
                  />
                </GoogleMapReact> */}
              </MapWrap>
            </Col>
          </Row>
        </MapContentWrap>
      </MapWrapMani>
      <MobileViewMain>
        <Row>
          <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
            <MobileViewMap>
              {/* <WrappedMap
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              /> */}

              <MobileSearch>
                <Form
                  onFinish={onFinish}
                  form={form}
                  layout="vertical"
                  autoComplete="off"
                  validateMessages={validateMessages}
                  style={{ maxWidth: "355px", margin: "auto" }}
                  onMouseOver={() => setViewModal(true)}
                  onMouseLeave={() => setViewModal(false)}
                  className="hover-to-show-link"
                >
                  <StyledFormItem smwh="100">
                    <StyledFormItemd
                      wh="100"
                      bgColor="#fff"
                      ftColor="#2B353F"
                      name="location"
                      // label="Where you want?"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Enter city, or zipcode" />
                    </StyledFormItemd>
                  </StyledFormItem>
                </Form>
                {viewModal ? (
                  <WrapOpen
                    onMouseOver={() => setViewModal(true)}
                    onMouseLeave={() => setViewModal(false)}
                  >
                    <h2>
                      ${inputValue[0]} -${inputValue[1]}
                    </h2>
                    <Slider
                      range={{ draggableTrack: true }}
                      defaultValue={[0, 1250]}
                      onChange={onChange}
                      max={9999.0}
                    />
                    <MinMaxPrice>
                      <span>Min Price</span>
                      <span>Max Price</span>
                    </MinMaxPrice>
                    <Divider />
                    <BedFilter>
                      <p>Beds</p>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Button
                          onClick={() => setNoOfBeds(Number(noOfBeds) - 1)}
                          disabled={noOfBeds <= 1}
                        >
                          -
                        </Button>
                        <h3>{("0" + noOfBeds).slice(-2)}</h3>
                        <Button
                          onClick={() => setNoOfBeds(Number(noOfBeds) + 1)}
                        >
                          +
                        </Button>
                      </div>
                    </BedFilter>
                    <Divider />
                    <BedFilter style={{ marginBottom: "20px" }}>
                      <p>Baths</p>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Button
                          onClick={() => setNoOfBaths(Number(noOfBaths) - 1)}
                          disabled={noOfBaths <= 1}
                        >
                          -
                        </Button>
                        <h3>{("0" + noOfBaths).slice(-2)}</h3>
                        <Button
                          onClick={() => setNoOfBaths(Number(noOfBaths) + 1)}
                        >
                          +
                        </Button>
                      </div>
                    </BedFilter>
                    <StyledButton
                      htmlType="submit"
                      onClick={() => setViewModal(false)}
                    >
                      Find Property
                    </StyledButton>
                  </WrapOpen>
                ) : (
                  ""
                )}
              </MobileSearch>
              <MobileApartCardMain>
                <Swiper
                  // install Swiper modules
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={50}
                  slidesPerView={1}
                  navigation
                  // pagination={{ clickable: true }}
                  // scrollbar={{ draggable: true }}
                  onSwiper={(swiper) => console.log(swiper)}
                  onSlideChange={() => console.log("slide change")}
                >
                  {applicationsData.map(
                    ({ _id, rating, title, Price, bed, bath, sqft, img }) => (
                      <SwiperSlide key={_id}>
                        <ApplicationsCard>
                          <ApplicationsCardTop>
                            <img src={img} />
                            <MobCardDetails>
                              <Row>
                                <Col>
                                  <StarRatings
                                    rating={rating}
                                    starRatedColor="#F67C36"
                                    starDimension="15px"
                                    starSpacing="3px"
                                    // changeRating={this.changeRating}
                                    numberOfStars={5}
                                    name="rating"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <h2>{title}</h2>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <p>${Price}</p>
                                </Col>
                              </Row>
                            </MobCardDetails>
                          </ApplicationsCardTop>
                          <BedBathBox>
                            <Row justify="space-between">
                              <Col className="card__bottom">
                                <p>
                                  <BedSvg className="icon" />
                                </p>
                                <span>Bed : {bed}</span>
                              </Col>
                              <Col className="card__bottom">
                                <p>
                                  <BathSvg />
                                </p>
                                <span>Bath : {bath}</span>
                              </Col>
                              <Col className="card__bottom">
                                <p>
                                  <Sqft />
                                </p>
                                <span>Sq ft : {sqft}</span>
                              </Col>
                            </Row>
                          </BedBathBox>
                        </ApplicationsCard>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </MobileApartCardMain>
            </MobileViewMap>
          </Col>
        </Row>
      </MobileViewMain>

      <BackTop>
        <div style={style}>
          <MdKeyboardArrowUp />
        </div>
      </BackTop>
    </>
  );
}

export default CategoryOfProperty;

export const BedFilter = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    font-weight: 400;
    font-size: 33px;
    line-height: 24px;
    color: #aaaaaa;
  }
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 0;
  }
  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #aaaaaa;
    margin-bottom: 0;
    width: 50px;
    text-align: center;
  }
  .ant-btn {
    display: inline-block !important;
    background: #eeeeee;
    border-radius: 100px;
    width: 30px;
    padding: 0;
    height: 30px;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    color: #40a9ff;
    border-color: #eee;
    background: #eee;
  }
`;

export const MinMaxPrice = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #aaaaaa;
  }
`;

export const WrapOpen = styled.div`
  background: #ffffff;
  border-radius: 5px;
  max-width: 355px;
  margin: auto;
  margin-top: 5px;
  padding: 15px;

  .ant-divider-horizontal {
    margin: 20px 0 30px 0;
    background: #dddddd;
  }
  .ant-btn {
    display: inline-block !important;
  }
`;

export const ApplicationsCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.4s;
  max-width: 355px;
  margin: auto;
  border-radius: 10px;

  :hover {
    -ms-transform: scale(0.98); /* IE 9 */
    -webkit-transform: scale(0.98); /* Safari 3-8 */
    transform: scale(0.98);
  }
`;

export const BedBathBox = styled.div`
  background: #f9f9f9;
  padding: 0 12px;
  border-radius: 10px;

  .ant-row {
    align-items: center;
  }

  .card__bottom p,
  span {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 0.05em;
    color: #848687;
  }

  .card__bottom {
    display: flex;
    align-items: center;
    svg {
      margin-top: 10px;
      margin-right: 10px;
    }
  }
`;

export const MobCardDetails = styled.div`
  padding: ${({ padding }) => padding || "10"}px;
  h2 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottm: 5px;
  }
  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #4160ac;
    margin-bottom: 0px;
  }
`;

export const MobileApartCard = styled.div`
  width: 100%;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  transition: transform 0.4s;
  max-width: 355px;
  margin: auto;
  img {
    width: 100px;
    height: 100px;
  }
`;

export const MobileApartCardMain = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 30px;

  .swiper {
    text-align: center;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: inline-block;
    margin-top: 15px;
    position: static;
    color: #000000;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
    border-radius: 10px;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    display: inline-grid;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 20px;
  }
  .swiper-button-next {
    margin-left: 10px;
  }
`;

export const MobileSearch = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  padding: 30px;
`;
export const MobileViewMap = styled.div`
  height: calc(100vh - 65px);
  position: relative;

  .gmnoprint {
    display: none;
  }
  .ant-slider-handle {
    width: 20px;
    height: 20px;
    margin-top: -9px;
    background-color: #4160ac;
    border: solid 2px #4160ac;
  }
  .ant-slider-track {
    background-color: #4160ac;
  }
  .ant-slider-rail {
    background: #ecf2ff;
  }
  h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
`;

export const MobileViewMain = styled.div``;

export const PriceWrap = styled.div`
  padding: 28px 0 0 35px;

  h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: ${({ theme }) => theme.colors.forth};
    margin-bottom: 10px;
  }
  p {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: ${({ theme }) => theme.colors.secondary};
  }
  .card__bottom p,
  span {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 0;
    letter-spacing: 0.05em;
    color: #848687;
  }

  .card__bottom {
    display: flex;
    align-items: center;
    margin-right: 30px;
    svg {
      margin-right: 10px;
    }
  }

  @media (max-width: 1550px) {
    padding: 20px 0 0 25px;

    h2 {
      font-size: 22px;
      line-height: 26px;
    }
    p {
      font-size: 22px;
      line-height: 26px;
    }
  }
`;

export const UnitCard = styled.div`
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;
  position: relative;
  transition: transform 0.4s;

  :hover {
    -ms-transform: scale(0.98); /* IE 9 */
    -webkit-transform: scale(0.98); /* Safari 3-8 */
    transform: scale(0.98);
  }

  img {
    width: 250px;
  }

  .top-left {
    position: absolute;
    top: 22px;
    left: 22px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    background: #f67c36;
    border-radius: 5px;
    text-align: center;
    padding: 4px 10px;
  }
  .top-right {
    position: absolute;
    top: 10px;
    right: 16px;
    svg {
      color: rgba(0, 0, 0, 0.5);
      font-size: 28px;
    }
  }

  @media (max-width: 1550px) {
    img {
      width: 200px;
    }
  }
`;

export const CardWrap = styled.div`
  ${"" /* padding: 0 0 30px 0; */}
`;

export const LeftPenalWrap = styled.div`
  padding: 30px 0 15px 0;
  .ant-row {
    align-items: center;
  }
  span {
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #848687;
  }
`;

export const MapWrap = styled.div`
  height: 80vh;
  @media (max-width: 769px) {
    height: 50vh;
  }
`;

export const MapWrapMani = styled.div`
  padding: 0 0 30px 0;

  @media (max-width: 1550px) {
    padding: 0 30px 30px;
  }
  @media (max-width: 769px) {
    padding: 0;
  }
`;
export const MapContentWrap = styled.div`
  max-width: 1400px;
  margin: auto;
`;

export const CategoryOfPropertyTop = styled.div`
  background-color: #ecf2ff;
  width: 100%;
  @media (max-width: 1550px) {
    padding: 0 30px;
  }
`;

export const CategoryOfPropertyWrapper = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 17px 0;
  @media (max-width: 769px) {
    padding: 0;
    .sell__property {
      display: none;
    }
  }
`;

const FilterButton = styled(Button)`
  margin-left: 15px;
  height: 40px !important;
  padding: 0 15px;
  border: 1px solid ${({ theme }) => theme.colors.secondary} !important;
  color: #777777;
  font-size: 18px;
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 5px;
  .anticon {
    transform: rotate(180deg) !important;
  }
  :hover,
  :active,
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.secondary} !important;
    background: ${({ theme }) => theme.colors.secondary} !important;
    color: #ffffff;
  }
  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    color: #ffffff;
  }
  svg {
    margin-bottom: -6px;
    font-size: 26px;
    color: #ffffff;
  }

  @media (max-width: 1550px) {
    height: 32px !important;
    font-size: 14px;
    span {
      font-size: 14px;
      line-height: 16px;
    }
    svg {
      margin-bottom: -4px;
      font-size: 20px;
    }
  }
`;
