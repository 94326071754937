import styled from "styled-components";
import { DatePicker, Button, Form } from "antd";

const { RangePicker } = DatePicker;

export const DashboardWrap = styled.div`
  .ant-divider-horizontal {
    margin: 0;
  }
`;
export const DashboardFilterWrap = styled.div`
  position: relative;
  box-shadow: inset 0 0 0 1000px rgba(43, 53, 63, 0.7);
  background-image: url("/images/backgroungimg.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @media (max-width: 1550px) {
    padding: 0 30px;
  }

  @media (max-width: 769px) {
    .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-top > .ant-tabs-nav::before {
      display: none;
    }
    .ant-tabs-top > .ant-tabs-nav {
      margin: 0;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${({ theme }) => theme.colors.third};
    }
    .ant-tabs-tab-btn {
      color: #fff;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
    .ant-form-item-label {
      display: none;
    }
  }

  @media (max-width: 425px) {
    padding: 0 15px;
  }
`;

export const MobileTabs = styled.div`
  display: none;
  @media (max-width: 769px) {
    display: block;
  }
`;
export const DashboardFilter = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 60px 0 30px 0;

  h2 {
    font-weight: 700;
    font-size: 60px;
    line-height: 73px;
    color: ${({ theme }) => theme.colors.primary};
  }
  p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }
  .find__property {
    padding-top: 60px;
  }

  @media (max-width: 1550px) {
    padding: 45px 0px;
    width: 100%;
    h2 {
      font-size: 44px;
      line-height: 58px;
    }
    .find__property {
      padding-top: 40px;
    }
  }

  @media (max-width: 769px) {
    h2 {
      font-size: 30px;
      line-height: 36px;
    }
    .find__property {
      display: none;
    }
  }

  @media (max-width: 425px) {
    padding: 35px 0px;
  }
`;

export const StyledFormItemd = styled(Form.Item)`
  width: ${({ wh }) => wh || "8"}%;
  margin-bottom: ${({ mb }) => mb || "0"};

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-select-arrow {
    font-size: 18px;
    color: #777;
  }
  .ant-form-item-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #232333;
  }
  input,
  .ant-select-selector {
    width: 100%;
    height: 50px !important;
    background: ${({ bgColor }) => bgColor || "#eee"} !important ;
    font-size: 18px !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    align-items: center !important;
    border: 0 !important;
    @media screen and (max-width: 595px) {
      width: 100%;
    }
  }
  .ant-form-item-label > label {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${({ ftColor }) => ftColor || "#fff"};
  }
  .ant-input:placeholder-shown {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #aaaaaa;
    border: 0;
  }
  .ant-picker-input > input:placeholder-shown {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #aaaaaa;
  }
  @media (max-width: 1550px) {
    input,
    .ant-select-selector {
      height: 40px !important;
      font-size: 16px !important;
    }
  }

  @media (max-width: 1024px) {
    width: ${({ sWh }) => sWh};
  }

  @media (max-width: 769px) {
    width: ${({ xsWh }) => xsWh};
  }
`;
export const StyledFormItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 769px) {
    flex-direction: ${({ fd }) => fd || "column"};
    .ant-form-item {
      width: ${({ smwh }) => smwh || "48%"};
    }
  }
`;

export const RangePickerWrap = styled(RangePicker)`
  font-size: 18px;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  height: 50px !important;
  border-radius: 5px !important;
  border: none !important;
  background: #eeeeee;

  .ant-picker-range,
  .ant-picker-active-bar {
    bottom: -1px;
    height: 2px;
    margin-left: 11px;
    background: #aaaaaa !important;
    opacity: 0;
    transition: all 0.3s ease-out;
    pointer-events: none;
  }
  .ant-picker-panel-container {
    background: #aaaaaa !important;
  }
`;
export const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.third};
  border-color: ${({ theme }) => theme.colors.third};
  text-transform: uppercase;
  margin-top: 30px;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
  }

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.third}90;
    background: ${({ theme }) => theme.colors.third}90;
  }
  @media (max-width: 1550px) {
    height: 40px;
    span {
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.5px;
    }
  }
  @media screen and (max-width: 769px) {
    padding: 4px 0;
    margin-top: 0;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
`;
