import React from "react";

const washingMachineSvg = ({
  wSize = "18",
  hSize = "24",
  bgColor = "#ffffff",
  color = "none",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox={`0 0 18 24`}
      {...props}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M0 0V23.4H18V0H0ZM17.1 0.9V4.5H0.9V0.9H17.1ZM0.9 22.5V5.4H17.1V22.5H0.9Z"
          fill="black"
        />
        <path
          d="M3.6002 3.5998C4.09725 3.5998 4.5002 3.19686 4.5002 2.6998C4.5002 2.20275 4.09725 1.7998 3.6002 1.7998C3.10314 1.7998 2.7002 2.20275 2.7002 2.6998C2.7002 3.19686 3.10314 3.5998 3.6002 3.5998Z"
          fill="black"
        />
        <path
          d="M7.1998 3.5998C7.69686 3.5998 8.0998 3.19686 8.0998 2.6998C8.0998 2.20275 7.69686 1.7998 7.1998 1.7998C6.70275 1.7998 6.2998 2.20275 6.2998 2.6998C6.2998 3.19686 6.70275 3.5998 7.1998 3.5998Z"
          fill="black"
        />
        <path
          d="M10.8004 3.5998C11.2974 3.5998 11.7004 3.19686 11.7004 2.6998C11.7004 2.20275 11.2974 1.7998 10.8004 1.7998C10.3033 1.7998 9.90039 2.20275 9.90039 2.6998C9.90039 3.19686 10.3033 3.5998 10.8004 3.5998Z"
          fill="black"
        />
        <path
          d="M14.4 3.5998C14.8971 3.5998 15.3 3.19686 15.3 2.6998C15.3 2.20275 14.8971 1.7998 14.4 1.7998C13.9029 1.7998 13.5 2.20275 13.5 2.6998C13.5 3.19686 13.9029 3.5998 14.4 3.5998Z"
          fill="black"
        />
        <path
          d="M9.0002 7.2002C7.75418 7.2002 6.53614 7.56968 5.50011 8.26194C4.46408 8.95419 3.65659 9.93811 3.17976 11.0893C2.70292 12.2405 2.57816 13.5072 2.82125 14.7293C3.06434 15.9513 3.66435 17.0739 4.54543 17.955C5.4265 18.836 6.54905 19.4361 7.77113 19.6791C8.99321 19.9222 10.2599 19.7975 11.4111 19.3206C12.5623 18.8438 13.5462 18.0363 14.2385 17.0003C14.9307 15.9643 15.3002 14.7462 15.3002 13.5002C15.3002 11.8293 14.6364 10.2269 13.455 9.04542C12.2735 7.86394 10.6711 7.2002 9.0002 7.2002ZM9.0002 18.9002C7.93218 18.9002 6.88814 18.5835 6.00012 17.9901C5.11209 17.3968 4.41996 16.5534 4.01125 15.5667C3.60254 14.58 3.4956 13.4942 3.70396 12.4467C3.91232 11.3992 4.42662 10.437 5.18182 9.68182C5.93702 8.92662 6.89921 8.41232 7.94671 8.20396C8.99421 7.99559 10.08 8.10253 11.0667 8.51125C12.0534 8.91996 12.8968 9.61209 13.4901 10.5001C14.0835 11.3881 14.4002 12.4322 14.4002 13.5002C14.4002 14.9324 13.8313 16.3059 12.8186 17.3186C11.8059 18.3313 10.4324 18.9002 9.0002 18.9002Z"
          fill="black"
        />
        <path
          d="M9.00039 9.90039C8.28838 9.90039 7.59236 10.1115 7.00034 10.5071C6.40832 10.9027 5.9469 11.4649 5.67443 12.1227C5.40195 12.7805 5.33066 13.5044 5.46957 14.2027C5.60847 14.901 5.95134 15.5425 6.45481 16.046C6.95828 16.5494 7.59974 16.8923 8.29807 17.0312C8.9964 17.1701 9.72024 17.0988 10.3781 16.8264C11.0359 16.5539 11.5981 16.0925 11.9937 15.5004C12.3893 14.9084 12.6004 14.2124 12.6004 13.5004C12.6004 12.5456 12.2211 11.6299 11.546 10.9548C10.8708 10.2797 9.95517 9.90039 9.00039 9.90039ZM9.00039 16.2004C8.46638 16.2004 7.94437 16.042 7.50035 15.7454C7.05634 15.4487 6.71027 15.027 6.50592 14.5336C6.30156 14.0403 6.24809 13.4974 6.35227 12.9736C6.45645 12.4499 6.7136 11.9688 7.0912 11.5912C7.46881 11.2136 7.9499 10.9565 8.47365 10.8523C8.9974 10.7481 9.54028 10.8016 10.0336 11.0059C10.527 11.2103 10.9487 11.5563 11.2454 12.0004C11.542 12.4444 11.7004 12.9664 11.7004 13.5004C11.7004 14.2165 11.4159 14.9032 10.9096 15.4096C10.4032 15.9159 9.71648 16.2004 9.00039 16.2004Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={wSize} height={hSize} fill={bgColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default washingMachineSvg;
