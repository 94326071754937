import React from "react";

const bathSvg = ({
  wSize = "20",
  hSize = "19",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 6.99954H5V4.11254C5 3.11554 5.678 2.18954 6.661 2.02754C6.94813 1.97868 7.24245 1.99303 7.52347 2.06958C7.80448 2.14613 8.06542 2.28305 8.28809 2.47078C8.51077 2.65852 8.68982 2.89256 8.81277 3.15659C8.93573 3.42062 8.99962 3.70828 9 3.99954H11C10.9995 3.44084 10.8821 2.88842 10.6553 2.37784C10.4284 1.86725 10.0972 1.4098 9.68299 1.03492C9.26873 0.660036 8.78059 0.37602 8.24996 0.201145C7.71933 0.0262688 7.15797 -0.0355939 6.602 0.0195367C4.523 0.221537 3 2.08854 3 4.17754V6.99954H1C0.734784 6.99954 0.48043 7.10489 0.292893 7.29243C0.105357 7.47997 0 7.73432 0 7.99954V9.99954C0 12.6055 1.674 14.8225 4 15.6495V18.9995H6V15.9995H14V18.9995H16V15.6495C18.326 14.8225 20 12.6055 20 9.99954V7.99954C20 7.73432 19.8946 7.47997 19.7071 7.29243C19.5196 7.10489 19.2652 6.99954 19 6.99954Z"
        fill="#848687"
      />
    </svg>
  );
};

export default bathSvg;
