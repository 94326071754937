import React from "react";
import styled from "styled-components";
import { Container } from "../container/Container";
import { Col, Row, Divider } from "antd";
import Marquee from "react-fast-marquee";

const logo = [
  "/images/logoone.png",
  "/images/logoone.png",
  "/images/logoone.png",
  "/images/logoone.png",
];

function LogoSection() {
  return (
    <LogoWrap>
      <Container paddings="30px 0">
        <Row className="logos">
          <Marquee speed={40} height="100px">
            {logo.map((image) => (
              <Col xs={5} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <img src={image} alt="logo" />
              </Col>
            ))}
          </Marquee>
        </Row>
      </Container>
      <Container paddings="0">
        <Divider />
      </Container>
    </LogoWrap>
  );
}

export default LogoSection;

export const LogoWrap = styled.div`
  background: #ffffff;

  .profile__pic img {
    margin-top: -210px;
  }

  .logos {
    justify-content: space-between;
    padding-top: 150px;
    text-align: center;
  }

  @media (max-width: 991.98px) {
    .logos {
      padding-top: 50px;
    }
  }
`;
