import React from "react";

const smileSvg = ({
  wSize = "57",
  hSize = "57",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.4999 0C44.24 0 57 12.7599 57 28.5001C57 44.2402 44.24 57 28.4999 57C12.7599 57 0 44.2402 0 28.5001C0 12.7599 12.7599 0 28.4999 0ZM28.4999 4.27434C15.1205 4.27434 4.27433 15.1206 4.27433 28.5001C4.27433 41.8797 15.1205 52.7257 28.4999 52.7257C41.8794 52.7257 52.7257 41.8797 52.7257 28.5001C52.7257 15.1206 41.8794 4.27434 28.4999 4.27434ZM18.4175 36.4313C20.8372 39.5023 24.5159 41.3274 28.4999 41.3274C32.4784 41.3274 36.1529 39.5071 38.5731 36.4433C39.3046 35.5169 40.6484 35.359 41.5748 36.0908C42.5009 36.8223 42.6588 38.1661 41.9273 39.0925C38.705 43.1722 33.8006 45.6018 28.4999 45.6018C23.1923 45.6018 18.2818 43.1657 15.0601 39.0765C14.3296 38.1496 14.489 36.8057 15.4161 36.0751C16.3433 35.3448 17.687 35.5041 18.4175 36.4313ZM19.9526 19.2408C21.9187 19.2408 23.5126 20.8346 23.5126 22.8007C23.5126 24.7669 21.9187 26.3607 19.9526 26.3607C17.9865 26.3607 16.3927 24.7669 16.3927 22.8007C16.3927 20.8346 17.9865 19.2408 19.9526 19.2408ZM37.05 19.2408C39.0159 19.2408 40.6099 20.8346 40.6099 22.8007C40.6099 24.7669 39.0159 26.3607 37.05 26.3607C35.0838 26.3607 33.49 24.7669 33.49 22.8007C33.49 20.8346 35.0838 19.2408 37.05 19.2408Z"
        fill="#F67C36"
      />
    </svg>
  );
};

export default smileSvg;
