import { Button, Col, Form, Input, message, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../components/layout/header/Header";
import OtpModal, { OtpModalWrap } from "../../components/otpModal/OtpModal";
import { BsFacebook } from "react-icons/bs";
import {
  AccountDone,
  FacebookSvg,
  GoogleSvg,
  TwitterSvg,
} from "../../assets/svgs";
// import FadeIn from "react-fade-in";
import {
  createSearchParams,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  createUser,
  login,
  register,
  verifySignUp,
} from "../../store/reducers/user.reducer";
import { useDispatch } from "react-redux";
import { SelectArrow } from "../signup/Signup";
import { StyledForm, StyledForms } from "../my-account/ChangePassword";
import OtpComponent from "./OtpComponent";

function LoginNew() {
  const { Option } = Select;

  var formRef = React.createRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [invalidUserOrPass, setInvalidUserOrPass] = useState(false);
  const [loadings, setloadings] = useState(false);
  const [isOptSend, setIsOptSend] = useState(false);
  const [loadingsOtp, setloadingsOtp] = useState(false);
  const [otpVerify, setOtpVerify] = useState(false);
  const [isFormField, setIsFormField] = useState(false);

  const [emailValidation, setEmailValidation] = useState("");
  const [writenNumber, setWritenNumber] = useState("");
  const [phoneSendOtp, setPhoneSendOtp] = useState("");
  const [verifyOtp, setVerifyOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const onFinishResendOtp = () => {
    let value = {};
    value.phoneNumber = writenNumber;

    dispatch(register(value)).then(({ payload }) => {
      setPhoneSendOtp(payload);
      setloadings(false);
      setIsOptSend(true);
      message.success("OTP has been sent to your mobile");
    });
  };

  const onFinish = async (value) => {
    // console.log(value, "value");
    setloadings(true);
    if (value.phoneNumber) {
      value.phoneNumber = `+1${value.phoneNumber}`;
      setWritenNumber(value.phoneNumber);
      dispatch(register(value)).then(({ payload }) => {
        setPhoneSendOtp(payload);
        setloadings(false);
        setIsOptSend(true);
      });
      // setTimeout(() => {
      //   setloadings(false);
      //   setIsOptSend(true);
      // }, 2000);
    } else {
      // console.log(value, "value");
      value.phoneNumber = writenNumber;
      dispatch(createUser(value)).then((res) => {
        // console.log(res, "res");
        setloadings(false);
        setIsFormField(true);
        navigate("/my-rentals");
      });
    }
  };
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      emailValidation?.length &&
      emailValidation?.includes("User with email")
    ) {
      formRef.current.setFields([
        {
          name: "email",
          errors: ["Email is not exist"],
        },
      ]);
    }
  }, [emailValidation, loadings]);

  const reDirectFun = () => {
    if (params.id) {
      navigate(`/signup/${params.id}`);
    } else {
      navigate(`/signup`);
    }
  };
  const prefixSelector = (
    <SelectArrow>
      <Form.Item noStyle>
        {/* <span>+1</span> */}
        <Select
          style={{
            width: 70,
          }}
          defaultValue="+1"
        >
          <Option value="+1">+1</Option>
        </Select>
      </Form.Item>
    </SelectArrow>
  );

  const otpValue = (value) => {
    setVerifyOtp(value);
    setloadingsOtp(true);
    if (value.length < 6) {
      setErrorMessage("Invalid code");
      setloadingsOtp(false);
    } else {
      if (phoneSendOtp?.request === "signIn") {
        dispatch(
          verifySignUp({
            request: "signIn",
            phoneNumber: writenNumber,
            otp: value,
          })
        ).then(({ payload }) => {
          if (payload?.Error || payload?.error) {
            setErrorMessage("Invalid code");
            setloadingsOtp(false);
          } else {
            // if (res.payload?.error || res.payload.Error) {
            // } else {
            navigate("/my-rentals");
            // }
            setErrorMessage("");
            setloadingsOtp(false);
            setOtpVerify(true);
          }
        });
      } else {
        dispatch(
          verifySignUp({
            request: "signUp",
            phoneNumber: writenNumber,
            otp: value,
          })
        ).then(({ payload }) => {
          if (payload?.Error || payload?.error) {
            setErrorMessage("Invalid code");
            setloadingsOtp(false);
          } else {
            setErrorMessage("");
            // console.log(payload, "payload otp");
            setloadingsOtp(false);
            setOtpVerify(true);
          }
        });
      }
    }
  };

  return (
    <div>
      <Header />
      <LoginMain>
        <LoginPageMain minH="calc(100vh - 65px)">
          <LoginWrap>
            <LoginLeft mb="30px">
              {isFormField ? (
                <>
                  <h2>Welcome Back</h2>
                  <AccountDone />
                  <h4>
                    We've sent an email to verify your account. Verify email
                    address to activate your account.
                  </h4>
                  <StyledButton
                    onClick={() => {
                      navigate("/my-rentals");
                    }}
                    htmlType="submit"
                    loading={loadings}
                  >
                    Login
                  </StyledButton>
                </>
              ) : otpVerify ? (
                <>
                  <h2>Welcome Back</h2>
                  <Form
                    layout="vertical"
                    onFinish={onFinish}
                    requiredMark={"optional"}
                    validateMessages={validateMessages}
                    ref={formRef}
                  >
                    <StyledFormItem smwh="100">
                      <Form.Item
                        name="firstName"
                        label="First Name"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="lastName"
                        label="Last Name"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </StyledFormItem>
                    <StyledFormItem wh="100" smwh="100">
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                          {
                            required: true,
                            type: "email",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </StyledFormItem>
                    <Form.Item>
                      <StyledButton htmlType="submit" loading={loadings}>
                        Continue
                      </StyledButton>
                    </Form.Item>
                  </Form>
                  {/* <LoginBottomWrap>
                    <p>
                      Are you a avenew? &nbsp;
                      <h2 onClick={reDirectFun}> Sign In</h2>
                    </p>
                  </LoginBottomWrap> */}
                </>
              ) : isOptSend ? (
                <>
                  <h2>Welcome Back</h2>
                  <h3>
                    Enter the code you have received on contact you've provided
                  </h3>
                  <OtpComponent
                    errorMessage={errorMessage}
                    otpValue={otpValue}
                    loadingsOtp={loadingsOtp}
                    onFinishResendOtp={onFinishResendOtp}
                  />
                  {/* <LoginBottomWrap>
                    <p>
                      Are you a avenew? &nbsp;
                      <h2 onClick={reDirectFun}> Sign In</h2>
                    </p>
                  </LoginBottomWrap> */}
                </>
              ) : (
                <>
                  <h2>Welcome Back</h2>
                  <Form
                    layout="vertical"
                    onFinish={onFinish}
                    requiredMark={"optional"}
                    validateMessages={validateMessages}
                    ref={formRef}
                  >
                    <StyledFormItem wh="100" smwh="100">
                      <Form.Item
                        name="phoneNumber"
                        label="Phone Number"
                        rules={[
                          {
                            required: true,
                          },
                          { max: 10, message: "Invalid Phone Number!" },
                        ]}
                      >
                        <Input
                          addonBefore={prefixSelector}
                          type="number"
                          pattern="[0-9]*"
                        />
                      </Form.Item>
                    </StyledFormItem>
                    <Form.Item>
                      <StyledButton htmlType="submit" loading={loadings}>
                        COntinue
                      </StyledButton>
                    </Form.Item>
                  </Form>
                  {/* <LoginBottomWrap>
                    <p>
                      Are you a avenew? &nbsp;
                      <h2 onClick={reDirectFun}> Sign In</h2>
                    </p>
                  </LoginBottomWrap> */}
                </>
              )}
            </LoginLeft>
          </LoginWrap>
        </LoginPageMain>
      </LoginMain>
    </div>
  );
}

export default LoginNew;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
  border-radius: 5px;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.secondary};
  border-color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  margin-top: 20px;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 0.5px;
  }

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.secondary}90;
    background: ${({ theme }) => theme.colors.secondary}90;
  }

  @media (max-width: 1550px) {
    height: 40px;
    margin-top: 15px;
    span {
      font-size: 16px;
      line-height: 20px;
    }
  }
  @media (max-width: 576px) {
    margin-top: 10px;
  }
`;

export const LoginPageMain = styled.div`
  margin-top: 80px;
  width: 100%;
  min-height: ${({ minH }) => minH || "calc(100vh - 80px)"};

  @media (max-width: 1550px) {
    margin-top: 65px;
    min-height: ${({ minH }) => minH || "calc(100vh - 80px)"};
  }
`;

export const StyledFormItem = styled.div`
  display: ${({ display }) => display || "flex"};
  width: 100%;
  justify-content: space-between;

  h3 {
    text-align: end;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: ${({ theme }) => theme.colors.third};
    margin-bottom: 0;
    margin-top: 10px;
    cursor: pointer;

    :hover {
      color: #ff5d00;
    }
  }

  .ant-form-vertical .ant-form-item {
    flex-direction: row;
  }

  .ant-form-item {
    width: ${({ wh }) => wh || "48"}%;
    .ant-form-item-label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    input {
      // max-width: 470px;
      width: 100%;
      height: 50px;
      background: #eeeeee;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      font-size: 18px;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
  }
  .ant-form-item-label > label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 0.05em;

    color: #bbbbbb;
  }
  .description__box {
    padding: 14px 20px;
  }

  .ant-form-item {
    width: ${({ wh }) => wh || "48"}%;
    .ant-form-item-label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    .ant-select-selector {
      width: 100%;
      height: 50px;
      background: #eeeeee;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      align-items: center;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
    .ant-select-selection-placeholder {
      color: #aaaaaa;
    }
  }

  @media screen and (max-width: 1600px) {
    flex-direction: ${({ fd }) => fd || "row"};
  }
  @media screen and (max-width: 1550px) {
    .ant-form-item {
      margin-bottom: 18px;
      input {
        height: 40px;
        font-size: 16px;
      }
      .ant-select-selector {
        height: 40px;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .ant-form-item {
      margin-bottom: 5px;
    }
    .ant-form-item {
      flex-direction: row;
    }
    .ant-form-item .ant-form-item-label {
      padding: 0;
    }
  }

  @media screen and (max-width: 424px) {
    flex-direction: ${({ fd }) => fd || "column"};
    .ant-form-item {
      width: ${({ smwh }) => smwh || "48"}%;
    }
  }
`;

export const LoginWrap = styled.div`
  max-width: 1400px;
  margin: auto;
  @media (max-width: 1440px) {
    padding: 0 30px;
  }
  @media (max-width: 769px) {
    padding: 0 15px;
  }
`;

const LoginLeft = styled.div`
  margin-top: 130px;
  margin-bottom: 30px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  width: 40%;
  padding: 50px;
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 5px;

  h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    margin-bottom: 25px;
    margin-top: 25px;
    color: #ffffff;
  }

  h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: ${({ mb }) => mb || "0"};
    letter-spacing: 0.05em;
    color: #ffffff;
  }
  h2 {
    margin-bottom: ${({ mb }) => mb || "0"};
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    /* identical to box height */
    letter-spacing: 0.05em;
    color: #ffffff;
  }
  p {
    margin-bottom: 35px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    letter-spacing: 0.05em;
    color: #ffffff;
  }
  @media screen and (max-width: 1550px) {
    margin-top: 100px;
    margin-bottom: 20px;
    width: 37%;
    padding: 40px;
    h4 {
      ${
        "" /* font-size: 16px;
      line-height: 18px; */
      }
    }
    h2 {
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 24px;
    }
    p {
      margin-bottom: 28px;
      font-size: 16px;
      line-height: 23px;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 45%;
  }

  @media screen and (max-width: 991.98px) {
    width: 100%;
  }

  @media screen and (max-width: 769px) {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 15px;
    margin-top: 50px;


    h2 {
      font-size: 28px;
      line-height: 40px;
    }
    p {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 23px;
    }
  }
`;

export const LoginMain = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  background-image: url("/images/loginBg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: ${(props) => props.theme.colors.primary};
  box-shadow: inset 0 0 0 1000px ${(props) => props.theme.colors.primary}30;

  background-position: initial;
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
  @keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`;

const LoginBottomWrap = styled.div`
  text-align: center;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    margin: 0;
    color: #ffffff;
    ${"" /* margin-bottom: 30px; */}
    display: flex;
    justify-content: center;
  }
  h2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    margin: 0;
    ${"" /* color: #ffffff; */}
    ${"" /* margin-bottom: 30px; */}
    color: ${({ theme }) => theme.colors.third};
    cursor: pointer;
    :hover {
      color: #ff5d00;
    }
  }
`;
export const LoginWithButton = styled(Button)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  color: #ffffff;
  background: ${({ bgColor }) => bgColor};
  border-color: ${({ bgColor }) => bgColor};
  padding: 10px 15px;
  cursor: pointer;
  transition: 0.5s;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #2b353f;
    margin-left: 15px;
  }
  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ bgColor }) => bgColor}90;
    background: ${({ bgColor }) => bgColor}90;
  }

  @media (max-width: 1550px) {
    height: 40px;
  }
  @media (max-width: 1440px) {
    padding: 0px;
    justify-content: center;
  }
`;
