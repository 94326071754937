import { Col, Form, Radio, Row } from "antd";
import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { applicationProcess } from "../../store/reducers/user.reducer";
import {
  StyledForm,
  StyledForms,
} from "../Profile-verification/BackgroundCheck";
import { StyledButton } from "../property-details/apply-for-property-modal/ApplyForPropertyModal";
import AddReference from "./sub-tabs/AddReference";
import { FormWrap, GeneralProfileWrap } from "./sub-tabs/GeneralProfile";

function RentalHistory({ onBackClick, onMainTabNext }) {
  const { applicationProcessData } = useSelector((state) => state.user);
  const [isSubmit, setIsSubmit] = useState(false);
  const [form] = Form.useForm();
  const [value, setValue] = useState(true);
  const [isAddReference, setIsAddReference] = useState(false);
  const dispatch = useDispatch();

  const onFinish = (formValue) => {
    setIsSubmit(true);
    formValue.rentedBefore = value;

    if (value) {
      setIsAddReference(formValue.rentedBefore);
      setIsSubmit(false);
    } else {
      let data = {};
      data.rentalHistory = {};
      data.rentalHistory.isPreviousRented = false;
      data.rentalHistory.references = [];
      dispatch(applicationProcess(data)).then(({ payload }) => {
        setIsSubmit(false);
        onMainTabNext("payment");
      });
    }
  };

  useEffect(() => {
    if (applicationProcessData && applicationProcessData?.id) {
      if (applicationProcessData?.rentalHistory) {
        setValue(applicationProcessData?.rentalHistory?.isPreviousRented);
      }

      if (applicationProcessData?.rentalHistory?.references?.length) {
        setIsAddReference(true);
      }
    }
  }, [applicationProcessData]);

  const onChange = (e) => {
    setValue(e.target.value);
    form.setFieldsValue({
      rentedBefore: e.target.value,
    });
  };

  const onClickBack = () => {
    setIsAddReference(false);
  };

  return (
    <>
      {isAddReference ? (
        <AddReference
          onMainTabNext={onMainTabNext}
          onClickBack={onClickBack}
          key={"addReferenceas"}
        />
      ) : (
        <GeneralProfileWrap>
          <h1>Have you rented a home or an apartment before?</h1>
          <Radio.Group onChange={onChange} value={value}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
          <FormWrap style={{ bottom: "0", position: "absolute" }} mt="0">
            <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
              <StyledForms>
                <Form
                  layout="vertical"
                  // requiredMark={"optional"}
                  form={form}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="rentedBefore"
                    // label="First Name"
                  >
                    {/* <Input /> */}
                  </Form.Item>
                  <Form.Item>
                    <Row
                      justify="space-between"
                      style={{ alignItems: "center" }}
                    >
                      <Col
                        xs={10}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="back__btn"
                      >
                        <IoIosArrowBack
                          onClick={() => onBackClick("rental-history")}
                        />
                        <h2 onClick={() => onBackClick("rental-history")}>
                          back
                        </h2>
                      </Col>
                      <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Row justify="end" style={{ alignItems: "center" }}>
                          <Col>
                            <p className="cancel__btn">Cancel</p>
                          </Col>
                          <Col xs={10} sm={8} md={8} lg={8} xl={6} xxl={6}>
                            <StyledButton
                              mt="0"
                              loading={isSubmit}
                              htmlType="submit"
                            >
                              next
                            </StyledButton>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </StyledForms>
            </StyledForm>
          </FormWrap>
        </GeneralProfileWrap>
      )}
    </>
  );
}

export default RentalHistory;
