import React from "react";
import { Container } from "../container/Container";
import { TopCitiesTitle } from "./TopBusinessCities.style";

function TopBusinessCities() {
  return (
    <Container padding="70px 0">
      <TopCitiesTitle>Top Business Cities in USA</TopCitiesTitle>
    </Container>
  );
}

export default TopBusinessCities;
