import React from "react";

const TwitterLogo = ({
  wSize = "34",
  hSize = "34",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 0C26.3874 0 34 7.6126 34 17C34 26.3874 26.3874 34 17 34C7.6126 34 0 26.3874 0 17C0 7.6126 7.6126 0 17 0Z"
        fill="#1DA1F2"
      />
      <path
        d="M13.056 25.6358C21.0732 25.6358 25.4626 18.9922 25.4626 13.2292C25.4626 13.0422 25.4626 12.8518 25.449 12.6648C26.3024 12.046 27.0402 11.2844 27.625 10.4072C26.8294 10.7608 25.9862 10.992 25.1226 11.094C26.0338 10.55 26.7138 9.69315 27.0402 8.68335C26.1834 9.18995 25.2484 9.55035 24.2726 9.74075C22.6202 7.98635 19.8594 7.90135 18.105 9.55035C16.9728 10.6146 16.4934 12.2024 16.8436 13.7154C13.3382 13.5386 10.0708 11.8828 7.85744 9.15935C6.70144 11.1518 7.28964 13.6984 9.20724 14.9802C8.51364 14.9598 7.83364 14.7728 7.22844 14.4362C7.22844 14.4532 7.22844 14.4736 7.22844 14.4906C7.22844 16.5646 8.69044 18.353 10.727 18.7644C10.0844 18.9378 9.41124 18.965 8.75844 18.8392C9.32964 20.614 10.965 21.8312 12.8316 21.8686C11.288 23.0824 9.38064 23.742 7.41884 23.7386C7.07204 23.7386 6.72524 23.7182 6.38184 23.674C8.36744 24.9558 10.6896 25.6358 13.056 25.6324"
        fill="white"
      />
    </svg>
  );
};

export default TwitterLogo;
