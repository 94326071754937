import React from "react";

const bedSvg = ({
  wSize = "20",
  hSize = "19",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6.556V0H16V2H4V0H2V6.557C0.81 7.25 0 8.526 0 10V14C0 14.2652 0.105357 14.5196 0.292893 14.7071C0.48043 14.8946 0.734784 15 1 15H2V19H4V15H16V19H18V15H19C19.2652 15 19.5196 14.8946 19.7071 14.7071C19.8946 14.5196 20 14.2652 20 14V10C20 8.526 19.189 7.25 18 6.556ZM9 6H4V4H9V6ZM16 6H11V4H16V6Z"
        fill="#848687"
      />
    </svg>
  );
};

export default bedSvg;
