import React, { useEffect, useState } from "react";
import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../../../Profile-verification/BackgroundCheck";
import { StyledButton } from "../../../property-details/apply-for-property-modal/ApplyForPropertyModal";
import { FormWrap, GeneralProfileWrap } from "../GeneralProfile";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import data from "../../../Profile-verification/state.json";
import { useDispatch, useSelector } from "react-redux";
import { applicationProcess } from "../../../../store/reducers/user.reducer";

function CreditHistory({ onYourSpouseNext, onBackClickTabs }) {
  const [form] = Form.useForm();
  const { applicationProcessData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const [citesValue, setCitesValue] = useState([]);
  const [stateValue, setstateValue] = useState("");

  const monthFormat = "YYYY/MM/DD";
  const { Option } = Select;

  const onFinish = (value) => {
    setIsSubmit(true);
    let data = {};
    data.stateProfile = {};
    data.stateProfile.creditHistory = value;

    dispatch(applicationProcess(data)).then(({ payload }) => {
      setIsSubmit(false);
      onYourSpouseNext("criminal-history");
    });
  };

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  useEffect(() => {
    setCitesValue(data[stateValue]);
  }, [stateValue]);

  const onChange = (newValue) => {
    setstateValue(newValue);
    form.setFieldsValue({ city: [] });
  };

  useEffect(() => {
    if (
      applicationProcessData?.stateProfile &&
      Object.values(applicationProcessData?.stateProfile?.creditHistory)?.length
    ) {
      const creditHistory = applicationProcessData?.stateProfile?.creditHistory;
      form.setFieldsValue({
        bankName: creditHistory.bankName,
        state: creditHistory.state,
        city: creditHistory.city,
        zipcode: creditHistory.zipcode,
        majorCreditCards: creditHistory.majorCreditCards,
        nonWorkIncome: creditHistory.nonWorkIncome,
        creditProblems: creditHistory.creditProblems,
      });
    } else {
    }
  }, [applicationProcessData]);

  return (
    <GeneralProfileWrap>
      <h1>Credit History</h1>

      <FormWrap mt="0">
        <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
          <StyledForms>
            <Form
              layout="vertical"
              // requiredMark={"optional"}
              form={form}
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="bankName"
                  label="Your bank name"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="state"
                  label="State"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Select
                    showSearch
                    placeholder=""
                    onChange={onChange}
                    suffixIcon={<IoIosArrowDown />}
                  >
                    {states.length &&
                      states.map((state) => (
                        <Option value={state}>{state}</Option>
                      ))}
                    {/* <Option value="3BHK">Texas</Option>
                        <Option value="4BHK">4BHk</Option> */}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="city"
                  label="City"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Select
                    showSearch
                    placeholder=""
                    // onChange={}
                    suffixIcon={<IoIosArrowDown />}
                  >
                    {citesValue
                      ? citesValue.map((cites) => (
                          <Option value={cites}>{cites}</Option>
                        ))
                      : ""}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="zipcode"
                  label="Zip code"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input type="number" pattern="[0-9]*" />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="majorCreditCards"
                  label="List major credit cards"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="nonWorkIncome"
                  label="Other non-work income you want considered. Please explain"
                  // rules={[{ required: true }]}
                >
                  <Input.TextArea className="description__box" />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="creditProblems"
                  label="Past credit problems you want to explain"
                  // rules={[{ required: true }]}
                >
                  <Input.TextArea className="description__box" />
                </Form.Item>
              </StyledFormItem>

              <Form.Item>
                <Row
                  justify="space-between"
                  style={{ alignItems: "center", marginTop: "20px" }}
                >
                  <Col
                    xs={10}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="back__btn"
                  >
                    <IoIosArrowBack
                      onClick={() => onBackClickTabs("credit-history")}
                    />
                    <h2 onClick={() => onBackClickTabs("credit-history")}>
                      back
                    </h2>
                  </Col>
                  <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Row justify="end" style={{ alignItems: "center" }}>
                      <Col>
                        <p className="cancel__btn">Cancel</p>
                      </Col>
                      <Col xs={10} sm={8} md={8} lg={8} xl={6} xxl={6}>
                        <StyledButton
                          mt="0"
                          loading={isSubmit}
                          htmlType="submit"
                        >
                          next
                        </StyledButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </StyledForms>
        </StyledForm>
      </FormWrap>
    </GeneralProfileWrap>
  );
}

export default CreditHistory;
