import React from "react";

const headerStyleSvg = ({ wSize = "38", hSize = "38" }) => {
  return (
    <svg
      width="121"
      height="263"
      viewBox="0 0 121 263"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-11.4707 250.504C54.2664 250.504 107.557 197.227 107.557 131.506C107.557 65.785 54.2664 12.5078 -11.4707 12.5078C-77.2078 12.5078 -130.498 65.785 -130.498 131.506C-130.498 197.227 -77.2078 250.504 -11.4707 250.504Z"
        stroke="#CEDBF6"
        strokeMiterlimit="10"
      />
      <path
        d="M27.0576 224.485C78.4243 203.213 102.817 144.339 81.5403 92.9849C60.2635 41.6309 1.37422 17.2443 -49.9925 38.5158C-101.359 59.7873 -125.752 118.662 -104.475 170.016C-83.1982 221.37 -24.3091 245.756 27.0576 224.485Z"
        stroke="#CEDBF6"
        strokeMiterlimit="10"
      />
      <path
        d="M68.5875 150.33C79.0346 106.077 51.621 61.7363 7.35753 51.2917C-36.906 40.8472 -81.2577 68.254 -91.7049 112.507C-102.152 156.759 -74.7384 201.1 -30.4749 211.544C13.7886 221.989 58.1404 194.582 68.5875 150.33Z"
        stroke="#CEDBF6"
        strokeMiterlimit="10"
      />
      <path
        d="M51.8198 141.814C57.4855 106.919 33.7834 74.0388 -1.1202 68.3746C-36.0238 62.7103 -68.9117 86.4066 -74.5774 121.302C-80.243 156.197 -56.541 189.076 -21.6374 194.74C13.2663 200.405 46.1541 176.709 51.8198 141.814Z"
        stroke="#CEDBF6"
        strokeMiterlimit="10"
      />
      <path
        d="M-11.4707 177.196C13.7705 177.196 34.2326 156.739 34.2326 131.504C34.2326 106.27 13.7705 85.8125 -11.4707 85.8125C-36.712 85.8125 -57.1738 106.27 -57.1738 131.504C-57.1738 156.739 -36.712 177.196 -11.4707 177.196Z"
        stroke="#CEDBF6"
        strokeMiterlimit="10"
      />
      <path
        d="M-0.989356 156.792C12.9815 151.007 19.6161 134.994 13.8291 121.027C8.0422 107.059 -7.9748 100.426 -21.9457 106.212C-35.9166 111.997 -42.551 128.01 -36.764 141.978C-30.9771 155.945 -14.9603 162.578 -0.989356 156.792Z"
        stroke="#CEDBF6"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default headerStyleSvg;
