import React from "react";

const EditSvg = ({
  size = "19",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.61 2.59L11.41 5.39L2.8 14H0V11.2L8.61 2.59ZM9.59 1.61L11.2 0L14 2.8L12.39 4.41L9.59 1.61Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};

export default EditSvg;
