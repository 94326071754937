import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";
import { Radio } from "antd";
import { OverviewTab, OverviewTabWrap } from "./tabs/Overview";
import { ApplyWrap, StyledButton } from "./PropertyDetails";
import ApplyForPropertyModal from "./apply-for-property-modal/ApplyForPropertyModal";
import { useSelector } from "react-redux";

function PticeHistory({ clickToApply, isBtnLoading, clickToMessage }) {
  const [size, setSize] = useState("1m");

  const [state, setState] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      stroke: {
        curve: "smooth",
      },
      markers: {
        size: [7, 7],
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2020/01/01",
          "2020/07/01",
          "2021/01/01",
          "2021/07/01",
          "2022/01/01",
        ],
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
    },
    series: [
      {
        name: "series-1",
        data: [65, 75, 120, 24, 43],
      },
    ],
  });

  const onChange = (e) => {
    setSize(e.target.value);
  };
  const [modalState, setModalState] = useState(false);
  const [isProfileVerifyed, setIsProfileVerifyed] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const { applicationProcessData } = useSelector((state) => state.user);

  useEffect(() => {
    if (applicationProcessData?.id) {
      if (
        applicationProcessData?.userProfile &&
        applicationProcessData?.stateProfile &&
        applicationProcessData?.rentalHistory &&
        applicationProcessData?.incomeAndEmployment &&
        applicationProcessData?.applicationPayment
      ) {
        setIsProfileVerifyed(true);
      } else {
        setIsProfileVerifyed(false);
      }
    } else {
      setIsProfileVerifyed(false);
    }
  }, [applicationProcessData]);
  return (
    <OverviewTab>
      <OverviewTabWrap>
        <h2>Price History</h2>
        <FilterWrap>
          <Radio.Group
            value={size}
            onChange={onChange}
            style={{
              marginBottom: 16,
            }}
          >
            <Radio.Button value="1m">1 M</Radio.Button>
            <Radio.Button value="6m">6 M</Radio.Button>
            <Radio.Button value="1y">1 Y</Radio.Button>
            <Radio.Button value="all">All</Radio.Button>
          </Radio.Group>
        </FilterWrap>
        <PriceChart>
          <Chart
            options={state.options}
            series={state.series}
            type="line"
            width="100%"
          />
        </PriceChart>
      </OverviewTabWrap>
      <ApplyWrap ml={4} pr={2.8}>
        <h2>Interested in the property, wants to move forward with us?</h2>
        <StyledButton
          loading={isBtnLoading}
          onClick={() => {
            clickToMessage();
          }}
          bgColor={({ theme }) => theme.colors.secondary}
        >
          Message
        </StyledButton>
        <StyledButton
          bgColor={isProfileVerifyed ? "#F67C36" : "#FFE1D0"}
          fcolor={isProfileVerifyed ? "#ffffff" : "#F67C36"}
          onClick={() => {
            clickToApply();
          }}
        >
          {isProfileVerifyed ? "Apply  now" : "Want to apply?"}
        </StyledButton>
      </ApplyWrap>
      <ApplyForPropertyModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
      />
    </OverviewTab>
  );
}

export default PticeHistory;

export const FilterWrap = styled.div`
  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0;
  }
  .ant-radio-button-wrapper {
    margin: 10px;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #4160ac;
    color: #fff !important;
    border-color: #4160ac;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    background-color: #4160ac;
    display: none;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):focus-within {
    box-shadow: 0 0 0 3px #2a5991;
  }
  .ant-radio-button-wrapper-checked:not(
      [class*=" ant-radio-button-wrapper-disabled"]
    ).ant-radio-button-wrapper:first-child {
    border: none;
  }
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
  }
`;
export const PriceChart = styled.div`
  .apexcharts-toolbar {
    display: none;
  }
`;
