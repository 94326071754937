import React from "react";
import TopSearchSection from "./TopSearchSection";
import MarketingSection from "./MarketingSection";
import FeatureSection from "./FeatureSection";
import WhyUs from "./WhyUs";
import OwnersCategory from "./OwnersCategory";
import PlanSection from "./PlanSection";
import FooterSection from "./FooterSection";
import { BackTop } from "antd";
import { MdKeyboardArrowUp } from "react-icons/md";

const OwnersPage = () => {
  const style = {
    height: 40,
    width: 40,
    lineHeight: "40px",
    borderRadius: "50%",
    backgroundColor: "#4160AC90",
    color: "#fff",
    textAlign: "center",
    fontSize: 40,
  };

  return (
    <>
      <TopSearchSection />
      <FeatureSection />
      <WhyUs />
      <OwnersCategory />
      <PlanSection />
      <FooterSection />

      <BackTop>
        <div style={style}>
          <MdKeyboardArrowUp />
        </div>
      </BackTop>
    </>
  );
};

export default OwnersPage;
