import React, { useEffect, useState } from "react";
import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../../../Profile-verification/BackgroundCheck";
import { StyledButton } from "../../../property-details/apply-for-property-modal/ApplyForPropertyModal";
import { FormWrap, GeneralProfileWrap } from "../GeneralProfile";
import { useDispatch, useSelector } from "react-redux";
import { applicationProcess } from "../../../../store/reducers/user.reducer";
import { IoIosArrowBack } from "react-icons/io";

function YourWork({ onYourSpouseNext, onBackClickTabs }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { applicationProcessData } = useSelector((state) => state.user);
  const [isSubmit, setIsSubmit] = useState(false);
  const monthFormat = "YYYY/MM/DD";

  const onFinish = (value) => {
    setIsSubmit(true);
    let data = {};
    data.stateProfile = {};
    data.stateProfile.workDetails = value;

    dispatch(applicationProcess(data)).then(({ payload }) => {
      setIsSubmit(false);
      onYourSpouseNext("credit-history");
    });
  };

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  useEffect(() => {
    if (
      applicationProcessData?.stateProfile &&
      Object.values(applicationProcessData?.stateProfile?.workDetails)?.length
    ) {
      const workDetails = applicationProcessData?.stateProfile?.workDetails;

      form.setFieldsValue({
        workPhone: workDetails.workPhone,
        position: workDetails.position,
        monthlyIncome: workDetails.monthlyIncome,
        begenJob: workDetails.begenJob ? moment(workDetails.begenJob) : "",
        supervisorName: workDetails.supervisorName,
        supervisorPhone: workDetails.supervisorPhone,
      });
    } else {
    }
  }, [applicationProcessData]);

  return (
    <GeneralProfileWrap>
      <h1>Your work</h1>

      <FormWrap mt="0">
        <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
          <StyledForms>
            <Form
              layout="vertical"
              // requiredMark={"optional"}
              form={form}
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  className="input__affix"
                  name="workPhone"
                  label="Work phone "
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input
                    prefix={`+1`}
                    type="number"
                    onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
                  />
                </Form.Item>
                <Form.Item
                  name="position"
                  label="Position"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  className="input__affix"
                  label="Your gross monthly income in over $"
                  name="monthlyIncome"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input prefix={`$`} type="number" pattern="[0-9]*" />
                </Form.Item>
                <Form.Item
                  name="begenJob"
                  label="Date you begen this job "
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <DatePicker
                    placeholder=""
                    format={monthFormat}
                    // picker="day"
                    disabledDate={(current) => {
                      return moment().add(-1, "days") <= current;
                    }}
                    allowClear={false}
                  />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="supervisorName"
                  label="Supervisor’s name"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  className="input__affix"
                  name="supervisorPhone"
                  label="Supervisor’s Phone"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input
                    prefix={`+1`}
                    type="number"
                    onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
                    pattern="[0-9]*"
                  />
                </Form.Item>
              </StyledFormItem>

              <Form.Item>
                <Row
                  justify="space-between"
                  style={{ alignItems: "center", marginTop: "20px" }}
                >
                  <Col
                    xs={10}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="back__btn"
                  >
                    <IoIosArrowBack
                      onClick={() => onBackClickTabs("your-work")}
                    />
                    <h2 onClick={() => onBackClickTabs("your-work")}>back</h2>
                  </Col>
                  <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Row justify="end" style={{ alignItems: "center" }}>
                      <Col>
                        <p className="cancel__btn">Cancel</p>
                      </Col>
                      <Col xs={10} sm={8} md={8} lg={8} xl={6} xxl={6}>
                        <StyledButton
                          mt="0"
                          loading={isSubmit}
                          htmlType="submit"
                        >
                          next
                        </StyledButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </StyledForms>
        </StyledForm>
      </FormWrap>
    </GeneralProfileWrap>
  );
}

export default YourWork;
