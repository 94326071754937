import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import styled from "styled-components";
import {
  FullAccess,
  KitchenSvg,
  LeafSvg,
  PoolSvg,
  TvSvg,
  WashingMachineSvg,
} from "../../../assets/svgs";
import { ApplyWrap, StyledButton } from "../PropertyDetails";
import ApplyForPropertyModal from "../apply-for-property-modal/ApplyForPropertyModal";
import { OverviewTab } from "./Overview";
import { useLocation } from "react-router-dom";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import {
  MdFitnessCenter,
  MdLocalDining,
  MdOutlineAcUnit,
  MdOutlineAirlineSeatIndividualSuite,
  MdOutlineBed,
  MdOutlineCable,
  MdOutlineOutdoorGrill,
  MdOutlinePets,
  MdOutlinePool,
  MdOutlinePower,
  MdOutlineRoofing,
  MdOutlineSignalWifiStatusbarConnectedNoInternet4,
  MdOutlineWaterDrop,
  MdOutlineYard,
} from "react-icons/md";
import { FaRestroom } from "react-icons/fa";
import { GiGate, GiWashingMachine } from "react-icons/gi";
import { RiFridgeLine } from "react-icons/ri";
import { useSelector } from "react-redux";

function Amenities({ ammenities, clickToApply, isBtnLoading, clickToMessage }) {
  const location = useLocation();
  const { width } = useWindowSize();
  const { applicationProcessData } = useSelector((state) => state.user);
  const [isProfileVerifyed, setIsProfileVerifyed] = useState(false);
  
  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const [showData, setShowData] = useState(15);

  const AmenitiesType = [
    "24 hour access",
    "Garden",
    "Pool",
    "Washing machine",
    "Kitchen",
    "TV",
    "24 hour access",
    "Pool",
    "TV",
    "Washing machine",
    "Kitchen",
    "Pool",
    "Washing machine",
    "Garden",
    "24 hour access",
    "Kitchen",
    "TV",
    "Garden",
    "24 hour access",
    "Kitchen",
    "TV",
    "Pool",
    "Garden",
    "Washing machine",
  ];

  useEffect(() => {
    if (width < 770) {
      setShowData(6);
    } else if (width < 769) {
      setShowData(4);
    } else {
      setShowData(15);
    }
  }, [width, location.pathname]);


  useEffect(() => {
    if (applicationProcessData?.id) {
      if (
        applicationProcessData?.userProfile &&
        applicationProcessData?.stateProfile &&
        applicationProcessData?.rentalHistory &&
        applicationProcessData?.incomeAndEmployment &&
        applicationProcessData?.applicationPayment
      ) {
        setIsProfileVerifyed(true);
      } else {
        setIsProfileVerifyed(false);
      }
    } else {
      setIsProfileVerifyed(false);
    }
  }, [applicationProcessData]);

  return (
    <OverviewTab>
      <AmenitiesTabWrap>
        <h2>Amenities</h2>
        <AmenitiesTypes>
          <Row>
            {ammenities?.slice(0, showData)?.map((type, id) => (
              <Col key={id} xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                {type === "24 hour access" ? (
                  <FullAccess />
                ) : type === "Kitchen" ? (
                  <KitchenSvg />
                ) : type === "TV" ? (
                  <TvSvg />
                ) : type === "Pool" ? (
                  <PoolSvg />
                ) : type === "Washing machine" ? (
                  <WashingMachineSvg />
                ) : type === "Washer and Dryer" ? (
                  <WashingMachineSvg />
                ) : type === "Garden" ? (
                  <LeafSvg />
                ) : type === "Bedroom" ? (
                  <MdOutlineBed style={{ fontSize: "24px" }} />
                ) : type === "Washroom" ? (
                  <FaRestroom style={{ fontSize: "24px" }} />
                ) : type === "Dining Area" ? (
                  <MdLocalDining style={{ fontSize: "24px" }} />
                ) : type === "Master Suite" ? (
                  <MdOutlineAirlineSeatIndividualSuite
                    style={{ fontSize: "24px" }}
                  />
                ) : type === "24/7 Water" ? (
                  <MdOutlineWaterDrop style={{ fontSize: "24px" }} />
                ) : type === "Yard" ? (
                  <MdOutlineYard style={{ fontSize: "24px" }} />
                ) : type === "Roof" ? (
                  <MdOutlineRoofing style={{ fontSize: "24px" }} />
                ) : type === "Outdoor areas" ? (
                  <MdOutlineOutdoorGrill style={{ fontSize: "24px" }} />
                ) : type === "Energy" ? (
                  <MdOutlinePower style={{ fontSize: "24px" }} />
                ) : type === "Gym" ? (
                  <MdFitnessCenter style={{ fontSize: "24px" }} />
                ) : type === "Ac" ? (
                  <MdOutlineAcUnit style={{ fontSize: "24px" }} />
                ) : type === "washer and dryer" ? (
                  <GiWashingMachine style={{ fontSize: "24px" }} />
                ) : type === "Fridge" ? (
                  <RiFridgeLine style={{ fontSize: "24px" }} />
                ) : type === "Pets allowed" ? (
                  <MdOutlinePets style={{ fontSize: "24px" }} />
                ) : type === "Internet access" ? (
                  <MdOutlineSignalWifiStatusbarConnectedNoInternet4
                    style={{ fontSize: "24px" }}
                  />
                ) : type === "Gated Access" ? (
                  <GiGate style={{ fontSize: "24px" }} />
                ) : type === "Swimming pool" ? (
                  <MdOutlinePool style={{ fontSize: "24px" }} />
                ) : type === "Cable ready" ? (
                  <MdOutlineCable style={{ fontSize: "24px" }} />
                ) : type === "Dishwasher" ? (
                  <GiWashingMachine style={{ fontSize: "24px" }} />
                ) : type === "Ac" ? (
                  <MdOutlineAcUnit style={{ fontSize: "24px" }} />
                ) : (
                  ""
                )}
                <p>{type}</p>
              </Col>
            ))}
          </Row>
          {ammenities?.length < showData + 1 ? (
            ""
          ) : (
            <Row>
              <Col onClick={() => setShowData(ammenities?.length)}>
                <span>View More</span>
              </Col>
            </Row>
          )}
        </AmenitiesTypes>
      </AmenitiesTabWrap>
      <ApplyWrap ml={4} pr={2.8}>
        <h2>Interested in the property, wants to move forward with us?</h2>
        <StyledButton
          loading={isBtnLoading}
          onClick={() => {
            clickToMessage();
          }}
          bgColor={({ theme }) => theme.colors.secondary}
        >
          Message
        </StyledButton>
        <StyledButton
          bgColor={isProfileVerifyed ? "#F67C36" : "#FFE1D0"}
          fcolor={isProfileVerifyed ? "#ffffff" : "#F67C36"}
          onClick={() => {
            clickToApply();
          }}
        >
          {isProfileVerifyed ? "Apply  now" : "Want to apply?"}
        </StyledButton>
      </ApplyWrap>
      <ApplyForPropertyModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
      />
    </OverviewTab>
  );
}

export default Amenities;

export const AmenitiesTypes = styled.div`
  .ant-col {
    display: flex !important;
    align-items: center;
  }
  svg {
    margin-right: 30px;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
    color: ${({ theme }) => theme.colors.secondary};
    cursor: pointer;
    margin-top: 30px;
  }
`;
export const AmenitiesTab = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 1400px;
  margin: auto;
`;

export const AmenitiesTabWrap = styled.div`
  width: 70%;

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: justify;
    color: #000000;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    text-align: justify;
    color: #2b353f;
  }

  @media (max-width: 1550px) {
    h2 {
      font-size: 22px;
      line-height: 31px;
      margin-bottom: 15px;
    }
  }
  @media (max-width: 769px) {
    width: 100%;
  }
`;
