import React from "react";

const eVerification = ({
  wSize = "126",
  hSize = "94",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width="126"
      height="94"
      viewBox="0 0 126 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M126 82.5049H0V82.5679H126V82.5049Z" fill="#EBEBEB" />
      <path
        d="M59.7279 71.2656H11.0692C10.688 71.2649 10.3227 71.113 10.0534 70.8433C9.78407 70.5735 9.63281 70.2079 9.63281 69.8267V1.42632C9.63613 1.04732 9.78885 0.684931 10.0578 0.417871C10.3267 0.150811 10.6902 0.00064925 11.0692 0H59.7279C60.1095 0 60.4755 0.1516 60.7454 0.42145C61.0152 0.6913 61.1668 1.05729 61.1668 1.43892V69.8267C61.1668 70.2083 61.0152 70.5743 60.7454 70.8442C60.4755 71.114 60.1095 71.2656 59.7279 71.2656ZM11.0692 0.0504002C10.7047 0.0510677 10.3554 0.196324 10.0979 0.454287C9.84043 0.71225 9.69581 1.06184 9.69581 1.42632V69.8267C9.69581 70.1912 9.84043 70.5407 10.0979 70.7987C10.3554 71.0567 10.7047 71.2019 11.0692 71.2026H59.7279C60.0926 71.2019 60.4422 71.0568 60.7001 70.7989C60.958 70.541 61.1031 70.1914 61.1038 69.8267V1.42632C61.1031 1.06161 60.958 0.712024 60.7001 0.454133C60.4422 0.196243 60.0926 0.0510663 59.7279 0.0504002H11.0692Z"
        fill="#EBEBEB"
      />
      <path
        d="M114.233 71.2656H65.5717C65.1903 71.2649 64.8247 71.1131 64.555 70.8434C64.2853 70.5737 64.1335 70.2081 64.1328 69.8267V1.42632C64.1368 1.04709 64.2901 0.684703 64.5594 0.417713C64.8288 0.150723 65.1925 0.000642169 65.5717 0H114.233C114.612 0.00131153 114.974 0.151767 115.243 0.418755C115.511 0.685744 115.664 1.04775 115.667 1.42632V69.8267C115.667 70.2074 115.516 70.5727 115.247 70.8424C114.978 71.1121 114.614 71.2643 114.233 71.2656ZM65.5717 0.0504002C65.207 0.0510663 64.8574 0.196243 64.5995 0.454133C64.3417 0.712024 64.1965 1.06161 64.1958 1.42632V69.8267C64.1965 70.1914 64.3417 70.541 64.5995 70.7989C64.8574 71.0568 65.207 71.2019 65.5717 71.2026H114.233C114.598 71.2019 114.947 71.0568 115.205 70.7989C115.463 70.541 115.608 70.1914 115.609 69.8267V1.42632C115.608 1.06161 115.463 0.712024 115.205 0.454133C114.947 0.196243 114.598 0.0510663 114.233 0.0504002H65.5717Z"
        fill="#EBEBEB"
      />
      <path
        d="M113.372 86.5596H106.82V86.6226H113.372V86.5596Z"
        fill="#EBEBEB"
      />
      <path
        d="M89.7226 87.2451H78.375V87.3081H89.7226V87.2451Z"
        fill="#EBEBEB"
      />
      <path
        d="M106.569 84.2207H91.25V84.2837H106.569V84.2207Z"
        fill="#EBEBEB"
      />
      <path
        d="M18.0647 84.6445H13.2188V84.7075H18.0647V84.6445Z"
        fill="#EBEBEB"
      />
      <path
        d="M34.2456 84.6445H20.3125V84.7075H34.2456V84.6445Z"
        fill="#EBEBEB"
      />
      <path d="M61.5243 85.708H42.7578V85.771H61.5243V85.708Z" fill="#EBEBEB" />
      <path
        d="M95.4059 81.2148H97.4219V43.9062H95.4059V81.2148Z"
        fill="#F0F0F0"
      />
      <path d="M85.7295 82.5H96.8906V81.2123H85.7295V82.5Z" fill="#F0F0F0" />
      <path
        d="M95.4064 43.9033H85.1953V81.2119H95.4064V43.9033Z"
        fill="#F5F5F5"
      />
      <path d="M94.315 63.333H86.2812V69.9959H94.315V63.333Z" fill="#F0F0F0" />
      <path
        d="M94.315 72.0518H86.2812V78.7146H94.315V72.0518Z"
        fill="#F0F0F0"
      />
      <path
        d="M89.2272 67.1201H91.3667C91.5309 67.1201 91.6641 66.987 91.6641 66.8228V66.5027C91.6641 66.3385 91.5309 66.2054 91.3667 66.2054H89.2272C89.063 66.2054 88.9299 66.3385 88.9299 66.5027V66.8228C88.9299 66.987 89.063 67.1201 89.2272 67.1201Z"
        fill="#F5F5F5"
      />
      <path
        d="M89.2272 75.8398H91.3667C91.5309 75.8398 91.6641 75.7067 91.6641 75.5425V75.2224C91.6641 75.0582 91.5309 74.9251 91.3667 74.9251H89.2272C89.063 74.9251 88.9299 75.0582 88.9299 75.2224V75.5425C88.9299 75.7067 89.063 75.8398 89.2272 75.8398Z"
        fill="#F5F5F5"
      />
      <path
        d="M94.315 45.9619H86.2812V52.6248H94.315V45.9619Z"
        fill="#F0F0F0"
      />
      <path
        d="M94.315 54.6113H86.2812V61.2742H94.315V54.6113Z"
        fill="#F0F0F0"
      />
      <path
        d="M89.2272 49.75H91.3667C91.5309 49.75 91.6641 49.6169 91.6641 49.4526V49.1326C91.6641 48.9684 91.5309 48.8352 91.3667 48.8352H89.2272C89.063 48.8352 88.9299 48.9684 88.9299 49.1326V49.4526C88.9299 49.6169 89.063 49.75 89.2272 49.75Z"
        fill="#F5F5F5"
      />
      <path
        d="M89.2272 58.4014H91.3667C91.5309 58.4014 91.6641 58.2682 91.6641 58.104V57.784C91.6641 57.6197 91.5309 57.4866 91.3667 57.4866H89.2272C89.063 57.4866 88.9299 57.6197 88.9299 57.784V58.104C88.9299 58.2682 89.063 58.4014 89.2272 58.4014Z"
        fill="#F5F5F5"
      />
      <path
        d="M106.577 81.2148H112.625V43.9062H106.577V81.2148Z"
        fill="#F0F0F0"
      />
      <path d="M96.9004 82.5H112.008V81.2123H96.9004V82.5Z" fill="#F0F0F0" />
      <path
        d="M106.578 43.9033H96.3672V81.2119H106.578V43.9033Z"
        fill="#F5F5F5"
      />
      <path
        d="M105.495 63.333H97.4609V69.9959H105.495V63.333Z"
        fill="#F0F0F0"
      />
      <path
        d="M105.495 72.0518H97.4609V78.7146H105.495V72.0518Z"
        fill="#F0F0F0"
      />
      <path
        d="M100.407 67.1201H102.546C102.711 67.1201 102.844 66.987 102.844 66.8228V66.5027C102.844 66.3385 102.711 66.2054 102.546 66.2054H100.407C100.243 66.2054 100.11 66.3385 100.11 66.5027V66.8228C100.11 66.987 100.243 67.1201 100.407 67.1201Z"
        fill="#F5F5F5"
      />
      <path
        d="M100.407 75.8398H102.546C102.711 75.8398 102.844 75.7067 102.844 75.5425V75.2224C102.844 75.0582 102.711 74.9251 102.546 74.9251H100.407C100.243 74.9251 100.11 75.0582 100.11 75.2224V75.5425C100.11 75.7067 100.243 75.8398 100.407 75.8398Z"
        fill="#F5F5F5"
      />
      <path
        d="M105.495 45.9619H97.4609V52.6248H105.495V45.9619Z"
        fill="#F0F0F0"
      />
      <path
        d="M105.495 54.6113H97.4609V61.2742H105.495V54.6113Z"
        fill="#F0F0F0"
      />
      <path
        d="M100.407 49.75H102.546C102.711 49.75 102.844 49.6169 102.844 49.4526V49.1326C102.844 48.9684 102.711 48.8352 102.546 48.8352H100.407C100.243 48.8352 100.11 48.9684 100.11 49.1326V49.4526C100.11 49.6169 100.243 49.75 100.407 49.75Z"
        fill="#F5F5F5"
      />
      <path
        d="M100.407 58.4014H102.546C102.711 58.4014 102.844 58.2682 102.844 58.104V57.784C102.844 57.6197 102.711 57.4866 102.546 57.4866H100.407C100.243 57.4866 100.11 57.6197 100.11 57.784V58.104C100.11 58.2682 100.243 58.4014 100.407 58.4014Z"
        fill="#F5F5F5"
      />
      <path
        d="M73.7406 36.1172L107.688 36.1172V6.31566L73.7406 6.31566V36.1172Z"
        fill="#E6E6E6"
      />
      <path
        d="M70.1008 5.15625H109.884C109.974 5.15625 110.059 5.19183 110.123 5.25515C110.186 5.31848 110.222 5.40437 110.222 5.49393C110.221 5.58305 110.185 5.6683 110.122 5.73108C110.059 5.79386 109.973 5.82909 109.884 5.82909H70.1008C70.0121 5.82843 69.9272 5.79291 69.8645 5.73019C69.8018 5.66748 69.7663 5.58262 69.7656 5.49393C69.7656 5.40481 69.8009 5.3193 69.8636 5.25604C69.9264 5.19279 70.0117 5.15692 70.1008 5.15625Z"
        fill="#E6E6E6"
      />
      <path
        d="M72.3001 36.1172L107.016 36.1172V6.31566L72.3001 6.31566V36.1172Z"
        fill="#F0F0F0"
      />
      <path
        d="M73.7429 36.1172H107.69V37.0723H72.75L73.7429 36.1172Z"
        fill="#E6E6E6"
      />
      <path
        d="M69.9069 36.1172H104.622L103.63 37.0723H68.9141L69.9069 36.1172Z"
        fill="#F0F0F0"
      />
      <path
        d="M105 34.1006V8.33105L74.319 8.33105V34.1006H105Z"
        fill="#FAFAFA"
      />
      <path
        d="M98.0327 34.1006L86.1962 8.33105H80.6094L92.4458 34.1006H98.0327Z"
        fill="white"
      />
      <path
        d="M91.2506 34.1006L79.4142 8.33105H77.2344L89.0708 34.1006H91.2506Z"
        fill="white"
      />
      <path
        d="M74.5781 34.1006V8.33105H74.3211V34.1006H74.5781Z"
        fill="#E6E6E6"
      />
      <g opacity="0.4">
        <path
          d="M104.967 29.1436H108.438V4.55087H104.967V29.1436Z"
          fill="#F0F0F0"
        />
        <path
          d="M103.616 29.1436H106.32V4.55087H103.616V29.1436Z"
          fill="#E6E6E6"
        />
        <path
          d="M101.499 29.1436H104.969V4.55087H101.499V29.1436Z"
          fill="#F0F0F0"
        />
        <path
          d="M100.148 29.1436H102.852V4.55087H100.148V29.1436Z"
          fill="#E6E6E6"
        />
        <path d="M98.03 29.1436H101.5V4.55087H98.03V29.1436Z" fill="#F0F0F0" />
      </g>
      <g opacity="0.4">
        <path
          d="M75.6007 29.1436H77.6016V4.55087H75.6007V29.1436Z"
          fill="#F0F0F0"
        />
        <path
          d="M74.8255 29.1436H76.3828V4.55087H74.8255V29.1436Z"
          fill="#E6E6E6"
        />
        <path
          d="M73.6007 29.1436H75.6016V4.55087H73.6007V29.1436Z"
          fill="#F0F0F0"
        />
        <path
          d="M72.8255 29.1436H74.3828V4.55087H72.8255V29.1436Z"
          fill="#E6E6E6"
        />
        <path
          d="M71.6007 29.1436H73.6016V4.55087H71.6007V29.1436Z"
          fill="#F0F0F0"
        />
      </g>
      <path
        d="M41.3828 24.3682H46.1859L45.6189 12.1865H41.3828V24.3682Z"
        fill="#F5F5F5"
      />
      <path
        d="M48.8998 25.0264L49.9355 30.4368H48.8998L42.5469 25.0264H48.8998Z"
        fill="#F5F5F5"
      />
      <path
        d="M49.9389 25.0264V30.4368L43.5859 25.0264H49.9389Z"
        fill="#E6E6E6"
      />
      <path
        d="M23.9857 25.0264L25.0215 30.4368H23.9857L17.6328 25.0264H23.9857Z"
        fill="#F5F5F5"
      />
      <path
        d="M25.017 25.0264V30.4368L18.6641 25.0264H25.017Z"
        fill="#E6E6E6"
      />
      <path
        d="M54.5973 24.3857H47.6875V25.1669H54.5973V24.3857Z"
        fill="#E6E6E6"
      />
      <path
        d="M13.3349 25.167L47.6875 25.167V24.3858L13.3349 24.3858V25.167Z"
        fill="#F5F5F5"
      />
      <path
        d="M34.3281 24.3686H44.64L45.9151 9.60645H35.6032L34.3281 24.3686Z"
        fill="#E6E6E6"
      />
      <path
        d="M33.7891 24.3686H44.1009L45.376 9.60645H35.0642L33.7891 24.3686Z"
        fill="#F0F0F0"
      />
      <path
        d="M43.2379 22.733L44.4046 11.2393H35.9324L34.7656 22.733H43.2379Z"
        fill="white"
      />
      <path
        d="M29.7109 24.3686H33.0197L32.6291 15.9795H29.7109V24.3686Z"
        fill="#E0E0E0"
      />
      <path
        d="M24.8516 24.3688H31.9504L32.8299 14.2031H25.7285L24.8516 24.3688Z"
        fill="#F0F0F0"
      />
      <path
        d="M24.4844 24.3688H31.5857L32.4627 14.2031H25.3613L24.4844 24.3688Z"
        fill="#F5F5F5"
      />
      <path
        d="M30.9901 23.2419L31.7914 15.3291H25.9601L25.1562 23.2419H30.9901Z"
        fill="#E0E0E0"
      />
      <path
        d="M66.1184 45.7224C66.1419 46.0041 66.0813 46.2865 65.9442 46.5338C65.8071 46.7811 65.5998 46.9821 65.3484 47.1115C65.0971 47.2409 64.813 47.2929 64.5321 47.2607C64.2512 47.2286 63.9861 47.1139 63.7705 46.931C63.5548 46.7482 63.3983 46.5055 63.3206 46.2337C63.2429 45.9618 63.2477 45.673 63.3342 45.4039C63.4207 45.1347 63.5851 44.8973 63.8067 44.7216C64.0282 44.5459 64.2968 44.4399 64.5786 44.417C64.7655 44.4015 64.9535 44.4229 65.1321 44.4802C65.3106 44.5374 65.4761 44.6292 65.6191 44.7505C65.7621 44.8717 65.8798 45.02 65.9655 45.1867C66.0512 45.3535 66.1031 45.5355 66.1184 45.7224Z"
        fill="#E0E0E0"
      />
      <path
        d="M68.7668 46.4692C68.7903 46.751 68.7297 47.0334 68.5926 47.2807C68.4556 47.5279 68.2483 47.729 67.9969 47.8584C67.7455 47.9878 67.4614 48.0397 67.1805 48.0076C66.8996 47.9755 66.6346 47.8607 66.4189 47.6779C66.2033 47.4951 66.0467 47.2524 65.969 46.9805C65.8914 46.7087 65.8961 46.4199 65.9826 46.1507C66.0691 45.8816 66.2336 45.6441 66.4551 45.4685C66.6766 45.2928 66.9453 45.1868 67.2271 45.1639C67.6043 45.1332 67.9782 45.2534 68.2669 45.4981C68.5556 45.7429 68.7353 46.0921 68.7668 46.4692Z"
        fill="#E0E0E0"
      />
      <path
        d="M70.6889 45.6122C70.7119 45.8939 70.6508 46.1761 70.5134 46.423C70.376 46.67 70.1685 46.8707 69.917 46.9997C69.6656 47.1287 69.3815 47.1803 69.1007 47.1478C68.82 47.1154 68.5552 47.0004 68.3398 46.8175C68.1243 46.6345 67.968 46.3917 67.8906 46.1199C67.8132 45.8481 67.8181 45.5594 67.9047 45.2904C67.9914 45.0214 68.1559 44.7841 68.3774 44.6086C68.5989 44.4331 68.8675 44.3272 69.1492 44.3043C69.5266 44.2743 69.9006 44.3951 70.1891 44.6402C70.4777 44.8854 70.6574 45.2349 70.6889 45.6122Z"
        fill="#E0E0E0"
      />
      <path
        d="M72.7075 46.1458C72.7299 46.4268 72.6686 46.7082 72.5312 46.9543C72.3939 47.2005 72.1866 47.4005 71.9357 47.5289C71.6847 47.6574 71.4014 47.7086 71.1213 47.6761C70.8413 47.6437 70.5772 47.5289 70.3623 47.3464C70.1474 47.1639 69.9915 46.9218 69.9141 46.6507C69.8368 46.3796 69.8415 46.0917 69.9277 45.8233C70.0139 45.5549 70.1776 45.318 70.3984 45.1426C70.6191 44.9672 70.8868 44.8612 71.1678 44.8379C71.3548 44.8224 71.5431 44.8439 71.7218 44.9013C71.9004 44.9587 72.066 45.0508 72.2091 45.1723C72.3521 45.2938 72.4698 45.4423 72.5553 45.6093C72.6408 45.7764 72.6925 45.9587 72.7075 46.1458Z"
        fill="#E0E0E0"
      />
      <path
        d="M74.5264 46.3682C74.5264 46.3682 74.3601 50.3346 67.9895 50.3346C61.6189 50.3346 61.4375 46.3682 61.4375 46.3682H74.5264Z"
        fill="#F0F0F0"
      />
      <path
        d="M15.0904 82.5049H17.4062L17.4062 50.3421H15.0904L15.0904 82.5049Z"
        fill="#E6E6E6"
      />
      <path
        d="M14.4726 82.5049H15.8359L15.8359 50.3421H14.4726L14.4726 82.5049Z"
        fill="#F0F0F0"
      />
      <path
        d="M46.9228 50.3418H14.0469V52.7534H46.9228V50.3418Z"
        fill="#F0F0F0"
      />
      <path
        d="M78.0591 82.5049H80.375V50.3421H78.0591V82.5049Z"
        fill="#E6E6E6"
      />
      <path
        d="M77.6289 82.5049H78.9922V50.3421H77.6289V82.5049Z"
        fill="#F0F0F0"
      />
      <path d="M80.79 50.3418H47.9141V52.7534H80.79V50.3418Z" fill="#E6E6E6" />
      <path
        d="M47.1216 82.5049H49.4375L49.4375 50.3421H47.1216L47.1216 82.5049Z"
        fill="#E6E6E6"
      />
      <path
        d="M46.5039 82.5049H47.8672L47.8672 50.3421H46.5039L46.5039 82.5049Z"
        fill="#F0F0F0"
      />
      <path
        d="M54.723 39.6445H37.5593L34.5 49.78H51.6662L54.723 39.6445Z"
        fill="#E6E6E6"
      />
      <path
        d="M51.664 49.7803L51.5959 50.3422H34.4297L34.4977 49.7803H51.664Z"
        fill="#E6E6E6"
      />
      <path
        d="M54.1917 39.6445H37.0255L33.9688 49.78H51.1325L54.1917 39.6445Z"
        fill="#F0F0F0"
      />
      <path
        d="M50.7996 49.319L53.5767 40.1084H37.3655L34.5859 49.319H50.7996Z"
        fill="white"
      />
      <path
        d="M46.6606 49.7803L46.59 50.3422H27.6875L27.7555 49.7803H46.6606Z"
        fill="#F0F0F0"
      />
      <path
        d="M63.0009 93.885C89.9857 93.885 111.861 92.6078 111.861 91.0323C111.861 89.4569 89.9857 88.1797 63.0009 88.1797C36.0161 88.1797 14.1406 89.4569 14.1406 91.0323C14.1406 92.6078 36.0161 93.885 63.0009 93.885Z"
        fill="#F5F5F5"
      />
      <path
        d="M95.148 13.6457H66.1453C65.1506 13.6621 64.1976 14.0486 63.4726 14.7299C62.7475 15.4111 62.3024 16.3382 62.2242 17.33L57.7134 86.7711C57.6766 87.2439 57.7387 87.7192 57.8957 88.1667C58.0526 88.6143 58.301 89.0242 58.625 89.3706C58.9491 89.7169 59.3416 89.9919 59.7778 90.1783C60.2139 90.3646 60.684 90.4581 61.1582 90.4528H90.171C91.1655 90.4371 92.1185 90.0511 92.8436 89.3702C93.5687 88.6894 94.0139 87.7626 94.0921 86.7711L97.3278 36.86C97.4653 36.812 97.5859 36.7254 97.6753 36.6105C97.7647 36.4956 97.8191 36.3573 97.8318 36.2123L98.4114 27.2814C98.4215 27.1427 98.3862 27.0044 98.3107 26.8876C98.2352 26.7707 98.1237 26.6816 97.9931 26.6338L98.5954 17.33C98.6321 16.8568 98.57 16.3812 98.4129 15.9333C98.2558 15.4855 98.0072 15.0752 97.683 14.7287C97.3587 14.3822 96.9659 14.1069 96.5295 13.9205C96.093 13.734 95.6226 13.6404 95.148 13.6457Z"
        fill="#4160AC"
      />
      <path
        d="M59.8772 31.6258H60.4467C60.6229 31.6227 60.7917 31.5541 60.9202 31.4335C61.0487 31.3129 61.1279 31.1488 61.1422 30.9731L61.3942 27.2435C61.4005 27.1596 61.3893 27.0753 61.3613 26.996C61.3333 26.9166 61.2891 26.844 61.2315 26.7826C61.1739 26.7212 61.1042 26.6725 61.0268 26.6395C60.9494 26.6065 60.866 26.5899 60.7818 26.5909H60.2148C60.0384 26.5935 59.8694 26.6618 59.7407 26.7825C59.6121 26.9033 59.5331 27.0677 59.5193 27.2435L59.2673 30.9731C59.2611 31.0568 59.2722 31.1409 59.3001 31.2201C59.3279 31.2993 59.3719 31.3718 59.4292 31.4331C59.4865 31.4945 59.5559 31.5433 59.633 31.5764C59.7101 31.6096 59.7932 31.6264 59.8772 31.6258Z"
        fill="#4160AC"
      />
      <path
        d="M59.4426 38.284H60.0096C60.1865 38.2809 60.3559 38.212 60.4849 38.091C60.6139 37.9699 60.6933 37.8052 60.7076 37.6288L60.9596 33.9017C60.9659 33.8178 60.9547 33.7335 60.9267 33.6542C60.8987 33.5748 60.8545 33.5022 60.7969 33.4408C60.7393 33.3794 60.6696 33.3307 60.5922 33.2977C60.5148 33.2647 60.4314 33.2481 60.3473 33.2491H59.7803C59.6036 33.2516 59.4342 33.3199 59.3052 33.4405C59.1761 33.5612 59.0966 33.7256 59.0822 33.9017L58.8302 37.6288C58.8232 37.713 58.8338 37.7978 58.8616 37.8776C58.8893 37.9574 58.9334 38.0305 58.9911 38.0923C59.0488 38.154 59.1187 38.203 59.1965 38.236C59.2743 38.269 59.3581 38.2854 59.4426 38.284Z"
        fill="#4160AC"
      />
      <path
        d="M88.6297 90.4528H59.627C59.1528 90.458 58.6826 90.3645 58.2465 90.1782C57.8104 89.9919 57.4178 89.7168 57.0938 89.3705C56.7698 89.0242 56.5214 88.6143 56.3644 88.1667C56.2075 87.7192 56.1454 87.2439 56.1822 86.771L60.6829 17.3299C60.7617 16.3379 61.2073 15.4108 61.9328 14.7296C62.6582 14.0484 63.6115 13.662 64.6065 13.6457H93.6067C94.081 13.6408 94.5512 13.7346 94.9873 13.9212C95.4235 14.1078 95.816 14.3831 96.14 14.7296C96.464 15.0761 96.7123 15.4862 96.8692 15.9339C97.0262 16.3816 97.0882 16.857 97.0515 17.3299L92.5508 86.771C92.4725 87.7626 92.0273 88.6894 91.3022 89.3702C90.5771 90.051 89.6242 90.437 88.6297 90.4528Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M76.0987 87.4996C77.1146 86.5161 77.1912 84.9471 76.2696 83.9951C75.3481 83.0431 73.7774 83.0687 72.7614 84.0522C71.7455 85.0357 71.6689 86.6047 72.5905 87.5567C73.512 88.5087 75.0827 88.4831 76.0987 87.4996Z"
        fill="white"
      />
      <path
        d="M91.2508 81.4389H58.2816C58.1694 81.4388 58.0583 81.4157 57.9553 81.371C57.8523 81.3263 57.7596 81.2609 57.6829 81.179C57.6061 81.0971 57.547 81.0003 57.5091 80.8946C57.4712 80.7889 57.4554 80.6765 57.4626 80.5645L61.3208 21.0119C61.3378 20.8062 61.4314 20.6144 61.5831 20.4744C61.7347 20.3344 61.9334 20.2564 62.1398 20.2559H95.1114C95.2237 20.256 95.3348 20.2791 95.4378 20.3238C95.5408 20.3685 95.6335 20.4338 95.7102 20.5158C95.787 20.5977 95.8461 20.6945 95.884 20.8002C95.9219 20.9059 95.9377 21.0183 95.9304 21.1303L92.0698 80.6703C92.0564 80.8785 91.9643 81.0737 91.8122 81.2165C91.6601 81.3592 91.4594 81.4387 91.2508 81.4389Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M82.0503 57.9667C85.7382 54.3967 86.0158 48.701 82.6703 45.2451C79.3248 41.7893 73.6232 41.8818 69.9353 45.4519C66.2474 49.0219 65.9699 54.7176 69.3153 58.1734C72.6608 61.6293 78.3624 61.5368 82.0503 57.9667Z"
        fill="#4160AC"
      />
      <path
        opacity="0.2"
        d="M75.1038 65.5855C67.4506 65.5855 61.6268 59.3586 62.1233 51.7054C62.6197 44.0521 69.2473 37.8252 76.9031 37.8252C84.5588 37.8252 90.38 44.0521 89.8836 51.7054C89.3872 59.3586 82.7495 65.5855 75.1038 65.5855ZM76.8678 38.0772C69.3532 38.0772 62.844 44.1907 62.3576 51.7054C61.8713 59.22 67.5866 65.3335 75.1013 65.3335C82.6159 65.3335 89.1251 59.22 89.6139 51.7054C90.1028 44.1907 84.3925 38.0772 76.8678 38.0772Z"
        fill="#4160AC"
      />
      <path
        d="M73.8438 56.0678L73.0878 54.6087C71.4498 51.431 67.6773 48.591 66.3594 47.7644L67.6043 45.9021C69.1641 46.8799 72.2814 49.3016 74.2369 52.1795C77.8531 47.8955 82.9637 43.4829 89.8055 38.7227L90.9722 40.4867C83.6642 45.572 78.405 50.2214 74.9072 54.702L73.8438 56.0678Z"
        fill="#4160AC"
      />
      <path
        opacity="0.2"
        d="M73.4879 17.61C73.8989 17.2122 73.9292 16.5767 73.5555 16.1907C73.1818 15.8048 72.5458 15.8144 72.1348 16.2122C71.7238 16.6101 71.6935 17.2455 72.0672 17.6315C72.4408 18.0175 73.0769 18.0079 73.4879 17.61Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M76.337 15.9063H84.6756C84.8053 15.9048 84.9337 15.9304 85.0529 15.9815C85.172 16.0325 85.2792 16.108 85.3674 16.2029C85.4557 16.2978 85.5231 16.4101 85.5655 16.5326C85.6078 16.6551 85.624 16.7852 85.6131 16.9143C85.5924 17.1852 85.4715 17.4386 85.2739 17.625C85.0763 17.8114 84.8162 17.9175 84.5446 17.9223H76.2084C76.0788 17.9234 75.9504 17.8975 75.8314 17.8464C75.7123 17.7952 75.6052 17.7198 75.5168 17.625C75.4284 17.5302 75.3607 17.4181 75.3179 17.2958C75.2752 17.1734 75.2584 17.0435 75.2685 16.9143C75.2891 16.6434 75.4101 16.39 75.6077 16.2036C75.8053 16.0172 76.0653 15.9112 76.337 15.9063Z"
        fill="white"
      />
      <path
        d="M45.296 34.5246C44.8702 34.9606 44.414 35.4419 43.9932 35.9207C43.5724 36.3995 43.1515 36.8909 42.7559 37.3949C42.3602 37.8989 41.9999 38.4029 41.6269 38.9346L41.3749 39.3302L41.1229 39.7309C41.0397 39.8645 40.9641 40.0006 40.8911 40.1392L40.6895 40.5096C40.6073 40.7214 40.5605 40.9453 40.5509 41.1724C40.5307 41.4643 40.5307 41.7574 40.5509 42.0493C40.6003 42.6793 40.6844 43.306 40.8029 43.9267C40.9138 44.5618 41.0549 45.1993 41.2036 45.8344C41.3522 46.4694 41.516 47.112 41.6823 47.737L40.75 48.0872C40.177 46.8547 39.6969 45.5811 39.3136 44.277C39.1189 43.6049 38.9699 42.9204 38.8675 42.2282C38.8165 41.8591 38.7904 41.487 38.7894 41.1144C38.7835 40.6754 38.8578 40.239 39.0086 39.8267C39.0666 39.6856 39.0968 39.6301 39.1372 39.5444L39.2531 39.305C39.3287 39.1463 39.4068 38.9875 39.5051 38.8338L39.7571 38.3701L40.0242 37.919C40.3801 37.3248 40.7679 36.7503 41.1859 36.1979C41.5992 35.646 42.0276 35.1092 42.4787 34.5901C42.9298 34.071 43.3859 33.5821 43.91 33.0781L45.296 34.5246Z"
        fill="#B55B52"
      />
      <path
        d="M45.4058 31.5008C44.04 31.5613 42.7422 33.8293 42.7422 33.8293L44.5465 37.511C44.5465 37.511 46.9909 35.4749 47.0211 33.479C47.0413 32.1711 46.749 31.4378 45.4058 31.5008Z"
        fill="#4160AC"
      />
      <path
        opacity="0.7"
        d="M45.4058 31.5008C44.04 31.5613 42.7422 33.8293 42.7422 33.8293L44.5465 37.511C44.5465 37.511 46.9909 35.4749 47.0211 33.479C47.0413 32.1711 46.749 31.4378 45.4058 31.5008Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M45.7283 34.1462C45.3301 34.7485 45.1386 36.3109 45.0781 37.0064C45.6794 36.4217 46.1891 35.7497 46.5901 35.0131C46.3205 34.3403 45.9904 33.7506 45.7283 34.1462Z"
        fill="black"
      />
      <path
        d="M41.4183 47.3535L43.079 47.8575L41.9727 48.9764C41.9727 48.9764 40.7757 48.606 40.6094 47.792L41.4183 47.3535Z"
        fill="#B55B52"
      />
      <path
        d="M44.0805 49.1253L43.8008 50.1963L41.9688 48.9665L43.075 47.8477L44.0805 49.1253Z"
        fill="#B55B52"
      />
      <path
        d="M55.0083 27.1289C54.9554 27.2775 54.9932 27.4287 55.0915 27.464C55.1898 27.4993 55.3107 27.4111 55.3611 27.2624C55.4115 27.1137 55.3763 26.96 55.278 26.9247C55.1797 26.8895 55.0613 26.9777 55.0083 27.1289Z"
        fill="#263238"
      />
      <path
        d="M55.2344 26.917L55.63 26.9498C55.63 26.9498 55.3478 27.1589 55.2344 26.917Z"
        fill="#263238"
      />
      <path
        d="M55.0842 27.3203C55.0991 27.7124 55.1669 28.1006 55.2857 28.4745C55.1896 28.4996 55.0891 28.5037 54.9912 28.4863C54.8933 28.4689 54.8004 28.4304 54.7188 28.3737L55.0842 27.3203Z"
        fill="#A02724"
      />
      <path
        d="M55.497 26.546C55.479 26.5435 55.4622 26.5357 55.4487 26.5235C55.4353 26.5114 55.4257 26.4955 55.4214 26.4779C55.3937 26.364 55.3406 26.2579 55.266 26.1675C55.1913 26.0771 55.0971 26.0049 54.9904 25.9563C54.9675 25.9478 54.9489 25.9306 54.9385 25.9084C54.9281 25.8863 54.9269 25.8609 54.935 25.8379C54.9389 25.8263 54.9452 25.8156 54.9533 25.8066C54.9615 25.7975 54.9714 25.7901 54.9824 25.7849C54.9935 25.7797 55.0055 25.7769 55.0177 25.7764C55.0299 25.776 55.042 25.778 55.0534 25.7824C55.1906 25.842 55.3118 25.9331 55.4073 26.0482C55.5027 26.1633 55.5697 26.2993 55.6028 26.4452C55.6058 26.4571 55.6065 26.4695 55.6047 26.4816C55.6029 26.4938 55.5987 26.5055 55.5924 26.516C55.586 26.5266 55.5777 26.5357 55.5678 26.543C55.5579 26.5504 55.5467 26.5556 55.5348 26.5586L55.497 26.546Z"
        fill="#263238"
      />
      <path
        d="M49.8283 27.7607C49.8636 28.9527 49.7426 31.0972 48.8203 31.7045C48.8203 31.7045 48.9438 32.9293 51.0883 33.3451C53.4445 33.8012 52.4063 32.4001 52.4063 32.4001C51.1715 31.8457 51.3605 30.9032 51.7309 30.0565L49.8283 27.7607Z"
        fill="#B55B52"
      />
      <path
        d="M56.8346 35.918C57.0462 36.495 57.2882 37.12 57.5452 37.7122C57.8022 38.3044 58.0492 38.9042 58.3491 39.4762C58.649 40.0482 58.9413 40.6228 59.2588 41.1797C59.4226 41.4544 59.5864 41.7291 59.7628 41.9912C59.8485 42.1273 59.9367 42.2583 60.0299 42.3818L60.166 42.5733L60.234 42.6716L60.2693 42.7195L60.292 42.7446C60.4559 42.9311 60.644 43.0947 60.8514 43.231C61.1012 43.4016 61.3603 43.5581 61.6276 43.6997C62.1795 43.9971 62.7717 44.2667 63.374 44.5212C63.9762 44.7758 64.5911 45.0252 65.2136 45.2445C65.836 45.4637 66.4736 45.6956 67.0859 45.9098L66.8516 46.88C65.5011 46.6542 64.1668 46.3402 62.8574 45.94C62.1869 45.7356 61.529 45.4924 60.8867 45.2117C60.5499 45.0623 60.2218 44.894 59.9039 44.7077C59.5351 44.4975 59.201 44.2314 58.9136 43.919L58.8531 43.8509L58.8102 43.798L58.7271 43.6947L58.5608 43.483C58.4474 43.3444 58.339 43.2033 58.2382 43.0596C58.024 42.7749 57.8274 42.4826 57.6334 42.1877C57.251 41.6029 56.8986 40.999 56.5775 40.3784C56.2575 39.761 55.9551 39.1385 55.6779 38.5035C55.4007 37.8684 55.1512 37.2435 54.9219 36.548L56.8346 35.918Z"
        fill="#B55B52"
      />
      <path
        d="M66.7565 45.9046L68.2433 45.3301L68.1703 47.0462C68.1703 47.0462 66.9657 47.3662 66.4062 46.7589L66.7565 45.9046Z"
        fill="#B55B52"
      />
      <path
        d="M69.3585 45.9198L69.8676 47.016L68.1641 47.0462L68.2371 45.3301L69.3585 45.9198Z"
        fill="#B55B52"
      />
      <path
        d="M44.7031 88.9285L46.6712 89.0747L47.2559 84.5563L45.2878 84.4102L44.7031 88.9285Z"
        fill="#B55B52"
      />
      <path
        d="M29.1172 78.7078L30.4024 80.2349L34.2177 77.783L32.9325 76.2559L29.1172 78.7078Z"
        fill="#B55B52"
      />
      <path
        d="M30.9999 80.0388L29.9642 78.048C29.9477 78.0134 29.9191 77.9861 29.8838 77.9712C29.8485 77.9564 29.809 77.955 29.7727 77.9674L28.1271 78.5369C28.0859 78.5535 28.0485 78.5787 28.0177 78.6107C27.9868 78.6428 27.9631 78.6811 27.9481 78.723C27.9331 78.7649 27.9272 78.8095 27.9307 78.8539C27.9342 78.8982 27.947 78.9414 27.9684 78.9804C28.3413 79.6684 28.5555 79.9884 29.0091 80.8578C29.2914 81.3946 29.6467 82.211 30.0323 82.9544C30.4178 83.6978 31.1537 83.3677 31.0579 83.0225C30.6245 81.4828 30.9117 81.0569 31.0579 80.3891C31.0787 80.2691 31.0582 80.1457 30.9999 80.0388Z"
        fill="#263238"
      />
      <path
        d="M46.7967 88.7949H44.5841C44.546 88.7945 44.509 88.8077 44.4799 88.8322C44.4508 88.8567 44.4314 88.8909 44.4254 88.9285L44.128 90.6774C44.1219 90.721 44.1252 90.7654 44.1377 90.8076C44.1502 90.8498 44.1716 90.8889 44.2006 90.9221C44.2295 90.9553 44.2652 90.9819 44.3053 91.0001C44.3454 91.0183 44.3889 91.0277 44.4329 91.0277C45.2066 91.0151 45.577 90.9697 46.5497 90.9697C47.1495 90.9697 48.3843 91.0327 49.2108 91.0327C50.0374 91.0327 50.1029 90.2162 49.7602 90.1406C48.2255 89.8105 47.6308 89.3519 47.1218 88.9159C47.0319 88.837 46.9162 88.7939 46.7967 88.7949Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M47.2544 84.5567L46.9293 87.0692L44.9688 86.8726L45.2863 84.4131L47.2544 84.5567Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M32.9326 76.2559L34.2178 77.783L32.6378 78.7985L31.3047 77.3017L32.9326 76.2559Z"
        fill="black"
      />
      <path
        d="M44.8594 43.4827L52.752 45.0123C56.4816 34.0327 55.5895 33.6723 55.5895 33.6723C55.0097 33.359 54.4114 33.0813 53.7978 32.8407L53.6895 32.8004C53.1855 32.6164 53.0796 32.5887 52.4043 32.3922C51.24 32.1175 50.1589 31.8882 48.8208 31.6992L48.5688 31.6639C48.3168 31.6336 48.085 31.6084 47.8531 31.5858C47.6969 31.5681 47.5407 31.558 47.392 31.548L46.888 31.5202C46.0261 31.4774 45.4087 31.49 45.4087 31.49C45.8422 38.3469 45.5121 40.3201 44.8594 43.4827Z"
        fill="#4160AC"
      />
      <path
        opacity="0.7"
        d="M44.8594 43.4827L52.752 45.0123C56.4816 34.0327 55.5895 33.6723 55.5895 33.6723C55.0097 33.359 54.4114 33.0813 53.7978 32.8407L53.6895 32.8004C53.1855 32.6164 53.0796 32.5887 52.4043 32.3922C51.24 32.1175 50.1589 31.8882 48.8208 31.6992L48.5688 31.6639C48.3168 31.6336 48.085 31.6084 47.8531 31.5858C47.6969 31.5681 47.5407 31.558 47.392 31.548L46.888 31.5202C46.0261 31.4774 45.4087 31.49 45.4087 31.49C45.8422 38.3469 45.5121 40.3201 44.8594 43.4827Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M54.4553 36.236L53.7422 36.1201C53.7422 37.0601 54.1 38.6729 54.4478 39.6985C54.7426 38.6905 54.9719 37.8614 55.1458 37.1634L54.4553 36.236Z"
        fill="black"
      />
      <path
        d="M55.3181 33.5393C56.4849 33.9123 57.3341 36.2206 57.6516 37.216L55.3836 39.8796C55.3836 39.8796 53.0602 36.5406 53.4306 35.5049C53.7935 34.4263 54.7536 33.3553 55.3181 33.5393Z"
        fill="#4160AC"
      />
      <path
        opacity="0.7"
        d="M55.3181 33.5393C56.4849 33.9123 57.3341 36.2206 57.6516 37.216L55.3836 39.8796C55.3836 39.8796 53.0602 36.5406 53.4306 35.5049C53.7935 34.4263 54.7536 33.3553 55.3181 33.5393Z"
        fill="white"
      />
      <path
        d="M56.0726 25.0167C55.8382 25.7047 54.8126 25.9794 53.792 25.6341C52.7714 25.2889 52.1262 24.4447 52.3606 23.7567C52.595 23.0688 53.6206 22.7941 54.6286 23.1418C55.6366 23.4896 56.3044 24.3288 56.0726 25.0167Z"
        fill="#263238"
      />
      <path
        d="M50.1559 24.8275C49.6318 26.7024 49.3697 27.4836 49.9695 28.7738C50.8716 30.7168 53.2858 31.0217 54.3593 29.356C55.3245 27.8591 56.02 25.0443 54.4777 23.6356C54.1523 23.321 53.7504 23.0968 53.3118 22.985C52.8732 22.8732 52.413 22.8776 51.9766 22.998C51.5403 23.1183 51.1428 23.3504 50.8236 23.6712C50.5043 23.9921 50.2742 24.3906 50.1559 24.8275Z"
        fill="#B55B52"
      />
      <path
        d="M53.082 22.6378C52.326 23.0712 50.7208 20.3572 48.7275 22.6378C47.3011 24.2733 48.2109 26.6698 48.2109 26.6698C48.2109 26.6698 47.0743 24.6714 46.1671 25.6467C44.9827 26.9067 46.2075 31.4149 50.1135 31.105C54.0195 30.795 53.6415 27.6249 53.6415 27.6249C53.6415 27.6249 55.471 25.7651 55.3803 24.0969C55.3122 22.8469 54.342 21.9246 53.082 22.6378Z"
        fill="#263238"
      />
      <path
        d="M53.5494 26.4381C53.452 26.787 53.4643 27.1574 53.5847 27.4991C53.746 27.9804 54.1164 27.9829 54.313 27.6503C54.5095 27.3176 54.6179 26.7406 54.3382 26.3525C54.0584 25.9644 53.6754 26.0526 53.5494 26.4381Z"
        fill="#B55B52"
      />
      <path
        d="M50.6563 44.6048C50.6563 44.6048 49.8146 47.523 48.7335 51.3735C48.0153 53.9389 47.1888 56.9175 46.4353 59.7223C46.0044 61.3275 45.5986 62.8748 45.2484 64.2583C43.2676 72.0829 33.3892 78.8088 33.3892 78.8088L31.4766 76.7046C31.4766 76.7046 38.1142 71.1152 40.0446 63.8148C43.943 48.9946 41.7506 46.4948 44.8578 43.4834L50.6563 44.6048Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M49.802 47.2205C49.5097 48.3519 49.1418 49.7808 48.7361 51.3785C48.0179 53.9438 47.1913 56.9225 46.4378 59.7272C46.1153 56.1488 45.9338 49.5691 48.5672 47.5229C49.0863 47.1247 49.4971 47.0415 49.802 47.2205Z"
        fill="black"
      />
      <path
        d="M33.553 79.111L31.1641 76.4826L31.8873 75.7568L34.8181 78.5188L33.553 79.111Z"
        fill="#4160AC"
      />
      <path
        opacity="0.7"
        d="M33.553 79.111L31.1641 76.4826L31.8873 75.7568L34.8181 78.5188L33.553 79.111Z"
        fill="white"
      />
      <path
        d="M31.3589 81.1066C31.3349 81.1061 31.3111 81.1018 31.2884 81.094C31.054 81.0083 30.9053 80.5598 30.8373 80.3002C30.8351 80.2914 30.8356 80.2821 30.8387 80.2736C30.8419 80.265 30.8475 80.2576 30.8549 80.2523C30.8622 80.247 30.8711 80.2441 30.8801 80.2441C30.8892 80.2441 30.898 80.247 30.9053 80.2523C30.9759 80.2927 31.5958 80.6555 31.6084 80.9101C31.6105 80.9378 31.605 80.9655 31.5926 80.9904C31.5802 81.0152 31.5613 81.0362 31.5378 81.0512C31.4863 81.0897 31.4232 81.1092 31.3589 81.1066ZM30.9582 80.3884C31.0616 80.7286 31.1926 80.9579 31.3211 81.0033C31.3486 81.0118 31.3778 81.0133 31.406 81.0075C31.4341 81.0018 31.4604 80.9891 31.4824 80.9705C31.5152 80.9479 31.5177 80.9252 31.5152 80.9101C31.5026 80.779 31.2027 80.5421 30.9582 80.3884Z"
        fill="#4160AC"
      />
      <path
        d="M52.6232 45.0127C52.6232 45.0127 53.1272 60.5914 52.0134 66.4605C50.8693 72.5765 47.3766 86.4365 47.3766 86.4365L44.5391 86.0711C44.5391 86.0711 46.3333 72.0448 47.0969 65.9817C47.9259 59.3692 46.8449 43.8838 46.8449 43.8838L52.6232 45.0127Z"
        fill="#263238"
      />
      <path
        d="M47.9899 86.6103L43.9453 86.0886L43.9932 85.126L48.3956 85.3175L47.9899 86.6103Z"
        fill="#4160AC"
      />
      <path
        opacity="0.7"
        d="M47.9899 86.6103L43.9453 86.0886L43.9932 85.126L48.3956 85.3175L47.9899 86.6103Z"
        fill="white"
      />
      <path
        d="M31.4559 80.4972C31.2465 80.4871 31.0422 80.4294 30.8586 80.3284C30.8517 80.3241 30.8461 80.3182 30.8421 80.3111C30.8381 80.3041 30.836 80.2961 30.8359 80.288C30.837 80.2799 30.8399 80.2721 30.8442 80.2652C30.8486 80.2582 30.8543 80.2523 30.8611 80.2477C30.8838 80.2477 31.3651 79.9831 31.6423 80.0864C31.6771 80.099 31.7086 80.1191 31.7348 80.1452C31.7609 80.1713 31.781 80.2029 31.7935 80.2376C31.8101 80.2712 31.8149 80.3093 31.8071 80.3459C31.7993 80.3825 31.7795 80.4153 31.7507 80.4392C31.6612 80.4897 31.5578 80.51 31.4559 80.4972ZM30.9846 80.2931C31.2039 80.3939 31.5869 80.4493 31.6927 80.3662C31.7104 80.351 31.728 80.3284 31.6927 80.2704C31.6853 80.2481 31.6728 80.2278 31.6562 80.2112C31.6396 80.1946 31.6193 80.1821 31.597 80.1746C31.3856 80.1509 31.1719 80.1922 30.9846 80.2931Z"
        fill="#4160AC"
      />
      <path
        d="M47.5229 89.138C47.3154 89.1336 47.1087 89.11 46.9055 89.0674C46.8959 89.0664 46.8869 89.062 46.88 89.0552C46.8731 89.0483 46.8688 89.0393 46.8677 89.0296C46.8662 89.02 46.8679 89.0102 46.8724 89.0015C46.8769 88.9929 46.8841 88.986 46.8929 88.9818C46.9836 88.9339 47.78 88.5332 48.0168 88.6668C48.0395 88.6792 48.0584 88.6974 48.0716 88.7196C48.0849 88.7417 48.0921 88.767 48.0924 88.7928C48.0974 88.8348 48.0916 88.8773 48.0758 88.9165C48.0599 88.9557 48.0344 88.9903 48.0017 89.017C47.8602 89.1098 47.6916 89.1524 47.5229 89.138ZM47.0618 89.0019C47.4952 89.075 47.8178 89.0548 47.9413 88.949C47.9625 88.9304 47.9788 88.9069 47.9889 88.8806C47.999 88.8542 48.0025 88.8258 47.9992 88.7978C47.9999 88.7876 47.9977 88.7775 47.9928 88.7685C47.9879 88.7596 47.9805 88.7522 47.9715 88.7474C47.843 88.6768 47.3894 88.8457 47.0618 89.0019Z"
        fill="#4160AC"
      />
      <path
        d="M46.9152 89.0701C46.9061 89.0726 46.8966 89.0726 46.8875 89.0701C46.8807 89.0653 46.8753 89.0589 46.8717 89.0514C46.8682 89.0438 46.8667 89.0355 46.8673 89.0273C46.8673 88.992 46.9656 88.1402 47.4419 88.1906C47.5679 88.2032 47.6057 88.2662 47.6133 88.3191C47.6485 88.5409 47.1723 88.9693 46.9329 89.0751L46.9152 89.0701ZM47.4041 88.2713C47.1219 88.2713 47.0085 88.7375 46.9732 88.9391C47.2252 88.7929 47.5427 88.4577 47.5225 88.3242C47.5225 88.3242 47.5225 88.2814 47.4318 88.2738L47.4041 88.2713Z"
        fill="#4160AC"
      />
      <path
        d="M44.8101 43.0022L44.3389 43.6347C44.3036 43.6851 44.359 43.7531 44.4497 43.7708L52.7279 45.3735C52.7985 45.3735 52.864 45.3735 52.8741 45.3206L53.0631 44.6301C53.0631 44.5847 53.0203 44.5293 52.9421 44.5142L44.9487 42.9669C44.9245 42.9604 44.8991 42.9603 44.8748 42.9664C44.8505 42.9726 44.8283 42.9849 44.8101 43.0022Z"
        fill="#4160AC"
      />
      <path
        d="M45.7394 44.101L45.5252 44.0582C45.4823 44.0582 45.4546 44.0229 45.4622 43.9977L45.7142 43.1031C45.7142 43.0779 45.7646 43.0628 45.8049 43.0703L46.0191 43.1132C46.0619 43.1132 46.0922 43.1484 46.0846 43.1736L45.8326 44.0682C45.8225 44.101 45.7822 44.101 45.7394 44.101Z"
        fill="#263238"
      />
      <path
        d="M51.1456 45.1563L50.9314 45.116C50.8886 45.116 50.8609 45.0782 50.8684 45.053L51.1204 44.1609C51.1204 44.1332 51.1683 44.1181 51.2111 44.1281L51.4253 44.1685C51.4657 44.1685 51.4959 44.2063 51.4883 44.2315L51.2363 45.1235C51.2288 45.1513 51.1885 45.1664 51.1456 45.1563Z"
        fill="#263238"
      />
    </svg>
  );
};

export default eVerification;
