import React from "react";

const tenantSvg = ({ wSize = "36", hSize = "36" }) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox={`0 0 ${wSize} ${hSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.623 17.4019L18.909 0.383887C18.4063 -0.127962 17.5937 -0.127962 17.091 0.383887L0.377029 17.4019C-0.125676 17.9138 -0.125676 18.7411 0.377029 19.253C0.879734 19.7648 1.69229 19.7648 2.195 19.253L18 3.16044L28.3035 13.6514C28.3022 13.6841 28.2855 13.7129 28.2855 13.7457V33.3818H7.71447V20.2911C7.71447 19.5671 7.13848 18.982 6.42878 18.982C5.71908 18.982 5.14309 19.5671 5.14309 20.2911V34.6909C5.14309 35.4148 5.71908 36 6.42878 36H29.5712C30.2822 36 30.8569 35.4148 30.8569 34.6909V16.2512L33.805 19.253C34.0557 19.5082 34.3848 19.6365 34.714 19.6365C35.0431 19.6365 35.3723 19.5082 35.623 19.253C36.1257 18.7411 36.1257 17.9138 35.623 17.4019Z"
        fill="#F67C36"
      />
      <path
        d="M22.3264 21.623C22.2769 21.5739 22.2182 21.535 22.1536 21.5085C22.0891 21.482 22.0199 21.4685 21.9501 21.4688C21.8804 21.469 21.8113 21.483 21.747 21.5099C21.6826 21.5368 21.6242 21.5762 21.575 21.6257C21.5258 21.6752 21.4869 21.7339 21.4604 21.7985C21.434 21.863 21.4205 21.9322 21.4207 22.0019C21.421 22.0717 21.4349 22.1408 21.4619 22.2051C21.4888 22.2695 21.5281 22.3279 21.5777 22.3771C22.027 22.8217 22.3837 23.351 22.627 23.9343C22.8704 24.5177 22.9956 25.1435 22.9955 25.7756C22.9955 26.4235 21.1316 27.3687 18.2163 27.3687C15.301 27.3687 13.4371 26.4235 13.4371 25.7756C13.4362 25.1477 13.5591 24.5258 13.7988 23.9454C14.0384 23.365 14.3901 22.8375 14.8337 22.393C14.9326 22.2935 14.9881 22.1589 14.9881 22.0187C14.9881 21.8784 14.9326 21.7438 14.8337 21.6443C14.7342 21.5454 14.5996 21.4899 14.4593 21.4899C14.319 21.4899 14.1844 21.5454 14.0849 21.6443C13.5407 22.1855 13.1093 22.8293 12.8158 23.5385C12.5223 24.2477 12.3724 25.0081 12.375 25.7756C12.375 27.5015 15.3859 28.4308 18.2163 28.4308C21.0466 28.4308 24.0575 27.5015 24.0575 25.7756C24.0596 25.0031 23.9075 24.238 23.6103 23.525C23.313 22.8119 22.8765 22.1653 22.3264 21.623Z"
        fill="#F67C36"
        stroke="#F67C36"
        stroke-width="0.8"
      />
      <path
        d="M18.2191 22.0593C18.9543 22.0593 19.673 21.8413 20.2843 21.4329C20.8955 21.0244 21.372 20.4439 21.6533 19.7647C21.9347 19.0854 22.0083 18.338 21.8648 17.617C21.7214 16.8959 21.3674 16.2336 20.8475 15.7137C20.3277 15.1939 19.6654 14.8399 18.9443 14.6964C18.2232 14.553 17.4758 14.6266 16.7966 14.908C16.1174 15.1893 15.5369 15.6657 15.1284 16.277C14.72 16.8883 14.502 17.607 14.502 18.3422C14.502 19.328 14.8936 20.2735 15.5907 20.9706C16.2878 21.6677 17.2333 22.0593 18.2191 22.0593ZM18.2191 15.687C18.7442 15.687 19.2576 15.8428 19.6942 16.1345C20.1308 16.4263 20.4712 16.8409 20.6721 17.3261C20.8731 17.8113 20.9257 18.3451 20.8232 18.8601C20.7208 19.3752 20.4679 19.8483 20.0966 20.2196C19.7252 20.5909 19.2521 20.8438 18.7371 20.9463C18.2221 21.0487 17.6882 20.9961 17.203 20.7952C16.7179 20.5942 16.3032 20.2539 16.0115 19.8173C15.7197 19.3806 15.564 18.8673 15.564 18.3422C15.564 17.638 15.8437 16.9626 16.3417 16.4647C16.8396 15.9668 17.5149 15.687 18.2191 15.687Z"
        fill="#F67C36"
        stroke="#F67C36"
        stroke-width="0.8"
      />
    </svg>
  );
};

export default tenantSvg;
