import { Button, Col, Divider, Form, Input, message, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { LessSvg, PlusSvg } from "../../assets/svgs";
import { application, me } from "../../store/reducers/user.reducer";
import { StyledButton } from "../property-details/PropertyDetails";
import {
  FormWrap,
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "./BackgroundCheck";
import data from "./state.json";

function PriorRentalInformation({ onSubmit = console.log() }) {
  const { Option } = Select;
  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.user);

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const [showinformation, setShowinformation] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [stateValue, setstateValue] = useState("");
  const [citesValue, setCitesValue] = useState([]);
  const [isAdd, setIsAdd] = useState(user?.priorRentals?.length);
  const [openIndexs, setOpenIndexs] = useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  ]);
  const [openIndex, setOpenIndex] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpenIndex(null);
  }, []);

  const openIndexFun = (idx) => {
    setOpenIndex(idx);
    // if (user?.priorRentals) {
    //   form.setFieldsValue({
    //     nameOfComplex: user?.priorRentals[idx].nameOfComplex,
    //     phoneNumberofComplex: user?.priorRentals[idx].phoneNumberofComplex,
    //     addressLine1: user?.priorRentals[idx].addressLine1,
    //     addressLine2: user?.priorRentals[idx].addressLine2,
    //     state: user?.priorRentals[idx].state,
    //     city: user?.priorRentals[idx].city,
    //     zipcode: user?.priorRentals[idx].zipcode,
    //   });
    // }
    if (openIndexs?.includes(idx)) {
      var filteredArray = openIndexs?.filter((e) => {
        return e !== idx;
      });
      setOpenIndexs(filteredArray);
    } else {
      setOpenIndexs((val) => [...val, idx]);
    }
  };

  useEffect(() => {
    setCitesValue(data[stateValue]);
  }, [stateValue]);

  const onChange = (newValue) => {
    setstateValue(newValue);
    form.setFieldsValue({ city: [] });
  };

  // new functions
  const [lenOfCharge, setLenOfCharge] = useState(1);
  const [newSetalFields, setNewSetalFields] = useState([
    {
      nameOfComplex: null,
      phoneNumberofComplex: null,
      addressLine1: null,
      addressLine2: null,
      state: null,
      city: null,
      zipcode: null,
    },
  ]);

  const addChanges = () => {
    newSetalFields.push({
      nameOfComplex: null,
      phoneNumberofComplex: null,
      addressLine1: null,
      addressLine2: null,
      state: null,
      city: null,
      zipcode: null,
    });
    setLenOfCharge(lenOfCharge + 1);
  };

  const onNameOfComplexChange = (value, type, id) => {
    let changedObj = newSetalFields[id];
    changedObj[type] = value;
    newSetalFields[id] = changedObj;
    setNewSetalFields(newSetalFields);
    setNewSetalFields((val) => [...val]);
  };

  const removeIdx = (id) => {
    let arr = newSetalFields.filter((item, idx) => idx !== id);
    setNewSetalFields(arr);
    setNewSetalFields((val) => [...val]);
  };

  useEffect(() => {
    if (user?.priorRentals) {
      const arr = [];
      user?.priorRentals.forEach((val) => {
        arr.push({
          nameOfComplex: val.nameOfComplex,
          phoneNumberofComplex: val.phoneNumberofComplex,
          addressLine1: val.addressLine1,
          addressLine2: val.addressLine2,
          state: val.state,
          city: val.city,
          zipcode: val.zipcode,
        });
      });
      setNewSetalFields(arr);
    }
  }, [user]);

  const onFinish = (value) => {
    const values = {};
    values.user_id = user?.id;
    values.priorRentals = newSetalFields;
    setIsSubmit(true);
    dispatch(application(values)).then((res) => {
      if (newSetalFields?.length >= 1) {
        message.success("Prior rental information added successfully");
      } else {
        message.success("Prior rental informations added successfully");
      }
      dispatch(me());
      setIsAdd(!isAdd);
      setIsSubmit(false);
      // onSubmit();
    });
  };

  return (
    <>
      <FormWrap>
        {showinformation === null ? (
          <PriorRentalInformationMain>
            <PriorRentalInformationWrap>
              <Row justify="center" style={{ textAlign: "center" }}>
                <Col xs={8} sm={6} md={24} lg={24} xl={24} xxl={24}>
                  <h2>Have you rented an apartment before?</h2>
                </Col>
                <Col xs={8} sm={6} md={6} lg={6} xl={4} xxl={4}>
                  <StyledButton onClick={() => setShowinformation("addrental")}>
                    Yes
                  </StyledButton>
                </Col>
                <Col
                  className="first__time"
                  xs={8}
                  sm={6}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <span
                    className="first__time"
                    onClick={() => setShowinformation("firsttime")}
                  >
                    No, I’m first time renter.
                  </span>
                </Col>
              </Row>
            </PriorRentalInformationWrap>
          </PriorRentalInformationMain>
        ) : showinformation === "addrental" ? (
          <InformationWrap>
            <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
              <StyledForms>
                <Form
                  layout="vertical"
                  name="dynamic_form_nest_item"
                  onFinish={onFinish}
                  autoComplete="off"
                  form={form}
                >
                  <Form.Item>
                    <Row justify="space-between">
                      <Col>
                        <h5>
                          Please enter the last 5 year of rental information.
                        </h5>
                      </Col>
                      <Col>
                        <Row
                          className="icon__text"
                          block
                          // onClick={() => setIsAdd(!isAdd)}
                          onClick={() => addChanges()}
                        >
                          <Col>
                            <PlusSvg />
                          </Col>
                          <Col>
                            <p>Add Rental</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Item>
                  {newSetalFields?.map((item, idx) => (
                    <>
                      <ViewAllRental key={idx}>
                        <Row
                          style={{ alignItems: "center" }}
                          onClick={() => openIndexFun(idx)}
                        >
                          <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                            <h6>Rental {idx + 1}</h6>
                          </Col>
                          <Col xs={20} sm={20} md={20} lg={20} xl={19} xxl={19}>
                            <Divider />
                          </Col>
                          <Col
                            style={{
                              textAlign: "end",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            xs={2}
                            sm={2}
                            md={2}
                            lg={2}
                            xl={3}
                            xxl={3}
                          >
                            {openIndexs?.includes(idx) ? (
                              <>
                                <DeleteBtn
                                  onClick={(e) => {
                                    e.preventDefault();
                                    removeIdx(idx);
                                  }}
                                >
                                  <span>Delete</span>
                                </DeleteBtn>

                                <LessSvg
                                  style={{ marginBottom: "5px" }}
                                  className="less__svg"
                                />
                              </>
                            ) : (
                              <>
                                <DeleteBtn
                                  onClick={(e) => {
                                    e.preventDefault();
                                    removeIdx(idx);
                                  }}
                                >
                                  <span>Delete</span>
                                </DeleteBtn>
                                <PlusSvg />
                              </>
                            )}
                          </Col>
                        </Row>
                      </ViewAllRental>
                      {openIndexs?.includes(idx) ? (
                        <>
                          <StyledFormItem
                            key={idx}
                            wh="49"
                            fd={"column"}
                            smwh={"100"}
                          >
                            <Form.Item
                              // name="nameOfComplex"
                              label="Name of complex"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Input
                                defaultValue={item.nameOfComplex}
                                onChange={(e) => {
                                  onNameOfComplexChange(
                                    e.target.value,
                                    "nameOfComplex",
                                    idx
                                  );
                                }}
                                placeholder="Name of complex"
                              />
                            </Form.Item>
                            <Form.Item
                              // name="phoneNumberofComplex"
                              label="Phone Number of Complex"
                              //   rules={[
                              //     {
                              //       required: true,
                              //     },
                              //   ]}
                            >
                              <Input
                                defaultValue={item.phoneNumberofComplex}
                                onChange={(e) => {
                                  onNameOfComplexChange(
                                    e.target.value,
                                    "phoneNumberofComplex",
                                    idx
                                  );
                                }}
                                placeholder="Phone Number of Complex"
                              />
                            </Form.Item>
                          </StyledFormItem>
                          <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                            <Form.Item
                              // name="addressLine1"
                              label="Address"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Input
                                onChange={(e) => {
                                  onNameOfComplexChange(
                                    e.target.value,
                                    "addressLine1",
                                    idx
                                  );
                                }}
                                defaultValue={item.addressLine1}
                                placeholder="Address Line 1"
                              />
                            </Form.Item>
                            <Form.Item
                              // name="addressLine2"
                              label=" "
                              //   rules={[
                              //     {
                              //       required: true,
                              //     },
                              //   ]}
                            >
                              <Input
                                onChange={(e) => {
                                  onNameOfComplexChange(
                                    e.target.value,
                                    "addressLine2",
                                    idx
                                  );
                                }}
                                defaultValue={item.addressLine2}
                                placeholder="Address Line 2"
                              />
                            </Form.Item>
                          </StyledFormItem>
                          <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
                            <Form.Item
                              // name="state"
                              label="State"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="State"
                                onChange={(value) => {
                                  onChange(value);
                                  onNameOfComplexChange(value, "state", idx);
                                }}
                                suffixIcon={<IoIosArrowDown />}
                                defaultValue={item.state}
                              >
                                {states.length &&
                                  states.map((state) => (
                                    <Option value={state}>{state}</Option>
                                  ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              // name="city"
                              label="City"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Select
                                onChange={(value) => {
                                  onNameOfComplexChange(value, "city", idx);
                                }}
                                showSearch
                                placeholder="City"
                                // onChange={}
                                suffixIcon={<IoIosArrowDown />}
                                defaultValue={item.city}
                              >
                                {citesValue
                                  ? citesValue.map((cites) => (
                                      <Option value={cites}>{cites}</Option>
                                    ))
                                  : ""}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              // name="zipcode"
                              label="Zip code"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Input
                                defaultValue={item.zipcode}
                                onChange={(e) => {
                                  onNameOfComplexChange(
                                    e.target.value,
                                    "zipcode",
                                    idx
                                  );
                                }}
                                type="number"
                                placeholder="Zip code"
                                pattern="[0-9]*"
                              />
                            </Form.Item>
                          </StyledFormItem>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                  <Form.Item>
                    <Row justify="end" style={{ alignItems: "center" }}>
                      {/* <Col>
                        <p
                          className="cancel__btn"
                          onClick={() => setShowinformation(null)}
                        >
                          Cancel
                        </p>
                      </Col> */}
                      <Col xs={8} sm={6} md={6} lg={6} xl={4} xxl={4}>
                        <StyledButton
                          disabled={!newSetalFields?.length}
                          loading={isSubmit}
                          htmlType="submit"
                        >
                          Submit
                        </StyledButton>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </StyledForms>
            </StyledForm>
          </InformationWrap>
        ) : (
          <PriorRentalInformationMain>
            <PriorRentalInformationWrap>
              <Row justify="center" style={{ textAlign: "center" }}>
                <Col xs={8} sm={6} md={24} lg={15} xl={15} xxl={15}>
                  <h2>
                    if you’re a first time renter, then you’re all set! This
                    step is complete!
                  </h2>
                </Col>
              </Row>
              <Row justify="center" style={{ textAlign: "center" }}>
                <Col xs={8} sm={6} md={6} lg={10} xl={10} xxl={10}>
                  <StyledButton onClick={() => setShowinformation("addrental")}>
                    Awesome complete this step
                  </StyledButton>
                </Col>
                <Col
                  className="first__time"
                  xs={8}
                  sm={6}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <span className="years__rental">
                    if you aren’t, then please enter the last 5 years of rental
                    information.
                  </span>
                </Col>
                <Col
                  className="first__time"
                  xs={8}
                  sm={6}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <span
                    className="info__enter"
                    onClick={() => setShowinformation("firsttime")}
                  >
                    Actually, I have info to enter.
                  </span>
                </Col>
              </Row>
            </PriorRentalInformationWrap>
          </PriorRentalInformationMain>
        )}
      </FormWrap>
    </>
  );
}

export default PriorRentalInformation;

export const DeleteBtn = styled.div`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 3px;
  justify-content: center;
  width: ${({ widthBtn }) => widthBtn || "55px"};
  display: flex;
  cursor: pointer;
  padding: ${({ paddingBtn }) => paddingBtn || "0"};

  p {
    margin-bottom: 0;
    margin-right: 10px;
  }
  svg {
    margin-bottom: -5px;
  }
  h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.05em;
    color: #848687;
    margin-bottom: 0;
  }
`;

export const ViewAllRental = styled.div`
  width: 100%;
  margin-bottom: 25px;
  margin-bottom: ${({ mb }) => mb || "25px"};

  .less__svg {
    margin-bottom: 5px;
  }

  .ant-divider-horizontal {
    margin: 0;
  }
  h6 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #2b353f;
    margin-bottom: 0;
  }
`;

export const InformationWrap = styled.div`
  h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #777777;
  }
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #4160ac;
    margin-bottom: 0;
    margin-left: 10px;
    text-transform: uppercase;
  }
  .icon__text,
  svg {
    cursor: pointer;
  }

  .less__svg {
    margin-bottom: 5px;
  }
`;

export const PriorRentalInformationWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #2b353f;
    margin-bottom: 25px;
  }
  .first__time {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #848687;
    cursor: pointer;
    padding-top: 25px;
  }
  .years__rental {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #848687;
  }
  .info__enter {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #f67c36;
  }
`;
export const PriorRentalInformationMain = styled.div`
  width: 100%;
  position: relative;
  min-height: 558px;
`;
