import { Modal } from "antd";
import React from "react";
import { IoIosClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SelectHousePana } from "../../assets/svgs";
import { StyledButtonApprove } from "../invitationModal/InvitationModal";

function ApplyingToProperty({ modalState, onCancel, unitDetails }) {
  const navigate = useNavigate();

  const letsVerify = () => {
    navigate("/application-process");
    sessionStorage.setItem("applyingProperty", JSON.stringify(unitDetails));
  };

  return (
    <Modal
      getContainer={() => document.querySelector("#modal-container")}
      visible={modalState}
      onCancel={() => {
        onCancel(false);
      }}
      width={"800px"}
      footer={null}
      style={{ borderRadius: "5px", top: "50px" }}
      className="modal_wrapper"
      closeIcon={<IoIosClose className="modal_close_icon" />}
      maskStyle={{
        background: "rgba(0, 0, 0, 0.3)",
        backdropFilter: "blur(20px)",
      }}
      focusTriggerAfterClose={false}
      destroyOnClose={true}
    >
      <ApplyingToPropertyWrap>
        <SelectHousePana />
        <h1>Applying to the property?</h1>
        <p>
          Your Application Process still has to be finished. Please finish the
          one-time Application Process before applying for properties to make
          the process as simple as possible.
        </p>
        <StyledButtonApprove onClick={() => letsVerify()}>
          Let’s Verifiy
        </StyledButtonApprove>
        <h2 onClick={() => onCancel(false)}>Cancel</h2>
      </ApplyingToPropertyWrap>
    </Modal>
  );
}

export default ApplyingToProperty;

const ApplyingToPropertyWrap = styled.div`
  text-align: center;
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    color: #2b353f;
    margin-bottom: 30px;
  }
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    width: 70%;
    margin: auto;
  }
  h2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #777777;
    margin-bottom: 0;
    margin-top: 30px;
    cursor: pointer;
  }

  @media (max-width: 1550px) {
    h1 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 24px;
    }
    p {
      font-size: 16px;
      line-height: 18px;
    }
    h2 {
      margin-top: 24px;
    }
  }
`;
