import React from "react";

const fileStorageSvg = ({ wSize = "38", hSize = "38" }) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox={`0 0 ${wSize} ${hSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.5271 20.5354L24.5896 14.5979C24.3669 14.3752 24.0649 14.2501 23.75 14.25H16.625C15.9951 14.25 15.391 14.5002 14.9456 14.9456C14.5002 15.391 14.25 15.9951 14.25 16.625V33.25C14.25 33.8799 14.5002 34.484 14.9456 34.9294C15.391 35.3748 15.9951 35.625 16.625 35.625H28.5C29.1299 35.625 29.734 35.3748 30.1794 34.9294C30.6248 34.484 30.875 33.8799 30.875 33.25V21.375C30.8749 21.0601 30.7498 20.7581 30.5271 20.5354ZM28.008 21.375H23.75V17.1167L28.008 21.375ZM16.625 33.25V16.625H21.375V21.375C21.375 22.0049 21.6252 22.609 22.0706 23.0544C22.516 23.4998 23.1201 23.75 23.75 23.75H28.5V33.25H16.625Z"
        fill="#F67C36"
      />
      <path
        d="M9.5 32.0625H4.75C4.12033 32.0618 3.51666 31.8113 3.07142 31.3661C2.62618 30.9208 2.37572 30.3172 2.375 29.6875V5.93751C2.37572 5.30784 2.62618 4.70417 3.07142 4.25893C3.51666 3.81369 4.12033 3.56323 4.75 3.56251H13.758C14.0701 3.56163 14.3792 3.62268 14.6675 3.74211C14.9558 3.86154 15.2175 4.03698 15.4375 4.25827L19.492 8.31251H33.25C33.8797 8.31323 34.4833 8.56369 34.9286 9.00893C35.3738 9.45417 35.6243 10.0578 35.625 10.6875V20.1875H33.25V10.6875H18.508L13.758 5.93751H4.75V29.6875H9.5V32.0625Z"
        fill="#F67C36"
      />
    </svg>
  );
};

export default fileStorageSvg;
