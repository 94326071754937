import { Col, Form, Row, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import styled from "styled-components";
import AutomaticVerificationModal from "./AutomaticVerificationModal";
import {
  FormWrap,
  StyledForm,
  StyledForms,
  StyledFormSelect,
} from "./BackgroundCheck";
import {
  usePlaidLink,
  PlaidLinkError,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
  PlaidLink,
  PlaidLinkStableEvent,
} from "react-plaid-link";

function ProofOfIncome() {
  const [form] = Form.useForm();
  const { Option } = Select;

  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);

  const onFinish = (value) => {
    // console.log(value, "value");
  };

  // const config: PlaidLinkOptions = {
  //   onSuccess: (public_token, metadata) => {}
  //   onExit: (err, metadata) => {}
  //   onEvent: (eventName, metadata) => {}
  //   token: 'GENERATED_LINK_TOKEN',
  //   //required for OAuth; if not using OAuth, set to null or omit:
  //   receivedRedirectUri: window.location.href,
  // };

  const onSuccess = (val) => {
    console.log(val, "val");
  };

  // const config = {
  //   clientName: "Quick Start",
  //   env: "sandbox",
  //   product: ["auth", "transactions"],
  //   publicKey: "public-sandbox-b0e2c4ee-a763-4df5-bfe9-46a46bce993d",

  //   onEvent: (eventName, metadata) => {
  //     console.log(eventName, ">>> exit");

  //   },
  //   onExit: (exiPlaidLinkErrort) => {
  //     console.log(PlaidLinkError, ">>> exit");
  //     console.log(exiPlaidLinkErrort, ">>> exit exiPlaidLinkErrort");
  //   },
  // };
  
  // const plaidClient = new LinkTokenCreateRequest.Client({
  //   clientID: "63c634c30051bf0012508ca7",
  //   secret: "51e92cb63f4baaa39762d021417289",
  //   env: "sandbox",
  //   options: {
  //     version: "2019-05-29",
  //   },
  // });

  // const request = () => ({
  //   user: {
  //     client_user_id: "63c634c30051bf0012508ca7",
  //   },
  //   client_name: "Plaid Test App",
  //   products: ["auth", "transactions"],
  //   country_codes: ["US"],
  //   language: "en",
  //   webhook: "https://sample-web-hook.com",
  //   redirect_uri: "https://domainname.com/oauth-page.html",
  //   account_filters: {
  //     depository: {
  //       account_subtypes: [
  //         "DepositoryAccountSubtype.Checking, DepositoryAccountSubtype.Savings",
  //       ],
  //     },
  //   },
  // });
  // useEffect(() => {
  //   console.log(request());
  //   console.log(plaidClient.linkTokenCreate(request));
    
  // }, []);

  const { open, ready } = usePlaidLink({
    clientName: "Plaid Quickstart",
    key: "63c634c30051bf0012508ca7",
    product: ["auth"],
    env: "sandbox",
    token: "link-sandbox-af1a0311-da53-4636-b754-dd15cc058176",
    onSuccess: (public_token, metadata) => {
      // console.log(public_token, metadata, "public_token, metadata");
      // send public_token to server
    },
    event_type: "posted",
    onEvent: (eventName, metadata) => {
      // console.log(eventName, ">>> onEvent");
    },
    onExit: (exiPlaidLinkErrort) => {
      // console.log(PlaidLinkError, ">>> onExit");
      // console.log(exiPlaidLinkErrort, ">>> onExit exiPlaidLinkErrort");
    },
  });

  return (
    <FormWrap>
      <ProofOfIncomeWrap>
        <h2>Login into your bank</h2>
        <Row>
          <Col>
            <p>For instant and secured </p>
          </Col>
          <Col>
            <p
              className="income__verification"
              onClick={() => setModalState(true)}
            >
              &nbsp; automatic income verification.
            </p>
          </Col>
        </Row>
        <button onClick={() => open()} disabled={!ready}>
          Connect a bank account
        </button>
        {/* <PlaidLink
          token={"link-sandbox-af1a0311-da53-4636-b754-dd15cc058176"}
          onSuccess={onSuccess}
          // onEvent={...}
          // onExit={...}
        >
          Link your bank account
        </PlaidLink> */}

        <Row>
          <Col span={24}>
            <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
              <StyledForms>
                <Form
                  layout="vertical"
                  // requiredMark={"optional"}
                  form={form}
                  onFinish={onFinish}
                >
                  <StyledFormSelect wh="100">
                    <Form.Item name="searchBankName" label="Search Bank Name">
                      <Select
                        placeholder="Ex Chase"
                        suffixIcon={<IoIosArrowDown />}
                      >
                        <Option value="JPMorgan-Chase">JPMorgan-Chase</Option>
                        <Option value="Bank of America ">
                          Bank of America
                        </Option>
                        <Option value="Wells Fargo">Wells Fargo</Option>
                      </Select>
                    </Form.Item>
                  </StyledFormSelect>
                </Form>
              </StyledForms>
            </StyledForm>
          </Col>
        </Row>
      </ProofOfIncomeWrap>
      <AutomaticVerificationModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
      />
    </FormWrap>
  );
}

export default ProofOfIncome;

export const ProofOfIncomeWrap = styled.div`
  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #2b353f;
    margin-bottom: 20px;
  }
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #2b353f;
    margin-bottom: 45px;
  }
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2b353f;
  }
  .income__verification {
    color: #4160ac;
    cursor: pointer;
  }
`;
