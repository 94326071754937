import React from "react";

const payCash = ({
  wSize = "45",
  hSize = "38",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox={`0 0 45 38`}
      {...props}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path d="M45 5.625H0V31.875H45V5.625Z" fill="#27AE60" />
        <path d="M45 3.75H0V30H45V3.75Z" fill="#2ECC71" />
        <path d="M45 1.875H0V28.125H45V1.875Z" fill="#27AE60" />
        <path d="M45 0H0V26.25H45V0Z" fill="#2ECC71" />
        <path
          d="M9.375 1.875C9.37519 1.875 9.375 1.875 9.375 1.875C9.375 6 6.01706 9.375 1.875 9.375V16.6875C6.01706 16.6875 9.375 20.0625 9.375 24.375H35.625V24.1875C35.625 20.0625 38.9831 16.6875 43.125 16.6875V9.375C38.9831 9.375 35.625 6 35.625 1.875H9.375Z"
          fill="#ECF0F1"
        />
        <path
          d="M30 13.125C30 18.3019 26.6419 22.5 22.5 22.5C18.3579 22.5 15 18.3019 15 13.125C15 7.94737 18.3579 3.75 22.5 3.75C26.6419 3.75 30 7.94737 30 13.125Z"
          fill="#2ECC71"
        />
        <path
          d="M18.7493 8.81032L23.3842 7.55273H23.8477V16.7755C23.8477 17.4043 23.8898 17.8234 23.9739 18.033C24.0677 18.2427 24.2547 18.2426 24.5359 18.4522H26.2491V18.8715H19.0862V18.4522H20.8278C21.0899 18.2426 21.2726 18.2427 21.3758 18.033C21.4786 17.8234 21.53 17.4043 21.53 16.7755V10.9064C21.53 10.068 21.4833 9.43929 21.3898 9.22969C21.324 9.02008 21.2025 9.01993 21.0245 8.81032H20.4064C20.0602 8.81032 19.578 8.81027 18.96 9.01988L18.7493 8.81032Z"
          fill="#ECF0F1"
        />
        <path
          d="M7.50019 31.8291C5.85958 31.8291 4.42147 32.208 3.39773 32.7666H1.87524V33.2939V33.5869V33.7041V33.8213V34.6417C1.87524 36.1951 4.39334 37.4542 7.50019 37.4542C10.607 37.4542 13.1251 36.1951 13.1251 34.6417V33.8213V33.7041V33.5869V33.2939V32.7666H11.6026C10.5789 32.208 9.1408 31.8291 7.50019 31.8291Z"
          fill="#F39C12"
        />
        <path
          d="M13.125 32.7442C13.125 34.2975 10.6069 35.5567 7.50007 35.5567C4.39322 35.5567 1.87512 34.2975 1.87512 32.7442C1.87512 31.1909 4.39322 29.9316 7.50007 29.9316C10.6069 29.9316 13.125 31.1909 13.125 32.7442Z"
          fill="#F1C40F"
        />
        <path
          d="M7.50031 28.0791C5.8597 28.0791 4.42159 28.458 3.39785 29.0166H1.87537V29.5439V29.8369V29.9541V30.0713V30.8917C1.87537 32.4451 4.39347 33.7042 7.50031 33.7042C10.6072 33.7042 13.1253 32.4451 13.1253 30.8917V30.0713V29.9541V29.8369V29.5439V29.0166H11.6028C10.579 28.458 9.14092 28.0791 7.50031 28.0791Z"
          fill="#F39C12"
        />
        <path
          d="M13.125 28.9942C13.125 30.5475 10.6069 31.8067 7.50007 31.8067C4.39322 31.8067 1.87512 30.5475 1.87512 28.9942C1.87512 27.4409 4.39322 26.1816 7.50007 26.1816C10.6069 26.1816 13.125 27.4409 13.125 28.9942Z"
          fill="#F1C40F"
        />
        <path
          d="M7.50019 24.3281C5.85958 24.3281 4.42147 24.7071 3.39773 25.2656H1.87524V25.7929V26.086V26.2032V26.3203V27.1407C1.87524 28.6941 4.39334 29.9532 7.50019 29.9532C10.607 29.9532 13.1251 28.6941 13.1251 27.1407V26.3203V26.2032V26.086V25.7929V25.2656H11.6026C10.5789 24.7071 9.1408 24.3281 7.50019 24.3281Z"
          fill="#F39C12"
        />
        <path
          d="M13.125 25.2432C13.125 26.7965 10.6069 28.0558 7.50007 28.0558C4.39322 28.0558 1.87512 26.7965 1.87512 25.2432C1.87512 23.6899 4.39322 22.4307 7.50007 22.4307C10.6069 22.4307 13.125 23.6899 13.125 25.2432Z"
          fill="#F1C40F"
        />
        <path
          d="M7.50019 20.5791C5.85958 20.5791 4.42147 20.958 3.39773 21.5166H1.87524V22.0439V22.3369V22.4541V22.5713V23.3917C1.87524 24.9451 4.39334 26.2042 7.50019 26.2042C10.607 26.2042 13.1251 24.9451 13.1251 23.3917V22.5713V22.4541V22.3369V22.0439V21.5166H11.6026C10.5789 20.958 9.1408 20.5791 7.50019 20.5791Z"
          fill="#F39C12"
        />
        <path
          d="M13.125 21.4942C13.125 23.0475 10.6069 24.3067 7.50007 24.3067C4.39322 24.3067 1.87512 23.0475 1.87512 21.4942C1.87512 19.9409 4.39322 18.6816 7.50007 18.6816C10.6069 18.6816 13.125 19.9409 13.125 21.4942Z"
          fill="#F1C40F"
        />
        <path
          d="M20.6241 31.8291C18.9835 31.8291 17.5454 32.208 16.5216 32.7666H14.9991V33.2939V33.5869V33.7041V33.8213V34.6417C14.9991 36.1951 17.5172 37.4542 20.6241 37.4542C23.7309 37.4542 26.249 36.1951 26.249 34.6417V33.8213V33.7041V33.5869V33.2939V32.7666H24.7265C23.7028 32.208 22.2647 31.8291 20.6241 31.8291Z"
          fill="#F39C12"
        />
        <path
          d="M26.2488 32.7442C26.2488 34.2975 23.7307 35.5567 20.6238 35.5567C17.517 35.5567 14.9989 34.2975 14.9989 32.7442C14.9989 31.1909 17.517 29.9316 20.6238 29.9316C23.7307 29.9316 26.2488 31.1909 26.2488 32.7442Z"
          fill="#F1C40F"
        />
        <path
          d="M24.3741 29.9531C22.7335 29.9531 21.2954 30.3321 20.2716 30.8906H18.7491V31.4179V31.711V31.8282V31.9453V32.7657C18.7491 34.3191 21.2672 35.5782 24.3741 35.5782C27.4809 35.5782 29.999 34.3191 29.999 32.7657V31.9453V31.8282V31.711V31.4179V30.8906H28.4765C27.4528 30.3321 26.0147 29.9531 24.3741 29.9531Z"
          fill="#F39C12"
        />
        <path
          d="M29.9988 30.8682C29.9988 32.4215 27.4807 33.6808 24.3738 33.6808C21.267 33.6808 18.7489 32.4215 18.7489 30.8682C18.7489 29.3149 21.267 28.0557 24.3738 28.0557C27.4807 28.0557 29.9988 29.3149 29.9988 30.8682Z"
          fill="#F1C40F"
        />
        <path
          d="M7.50055 16.8291C5.85995 16.8291 4.42184 17.208 3.3981 17.7666H1.87561V18.2939V18.5869V18.7041V18.8213V19.6417C1.87561 21.1951 4.39371 22.4542 7.50055 22.4542C10.6074 22.4542 13.1255 21.1951 13.1255 19.6417V18.8213V18.7041V18.5869V18.2939V17.7666H11.603C10.5793 17.208 9.14116 16.8291 7.50055 16.8291Z"
          fill="#F39C12"
        />
        <path
          d="M13.1253 17.7442C13.1253 19.2975 10.6072 20.5567 7.50031 20.5567C4.39347 20.5567 1.87537 19.2975 1.87537 17.7442C1.87537 16.1909 4.39347 14.9316 7.50031 14.9316C10.6072 14.9316 13.1253 16.1909 13.1253 17.7442Z"
          fill="#F1C40F"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={wSize} height={hSize} fill={bgColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default payCash;
