import React from "react";

const MsgSvg = ({
  wSize = "22",
  hSize = "17",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8889 6.07143C15.8889 2.71696 12.333 0 7.94444 0C3.5559 0 0 2.71696 0 6.07143C0 7.37299 0.538542 8.5721 1.45139 9.5625C0.939583 10.7085 0.0954861 11.6192 0.0840278 11.6306C0 11.7179 -0.0229167 11.8469 0.0267361 11.9607C0.0763889 12.0746 0.183333 12.1429 0.305556 12.1429C1.70347 12.1429 2.86076 11.6761 3.6934 11.1942C4.92326 11.79 6.37847 12.1429 7.94444 12.1429C12.333 12.1429 15.8889 9.42589 15.8889 6.07143ZM20.5486 14.4196C21.4615 13.433 22 12.2301 22 10.9286C22 8.38996 19.9566 6.21562 17.0615 5.30871C17.0958 5.55915 17.1111 5.81339 17.1111 6.07143C17.1111 10.09 12.9976 13.3571 7.94444 13.3571C7.53194 13.3571 7.1309 13.3268 6.73368 13.285C7.93681 15.467 10.7632 17 14.0556 17C15.6215 17 17.0767 16.6509 18.3066 16.0513C19.1392 16.5333 20.2965 17 21.6944 17C21.8167 17 21.9274 16.9279 21.9733 16.8179C22.0229 16.7078 22 16.5788 21.916 16.4877C21.9045 16.4763 21.0604 15.5694 20.5486 14.4196Z"
        fill="white"
      />
    </svg>
  );
};

export default MsgSvg;
