import React from "react";

const googleSvg = ({
  wSize = "28",
  hSize = "28",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox={`0 0 30 30`}
      {...props}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.03423 13.9999C6.03423 13.0905 6.18517 12.2186 6.45489 11.4009L1.73645 7.79785C0.816828 9.66488 0.298828 11.7688 0.298828 13.9999C0.298828 16.2291 0.816391 18.3315 1.73448 20.1975L6.4503 16.5874C6.1832 15.7735 6.03423 14.9048 6.03423 13.9999Z"
          fill="#FBBC05"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3187 5.72731C16.2943 5.72731 18.0786 6.42731 19.4806 7.57269L23.5589 3.5C21.0737 1.33634 17.8874 0 14.3187 0C8.77823 0 4.01648 3.16837 1.73645 7.798L6.45467 11.401C7.54186 8.10097 10.6409 5.72731 14.3187 5.72731Z"
          fill="#EA4335"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3187 22.2728C10.6411 22.2728 7.54208 19.8992 6.45489 16.5991L1.73645 20.2015C4.01648 24.8318 8.77823 28.0002 14.3187 28.0002C17.7382 28.0002 21.0031 26.7859 23.4533 24.5109L18.9746 21.0485C17.7109 21.8445 16.1195 22.2728 14.3187 22.2728Z"
          fill="#34A853"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.7012 14C27.7012 13.1727 27.5737 12.2817 27.3825 11.4546H14.3187V16.8636H21.8385C21.4624 18.7079 20.4391 20.1256 18.9746 21.0483L23.4533 24.5107C26.0271 22.1219 27.7012 18.5633 27.7012 14Z"
          fill="#4285F4"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={wSize} height={hSize} fill={bgColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default googleSvg;
