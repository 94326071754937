import React from "react";
import { Container } from "./MarketingSection";
import styled from "styled-components";
import { RectangleWrap } from "./FeatureSection";
import { Col, Row } from "antd";
import { H2Style, HeaderWrap, MainContainer } from "./TopSearchSection";
import {
  PropDetailSvg,
  SettingSvg,
  TargetSvg,
  TelescopeSvg,
} from "../../../assets/svgs";

const WhyUs = () => {
  return (
    <MainContainer containterHeight="auto">
      <HeaderWrap>
        <WhyUsWrap>
          <TitleWrap>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <RectangleWrap />
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <H2Style
                  paddingtop="25px"
                  paddingtopSm="10px"
                  lineHeight="normal"
                  fontSize="40px"
                  style={{ textAlign: "center" }}
                >
                  What makes us the ideal choice.
                </H2Style>
              </Col>
            </Row>
          </TitleWrap>
          <ContentWrap>
            <Row gutter={[0, 0]} justify="space-between">
              <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                <Row className="top_sec" gutter={[20, 20]}>
                  <Col span={4}>
                    <TargetSvg />
                  </Col>
                  <Col span={20}>
                    <Row>
                      <h2>Targeted Match Making</h2>
                    </Row>
                    <Row>
                      <p>
                        Our innovative technology matches applicants with
                        properties based on their unique qualifications and your
                        property's criteria. This ensures that you receive
                        applications from individuals who meet your specific
                        requirements, increasing the likelihood of finding
                        tenants who are an excellent fit for your property.
                      </p>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={[20, 20]}>
                  <Col span={4}>
                    <TelescopeSvg />
                  </Col>
                  <Col span={20}>
                    <Row>
                      <h2>Increased Visibility</h2>
                    </Row>
                    <Row>
                      <p>
                        By listing your properties on Avenew, you benefit from
                        enhanced visibility and exposure. Our platform employs
                        various marketing strategies to attract a broad
                        audience, ensuring that your properties receive the
                        attention they deserve. This leads to more inquiries and
                        faster tenant placements.
                      </p>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                <img
                  className="mob__image"
                  src="./images/mobileUnit.png"
                  alt="Mobile Img"
                />
                {/* <div
                style={{
                  backgroundImage: "url(./images/mobileUnit1.png)",
                  backgroundRepeat: "no-repeat",
                  height: "751px",
                  justifyContent: "space-between",
                  backgroundPosition: "top center",
                  backgroundSize: "cover",
                  width: "100%",
                  backgroundColor: "transparent",
                }}
              ></div> */}
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                <Row className="top_sec">
                  <Col span={4}>
                    <SettingSvg />
                  </Col>
                  <Col span={20}>
                    <Row>
                      <h2>Transparency and Control</h2>
                    </Row>
                    <Row>
                      <p>
                        We prioritize transparency in the rental process. As a
                        property owner on Avenew, you have control over the
                        eligibility criteria and can clearly communicate the
                        requirements to potential applicants. This allows you to
                        set expectations from the beginning and ensure a fair
                        and unbiased selection process.
                      </p>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={4}>
                    <PropDetailSvg />
                  </Col>
                  <Col span={20}>
                    <Row>
                      <h2>Detailed Property Information.</h2>
                    </Row>
                    <Row>
                      <p>
                        Avenew provides a platform where you can showcase your
                        properties with comprehensive and detailed information.
                        From high-quality photos to accurate descriptions, you
                        can present your properties in the best possible light,
                        attracting tenants who are genuinely interested and
                        qualified
                      </p>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ContentWrap>
        </WhyUsWrap>
      </HeaderWrap>
    </MainContainer>
  );
};

export default WhyUs;

const WhyUsWrap = styled.div`
  width: 1400px;
  margin: 0 auto;
  position: relative;

  @media (max-width: 1400px) {
    margin: 0;
    width: 100%;
  }
`;
const TitleWrap = styled.div`
  margin-top: 100px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .middle_item {
    justify-content: center;
  }

  @media (max-width: 991px) {
    margin-top: 50px;
    margin-bottom: 30px;
  }
`;
const ContentWrap = styled.div`
  padding-bottom: 90px;

  h2 {
    color: #2b353f;
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
  }

  p {
    color: #848687;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  .top_sec {
    margin-top: 50px;
    margin-bottom: 70px;
  }

  @media (max-width: 991px) {
    padding-bottom: 50px;

    .top_sec {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;
