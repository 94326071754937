import React from "react";
import { Container } from "./MarketingSection";
import { Col, Row } from "antd";
import { H2Style, StyledButton } from "./TopSearchSection";
import { FeatureWrap } from "./FeatureSection";
import styled from "styled-components";

const ScheduleDemo = () => {
  return (
    <>
      <BottomWrap>
        <Row justify="space-between">
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <img
              className="img_resident"
              src="/images/residentalHouse.png"
              alt="Residental House"
            />
          </Col>
          <Col
            className="right_content"
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
          >
            <Row>
              <Col span={24}>
                <H2Style
                  marginBottom="20px"
                  marginBottomSm="20px"
                  fColor="#fff"
                  lineHeight="normal"
                  fontSize="40px"
                >
                  Ready to simplify your rental journey?
                </H2Style>
              </Col>
              <Col span={24}>
                <span className="middle_text">
                  Request a demo and let Avenew guide you.
                </span>
              </Col>
              <Col span={24}>
                <StyledButton style={{ marginLeft: "0px", marginTop: "20px" }}>
                  Schedule your demo now
                </StyledButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </BottomWrap>
    </>
  );
};

export default ScheduleDemo;

const BottomWrap = styled.div`
  width: 100%;
  margin-top: 120px;
  margin-bottom: 100px;
  background: #4160ac;
  border-radius: 70px;

  img {
    margin-top: -50px;
  }

  .middle_text {
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .right_content {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  @media screen and (max-width: 991px) {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 0 10px;

    .middle_text {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .right_content,
    img {
      text-align: center;
      padding-bottom: 40px;
    }
  }
`;
