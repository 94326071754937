import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import GeneralProfile from "./sub-tabs/GeneralProfile";
import IdProof from "./sub-tabs/IdProof";
import Members from "./sub-tabs/Members";

function UserProfileTab({ isLoading, onMainTabNext }) {
  const dispatch = useDispatch();
  const { applicationProcessData } = useSelector((state) => state.user);
  const { TabPane } = Tabs;
  const { width } = useWindowSize();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("members");
  const [tabPosition, setTabPosition] = useState("left");

  useEffect(() => {
    if (applicationProcessData && applicationProcessData?.id) {
      if (
        applicationProcessData?.userProfile &&
        applicationProcessData?.userProfile?.members
      ) {
        setActiveTab("general-profile");
      }
      if (
        applicationProcessData?.userProfile &&
        applicationProcessData?.userProfile?.generalProfile
      ) {
        setActiveTab("id-proof");
      } else {
        setActiveTab("members");
      }

      if (
        applicationProcessData?.userProfile &&
        !applicationProcessData?.userProfile?.members
      ) {
        setActiveTab("members");
      }
    }
  }, [applicationProcessData]);

  useEffect(() => {
    if (width < 993) {
      setTabPosition("horizontal");
    } else {
      setTabPosition("left");
    }
  }, [width, location.pathname]);

  const onGeneralProfileNext = (tab) => {
    setActiveTab(tab);
  };

  const onBackClickTabs = (tab) => {
    switch (tab) {
      case "id-proof":
        setActiveTab("general-profile");
        break;
      case "general-profile":
        setActiveTab("members");
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <StyledTabWrapper>
        <StyledTabs
          defaultActiveKey={"members"}
          activeKey={activeTab}
          onChange={(tabKey) => {
            // setList([]);
            window.scrollTo(0, 0);
            setActiveTab(tabKey);
          }}
          tabPosition={tabPosition}
        >
          <TabPane tab="Members" key="members">
            <Members onGeneralProfileNext={onGeneralProfileNext} />
          </TabPane>
          <TabPane tab="General Profile" key="general-profile">
            <GeneralProfile
              isLoading={isLoading}
              onGeneralProfileNext={onGeneralProfileNext}
              onBackClickTabs={onBackClickTabs}
            />
          </TabPane>
          <TabPane tab="ID Proof" key="id-proof">
            <IdProof
              onMainTabNext={onMainTabNext}
              onBackClickTabs={onBackClickTabs}
            />
          </TabPane>
        </StyledTabs>
      </StyledTabWrapper>
    </div>
  );
}

export default UserProfileTab;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding: 8px 100px 8px 24px !important;
    font-weight: 400 !important;
    font-size: 18px !important;

    @media (max-width: 1200px) {
      padding: 8px 10px 8px 24px !important;
    }
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 10px 8px 24px !important;
  }
  .ant-tabs-tab-btn {
    font-size: 16px !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.secondary} !important;
    font-size: 16px !important;
  }
  .ant-tabs-ink-bar {
    background: #ffffff !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background: #ffffff !important;
    border-right: 2px solid #4160ac !important;
  }

  @media (max-width: 1550px) {
    .ant-tabs-tab {
      padding: 6px 50px 6px 18px !important;
      font-size: 14px !important;
    }
    .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
      padding: 8px 10px 8px 24px !important;
    }
  }
  @media (max-width: 992px) {
    .ant-tabs-tab.ant-tabs-tab-active {
      border-right: none !important;
      border-bottom: 2px solid #4160ac !important;
    }

    .ant-tabs-tab {
      padding: 8px 24px 8px 24px !important;
    }
  }

  @media (max-width: 769px) {
    .ant-tabs-tab.ant-tabs-tab-active {
      border-bottom: 2px solid #4160ac !important;
      margin-bottom: 2px;
    }

    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before {
      bottom: 0;
      margin-bottom: 2px;
    }
  }
`;

const StyledTabWrapper = styled.div`
  margin-left: 45px !important;

  .ant-tabs > .ant-tabs-nav {
    background: none !important;
    box-shadow: none !important;
    border-radius: 5px;
    min-height: calc(100vh - 260px);
    padding-top: 0 !important;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 74px 8px 8px !important;
  }

  .ant-divider-vertical {
    margin-top: 0px !important;
    height: 13.9em !important;
    margin: 0 263px !important;
    @media (max-width: 1540px) {
      height: 8.9em !important;
    }

    @media (max-width: 993px) {
      display: none !important;
    }
  }
  @media (max-width: 992px) {
    ${"" /* margin-left: 30px; */}
    padding: 15px !important;
  }
  .ant-tabs-left
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    padding: 0 25px !important;
  }

  .ant-tabs-tab:hover {
    color: ${(props) => props.theme.colors.secondary} !important;
  }

  @media (max-width: 1550px) {
    margin-left: 0 !important;
    .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
      padding: 8px 60px 8px 8px !important;
    }
  }
  @media (max-width: 1200px) {
    margin-left: 0 !important;
  }
  @media (max-width: 993px) {
    .ant-tabs > .ant-tabs-nav {
      min-height: auto !important;
    }
  }

  @media (max-width: 769px) {
    padding: 0 !important;

    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before {
      bottom: 0;
      margin-bottom: 2px;
    }
  }
`;
