import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, Select } from "antd";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../../../Profile-verification/BackgroundCheck";
import { StyledButton } from "../../../property-details/apply-for-property-modal/ApplyForPropertyModal";
import { FormWrap, GeneralProfileWrap } from "../GeneralProfile";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { applicationProcess } from "../../../../store/reducers/user.reducer";

function YourVehicles({ onYourSpouseNext, onBackClickTabs }) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const { applicationProcessData } = useSelector((state) => state.user);

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const onFinish = (value) => {
    setIsSubmit(true);
    let data = {};
    data.stateProfile = {};
    data.stateProfile.vehicleDetails = value;

    dispatch(applicationProcess(data)).then(({ payload }) => {
      setIsSubmit(false);
      onYourSpouseNext("why-here");
    });
    onYourSpouseNext("why-here");
  };

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  useEffect(() => {
    if (
      applicationProcessData?.stateProfile &&
      Object.values(applicationProcessData?.stateProfile?.vehicleDetails)
        ?.length
    ) {
      const vehicleDetails =
        applicationProcessData?.stateProfile?.vehicleDetails;

      form.setFieldsValue({
        vehicleColor: vehicleDetails.vehicleColor,
        year: vehicleDetails.year,
        license: vehicleDetails.license,
        state: vehicleDetails.state,
      });
    } else {
    }
  }, [applicationProcessData]);

  return (
    <GeneralProfileWrap>
      <h1>
        Have you, your spouse, or any occupant listed in this Application ever:
      </h1>

      <FormWrap mt="0">
        <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
          <StyledForms>
            <Form
              layout="vertical"
              // requiredMark={"optional"}
              form={form}
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="vehicleColor"
                  label="Make and color of vehicle"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="year"
                  label="Year"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input type="number" pattern="[0-9]*" />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="license"
                  label="License"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="state"
                  label="State"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Select
                    showSearch
                    placeholder="State"
                    suffixIcon={<IoIosArrowDown />}
                  >
                    {states.length &&
                      states.map((state) => (
                        <Option value={state}>{state}</Option>
                      ))}
                  </Select>
                </Form.Item>
              </StyledFormItem>

              <Form.Item>
                <Row
                  justify="space-between"
                  style={{ alignItems: "center", marginTop: "20px" }}
                >
                  <Col
                    xs={10}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="back__btn"
                  >
                    <IoIosArrowBack
                      onClick={() => onBackClickTabs("your-vehicles")}
                    />
                    <h2 onClick={() => onBackClickTabs("your-vehicles")}>
                      back
                    </h2>
                  </Col>
                  <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Row justify="end" style={{ alignItems: "center" }}>
                      <Col>
                        <p className="cancel__btn">Cancel</p>
                      </Col>
                      <Col xs={10} sm={8} md={8} lg={8} xl={6} xxl={6}>
                        <StyledButton
                          mt="0"
                          loading={isSubmit}
                          htmlType="submit"
                        >
                          next
                        </StyledButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </StyledForms>
        </StyledForm>
      </FormWrap>
    </GeneralProfileWrap>
  );
}

export default YourVehicles;
