import React from "react";

const alabama = ({
  wSize = "35",
  hSize = "23",
  bgColor = "#fff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox="0 0 600 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M0,0 600,400M0,400 600,0" stroke="#b10021" strokeWidth="68"/>
     <defs>
        <clipPath id="clip0">
          <rect width={wSize} height={hSize} fill={bgColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default alabama;
