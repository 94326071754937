import { Select } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "./MyAccountMobileView.css";
import Profile from "./Profile";
import Applications from "./Applications";
import ChangePassword from "./ChangePassword";
import DeleteAccount from "./DeleteAccount";
import Footer from "../../components/footer/Footer";

function MyAccountMobileView() {
  const { Option } = Select;

  const [selectedOption, setSelectedOption] = useState("profile");

  const handleChange = (value) => {
    // console.log(`selected ${value}`);
    setSelectedOption(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MyAccountMobile>
        <SelectWrap>
          <Select
            defaultValue="profile"
            onChange={handleChange}
            suffixIcon={<IoIosArrowDown />}
          >
            <Option value="profile">Profile</Option>
            <Option value="myBooking">My Booking</Option>
            {/* <Option value="changePassword">Change Password</Option> */}
            <Option value="deleteAccount">Delete Account</Option>
          </Select>
        </SelectWrap>
        <TabView>
          {selectedOption === "profile" ? (
            <Profile />
          ) : selectedOption === "myBooking" ? (
            <Applications />
          ) : selectedOption === "changePassword" ? (
            <ChangePassword />
          ) : selectedOption === "deleteAccount" ? (
            <DeleteAccount />
          ) : (
            ""
          )}
        </TabView>
      </MyAccountMobile>
      <Footer />
    </>
  );
}

export default MyAccountMobileView;

export const TabView = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const MyAccountMobile = styled.div`
  background: #fff;
  padding: 30px;
  width: 100%;
`;

export const SelectWrap = styled.div`
  width: 100%;

  .ant-select {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  .ant-select-selector {
    border-radius: 5px !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    height: 50px;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #2b353f;
  }
  .ant-select-arrow {
    font-size: 18px;
    color: #777;
  }
`;
