import React from "react";

const LinkedLn = ({
  wSize = "34",
  hSize = "34",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 34C7.6126 34 0 26.3874 0 17C0 7.6126 7.6126 0 17 0C26.3874 0 34 7.6126 34 17C34 26.3874 26.3874 34 17 34Z"
        fill="#0078B5"
      />
      <path
        d="M6.26275 13.1679H10.7814V27.7097H6.26275V13.1679ZM8.52375 5.93945C9.96875 5.93945 11.1418 7.11245 11.1418 8.56085C11.1418 10.0093 9.96875 11.1823 8.52375 11.1823C7.07535 11.1823 5.90235 10.0093 5.90235 8.56085C5.89895 7.11585 7.07195 5.93945 8.52375 5.93945Z"
        fill="white"
      />
      <path
        d="M13.6172 13.1675H17.9522V15.1531H18.0134C18.6152 14.0107 20.0874 12.8037 22.2906 12.8037C26.867 12.8037 27.7136 15.8161 27.7136 19.7295V27.7093H23.195V20.6407C23.195 18.9509 23.1644 16.7851 20.8456 16.7851C18.4928 16.7851 18.1324 18.6211 18.1324 20.5183V27.7127H13.6172V13.1675Z"
        fill="white"
      />
    </svg>
  );
};

export default LinkedLn;
