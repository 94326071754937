import { Button, Input } from "antd";
import React, { useEffect } from "react";
import styled from "styled-components";
import { useWindowSize } from "../../utils/hooks/useWindowSize";

import {
  EmailLogo,
  SendMail,
  SvgDashbord,
  SvgDashbordRight,
} from "../../assets/svgs";

function HeropartDashboard() {
  const { width } = useWindowSize();

  return (
    <>
      <HeropartDashboardWrap>
        <HeropartDashboarContaint>
          <LeftRightWrap>
            <LeftWrap>
              <h1>Sell or rent your property at the best price</h1>
              <p>
                Explore how you could save your time, efforts and thousands on
                commission while dealing with buying or selling a property.
              </p>

              <SendEmail>
                <Input.Group compact>
                  <Input
                    prefix={<EmailLogo />}
                    placeholder="Enter Email"
                    style={{
                      width: "calc(100% - 52px)",
                      height: "50px",
                    }}
                    type="email"
                  />
                  <Button>
                    <SendMail />
                  </Button>
                </Input.Group>
              </SendEmail>
            </LeftWrap>
            <RightWrap>
              <img src="/images/emdashbord.png" />
            </RightWrap>
          </LeftRightWrap>
        </HeropartDashboarContaint>
        <SvgWrap>
          <SvgDashbord />
          <SvgDashbordRight />
        </SvgWrap>
      </HeropartDashboardWrap>
    </>
  );
}

export default HeropartDashboard;

const SendEmail = styled.div`
  border-left: 0;
  width: 90%;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .ant-btn:active {
    border-color: #fff;
  }

  .ant-input-prefix {
    margin-right: 20px;
  }

  .ant-btn {
    height: 50px;
    border-left: 0;
    color: #f67c36;
    background: #fff;
    border: none;
    svg {
      margin-top: 5px;
    }
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: #f67c36;
    border-color: #d9d9d9;
    background: #fff;
  }

  .ant-input:hover {
    border-right: none;
    border-color: #d9d9d9;
  }

  .ant-input {
    background: #fff;
    border-right: none;
  }
  .ant-input-group.ant-input-group-compact > *:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #fff;
    border: none;
  }
  .ant-input-group.ant-input-group-compact > *:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .ant-input:focus,
  .ant-input-focused {
    border-color: #eeeeee;
    box-shadow: 0 0 0 2px #eeeeee;
    border-right-width: 0;
    outline: 0;
  }

  .ant-input-affix-wrapper:focus {
    border-color: #fff;
    box-shadow: none;
    border-right-width: 1px;
    outline: 0;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #fff;
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-color: #fff;
  }

  @media (max-width: 1550px) {
    width: 75%;
  }
`;

const LeftRightWrap = styled.div`
  display: flex;
  width: 1400px;
  margin: 0 auto;
  @media (max-width: 1400px) {
    width: 100%;
  }
`;

const LeftWrap = styled.div`
  width: 50%;
  padding-top: 150px;

  h1 {
    font-weight: 400;
    font-size: 50px;
    line-height: 61px;
    color: #2b353f;
    width: 90%;
    margin-bottom: 30px;
  }
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #848687;
    margin-bottom: 45px;
    width: 90%;
  }

  @media (max-width: 1550px) {
    padding-top: 120px;

    h1 {
      font-size: 40px;
      line-height: 47px;
      margin-bottom: 24px;
      width: 70%;
    }
    p {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 35px;
      width: 75%;
    }
  }
`;
const RightWrap = styled.div`
  width: 50%;
  padding-top: 100px;

  @media (max-width: 1550px) {
    padding-top: 80px;
    img {
      width: 70%;
    }
  }
`;

const HeropartDashboarContaint = styled.div`
  width: 1400px;
  margin: 0 auto;

  @media (max-width: 1550px) {
    padding: 0 30px;
    width: 100%;
  }
  @media (max-width: 425px) {
    padding: 0 15px;
  }
`;

const HeropartDashboardWrap = styled.div`
  background: #f5f8ff;
  height: calc(100vh - 80px);
  position: relative;
  overflow: hidden;

  @media (max-width: 1550px) {
    height: calc(100vh - 65px);
  }
`;

const SvgWrap = styled.div`
  width: -webkit-fill-available;

  display: flex;
  position: absolute;
  bottom: -30px;
  left: 0;
`;
