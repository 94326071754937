import React, { useState } from "react";
import { Image, Popconfirm } from "antd";
import styled from "styled-components";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { FaRegEye, FaTrash } from "react-icons/fa";
import { IoIosImage } from "react-icons/io";
import { AiOutlineDownload } from "react-icons/ai";

const download = (e) => {
  const link = document.createElement("a");
  link.href = e;
  // link.setAttribute("download", "images.jfif"); //or any other extension
  document.body.appendChild(link);
  link.click();
  // fetch(
  //   "https://chats-attachment-dev.s3.amazonaws.com/chatAttachments/8cff4981-3166-49b1-8ea3-45ed1daff63e/988e6503-e2da-45fe-ab8b-b81fe3e91c57download (1).jfif",
  //   {
  //     method: "GET",
  //     headers: {},
  //   }
  // )
  //   .then((response) => {
  //     response.arrayBuffer().then(function (buffer) {
  //       const url = window.URL.createObjectURL(new Blob([buffer]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "images.jfif"); //or any other extension
  //       document.body.appendChild(link);
  //       link.click();
  //     });
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
};

const ImagePreview = ({
  onDelete = console.log,
  onCover = console.log,
  isCoverDisProp = true,
  isDeleteDisProp = true,
  isDownload = false,
  downloadImgUrl,
  ...props
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
    <ImageContainer
      preview={{
        visible: visible,
        onVisibleChange: (current, prev) => {
          prev && setVisible(false);
        },
        mask: (
          <Mask
            downloadImgUrl={downloadImgUrl}
            onPreview={() => setVisible(true)}
            onDownload={() => {
              download(downloadImgUrl);
            }}
            onDelete={(e) => {
              // e.stopPropagation();
              onDelete(e);
            }}
            onCover={(e) => onCover(e)}
            isCoverDis={isCoverDisProp}
            isDeleteDis={isDeleteDisProp}
            isDownload={isDownload}
          />
        ),
        maskClassName: "img-preview-mask",
      }}
      {...props}
    />
    </>
  );
};

const Mask = ({
  onPreview,
  onDelete,
  onCover,
  downloadImgUrl,
  onDownload,
  isDownload = false,
  isCoverDis = true,
  isDeleteDis = true,
}) => {
  return (
    <div>
      <div onClick={onPreview}>
        <Icon>
          <FaRegEye />
        </Icon>{" "}
        <span>Preview</span>
      </div>
      {isDeleteDis ? (
        <Popconfirm
          key={"del-c"}
          title="Are you sure?"
          onConfirm={(e) => onDelete(e)}
          okText="Yes"
          cancelText="No"
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        >
          <div>
            <Icon>
              <FaTrash />
            </Icon>{" "}
            <span>Delete</span>
          </div>
        </Popconfirm>
      ) : (
        ""
      )}
      {isCoverDis ? (
        <div onClick={onCover}>
          <Icon>
            <IoIosImage />
          </Icon>{" "}
          <span>Cover</span>
        </div>
      ) : (
        ""
      )}
      {isDownload ? (
        <div onClick={onDownload}>
          <Icon>
            <AiOutlineDownload />
          </Icon>{" "}
          <span href={downloadImgUrl} download onClick={(e) => download(e)}>
            <i className="fa fa-download" />
            Download
          </span>
          {/* <span>Download</span> */}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export const ImageContainer = styled(Image)`
  border-radius: 10px;
`;

export const Icon = styled.span`
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 32px;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
`;

export default ImagePreview;
