import React from "react";
import {
  H2Style,
  HeaderWrap,
  MainContainer,
  StyledButton,
} from "./TopSearchSection";
import { Container } from "./MarketingSection";
import styled from "styled-components";
import { Col, Row } from "antd";
import { RectangleWrap } from "./FeatureSection";

const OwnersCategory = () => {
  return (
    <>
      <Container>
        <HeaderWrap>
          <CategoryWrap>
            <TopWrap>
              <Row>
                <Col xs={24} sm={24} md={24} lg={13} xl={13} xxl={13}>
                  <img
                    className="lap__img"
                    src="./images/iPad_Pro_Smart_Keyboard.png"
                  />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={{
                    span: 10,
                    offset: 1,
                  }}
                  xl={{
                    span: 10,
                    offset: 1,
                  }}
                  xxl={{
                    span: 10,
                    offset: 1,
                  }}
                >
                  <Row>
                    <RectangleWrap />
                  </Row>
                  <H2Style
                    lineHeight="normal"
                    fontSize="40px"
                    paddingtop="25px"
                    paddingBottom="30px"
                  >
                    For each Type of property owner
                  </H2Style>
                  <h4>Unit Owners</h4>
                  <p>
                    Got a handful of units but insufficient for employing a
                    property manager? Arrange and simplify all your listings in
                    a unified platform.
                  </p>
                  <h4>Part-time Property Managers</h4>
                  <p>
                    For those with busy schedules, always on the move, we're
                    here to assit you in attaining peak efficiency, freeing up
                    your time to concentrate on your priorities.
                  </p>
                  <h4>Full-time Property Managers</h4>
                  <p>
                    Recognizing the value of time and efficiency, Avenew.app is
                    available to aid full-time property managers handling
                    numerous listings and teams in streamlining their
                    communication processes.
                  </p>
                </Col>
              </Row>
            </TopWrap>
            <BottomWrap>
              <Row justify="space-between">
                <Col
                  className="left_content"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  xl={12}
                  xxl={12}
                >
                  <Row>
                    <h1>
                      See how Avenew can transform your property management
                      process.
                    </h1>
                  </Row>
                  <Row className="demo__btn">
                    <StyledButton style={{ marginLeft: "0px" }}>
                      Schedule your demo Now
                    </StyledButton>
                  </Row>
                </Col>
                {/* <Col
                xs={24}
                sm={24}
                md={24}
                lg={{ span: 11 }}
                xl={{ span: 11 }}
                xxl={{ span: 11 }}
              >
                <img
                  src="./images/hand_presenting_model_house.png"
                  alt="Hand Img"
                />
              </Col> */}
              </Row>
              <div className="house__hand__mob">
                <img
                  className="house__hand"
                  src="./images/hand_presenting_model_house.png"
                  alt="Hand Img"
                />
              </div>
            </BottomWrap>
          </CategoryWrap>
        </HeaderWrap>
      </Container>
    </>
  );
};

export default OwnersCategory;

const CategoryWrap = styled.div`
  width: 1400px;
  margin: 0 auto;
  padding-bottom: 100px;

  @media (max-width: 1400px) {
    margin: 0;
    width: 100%;
  }

  @media (max-width: 991px) {
    padding-bottom: 50px;
  }
`;

const BottomWrap = styled.div`
  width: 100%;
  ${"" /* height: 372px; */}
  position: relative;
  background-color: #26314b;
  border-radius: 70px;
  .house__hand {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  h1 {
    color: #fff;
    padding-bottom: 30px;
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
  }
  .left_content {
    padding: 65px 0 65px 65px;
  }

  @media (max-width: 991px) {
    border-radius: 50px;
    h1 {
      color: #fff;
      text-align: center;
      font-size: 30px;
      font-weight: 700;
    }
    .house__hand {
      position: relative;
      width: 95%;
    }
    .house__hand__mob {
      display: flex;
      justify-content: end;
    }

    .left_content {
      padding: 50px 25px 30px;
    }
    .demo__btn {
      justify-content: center;s
    }
  }

  @media (max-width: 425px) {
    .left_content {
      padding: 50px 15px 30px;
    }
  }
`;

const TopWrap = styled.div`
  margin-bottom: 130px;
  img {
    display: flex;
    width: 100%;
  }
  h2 {
    padding-top: 25px;
    color: #2b353f;
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 30px;
  }
  h4 {
    color: #2b353f;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
  }
  p {
    color: #848687;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
  }

  @media (max-width: 991px) {
    margin-bottom: 50px;

    h2 {
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .lap__img {
      margin-bottom: 50px;
    }
  }
`;
