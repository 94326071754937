import React, { useEffect, useState } from "react";
import {
  Badge,
  Col,
  Row,
  Steps,
  Checkbox,
  Button,
  Breadcrumb,
  Divider,
  Input,
  Form,
  Select,
  Spin,
} from "antd";
import StarRatings from "react-star-ratings";
import styled from "styled-components";
import {
  AccountCreateLogo,
  AvenewImg,
  BathSvg,
  BedSvg,
  NoApplications,
  OpsSvg,
  SmileSvg,
  Sqft,
  WelcomeHomeSvg,
} from "../../assets/svgs";
import { Container } from "../../components/container/Container";
import { applicationsData } from "../../components/featuredProperties/Cards/dummy.data";
import Footer from "../../components/footer/Footer";
import HeaderSearch from "../../components/headerSearch/HeaderSearch";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  BreadCrumbsWrapper,
  BreadcrumbTop,
} from "../property-details/PropertyDetails";
import { AiOutlineCheck, AiOutlineUpload } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { getApplications, leaseSign } from "../../store/reducers/user.reducer";
import { AtchBox } from "../message/Message";
import { CgFileDocument } from "react-icons/cg";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import SignatureCanvas from "react-signature-canvas";
import { useRef } from "react";
import {
  StyledFormSelect,
  StyledUploadPreviewWrapper,
  UploadBox,
  UploadInput,
} from "../Profile-verification/BackgroundCheck";
import { IoIosArrowDown, IoIosClose } from "react-icons/io";
import { ImagePreview } from "../../components/ImagePreview";
import { imagePath } from "../../utils/fn";
import { HiDownload } from "react-icons/hi";
import "jspdf/dist/polyfills.es.js";
import { jsPDF } from "jspdf";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

function Applications() {
  const doc = new jsPDF({
    orientation: "p",
    unit: "in",
    // format: [20, 25],
    format: [8.27, 11.69],
  });
  const navigate = useNavigate();

  const location = useLocation();
  const { Step } = Steps;
  const [form] = Form.useForm();
  const upload = React.useRef(null);
  const { Option } = Select;
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const [currentStatus, setCurrentStatus] = useState(0);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [allApplicatiosObj, setAllApplicationsObj] = useState({});
  const [selectedApplication, setSelectedApplication] = useState({});
  const [allApplicationsArr, setAllApplicationsArr] = useState([]);
  const [signature, setSignature] = useState(null);
  const [state, setState] = useState([]);
  const [selectedSignFormat, setSelectedSignFormat] = useState("drawSign");

  const attachmentType = ["image/svg+xml", "application/pdf"];

  const handleSignatureEnd = () => {
    setSignature({ file: sig.current.toDataURL() });
  };

  const onChange = (e) => {
    setDisabledBtn(e.target.checked);
  };

  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const breadcrumbNameMap = [
    { path: "/dashboard", label: "Dashboard", isLink: true },
    {
      path: "/applications",
      label: "Property Application",
      isLink: false,
    },
  ];
  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      const breadcrumb = breadcrumbNameMap.find((b) => b.path === url);
      return breadcrumb ? (
        <Breadcrumb.Item key={`${breadcrumb.path}_${index}`}>
          {breadcrumb.isLink ? (
            <Link to={breadcrumb.isLink && breadcrumb.path}>
              {breadcrumb.label}
            </Link>
          ) : (
            <span className="breadcrumbs_label">{breadcrumb.label}</span>
          )}
        </Breadcrumb.Item>
      ) : null;
    })
    .filter((i) => i);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getApplications()).then(({ payload }) => {
      setIsLoading(false);
      setAllApplicationsObj(payload);
    });
  }, []);

  useEffect(() => {
    if (allApplicatiosObj && Object.keys(allApplicatiosObj).length) {
      const arr = Object.values(allApplicatiosObj);
      setAllApplicationsArr(arr);
      setSelectedApplication(arr[0]);
    } else {
      setAllApplicationsArr([]);
    }
  }, [allApplicatiosObj]);

  const download = (e, name) => {
    const link = document.createElement("a");
    // link.setAttribute("download", "images.jfif");
    link.href = e;

    document.body.appendChild(link);
    link.click();
  };
  const downloadPdf = (text) => {
    doc.text(text, 1, 1);
    doc.save("a4.pdf");
  };
  const [stringSort, setStringSort] = useState(8);

  useEffect(() => {
    if (width < 768) {
      setStringSort(8);
    } else {
      setStringSort(30);
    }
  }, [width, location.pathname]);

  const slpitFunc = (value) => {
    if (value?.length > 28) {
      return value.slice(0, stringSort) + "..." + value.slice(-8);
    } else {
      return value;
    }
  };

  const statusFtColor = (status) => {
    switch (status) {
      case "Awaiting Approval":
        return "#F78341";
      case "Waiting for Lease":
        return "#4160AC";
      case "Deposit Pending":
        return "#FFC123";
      case "Awaiting Approval":
        return "#4160AC";
      case "Approved":
        return "#56C274";
      case "Denied":
        return "#2B353F";
      default:
        return "#aaaaaa";
    }
  };

  const toUSACurrency = (num) => {
    const curr = num.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return curr;
  };

  const upperCase = (value) => {
    if (value) {
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2;
    }
  };

  const onChangeTab = (value) => {
    setCurrentStatus(value);
  };

  const sig = useRef();

  const clear = () => {
    sig.current.clear();
  };
  const clearSign = () => {
    let editObj = { ...selectedApplication };
    delete editObj.sign;
    setSelectedApplication(editObj);
  };

  const MAX_LENGTH = 4;

  const fileChangeHandler = async (event) => {
    // const imgType =
    if (Array.from(event.target.files).length > MAX_LENGTH) {
      event.preventDefault();
      alert(`Cannot upload files more than ${MAX_LENGTH}`);
      return;
    }
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    const newFun = async (img) => {
      const convertImg = await convertToBase64(img);
      setState((s) => [
        {
          file: convertImg,
          path: URL.createObjectURL(img),
          name: img.name,
          ext: img.type,
        },
      ]);
    };

    Array(...event.target.files).length &&
      (await Promise.all(
        Array(...event.target.files).map(async (file) => {
          return await newFun(file);
        })
      ));
  };

  const fileChangeHandlerDrag = async (files) => {
    if (Array.from(files).length > MAX_LENGTH) {
      alert(`Cannot upload files more than ${MAX_LENGTH}`);
      files.preventDefault();
      return;
    }

    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    await Promise.all(
      Array(...files).map(async (img) => {
        const convertImg = await convertToBase64(img);
        setState((s) => [
          {
            file: convertImg,
            path: URL.createObjectURL(img),
            name: img.name,
            ext: img.type,
          },
        ]);
      })
    );
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      fileChangeHandlerDrag(files);
    }
  };

  const drop = React.useRef(null);

  React.useEffect(() => {
    // drop.current.addEventListener("dragover", handleDragOver);
    // drop.current.addEventListener("drop", handleDrop);
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      leaseAgreementDoc: state,
    });
  }, [form, state]);

  useEffect(() => {
    form.setFieldsValue({
      signFormat: "drawSign",
    });
  }, [form]);

  const [value, setValue] = useState("");
  const exportHTML = () => {
    var header =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
      "xmlns:w='urn:schemas-microsoft-com:office:word' " +
      "xmlns='http://www.w3.org/TR/REC-html40'>" +
      "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
    var footer = "</body></html>";
    var sourceHTML =
      header + document.getElementById("source-html").innerHTML + footer;
    // downloadPdf(sourceHTML);

    var source =
      "data:application/pdf;charset=utf-8," + encodeURIComponent(sourceHTML);
    var fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = "document.doc";
    fileDownload.click();
    document.body.removeChild(fileDownload);
  };

  useEffect(() => {
    setState([]);
    if (selectedApplication) {
      if (selectedApplication?.signFormat) {
        form.setFieldsValue({
          signFormat: selectedApplication?.signFormat,
        });
      }
      setDisabledBtn(selectedApplication?.isAgreeSign);

      if (selectedApplication?.sign) {
        setCurrentStatus(2);
      } else if (
        selectedApplication?.leaseAgreementText ||
        selectedApplication?.leaseAgreementDoc
      ) {
        setCurrentStatus(1);
      } else {
        setCurrentStatus(0);
      }
    }
  }, [selectedApplication]);

  console.log(signature, "signature");

  const onFinish = (value = {}) => {
    value.selectedDate = selectedApplication?.selectedDate;
    value.prospectUserId = selectedApplication?.prospectUserId;
    value.previousRental = selectedApplication?.previousRental;
    value.leaseLength = selectedApplication?.leaseLength;
    value.isApprove = selectedApplication?.isApprove;
    value.incomeVerified = selectedApplication?.incomeVerified;
    value.id = selectedApplication?.id;
    value.creditScoreVerified = selectedApplication?.creditScoreVerified;
    value.backgroundCheckVerified =
      selectedApplication?.backgroundCheckVerified;
    value.adminUserId = selectedApplication?.adminUserId;
    value.unitId = selectedApplication?.unitId;
    value.leaseAgreementDoc = selectedApplication?.leaseAgreementDoc;
    if (signature && value.signFormat === "drawSign") {
      console.log("called");
      value.isAgreeSign = disabledBtn;
      value.sign = signature;
    } else if (state?.length && value.signFormat === "uploadSign") {
      value.isAgreeSign = disabledBtn;
      value.sign = state[0];
    } else if (state?.length && !value.signFormat) {
      value.signedLeaseAgreementDoc = state[0];
    } else if (selectedApplication?.sign) {
      value.sign = selectedApplication?.sign;
    }

    console.log(value, "value");

    setIsNextLoading(true);

    value.leaseAgreementText = selectedApplication?.leaseAgreementText;
    dispatch(leaseSign(value)).then(({ payload }) => {
      setIsNextLoading(false);
      setSelectedApplication(payload);
    });
  };

  console.log(selectedApplication, "selectedApplication");

  return (
    <>
      <BreadcrumbTop>
        <BreadCrumbsWrapper>
          <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
        </BreadCrumbsWrapper>
      </BreadcrumbTop>

      <Container padding="50px 0">
        <ApplicationsMain>
          <ApplicationsLeft>
            <Row>
              <Col span={24}>
                <HeaderSearch bgcolor="#eee" />
              </Col>
            </Row>
            {isLoading ? (
              <div
                style={{
                  position: "relative",
                  minHeight: "calc(100vh - 80px)",
                }}
              >
                <Spin className="ant__spin" size="large" />
              </div>
            ) : (
              ""
            )}

            <ApplicationsCardWrap>
              <Row gutter={[10, 10]}>
                {allApplicationsArr?.map((item, id) => (
                  <Col span={24} key={id}>
                    <Badge.Ribbon
                      text={
                        item?.isApprove === true
                          ? "Approved"
                          : item?.isApprove === false
                          ? "Denied"
                          : "Awaiting Approval"
                      }
                      color={statusFtColor(
                        item?.isApprove === true
                          ? "Approved"
                          : item?.isApprove === false
                          ? "Denied"
                          : "Awaiting Approval"
                      )}
                    >
                      <ApplicationsCard
                        borderColor={
                          item.id === selectedApplication.id
                            ? "#F67C36"
                            : "none"
                        }
                        onClick={() => setSelectedApplication(item)}
                      >
                        <ApplicationsCardTop>
                          {item.unitDetails?.images &&
                          item.unitDetails?.images?.length ? (
                            <>
                              {item.unitDetails?.images.map((img, idx) => (
                                <>
                                  {img.isCover === true ? (
                                    <img alt="example" src={img.path} />
                                  ) : (
                                    ""
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <AvenewImg
                              className="svg__border"
                              style={{
                                background: "#eeeeee",
                                height: "100px",
                                width: "100px",
                              }}
                            />
                          )}
                          <CardDetails>
                            <Row>
                              <Col>
                                <StarRatings
                                  rating={4.7}
                                  starRatedColor="#F67C36"
                                  starDimension="15px"
                                  starSpacing="3px"
                                  numberOfStars={5}
                                  name="rating"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <h2>
                                  {upperCase(item.unitDetails?.unit_name)}
                                </h2>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p>
                                  {toUSACurrency(
                                    Number(item.unitDetails?.price)
                                  )}
                                </p>
                              </Col>
                            </Row>
                          </CardDetails>
                        </ApplicationsCardTop>
                        <BedBathBox>
                          <Row justify="space-between">
                            <Col className="card__bottom">
                              <p>
                                <BedSvg className="icon" />
                              </p>
                              <span>Bed : {item.unitDetails?.bed}</span>
                            </Col>
                            <Col className="card__bottom">
                              <p>
                                <BathSvg />
                              </p>
                              <span>Bath : {item.unitDetails?.bath}</span>
                            </Col>
                            <Col className="card__bottom">
                              <p>
                                <Sqft />
                              </p>
                              <span>Sq ft : {item.unitDetails?.sqft}</span>
                            </Col>
                          </Row>
                        </BedBathBox>
                      </ApplicationsCard>
                    </Badge.Ribbon>
                  </Col>
                ))}
              </Row>
              <NoApplicationWrap>
                {allApplicationsArr?.length ? (
                  ""
                ) : (
                  <>
                    <NoApplications />
                    <p>No applications been sent</p>
                  </>
                )}
              </NoApplicationWrap>
            </ApplicationsCardWrap>
          </ApplicationsLeft>
          <ApplicationsRight>
            <h2>
              {currentStatus === 0 ? "Applications" : "Property Application"}
            </h2>
            <Steps onChange={onChangeTab} current={currentStatus}>
              <Step title="Apply" />
              <Step title="Sign Agreement" />
              <Step title="Checkout" />
              <Step title="Done" />
            </Steps>
            {currentStatus === 0 ? (
              <>
                {selectedApplication?.isApprove ? (
                  <StatusMain>
                    <Row justify="center">
                      <Col span={24}>
                        <AccountCreateLogo />
                      </Col>
                      <Col span={24}>
                        <h3 className="avenew__signup">Congratulations!</h3>
                      </Col>
                      <Col span={20}>
                        <h4>Your application has been approved successfully</h4>
                      </Col>
                      <Col span={5}>
                        <StyledButton
                          htmlType="submit"
                          onClick={() => setCurrentStatus(currentStatus + 1)}
                          loading={isNextLoading}
                        >
                          NEXT
                        </StyledButton>
                      </Col>
                    </Row>
                  </StatusMain>
                ) : selectedApplication?.isApprove === false ? (
                  <StatusMain>
                    <Row justify="center">
                      <Col span={24}>
                        <OpsSvg />
                      </Col>
                      <Col span={24}>
                        <h3 className="avenew__signup">Opps!</h3>
                      </Col>
                      <Col span={20}>
                        <h4>
                          Your application has been rejected we can’t process
                        </h4>
                      </Col>
                      <Col span={24}>
                        <StyledButton
                          htmlType="submit"
                          onClick={() => navigate("/")}
                          loading={isNextLoading}
                        >
                          Go to Homepage
                        </StyledButton>
                      </Col>
                    </Row>
                  </StatusMain>
                ) : (
                  <StatusMain>
                    <Row justify="center">
                      <Col span={24}>
                        <SmileSvg />
                      </Col>
                      <Col span={20}>
                        <h4>
                          You haven't applied to any units yet. <br />
                          Would you like to apply now?
                        </h4>
                      </Col>
                      <Col span={20}>
                        <h5>
                          Completed user verification process? Complete it now
                        </h5>
                      </Col>
                      <Col span={24}>
                        <StyledButton
                          htmlType="submit"
                          onClick={() => navigate("/")}
                          loading={isNextLoading}
                        >
                          Let's explore listings
                        </StyledButton>
                      </Col>
                    </Row>
                  </StatusMain>
                )}
              </>
            ) : currentStatus === 1 ? (
              <LeasingAgreement>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h1>Leasing Agreement</h1>
                  {selectedApplication?.leaseAgreementDoc ? (
                    <AtchBox
                      fontColor="#F67C36"
                      style={{ marginBottom: "30px" }}
                      onClick={() =>
                        download(
                          selectedApplication?.leaseAgreementDoc.path,
                          selectedApplication?.leaseAgreementDoc.name
                        )
                      }
                    >
                      {/* <CgFileDocument /> */}
                      <h4 className="dow__svg">Download</h4>
                      <HiDownload
                        style={{ fontSize: "24px", marginLeft: "10px" }}
                        className="dow__svg"
                      />
                    </AtchBox>
                  ) : (
                    <AtchBox
                      fontColor="#F67C36"
                      style={{ marginBottom: "30px" }}
                      id="btn-export"
                      onClick={() => {
                        // downloadPdf(selectedApplication?.leaseAgreementText)
                        exportHTML();
                      }}
                    >
                      <h4 className="dow__svg">Download</h4>
                      <HiDownload
                        style={{ fontSize: "24px", marginLeft: "10px" }}
                        className="dow__svg"
                      />
                    </AtchBox>
                  )}
                </div>

                {/* <h5>{selectedApplication?.leaseAgreementText}</h5> */}
                <ReactQuill
                  id="source-html"
                  value={selectedApplication?.leaseAgreementText}
                  readOnly={true}
                  theme={"bubble"}
                  onChange={setValue}
                />

                {selectedApplication?.leaseAgreementDoc ? (
                  <AgreeSign>
                    <AgreeSignRight width="100%" justifyContent="space-between">
                      <UploadBox
                        style={{ height: "100px", width: "35%" }}
                        mt="0"
                        ref={drop}
                        onClick={(e) => upload.current.click()}
                      >
                        <UploadInput>
                          <h6 className="ant-upload-drag-icon">
                            <AiOutlineUpload className="upload__icon" />
                          </h6>
                          <h6 className="upload__icon">
                            Upload Signed Agreement
                          </h6>
                        </UploadInput>
                      </UploadBox>
                      <input
                        accept="application/pdf"
                        id="myInput"
                        // multiple={"multiple"}
                        type="file"
                        ref={upload}
                        style={{ display: "none" }}
                        onChange={fileChangeHandler}
                      />
                      <div style={{ display: "flex" }}>
                        {state.map((img, idx) => (
                          <StyledUploadPreviewWrapper isPhoto={state.length}>
                            {attachmentType.includes(img.ext) ? (
                              <ObjWrap>
                                <object
                                  data={img.path}
                                  type="application/pdf"
                                  width="100%"
                                  // height="100%"
                                  height="200"
                                >
                                  <p>
                                    Alternative text - include a link{" "}
                                    <a href={img.path}>to the PDF!</a>
                                  </p>
                                </object>
                                <h3>
                                  <IoIosClose
                                    onClick={() => setState([])}
                                    className="modal_close_icon"
                                  />
                                </h3>
                              </ObjWrap>
                            ) : (
                              <ImagePreview
                                key={idx}
                                src={img.path ? img.path : imagePath(img.path)}
                                height={95}
                                width={95}
                                onDelete={() => {
                                  const st = [...state];
                                  st.splice(idx, 1);
                                  setState(st);
                                }}
                                isCoverDisProp={false}
                              />
                            )}
                          </StyledUploadPreviewWrapper>
                        ))}
                      </div>
                      <StyledButton
                        // disabled={!disabledBtn}
                        // onClick={() => setCurrentStatus(currentStatus + 1)}
                        onClick={() => onFinish()}
                        htmlType="submit"
                        loading={isNextLoading}
                      >
                        NEXT
                      </StyledButton>
                    </AgreeSignRight>
                  </AgreeSign>
                ) : selectedApplication?.leaseAgreementText ? (
                  <>
                    <AgreeSign display="block">
                      <Checkbox checked={disabledBtn} onChange={onChange}>
                        Agree & Sign
                      </Checkbox>
                    </AgreeSign>
                    <AgreeSign>
                      <Form
                        style={{ width: "100%" }}
                        layout="vertical"
                        // requiredMark={"optional"}
                        form={form}
                        onFinish={onFinish}
                        // validateMessages={validateMessages}
                      >
                        <Row
                          justify="space-between"
                          style={{ alignItems: "center", width: "100%" }}
                        >
                          <Col span={6}>
                            <StyledFormSelect wh="100">
                              <Form.Item
                                name="signFormat"
                                // label="Form of Identification"
                              >
                                <Select
                                  // placeholder="Draw sign"
                                  suffixIcon={<IoIosArrowDown />}
                                  defaultValue="drawSign"
                                  onChange={(value) =>
                                    setSelectedSignFormat(value)
                                  }
                                >
                                  <Option value="drawSign">Draw sign</Option>
                                  <Option value="uploadSign">
                                    Upload sign
                                  </Option>
                                </Select>
                              </Form.Item>
                            </StyledFormSelect>
                          </Col>
                          <Col span={12}>
                            <Row>
                              <Col
                                style={{
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                                span={24}
                              >
                                {selectedSignFormat === "drawSign" ? (
                                  <>
                                    {selectedApplication?.sign?.path ? (
                                      <img
                                        src={selectedApplication?.sign?.path}
                                      />
                                    ) : (
                                      <SignatureCanvas
                                        ref={sig}
                                        onEnd={handleSignatureEnd}
                                        penColor="green"
                                        canvasProps={{
                                          // width: 370,
                                          height: 175,
                                          className: "sigCanvas",
                                        }}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <UploadBox
                                      style={{ height: "100px", width: "50%" }}
                                      mt="0"
                                      ref={drop}
                                      onClick={(e) => upload.current.click()}
                                    >
                                      <UploadInput>
                                        <h6 className="ant-upload-drag-icon">
                                          <AiOutlineUpload className="upload__icon" />
                                        </h6>
                                        <h6 className="upload__icon">
                                          Upload Sign
                                        </h6>
                                      </UploadInput>
                                    </UploadBox>
                                    <input
                                      accept="image/*"
                                      id="myInput"
                                      // multiple={"multiple"}
                                      type="file"
                                      ref={upload}
                                      style={{ display: "none" }}
                                      onChange={fileChangeHandler}
                                    />
                                    <div style={{ display: "flex" }}>
                                      {state.map((img, idx) => (
                                        <StyledUploadPreviewWrapper
                                          isPhoto={state.length}
                                        >
                                          <ImagePreview
                                            key={idx}
                                            src={
                                              img.path
                                                ? img.path
                                                : imagePath(img.path)
                                            }
                                            height={95}
                                            width={95}
                                            onDelete={() => {
                                              const st = [...state];
                                              st.splice(idx, 1);
                                              setState(st);
                                            }}
                                            isCoverDisProp={false}
                                          />
                                        </StyledUploadPreviewWrapper>
                                      ))}
                                    </div>
                                  </>
                                )}
                              </Col>
                              {selectedSignFormat === "drawSign" ? (
                                <>
                                  {selectedApplication?.sign?.path ? (
                                    <Col
                                      style={{
                                        justifyContent: "end",
                                        display: "flex",
                                      }}
                                      span={24}
                                    >
                                      <h5 onClick={clearSign}>Clear</h5>
                                    </Col>
                                  ) : (
                                    <Col
                                      style={{
                                        justifyContent: "end",
                                        display: "flex",
                                      }}
                                      span={24}
                                    >
                                      <h5 onClick={clear}>Clear</h5>
                                    </Col>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </Row>
                          </Col>
                          <Col span={6}>
                            <AgreeSignRight>
                              <StyledButton
                                disabled={!disabledBtn}
                                loading={isNextLoading}
                                // onClick={() => setCurrentStatus(currentStatus + 1)}
                                htmlType="submit"
                              >
                                NEXT
                              </StyledButton>
                            </AgreeSignRight>
                          </Col>
                        </Row>
                      </Form>
                    </AgreeSign>
                  </>
                ) : (
                  <AgreeSign>
                    <h1
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        display: "flex",
                        color: "#777777",
                      }}
                    >
                      Waiting for{" "}
                      {selectedApplication?.isApprove === true
                        ? "Lease Agreement..."
                        : "Approval..."}
                    </h1>
                  </AgreeSign>
                )}
              </LeasingAgreement>
            ) : currentStatus === 2 ? (
              <CheckoutWrap>
                <p>Checkout</p>
                <Row gutter={[20, 20]}>
                  <Col span={24}>
                    <ApplicationsCards>
                      {selectedApplication.unitDetails?.images &&
                      selectedApplication.unitDetails?.images?.length ? (
                        <>
                          {selectedApplication.unitDetails?.images.map(
                            (img, idx) => (
                              <>
                                {img.isCover === true ? (
                                  <img alt="example" src={img.path} />
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          )}
                        </>
                      ) : (
                        <AvenewImg
                          className="svg__border"
                          style={{
                            background: "#eeeeee",
                            height: "150px",
                            width: "150px",
                          }}
                        />
                      )}
                      <CardDetails>
                        <Row>
                          <Col>
                            <StarRatings
                              rating={3.6}
                              starRatedColor="#F67C36"
                              starDimension="20px"
                              starSpacing="5px"
                              // changeRating={this.changeRating}
                              numberOfStars={5}
                              name="rating"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h2>
                              {upperCase(
                                selectedApplication.unitDetails?.unit_name
                              )}
                            </h2>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p>
                              {toUSACurrency(
                                Number(selectedApplication.unitDetails?.price)
                              )}
                            </p>
                          </Col>
                        </Row>
                        <Row justify="space-between">
                          <Col className="card__bottom">
                            <p>
                              <BedSvg className="icon" />
                            </p>
                            <span>
                              Bed : {selectedApplication.unitDetails?.bed}
                            </span>
                          </Col>
                          <Col className="card__bottom">
                            <p>
                              <BathSvg />
                            </p>
                            <span>
                              Bath : {selectedApplication.unitDetails?.bath}
                            </span>
                          </Col>
                          <Col className="card__bottom">
                            <p>
                              <Sqft />
                            </p>
                            <span>
                              Sq ft : {selectedApplication.unitDetails?.sqft}
                            </span>
                          </Col>
                        </Row>
                      </CardDetails>
                    </ApplicationsCards>
                  </Col>
                </Row>
                <PriceComponent>
                  <DiscountCode>
                    <Input.Group compact>
                      <Input
                        placeholder="Discount Code"
                        style={{
                          width: "calc(100% - 75px)",
                          height: "50px",
                        }}
                      />
                      <Button>Apply</Button>
                    </Input.Group>
                  </DiscountCode>
                  <Divider type="vertical" />
                  <PriceCode>
                    <Row gutter={[20, 20]} justify="space-between">
                      <Col span={12}>
                        <span>Sub Total</span>
                      </Col>
                      <Col span={12}>
                        <p>
                          {toUSACurrency(
                            Number(selectedApplication.unitDetails?.price)
                          )}
                        </p>
                      </Col>
                      <Col span={12}>
                        <span>Discount</span>
                      </Col>
                      <Col span={12}>
                        <p>{toUSACurrency(Number("0"))}</p>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "40px",
                      }}
                    >
                      <Col span={12}>
                        <span>Total</span>
                      </Col>
                      <Col span={12}>
                        <p>
                          {toUSACurrency(
                            Number(selectedApplication.unitDetails?.price)
                          )}
                        </p>
                      </Col>
                    </Row>
                  </PriceCode>
                </PriceComponent>
                <Divider />
                <BottomWrap>
                  <StyledButton
                    padding="4px 40px"
                    onClick={() => setCurrentStatus(currentStatus + 1)}
                  >
                    Make Payment
                  </StyledButton>
                  <p>Cancel</p>
                </BottomWrap>
              </CheckoutWrap>
            ) : currentStatus === 3 ? (
              <WelcomeHomeWrap>
                <Row justify="center">
                  <Col>
                    <WelcomeHomeSvg />
                  </Col>
                </Row>
                <Row justify="center">
                  <Col span={24}>
                    <h2>Welcome Home</h2>
                  </Col>
                  <Col span={24}>
                    <p>
                      Congrats you’re successfully completed the application
                      process
                    </p>
                  </Col>
                  <Col span={24}>
                    <Divider />
                  </Col>
                  <Col style={{ textAlign: "center" }} span={24}>
                    <span>
                      Mark Jecno is an Avenew Partner. That means you can use
                      avenew to :
                    </span>
                  </Col>
                </Row>
                <RentalPayment>
                  <Row>
                    <Col style={{ textAlign: "center" }} span={8}>
                      <span>
                        <AiOutlineCheck />
                        Pay Rent
                      </span>
                    </Col>
                    <Col style={{ textAlign: "center" }} span={8}>
                      <span>
                        <AiOutlineCheck />
                        Maintenance Requests
                      </span>
                    </Col>
                    <Col style={{ textAlign: "center" }} span={8}>
                      <span>
                        <AiOutlineCheck />
                        Renew Lease
                      </span>
                    </Col>
                  </Row>
                  <Row
                    gutter={[20, 20]}
                    style={{ marginTop: "30px" }}
                    justify="center"
                  >
                    <Col style={{ textAlign: "center" }} span={24}>
                      <StyledButton padding="15px 35px" marginLeft="0">
                        set up rental payment
                      </StyledButton>
                    </Col>
                    <Col style={{ textAlign: "center" }} span={24}>
                      <h5>Not right now</h5>
                    </Col>
                  </Row>
                </RentalPayment>
              </WelcomeHomeWrap>
            ) : (
              ""
            )}
          </ApplicationsRight>
        </ApplicationsMain>
        <Footer />
      </Container>
    </>
  );
}

export default Applications;

export const NoApplicationWrap = styled.div`
  width: 100%;
  margin-top: 100px;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #455a64;
    margin-bottom: 0;
    margin-top: 40px;
  }

  svg {
    display: flex;
    margin: auto;
  }

  @media (max-width: 1550px) {
    margin-top: 80px;

    p {
      margin-top: 30px;
    }
  }
`;

export const ObjWrap = styled.div`
  display: flex;
  svg {
    cursor: pointer;
  }
`;

export const RentalPayment = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 20px;

  svg {
    margin-right: 10px;
  }
  h5 {
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 28px !important;
    color: #848687 !important;
    cursor: pointer;
  }
`;
export const WelcomeHomeWrap = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 50px;

  .ant-divider-horizontal {
    background: #eeeeee;
  }

  h2 {
    margin-top: 30px !important;
    font-weight: 700 !important;
    font-size: 40px !important;
    line-height: 49px !important;
    text-align: center !important;
    color: #2b353f !important;
    margin-bottom: 20px !important;
  }
  p {
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    text-align: center !important;
    color: #f67c36 !important;
  }
  span {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    text-align: center !important;
    color: #2b353f !important;
  }
`;

export const BottomWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin: 50px 0 90px 0;
  text-align: left;
  align-items: center;

  p {
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 28px !important;
    color: #848687 !important;
    margin-bottom: 0 !important;
    text-align: end;
    cursor: pointer;
  }
`;

export const PriceCode = styled.div`
  width: 50%;

  span {
    font-weight: 400 !important;
    font-size: 24px !important;
    line-height: 29px !important;
    color: #848687 !important;
  }
  p {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 29px !important;
    text-align: right !important;
    color: #2b353f !important;
    margin-bottom: 0 !important;
  }
`;

export const DiscountCode = styled.div`
  width: 50%;
  border-left: 0;

  .ant-btn {
    height: 50px;
    border-left: 0;
    color: #f67c36;
    background: #eeeeee;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: #f67c36;
    border-color: #d9d9d9;
    background: #eeeeee;
  }

  .ant-input:hover {
    border-right: none;
    border-color: #d9d9d9;
  }

  .ant-input {
    background: #eeeeee;
    border-right: none;
  }
  .ant-input-group.ant-input-group-compact > *:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .ant-input-group.ant-input-group-compact > *:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .ant-input:focus,
  .ant-input-focused {
    border-color: #eeeeee;
    box-shadow: 0 0 0 2px #eeeeee;
    border-right-width: 0;
    outline: 0;
  }
`;

export const PriceComponent = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;

  .ant-divider-vertical {
    background: #dddddd;
    margin: 0 30px;
    height: auto;
  }
`;

export const ApplicationsCards = styled.div`
  width: 100%;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.4s;

  :hover {
    -ms-transform: scale(0.98); /* IE 9 */
    -webkit-transform: scale(0.98); /* Safari 3-8 */
    transform: scale(0.98);
  }
  img {
    width: 150px;
    height: 150px;
  }
`;

export const CheckoutWrap = styled.div`
  margin-top: 50px;

  p {
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: #2b353f;
    margin-bottom: 20px;
  }
`;

export const StyledButton = styled(Button)`
  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.secondary}90;
    background: ${({ theme }) => theme.colors.secondary}90;
  }

  span {
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    text-transform: uppercase !important;
    color: #ffffff !important;
  }

  margin-left: ${({ marginLeft }) => marginLeft || "30px"};

  padding: ${({ padding }) => padding || "4px 65px"};
  height: ${({ hg }) => hg || "50px"};
  border-radius: 5px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.secondary};
`;

export const AgreeSignRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || ""};
  width: ${({ width }) => width || ""};

  h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #848687;
    margin-bottom: 0;
    cursor: pointer;
  }

  .ant-btn[disabled] {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: ${({ theme }) => theme.colors.secondary};
  }
`;

export const AgreeSign = styled.div`
  display: ${({ display }) => display || "flex"};
  justify-content: space-between;
  align-items: center;

  img {
    width: 100px;
  }
  .ant-form-item {
    margin-bottom: auto;
  }

  canvas {
    width: 80%;

    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  .ant-checkbox {
    background: #ffffff;
    border: 1px solid #2b353f;
    border-radius: 3px;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
  }
  h5 {
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #777777;
  }
`;

export const LeasingAgreement = styled.div`
  margin-top: 50px;

  .ql-clipboard {
    display: none;
  }

  h1 {
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: #2b353f;
    margin-bottom: 20px;
  }
  h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #848687;
    margin-bottom: 0px;
  }
`;

export const StatusMain = styled.div`
  margin-top: 100px;
  text-align: center;
  h5 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #2b353f;
    margin-bottom: 35px;
  }
  h4 {
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    color: #2b353f;
    margin-bottom: 25px;
  }
  h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #f67c36;
    margin-bottom: 15px;
  }
  svg {
    margin-bottom: 35px;
  }

  @media (max-width: 1550px) {
    margin-top: 80px;

    h5 {
      font-size: 16px;
      line-height: 17px;
      margin-bottom: 28px;
    }
    h4 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 24px;
      line-height: 19px;
      margin-bottom: 12px;
    }
    svg {
      margin-bottom: 28px;
    }
  }
`;

export const ApplicationsRight = styled.div`
  width: 70%;
  padding: 0 50px;

  h2 {
    font-weight: 700;
    font-size: 60px;
    line-height: 73px;
    color: #2b353f;
    margin-bottom: 50px;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #dee8ff;
    border-color: #dee8ff;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #4160ac;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #4160ac;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #4160ac;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #eeeeee;
    border-color: #eeeeee;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon
    .ant-steps-icon {
    color: #2b353f;
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #eeeeee;
    border-color: #eeeeee;
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon,
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #2b353f;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after,
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #ddd;
  }

  @media (max-width: 1550px) {
    h2 {
      font-size: 44px;
      line-height: 58px;
      margin-bottom: 35px;
    }
  }
`;

export const BedBathBox = styled.div`
  background: #f9f9f9;
  padding: 0 12px;
  border-radius: 0 0 5px 5px;
  .ant-row {
    align-items: center;
  }

  .card__bottom p,
  span {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 0.05em;
    color: #848687;
  }

  .card__bottom {
    display: flex;
    align-items: center;
    svg {
      margin-top: 10px;
      margin-right: 10px;
    }
  }
`;

export const CardDetails = styled.div`
  padding: 10px 15px;
  width: ${({ cardWidth }) => cardWidth || ""};
  h2 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #4160ac;
    margin-bottom: 10px;
  }
  .card__bottom p,
  span {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 0;
    letter-spacing: 0.05em;
    color: #848687;
  }

  .card__bottom {
    display: flex;
    align-items: center;
    margin-right: 30px;
    svg {
      margin-right: 10px;
    }
  }
`;

export const ApplicationsCardTop = styled.div`
  padding: 5px;
  display: flex;
  border-radius: 5px 5px 0 0;
  img {
    width: 100px;
    height: 100px;
  }
`;

export const ApplicationsCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.4s;
  border: 1px solid ${({ borderColor }) => borderColor || "#dddddd"};
  border-radius: 5px;

  img {
    width: ${({ imgWidth }) => imgWidth || ""};
    height: ${({ imgWidth }) => imgWidth || ""};
  }

  /* :hover {
    -ms-transform: scale(0.98);
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
  */
`;

export const ApplicationsCardWrap = styled.div`
  margin-top: 15px;
`;

export const ApplicationsLeft = styled.div`
  width: 30%;
  ${"" /* border: 1px solid blue; */}
  padding: 20px;
  max-height: 777px;
  overflow: auto;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  /* scroll bar */
  ::-webkit-scrollbar,
  .ant-modal-wrap::-webkit-scrollbar {
    width: 0.5em;

    @media (max-width: 767px) {
      width: 0 !important;
      display: none !important;
    }
  }

  ::-webkit-scrollbar-track,
  .ant-modal-wrap::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

    @media (max-width: 767px) {
      box-shadow: none;
    }
  }

  ::-webkit-scrollbar-thumb,
  .ant-modal-wrap::-webkit-scrollbar-thumb {
    background-color: rgb(97, 96, 96);
    outline: 1px solid rgb(112, 128, 144);
    border-radius: 10px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .ant-modal-wrap::-webkit-scrollbar-thumb {
    display: none;
  }
`;

export const ApplicationsMain = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 40px;
`;
