import React from "react";

const FooterCall = ({
  wSize = "15",
  hSize = "15",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6.5C4.16667 8.83333 6.16667 10.75 8.5 12L10.3333 10.1667C10.5833 9.91667 10.9167 9.83333 11.1667 10C12.0833 10.3333 13.0833 10.5 14.1667 10.5C14.6667 10.5 15 10.8333 15 11.3333V14.1667C15 14.6667 14.6667 15 14.1667 15C6.33333 15 0 8.66667 0 0.833333C0 0.333333 0.333333 0 0.833333 0H3.75C4.25 0 4.58333 0.333333 4.58333 0.833333C4.58333 1.83333 4.75 2.83333 5.08333 3.83333C5.16667 4.08333 5.08333 4.41667 4.91667 4.66667L3 6.5Z"
        fill="#848687"
      />
    </svg>
  );
};

export default FooterCall;
