import React, { useEffect } from "react";
import { usePlaidLink } from "react-plaid-link";
import { useDispatch } from "react-redux";
import { exchangePublicToken } from "../store/reducers/user.reducer";

/**
 * Launches Link, calling props.successCallback when complete. This could have
 * been combined with the LinkLoader, but it got a bit unweildy
 */
function LaunchLink(props) {
  const dispatch = useDispatch();

  // define onSuccess, onExit and onEvent functions as configs for Plaid Link creation
  const onSuccess = async (publicToken, metadata) => {
    dispatch(
      exchangePublicToken({
        public_token: publicToken,
      })
    ).then((res) => {
    });
    // console.log(`Hooray! Public token is ${publicToken}`);
    // console.log(metadata);
    props.successCallback(publicToken);
  };

  const onExit = async (error, metadata) => {
    // console.log(`Awww...${JSON.stringify(error)}`);
    // console.log(metadata);
  };

  const onEvent = async (eventName, metadata) => {
    // console.log(`Event: ${eventName}, Metadata: ${metadata}`);
  };

  const config = {
    onSuccess,
    onExit,
    onEvent,
    token: props.token,
  };

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open, props.token]);

  return <></>;
}

export default LaunchLink;
