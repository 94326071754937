import React from "react";

const settingSvg = ({ wSize = "40", hSize = "40" }) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox={`0 0 ${wSize} ${hSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.1934 7.99093V2"
        stroke="#F67C36"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.1934 38.0005V17.6484"
        stroke="#F67C36"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.1943 17.6489C30.5251 17.6489 28.3613 15.4872 28.3613 12.8206C28.3613 10.1539 30.5251 7.99219 33.1943 7.99219C35.8635 7.99219 38.0273 10.1539 38.0273 12.8206C38.0273 15.4872 35.8635 17.6489 33.1943 17.6489Z"
        stroke="#F67C36"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="bevel"
      />
      <path
        d="M20.0156 21.6649L20.0156 2"
        stroke="#F67C36"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.0156 38.0023V31.3242"
        stroke="#F67C36"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.0166 31.3247C17.3474 31.3247 15.1836 29.163 15.1836 26.4964C15.1836 23.8297 17.3474 21.668 20.0166 21.668C22.6858 21.668 24.8496 23.8297 24.8496 26.4964C24.8496 29.163 22.6858 31.3247 20.0166 31.3247Z"
        stroke="#F67C36"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="bevel"
      />
      <path
        d="M6.83203 12.2309V2"
        stroke="#F67C36"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.83203 37.9989V21.8867"
        stroke="#F67C36"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.83302 21.8872C4.16382 21.8872 2.00002 19.7255 2.00002 17.0589C2.00002 14.3922 4.16382 12.2305 6.83302 12.2305C9.50222 12.2305 11.666 14.3922 11.666 17.0589C11.666 19.7255 9.50222 21.8872 6.83302 21.8872Z"
        stroke="#F67C36"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="bevel"
      />
    </svg>
  );
};

export default settingSvg;
