import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { FaHeart } from "react-icons/fa";
import { Row, Col, Button, Spin, Skeleton, message } from "antd";
import { StyledApparCard, CardDiscriptionWrap } from "./CardStyle";
import { AvenewImg, BathSvg, BedSvg, Sqft } from "../../../assets/svgs";
import StarRatings from "react-star-ratings";
import { Link, useLocation, useParams } from "react-router-dom";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import { addOrRemoveFavorites, me } from "../../../store/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "../../container/Container";
import { httpAPI } from "../../../api";

function CardSection({ cardDummyData, allUnits }) {
  const location = useLocation();
  const { width } = useWindowSize();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [allUnitsAdd, setAllUnitsAdd] = useState([]);
  const [favoriteUserUnits, setFavoriteUserUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isButton, setIsButton] = useState(true);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 8,
  });
  useEffect(() => {
    if (allUnits) {
      setAllUnitsAdd(allUnits);
    }
  }, [allUnits]);
  useEffect(() => {
    setIsButton(true);
  }, []);

  const getTeams = useCallback((paginations) => {
    setLoading(true);
    httpAPI(
      `/units?pageSize=${paginations.pageSize}&pageNumber=${paginations.pageNumber}`,
      "",
      "GET"
    )
      .then(({ data }) => {
        setLoading(false);
        setAllUnitsAdd((val) => [...val, ...data]);
        if (data?.length > 7) {
        } else {
          setIsButton(false);
        }
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      })
      .finally(() => {});
  }, []);

  const [showData, setShowData] = useState(8);
  const [addApartment, setAddApartment] = useState(4);

  useEffect(() => {
    if (width < 1200) {
      setShowData(6);
      setAddApartment(3);
    } else if (width < 769) {
      setShowData(4);
    } else {
      setShowData(8);
      setAddApartment(4);
    }
  }, [width, location.pathname]);

  useEffect(() => {
    if (width < 992) {
      setShowData(4);
      setAddApartment(2);
    }
  }, [width, location.pathname]);

  const toIndianCurrency = (num) => {
    const curr = num.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return curr;
  };

  const slpitFunc = (value) => {
    if (value.length > 15) {
      return value.slice(0, 15) + "...";
    } else {
      return value;
    }
  };

  const upperCase = (value) => {
    if (value) {
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return slpitFunc(str2);
    }
  };

  const paginationFun = () => {
    let newPagination = { ...pagination };
    setPagination((p) => ({ ...p, pageNumber: pagination.pageNumber + 1 }));
    newPagination.pageNumber = pagination.pageNumber + 1;
    getTeams(newPagination);
  };

  useEffect(() => {
    if (user?.favoriteUnits) {
      setFavoriteUserUnits(user.favoriteUnits);
    }
  }, [user]);

  const getFavorites = (unitId) => {
    var value = {};
    value.unit_id = unitId;
    value.user_id = user?.id;
    if (favoriteUserUnits?.length) {
      if (favoriteUserUnits.includes(unitId)) {
        value.isFavorite = false;
        const newFav = favoriteUserUnits.filter(function (ele) {
          return ele != unitId;
        });
        setFavoriteUserUnits([...newFav]);
      } else {
        let arr = [];
        arr.push(unitId);
        setFavoriteUserUnits((val) => [...val, ...arr]);
        value.isFavorite = true;
      }
    } else {
      let arr = [];
      arr.push(unitId);
      setFavoriteUserUnits((val) => [...val, ...arr]);
      value.isFavorite = true;
    }
    dispatch(addOrRemoveFavorites(value));
  };

  return (
    <>
      <h1> Top Featured Properties </h1>
      {[...allUnitsAdd]?.length ? (
        <>
          <ApartCard>
            <Row gutter={[30, 30]}>
              {allUnitsAdd.map((items, _id) => (
                <Col key={_id} xs={24} sm={12} md={12} lg={8} xl={6} xxl={6}>
                  <Link to={`/property-details/${items.id}`}>
                    <StyledApparCard hoverable>
                      <ImgWrap>
                        {items.images && items.images?.length ? (
                          <>
                            {items.images.map((img, idx) => (
                              <div key={idx}>
                                {img.isCover === true ? (
                                  <img alt="example" src={img.path} />
                                ) : (
                                  ""
                                )}
                              </div>
                            ))}
                          </>
                        ) : (
                          <AvenewImg
                            className="svg__border"
                            style={{ background: "#eeeeee", height: "175px" }}
                          />
                        )}
                        <div className="top-left">NEW</div>
                        <div
                          className="top-right"
                          onClick={(e) => {
                            e.preventDefault();
                            getFavorites(items.id);
                          }}
                        >
                          {favoriteUserUnits?.includes(items.id) ? (
                            <FaHeart style={{ color: "#f67c36" }} />
                          ) : (
                            <FaHeart />
                          )}
                        </div>
                        {items.images && items.images?.length ? (
                          <div className="bottom-right">
                            <p>{items.images.length} Photos</p>
                          </div>
                        ) : (
                          <div className="bottom-right">
                            <p>0 Photo</p>
                          </div>
                        )}
                      </ImgWrap>
                      <CardDiscriptionWrap>
                        {/* <div className="rating__star">
                          <StarRatings
                            rating={3}
                            starRatedColor="#F67C36"
                            starDimension="20px"
                            starSpacing="5px"
                            // changeRating={this.changeRating}
                            numberOfStars={5}
                            name="rating"
                          />
                        </div> */}
                        <p className="card__title">
                          {upperCase(items.unit_name)}
                        </p>
                        <p className="card__price">
                          {toIndianCurrency(Number(items.price))}
                        </p>

                        <Row justify="space-between">
                          <Col className="card__bottom">
                            <p>
                              <BedSvg className="icon" />
                            </p>
                            <span>Bed : {items.bed}</span>
                          </Col>
                          <Col className="card__bottom">
                            <p>
                              <BathSvg />
                            </p>
                            <span>Bath : {items.bath}</span>
                          </Col>
                          <Col className="card__bottom">
                            <p>
                              <Sqft />
                            </p>
                            <span>Sq ft : {items.sqft}</span>
                          </Col>
                        </Row>
                      </CardDiscriptionWrap>
                    </StyledApparCard>
                  </Link>
                </Col>
              ))}
            </Row>
          </ApartCard>

          {loading ? (
            <Container>
              <div
                style={{
                  position: "relative",
                  minHeight: "50px",
                  marginTop: "50px",
                }}
              >
                <Spin className="ant__spin" size="large" />
              </div>
            </Container>
          ) : (
            ""
          )}

          {isButton ? (
            <ViewMore>
              <Button
                onClick={() => {
                  paginationFun();

                  setShowData(showData + addApartment);
                }}
              >
                View Some More Property
              </Button>
            </ViewMore>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default CardSection;

export const ApartCard = styled.div`
  width: 100%;
`;

export const ImgWrap = styled.div`
  width: 100%;
  position: relative;

  svg {
    width: 100%;
    height: auto;
  }
  .svg__border {
    border-radius: 10px 10px 0 0;
  }

  img {
    width: 100%;
    border-radius: 10px 10px 0 0;
  }

  .top-left {
    position: absolute;
    top: 8px;
    left: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    background: #f67c36;
    border-radius: 5px;
    text-align: center;
    padding: 4px 10px;
  }
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
    svg {
      color: rgba(0, 0, 0, 0.5);
      font-size: 28px;
    }
  }
  .bottom-right {
    padding: 8px 20px;
    position: absolute;
    bottom: 10px;
    right: 16px;
    line-height: 15px;
    background: #00000090;
    border-radius: 3px;
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: #ffffff;
    }
  }
`;

export const ViewMore = styled.div`
  justify-content: center;
  text-align: center;
  padding-top: 50px;
  .ant-btn {
    height: 40px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #f67c36;
    background: #ffffff;
    border-radius: 3px;
    border: none;
  }

  @media (max-width: 1550px) {
    padding-top: 40px;
    .ant-btn {
      letter-spacing: 0.5px;
      font-size: 14px;
      line-height: 15px;
    }
  }
`;
