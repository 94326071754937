import { Col, Divider, Row } from "antd";
import React from "react";
import styled from "styled-components";
import {
  HappySvg,
  HomeDollarSvg,
  HomeSvg,
  SupportSvg,
} from "../../../assets/svgs";

const MarketingSection = () => {
  return (
    <Container>
      <MarketingWrap>
        <Row justify="space-between" gutter={[20, 20]}>
          <Col xs={10} sm={10} md={10} lg={6} xl={6} xxl={6}>
            <Row gutter={[20, 20]}>
              <Col>
                <HomeSvg />
              </Col>
              <Col>
                <Row>
                  <span className="market_statistics">12K+</span>
                </Row>
                <Row>
                  <span className="statistics_category">Property Listed</span>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Row gutter={[20, 20]}>
              <Col>
                <HappySvg />
              </Col>
              <Col>
                <Row>
                  <span className="market_statistics">10M+</span>
                </Row>
                <Row>
                  <span className="statistics_category">Happy Customers</span>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Row gutter={[20, 20]}>
              <Col>
                <HomeDollarSvg />
              </Col>
              <Col>
                <Row>
                  <span className="market_statistics">2K+</span>
                </Row>
                <Row>
                  <span className="statistics_category">Property Owners</span>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Row gutter={[20, 20]}>
              <Col>
                <SupportSvg />
              </Col>
              <Col>
                <Row>
                  <span className="market_statistics">24x7</span>
                </Row>
                <Row>
                  <span className="statistics_category">Online Support</span>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
      </MarketingWrap>
    </Container>
  );
};

export default MarketingSection;

export const Container = styled.div`
  width: 100%;
  margin-top: 150px;

  @media (max-width: 1550px) {
    margin-top: 120px;
  }

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
const MarketingWrap = styled.div`
  max-width: 1299px;
  margin: 0 auto;

  .market_statistics {
    ${"" /* margin-left: 20px; */}
    color: #2b353f;
    font-size: 50px;
    font-weight: 700;
    line-height: 61px;
  }
  .statistics_category {
    ${"" /* margin-left: 20px; */}
    ${"" /* padding-top: 10px; */}
    color: #000;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
  }
  .ant-divider {
    margin-top: 100px;
  }
`;
