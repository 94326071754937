import React from "react";

const attachmentSvg = ({
  color = "#666666",
  width = "21",
  height = "26",
  fill = "#ffffff",
  stroke = "#4160AC",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.333 8.25397V8.13016L15.3599 2.74444L14.1425 3.85873L18.1869 8.25397H12.079V0H0.523438V26H20.333V8.25397ZM18.6822 24.3492H2.17423V1.65079H10.4282V9.90476H18.6822V24.3492Z"
        fill="#2B353F"
      />
      <path
        d="M14.5587 14.2344H6.30469V15.8852H14.5587V14.2344Z"
        fill="#2B353F"
      />
      <path
        d="M14.5587 18.5703H6.30469V20.2211H14.5587V18.5703Z"
        fill="#2B353F"
      />
    </svg>
  );
};

export default attachmentSvg;
