import { Button, Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import { Container } from "../container/Container";

function ConnectingBuyersAndSellers() {
  return (
    <Container paddings="0">
      <BuyerSellerWrap>
        <h2>Connecting buyers and sellers</h2>
        <p>
          Explore how you could save your time, efforts and thousands on
          commission while dealing with buying or selling a property.
        </p>
        <Row gutter={[20, 20]} justify="center">
          <Col xs={24} sm={8} md={8} lg={6} xl={4} xxl={4}>
            <FindButton>Find Property</FindButton>
          </Col>
          <Col xs={24} sm={8} md={8} lg={6} xl={4} xxl={4}>
            <PropertyButton>I’m Property Owner</PropertyButton>
          </Col>
        </Row>
        <ImageWrap>
          <img src="/images/connecting.png" alt="image" />
        </ImageWrap>
      </BuyerSellerWrap>
    </Container>
  );
}

export default ConnectingBuyersAndSellers;

export const BuyerSellerWrap = styled.div`
  text-align: center;
  padding: 150px 0 0;
  h2 {
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    letter-spacing: 0.05em;
    color: ${({ theme }) => theme.colors.forth};
    margin-bottom: 30px;
  }
  p {
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #848687;
    margin-bottom: 50px;
  }

  @media (max-width: 1550px) {
    padding: 100px 0 0;
    h2 {
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 24px;
    }
    p {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 40px;
    }
  }

  @media (max-width: 1440px) {
    padding: 100px 30px 0 30px;
  }

  @media (max-width: 769px) {
    padding: 40px 30px 0 30px;
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0.05em;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.05em;
      margin-bottom: 25px;
    }
  }
`;
export const FindButton = styled(Button)`
  width: 100%;
  height: 50px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.third};
  border-color: ${({ theme }) => theme.colors.third};
  text-transform: uppercase;
  ${"" /* padding: 4px 30px; */}

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
  }

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.third}90;
    background: ${({ theme }) => theme.colors.third}90;
  }
  @media (max-width: 1550px) {
    height: 40px;
    padding: 4px 0px;

    span {
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.5px;
    }
  }
`;
export const PropertyButton = styled(Button)`
  width: 100%;
  height: 50px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.secondary};
  border-color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  ${"" /* padding: 4px 35px; */}

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
  }

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.secondary}90;
    background: ${({ theme }) => theme.colors.secondary}90;
  }
  @media (max-width: 1550px) {
    height: 40px;
    padding: 4px 0;

    span {
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.5px;
    }
  }
`;
export const ImageWrap = styled.div`
  width: 100%;
  margin-top: -50px;
  display: flex;
  flex-flow: column;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: fixed;
`;
