import React from "react";

const depositSvg = ({
  wSize = "14",
  hSize = "16",
  bgColor = "#ffffff",
  color = "none",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox={`0 0 14 16`}
      {...props}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M13.1774 8.15885C12.9447 6.49103 11.8815 5.05869 10.3754 4.2117C9.82501 4.87145 8.99694 5.29195 8.07083 5.29195C6.84999 5.29195 5.80189 4.56159 5.33348 3.51507L3.55723 2.29297V4.51431L3.56669 4.51494C2.60149 5.18194 1.86357 6.10395 1.48247 7.1738H0V10.1141H1.35985C1.71195 11.4551 2.60559 12.6063 3.82579 13.3593V15.0867H6.07519V14.2038C6.44336 14.2671 6.82225 14.3034 7.21092 14.3034C7.63835 14.3034 8.05444 14.2615 8.45728 14.1852V15.0864H10.7064V13.2843C12.117 12.3702 13.0711 10.9202 13.2086 9.26085H13.9228V8.15822H13.1774V8.15885ZM4.09436 7.07072C3.7492 7.07072 3.4696 6.79081 3.4696 6.44627C3.4696 6.10111 3.74951 5.82151 4.09436 5.82151C4.43921 5.82151 4.71881 6.10143 4.71881 6.44627C4.71881 6.79112 4.43921 7.07072 4.09436 7.07072Z"
          fill="#848687"
        />
        <path
          d="M8.05622 4.58673C9.32276 4.58673 10.3494 3.55975 10.3494 2.29321C10.3494 1.02666 9.32245 0 8.05622 0C6.78936 0 5.7627 1.02666 5.7627 2.29321C5.7627 3.55975 6.78936 4.58673 8.05622 4.58673ZM7.89987 2.49621C7.44659 2.34396 7.13799 2.13245 7.13799 1.72172C7.13799 1.34945 7.40057 1.05755 7.85322 0.968663V0.566761H8.26741V0.939348C8.55111 0.952272 8.7415 1.01153 8.88114 1.07899L8.75852 1.55717C8.6482 1.51084 8.45371 1.41312 8.14889 1.41312C7.87371 1.41312 7.78513 1.53164 7.78513 1.65016C7.78513 1.78949 7.93297 1.8787 8.29295 2.01392C8.79603 2.19171 8.99966 2.42465 8.99966 2.80544C8.99966 3.18212 8.73236 3.50333 8.24598 3.58812V4.01997H7.83115V3.61807C7.54809 3.60514 7.2729 3.52918 7.11183 3.43587L7.23886 2.94098C7.41664 3.03807 7.66598 3.12696 7.94116 3.12696C8.18262 3.12696 8.34748 3.03397 8.34748 2.8647C8.34779 2.70362 8.21257 2.60212 7.89987 2.49621Z"
          fill="#848687"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={wSize} height={hSize} fill={bgColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default depositSvg;
