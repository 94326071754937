import React from "react";

const targetSvg = ({ wSize = "42", hSize = "42" }) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox={`0 0 ${wSize} ${hSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.6844 19.6875C36.372 15.9805 34.7575 12.5036 32.1269 9.87307C29.4964 7.2425 26.0195 5.628 22.3125 5.31563V0H19.6875V5.31563C15.9805 5.628 12.5036 7.2425 9.87307 9.87307C7.2425 12.5036 5.628 15.9805 5.31563 19.6875H0V22.3125H5.31563C5.628 26.0195 7.2425 29.4964 9.87307 32.1269C12.5036 34.7575 15.9805 36.372 19.6875 36.6844V42H22.3125V36.6844C26.0195 36.372 29.4964 34.7575 32.1269 32.1269C34.7575 29.4964 36.372 26.0195 36.6844 22.3125H42V19.6875H36.6844ZM34.0594 22.3125C33.7566 25.3249 32.4218 28.1401 30.2809 30.2809C28.1401 32.4218 25.3249 33.7566 22.3125 34.0594V28.875H19.6875V34.0594C16.6751 33.7566 13.8599 32.4218 11.7191 30.2809C9.57823 28.1401 8.24335 25.3249 7.94063 22.3125H13.125V19.6875H7.94063C8.24335 16.6751 9.57823 13.8599 11.7191 11.7191C13.8599 9.57823 16.6751 8.24335 19.6875 7.94063V13.125H22.3125V7.94063C25.3249 8.24335 28.1401 9.57823 30.2809 11.7191C32.4218 13.8599 33.7566 16.6751 34.0594 19.6875H28.875V22.3125H34.0594Z"
        fill="#F67C36"
      />
      <path
        d="M21 23.625C22.4497 23.625 23.625 22.4497 23.625 21C23.625 19.5503 22.4497 18.375 21 18.375C19.5503 18.375 18.375 19.5503 18.375 21C18.375 22.4497 19.5503 23.625 21 23.625Z"
        fill="#F67C36"
      />
    </svg>
  );
};

export default targetSvg;
