import React from "react";

const twitterSvg = ({
  wSize = "30",
  hSize = "25",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox={`0 0 30 30`}
      {...props}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M9.43441 24.3765C20.7552 24.3765 26.9472 14.9974 26.9472 6.86373C26.9472 6.59736 26.9472 6.33217 26.9292 6.06814C28.1338 5.1968 29.1737 4.11803 30 2.88217C28.8767 3.37992 27.685 3.70629 26.4648 3.85055C27.7494 3.08156 28.7115 1.87113 29.1708 0.446133C27.9626 1.16303 26.6407 1.66834 25.2624 1.94016C24.0986 0.702598 22.4732 0 20.7745 0C17.3947 0 14.6137 2.78109 14.6137 6.16078C14.6137 6.62965 14.6672 7.09705 14.7732 7.55373C9.82682 7.30582 5.21186 4.96617 2.08799 1.12295C0.463535 3.91939 1.30406 7.54342 3.99357 9.33932C3.01418 9.31031 2.05582 9.04605 1.2 8.56893V8.64691C1.20082 11.5657 3.27697 14.1026 6.13799 14.6805C5.23189 14.9276 4.28098 14.9637 3.35883 14.7861C4.16314 17.2872 6.48252 19.0112 9.10922 19.0605C6.93012 20.7731 4.23674 21.7033 1.4652 21.7005C0.975469 21.6996 0.486211 21.67 0 21.6117C2.81449 23.4179 6.09023 24.3762 9.43441 24.3717"
          fill="#1DA1F2"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={wSize} height={hSize} fill={bgColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default twitterSvg;
