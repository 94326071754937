import React from "react";

const accountDone = ({
  wSize = "49",
  hSize = "50",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox="0 0 49 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1827 3.49825C22.9626 1.50058 26.0373 1.50058 27.8172 3.49825L30.1059 6.06716C31.0052 7.07678 32.298 7.62416 33.6326 7.56041L37.0279 7.39822C39.6681 7.27207 41.8426 9.4947 41.7192 12.1937L41.5603 15.6645C41.498 17.0285 42.0335 18.3501 43.0212 19.2695L45.5342 21.609C47.4886 23.4284 47.4886 26.5715 45.5342 28.3909L43.0212 30.7304C42.0335 31.6498 41.498 32.9713 41.5603 34.3355L41.7192 37.8063C41.8426 40.5052 39.6681 42.7279 37.0279 42.6018L33.6326 42.4395C32.298 42.3757 31.0052 42.9231 30.1059 43.9328L27.8172 46.5016C26.0373 48.4995 22.9626 48.4995 21.1827 46.5016L18.8941 43.9328C17.9946 42.9231 16.7018 42.3757 15.3674 42.4395L11.9721 42.6018C9.33177 42.7279 7.15746 40.5052 7.28086 37.8063L7.43954 34.3355C7.5019 32.9713 6.96641 31.6498 5.97874 30.7304L3.46568 28.3909C1.51144 26.5715 1.51144 23.4284 3.46568 21.609L5.97874 19.2695C6.96641 18.3501 7.5019 17.0285 7.43954 15.6645L7.28086 12.1937C7.15746 9.4947 9.33177 7.27207 11.9721 7.39822L15.3674 7.56041C16.7018 7.62416 17.9946 7.07678 18.8941 6.06716L21.1827 3.49825Z"
        stroke="#F67C36"
        strokeWidth="3"
      />
      <path
        d="M18 25L22.3333 29L31 21"
        stroke="#F67C36"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default accountDone;
