import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import styled from "styled-components";
import { OverviewTab, OverviewTabWrap } from "./tabs/Overview";
import { ApplyWrap, StyledButton } from "./PropertyDetails";
import ApplyForPropertyModal from "./apply-for-property-modal/ApplyForPropertyModal";
import { useSelector } from "react-redux";

function NearByLocation({ clickToApply, isBtnLoading, clickToMessage }) {
  const NearLocation = [
    { id: 1, km: "0.8 Km", location: "Near Pogues Run Art and Nature Park" },
    { id: 2, km: "1.6 Km", location: "Near Dwight D. Eisenhower Hwy" },
    { id: 3, km: "1.3 Km", location: "Near Pull a Part" },
    { id: 4, km: "2.0 Km", location: "Near Luthe’s Granite & Marble Store" },
  ];

  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const [isProfileVerifyed, setIsProfileVerifyed] = useState(false);
  const { applicationProcessData } = useSelector((state) => state.user);

  useEffect(() => {
    if (applicationProcessData?.id) {
      if (
        applicationProcessData?.userProfile &&
        applicationProcessData?.stateProfile &&
        applicationProcessData?.rentalHistory &&
        applicationProcessData?.incomeAndEmployment &&
        applicationProcessData?.applicationPayment
      ) {
        setIsProfileVerifyed(true);
      } else {
        setIsProfileVerifyed(false);
      }
    } else {
      setIsProfileVerifyed(false);
    }
  }, [applicationProcessData]);

  return (
    <OverviewTab>
      <OverviewTabWrap>
        <h2>Near by Location</h2>
        <NearByLocationName>
          <Row>
            {NearLocation.map(({ km, location, id }) => (
              <Col key={id} xs={24} sm={12} md={24} lg={12} xl={12} xxl={12}>
                <h3>{km}</h3>
                <p>{location}</p>
              </Col>
            ))}
          </Row>
        </NearByLocationName>
      </OverviewTabWrap>
      <ApplyWrap ml={4} pr={2.8}>
        <h2>Interested in the property, wants to move forward with us?</h2>
        <StyledButton
          loading={isBtnLoading}
          onClick={() => {
            clickToMessage();
          }}
          bgColor={({ theme }) => theme.colors.secondary}
        >
          Message
        </StyledButton>
        <StyledButton
          bgColor={isProfileVerifyed ? "#F67C36" : "#FFE1D0"}
          fcolor={isProfileVerifyed ? "#ffffff" : "#F67C36"}
          onClick={() => {
            clickToApply();
          }}
        >
          {isProfileVerifyed ? "Apply  now" : "Want to apply?"}
        </StyledButton>
      </ApplyWrap>
      <ApplyForPropertyModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
      />
    </OverviewTab>
  );
}

export default NearByLocation;

export const NearByLocationName = styled.div`
  width: 100%;
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    ${"" /* text-align: justify; */}
    color: #848687;
  }
  h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #2b353f;
  }
`;
