import React from "react";

const headerSquareSvg = ({ wSize = "38", hSize = "38" }) => {
  return (
    <svg
      width="173"
      height="166"
      viewBox="0 0 173 166"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M161.91 5.5449C161.91 6.64158 162.235 7.71355 162.845 8.62541C163.454 9.53726 164.32 10.248 165.333 10.6677C166.346 11.0874 167.461 11.1971 168.537 10.9832C169.612 10.7692 170.6 10.2412 171.376 9.46574C172.151 8.69027 172.68 7.70222 172.893 6.62662C173.107 5.55101 172.998 4.43612 172.578 3.42292C172.158 2.40972 171.448 1.5437 170.536 0.934416C169.624 0.325134 168.552 0 167.455 0C165.985 0 164.574 0.584189 163.534 1.62406C162.494 2.66393 161.91 4.0743 161.91 5.5449Z"
        fill="#CEDBF6"
      />
      <path
        d="M161.91 31.0527C161.91 32.1494 162.235 33.2214 162.845 34.1333C163.454 35.0451 164.32 35.7558 165.333 36.1754C166.346 36.5951 167.461 36.705 168.537 36.491C169.612 36.2771 170.6 35.749 171.376 34.9735C172.151 34.198 172.68 33.21 172.893 32.1344C173.107 31.0588 172.998 29.9439 172.578 28.9307C172.158 27.9175 171.448 27.0516 170.536 26.4423C169.624 25.833 168.552 25.5078 167.455 25.5078C165.985 25.5078 164.574 26.092 163.534 27.1319C162.494 28.1717 161.91 29.5821 161.91 31.0527Z"
        fill="#CEDBF6"
      />
      <path
        d="M161.91 56.5605C161.91 57.6571 162.235 58.7292 162.845 59.6411C163.454 60.5529 164.32 61.2636 165.333 61.6833C166.346 62.103 167.461 62.2128 168.537 61.9988C169.612 61.7849 170.6 61.2568 171.376 60.4813C172.151 59.7058 172.68 58.7179 172.893 57.6423C173.107 56.5667 172.998 55.4518 172.578 54.4386C172.158 53.4254 171.448 52.5594 170.536 51.9501C169.624 51.3408 168.552 51.0156 167.455 51.0156C165.985 51.0156 164.574 51.5998 163.534 52.6397C162.494 53.6795 161.91 55.0899 161.91 56.5605Z"
        fill="#CEDBF6"
      />
      <path
        d="M161.91 82.6154C161.91 83.6024 162.235 84.5672 162.845 85.3879C163.454 86.2086 164.32 86.8482 165.333 87.2259C166.346 87.6036 167.461 87.7024 168.537 87.5099C169.612 87.3173 170.6 86.842 171.376 86.1441C172.151 85.4462 172.68 84.557 172.893 83.5889C173.107 82.6209 172.998 81.6175 172.578 80.7056C172.158 79.7937 171.448 79.0144 170.536 78.466C169.624 77.9177 168.552 77.625 167.455 77.625C165.985 77.625 164.574 78.1508 163.534 79.0866C162.494 80.0225 161.91 81.2918 161.91 82.6154Z"
        fill="#CEDBF6"
      />
      <path
        d="M161.91 108.123C161.91 109.11 162.235 110.075 162.845 110.896C163.454 111.716 164.32 112.356 165.333 112.734C166.346 113.111 167.461 113.21 168.537 113.018C169.612 112.825 170.6 112.35 171.376 111.652C172.151 110.954 172.68 110.065 172.893 109.097C173.107 108.129 172.998 107.125 172.578 106.213C172.158 105.302 171.448 104.522 170.536 103.974C169.624 103.425 168.552 103.133 167.455 103.133C165.985 103.133 164.574 103.659 163.534 104.594C162.494 105.53 161.91 106.8 161.91 108.123Z"
        fill="#CEDBF6"
      />
      <path
        d="M161.91 134.185C161.91 135.282 162.235 136.354 162.845 137.266C163.454 138.178 164.32 138.889 165.333 139.308C166.346 139.728 167.461 139.838 168.537 139.624C169.612 139.41 170.6 138.882 171.376 138.106C172.151 137.331 172.68 136.343 172.893 135.267C173.107 134.192 172.998 133.077 172.578 132.064C172.158 131.05 171.448 130.184 170.536 129.575C169.624 128.966 168.552 128.641 167.455 128.641C165.985 128.641 164.574 129.225 163.534 130.265C162.494 131.305 161.91 132.715 161.91 134.185Z"
        fill="#CEDBF6"
      />
      <path
        d="M161.91 159.693C161.91 160.79 162.235 161.862 162.845 162.774C163.454 163.686 164.32 164.396 165.333 164.816C166.346 165.236 167.461 165.346 168.537 165.132C169.612 164.918 170.6 164.39 171.376 163.614C172.151 162.839 172.68 161.851 172.893 160.775C173.107 159.699 172.998 158.585 172.578 157.571C172.158 156.558 171.448 155.692 170.536 155.083C169.624 154.474 168.552 154.148 167.455 154.148C165.985 154.148 164.574 154.733 163.534 155.772C162.494 156.812 161.91 158.223 161.91 159.693Z"
        fill="#CEDBF6"
      />
      <path
        d="M135.295 5.5449C135.295 6.64158 135.62 7.71355 136.23 8.62541C136.839 9.53726 137.705 10.248 138.718 10.6677C139.731 11.0874 140.846 11.1971 141.922 10.9832C142.997 10.7692 143.985 10.2412 144.761 9.46574C145.536 8.69027 146.064 7.70222 146.278 6.62662C146.492 5.55101 146.382 4.43612 145.963 3.42292C145.543 2.40972 144.832 1.5437 143.92 0.934416C143.009 0.325134 141.937 0 140.84 0C139.369 0 137.959 0.584189 136.919 1.62406C135.879 2.66393 135.295 4.0743 135.295 5.5449Z"
        fill="#CEDBF6"
      />
      <path
        d="M108.679 5.5449C108.679 6.64158 108.972 7.71355 109.52 8.62541C110.069 9.53726 110.848 10.248 111.76 10.6677C112.672 11.0874 113.675 11.1971 114.643 10.9832C115.611 10.7692 116.501 10.2412 117.198 9.46574C117.896 8.69027 118.372 7.70222 118.564 6.62662C118.757 5.55101 118.658 4.43612 118.28 3.42292C117.903 2.40972 117.263 1.5437 116.442 0.934416C115.622 0.325134 114.657 0 113.67 0C112.346 0 111.077 0.584189 110.141 1.62406C109.205 2.66393 108.679 4.0743 108.679 5.5449Z"
        fill="#CEDBF6"
      />
      <path
        d="M82.0642 5.5449C82.0642 6.64158 82.3568 7.71355 82.9051 8.62541C83.4535 9.53726 84.2329 10.248 85.1448 10.6677C86.0566 11.0874 87.0601 11.1971 88.0282 10.9832C88.9962 10.7692 89.8853 10.2412 90.5832 9.46574C91.2811 8.69027 91.7564 7.70222 91.949 6.62662C92.1416 5.55101 92.0428 4.43612 91.6651 3.42292C91.2874 2.40972 90.6477 1.5437 89.8271 0.934416C89.0064 0.325134 88.0416 0 87.0546 0C85.731 0 84.4616 0.584189 83.5257 1.62406C82.5898 2.66393 82.0642 4.0743 82.0642 5.5449Z"
        fill="#CEDBF6"
      />
      <path
        d="M54.3399 5.5449C54.3399 6.64158 54.6651 7.71355 55.2744 8.62541C55.8836 9.53726 56.7497 10.248 57.7629 10.6677C58.7761 11.0874 59.891 11.1971 60.9666 10.9832C62.0422 10.7692 63.0303 10.2412 63.8057 9.46574C64.5812 8.69027 65.1092 7.70222 65.3231 6.62662C65.5371 5.55101 65.4274 4.43612 65.0077 3.42292C64.588 2.40972 63.8773 1.5437 62.9654 0.934416C62.0536 0.325134 60.9815 0 59.8848 0C58.4142 0 57.004 0.584189 55.9641 1.62406C54.9242 2.66393 54.3399 4.0743 54.3399 5.5449Z"
        fill="#CEDBF6"
      />
      <path
        d="M27.7243 5.5449C27.7243 6.64158 28.0171 7.71355 28.5654 8.62541C29.1138 9.53726 29.893 10.248 30.8049 10.6677C31.7168 11.0874 32.7202 11.1971 33.6883 10.9832C34.6563 10.7692 35.5455 10.2412 36.2434 9.46574C36.9413 8.69027 37.4166 7.70222 37.6092 6.62662C37.8018 5.55101 37.7029 4.43612 37.3252 3.42292C36.9475 2.40972 36.3078 1.5437 35.4871 0.934416C34.6665 0.325134 33.7016 0 32.7147 0C31.3911 0 30.1219 0.584189 29.186 1.62406C28.2501 2.66393 27.7243 4.0743 27.7243 5.5449Z"
        fill="#CEDBF6"
      />
      <path
        d="M0.000100136 5.5449C0.000100136 6.64158 0.325399 7.71355 0.934672 8.62541C1.54395 9.53726 2.40996 10.248 3.42314 10.6677C4.43633 11.0874 5.55112 11.1971 6.62671 10.9832C7.7023 10.7692 8.69034 10.2412 9.4658 9.46574C10.2413 8.69027 10.7694 7.70222 10.9833 6.62662C11.1973 5.55101 11.0874 4.43612 10.6677 3.42292C10.2481 2.40972 9.53732 1.5437 8.62548 0.934416C7.71363 0.325134 6.64159 0 5.54492 0C4.07435 0 2.66408 0.584189 1.62422 1.62406C0.584366 2.66393 0.000100136 4.0743 0.000100136 5.5449Z"
        fill="#CEDBF6"
      />
      <path
        d="M135.295 31.0527C135.295 32.1494 135.62 33.2214 136.23 34.1333C136.839 35.0451 137.705 35.7558 138.718 36.1754C139.731 36.5951 140.846 36.705 141.922 36.491C142.997 36.2771 143.985 35.749 144.761 34.9735C145.536 34.198 146.064 33.21 146.278 32.1344C146.492 31.0588 146.382 29.9439 145.963 28.9307C145.543 27.9175 144.832 27.0516 143.92 26.4423C143.009 25.833 141.937 25.5078 140.84 25.5078C139.369 25.5078 137.959 26.092 136.919 27.1319C135.879 28.1717 135.295 29.5821 135.295 31.0527Z"
        fill="#CEDBF6"
      />
      <path
        d="M135.295 56.5605C135.295 57.6571 135.62 58.7292 136.23 59.6411C136.839 60.5529 137.705 61.2636 138.718 61.6833C139.731 62.103 140.846 62.2128 141.922 61.9988C142.997 61.7849 143.985 61.2568 144.761 60.4813C145.536 59.7058 146.064 58.7179 146.278 57.6423C146.492 56.5667 146.382 55.4518 145.963 54.4386C145.543 53.4254 144.832 52.5594 143.92 51.9501C143.009 51.3408 141.937 51.0156 140.84 51.0156C139.369 51.0156 137.959 51.5998 136.919 52.6397C135.879 53.6795 135.295 55.0899 135.295 56.5605Z"
        fill="#CEDBF6"
      />
      <path
        d="M135.295 82.6154C135.295 83.6024 135.62 84.5672 136.23 85.3879C136.839 86.2086 137.705 86.8482 138.718 87.2259C139.731 87.6036 140.846 87.7024 141.922 87.5099C142.997 87.3173 143.985 86.842 144.761 86.1441C145.536 85.4462 146.064 84.557 146.278 83.5889C146.492 82.6209 146.382 81.6175 145.963 80.7056C145.543 79.7937 144.832 79.0144 143.92 78.466C143.009 77.9177 141.937 77.625 140.84 77.625C139.369 77.625 137.959 78.1508 136.919 79.0866C135.879 80.0225 135.295 81.2918 135.295 82.6154Z"
        fill="#CEDBF6"
      />
      <path
        d="M135.295 108.123C135.295 109.11 135.62 110.075 136.23 110.896C136.839 111.716 137.705 112.356 138.718 112.734C139.731 113.111 140.846 113.21 141.922 113.018C142.997 112.825 143.985 112.35 144.761 111.652C145.536 110.954 146.064 110.065 146.278 109.097C146.492 108.129 146.382 107.125 145.963 106.213C145.543 105.302 144.832 104.522 143.92 103.974C143.009 103.425 141.937 103.133 140.84 103.133C139.369 103.133 137.959 103.659 136.919 104.594C135.879 105.53 135.295 106.8 135.295 108.123Z"
        fill="#CEDBF6"
      />
      <path
        d="M135.295 134.185C135.295 135.282 135.62 136.354 136.23 137.266C136.839 138.178 137.705 138.889 138.718 139.308C139.731 139.728 140.846 139.838 141.922 139.624C142.997 139.41 143.985 138.882 144.761 138.106C145.536 137.331 146.064 136.343 146.278 135.267C146.492 134.192 146.382 133.077 145.963 132.064C145.543 131.05 144.832 130.184 143.92 129.575C143.009 128.966 141.937 128.641 140.84 128.641C139.369 128.641 137.959 129.225 136.919 130.265C135.879 131.305 135.295 132.715 135.295 134.185Z"
        fill="#CEDBF6"
      />
      <path
        d="M108.679 31.0527C108.679 32.1494 108.972 33.2214 109.52 34.1333C110.069 35.0451 110.848 35.7558 111.76 36.1754C112.672 36.5951 113.675 36.705 114.643 36.491C115.611 36.2771 116.501 35.749 117.198 34.9735C117.896 34.198 118.372 33.21 118.564 32.1344C118.757 31.0588 118.658 29.9439 118.28 28.9307C117.903 27.9175 117.263 27.0516 116.442 26.4423C115.622 25.833 114.657 25.5078 113.67 25.5078C112.346 25.5078 111.077 26.092 110.141 27.1319C109.205 28.1717 108.679 29.5821 108.679 31.0527Z"
        fill="#CEDBF6"
      />
      <path
        d="M82.0642 31.0527C82.0642 32.1494 82.3568 33.2214 82.9051 34.1333C83.4535 35.0451 84.2329 35.7558 85.1448 36.1754C86.0566 36.5951 87.0601 36.705 88.0282 36.491C88.9962 36.2771 89.8853 35.749 90.5832 34.9735C91.2811 34.198 91.7564 33.21 91.949 32.1344C92.1416 31.0588 92.0428 29.9439 91.6651 28.9307C91.2874 27.9175 90.6477 27.0516 89.8271 26.4423C89.0064 25.833 88.0416 25.5078 87.0546 25.5078C85.731 25.5078 84.4616 26.092 83.5257 27.1319C82.5898 28.1717 82.0642 29.5821 82.0642 31.0527Z"
        fill="#CEDBF6"
      />
      <path
        d="M54.3399 31.0527C54.3399 32.1494 54.6651 33.2214 55.2744 34.1333C55.8836 35.0451 56.7497 35.7558 57.7629 36.1754C58.7761 36.5951 59.891 36.705 60.9666 36.491C62.0422 36.2771 63.0303 35.749 63.8057 34.9735C64.5812 34.198 65.1092 33.21 65.3231 32.1344C65.5371 31.0588 65.4274 29.9439 65.0077 28.9307C64.588 27.9175 63.8773 27.0516 62.9654 26.4423C62.0536 25.833 60.9815 25.5078 59.8848 25.5078C58.4142 25.5078 57.004 26.092 55.9641 27.1319C54.9242 28.1717 54.3399 29.5821 54.3399 31.0527Z"
        fill="#CEDBF6"
      />
      <path
        d="M27.7243 31.0527C27.7243 32.1494 28.0171 33.2214 28.5654 34.1333C29.1138 35.0451 29.893 35.7558 30.8049 36.1754C31.7168 36.5951 32.7202 36.705 33.6883 36.491C34.6563 36.2771 35.5455 35.749 36.2434 34.9735C36.9413 34.198 37.4166 33.21 37.6092 32.1344C37.8018 31.0588 37.7029 29.9439 37.3252 28.9307C36.9475 27.9175 36.3078 27.0516 35.4871 26.4423C34.6665 25.833 33.7016 25.5078 32.7147 25.5078C31.3911 25.5078 30.1219 26.092 29.186 27.1319C28.2501 28.1717 27.7243 29.5821 27.7243 31.0527Z"
        fill="#CEDBF6"
      />
      <path
        d="M108.679 56.5605C108.679 57.6571 108.972 58.7292 109.52 59.6411C110.069 60.5529 110.848 61.2636 111.76 61.6833C112.672 62.103 113.675 62.2128 114.643 61.9988C115.611 61.7849 116.501 61.2568 117.198 60.4813C117.896 59.7058 118.372 58.7179 118.564 57.6423C118.757 56.5667 118.658 55.4518 118.28 54.4386C117.903 53.4254 117.263 52.5594 116.442 51.9501C115.622 51.3408 114.657 51.0156 113.67 51.0156C112.346 51.0156 111.077 51.5998 110.141 52.6397C109.205 53.6795 108.679 55.0899 108.679 56.5605Z"
        fill="#CEDBF6"
      />
      <path
        d="M108.679 82.6154C108.679 83.6024 108.972 84.5672 109.52 85.3879C110.069 86.2086 110.848 86.8482 111.76 87.2259C112.672 87.6036 113.675 87.7024 114.643 87.5099C115.611 87.3173 116.501 86.842 117.198 86.1441C117.896 85.4462 118.372 84.557 118.564 83.5889C118.757 82.6209 118.658 81.6175 118.28 80.7056C117.903 79.7937 117.263 79.0144 116.442 78.466C115.622 77.9177 114.657 77.625 113.67 77.625C112.346 77.625 111.077 78.1508 110.141 79.0866C109.205 80.0225 108.679 81.2918 108.679 82.6154Z"
        fill="#CEDBF6"
      />
      <path
        d="M108.679 108.123C108.679 109.11 108.972 110.075 109.52 110.896C110.069 111.716 110.848 112.356 111.76 112.734C112.672 113.111 113.675 113.21 114.643 113.018C115.611 112.825 116.501 112.35 117.198 111.652C117.896 110.954 118.372 110.065 118.564 109.097C118.757 108.129 118.658 107.125 118.28 106.213C117.903 105.302 117.263 104.522 116.442 103.974C115.622 103.425 114.657 103.133 113.67 103.133C112.346 103.133 111.077 103.659 110.141 104.594C109.205 105.53 108.679 106.8 108.679 108.123Z"
        fill="#CEDBF6"
      />
      <path
        d="M87.0545 62.1054C84.2984 62.1054 82.0642 59.6228 82.0642 56.5605C82.0642 53.4981 84.2984 51.0156 87.0545 51.0156C89.8107 51.0156 92.0449 53.4981 92.0449 56.5605C92.0449 59.6228 89.8107 62.1054 87.0545 62.1054Z"
        fill="#CEDBF6"
      />
      <path
        d="M59.8848 62.1054C56.8225 62.1054 54.3399 59.6228 54.3399 56.5605C54.3399 53.4981 56.8225 51.0156 59.8848 51.0156C62.9472 51.0156 65.4297 53.4981 65.4297 56.5605C65.4297 59.6228 62.9472 62.1054 59.8848 62.1054Z"
        fill="#CEDBF6"
      />
    </svg>
  );
};

export default headerSquareSvg;
