import React from "react";

const accountingSvg = ({ wSize = "30", hSize = "35" }) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox={`0 0 ${wSize} ${hSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.227 17.7427C25.1015 17.6181 24.9527 17.5194 24.789 17.4523C24.6253 17.3852 24.4501 17.3509 24.2732 17.3516C24.0963 17.3522 23.9213 17.3877 23.7581 17.4559C23.595 17.5242 23.4468 17.6239 23.3222 17.7494C23.1976 17.8749 23.0989 18.0238 23.0318 18.1874C22.9647 18.3511 22.9304 18.5264 22.9311 18.7032C22.9317 18.8801 22.9671 19.0551 23.0354 19.2183C23.1037 19.3815 23.2034 19.5296 23.3289 19.6542C24.468 20.7812 25.3722 22.123 25.9891 23.6019C26.6061 25.0807 26.9236 26.6672 26.9232 28.2696C26.9232 29.9119 22.1982 32.3081 14.8078 32.3081C7.41739 32.3081 2.69239 29.9119 2.69239 28.2696C2.69025 26.6778 3.00183 25.1012 3.60931 23.6298C4.21679 22.1585 5.10826 20.8213 6.23277 19.6946C6.4835 19.4424 6.62423 19.1012 6.62423 18.7456C6.62423 18.39 6.4835 18.0488 6.23277 17.7965C5.98056 17.5458 5.63937 17.4051 5.28374 17.4051C4.9281 17.4051 4.58692 17.5458 4.3347 17.7965C2.95503 19.1685 1.86146 20.8007 1.11738 22.5985C0.373301 24.3963 -0.00647112 26.3239 8.34182e-05 28.2696C8.34182e-05 32.6446 7.63277 35.0004 14.8078 35.0004C21.9828 35.0004 29.6155 32.6446 29.6155 28.2696C29.6206 26.3113 29.2352 24.3716 28.4816 22.5641C27.7281 20.7566 26.6217 19.1175 25.227 17.7427Z"
        fill="#F67C36"
      />
      <path
        d="M14.8078 18.8462C16.6715 18.8462 18.4934 18.2935 20.043 17.2581C21.5926 16.2227 22.8004 14.751 23.5136 13.0291C24.2268 11.3073 24.4134 9.41263 24.0499 7.58473C23.6863 5.75683 22.7888 4.0778 21.471 2.75996C20.1531 1.44212 18.4741 0.544657 16.6462 0.181066C14.8183 -0.182525 12.9236 0.00408286 11.2018 0.717293C9.47994 1.4305 8.00826 2.63828 6.97284 4.1879C5.93742 5.73752 5.38477 7.55937 5.38477 9.42308C5.38477 11.9222 6.37755 14.319 8.14472 16.0862C9.91189 17.8534 12.3087 18.8462 14.8078 18.8462ZM14.8078 2.69231C16.1391 2.69231 17.4404 3.08706 18.5473 3.82665C19.6541 4.56624 20.5168 5.61744 21.0263 6.84733C21.5357 8.07721 21.669 9.43055 21.4093 10.7362C21.1496 12.0418 20.5085 13.2411 19.5672 14.1825C18.6259 15.1238 17.4266 15.7648 16.121 16.0245C14.8153 16.2842 13.462 16.1509 12.2321 15.6415C11.0022 15.1321 9.951 14.2694 9.21141 13.1625C8.47183 12.0556 8.07707 10.7543 8.07707 9.42308C8.07707 7.63797 8.78621 5.92597 10.0485 4.66371C11.3107 3.40144 13.0227 2.69231 14.8078 2.69231Z"
        fill="#F67C36"
      />
    </svg>
  );
};

export default accountingSvg;
