import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Input, message } from "antd";
import styled from "styled-components";
import { UserAvatar } from "../../ui/Avatar";
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "./ChangePassword";
import { useDispatch, useSelector } from "react-redux";
import { editProfile, me } from "../../store/reducers/user.reducer";
import { useBeforeunload } from "react-beforeunload";
import { FileUploadAvtar } from "../../ui/Form/FileUploadAvtar";
import { BsCurrencyDollar } from "react-icons/bs";

function Profile({ activeTab }) {
  const [form] = Form.useForm();

  const [isEdit, setisEdit] = useState(false);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [loadings, setloadings] = useState(false);
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [state, setState] = useState({});

  const editClickHandler = () => {
    setisEdit(true);
  };
  useEffect(() => {
    setisEdit(false);
  }, [activeTab]);

  useEffect(() => {
    if (isEdit && user) {
      form.setFieldsValue({
        firstName: user?.firstName,
        lastName: user?.lastName,
        phoneNumber: Number(user?.phoneNumber?.slice(2)),
        email: user?.email,
      });
    }
  }, [isEdit, user]);

  const beforeUpload = async (file) => {
    const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
      return false;
    }

    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    const convertImg = await convertToBase64(file);

    setState((s) => ({
      ...s,
      image: convertImg,
      path: URL.createObjectURL(file),
      name: file.name,
    }));

    // getBase64(file, (base64) =>
    //   setState((s) => ({
    //     ...s,
    //     image_src: base64,
    //     image: file,
    //   }))
    // );
    return false;
  };

  useBeforeunload((event) => {
    if (showPrompt) {
      event.preventDefault();
    }
  });

  useEffect(() => {
    setState((s) => ({
      ...s,
      path: user?.image?.path ? user?.image?.path : "",
    }));
  }, [user]);

  const onFinish = (value) => {
    setloadings(true);
    value.id = user.id;
    value.phoneNumber = `+1${value.phoneNumber}`;
    value.email = value.email.toLowerCase();
    value.firstName = value.firstName.toLowerCase();
    value.lastName = value.lastName.toLowerCase();
    if (Object.keys(state)?.length) {
      value.image = state;
    }
    dispatch(editProfile(value)).then(({ payload }) => {
      dispatch(me()).then(() => {
        setloadings(false);
        setisEdit(false);
      });
    });
  };

  const onFieldsChange = (changedFields) => {};

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
  };

  const upperCase = (value) => {
    if (value) {
      const arr = value.toLowerCase().split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2;
    }
  };

  return (
    <ProfileWrap>
      <AvatarWrap>
        {isEdit ? (
          <FileUploadAvtar
            uploadSize={"108px"}
            cameraIcon={"14px"}
            name="avatar"
            className="avatar-uploader"
            showUploadList={false}
            size="106"
            beforeUpload={beforeUpload}
            userIcon={"78px"}
            offset={[-6, 100]}
            image_src={state?.path}
            username={`${user?.firstName || ""} ${user?.lastName || ""}`}
            badgeSize={"35px"}
          />
        ) : (
          <UserAvatar
            className="userName__icon"
            size={108}
            src={state?.path}
            fullName={`${user?.firstName || ""} ${user?.lastName || ""}`}
          />
        )}
        {/* <UserAvatar
          className="userName__icon"
          fullName={"Mark Jecno"}
          src="/images/avatar.png"
          // fullName={`${firstName || ""} ${lastName || ""}`}
          // src={
          //   item?.user?.avatar?.path
          //     ? imagePath(item?.user?.avatar?.path)
          //     : ""
          // }
          size={108}
        /> */}
        <Styledbtn onClick={editClickHandler}>Edit</Styledbtn>
      </AvatarWrap>
      {isEdit ? (
        <EditPersonalDetailsWrap>
          <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
            <StyledForms>
              <Form
                // fields={[...fields]}
                layout="vertical"
                onFinish={onFinish}
                requiredMark={"optional"}
                validateMessages={validateMessages}
                onFieldsChange={onFieldsChange}
                form={form}
              >
                <StyledFormItem smwh="100" tabSmwh="100" sfd="column">
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </StyledFormItem>
                <StyledFormItem smwh="100" tabSmwh="100" sfd="column">
                  <Form.Item
                    className="input__affix"
                    name="phoneNumber"
                    label="Contact Number"
                    rules={[{ required: true }]}
                  >
                    <Input
                      pattern="[0-9]*"
                      type="number"
                      prefix={`+1`}
                      placeholder="Contact Number"
                      onKeyDown={(e) =>
                        e.key === "." ? e.preventDefault() : ""
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true, type: "email" }]}
                  >
                    <Input disabled={true} placeholder="Email" />
                  </Form.Item>
                </StyledFormItem>

                <Form.Item>
                  <Row justify="end">
                    <Col xs={8} sm={6} md={6} lg={6} xl={4} xxl={5}>
                      <StyledButton htmlType="submit" loading={loadings}>
                        Save
                      </StyledButton>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </StyledForms>
          </StyledForm>
        </EditPersonalDetailsWrap>
      ) : (
        <PersonalDetailsWrap>
          <Row gutter={[30, 30]} justify="space-between">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <span>First Name</span>
              <h2>{upperCase(user?.firstName)}</h2>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <span>Last Name</span>
              <h2>{upperCase(user?.lastName)}</h2>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <span>Contact Number</span>
              <h2>{user?.phoneNumber}</h2>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <span>Email</span>
              <h2>{user?.email}</h2>
            </Col>
          </Row>
        </PersonalDetailsWrap>
      )}
    </ProfileWrap>
  );
}

export default Profile;

export const EditPersonalDetailsWrap = styled.div`
  margin-top: 65px;

  .ant-form-item {
    input {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
    }
  }

  @media (max-width: 1550px) {
    .ant-form-item {
      input {
        font-size: 18px;
        line-height: 21px;
      }
    }
  }

  @media (max-width: 1441px) {
    padding: 0;
  }

  @media (max-width: 769px) {
    margin-top: 25px;
  }
`;

export const PersonalDetailsWrap = styled.div`
  margin-top: 65px;

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #aaaaaa;
  }

  h2 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.forth};
  }

  @media (max-width: 769px) {
    margin-top: 25px;
  }
`;

export const Styledbtn = styled(Button)`
  height: 50px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.secondary};
  border-color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  padding: 4px 35px;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
  }

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.secondary}90;
    background: ${({ theme }) => theme.colors.secondary}90;
  }
`;
export const AvatarWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ProfileWrap = styled.div`
  padding: 0 50px;
  min-height: 500px;
  @media (max-width: 1550px) {
    padding: 0 60px;
    min-height: 500px;
  }
  @media (max-width: 991.98px) {
    padding: 0;
  }
`;
