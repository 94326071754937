import { useContext, useEffect, useState } from "react";
import { UserContext } from "./UserContext";
import { Button, Col, Row } from "antd";
import LaunchLink from "./LinkLauncher";
import { InformationWrap } from "../pages/Profile-verification/PriorRentalInformation";
import { PlusSvg } from "../assets/svgs";
import { createLinkToken } from "../store/reducers/user.reducer";
import { useDispatch } from "react-redux";
import { useWindowSize } from "../utils/hooks/useWindowSize";
import { useLocation } from "react-router-dom";
// import LaunchLink from "./LinkLauncher";

/**
 * Grabs a link token from the server, calls Link, and then either sends the
 * public token back down to the server, or just reports success back ot the
 * sever. The behavior of this changes quite a bit depending on whether
 * or not you're using Plaid Income.
 */

const LinkLoader = (props) => {
  const [linkToken, setLinkToken] = useState("");
  const { user, setUser } = useContext(UserContext);
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const location = useLocation();
  const [wordMt, setWordMt] = useState("30px");
  const [wordMb, setWordMb] = useState("30px");

  const loadAndLaunchLink = async () => {
    // const linkToken = await fetchLinkToken();
    // setLinkToken(linkToken);
    dispatch(createLinkToken()).then(({ payload }) => {
      // console.log(payload.link_token, "res >>>");
      setLinkToken(payload.link_token);
    });
  };

  const linkSuccess = async (public_token) => {
    if (public_token != null && public_token !== "") {
      if (props.income) {
        await incomeSuccess(public_token);
      } else {
        await accessTokenSuccess(public_token);
      }
    }
  };

  const incomeSuccess = async (public_token) => {
    const response = await fetch("/appServer/income_was_successful", {
      method: "POST",
      headers: { "Content-type": "application/json" },
    });
    // console.log(response);
    setUser(
      Object.assign({}, user, {
        incomeConnected: "connected",
        incomeUpdateTime: Date.now(),
      })
    );
  };

  const accessTokenSuccess = async (public_token) => {
    const response = await fetch("/appServer/swap_public_token", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        public_token: public_token,
      }),
    });
    // console.log(response);
    setUser(
      Object.assign({}, user, {
        liabilitiesConnected: "connected",
      })
    );
  };

  const fetchLinkToken = async () => {
    const messageBody = props.income
      ? JSON.stringify({
          income: true,
          incomeType: props.incomeType,
        })
      : JSON.stringify({
          income: false,
        });

    const response = await fetch("/appServer/generate_link_token", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: messageBody,
    });
    if (response.status === 500) {
      alert(
        "We received an error trying to create a link token. Please make sure you've followed all the setup steps in the readme file, and that your account is activated for income verification."
      );
    } else {
      const data = await response.json();

      // console.log(`Got back link token ${data.link_token}`);
      return data.link_token;
    }
  };

  useEffect(() => {
    if (width < 993) {
      setWordMt("0");
      setWordMb("30px");
    } else {
      setWordMb("0");
      setWordMt("30px");
    }
  }, [width, location.pathname]);

  return (
    <>
      <InformationWrap>
        <Row
          justify="end"
          className="icon__text"
          block="true"
          onClick={() => loadAndLaunchLink()}
          style={{ marginTop: wordMt, marginBottom: wordMb }}
        >
          <Col>
            <PlusSvg />
          </Col>
          <Col>
            <p> {props.buttonText}</p>
          </Col>
        </Row>
      </InformationWrap>
      <LaunchLink token={linkToken} successCallback={linkSuccess} />

      {/* <Row justify="center">
        <Col span={10}>
          <StyledButton colorScheme="green" onClick={() => loadAndLaunchLink()}>
            {props.buttonText}
          </StyledButton>
        </Col>
      </Row> */}
    </>
  );
};

LinkLoader.defaultProps = {
  income: false,
  incomeType: "payroll",
  buttonText: "Connect my bank",
};

export default LinkLoader;
