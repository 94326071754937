import styled from "styled-components";

export const StyledHeaderTop = styled.div`
  height: 80px;
  background-color: ${({ theme }) => theme.colors.primary};
  background-size: cover;
  background-position: 100% 100%;
  display: flex;
  ${"" /* padding: 0 30px; */}
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: fixed;
  width: 100%;
  z-index: 10;

  @media (max-width: 1550px) {
    height: 65px;
    padding: 0 30px;
    width: 100%;
  }
  @media (max-width: 425px) {
    padding: 0 15px;
  }
`;

export const HeaderWrap = styled.div`
  display: flex;
  width: 1400px;
  margin: 0 auto;

  @media (max-width: 1400px) {
    width: 100%;
  }
`;
export const StyledLeftSide = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  @media (max-width: 1550px) {
    .avenew__title {
      width: 160px;
      height: 39px;
    }
  }
`;
export const StyledRightSide = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: end;

  .header__arrow {
    font-size: 18px;
    color: #000;
    margin-left: 15px;
    cursor: pointer;
  }

  p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.forth};
    cursor: pointer;
  }
  .user__icon {
    cursor: pointer;
    margin-left: 24px;
    display: inline-block;
  }

  @media (max-width: 769px) {
    p,
    .header__arrow {
      display: none;
    }
    
    .user__icon {
      cursor: auto;
      margin-left: 10px;
      display: inline-block;
    }
  }
`;
