import React from "react";

const applicationSvg = ({ wSize = "42", hSize = "42" }) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox={`0 0 ${wSize} ${hSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5 15.75H10.5V18.375H30.1875H31.5V15.75ZM36.75 20.3674V5.25H5.25V36.75H20.3674C22.4858 39.9144 26.0912 41.9987 30.1875 42C36.7119 41.9974 41.9974 36.7119 42 30.1875C41.9987 26.0912 39.9144 22.4858 36.75 20.3674ZM20.3661 23.625C19.8253 24.4335 19.3909 25.3168 19.0614 26.25H10.5V28.875H18.4511C18.4039 29.3068 18.375 29.7426 18.375 30.1875C18.375 31.5709 18.6244 32.8926 19.0614 34.125H7.875V7.875H34.125V19.0614C32.8926 18.6244 31.5709 18.375 30.1875 18.375C27.3748 18.375 24.7971 19.3607 22.7692 21H10.5V23.625H20.3661ZM30.1875 39.2214C25.1987 39.2096 21.1627 35.1737 21.1509 30.1875C21.1627 25.1987 25.1987 21.1627 30.1875 21.1522C35.1737 21.1627 39.2096 25.1987 39.2214 30.1875C39.2096 35.1737 35.1737 39.2096 30.1875 39.2214Z"
        fill="#F67C36"
      />
      <path
        d="M23.625 30.1875L26.25 27.5625L28.875 30.1875L34.125 24.9375L36.75 27.5625L28.875 35.4375L23.625 30.1875Z"
        fill="#F67C36"
      />
    </svg>
  );
};

export default applicationSvg;
