import React from "react";

const searchIconSvg = ({
  wSize = "21",
  hSize = "21",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8.01378C16.0031 6.20565 15.3936 4.44979 14.2709 3.03247C13.1481 1.61515 11.5783 0.619975 9.81749 0.209194C8.05663 -0.201587 6.20855 -0.00374395 4.57455 0.770467C2.94055 1.54468 1.61702 2.84959 0.819732 4.47246C0.0224456 6.09532 -0.201564 7.94041 0.184225 9.70691C0.570014 11.4734 1.54285 13.0571 2.94411 14.1998C4.34538 15.3426 6.09243 15.9769 7.90042 15.9994C9.70842 16.0219 11.4707 15.4313 12.9 14.3238L19.3 20.7238L20.71 19.3138L14.31 12.9138C15.4015 11.5133 15.9961 9.7894 16 8.01378ZM8 14.0138C6.81331 14.0138 5.65327 13.6619 4.66658 13.0026C3.67988 12.3433 2.91085 11.4062 2.45672 10.3099C2.00259 9.21353 1.88377 8.00713 2.11529 6.84324C2.3468 5.67935 2.91824 4.61026 3.75736 3.77114C4.59647 2.93203 5.66557 2.36058 6.82945 2.12907C7.99334 1.89756 9.19974 2.01638 10.2961 2.4705C11.3925 2.92463 12.3295 3.69367 12.9888 4.68036C13.6481 5.66706 14 6.82709 14 8.01378C14 9.60508 13.3679 11.1312 12.2426 12.2564C11.1174 13.3816 9.5913 14.0138 8 14.0138Z"
        fill="black"
      />
    </svg>
  );
};

export default searchIconSvg;
