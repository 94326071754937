import { message } from "antd";

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  message.success("copied to clipboard");
};

export const hexToRgb = (hex) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
};

export const rgbaToHexify = (rgba) => {
  var values = rgba
    .replace(/rgba?\(/, "")
    .replace(/\)/, "")
    .replace(/[\s+]/g, "")
    .split(",");
  var a = parseFloat(values[3] || 1),
    r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255),
    g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
    b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255);
  return (
    "#" +
    ("0" + r.toString(16)).slice(-2) +
    ("0" + g.toString(16)).slice(-2) +
    ("0" + b.toString(16)).slice(-2)
  );
};

export const imagePath = (path, isStatic = false) => {
  if (isStatic) return path;
  return process.env.REACT_APP_API_URL
    ? `${process.env.REACT_APP_API_URL}${path}`
    : path;
};

export const Capitalize = (str, from = "-", to = "") => {
  if (str) {
    str = str.split(from);
    for (var i = 0, x = str.length; i < x; i++) {
      str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }
    return str.join(to);
  } else {
    return "";
  }
};

export const getInitials = (fullname = " ") => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
  let initials = [...fullname.matchAll(rgx)] || [];
  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();

  return initials;
};

export const qs = (obj, prefix) => {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === "object"
          ? qs(v, k)
          : encodeURIComponent(k) + "=" + encodeURIComponent(v)
      );
    }
  }
  return str.join("&");
};

export const querystring = (mixedObj) => {
  const reducer =
    (obj, parentPrefix = null) =>
    (prev, key) => {
      const val = obj[key];
      key = encodeURIComponent(key);
      const prefix = parentPrefix ? `${parentPrefix}[${key}]` : key;

      if (val == null || typeof val === "function") {
        prev.push(`${prefix}=`);
        return prev;
      }

      if (["number", "boolean", "string"].includes(typeof val)) {
        prev.push(`${prefix}=${encodeURIComponent(val)}`);
        return prev;
      }

      prev.push(Object.keys(val).reduce(reducer(val, prefix), []).join("&"));
      return prev;
    };

  return Object.keys(mixedObj).reduce(reducer(mixedObj), []).join("&");
};

export const paginationObj = ({
  current,
  pageSize,
  filter = {},
  aggregate = [],
  sorter,
  populate,
}) => {
  let pagination;
  if (aggregate.length) {
    let pipeline = [...aggregate];

    if (pageSize) pipeline.push({ $limit: pageSize });
    if (current && pageSize)
      pipeline.push({ $skip: +pageSize * (+current - 1) });
    if (sorter && sorter?.order)
      pipeline.push({
        $sort: {
          [sorter.field]: sorter?.order === "ascend" ? 1 : -1,
        },
      });
    pagination = { aggregate: pipeline };
  } else {
    pagination = {};
    if (filter) pagination = { ...filter };
    if (pageSize) pagination.$limit = pageSize;
    if (current && pageSize) pagination.$skip = +pageSize * (+current - 1);
    if (sorter && sorter?.order)
      pagination.$sort = {
        [sorter.field]: sorter?.order === "ascend" ? 1 : -1,
      };
    if (populate) pagination.$populate = populate;
  }

  return pagination;
};

export const searchObj = (columns = [], search) => {
  return columns.length && search
    ? {
        $or: columns.map((col) => ({
          [col]: { $regex: `${search}`, $options: "igm" },
        })),
      }
    : {};
};

export const nFormatter = (num, digits = 1) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const _filter = (arr, fn) => {
  return arr.filter(fn);
};

export const _keys = (obj) => {
  return Object.keys(obj);
};

export const _get = (obj, path, defaultValue = undefined) => {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj
      );
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

export const _set = (obj, path, value) => {
  if (Object(obj) !== obj) return obj; // When obj is not an object
  // If not yet an array, get the keys from the string-path
  if (!Array.isArray(path)) path = path.toString().match(/[^.[\]]+/g) || [];
  path.slice(0, -1).reduce(
    (
      a,
      c,
      i // Iterate all of them except the last one
    ) =>
      Object(a[c]) === a[c] // Does the key exist and is its value an object?
        ? // Yes: then follow that path
          a[c]
        : // No: create the key. Is the next key a potential array-index?
          (a[c] =
            Math.abs(path[i + 1]) >> 0 === +path[i + 1]
              ? [] // Yes: assign a new array object
              : {}), // No: assign a new plain object
    obj
  )[path[path.length - 1]] = value; // Finally assign the value to the last key
  return obj; // Return the top-level object to allow chaining
};

export const slugify = (string) => {
  string = string || "";
  return (
    string
      .replace(/ /g, "-")
      // eslint-disable-next-line no-useless-escape
      .replace(/[^\一-龠\ぁ-ゔ\ァ-ヴー\w\.\-]+/g, "")
  );
};

export const convertJSONToFormData = (
  jsonData,
  form = null,
  namespace = ""
) => {
  let formData = form || new FormData();

  for (let propertyName in jsonData) {
    if (!jsonData.hasOwnProperty(propertyName) || !jsonData[propertyName])
      continue;
    let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
    if (jsonData[propertyName] instanceof Date)
      formData.append(formKey, jsonData[propertyName].toISOString());
    else if (jsonData[propertyName] instanceof Array) {
      jsonData[propertyName].forEach((element, index) => {
        const tempFormKey = `${formKey}[${index}]`;
        convertJSONToFormData(element, formData, tempFormKey);
      });
    } else if (
      typeof jsonData[propertyName] === "object" &&
      !(jsonData[propertyName] instanceof File)
    )
      convertJSONToFormData(jsonData[propertyName], formData, formKey);
    else formData.append(formKey, jsonData[propertyName].toString());
  }
  return formData;
};

export const downloadFile = (
  url,
  mimetype = "application/octet-stream",
  filename = "download"
) => {
  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": mimetype,
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
};

export const getImageBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const randomNumber = (min, max) => {
  var random = Math.random();
  return Math.floor(random * (max - min) + min);
};

export const shortenDateFromNow = (str) => {
  try {
    const tStr = str.replace("ago", "").match(/(^[\da]+ [\w]?o?)/gi);
    console.log(tStr);
    if (tStr.length && /f$/g.test(tStr[0])) {
      return "now";
    }
    return tStr.length
      ? tStr[0].replace(/ho$/gi, "h").replace(/^a/i, "1")
      : str.replace("ago", "");
  } catch (error) {
    console.log(error);
    return str.replace("ago", "");
  }
};

export const _merge = (original, newdata, selector = "key") => {
  newdata.forEach((dat) => {
    const foundIndex = original.findIndex(
      (ori) => ori[selector] === dat[selector]
    );
    if (foundIndex >= 0) original.splice(foundIndex, 1, dat);
    else original.push(dat);
  });

  return original;
};
