import React from "react";

const facebookSvg = ({
  wSize = "30",
  hSize = "30",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
    >
      <g clip-path="url(#clip0_5393_1264)">
        <path
          d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34Z"
          fill="#3B5998"
        />
        <path
          d="M23.375 34V21.25H27.625L28.6875 15.9375H23.375V13.8125C23.375 11.6875 24.4396 10.625 26.5625 10.625H28.6875V5.3125C27.625 5.3125 26.3075 5.3125 24.4375 5.3125C20.5328 5.3125 18.0625 8.37356 18.0625 12.75V15.9375H13.8125V21.25H18.0625V34H23.375Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5393_1264">
          <rect width="34" height="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default facebookSvg;
