import styled from "styled-components";
import { Card } from "../../../ui/Card";

export const CardWrapWrap = styled.div`
  max-width: 1400px;
  margin: auto;
`;
export const CardDiscriptionWrap = styled.div`
  padding: 25px;
  @media (max-width: 1550px) {
    padding: 20px;
  }
`;
export const CardWrapper = styled.div`
  background-color: #ecf2ff;
  display: flex;
  flex-flow: column;
  margin-top: 0px;
  width: 100%;
  padding: 70px 0;

  h1 {
    margin-bottom: 35px;
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    letter-spacing: 0.05em;
    color: #2b353f;
    text-align: center;
  }

  @media (max-width: 1550px) {
    padding: 55px 0;

    h1 {
      margin-bottom: 25px;
      font-size: 40px;
      line-height: 48px;
    }
  }
  @media (max-width: 1440px) {
    padding: 55px 30px;
  }
  @media (max-width: 769px) {
    padding: 30px;

    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.05em;
    }
  }
`;

export const StyledApparCard = styled(Card)`
  display: flex;
  ${"" /* position: relative; */}
  flex-flow: column;
  padding: 0px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.4s;
  width: 100%;

  .card__bottom p,
  span {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.05em;
    color: #848687;
  }
  .card__bottom p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 0.05em;
    color: #848687;
  }

  .card__bottom {
    display: flex;
    align-items: center;
    svg {
      width: 16px;
      margin-top: 10px;
      margin-right: 3px;
    }
  }

  .rating__star {
    margin-bottom: 5px;
  }

  .card__title {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 5px;
  }
  .card__price {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #4160ac;
    margin-bottom: 10px;
  }

  .heart__rating {
    display: flex;
    flex-flow: column;
  }

  .ant-skeleton-element {
    width: 100%;
  }

  .ant-skeleton-image,
  img {
    height: 180px;
    width: 100%;
  }
  .ant-skeleton-element .ant-skeleton-image-svg {
    width: 100px;
    height: 100px;
  }
  .text a {
    display: table;
    color: #0061a4;
    margin-top: 33px;
  }

  :hover {
    -ms-transform: scale(0.98); /* IE 9 */
    -webkit-transform: scale(0.98); /* Safari 3-8 */
    transform: scale(0.98);
  }

  @media (max-width: 1550px) {
    .rating__star {
      margin-bottom: 5px;
    }
    .card__title {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 5px;
    }
    .card__price {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 10px;
    }
    .card__bottom p,
    span {
      font-size: 14px;
      line-height: 22px;
    }
    .card__bottom {
      svg {
        width: 18px;
      }
    }
  }

  @media (max-width: 1440px) {
    .card__bottom {
      svg {
        margin-top: 5px;
      }
    }
  }

  @media (max-width: 380px) {
    .card__price {
      flex-direction: column;
      align-items: start;
    }
  }
`;
