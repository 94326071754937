import styled from "styled-components";
import { Divider, Menu } from "antd";

export const SidebarMenu = styled(Menu)`
  border-right: none !important;
  border-bottom: 0px solid #f0f0f0 !important;
  border-bottom: none !important;
  border: none !important;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;

  ::before {
    display: none;
    content: "";
  }
  ::after {
    display: none;
  }

  margin-bottom: ${({ mb }) => mb || ""};
  .ant-menu-inline {
    border-right: none !important;
    background: transparent;
  }
  &:not(.ant-menu-dark) > .ant-menu-item:hover::after {
    border-bottom: none !important;
  }
  &:not(.ant-menu-dark) > .ant-menu-item {
    text-align: center !important;
  }
  &:not(.ant-menu-dark) > .ant-menu-item:hover {
    color: transparent !important;
  }
`;

export const MenuItem = styled(Menu.Item)`
  overflow: initial !important;

  .ant-menu-title-content {
    font-weight: bold;
    font-size: 14px;
    line-height: 46px;
    color: #666666;
    a {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.05em;
      color: #000000;
    }
  }

  &:hover {
    .ant-menu-title-content {
      a {
        color: #666666;
      }
    }
  }

  &.ant-menu-item-selected {
    background: transparent !important;
    overflow: initial !important;
    &:after {
      border-right: none !important;
      border-bottom: none !important;
    }
    .ant-menu-item-icon {
      background: #4160ac;
      ${
        "" /* background: linear-gradient(
        276.7deg,
        ${({ theme }) => theme.colors.primary} 0%,
        ${({ theme }) => theme.colors.primary2} 100%
      ); */
      }
      box-shadow: 0px 10px 15px ${({ theme }) => theme.colors.primary}59;
      height: 130px;
      width: 130px;
      border-radius: 50%;
      svg {
        fill: #ffffff;
      }
    }
    .ant-menu-title-content {
      a {
        color: ${({ theme }) => theme.colors.third};
      }
    }
  }

  .ant-menu-item-icon + span {
    margin-left: 0 !important;
  }
`;

export const MenuIconWrap = styled.div`
  transition: box-shadow 0.1s;
  height: 130px;
  width: 130px;
  background: #ecf2ff;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  ${"" /* padding-top: 4px; */}
  min-width: 18px !important;
  font-size: 25px !important;
`;
