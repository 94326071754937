import React from "react";

const kitchenSvg = ({
  wSize = "24",
  hSize = "23",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox={`0 0 17 23`}
      {...props}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M15.8064 11.2538H11.2763C10.9327 11.2522 10.6037 11.1147 10.3611 10.8713C10.1184 10.628 9.98198 10.2985 9.98145 9.95485V3.44985C9.98138 3.24782 10.0281 3.04853 10.1179 2.86755L10.8427 1.41792C10.9496 1.2021 11.1148 1.02045 11.3195 0.893463C11.5242 0.766475 11.7603 0.699199 12.0012 0.699219H14.8129C15.1574 0.699219 15.4878 0.836074 15.7314 1.07968C15.975 1.32328 16.1118 1.65368 16.1118 1.99818V10.9566C16.1097 11.0361 16.0766 11.1117 16.0195 11.1673C15.9625 11.2228 15.886 11.2538 15.8064 11.2538ZM12.0012 1.31002C11.874 1.31022 11.7494 1.34598 11.6414 1.41326C11.5335 1.48053 11.4465 1.57665 11.3904 1.69075L10.6594 3.14241C10.6141 3.23851 10.5912 3.34361 10.5922 3.44985V9.96503C10.5922 10.1475 10.6647 10.3226 10.7938 10.4516C10.9229 10.5807 11.0979 10.6532 11.2804 10.6532H15.501V1.99818C15.5005 1.81584 15.4278 1.64111 15.2989 1.51217C15.1699 1.38323 14.9952 1.31055 14.8129 1.31002H12.0012Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M15.8064 14.4988C15.7254 14.4988 15.6477 14.4666 15.5904 14.4093C15.5332 14.352 15.501 14.2743 15.501 14.1934V10.948C15.501 10.867 15.5332 10.7893 15.5904 10.732C15.6477 10.6748 15.7254 10.6426 15.8064 10.6426C15.8874 10.6426 15.9651 10.6748 16.0223 10.732C16.0796 10.7893 16.1118 10.867 16.1118 10.948V14.1934C16.1118 14.2743 16.0796 14.352 16.0223 14.4093C15.9651 14.4666 15.8874 14.4988 15.8064 14.4988Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M14.1491 3.519C13.9796 3.519 13.8139 3.46872 13.6729 3.37454C13.532 3.28035 13.4221 3.14648 13.3572 2.98986C13.2924 2.83324 13.2754 2.66089 13.3085 2.49462C13.3415 2.32835 13.4232 2.17562 13.543 2.05574C13.6629 1.93587 13.8157 1.85423 13.9819 1.82116C14.1482 1.78808 14.3205 1.80506 14.4772 1.86993C14.6338 1.93481 14.7677 2.04467 14.8618 2.18563C14.956 2.32659 15.0063 2.49231 15.0063 2.66184C15.0058 2.88901 14.9153 3.10672 14.7547 3.26735C14.594 3.42798 14.3763 3.51846 14.1491 3.519ZM14.1491 2.41549C14.1004 2.41549 14.0528 2.42993 14.0123 2.457C13.9718 2.48407 13.9402 2.52255 13.9215 2.56757C13.9029 2.61258 13.898 2.66211 13.9075 2.7099C13.917 2.75769 13.9405 2.80159 13.9749 2.83604C14.0094 2.87049 14.0533 2.89396 14.1011 2.90346C14.1489 2.91297 14.1984 2.90809 14.2434 2.88944C14.2884 2.8708 14.3269 2.83922 14.354 2.79871C14.3811 2.7582 14.3955 2.71057 14.3955 2.66184C14.3955 2.5965 14.3695 2.53384 14.3233 2.48764C14.2771 2.44144 14.2145 2.41549 14.1491 2.41549Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M3.53143 11.2578C2.88462 11.2516 2.26525 10.9955 1.80287 10.5432C1.54164 10.2911 1.33548 9.9877 1.19741 9.65199C1.05934 9.31628 0.992358 8.95559 1.00069 8.59269V2.11009C1.00069 2.02909 1.03287 1.95141 1.09014 1.89414C1.14742 1.83686 1.22509 1.80469 1.30609 1.80469C1.38709 1.80469 1.46477 1.83686 1.52204 1.89414C1.57932 1.95141 1.61149 2.02909 1.61149 2.11009V8.60084C1.6038 8.87992 1.65412 9.15756 1.75926 9.4162C1.86439 9.67483 2.02206 9.90884 2.22229 10.1034C2.40776 10.2849 2.62848 10.4264 2.87082 10.5192C3.11315 10.612 3.37194 10.6541 3.6312 10.6429C4.13338 10.5983 4.59903 10.3618 4.93124 9.98259C5.26344 9.60336 5.43662 9.11063 5.41473 8.60695V2.11009C5.41473 2.02909 5.4469 1.95141 5.50418 1.89414C5.56145 1.83686 5.63913 1.80469 5.72013 1.80469C5.80112 1.80469 5.8788 1.83686 5.93608 1.89414C5.99335 1.95141 6.02553 2.02909 6.02553 2.11009V8.60084C6.04871 9.26074 5.81696 9.90423 5.37834 10.3978C4.93973 10.8914 4.32793 11.1972 3.66988 11.2517C3.62509 11.2558 3.57894 11.2578 3.53143 11.2578Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M3.82076 10.8096H3.20996V15.2969H3.82076V10.8096Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M3.58477 22.2322C3.21073 22.2316 2.85217 22.0828 2.58768 21.8183C2.32319 21.5538 2.17437 21.1953 2.17383 20.8212V16.4031C2.17383 16.0289 2.32248 15.67 2.58708 15.4054C2.85169 15.1408 3.21057 14.9922 3.58477 14.9922C3.95898 14.9922 4.31786 15.1408 4.58246 15.4054C4.84706 15.67 4.99572 16.0289 4.99572 16.4031V20.8212C4.99518 21.1953 4.84635 21.5538 4.58187 21.8183C4.31738 22.0828 3.95881 22.2316 3.58477 22.2322ZM3.58477 15.603C3.37273 15.6035 3.16952 15.688 3.01958 15.8379C2.86964 15.9879 2.78516 16.1911 2.78463 16.4031V20.8212C2.78463 21.0335 2.86893 21.237 3.01898 21.387C3.16904 21.5371 3.37256 21.6214 3.58477 21.6214C3.79698 21.6214 4.0005 21.5371 4.15056 21.387C4.30062 21.237 4.38492 21.0335 4.38492 20.8212V16.4031C4.38492 16.1909 4.30062 15.9874 4.15056 15.8373C4.0005 15.6873 3.79698 15.603 3.58477 15.603Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M14.8393 22.3014C14.4291 22.3009 14.0359 22.1377 13.7458 21.8476C13.4557 21.5575 13.2925 21.1643 13.292 20.754V15.436C13.2925 15.0258 13.4557 14.6325 13.7458 14.3425C14.0359 14.0524 14.4291 13.8892 14.8393 13.8887H15.8064C15.8874 13.8887 15.9651 13.9208 16.0224 13.9781C16.0797 14.0354 16.1118 14.1131 16.1118 14.1941V21.0289C16.1118 21.196 16.0789 21.3615 16.015 21.5159C15.951 21.6703 15.8573 21.8105 15.7391 21.9287C15.621 22.0469 15.4807 22.1406 15.3263 22.2045C15.1719 22.2685 15.0065 22.3014 14.8393 22.3014ZM14.8393 14.4995C14.591 14.4995 14.3527 14.5981 14.1771 14.7738C14.0015 14.9494 13.9028 15.1876 13.9028 15.436V20.754C13.9033 21.0023 14.0022 21.2402 14.1777 21.4157C14.3532 21.5912 14.5911 21.6901 14.8393 21.6906C15.0147 21.6901 15.1827 21.6202 15.3066 21.4962C15.4306 21.3722 15.5005 21.2042 15.501 21.0289V14.4995H14.8393Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={wSize} height={hSize} fill={bgColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default kitchenSvg;
