import React, { useEffect, useState } from "react";
import { Divider, Form, Steps, message, Select, Input, Row, Col } from "antd";
import { PageTitle } from "../my-account/MyAccountTabs";
import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";
import { AiOutlineUpload } from "react-icons/ai";
import { StyledButton } from "../property-details/PropertyDetails";
import { ImagePreview } from "../../components/ImagePreview";
import data from "./state.json";
import BackgroundCheck from "./BackgroundCheck";
import PriorRentalInformation from "./PriorRentalInformation";
import Footer from "../../components/footer/Footer";
import ProofOfIncome from "./ProofOfIncome";
import { useSelector } from "react-redux";

function ProfileVerificationTabs() {
  const { Step } = Steps;
  const { user } = useSelector((state) => state.user);

  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    if (user?.backgroundProfile) {
      setCurrentTab(1);
    }
  }, [user]);

  const onChange = (value) => {
    setCurrentTab(value);
  };

  const onSubmit = () => {
    setCurrentTab(currentTab + 1);
  };

  return (
    <div>
      <ProfileVerificationTabsWrap>
        <PageTitle>
          <h2>Profile Verification</h2>
        </PageTitle>
        <ProfileVerificationMain>
          <StapsWrap>
            <Steps
              size="small"
              direction="vertical"
              onChange={onChange}
              current={currentTab}
            >
              <Step
                title="Background Check"
                description="Submit a picture of your Driver’s License or the last 4 digits of you SSN."
              />
              <Step
                title="Prior Rental Information"
                description="If you are a first time renter, no worriest! We’ll help you through the process."
              />
              <Step
                title="Proof of income"
                description="Connect us with your bank or upload your last 2 pay stubs."
              />
              <Step
                title="Verified!"
                description="Now you can get pre-approved for listing and apply for multiple properties by entering your info once!"
              />
            </Steps>
            <Divider type="vertical" />
          </StapsWrap>
          {currentTab === 0 ? (
            <BackgroundCheck onSubmit={onSubmit} />
          ) : currentTab === 1 ? (
            <PriorRentalInformation onSubmit={onSubmit} />
          ) : currentTab === 2 ? (
            <ProofOfIncome />
          ) : (
            ""
          )}
        </ProfileVerificationMain>
      </ProfileVerificationTabsWrap>
      <Footer />
    </div>
  );
}

export default ProfileVerificationTabs;

export const ProfileVerificationMain = styled.div`
  display: flex;
`;

export const StapsWrap = styled.div`
  width: 28%;
  display: flex;
  max-height: 558px;

  .ant-divider-vertical {
    margin-left: 80px;
    height: auto;
    margin-right: 0;
    max-height: 558px;
  }

  .ant-steps-item-icon .ant-steps-icon {
    top: -1.5px;
    color: #4160ac;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #d6ffc8;
    border-color: #d6ffc8;
  }
  .anticon svg {
    color: #7ccd60;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: #dee8ff;
    border-color: #dee8ff;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #7ccd60;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon
    .ant-steps-icon {
    color: #4160ac;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #7ccd60;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #4160ac;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #848687;
    padding-top: 10px;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background: #eeeeee;
    border-color: #eeeeee;
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2b353f;
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #2b353f;
  }

  @media (max-width: 1550px) {
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
      font-size: 12px;
    }

    .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-icon
      .ant-steps-icon {
      font-size: 12px;
    }
  }
`;

export const ProfileVerificationTabsWrap = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 50px 0;

  /* scrollbar design */
  body::-webkit-scrollbar {
    width: 0.5em;
  }

  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  body::-webkit-scrollbar-thumb {
    background-color: rgb(97, 96, 96);
    outline: 1px solid rgb(112, 128, 144);
    border-radius: 10px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 767px) {
    body::-webkit-scrollbar,
    .ant-modal-wrap::-webkit-scrollbar {
      width: 0 !important;
      display: none !important;
    }

    body::-webkit-scrollbar-track,
    .ant-modal-wrap::-webkit-scrollbar-track {
      box-shadow: none;
    }

    body::-webkit-scrollbar-thumb,
    .ant-modal-wrap::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  @media (max-width: 1550px) {
    padding: 30px;
  }
`;
