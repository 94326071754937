import { Button, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../components/layout/header/Header";
import { BsFacebook } from "react-icons/bs";
import { FacebookSvg, GoogleSvg, TwitterSvg } from "../../assets/svgs";
// import FadeIn from "react-fade-in";
import {
  createSearchParams,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import { login } from "../../store/reducers/user.reducer";
import { useDispatch } from "react-redux";

function Login() {
  var formRef = React.createRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [invalidUserOrPass, setInvalidUserOrPass] = useState(false);
  const [loadings, setloadings] = useState(false);
  const [emailValidation, setEmailValidation] = useState("");

  const onFinish = async (value) => {
    value.email = value.email.toLowerCase();
    setEmailValidation("");
    setloadings(true);
    await dispatch(login(value)).then((res) => {
      setloadings(false);
      setEmailValidation(res.payload.error || res.payload.Error);
      setInvalidUserOrPass(res.payload.Error?.includes("password"));
      if (res.payload?.error || res.payload.Error) {
      } else {
        navigate("/my-rentals");
      }
    });
  };
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      emailValidation?.length &&
      emailValidation?.includes("User with email")
    ) {
      formRef.current.setFields([
        {
          name: "email",
          errors: ["Email is not exist"],
        },
      ]);
    }
  }, [emailValidation, loadings]);

  const reDirectFun = () => {
    if (params.id) {
      navigate(`/signup/${params.id}`);
    } else {
      navigate(`/signup`);
    }
  };

  return (
    <div>
      <Header />
      <LoginMain>
        <LoginPageMain minH="calc(100vh - 65px)">
          {/* <FadeIn> */}
          <LoginWrap>
            <LoginLeft>
              <h2>Welcome Back</h2>
              <p>
                You can log in to access our tenant site, where you can monitor
                your balance, pay rent, explore and maintain properties, and
                directly connect with the home owners.
              </p>
              <Form
                layout="vertical"
                onFinish={onFinish}
                requiredMark={"optional"}
                validateMessages={validateMessages}
                ref={formRef}
              >
                <StyledFormItem wh="100" smwh="100">
                  <Form.Item
                    name="email"
                    label="User Name"
                    rules={[
                      {
                        required: true,
                        type: "email",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </StyledFormItem>
                <StyledFormItem display="block" wh="100" smwh="100">
                  <Form.Item
                    name="password"
                    label="Password"
                    className="password-w-icon"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        min: 8,
                        message: "password should contain minimum 8 characters",
                      },
                    ]}
                  >
                    <Input type="password" />
                  </Form.Item>
                  <h3>Forget Password?</h3>
                </StyledFormItem>
                {invalidUserOrPass ? (
                  <p style={{ color: "#ff4d4f" }}>
                    * Incorrect Email or Password.
                  </p>
                ) : (
                  ""
                )}
                <Form.Item>
                  <StyledButton htmlType="submit" loading={loadings}>
                    Login Now
                  </StyledButton>
                </Form.Item>
              </Form>
              <LoginBottomWrap>
                <p>
                  Don’t Have Account? <h2 onClick={reDirectFun}> Sign Up</h2>
                </p>
                <Row gutter={[10, 10]}>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <LoginWithButton bgColor="#E8F2FF" icon={<FacebookSvg />}>
                      Facebook
                    </LoginWithButton>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <LoginWithButton bgColor="#FFECEA" icon={<GoogleSvg />}>
                      Google
                    </LoginWithButton>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <LoginWithButton bgColor="#DFF3FF" icon={<TwitterSvg />}>
                      Twitter
                    </LoginWithButton>
                  </Col>
                </Row>
              </LoginBottomWrap>
            </LoginLeft>
          </LoginWrap>
          {/* </FadeIn> */}
        </LoginPageMain>
      </LoginMain>
    </div>
  );
}

export default Login;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
  border-radius: 5px;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.secondary};
  border-color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  margin-top: 20px;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 0.5px;
  }

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.secondary}90;
    background: ${({ theme }) => theme.colors.secondary}90;
  }

  @media (max-width: 1550px) {
    height: 40px;
    margin-top: 15px;
    span {
      font-size: 16px;
      line-height: 20px;
    }
  }
  @media (max-width: 576px) {
    margin-top: 10px;
  }
`;

export const LoginPageMain = styled.div`
  margin-top: 80px;
  width: 100%;
  min-height: ${({ minH }) => minH || "calc(100vh - 80px)"};

  @media (max-width: 1550px) {
    margin-top: 65px;
    min-height: ${({ minH }) => minH || "calc(100vh - 80px)"};
  }
`;

export const StyledFormItem = styled.div`
  display: ${({ display }) => display || "flex"};
  width: 100%;
  justify-content: space-between;

  h3 {
    text-align: end;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: ${({ theme }) => theme.colors.third};
    margin-bottom: 0;
    margin-top: 10px;
    cursor: pointer;

    :hover {
      color: #ff5d00;
    }
  }

  .ant-form-vertical .ant-form-item {
    flex-direction: row;
  }

  .ant-form-item {
    width: ${({ wh }) => wh || "48"}%;
    .ant-form-item-label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    input {
      // max-width: 470px;
      width: 100%;
      height: 50px;
      background: #eeeeee;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      font-size: 18px;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
  }
  .ant-form-item-label > label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 0.05em;

    color: #bbbbbb;
  }
  .description__box {
    padding: 14px 20px;
  }

  .ant-form-item {
    width: ${({ wh }) => wh || "48"}%;
    .ant-form-item-label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    .ant-select-selector {
      width: 100%;
      height: 50px;
      background: #eeeeee;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      align-items: center;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
    .ant-select-selection-placeholder {
      color: #aaaaaa;
    }
  }

  @media screen and (max-width: 1600px) {
    flex-direction: ${({ fd }) => fd || "row"};
  }
  @media screen and (max-width: 1550px) {
    .ant-form-item {
      margin-bottom: 18px;
      input {
        height: 40px;
        font-size: 16px;
      }
      .ant-select-selector {
        height: 40px;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .ant-form-item {
      margin-bottom: 5px;
    }
    .ant-form-item {
      flex-direction: row;
    }
    .ant-form-item .ant-form-item-label {
      padding: 0;
    }
  }

  @media screen and (max-width: 424px) {
    flex-direction: ${({ fd }) => fd || "column"};
    .ant-form-item {
      width: ${({ smwh }) => smwh || "48"}%;
    }
  }
`;

export const LoginWrap = styled.div`
  max-width: 1400px;
  margin: auto;
  @media (max-width: 1440px) {
    padding: 0 30px;
  }
  @media (max-width: 769px) {
    padding: 0 15px;
  }
`;

export const LoginLeft = styled.div`
  margin-top: 130px;
  margin-bottom: 30px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  width: 40%;
  padding: 50px;
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 5px;
  h2 {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    /* identical to box height */
    letter-spacing: 0.05em;
    color: #ffffff;
  }
  p {
    margin-bottom: 35px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    letter-spacing: 0.05em;
    color: #ffffff;
  }
  @media screen and (max-width: 1550px) {
    margin-top: 35px;
    margin-bottom: 20px;
    width: 35%;
    padding: 30px;
    h2 {
      font-size: 40px;
      line-height: 48px;
    }
    p {
      margin-bottom: 28px;
      font-size: 16px;
      line-height: 23px;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 45%;
  }

  @media screen and (max-width: 991.98px) {
    width: 100%;
  }

  @media screen and (max-width: 769px) {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 15px;

    h2 {
      font-size: 28px;
      line-height: 40px;
    }
    p {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 23px;
    }
  }
`;

export const LoginMain = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  background-image: url("/images/loginBg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: ${(props) => props.theme.colors.primary};
  box-shadow: inset 0 0 0 1000px ${(props) => props.theme.colors.primary}30;

  background-position: initial;
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
  @keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const LoginBottomWrap = styled.div`
  text-align: center;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    margin: 0;
    color: #ffffff;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }
  h2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    margin: 0;
    ${"" /* color: #ffffff; */}
    margin-bottom: 30px;
    color: ${({ theme }) => theme.colors.third};
    cursor: pointer;
    :hover {
      color: #ff5d00;
    }
  }
`;
export const LoginWithButton = styled(Button)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  color: #ffffff;
  background: ${({ bgColor }) => bgColor};
  border-color: ${({ bgColor }) => bgColor};
  padding: 10px 15px;
  cursor: pointer;
  transition: 0.5s;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #2b353f;
    margin-left: 15px;
  }
  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ bgColor }) => bgColor}90;
    background: ${({ bgColor }) => bgColor}90;
  }

  @media (max-width: 1550px) {
    height: 40px;
  }
  @media (max-width: 1440px) {
    padding: 0px;
    justify-content: center;
  }
`;
