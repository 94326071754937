import { createContext } from "react";

export const UserContext = createContext({
  user: {
    userName: "",
    incomeConnected: "unknown",
    incomeUpdateTime: Date.now(),
    liabilitiesConnected: "unknown",
  },
  setUser: (obj) => {},
});
