import React, { useEffect, useState } from "react";
import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../../../Profile-verification/BackgroundCheck";
import { StyledButton } from "../../../property-details/apply-for-property-modal/ApplyForPropertyModal";
import { FormWrap, GeneralProfileWrap } from "../GeneralProfile";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import data from "../../../Profile-verification/state.json";
import { applicationProcess } from "../../../../store/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";

function YourSpouse({ onYourSpouseNext, onBackClick }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { applicationProcessData } = useSelector((state) => state.user);
  const [isSubmit, setIsSubmit] = useState(false);
  const [citesValue, setCitesValue] = useState([]);
  const [stateValue, setstateValue] = useState("");

  const monthFormat = "YYYY/MM/DD";
  const { Option } = Select;

  const onFinish = (value) => {
    setIsSubmit(true);
    let data = {};
    data.stateProfile = {};
    data.stateProfile.spouseDetails = value;

    dispatch(applicationProcess(data)).then(({ payload }) => {
      setIsSubmit(false);
      onYourSpouseNext("your-work");
    });
  };

  useEffect(() => {
    if (
      applicationProcessData?.stateProfile &&
      Object.values(applicationProcessData?.stateProfile?.spouseDetails)?.length
    ) {
      const spouseDetails = applicationProcessData?.stateProfile?.spouseDetails;

      form.setFieldsValue({
        fullName: spouseDetails?.fullName,
        lastName: spouseDetails?.lastName,
        spouseSocialSecurity: spouseDetails?.spouseSocialSecurity,
        birthdate: spouseDetails?.birthdate
          ? moment(spouseDetails?.birthdate)
          : "",
        height: spouseDetails?.height,
        weight: spouseDetails?.weight,
        sex: spouseDetails?.sex,
        eyeColor: spouseDetails?.eyeColor,
        hairColor: spouseDetails?.hairColor,
        USCitizen: spouseDetails?.USCitizen,
        presentEmployer: spouseDetails?.presentEmployer,
        address: spouseDetails?.address,
        state: spouseDetails?.state,
        city: spouseDetails?.city,
        zipcode: spouseDetails?.zipcode,
        workPhone: spouseDetails?.workPhone,
        positions: spouseDetails?.positions,
        monthlyIncome: spouseDetails?.monthlyIncome,
        begenJob: spouseDetails?.begenJob
          ? moment(spouseDetails?.begenJob)
          : "",
        supervisorNameAndPhone: spouseDetails?.supervisorNameAndPhone,

        // invoiceCreatedDate: moment(selectedInvoice?.invoiceCreatedDate),
        // dueDate: moment(selectedInvoice?.dueDate),
      });
    } else {
    }
  }, [applicationProcessData]);

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  useEffect(() => {
    setCitesValue(data[stateValue]);
  }, [stateValue]);

  const onChange = (newValue) => {
    setstateValue(newValue);
    form.setFieldsValue({ city: [] });
  };

  return (
    <GeneralProfileWrap>
      <h1>Spouse personal details</h1>

      <FormWrap mt="0">
        <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
          <StyledForms>
            <Form
              layout="vertical"
              // requiredMark={"optional"}
              form={form}
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="fullName"
                  label="Full name (Exactly same on gov. ID proof)"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label="Formal last name (maiden and married)"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="spouseSocialSecurity"
                  label="Spouse social security"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="birthdate"
                  label="Birthdate"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <DatePicker
                    placeholder=""
                    format={monthFormat}
                    // picker="day"
                    disabledDate={(current) => {
                      return moment().add(-1, "days") <= current;
                    }}
                    allowClear={false}
                  />
                </Form.Item>
                <Form.Item
                  className="input__affix"
                  name="height"
                  label="Height"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input type="number" pattern="[0-9]*" />
                </Form.Item>
                <Form.Item
                  className="input__affix"
                  name="weight"
                  label="Weight"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input type="number" pattern="[0-9]*" />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="sex"
                  label="Sex"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="eyeColor"
                  label="Eye color"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  className="input__affix"
                  name="hairColor"
                  label="Hair color"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>

              <h1>Spouse company details</h1>

              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="USCitizen"
                  label="Are you U.S. citizen?"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Select
                    // showSearch
                    placeholder=""
                    // onChange={onChange}
                    suffixIcon={<IoIosArrowDown />}
                  >
                    <Option value={"yes"}>Yes</Option>
                    <Option value={"no"}>No</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="presentEmployer"
                  label="Present employer"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="address"
                  label="Address"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="state"
                  label="State"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Select
                    showSearch
                    placeholder=""
                    onChange={onChange}
                    suffixIcon={<IoIosArrowDown />}
                  >
                    {states.length &&
                      states.map((state) => (
                        <Option value={state}>{state}</Option>
                      ))}
                    {/* <Option value="3BHK">Texas</Option>
                        <Option value="4BHK">4BHk</Option> */}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="city"
                  label="City"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Select
                    showSearch
                    placeholder=""
                    // onChange={}
                    suffixIcon={<IoIosArrowDown />}
                  >
                    {citesValue
                      ? citesValue.map((cites) => (
                          <Option value={cites}>{cites}</Option>
                        ))
                      : ""}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="zipcode"
                  label="Zip code"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input type="number" pattern="[0-9]*" />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
                <Form.Item
                  className="input__affix"
                  name="workPhone"
                  label="Work Phone"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input
                    type="number"
                    prefix={`+1`}
                    onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
                    pattern="[0-9]*"
                  />
                </Form.Item>
                <Form.Item
                  name="positions"
                  label="Positions"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  className="input__affix"
                  name="monthlyIncome"
                  label="Gross monthly income $"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input prefix={`$`} />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="begenJob"
                  label="Date you begen this job "
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <DatePicker
                    placeholder=""
                    format={monthFormat}
                    // picker="day"
                    disabledDate={(current) => {
                      return moment().add(-1, "days") <= current;
                    }}
                    allowClear={false}
                  />
                </Form.Item>
                <Form.Item
                  name="supervisorNameAndPhone"
                  label="Supervisor’s name and phone"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>

              <Form.Item>
                <Row
                  justify="space-between"
                  style={{ alignItems: "center", marginTop: "20px" }}
                >
                  <Col
                    xs={10}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="back__btn"
                  >
                    <IoIosArrowBack onClick={() => onBackClick()} />
                    <h2 onClick={() => onBackClick()}>back</h2>
                  </Col>
                  <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Row justify="end" style={{ alignItems: "center" }}>
                      <Col>
                        <p className="cancel__btn">Cancel</p>
                      </Col>
                      <Col xs={10} sm={8} md={8} lg={8} xl={6} xxl={6}>
                        <StyledButton
                          mt="0"
                          loading={isSubmit}
                          htmlType="submit"
                        >
                          next
                        </StyledButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </StyledForms>
        </StyledForm>
      </FormWrap>
    </GeneralProfileWrap>
  );
}

export default YourSpouse;
