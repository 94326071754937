import React from "react";
import { CardWrapper, CardWrapWrap } from "./CardStyle";
import { cardDummyData } from "./dummy.data";
import CardSection from "./CardSection";

function Cards({allUnits}) {
  return (
    <>
      <CardWrapper>
        <CardWrapWrap>
          <CardSection allUnits={allUnits} cardDummyData={cardDummyData} />
        </CardWrapWrap>
      </CardWrapper>
    </>
  );
}

export default Cards;
