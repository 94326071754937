import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import Footer from "../../../components/footer/Footer";
import {
  MyAccountMobile,
  SelectWrap,
  TabView,
} from "../../my-account/MyAccountMobileView";
import BankingProfile from "../BankingProfile";
import RentalHistory from "../RentalHistory";
import StateProfile from "../StateProfile";
import UserProfileTab from "../UserProfileTab";
import Payment from "../Payment";

function VerificationProcessMobileView() {
  const { Option } = Select;

  const [selectedOption, setSelectedOption] = useState("user-profile");

  const handleChange = (value) => {
    setSelectedOption(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onBackClick = (tab) => {
    switch (tab) {
      case "rental-history":
        setSelectedOption("banking-profile");
        break;
      case "banking-profile":
        setSelectedOption("user-profile");

        break;

      default:
        break;
    }
  };

  return (
    <>
      <MyAccountMobile>
        <SelectWrap>
          <Select
            defaultValue={selectedOption}
            value={selectedOption}
            onChange={handleChange}
            suffixIcon={<IoIosArrowDown />}
          >
            <Option value="user-profile">User Profile</Option>
            <Option value="banking-profile">Income and Employment</Option>
            <Option value="rental-history">Rental History</Option>
            <Option value="payment">Payment</Option>
            <Option value="state-profile">State Profile</Option>
          </Select>
        </SelectWrap>
        <TabView>
          {selectedOption === "user-profile" ? (
            <UserProfileTab />
          ) : selectedOption === "banking-profile" ? (
            <BankingProfile onBackClick={onBackClick} />
          ) : selectedOption === "rental-history" ? (
            <RentalHistory onBackClick={onBackClick} />
          ) : selectedOption === "payment" ? (
            <Payment />
          ) : selectedOption === "state-profile" ? (
            <StateProfile onBackClick={onBackClick}/>
          ) : (
            ""
          )}
        </TabView>
      </MyAccountMobile>
      <Footer />
    </>
  );
}

export default VerificationProcessMobileView;
