import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import styled from "styled-components";
import StarRatings from "react-star-ratings";
import { AvenewImg, BathSvg, BedSvg, Sqft } from "../../assets/svgs";
import { applicationsData } from "../../components/featuredProperties/Cards/dummy.data";
import { ApplicationsCardTop, BedBathBox } from "../applications/Applications";
import { MobCardDetails } from "../category-of-property/CategoryOfProperty";
import { getApplications } from "../../store/reducers/user.reducer";
import { useDispatch } from "react-redux";

function Applications() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [allApplications, setAllApplications] = useState([]);
  const [applicatiomUnits, setApplicatiomUnits] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getApplications()).then(({ payload }) => {
      setIsLoading(false);
      const arr = Object.values(payload);
      setAllApplications(arr);
    });
  }, []);

  useEffect(() => {
    if (allApplications?.length) {
      let unitDetail = [];
      allApplications?.map((item) => {
        unitDetail.push(item?.unitDetails);
      });

      setApplicatiomUnits(unitDetail);
    }
  }, [allApplications]);

  const toUSACurrency = (num) => {
    const curr = num.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return curr;
  };

  return (
    <ApplicationsWrap>
      <Row gutter={[20, 20]}>
        {applicatiomUnits.map((items) => (
          <>
            <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
              <ApplicationsCard>
                {items?.images && items?.images?.length ? (
                  <>
                    {items.images.map((img, idx) => (
                      <div key={idx}>
                        {img.isCover === true ? (
                          <img alt="example" src={img.path} />
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <AvenewImg
                    className="svg__border"
                    style={{ background: "#eeeeee", height: "175px" }}
                  />
                )}
                <CardDetails>
                  {/* <Row>
                    <Col>
                      <StarRatings
                        rating={rating}
                        starRatedColor="#F67C36"
                        starDimension="20px"
                        starSpacing="5px"
                        // changeRating={this.changeRating}
                        numberOfStars={5}
                        name="rating"
                      />
                    </Col>
                  </Row> */}
                  <Row>
                    <Col>
                      <h2>{items.unit_name}</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>{toUSACurrency(Number(items.price))}</p>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    {items?.bed ? (
                      <Col className="card__bottom">
                        <p>
                          <BedSvg className="icon" />
                        </p>
                        <span>Bed : {items?.bed}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {items?.bath ? (
                      <Col className="card__bottom">
                        <p>
                          <BathSvg />
                        </p>
                        <span>Bath : {items?.bath}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {items?.sqft ? (
                      <Col className="card__bottom">
                        <p>
                          <Sqft />
                        </p>
                        <span>Sq ft : {items?.sqft}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </CardDetails>
              </ApplicationsCard>
            </Col>
            <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
              <MobCard>
                <ApplicationsCardTop>
                  {items?.images && items?.images?.length ? (
                    <>
                      {items.images.map((img, idx) => (
                        <div key={idx}>
                          {img.isCover === true ? (
                            <img alt="example" src={img.path} />
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                    </>
                  ) : (
                    <AvenewImg
                      className="svg__border"
                      style={{ background: "#eeeeee", height: "175px" }}
                    />
                  )}
                  <MobCardDetails padding="5">
                    {/* <Row>
                      <Col>
                        <StarRatings
                          rating={rating}
                          starRatedColor="#F67C36"
                          starDimension="15px"
                          starSpacing="3px"
                          // changeRating={this.changeRating}
                          numberOfStars={5}
                          name="rating"
                        />
                      </Col>
                    </Row> */}
                    <Row>
                      <Col>
                        <h2>{items.unit_name}</h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>{toUSACurrency(Number(items.price))}</p>
                      </Col>
                    </Row>
                  </MobCardDetails>
                </ApplicationsCardTop>
                <BedBathBox>
                  <Row justify="space-between">
                    {items?.bed ? (
                      <Col className="card__bottom">
                        <p>
                          <BedSvg className="icon" />
                        </p>
                        <span>Bed : {items?.bed}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {items?.bath ? (
                      <Col className="card__bottom">
                        <p>
                          <BathSvg />
                        </p>
                        <span>Bath : {items?.bath}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                    {items?.sqft ? (
                      <Col className="card__bottom">
                        <p>
                          <Sqft />
                        </p>
                        <span>Sq ft : {items?.sqft}</span>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </BedBathBox>
              </MobCard>
            </Col>
          </>
        ))}
      </Row>
    </ApplicationsWrap>
  );
}

export default Applications;

export const MobCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.4s;
  border-radius: 10px;

  :hover {
    -ms-transform: scale(0.98); /* IE 9 */
    -webkit-transform: scale(0.98); /* Safari 3-8 */
    transform: scale(0.98);
  }
`;

export const CardDetails = styled.div`
  padding: 10px 15px;
  h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  p {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #4160ac;
    margin-bottom: 10px;
  }
  .card__bottom p,
  span {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 0;
    letter-spacing: 0.05em;
    color: #848687;
  }

  .card__bottom {
    display: flex;
    align-items: center;
    margin-right: 30px;
    margin-right: ${({ mr }) => mr || "30px"};
    svg {
      margin-right: 10px;
    }
  }
`;

export const ApplicationsCard = styled.div`
  width: 100%;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.4s;

  :hover {
    ${"" /* -ms-transform: scale(0.98);  */}
    /* IE 9 */
    ${"" /* -webkit-transform: scale(0.98); */}
     /* Safari 3-8 */
    ${"" /* transform: scale(0.98); */}
  }
  img {
    width: 150px;
    height: 150px;
  }
`;

export const ApplicationsWrap = styled.div`
  width: 100%;
  padding: 20px 50px;
  max-height: 500px;
  overflow-y: scroll;

  /* scrollbar design */
  ::-webkit-scrollbar {
    width: 0.5em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(140, 153, 166, 0.8);
    outline: 1px solid rgb(140, 153, 166, 0.8);
    border-radius: 10px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 991.98px) {
    padding: 20px 10px;
  }

  @media (max-width: 767px) {
    ::-webkit-scrollbar,
    .ant-modal-wrap::-webkit-scrollbar {
      width: 0 !important;
      display: none !important;
    }

    ::-webkit-scrollbar-track,
    .ant-modal-wrap::-webkit-scrollbar-track {
      box-shadow: none;
    }

    ::-webkit-scrollbar-thumb,
    .ant-modal-wrap::-webkit-scrollbar-thumb {
      display: none;
    }
  }
`;
