import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../../Profile-verification/BackgroundCheck";
import { StyledButton } from "../../property-details/apply-for-property-modal/ApplyForPropertyModal";
import data from "../../Profile-verification/state.json";
import moment from "moment";
import { FormWrap, GeneralProfileWrap } from "./GeneralProfile";
import { applicationProcess } from "../../../store/reducers/user.reducer";

function EmployerDetails({ onBackClicked, onMainTabNext }) {
  const { applicationProcessData } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const monthFormat = "YYYY/MM/DD";
  const [form] = Form.useForm();
  const { Option } = Select;
  const { user } = useSelector((state) => state.user);
  const [state, setState] = useState([]);
  const [stateValue, setstateValue] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [citesValue, setCitesValue] = useState([]);
  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  useEffect(() => {
    setCitesValue(data[stateValue]);
  }, [stateValue]);

  const onFinish = (value) => {
    setIsSubmit(true);

    value.supervisorsNumber = `+1${value.supervisorsNumber}`;
    value.workPhone = `+1${value.workPhone}`;
    let data = {};
    data.manualVerification = {};
    data.manualVerification.employerDetails = value;

    dispatch(applicationProcess(data)).then(({ payload }) => {
      setIsSubmit(false);
      onMainTabNext("rental-history");
    });
  };

  useEffect(() => {
    if (applicationProcessData && applicationProcessData?.id) {
      if (
        applicationProcessData?.incomeAndEmployment &&
        Object.values(
          applicationProcessData?.incomeAndEmployment?.manualVerification
            ?.employerDetails
        ).length
      ) {
        form.setFieldsValue({
          businessName:
            applicationProcessData?.incomeAndEmployment?.manualVerification
              ?.employerDetails?.businessName,
          address:
            applicationProcessData?.incomeAndEmployment?.manualVerification
              ?.employerDetails?.address,
          state:
            applicationProcessData?.incomeAndEmployment?.manualVerification
              ?.employerDetails?.state,
          city: applicationProcessData?.incomeAndEmployment?.manualVerification
            ?.employerDetails?.city,
          zipcode:
            applicationProcessData?.incomeAndEmployment?.manualVerification
              ?.employerDetails?.zipcode,
          position:
            applicationProcessData?.incomeAndEmployment?.manualVerification
              ?.employerDetails?.position,
          workPhone:
            applicationProcessData?.incomeAndEmployment?.manualVerification?.employerDetails?.workPhone?.slice(
              2
            ),
          grossMonthlyIncome:
            applicationProcessData?.incomeAndEmployment?.manualVerification
              ?.employerDetails?.grossMonthlyIncome,
          dateStarted: moment(
            applicationProcessData?.incomeAndEmployment?.manualVerification
              ?.employerDetails?.dateStarted
          ),
          supervisorsName:
            applicationProcessData?.incomeAndEmployment?.manualVerification
              ?.employerDetails?.supervisorsName,
          supervisorsNumber:
            applicationProcessData?.incomeAndEmployment?.manualVerification?.employerDetails?.supervisorsNumber?.slice(
              2
            ),
        });
      }
    }
  }, [applicationProcessData]);

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onChange = (newValue) => {
    setstateValue(newValue);
    form.setFieldsValue({ city: [] });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <GeneralProfileWrap>
      <h1>Employer Details</h1>
      <FormWrap mt="0">
        <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
          <StyledForms>
            <Form
              layout="vertical"
              // requiredMark={"optional"}
              form={form}
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="businessName"
                  label="Business Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="address"
                  label="Address"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder=""
                    onChange={onChange}
                    suffixIcon={<IoIosArrowDown />}
                  >
                    {states.length &&
                      states.map((state) => (
                        <Option value={state}>{state}</Option>
                      ))}
                    {/* <Option value="3BHK">Texas</Option>
                        <Option value="4BHK">4BHk</Option> */}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder=""
                    // onChange={}
                    suffixIcon={<IoIosArrowDown />}
                  >
                    {citesValue
                      ? citesValue.map((cites) => (
                          <Option value={cites}>{cites}</Option>
                        ))
                      : ""}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="zipcode"
                  label="Zip code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input type="number" pattern="[0-9]*" />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="position"
                  label="Position"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  className="input__affix"
                  name="workPhone"
                  label="Work Phone"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    type="number"
                    prefix={`+1`}
                    onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
                  />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  className="input__affix"
                  name="grossMonthlyIncome"
                  label="Gross Monthly Income"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input type="number" pattern="[0-9]*" prefix={`$`} />
                </Form.Item>
                <Form.Item
                  name="dateStarted"
                  label="Date Started"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker
                    placeholder=""
                    format={monthFormat}
                    // picker="day"
                    // disabledDate={(current) => {
                    //   return moment().add(-1, "days") <= current;
                    // }}
                    allowClear={false}
                  />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="supervisorsName"
                  label="Supervisor’s Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  className="input__affix"
                  name="supervisorsNumber"
                  label="Supervisor’s Number"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    pattern="[0-9]*"
                    type="number"
                    prefix={`+1`}
                    onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
                  />
                </Form.Item>
              </StyledFormItem>

              <Form.Item>
                <Row justify="space-between" style={{ alignItems: "center" }}>
                  <Col
                    xs={10}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="back__btn"
                  >
                    <IoIosArrowBack onClick={() => onBackClicked()} />
                    <h2 onClick={() => onBackClicked()}>back</h2>
                  </Col>
                  <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Row justify="end" style={{ alignItems: "center" }}>
                      <Col>
                        <p className="cancel__btn">Cancel</p>
                      </Col>
                      <Col xs={10} sm={8} md={8} lg={8} xl={6} xxl={6}>
                        <StyledButton
                          mt="0"
                          loading={isSubmit}
                          htmlType="submit"
                        >
                          next
                        </StyledButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </StyledForms>
        </StyledForm>
      </FormWrap>
    </GeneralProfileWrap>
  );
}

export default EmployerDetails;
