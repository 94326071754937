import React from "react";

const tvSvg = ({
  wSize = "25",
  hSize = "18",
  bgColor = "#ffffff",
  color = "none",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox={`0 0 25 18`}
      {...props}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M23.5882 1H1V15.1176H23.5882V1Z"
          stroke="black"
          stroke-miterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M18.8825 17H5.70605"
          stroke="black"
          stroke-miterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M15.5776 8.08209L10.3913 5.08773C10.2986 5.03456 10.1838 5.03456 10.0915 5.08773C9.99835 5.14091 9.94141 5.23973 9.94141 5.34656V11.3367C9.94141 11.444 9.99835 11.5428 10.0911 11.596C10.1372 11.6228 10.1889 11.636 10.2407 11.636C10.292 11.636 10.3442 11.6228 10.3903 11.596L15.5767 8.60162C15.6694 8.54797 15.7263 8.44915 15.7263 8.34185C15.7263 8.23456 15.6703 8.13573 15.5776 8.08209Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={wSize} height={hSize} fill={bgColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default tvSvg;
