import React from "react";
import styled from "styled-components";
import { Row, Col, Input, Button, Menu, Divider } from "antd";
import { Container } from "../container/Container";
import {
  AvenewLogo,
  InstaGram,
  LinkedLn,
  FooterLocation,
  FooterCall,
  FooterEmail,
  EmailLogo,
  SendMail,
  FacebookSvg,
  TwitterLogo,
} from "../../assets/svgs";
import { NewsLetter } from "../aboutAvenew/AboutAvenew";
import { Link } from "react-router-dom";

function Footer() {
  function getItem(label, key, children, type) {
    return {
      key,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem("Quick Links", "sub1", [
      getItem(<Link to="/account">My Account</Link>),
      getItem(<Link to="/privacy-policy">Privacy Policy</Link>),
      getItem(<Link to="/terms-conditions">Terms and Conditions</Link>),
      getItem(<Link to="/about-us">About us</Link>),
      getItem("Career"),
      getItem("Sitemap"),
    ]),
    getItem("Featured City", "sub2", [
      getItem("Alberta"),
      getItem("British Columbia"),
      getItem("Manitoba"),
      getItem("New Brunswick"),
      getItem("Newfoundland and Labrador"),
      getItem("Northwest Territories"),
    ]),
    getItem("Contact us", "sub3", [
      getItem("4517 Washington Ave. Manchester, Kentucky 39495"),
      getItem("+1 123 456 779"),
      getItem("support@avenew.app"),
    ]),
  ];

  const onClick = (e) => {
    // console.log("click ", e);
  };

  return (
    <>
      <FooterWrap>
        <Row justify="center" style={{ alignItems: "center" }}>
          <Col
            xs={24}
            sm={18}
            md={16}
            lg={12}
            xl={10}
            xxl={10}
            style={{ textAlign: "center" }}
          >
            <h2>Subscribe to our newsletter and stay updated</h2>
          </Col>
          <Col xs={24} sm={14} md={12} lg={8} xl={9} xxl={9}>
            <SendEmail>
              <Input.Group compact>
                <Input
                  prefix={<EmailLogo />}
                  placeholder="Enter Your Email"
                  style={{
                    width: "calc(100% - 52px)",
                    height: "50px",
                  }}
                  type="email"
                />
                <Button>
                  <SendMail />
                </Button>
              </Input.Group>
            </SendEmail>
          </Col>
        </Row>
      </FooterWrap>
      <NewsLetter>
        <Container paddings="0" padding="0 0 30px 0">
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
              <Menu
                onClick={onClick}
                mode="inline"
                items={items}
                selectable={false}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              xxl={6}
              className="avenew__bottom__logo"
            >
              <img src="/images/avenewLogo.svg" className="avenew__title" />

              {/* <AvenewLogo /> */}
              <div className="social__icon">
                <Row gutter={[16, 16]} className="icon__wrap">
                  <Col xs={5} sm={2} md={2} lg={4} xl={3} xxl={3}>
                    <FacebookSvg wSize="34" hSize="34" />
                  </Col>
                  <Col xs={5} sm={2} md={2} lg={4} xl={3} xxl={3}>
                    <TwitterLogo />
                  </Col>
                  <Col xs={5} sm={2} md={2} lg={4} xl={3} xxl={3}>
                    <LinkedLn />
                  </Col>
                  <Col xs={5} sm={2} md={2} lg={4} xl={3} xxl={3}>
                    <InstaGram />
                  </Col>
                </Row>
              </div>
              <h3>&#169; 2022 Avenew</h3>
            </Col>

            <Col xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}>
              <div>
                <h2>Quick Links</h2>
                <p>
                  <Link to="/account">My account</Link>
                </p>
                <p>
                  <Link to="/privacy-policy">Privacy policy</Link>
                </p>
                <p>
                  <Link to="/terms-conditions">Terms and Condition</Link>
                </p>
                <p>
                  <Link to="/about-us">About us</Link>
                </p>
                <p>
                  <Link to="/">Career</Link>
                </p>
                <p>
                  <Link to="/">Sitemap</Link>
                </p>
              </div>
            </Col>

            <Col xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}>
              <div>
                <h2>Featured City</h2>
                <p>
                  <Link to="/">Alberta</Link>
                </p>
                <p>
                  <Link to="/">British Columbia</Link>
                </p>
                <p>
                  <Link to="/">Manitoba</Link>
                </p>
                <p>
                  <Link to="/">New Brunswick</Link>
                </p>
                <p>
                  <Link to="/">Newfoundland and Labrador</Link>
                </p>
                <p>
                  <Link to="/">Northwest Territories</Link>
                </p>
              </div>
            </Col>

            <Col xs={0} sm={0} md={0} lg={6} xl={6} xxl={6}>
              <h2>Contact us</h2>
              <p>
                <FooterLocation /> 4517 Washington Ave. Manchester, <br />
                Kentucky 39495
              </p>
              <p>
                <FooterCall /> +1 123 456 779
              </p>
              <p>
                <FooterEmail /> support@avenew.app
              </p>
            </Col>
          </Row>
        </Container>
      </NewsLetter>
    </>
  );
}

export default Footer;

export const SendEmail = styled.div`
  border-left: 0;

  .ant-btn:active {
    border-color: #fff;
  }

  .ant-input-prefix {
    margin-right: 20px;
  }

  .ant-btn {
    height: 50px;
    border-left: 0;
    color: #f67c36;
    background: #fff;
    border: none;
    svg {
      margin-top: 5px;
    }
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: #f67c36;
    border-color: #d9d9d9;
    background: #fff;
  }

  .ant-input:hover {
    border-right: none;
    border-color: #d9d9d9;
  }

  .ant-input {
    background: #fff;
    border-right: none;
  }
  .ant-input-group.ant-input-group-compact > *:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #fff;
    border: none;
  }
  .ant-input-group.ant-input-group-compact > *:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .ant-input:focus,
  .ant-input-focused {
    border-color: #eeeeee;
    box-shadow: 0 0 0 2px #eeeeee;
    border-right-width: 0;
    outline: 0;
  }

  .ant-input-affix-wrapper:focus {
    border-color: #fff;
    box-shadow: none;
    border-right-width: 1px;
    outline: 0;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #fff;
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-color: #fff;
  }
`;

export const FooterWrap = styled.div`
  max-width: 1400px;
  margin: auto;
  background: #ecf2ff;
  padding: 10px 0;
  margin-bottom: 70px;

  h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-bottom: 0;
  }

  @media (max-width: 1550px) {
    margin-bottom: 55px;

    h2 {
      font-size: 20px;
      line-height: 28px;
    }
  }
  @media (max-width: 991.98px) {
    padding: 30px;
    h2 {
      margin-bottom: 20px;
    }
  }
`;
