import { Checkbox, Col, Form, Radio, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  Alabama,
  Alaska,
  AmericanSamoa,
  AvenewImg,
  BathSvg,
  BedSvg,
} from "../../assets/svgs";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import {
  StyledForm,
  StyledForms,
} from "../Profile-verification/BackgroundCheck";
import { StyledButton } from "../property-details/apply-for-property-modal/ApplyForPropertyModal";
import { FormWrap } from "./sub-tabs/GeneralProfile";
import StateProfileTabs from "./sub-tabs/state-profile-tabs/StateProfileTabs";
import states from "./sub-tabs/states.json";
import { IoIosArrowBack } from "react-icons/io";

function StateProfile({ onBackClick }) {
  const clickRef = useRef(null);
  const location = useLocation();
  const { width } = useWindowSize();
  const [value, setValue] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const [showData, setShowData] = useState(10);

  const onChange = (e) => {
    setValue(e.target.checked);
  };

  const [stringSort, setStringSort] = useState(100);

  useEffect(() => {
    if (width < 769) {
      setStringSort(18);
    } else {
      setStringSort(100);
    }
  }, [width, location.pathname]);

  const slpitFunc = (value) => {
    if (value?.length > 25) {
      return value.slice(0, stringSort) + "..." + value.slice(-5);
    } else {
      return value;
    }
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("applyingProperty") &&
      JSON.parse(sessionStorage.getItem("applyingProperty"))?.id
    ) {
      const item = JSON.parse(sessionStorage.getItem("applyingProperty"));
      if (item?.id) {
        setSelectedItem(item);
      } else {
        setSelectedItem({});
      }
    } else {
    }
  }, [sessionStorage.getItem("applyingProperty")]);

  return (
    <>
      {isNext ? (
        <StateProfileTabs onBackClick={() => setIsNext(false)} />
      ) : (
        <GeneralProfileWrap>
          <h1>
            It looks like you were looking at the listing below. Would you like
            to continue filling out the Texas profile for that listing? not
            select a state from below.
          </h1>
          {selectedItem?.id ? (
            <UnitBox>
              <Row gutter={[10, 10]}>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  {selectedItem?.images && selectedItem?.images?.length ? (
                    <>
                      {selectedItem.images.map((img, idx) => (
                        <div key={idx}>
                          {img.isCover === true ? (
                            <img alt="example" src={img.path} />
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                    </>
                  ) : (
                    <AvenewImg
                      className="svg__border"
                      style={{ background: "#eeeeee", height: "175px" }}
                    />
                  )}
                </Col>
                <Col style={{ alignItems: "center", display: "flex" }}>
                  <Row>
                    <Col span={24}>
                      <h2>{selectedItem?.unit_name}</h2>
                    </Col>
                    <Col style={{ display: "flex" }} span={24}>
                      <Row gutter={[10, 10]}>
                        {selectedItem?.bath ? (
                          <Col>
                            <SmallBox>
                              <p>
                                <BathSvg />
                              </p>
                              <span>Bath : {selectedItem?.bath}</span>
                            </SmallBox>
                          </Col>
                        ) : (
                          ""
                        )}
                        {selectedItem?.bed ? (
                          <Col>
                            <SmallBox>
                              <p>
                                <BathSvg />
                              </p>
                              <span>Bed : {selectedItem?.bed}</span>
                            </SmallBox>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <ContinueWrap>
                  <p>Continue</p>
                </ContinueWrap>
              </Row>
            </UnitBox>
          ) : (
            ""
          )}
          {/* 
      <Alabama />
      <Alaska /> */}

          <h3>Select State</h3>
          <Row gutter={[20, 20]}>
            {states?.slice(0, showData).map((val, id) => (
              <Col key={id} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <StateBox>
                  <Row style={{ alignItems: "center" }}>
                    <Col
                      style={{ alignItems: "center", display: "flex" }}
                      span={4}
                    >
                      <Alabama />
                    </Col>
                    <Col span={20}>
                      <Row justify="space-between">
                        <Col>
                          <p>{slpitFunc(val.name)}</p>
                        </Col>
                        <Col>
                          <Checkbox
                            ref={clickRef}
                            // options={plainOptions}
                            // defaultValue={["Apple"]}
                            onChange={onChange}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </StateBox>
              </Col>
            ))}
          </Row>
          {showData < states.length ? (
            <Row justify="end">
              <Col
                style={{ marginTop: "30px" }}
                onClick={() => setShowData(showData + 10)}
              >
                <span className="view__more">View More</span>
              </Col>
            </Row>
          ) : (
            <Row justify="end">
              <Col
                style={{ marginTop: "30px" }}
                onClick={() => setShowData(10)}
              >
                <span className="view__more">View Less</span>
              </Col>
            </Row>
          )}

          <FormWrap>
            <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
              <StyledForms>
                <Form>
                  <Form.Item>
                    <Row
                      justify="space-between"
                      style={{ alignItems: "center", marginTop: "20px" }}
                    >
                      <Col
                        xs={10}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="back__btn"
                      >
                        <IoIosArrowBack
                          onClick={() => onBackClick("state-profile")}
                        />
                        <h2 onClick={() => onBackClick("state-profile")}>
                          back
                        </h2>
                      </Col>
                      <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Row justify="end" style={{ alignItems: "center" }}>
                          <Col>
                            <p className="cancel__btn">Cancel</p>
                          </Col>
                          <Col xs={10} sm={8} md={8} lg={8} xl={6} xxl={6}>
                            <StyledButton
                              mt="0"
                              loading={isSubmit}
                              onClick={() => setIsNext(true)}
                            >
                              next
                            </StyledButton>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </StyledForms>
            </StyledForm>
          </FormWrap>
        </GeneralProfileWrap>
      )}
    </>
  );
}

export default StateProfile;

const StateBox = styled.div`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 12px;

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #eeeeee;
    background-color: #2b353f;
  }

  .ant-checkbox-checked::after {
    border: 1px solid #eeeeee;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #eeeeee;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2b353f;
    border: 1px solid #2b353f;
  }

  .ant-checkbox-inner {
    background-color: #eeeeee;
    border: 1px solid #eeeeee;
    border-radius: 50%;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 0;
  }
`;

const GeneralProfileWrap = styled.div`
  .view__more {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
    color: #4160ac;
    cursor: pointer;
    margin-top: 30px;
  }

  .back__btn h2,
  svg {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #777777;
    margin-left: 10px;
    cursor: pointer;
  }
  h1 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: ${({ mb }) => mb || "30px"};
  }
  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: ${({ mt }) => mt || "20px"};
    margin-bottom: ${({ mb }) => mb || "15px"};
    color: #777777;
  }

  @media (max-width: 769px) {
    h1 {
      display: ${({ display }) => display || "visible"};
      margin-bottom: ${({ mb }) => mb || "20px"};
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }
  }
`;

const SmallBox = styled.div`
  align-items: center;
  display: flex;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 10px 10px 10px 0;

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #848687;
    margin-bottom: 0;
  }
  span {
    margin-left: 10px;
    line-height: 24px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.05em;
    color: #848687;
  }

  @media (max-width: 769px) {
    padding: 5px 5px 5px 0;
  }
`;

const ContinueWrap = styled.div`
  position: absolute;
  right: 10px;
  background: #ffe1d0;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #f67c36;
    margin-bottom: 0;
  }

  @media (max-width: 769px) {
    display: none;
  }
`;

const UnitBox = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  position: relative;

  img {
    width: 100%;
  }

  h2 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
    color: #2b353f;
  }

  @media (max-width: 769px) {
    h2 {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #2b353f;
    }
  }
`;
