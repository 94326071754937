export const MapData = [
  {
    _id: 1,
    position: [33.836466, -84.549665],
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card1.png",
  },
  {
    _id: 2,
    position: [33.497196, -84.32679],
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card2.png",
  },
  {
    _id: 3,
    position: [33.680933, -84.653597],
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card3.png",
  },
  {
    _id: 4,
    position: [33.890165, -84.230942],
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card4.png",
  },
  {
    _id: 5,
    rating: "4",
    position: [33.618449, -84.106224],
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card5.png",
  },
  {
    _id: 6,
    rating: "4",
    position: [33.873867, -84.423792],
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card6.png",
  },
  {
    _id: 7,
    rating: "4",
    position: [33.992674, -84.593547],
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card6.png",
  },
  {
    _id: 8,
    rating: "4",
    position: [33.680933, -84.376446],
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card5.png",
  },
  {
    _id: 9,
    position: [33.775055, -84.189369],
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card4.png",
  },
  {
    _id: 10,
    position: [33.757467, -84.511545],
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card4.png",
  },
  {
    _id: 11,
    position: [33.8209, -84.353241],
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card4.png",
  },
];
