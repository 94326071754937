import { Col, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PageNotFoundSvg } from "../../assets/svgs";
import { Container } from "../../components/container/Container";
import { StyledButton } from "../property-details/apply-for-property-modal/ApplyForPropertyModal";
import { NoInvoiceWrap } from "../unit-invoices/UnitInvoice";

function PageNotFound({ isNoInternet = false }) {
  const navigate = useNavigate();
  // console.log(isNoInternet, "isNoInternet");

  return (
    <Container paddings="0" padding="30px 0">
      <NoInvoiceWrap mb="20px" mt="30px">
        <PageNotFoundSvg />
        {isNoInternet ? (
          <h1>Opps! Bad Gateway server</h1>
        ) : (
          <h1>Opps! Page not found</h1>
        )}
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p>
        {isNoInternet ? (
          ""
        ) : (
          <Row justify="center" style={{ marginBottom: "40px" }}>
            <Col xs={20} sm={14} md={8} lg={4} xl={4} xxl={4}>
              <StyledButton onClick={() => navigate("/")}>
                Back to Home
              </StyledButton>
            </Col>
          </Row>
        )}
      </NoInvoiceWrap>
    </Container>
  );
}

export default PageNotFound;
