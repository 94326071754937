import { Button, Col, Form, Input, message, Row, Select } from "antd";
import React, { useEffect } from "react";
import styled from "styled-components";
import Header from "../../components/layout/header/Header";
import { FacebookSvg, GoogleSvg, TwitterSvg } from "../../assets/svgs";
// import FadeIn from "react-fade-in";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  LoginBottomWrap,
  LoginLeft,
  LoginPageMain,
  LoginWithButton,
  LoginWrap,
  StyledButton,
  StyledFormItem,
} from "../login/Login";
import { useState } from "react";
import {
  register,
  verifyResendOtp,
  verifySignUp,
} from "../../store/reducers/user.reducer";
import { useDispatch } from "react-redux";
import OtpModal from "../../components/otpModal/OtpModal";

function Signup() {
  var formRef = React.createRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { Option } = Select;

  const [emailValidation, setEmailValidation] = useState("");
  const [loadings, setloadings] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState("");
  const [formVal, setFormVal] = useState({});
  const [loadingsOtp, setloadingsOtp] = useState(false);
  const [signUpUserData, setSignUpUserData] = useState({});
  const [invalidCode, setInvalidCode] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [asetNewOtp, setSetNewOtp] = useState("");

  const otpValue = (value) => {
    setVerifyOtp(value);
  };

  useEffect(() => {
    setSignUpUserData({
      ...signUpUserData,
      otp: verifyOtp,
    });
    setSetNewOtp(verifyOtp);
  }, [verifyOtp]);

  useEffect(() => {
    if (asetNewOtp) {
      setloadingsOtp(true);
      dispatch(verifySignUp(signUpUserData)).then(({ payload }) => {
        setInvalidCode(payload);
        setloadingsOtp(false);
      });
      // navigate("/registration/company-details");
      // if (verifyOtp === "123654") {
      //   setModalState(false);
      // }
    }
  }, [asetNewOtp]);

  // const onFinishOtp = async () => {
  //   setloadingsOtp(true);
  //   await dispatch(verifySignUp(signUpUserData)).then(({ payload }) => {
  //     setInvalidCode(payload);
  //     setloadingsOtp(false);
  //   });
  //   // navigate("/registration/company-details");
  //   // if (verifyOtp === "123654") {
  //   //   setModalState(false);
  //   // }
  // };

  const onFinish = async (value) => {
    setEmailValidation("");
    value.email = value.email.toLowerCase();
    value.phoneNumber = `+1${value.phoneNumber}`;

    setFormVal(value);
    if (params.id) {
      value.inviteCode = params.id;
    }
    setloadings(true);
    await dispatch(register(value)).then(({ payload }) => {
      setloadings(false);
      setEmailValidation(payload?.Error || payload?.error);

      if (payload?.Error || payload?.error) {
      } else {
        setModalState(true);
      }
    });
    setSignUpUserData({
      email: value.email,
      otp: "",
      password: value.password,
      user: {
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        phoneNumber: value.phoneNumber,
        isSeen: false,
      },
    });
  };

  // const onFinishOtp = async () => {
  //   setloadingsOtp(true);
  //   await dispatch(verifySignUp(signUpUserData)).then(({ payload }) => {
  //     setInvalidCode(payload);
  //     setloadingsOtp(false);
  //   });
  //   // navigate("/registration/company-details");
  //   // if (verifyOtp === "123654") {
  //   //   setModalState(false);
  //   // }
  // };

  const onFinishResendOtp = async () => {
    await dispatch(verifyResendOtp({ email: formVal.email })).then(
      ({ payload }) => {
        // setInvalidCode(payload);
        // setloadingsOtp(false);
      }
    );
  };

  useEffect(() => {
    if (invalidCode?.Error) {
      setErrorMessage("Invalid code");
    } else if (invalidCode?.id) {
      setModalState(false);
      message.success("User Created Successfully");
      navigate("/");
    }
  }, [invalidCode]);

  useEffect(() => {
    if (emailValidation?.length && emailValidation?.includes("already exist")) {
      formRef.current.setFields([
        {
          name: "email",
          errors: ["Email is already exist"],
        },
      ]);
    } else if (
      emailValidation?.length &&
      emailValidation?.includes("invited Contact Number")
    ) {
      formRef.current.setFields([
        {
          name: "phoneNumber",
          errors: ["Please provide invited contact number"],
        },
      ]);
    }
  }, [emailValidation, loadings]);

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
  };

  const prefixSelector = (
    <SelectArrow>
      <Form.Item name="prefix" noStyle>
        {/* <span>+1</span> */}
        <Select
          style={{
            width: 70,
          }}
          defaultValue="+1"
        >
          <Option value="+1">+1</Option>
        </Select>
      </Form.Item>
    </SelectArrow>
  );

  return (
    <div>
      <Header />
      <LoginMain>
        <LoginPageMain>
          {/* <FadeIn> */}
          <LoginWrap>
            <LoginLeft>
              <h2>Signup</h2>
              <p>
                Sign up to access our tenant site, where you can monitor your
                balance, pay rent, explore and maintain properties, and directly
                connect with the home owners.
              </p>
              <Form
                layout="vertical"
                onFinish={onFinish}
                requiredMark={"optional"}
                validateMessages={validateMessages}
                ref={formRef}
              >
                <StyledFormItem smwh="100">
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </StyledFormItem>
                <StyledFormItem wh="100" smwh="100">
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </StyledFormItem>
                <StyledFormItem wh="100" smwh="100">
                  <Form.Item
                    name="phoneNumber"
                    label="Contact Number"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input pattern="[0-9]*" addonBefore={prefixSelector} type="number" />
                  </Form.Item>
                </StyledFormItem>
                <StyledFormItem wh="100" smwh="100">
                  <Form.Item
                    name="password"
                    label="Password"
                    className="password-w-icon"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        min: 8,
                        pattern: new RegExp(
                          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                        ),
                        message:
                          "Use 8 and more characters with a mix of letters, numbers & symbols",
                      },
                    ]}
                  >
                    <Input type="password" />
                  </Form.Item>
                </StyledFormItem>

                <Form.Item>
                  <StyledButton htmlType="submit" loading={loadings}>
                    Signup Now
                  </StyledButton>
                </Form.Item>
              </Form>
              <LoginBottomWrap>
                <p>
                  Already have account? <Link to="/login"> Login Now</Link>
                </p>
                <Row gutter={[10, 10]}>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <LoginWithButton bgColor="#E8F2FF" icon={<FacebookSvg />}>
                      Facebook
                    </LoginWithButton>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <LoginWithButton bgColor="#FFECEA" icon={<GoogleSvg />}>
                      Google
                    </LoginWithButton>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <LoginWithButton bgColor="#DFF3FF" icon={<TwitterSvg />}>
                      Twitter
                    </LoginWithButton>
                  </Col>
                </Row>
              </LoginBottomWrap>
            </LoginLeft>
          </LoginWrap>
          {/* </FadeIn> */}
        </LoginPageMain>
      </LoginMain>
      <OtpModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        // onFinishOtp={onFinishOtp}
        onSubmit={() => setModalStates(true)}
        otpValue={otpValue}
        errorMessage={errorMessage}
        loadingsOtp={loadingsOtp}
        onFinishResendOtp={onFinishResendOtp}
      />
    </div>
  );
}

export default Signup;

export const SelectArrow = styled.div`
  .ant-select-arrow {
    display: none;
  }
`;

export const LoginMain = styled.div`
  width: 100%;
  ${"" /* height: 100vh; */}
  display: flex;
  position: relative;
  background-image: url("/images/loginBg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: ${(props) => props.theme.colors.primary};
  box-shadow: inset 0 0 0 1000px ${(props) => props.theme.colors.primary}30;

  background-position: initial;
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
  @keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`;
