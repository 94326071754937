import React from "react";

const menuSvg = ({
  wSize = "16",
  hSize = "19",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0H21V2.375H0V0ZM0 8.3125H14V10.6875H0V8.3125ZM0 16.625H21V19H0V16.625Z"
        fill="#2B353F"
      />
    </svg>
  );
};

export default menuSvg;
