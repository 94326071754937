import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpAPI } from "../../api/index";
import { message } from "antd";
import axios from "axios";
import { querystring } from "../../utils/fn";
import { Navigate, useParams } from "react-router";
import App from "../../App";

const initialState = {
  user: null,
  accessToken: "",
  loading: false,
  isLoggedIn: false,
  error: null,
  emailVerification: false,
  allUnit: {},
  applicationProcessData: {},
  payment: {},
  allNotificatios: {},
};

export const register = createAsyncThunk("sendOtp", async (data, thunkAPI) => {
  try {
    const response = await httpAPI("/sendOtp", data, "POST", true);
    return { ...response.data };
  } catch (error) {
    if (error) {
      return error;
    }
  }
});

export const createChat = createAsyncThunk(
  "create/chat",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI("/chat", data, "POST");
      return { ...response.data };
    } catch (error) {
      return error;
    }
  }
);

export const verifySignUp = createAsyncThunk(
  "verifyOtp",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI("/verifyOtp", data, "POST", true);
      return { ...response.data };
    } catch (error) {
      if (error) {
        return error;
      }
    }
  }
);

export const login = createAsyncThunk("user/login", async (data, thunkAPI) => {
  try {
    const response = await httpAPI("/signIn", data);
    return { ...response.data };
  } catch (error) {
    return error;
  }
});

export const createUser = createAsyncThunk(
  "user/login/create",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI("/user", data);
      return { ...response.data };
    } catch (error) {
      return error;
    }
  }
);

export const applicationProcess = createAsyncThunk(
  "application/process",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPI(`/profile/user/${id}`, data, "PUT");
      return { ...response.data };
    } catch (error) {
      return error;
    }
  }
);

export const getApplicationProcess = createAsyncThunk(
  "get/application/process",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPI(`/profile/user/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      return error;
    }
  }
);

export const getGetMembers = createAsyncThunk(
  "get/all/members",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPI(`/profile/members/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      return error;
    }
  }
);

export const createLinkToken = createAsyncThunk(
  "create/link/token",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPI(`/profile/userToken/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      return error;
    }
  }
);

export const getBankIncome = createAsyncThunk(
  "bank/income",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPI(`/profile/bank-income/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      return error;
    }
  }
);

export const exchangePublicToken = createAsyncThunk(
  "exchange/public/token",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI(
        "/profile/exchange-public-token",
        data,
        "POST",
        true
      );

      return { ...response.data };
    } catch (error) {
      if (error) {
        return error;
      }
    }
  }
);

export const verifyResendOtp = createAsyncThunk(
  "resendOtp",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI("/resendOtp", data, "POST", true);

      return { ...response.data };
    } catch (error) {
      if (error) {
        return error;
      }
    }
  }
);

export const me = createAsyncThunk("user/me", async (data, thunkAPI) => {
  try {
    let id = localStorage.getItem("user_id");
    const response = await httpAPI(`/user/${id}`, data, "GET");
    return { ...response.data };
  } catch (error) {
    message.error(error.Error || error.message);
    return thunkAPI.rejectWithValue(error);
  }
});

export const resetPassword = createAsyncThunk(
  "user/reset-password",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPI(`/reset-password?id=${id}`, data, "POST");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editProfile = createAsyncThunk("edit", async (data, thunkAPI) => {
  try {
    const response = await httpAPI(`/user`, data, "PUT");
    return { ...response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getUnits = createAsyncThunk("all/unit", async (data, thunkAPI) => {
  try {
    const response = await httpAPI(`/units`, data, "GET");
    return { ...response.data };
  } catch (error) {
    message.error(error.Error || error.message);
    return thunkAPI.rejectWithValue(error);
  }
});

export const getUnitsDetails = createAsyncThunk(
  "unit/details",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("unit-details");

      const response = await httpAPI(`/unit/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateInvitation = createAsyncThunk(
  "updateInvitation",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI(`/invitation`, data, "PUT");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateApplicationInvitation = createAsyncThunk(
  "update/application/invitation",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI(`/profile/invitation`, data, "PUT");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createPaymentRent = createAsyncThunk(
  "/payment",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI("/payment", data, "POST");
      return { ...response.data };
    } catch (error) {
      return error;
    }
  }
);

export const createSmsPaymentRent = createAsyncThunk(
  "sms/payment",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI("/anonymous-payment", data, "POST");
      return { ...response.data };
    } catch (error) {
      return error;
    }
  }
);

export const createApplicationPaymentRent = createAsyncThunk(
  "application/payment",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI("/profile/payment", data, "POST");
      return { ...response.data };
    } catch (error) {
      return error;
    }
  }
);

export const getTransection = createAsyncThunk(
  "transections",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPI(`/transactions/user/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getInvoicesUnit = createAsyncThunk(
  "Unit/invoices",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("unit_id");

      const response = await httpAPI(`/invoices/unit/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getInvoice = createAsyncThunk(
  "Invoice",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("invoice_id");

      const response = await httpAPI(`/invoices/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSmsInvoice = createAsyncThunk(
  "invoice/sms",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("invoice_id");

      const response = await httpAPI(`/invoice/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getChats = createAsyncThunk("chats", async (data, thunkAPI) => {
  try {
    let id = localStorage.getItem("user_id");
    const response = await httpAPI(`/chats/prospect/${id}`, data, "GET");
    return { ...response.data };
  } catch (error) {
    message.error(error.Error || error.message);
    return thunkAPI.rejectWithValue(error);
  }
});

export const getMessages = createAsyncThunk(
  "messages",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("chat_id");
      const response = await httpAPI(`/messages/chat/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addOrRemoveFavorites = createAsyncThunk(
  "/add/remove/favorites",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI("/units/favorites", data, "POST");
      return { ...response.data };
    } catch (error) {
      return error;
    }
  }
);

export const createMessage = createAsyncThunk(
  "/message",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI("/message", data, "POST");
      return { ...response.data };
    } catch (error) {
      return error;
    }
  }
);

export const applicationStart = createAsyncThunk(
  "/application",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI("/application", data, "POST");
      return { ...response.data };
    } catch (error) {
      return error;
    }
  }
);

export const inviteMember = createAsyncThunk(
  "/invite/member",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI("/profile/invite-member", data, "POST");
      return { ...response.data };
    } catch (error) {
      return error;
    }
  }
);

export const leaseSign = createAsyncThunk(
  "/application/PUT",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI("/application", data, "PUT");
      return { ...response.data };
    } catch (error) {
      return error;
    }
  }
);

export const getNotifications = createAsyncThunk(
  "notifications",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPI(
        `/notifications/prospect/${id}`,
        data,
        "GET"
      );
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getFavoriteUnits = createAsyncThunk(
  "favorite/units",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPI(
        `/units/favorites/user/${id}`,
        data,
        "GET"
      );
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getApplications = createAsyncThunk(
  "favorite/units",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPI(
        `/applications/prospect/${id}`,
        data,
        "GET"
      );
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const application = createAsyncThunk(
  "profile/verification",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI(`/profile-verification`, data, "PUT");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const counterSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.clear();
      state.user = null;
      state.accessToken = "";
      state.loading = false;
      state.isLoggedIn = false;
      state.error = null;
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, { payload }) => {
      if (!payload.error && !payload.Error) {
        state.loading = false;

        localStorage.setItem("idToken", payload.AuthenticationResult?.IdToken);
        localStorage.setItem(
          "accessToken",
          payload.AuthenticationResult?.AccessToken
        );
        localStorage.setItem("user_id", payload.user_id);
        state.isLoggedIn = true;
        state.accessToken = payload.AuthenticationResult?.AccessToken;
      }
    },
    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.isLoggedIn = false;
      localStorage.setItem("isLoggedIn", false);
    },
    [verifySignUp.fulfilled]: (state, { payload }) => {
      if (!payload.error && !payload.Error) {
        if (payload?.AuthenticationResult) {
          state.loading = false;

          localStorage.setItem(
            "idToken",
            payload.AuthenticationResult?.IdToken
          );
          localStorage.setItem(
            "accessToken",
            payload.AuthenticationResult?.AccessToken
          );
          localStorage.setItem("user_id", payload.user_id);
          state.isLoggedIn = true;
          state.accessToken = payload.AuthenticationResult?.AccessToken;
        }
      }
    },
    [verifySignUp.pending]: (state) => {
      state.loading = true;
    },
    [verifySignUp.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.isLoggedIn = false;
      localStorage.setItem("isLoggedIn", false);
    },
    [createUser.fulfilled]: (state, { payload }) => {
      if (!payload.error && !payload.Error) {
        state.loading = false;

        localStorage.setItem("idToken", payload.AuthenticationResult?.IdToken);
        localStorage.setItem(
          "accessToken",
          payload.AuthenticationResult?.AccessToken
        );
        localStorage.setItem("user_id", payload.user.id);
        state.isLoggedIn = true;
        state.accessToken = payload.AuthenticationResult?.AccessToken;
      }
    },
    [createUser.pending]: (state) => {
      state.loading = true;
    },
    [createUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.isLoggedIn = false;
      localStorage.setItem("isLoggedIn", false);
    },
    [me.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.loading = false;

      // state.faviconImage = initialState.faviconImage;
      // state.landingPageLogo = initialState.landingPageLogo;
      // state.backgroundImage = initialState.backgroundImage;
      // state.homePageLogo = initialState.homePageLogo;
    },
    [me.pending]: (state) => {
      state.loading = true;
    },
    [me.rejected]: (state, { payload }) => {
      localStorage.clear();
      state.user = null;
      state.accessToken = "";
      state.loading = false;
      state.isLoggedIn = false;
      state.error = null;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      console.log("resetPassword rejected");
    },
    [createPaymentRent.fulfilled]: (state, { payload }) => {
      state.payment = JSON.parse(payload.result);
      state.loading = false;
    },
    [createPaymentRent.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createPaymentRent.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [createSmsPaymentRent.fulfilled]: (state, { payload }) => {
      state.payment = JSON.parse(payload.result);
      state.loading = false;
    },
    [createSmsPaymentRent.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createSmsPaymentRent.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [getInvoice.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [getInvoice.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getInvoice.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [getSmsInvoice.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [getSmsInvoice.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getSmsInvoice.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [getNotifications.fulfilled]: (state, { payload }) => {
      state.allNotificatios = payload;
      state.loading = false;
    },
    [getNotifications.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getNotifications.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [getUnits.fulfilled]: (state, { payload }) => {
      state.allUnit = payload;
      state.loading = false;
    },
    [getUnits.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getUnits.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [getApplicationProcess.fulfilled]: (state, { payload }) => {
      state.applicationProcessData = payload;
      state.loading = false;
    },
    [getApplicationProcess.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getApplicationProcess.rejected]: (state, { payload }) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { logout } = counterSlice.actions;

export default counterSlice.reducer;
