import React, { useEffect } from "react";
import { Breadcrumb } from "antd";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Container } from "../../components/container/Container";
import Footer from "../../components/footer/Footer";
import {
  BreadCrumbsWrapper,
  BreadcrumbTop,
} from "../property-details/PropertyDetails";
function AboutUs() {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  const breadcrumbNameMap = [
    { path: "/dashboard", label: "Dashboard", isLink: true },
    {
      path: "/account",
      label: "My Account",
      isLink: false,
    },
    {
      path: "/privacy-policy",
      label: "Privacy Policy",
      isLink: false,
    },
    {
      path: "/about-us",
      label: "About Us",
      isLink: false,
    },
  ];

  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      const breadcrumb = breadcrumbNameMap.find((b) => b.path === url);
      return breadcrumb ? (
        <Breadcrumb.Item key={`${breadcrumb.path}_${index}`}>
          {breadcrumb.isLink ? (
            <Link to={breadcrumb.isLink && breadcrumb.path}>
              {breadcrumb.label}
            </Link>
          ) : (
            <span className="breadcrumbs_label">{breadcrumb.label}</span>
          )}
        </Breadcrumb.Item>
      ) : null;
    })
    .filter((i) => i);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <BreadcrumbTop>
        <BreadCrumbsWrapper>
          <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
        </BreadCrumbsWrapper>
      </BreadcrumbTop>
      <ImgWrap>
        <img src="/images/aboutBg.png" />
      </ImgWrap>
      <Container mWid="650" padding="50px 0">
        <TextWrap>
          <h1>About Avenew</h1>
          <p>
            simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard dummy text ever since the
            1500s, when an unknown printer took a galley of type and scrambled
            centuries, but also simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the industry's standard
            dummy
          </p>
        </TextWrap>
      </Container>
    </>
  );
}

export default AboutUs;

export const TextWrap = styled.div`
  text-align: center;
  h1 {
    font-weight: 700;
    font-size: 60px;
    line-height: 80px;
    color: #000000;
    margin-bottom: 25px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: justify;
    color: #aaaaaa;
    margin-bottom: 15px;
    text-align: center;
  }
  @media (max-width: 1550px) {
    h1 {
      font-size: 44px;
      line-height: 58px;
    }
  }
  @media (max-width: 769px) {
    h1 {
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 15px;
    }
    p {
      text-align: justify;
    }
  }
`;

export const ImgWrap = styled.div`
  width: 100%;
  background-repeat: no-repeat;
  img {
    width: inherit;
  }
`;
