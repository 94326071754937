import { Button, Col, Divider, Form, Input, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { PayCash, PayCashLockSm } from "../../assets/svgs";
import { Container } from "../../components/container/Container";
import {
  createSmsPaymentRent,
  getSmsInvoice,
} from "../../store/reducers/user.reducer";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

function PaymentInvoiceSms() {
  const [form] = Form.useForm();
  var formRef = React.createRef();

  const dispatch = useDispatch();
  const [tenantsData, setTenantsData] = useState([]);
  const [paymentToken, setPaymentToken] = useState("");
  const [amountValue, setAmountValue] = useState("");
  const [cashAppPayBtn, setCashAppPayBtn] = useState(false);
  const [cardPayBtn, setCardPayBtn] = useState(false);
  const [isCompLoad, setIsCompLoad] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [viewAmmenities, setViewAmmenities] = useState(false);
  const [subTotalValue, setSubTotalValue] = useState("");

  const params = useParams();
  const { loading } = useSelector((state) => state.user);
  const navigate = useNavigate();

  console.log(tenantsData, "tenantsData");

  useEffect(() => {
    if (paymentToken) {
      setLoadingPayment(true);
      dispatch(
        createSmsPaymentRent({
          sourceId: paymentToken,
          amount: amountValue
            ? amountValue
            : tenantsData?.payableAmount.toString(),
          tenant_id: tenantsData?.tenant_id,
          // invitation_id: tenantsData?.id,
          apartment_name: tenantsData?.apartment_name,
          unit_name: tenantsData?.unit_name,
          unit_id: tenantsData?.unit_id,
          org_id: tenantsData?.org_id,
          firstName: tenantsData?.firstName,
          lastName: tenantsData?.lastName,
          // rate: tenantsData?.rate,
          payableAmount: tenantsData?.payableAmount,
          invoice_id: params.id,
          invitaion_id: localStorage.getItem("invitaion_id"),
        })
      ).then(({ payload }) => {
        navigate(`/payment-confirmation/${params.id}`);
        setLoadingPayment(false);
        console.log(JSON.parse(payload.result), "res>>>");
      });
    }
  }, [paymentToken]);

  useEffect(() => {
    if (params.id) {
      setIsCompLoad(true);
      localStorage.setItem("invoice_id", params.id);
      dispatch(getSmsInvoice()).then(({ payload }) => {
        setIsCompLoad(false);
        setTenantsData(payload[0]);
      });
    }
  }, [params.id]);

  const toUSACurrency = (num) => {
    const curr = num.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return curr;
  };

  function percentage(num, per) {
    return toUSACurrency(num - (num / 100) * per);
  }

  function percentageRemaning(num, per) {
    return toUSACurrency((num / 100) * per);
  }
  function percentageRemaningTotal(num, per) {
    return (num / 100) * per;
  }

  const payableAmount = tenantsData?.payableAmount;
  const paidAmount = tenantsData?.paidAmount;
  const finalAmount = Number(payableAmount - paidAmount);
  const finalAmountTostring = finalAmount.toString();

  // cash-app-pay
  const cashAppFun = async () => {
    // setCashAppPayBtn(true);
    // "sq0idp-mW1NCZDRGXhksh3mjYTunA",
    // "LC15QN4TJG0T3"
    const payment = window.Square.payments(
      process.env.REACT_APP_SQUARE_API_KEY,
      process.env.REACT_APP_SQUARE_LOCATIONID
    );

    const payableAmount = amountValue
      ? amountValue
      : tenantsData?.payableAmount.toString();

    const finalAmountTostring = payableAmount.toString();

    const cashReq = payment.paymentRequest({
      countryCode: "US",
      currencyCode: "USD",
      total: { amount: finalAmountTostring, label: "Total", pending: false },
    });

    {
      /* referenceId: "my-distinct-reference-id", */
    }
    const cashAppPay = await payment.cashAppPay(cashReq, {
      redirectURL: window.location.href,
      referenceId: "my-website-00000001",
    });

    function displayPaymentResults(status) {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      if (status === "SUCCESS") {
        statusContainer.classList.remove("is-failure");
        statusContainer.classList.add("is-success");
      } else {
        statusContainer.classList.remove("is-success");
        statusContainer.classList.add("is-failure");
      }

      statusContainer.style.visibility = "visible";
    }

    cashAppPay.addEventListener("ontokenization", (event) => {
      const { tokenResult } = event.detail;
      const tokenStatus = tokenResult.status;
      if (tokenStatus === "OK") {
        const token = tokenResult.token;
        // displayPaymentResults("SUCCESS");
        setPaymentToken(token);

        // alert(token);
      }
    });

    await cashAppPay.attach("#cash-app-pay", {
      shape: "semiround",
      theme: "dark",
      width: "full",
      size: "medium",
    });
  };

  useEffect(() => {
    cashAppFun();
  }, [tenantsData, subTotalValue]);

  // ach-bank-tf
  const achClickFun = async () => {
    const appId = process.env.REACT_APP_SQUARE_API_KEY;
    const locationId = process.env.REACT_APP_SQUARE_LOCATIONID;

    // async function initializeCard(payments) {
    //   const card = await payments.card();
    //   await card.attach("#card-container");

    //   return card;
    // }

    if (!window.Square) {
      throw new Error("Square.js failed to load properly");
    }

    let payments;
    try {
      payments = window.Square.payments(appId, locationId);
    } catch {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      statusContainer.className = "missing-credentials";
      statusContainer.style.visibility = "visible";
      return;
    }

    // let card;
    // try {
    //   card = await initializeCard(payments);
    // } catch (e) {
    //   console.error("Initializing Card failed", e);
    //   return;
    // }

    let ach;
    try {
      ach = await payments.ach();
    } catch (e) {
      console.error("Initializing ACH failed", e);
      return;
    }
    async function tokenize(paymentMethod, options = {}) {
      const tokenResult = await paymentMethod.tokenize(options);
      if (tokenResult.status === "OK") {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
        }

        throw new Error(errorMessage);
      }
    }

    function displayPaymentResults(status) {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      if (status === "SUCCESS") {
        statusContainer.classList.remove("is-failure");
        statusContainer.classList.add("is-success");
      } else {
        statusContainer.classList.remove("is-success");
        statusContainer.classList.add("is-failure");
      }

      statusContainer.style.visibility = "visible";
    }

    async function handlePaymentMethodSubmission(
      event,
      paymentMethod,
      options
    ) {
      event.preventDefault();

      try {
        // disable the submit button as we await tokenization and make a payment request.
        achButton.disabled = true;
        // cardButton.disabled = true;
        const token = await tokenize(paymentMethod, options);
        setPaymentToken(token);
        displayPaymentResults("SUCCESS");
      } catch (e) {
        // cardButton.disabled = false;
        achButton.disabled = false;
        displayPaymentResults("FAILURE");
        console.error(e.message, "err");
      }
    }

    function getACHOptions(form) {
      const accountHolderName = `shani patel`;

      return { accountHolderName };
    }

    const achButton = document.getElementById("ach-button");
    achButton.addEventListener("click", async function (event) {
      const achOptions = getACHOptions();
      await handlePaymentMethodSubmission(event, ach, achOptions);
    });

    // const cardButton = document.getElementById("card-button");
    // cardButton.addEventListener("click", async function (event) {
    //   await handlePaymentMethodSubmission(event, card);
    // });
  };

  useEffect(() => {
    window.addEventListener("click", achClickFun);
  }, []);

  // card-payment
  const cardFun = async () => {
    setCardPayBtn(true);
    const appId = process.env.REACT_APP_SQUARE_API_KEY;
    const locationId = process.env.REACT_APP_SQUARE_LOCATIONID;

    async function initializeCard(payments) {
      const card = await payments.card();
      await card.attach("#card-container");

      return card;
    }

    if (!window.Square) {
      throw new Error("Square.js failed to load properly");
    }

    let payments;
    try {
      payments = window.Square.payments(appId, locationId);
    } catch {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      statusContainer.className = "missing-credentials";
      statusContainer.style.visibility = "visible";
      return;
    }

    let card;
    try {
      card = await initializeCard(payments);
    } catch (e) {
      console.error("Initializing Card failed", e);
      return;
    }

    function displayPaymentResults(status) {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      if (status === "SUCCESS") {
        statusContainer.classList.remove("is-failure");
        statusContainer.classList.add("is-success");
      } else {
        statusContainer.classList.remove("is-success");
        statusContainer.classList.add("is-failure");
      }

      statusContainer.style.visibility = "visible";
    }

    async function tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === "OK") {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
        }

        throw new Error(errorMessage);
      }
    }

    // Checkpoint 2.
    async function handlePaymentMethodSubmission(event, paymentMethod) {
      event.preventDefault();

      try {
        // disable the submit button as we await tokenization and make a payment request.
        cardButton.disabled = true;
        const token = await tokenize(paymentMethod);
        setPaymentToken(token);

        // const paymentResults = await createPayment(token);
        displayPaymentResults("SUCCESS");

        // console.debug("Payment Success", paymentResults);
        console.debug("Payment Success");
      } catch (e) {
        cardButton.disabled = false;
        displayPaymentResults("FAILURE");
        console.error(e.message);
      }
    }

    const cardButton = document.getElementById("card-button");
    cardButton.addEventListener("click", async function (event) {
      await handlePaymentMethodSubmission(event, card);
    });
  };

  useEffect(() => {}, []);

  const [cardFunCalled, setCardFunCalled] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setCashAppPayBtn(true);
      setCardFunCalled("called");
      // console.log("first")
    }, 3000);
  }, []);

  useEffect(() => {
    if (cardFunCalled != "called") {
      setTimeout(() => {
        setCashAppPayBtn(true);
        setCardFunCalled("called");
        // console.log("first")
      }, 4000);
    }
  }, [cardFunCalled]);

  useEffect(() => {
    if (cashAppPayBtn) {
      cardFun();
    }
    return () => {};
  }, [cashAppPayBtn]);

  const onChangeMultiPropertyUpDown = (e) => {
    setAmountValue(e.target.value);

    // if (0 < Number(e) && Number(e) <= 1) {
    //   setMultiProperty(false);
    // } else if (Number(e) > 1) {
    //   setMultiProperty(true);
    // } else {
    //   setMultiProperty(true);
    // }

    // if (0 < Number(e)) {
    //   setDisableBtn(false);
    // } else {
    //   setDisableBtn(true);
    // }
  };

  useEffect(() => {
    if (amountValue && Number(amountValue) < 0) {
      formRef.current.setFields([
        {
          name: "amount",
          errors: ["Amount should be greater than 1$"],
        },
      ]);
    } else {
      formRef.current.setFields([
        {
          name: "amount",
        },
      ]);
    }
  }, [amountValue]);

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  useEffect(() => {
    // const tPaidAmount = tenantsData?.paidAmount;
    const tRate = Number(tenantsData?.rate);
    const tLateFee = tenantsData?.fees?.lateFee
      ? Number(tenantsData?.fees.lateFee)
      : null;
    const tAvenewFee = tenantsData?.fees?.avenewFees
      ? Number(tenantsData?.fees.avenewFees)
      : null;
    const tParkingFee = tenantsData?.fees?.parkingFee
      ? Number(tenantsData?.fees.parkingFee)
      : null;

    // const tParkingFee = null;

    const subTotal = tParkingFee + tRate + tLateFee + tAvenewFee;
    setSubTotalValue(subTotal);
  }, [tenantsData]);

  // console.log(tenantsData?.fees, "tenantsData?.fees");

  const feeType = (status) => {
    switch (status) {
      case "rate":
        return "Rent";
      case "lateFee":
        return "Late Fee";
      case "parkingFee":
        return "Parking Fee";
      case "avenewFees":
        return "Avenew Fee";
      case "otherFee":
        return "Other Fee";
      case "taxFee":
        return "Tax";
      default:
        return "Other Fee";
    }
  };

  return (
    <>
      {loading ? (
        <Container padding="0 50px 0px 330px">
          <div
            style={{
              position: "relative",
              minHeight: "calc(100vh - 80px)",
            }}
          >
            <Spin className="ant__spin" size="large" />
          </div>
        </Container>
      ) : loadingPayment ? (
        <Container padding="0 50px 0px 330px">
          <div
            style={{
              position: "relative",
              minHeight: "calc(100vh - 80px)",
            }}
          >
            <Spin className="ant__spin" size="large" />
          </div>
        </Container>
      ) : (
        <Container>
          <Row justify="center">
            <Col xs={24} sm={18} md={14} lg={14} xl={10} xxl={10}>
              <PaymentCard>
                <CardHeader>
                  <PayCash />
                  <div className="balance__amount">
                    <p>Balance</p>
                    <span>
                      &nbsp; &nbsp;
                      {toUSACurrency(Number(tenantsData?.balance))}
                    </span>
                  </div>
                </CardHeader>
                <InvoiceWrap>
                  <Row
                    justify="space-between"
                    onClick={() => setViewAmmenities(!viewAmmenities)}
                    style={{ cursor: "pointer" }}
                  >
                    <Col>
                      <Row>
                        <Col>
                          <span>Sub Total</span>
                        </Col>
                        <Col
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "10px",
                          }}
                        >
                          {!viewAmmenities ? (
                            <IoIosArrowDown />
                          ) : (
                            <IoIosArrowUp />
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <p>{toUSACurrency(Number(tenantsData?.payableAmount))}</p>
                    </Col>
                  </Row>
                  {viewAmmenities && (
                    <>
                      {tenantsData?.fees
                        ? tenantsData?.fees.map((item, id) => (
                            <>
                              {item.type === "taxFee" ? (
                                <Row
                                  key={id}
                                  justify="space-between"
                                  className="dropdown__price"
                                >
                                  <Col>
                                    <span>{feeType(item.type)}</span>
                                  </Col>
                                  <Col>
                                    <p>
                                      {item.dollerOrPercentage === "$"
                                        ? "$"
                                        : ""}
                                      {item.amount}
                                      {item.dollerOrPercentage === "%"
                                        ? "%"
                                        : ""}
                                    </p>
                                  </Col>
                                </Row>
                              ) : (
                                <Row
                                  key={id}
                                  justify="space-between"
                                  className="dropdown__price"
                                >
                                  <Col>
                                    <span>{feeType(item.type)}</span>
                                  </Col>
                                  <Col>
                                    <p>{toUSACurrency(Number(item?.amount))}</p>
                                  </Col>
                                </Row>
                              )}
                            </>
                          ))
                        : ""}
                    </>
                  )}
                  <Divider />

                  <AntInputAmount>
                    <Form
                      form={form}
                      validateMessages={validateMessages}
                      ref={formRef}
                    >
                      <Form.Item name="amount">
                        <Input
                          onChange={onChangeMultiPropertyUpDown}
                          placeholder="Enter Amount"
                          type="number"
                          pattern="[0-9]*"
                        />
                      </Form.Item>
                    </Form>
                  </AntInputAmount>

                  {!cashAppPayBtn ? (
                    <form style={{ margin: "70px 0 0 0" }} id="payment-form">
                      <div className="pay__btn" id="cash-app-pay"></div>
                    </form>
                  ) : (
                    <form style={{ margin: "70px 0 0 0" }} id="payment-form">
                      <div className="pay__btn" id="cash-app-pay"></div>
                    </form>
                  )}
                  <Row style={{ alignItems: "center" }} justify="space-between">
                    <Col span={11}>
                      <Divider />
                    </Col>
                    <Col span={2}>
                      <span>OR</span>
                    </Col>
                    <Col span={11}>
                      <Divider />
                    </Col>
                  </Row>

                  <form id="payment-form">
                    <StyledButton id="ach-button" type="button">
                      Pay with ACH Account
                    </StyledButton>
                  </form>
                  <Row style={{ alignItems: "center" }} justify="space-between">
                    <Col span={11}>
                      <Divider />
                    </Col>
                    <Col span={2}>
                      <span>OR</span>
                    </Col>
                    <Col span={11}>
                      <Divider />
                    </Col>
                  </Row>

                  {!cardPayBtn ? (
                    <>
                      <form id="payment-form">
                        <div id="card-container"></div>
                        <StyledButton id="card-button" type="button">
                          Pay with Card
                        </StyledButton>
                      </form>
                      <div id="payment-status-container"></div>
                    </>
                  ) : (
                    <>
                      <form id="payment-form">
                        <div id="card-container"></div>
                        <StyledButton id="card-button" type="button">
                          Pay with Card
                        </StyledButton>
                      </form>
                      <div id="payment-status-container"></div>
                    </>
                  )}

                  <h2 style={{ marginTop: "20px" }}>
                    <PayCashLockSm style={{ marginRight: "10px" }} /> 100%
                    Secure Payment
                  </h2>
                </InvoiceWrap>
              </PaymentCard>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default PaymentInvoiceSms;

export const AntInputAmount = styled.div`
  padding-top: 30px;

  input {
    // max-width: 470px;
    width: 100%;
    height: 50px;
    ${"" /* background: #eeeeee; */}
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 18px;
    @media screen and (max-width: 595px) {
      width: 100%;
    }
  }
`;

const StyledButton = styled(Button)`
  :hover,
  :focus {
    color: #ffffff;
    --tw-bg-opacity: 1;
    background-color: rgb(51 51 51 / var(--tw-bg-opacity));
    border-color: rgb(51 51 51 / var(--tw-bg-opacity));
  }

  :active,
  :disabled,
  :disabled:hover {
    text-decoration: none;
    --tw-bg-opacity: 1;

    background: rgb(51 51 51 / var(--tw-bg-opacity));
    border-color: rgb(51 51 51 / var(--tw-bg-opacity));
  }

  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: red;
    background: red;
    text-shadow: none;
    box-shadow: none;
  }

  span {
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    ${"" /* text-transform: uppercase !important; */}
    color: #ffffff !important;
    letter-spacing: 1px;
  }

  height: ${({ hg }) => hg || "50px"};
  width: 100%;
  border-radius: 7px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  ${"" /* text-transform: uppercase; */}
  color: #ffffff;
  background: #000;
`;

export const PaymentCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: ${({ marginTop }) => marginTop || "50px"};
`;

export const InvoiceWrap = styled.div`
  padding: 30px;
  position: relative;

  h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #949596;
  }

  .big__lock {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .ant-divider-horizontal {
    background: #dddddd;
  }
  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #848687;
  }
  p {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: right;
    color: #2b353f;
  }

  svg {
    color: #848687;
  }

  .dropdown__price {
    margin-top: 15px;

    span,
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      text-align: center;

      color: #848687;
    }
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 70px;
  background: linear-gradient(92.34deg, #4160ac 1.13%, #f67c36 100%);
  border-radius: 5px 5px 0px 0px;
  align-items: center;
  padding: 0 20px;
  .balance__amount {
    display: flex;
    align-items: center;
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: right;
      color: #ffffff;
      margin-bottom: 0;
    }

    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-align: right;
      color: #ffffff;
    }
  }
`;
