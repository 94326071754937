import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ApartmentSvg } from "../../assets/svgs";
import { Container } from "../container/Container";
import { MenuIconWrap, MenuItem, SidebarMenu } from "./PropertyType.style";

function PropertyType() {
  const menu = [
    {
      name: "Apartments",
      key: "apartments",
      component: MenuItem,
      icon: ApartmentSvg,
      to: "/",
    },
    {
      name: "Apartments",
      key: "apartments1",
      component: MenuItem,
      icon: ApartmentSvg,
      to: "/",
    },
    {
      name: "Apartments",
      key: "apartments2",
      component: MenuItem,
      icon: ApartmentSvg,
      to: "/",
    },
    {
      name: "Apartments",
      key: "apartments3",
      component: MenuItem,
      icon: ApartmentSvg,
      to: "/",
    },
    {
      name: "Apartments",
      key: "apartments4",
      component: MenuItem,
      icon: ApartmentSvg,
      to: "/",
    },
    {
      name: "Apartments",
      key: "apartments5",
      component: MenuItem,
      icon: ApartmentSvg,
      to: "/",
    },
    {
      name: "Apartments",
      key: "apartments6",
      component: MenuItem,
      icon: ApartmentSvg,
      to: "/",
    },
  ];

  const [topMenu, setTopMenu] = useState(menu);
  const renderMenu = (menu, className = "") => {
    return menu.map(
      (
        {
          name,
          key,
          to,
          onClick,
          component: Item,
          icon: Icon,
          children,
          className: className,
          divider,
        },
        idx
      ) => {
        return (
          <>
            <Item
              key={key}
              className={className}
              icon={
                Icon && (
                  <MenuIconWrap>
                    <Icon />
                  </MenuIconWrap>
                )
              }
              title={children && name}
              level={idx + 1}
            >
              {children ? (
                renderMenu(children, `sub-children ${key}`)
              ) : to ? (
                <>
                  {/* <Link to={to} onClick={() => localStorage.setItem("orgName", name)}> */}
                  <Link to={to}>{name}</Link>
                </>
              ) : (
                name
              )}
            </Item>
          </>
        );
      }
    );
  };
  return (
    <Container padding="70px 0">
      <SidebarMenu
        mode="horizontal"
        // openKeys={openKeys}
        // selectedKeys={activeKey}
        // onOpenChange={onOpenChange}
      >
        {renderMenu(topMenu)}
      </SidebarMenu>
      
    </Container>
  );
}

export default PropertyType;
