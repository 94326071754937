import { Button, Col, Modal, Row, Spin } from "antd";
import React from "react";
import { IoIosClose } from "react-icons/io";
import StarRatings from "react-star-ratings";
import styled from "styled-components";
import {
  ApplicationInvitaion,
  BathSvg,
  BedSvg,
  FooterCall,
  FooterLocation,
  Sqft,
} from "../../assets/svgs";
import {
  ApplicationsCard,
  ApplicationsCardTop,
  BedBathBox,
} from "../../pages/applications/Applications";
import { MobCardDetails } from "../../pages/category-of-property/CategoryOfProperty";
import { StyledButton } from "../../pages/dashboard/Dashboard.styles";
import { MobCard } from "../../pages/my-account/Applications";
import {
  ModalWrap,
  ModalWrapMain,
} from "../../pages/Profile-verification/AutomaticVerificationModal";
import { StyledTitle } from "../../pages/property-details/tabs/floor-plan-modal/FloorPlanModal";
import { LoadingOutlined } from "@ant-design/icons";
import { Capitalize } from "../../utils/fn";

function ApplicationInvitationModal({
  modalState,
  onCancel,
  dataModal,
  statusBtn,
  isLoadingApprove = false,
  isLoadingDeny = false,
  isLoadingCansel = false,
}) {
  const toUSACurrency = (num) => {
    const curr = num.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return curr;
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  return (
    <Modal
      getContainer={() => document.querySelector("#modal-container")}
      visible={modalState}
      onCancel={() => {
        // onCancel(false);
        statusBtn("cancel", dataModal);
      }}
      width={"700px"}
      footer={null}
      // style={{top: "50px" }}
      className="modal_wrapper"
      closeIcon={<IoIosClose className="modal_close_icon" />}
      maskStyle={{
        background: "rgba(0, 0, 0, 0.3)",
        backdropFilter: "blur(20px)",
      }}
      focusTriggerAfterClose={false}
      destroyOnClose={true}
    >
      <ApplicationsWrap>
        <Row gutter={[20, 20]}>
          <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
            <ApplicationInvitaion />
          </Col>
        </Row>
        <h4>
          Hi! You have been invited to join an online rental application from{" "}
          {Capitalize(dataModal?.invitedName ? dataModal?.invitedName : "")}.
        </h4>
      </ApplicationsWrap>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <StyledButton
            loading={isLoadingDeny}
            onClick={() => statusBtn("reject", dataModal)}
          >
            reject
          </StyledButton>
        </Col>
        <Col span={12}>
          <StyledButtonApprove
            loading={isLoadingApprove}
            onClick={() => statusBtn("accept", dataModal)}
          >
            accept
          </StyledButtonApprove>
        </Col>
      </Row>

      <ModalWrapMain>
        <span onClick={() => statusBtn("cancel", dataModal)}>
          {isLoadingCansel ? <Spin indicator={antIcon} /> : ""} Cancel
        </span>
      </ModalWrapMain>
    </Modal>
  );
}

export default ApplicationInvitationModal;

export const StyledButtonApprove = styled(Button)`
  width: 100%;
  height: 50px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.secondary};
  border-color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  margin-top: 30px;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
  }

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.secondary}90;
    background: ${({ theme }) => theme.colors.secondary}90;
  }
  @media (max-width: 1550px) {
    height: 40px;
    span {
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.5px;
    }
  }
  @media screen and (max-width: 769px) {
    padding: 4px 0;
    margin-top: 0;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
`;

const CardDetails = styled.div`
  padding: 10px 15px;
  width: ${({ cardWidth }) => cardWidth || ""};
  h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
    color: #777777;
  }
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #2b353f;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #4160ac;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .card__bottom p,
  span {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 0;
    letter-spacing: 0.05em;
    color: #848687;
    margin-top: 0px;
  }

  .card__bottom {
    display: flex;
    align-items: center;
    margin-right: 30px;
    svg {
      margin-right: 10px;
    }
  }
`;

const ApplicationsWrap = styled.div`
  svg {
    width: 100%;
  }
  h4 {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    color: #2b353f;
    margin-top: 30px;
  }
  ${
    "" /* width: 100%;
  padding: 20px 50px;
  max-height: 500px;
  overflow-y: scroll; */
  }

  /* scrollbar design */
  ::-webkit-scrollbar {
    width: 0.5em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(140, 153, 166, 0.8);
    outline: 1px solid rgb(140, 153, 166, 0.8);
    border-radius: 10px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 1550px) {
    h4 {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-top: 24px;
    }
  }
  @media (max-width: 991.98px) {
    padding: 0;
  }

  @media (max-width: 767px) {
    ::-webkit-scrollbar,
    .ant-modal-wrap::-webkit-scrollbar {
      width: 0 !important;
      display: none !important;
    }

    ::-webkit-scrollbar-track,
    .ant-modal-wrap::-webkit-scrollbar-track {
      box-shadow: none;
    }

    ::-webkit-scrollbar-thumb,
    .ant-modal-wrap::-webkit-scrollbar-thumb {
      display: none;
    }

    h4 {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      margin-top: 20px;
    }
  }
`;
