import React from "react";

const fullAccess = ({
  wSize = "25",
  hSize = "25",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox={`0 0 25 25`}
      {...props}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M24.5192 12.0182C24.4561 12.0182 24.3936 12.0306 24.3352 12.0548C24.2769 12.0789 24.2239 12.1144 24.1792 12.159C24.1346 12.2037 24.0992 12.2567 24.075 12.315C24.0509 12.3733 24.0384 12.4359 24.0385 12.499C24.0386 15.1159 23.1492 17.6552 21.5162 19.7001C19.8832 21.745 17.6036 23.1741 15.0514 23.7528C12.4993 24.3316 9.82623 24.0256 7.47087 22.8852C5.11551 21.7448 3.21775 19.8376 2.089 17.4766C0.960253 15.1156 0.667567 12.4411 1.25897 9.89184C1.85037 7.34261 3.29073 5.07012 5.34369 3.44726C7.39666 1.82441 9.94029 0.947588 12.5572 0.96068C15.1741 0.973772 17.7088 1.876 19.7454 3.51932H17.718C17.5905 3.51932 17.4682 3.56997 17.3781 3.66013C17.2879 3.75029 17.2373 3.87258 17.2373 4.00009C17.2373 4.12759 17.2879 4.24988 17.3781 4.34004C17.4682 4.4302 17.5905 4.48085 17.718 4.48085H20.9989C21.1263 4.48036 21.2483 4.42955 21.3383 4.3395C21.4284 4.24944 21.4792 4.12744 21.4797 4.00009V0.719214C21.4797 0.591706 21.429 0.46942 21.3389 0.379259C21.2487 0.289097 21.1264 0.238445 20.9989 0.238445C20.8714 0.238445 20.7491 0.289097 20.659 0.379259C20.5688 0.46942 20.5181 0.591706 20.5181 0.719214V2.91131C18.049 0.846234 14.8783 -0.186654 11.6666 0.0277886C8.45482 0.242231 5.44962 1.68747 3.27691 4.06248C1.10421 6.43748 -0.0684874 9.55914 0.00309435 12.7772C0.0746761 15.9953 1.38501 19.0618 3.66118 21.3378C5.40933 23.086 7.63662 24.2765 10.0614 24.7588C12.4861 25.2411 14.9995 24.9936 17.2835 24.0475C19.5676 23.1014 21.5198 21.4992 22.8934 19.4436C24.2669 17.388 25 14.9713 25 12.499C25 12.4359 24.9876 12.3733 24.9634 12.315C24.9393 12.2567 24.9039 12.2037 24.8592 12.159C24.8146 12.1144 24.7616 12.0789 24.7032 12.0548C24.6449 12.0306 24.5824 12.0182 24.5192 12.0182Z"
          fill="black"
        />
        <path
          d="M15.156 9.04872L12.7061 15.7795C12.6844 15.8388 12.6745 15.9019 12.6772 15.9651C12.6798 16.0282 12.6949 16.0903 12.7216 16.1476C12.7482 16.2049 12.7859 16.2564 12.8325 16.2992C12.8791 16.3419 12.9336 16.375 12.9931 16.3966C13.0525 16.4182 13.1156 16.4279 13.1787 16.4251C13.2419 16.4223 13.3039 16.4071 13.3611 16.3803C13.4184 16.3535 13.4698 16.3157 13.5124 16.269C13.555 16.2223 13.588 16.1676 13.6095 16.1081L16.0593 9.37738C16.1029 9.25759 16.0971 9.12539 16.0432 9.00987C15.9893 8.89435 15.8918 8.80497 15.772 8.76139C15.6522 8.7178 15.52 8.72359 15.4045 8.77748C15.289 8.83136 15.1996 8.92893 15.156 9.04872Z"
          fill="black"
        />
        <path
          d="M8.44268 11.6291C8.44331 11.3959 8.39768 11.1648 8.30842 10.9493C8.21917 10.7338 8.08807 10.5382 7.92271 10.3737C7.25179 9.70227 6.08273 9.70227 5.41182 10.3737C5.24638 10.5381 5.11522 10.7338 5.02592 10.9493C4.93663 11.1648 4.89097 11.3958 4.89161 11.6291C4.89161 11.7566 4.94226 11.8789 5.03242 11.9691C5.12258 12.0592 5.24487 12.1099 5.37238 12.1099C5.49989 12.1099 5.62217 12.0592 5.71233 11.9691C5.80249 11.8789 5.85315 11.7566 5.85315 11.6291C5.8529 11.5222 5.87385 11.4162 5.91478 11.3174C5.95572 11.2186 6.01584 11.1289 6.09166 11.0535C6.24662 10.9052 6.45281 10.8225 6.66726 10.8225C6.88172 10.8225 7.08791 10.9052 7.24287 11.0535C7.39544 11.2061 7.48114 11.4131 7.48114 11.6289C7.48114 11.8447 7.39544 12.0516 7.24287 12.2042L5.03246 14.4147C4.96522 14.4819 4.91942 14.5675 4.90087 14.6608C4.88231 14.7541 4.89182 14.8507 4.92821 14.9386C4.96459 15.0264 5.02622 15.1015 5.10528 15.1543C5.18434 15.2072 5.27729 15.2353 5.37238 15.2353H7.96192C8.08943 15.2353 8.21171 15.1847 8.30188 15.0945C8.39204 15.0044 8.44269 14.8821 8.44269 14.7546C8.44269 14.6271 8.39204 14.5048 8.30188 14.4146C8.21171 14.3245 8.08943 14.2738 7.96192 14.2738H6.53298L7.92271 12.8841C8.08806 12.7197 8.21916 12.5241 8.30842 12.3087C8.39767 12.0933 8.44331 11.8623 8.44268 11.6291Z"
          fill="black"
        />
        <path
          d="M11.183 15.2354C11.2461 15.2354 11.3087 15.223 11.367 15.1988C11.4253 15.1747 11.4783 15.1393 11.523 15.0946C11.5676 15.05 11.6031 14.997 11.6272 14.9386C11.6514 14.8803 11.6638 14.8178 11.6638 14.7546V14.0377H11.9119C12.0394 14.0377 12.1617 13.987 12.2519 13.8969C12.342 13.8067 12.3927 13.6844 12.3927 13.5569C12.3927 13.4294 12.342 13.3071 12.2519 13.2169C12.1617 13.1268 12.0394 13.0761 11.9119 13.0761H11.6638V10.3342C11.6637 10.2308 11.6303 10.1301 11.5685 10.0471C11.5066 9.9641 11.4197 9.90328 11.3205 9.87365C11.2214 9.84402 11.1153 9.84716 11.0181 9.8826C10.9209 9.91805 10.8377 9.9839 10.7809 10.0704L8.66766 13.293C8.62008 13.3656 8.59301 13.4496 8.58931 13.5363C8.5856 13.6229 8.60541 13.709 8.64662 13.7853C8.68784 13.8616 8.74893 13.9254 8.82343 13.9698C8.89792 14.0142 8.98305 14.0377 9.06978 14.0377H10.7022V14.7546C10.7022 14.8178 10.7146 14.8803 10.7388 14.9386C10.763 14.997 10.7984 15.05 10.843 15.0946C10.8877 15.1393 10.9407 15.1747 10.999 15.1988C11.0573 15.223 11.1199 15.2354 11.183 15.2354ZM9.95996 13.0761L10.7022 11.9442V13.0761H9.95996Z"
          fill="black"
        />
        <path
          d="M17.7256 14.7547C17.7256 14.8822 17.7763 15.0045 17.8664 15.0946C17.9566 15.1848 18.0789 15.2354 18.2064 15.2354C18.3339 15.2354 18.4562 15.1848 18.5464 15.0946C18.6365 15.0045 18.6872 14.8822 18.6872 14.7547V13.7649C18.6857 13.1907 18.798 12.6219 19.0178 12.0914C19.2376 11.5608 19.5604 11.0792 19.9675 10.6742C20.0347 10.607 20.0805 10.5213 20.0991 10.4281C20.1176 10.3348 20.1081 10.2381 20.0717 10.1503C20.0354 10.0624 19.9737 9.98736 19.8947 9.93453C19.8156 9.8817 19.7227 9.85351 19.6276 9.85352H16.7855C16.6579 9.85352 16.5357 9.90417 16.4455 9.99433C16.3553 10.0845 16.3047 10.2068 16.3047 10.3343C16.3047 10.4618 16.3553 10.5841 16.4455 10.6742C16.5357 10.7644 16.6579 10.8151 16.7855 10.8151H18.6139C18.032 11.6886 17.7228 12.7153 17.7256 13.7649V14.7547Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={wSize} height={hSize} fill={bgColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default fullAccess;
