import { BackTop, Breadcrumb, Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { FaHeart } from "react-icons/fa";
import { MdKeyboardArrowUp } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  AvenewImg,
  BathSvg,
  BedSvg,
  NoProperty,
  Sqft,
} from "../../assets/svgs";
import { Container } from "../../components/container/Container";
import Cards from "../../components/featuredProperties/Cards/Cards";
import { ImgWrap } from "../../components/featuredProperties/Cards/CardSection";
import {
  CardDiscriptionWrap,
  StyledApparCard,
} from "../../components/featuredProperties/Cards/CardStyle";
import Footer from "../../components/footer/Footer";
import {
  addOrRemoveFavorites,
  getFavoriteUnits,
  me,
} from "../../store/reducers/user.reducer";
import { PageTitle } from "../my-account/MyAccountTabs";
import { StyledButton } from "../property-details/apply-for-property-modal/ApplyForPropertyModal";
import {
  BreadCrumbsWrapper,
  BreadcrumbTop,
} from "../property-details/PropertyDetails";
import { NoInvoiceWrap } from "../unit-invoices/UnitInvoice";

function Favorite() {
  const location = useLocation();
  const [allUnitsAdd, setAllUnits] = useState([]);
  const [favoriteUserUnits, setFavoriteUserUnits] = useState([]);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [isloading, setIsloading] = useState(true);
  const dispatch = useDispatch();

  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const breadcrumbNameMap = [
    { path: "/dashboard", label: "Dashboard", isLink: true },
    {
      path: "/favorite",
      label: "Wishlist",
      isLink: false,
    },
  ];

  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      const breadcrumb = breadcrumbNameMap.find((b) => b.path === url);
      return breadcrumb ? (
        <Breadcrumb.Item key={`${breadcrumb.path}_${index}`}>
          {breadcrumb.isLink ? (
            <Link to={breadcrumb.isLink && breadcrumb.path}>
              {breadcrumb.label}
            </Link>
          ) : (
            <span className="breadcrumbs_label">{breadcrumb.label}</span>
          )}
        </Breadcrumb.Item>
      ) : null;
    })
    .filter((i) => i);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  const style = {
    height: 40,
    width: 40,
    lineHeight: "40px",
    borderRadius: "50%",
    backgroundColor: "#4160AC90",
    color: "#fff",
    textAlign: "center",
    fontSize: 40,
  };

  useEffect(() => {
    setIsloading(true);
    dispatch(me());
    dispatch(getFavoriteUnits()).then(({ payload }) => {
      setIsloading(false);
      setAllUnits(payload?.favoriteUnits);
    });
  }, []);

  const getFavorites = (unitId) => {
    var value = {};
    value.unit_id = unitId;
    value.user_id = user?.id;
    if (favoriteUserUnits?.length) {
      if (favoriteUserUnits.includes(unitId)) {
        value.isFavorite = false;
        const newFav = favoriteUserUnits.filter(function (ele) {
          return ele != unitId;
        });
        setFavoriteUserUnits([...newFav]);
      } else {
        let arr = [];
        arr.push(unitId);
        setFavoriteUserUnits((val) => [...val, ...arr]);

        value.isFavorite = true;
      }
    }
    dispatch(addOrRemoveFavorites(value)).then(() => {
      dispatch(me());
    });
  };

  useEffect(() => {
    if (user?.favoriteUnits) {
      setFavoriteUserUnits(user.favoriteUnits);
    }
  }, [user]);

  const toIndianCurrency = (num) => {
    const curr = num.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return curr;
  };

  const slpitFunc = (value) => {
    if (value.length > 15) {
      return value.slice(0, 15) + "...";
    } else {
      return value;
    }
  };

  const upperCase = (value) => {
    if (value) {
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return slpitFunc(str2);
    }
  };

  return (
    <>
      <BreadcrumbTop>
        <BreadCrumbsWrapper>
          <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
        </BreadCrumbsWrapper>
      </BreadcrumbTop>

      {isloading ? (
        <Container padding="0 50px 0px 330px">
          <div
            style={{
              position: "relative",
              minHeight: "calc(100vh - 80px)",
            }}
          >
            <Spin className="ant__spin" size="large" />
          </div>
        </Container>
      ) : (
        <Container paddings="0" padding="30px 0">
          {favoriteUserUnits?.length ? (
            <>
              <PageTitle>
                <h2>Wishlist</h2>
              </PageTitle>
              <FavoriteWrap>
                <Row gutter={[30, 30]}>
                  {allUnitsAdd.map((items, _id) => (
                    <>
                      {favoriteUserUnits?.includes(items.id) ? (
                        <Col
                          key={_id}
                          xs={24}
                          sm={12}
                          md={12}
                          lg={8}
                          xl={6}
                          xxl={6}
                        >
                          <Link to={`/property-details/${items.id}`}>
                            <StyledApparCard hoverable>
                              <ImgWrap>
                                {items.images && items.images?.length ? (
                                  <>
                                    {items.images.map((img, idx) => (
                                      <>
                                        {img.isCover === true ? (
                                          <img alt="example" src={img.path} />
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <AvenewImg
                                    className="svg__border"
                                    style={{
                                      background: "#eeeeee",
                                      height: "175px",
                                    }}
                                  />
                                )}
                                <div className="top-left">NEW</div>
                                <div
                                  className="top-right"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    getFavorites(items.id);
                                  }}
                                >
                                  <FaHeart style={{ color: "#f67c36" }} />
                                </div>
                                {items.images && items.images?.length ? (
                                  <div className="bottom-right">
                                    <p>{items.images.length} Photos</p>
                                  </div>
                                ) : (
                                  <div className="bottom-right">
                                    <p>0 Photo</p>
                                  </div>
                                )}
                              </ImgWrap>
                              <CardDiscriptionWrap>
                                {/* <div className="rating__star">
                          <StarRatings
                            rating={3}
                            starRatedColor="#F67C36"
                            starDimension="20px"
                            starSpacing="5px"
                            // changeRating={this.changeRating}
                            numberOfStars={5}
                            name="rating"
                          />
                        </div> */}
                                <p className="card__title">
                                  {upperCase(items.unit_name)}
                                </p>
                                <p className="card__price">
                                  {toIndianCurrency(Number(items.price))}
                                </p>

                                <Row justify="space-between">
                                  <Col className="card__bottom">
                                    <p>
                                      <BedSvg className="icon" />
                                    </p>
                                    <span>Bed : {items.bed}</span>
                                  </Col>
                                  <Col className="card__bottom">
                                    <p>
                                      <BathSvg />
                                    </p>
                                    <span>Bath : {items.bath}</span>
                                  </Col>
                                  <Col className="card__bottom">
                                    <p>
                                      <Sqft />
                                    </p>
                                    <span>Sq ft : {items.sqft}</span>
                                  </Col>
                                </Row>
                              </CardDiscriptionWrap>
                            </StyledApparCard>
                          </Link>
                        </Col>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                </Row>
              </FavoriteWrap>
            </>
          ) : (
            <NoInvoiceWrap mb="20px" mt="30px">
              <NoProperty />
              <h1>No property available</h1>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
              <Row justify="center" style={{ marginBottom: "40px" }}>
                <Col xs={20} sm={14} md={8} lg={4} xl={4} xxl={4}>
                  <StyledButton onClick={() => navigate("/")}>
                    Back to Home
                  </StyledButton>
                </Col>
              </Row>
            </NoInvoiceWrap>
          )}

          <Footer />

          <BackTop>
            <div style={style}>
              <MdKeyboardArrowUp />
            </div>
          </BackTop>
        </Container>
      )}
    </>
  );
}

export default Favorite;

const FavoriteWrap = styled.div`
  margin-bottom: 30px;
  min-height: calc(100vh - 340px);
`;
