import React, { useEffect, useState } from "react";
import { Col, Form, Row, Tabs } from "antd";
import styled from "styled-components";
import { EVerification, LessSvg, UploadManually } from "../../assets/svgs";
import {
  StyledForm,
  StyledForms,
  StyledFormItem,
  UploadBox,
  UploadInput,
} from "../Profile-verification/BackgroundCheck";
import GeneralProfile, { FormWrap } from "./sub-tabs/GeneralProfile";
import { StyledButton } from "../property-details/apply-for-property-modal/ApplyForPropertyModal";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlineUpload } from "react-icons/ai";
import IdProof, { ShowUplaodImg, UploadBoxLen } from "./sub-tabs/IdProof";
import { ImagePreview } from "../../components/ImagePreview";
import EmployerDetails from "./sub-tabs/EmployerDetails";
import UserProfileTab from "./UserProfileTab";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationProcess,
  createLinkToken,
  getBankIncome,
} from "../../store/reducers/user.reducer";
import LinkLoader from "../../components/LinkLoader";
import { useContext } from "react";
import { UserContext } from "../../components/UserContext";
import { useCallback } from "react";

function BankingProfile({ onBackClick, onMainTabNext }) {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const { applicationProcessData } = useSelector((state) => state.user);
  const { width } = useWindowSize();
  const location = useLocation();
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedBanking, setSelectedBanking] = useState("");
  const [form] = Form.useForm();
  const drop = React.useRef(null);
  const uploadFront = React.useRef(null);
  const uploadBack = React.useRef(null);
  const [statePayStub1, serStatePayStub1] = useState([]);
  const [statePayStub2, serStatePayStub2] = useState([]);
  const [state, setState] = useState([]);
  const [isVerifiedBanking, setIsVerifiedBanking] = useState(false);
  const [activeTab, setActiveTab] = useState("change-method");
  const [activeTabForManual, setActiveTabForManual] = useState("pay-stubs");
  const [tabPosition, setTabPosition] = useState("left");
  const [positionBack, setPositionBack] = useState("absolute");

  useEffect(() => {
    if (width < 993) {
      setTabPosition("horizontal");
      setPositionBack("static");
    } else {
      setPositionBack("absolute");
      setTabPosition("left");
    }
  }, [width, location.pathname]);

  const onFinish = (value) => {
    let data = {};
    data.manualVerification = {};
    data.manualVerification.payStubs = value;

    setIsSubmit(true);
    dispatch(applicationProcess(data)).then(({ payload }) => {
      setIsSubmit(false);
      setActiveTabForManual("employer-details");
      // onMainTabNext("rental-history");
    });
  };

  // console.log(applicationProcessData, "applicationProcessData");

  useEffect(() => {
    if (applicationProcessData && applicationProcessData?.id) {
      if (
        applicationProcessData?.incomeAndEmployment &&
        Object.values(
          applicationProcessData?.incomeAndEmployment?.manualVerification
            ?.payStubs
        ).length
      ) {
        serStatePayStub1([
          applicationProcessData?.incomeAndEmployment?.manualVerification
            ?.payStubs?.payStub1,
        ]);
        serStatePayStub2([
          applicationProcessData?.incomeAndEmployment?.manualVerification
            ?.payStubs?.payStub2,
        ]);
        form.setFieldsValue({
          payStub1:
            applicationProcessData?.incomeAndEmployment?.manualVerification
              ?.payStubs?.payStub1,
          payStub2:
            applicationProcessData?.incomeAndEmployment?.manualVerification
              ?.payStubs?.payStub2,
        });
      } else {
        serStatePayStub1([]);
        serStatePayStub2([]);
      }
    }
  }, [applicationProcessData]);

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const imagePath = (path, isStatic = false) => {
    if (isStatic) return path;
    return process.env.REACT_APP_API_URL
      ? `${process.env.REACT_APP_API_URL}${path}`
      : path;
  };

  const fileChangeHandlerFront = async (event) => {
    // console.log(event.target.files);

    event.preventDefault();
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    const newFun = async (img) => {
      const convertImg = await convertToBase64(img);
      serStatePayStub1((s) => [
        {
          file: convertImg,
          path: URL.createObjectURL(img),
          name: img.name,
          ext: img.type,
        },
      ]);
    };

    Array(...event.target.files).length &&
      (await Promise.all(
        Array(...event.target.files).map(async (file) => {
          return await newFun(file);
        })
      ));
  };

  const fileChangeHandlerBack = async (event) => {
    event.preventDefault();
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    const newFun = async (img) => {
      const convertImg = await convertToBase64(img);
      serStatePayStub2((s) => [
        {
          file: convertImg,
          path: URL.createObjectURL(img),
          name: img.name,
          ext: img.type,
        },
      ]);
    };

    Array(...event.target.files).length &&
      (await Promise.all(
        Array(...event.target.files).map(async (file) => {
          return await newFun(file);
        })
      ));
  };

  const fileChangeHandlerDrag = (files) => {
    // console.log(files, "files");
    setState((s) => [
      ...s,
      ...Array.from(files).map((file) => ({
        image: file,
        path: URL.createObjectURL(file),
      })),
    ]);
  };

  useEffect(() => {
    form.setFieldsValue({
      payStub1: statePayStub1[0],
      payStub2: statePayStub2[0],
      // identification: "Driving License",
    });
  }, [form, statePayStub2, statePayStub1]);

  // useEffect(() => {
  //   drop.current.addEventListener("dragover", handleDragOver);
  //   drop.current.addEventListener("drop", handleDrop);
  // }, []);

  const onTabChnageBack = (key) => {
    if (key === "change-method") {
      setSelectedBanking("");
      setActiveTabForManual("pay-stubs");
    } else {
      setActiveTabForManual(key);
    }
  };

  const onBackClicked = () => {
    // setSelectedBanking("");
    setActiveTabForManual("pay-stubs");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [bankIncome, setBankIncome] = useState(Array());
  const hardCodedCurrencyCode = "USD";
  const { user } = useContext(UserContext);

  useEffect(() => {
    dispatch(getBankIncome()).then(({ payload }) => {
      const thisUsersIncome =
        payload?.incomeVerification?.bank_income?.flatMap((report) => {
          return report.items.flatMap((item) => {
            const institution_name = item.institution_name;
            const income_sources = item.bank_income_sources.map((source) => ({
              bank_name: institution_name,
              total_amount: source.total_amount,
              transaction_count: source.transaction_count,
              description: source.income_description,
              income_id: source.income_source_id,
            }));
            return income_sources;
          });
        }) || [];

      setBankIncome(thisUsersIncome);
    });
  }, [user]);

  // const getIncome = useCallback(async () => {
  //   const response = await fetch("/appServer/get_bank_income");
  //   const data = await response.json();
  //   // console.log("Bank Income: ", data);

  //   // `bank_income` is an array of objects, each of which contains an array of
  //   // `items`, which are objects that, in turn, contains an array of
  //   // `bank_income_sources`.
  //   const thisUsersIncome =
  //     data.bank_income?.flatMap((report) => {
  //       return report.items.flatMap((item) => {
  //         const institution_name = item.institution_name;
  //         const income_sources = item.bank_income_sources.map((source) => ({
  //           bank_name: institution_name,
  //           total_amount: source.total_amount,
  //           transaction_count: source.transaction_count,
  //           description: source.income_description,
  //           income_id: source.income_source_id,
  //         }));
  //         return income_sources;
  //       });
  //     }) || [];

  //   setBankIncome(thisUsersIncome);
  // }, []);

  // useEffect(() => {
  //   getIncome();
  // }, [getIncome, user.incomeConnected, user.incomeUpdateTime]);

  return (
    <BankingProfileWrap mt="0">
      {isVerifiedBanking ? (
        <EmployerDetails />
      ) : (
        <>
          {selectedBanking === "Instant e-verification" ? (
            <>
              <StyledTabWrapper>
                <StyledTabs
                  defaultActiveKey={"change-method"}
                  activeKey={activeTab}
                  onChange={(tabKey) => {
                    // setList([]);
                    setActiveTab(tabKey);
                  }}
                  tabPosition={tabPosition}
                  onTabClick={() => setSelectedBanking("")}
                >
                  <TabPane
                    tab={
                      <div
                        className="change__method"
                        style={{ alignItems: "center", display: "flex" }}
                      >
                        <IoIosArrowBack />
                        Change Method
                      </div>
                    }
                    key="change-method"
                  >
                    <h1>Instant Bank verification</h1>

                    <Row gutter={[10, 10]}>
                      {bankIncome?.map((e, idx) => (
                        <>
                          <Col span={12}>
                            <BankIncomeBox>
                              <h4>{e.bank_name}</h4>
                              <h3>
                                {e.total_amount.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: hardCodedCurrencyCode,
                                })}
                              </h3>
                            </BankIncomeBox>
                          </Col>
                          {/* <span> from {e.transaction_count} txns</span> */}
                          {/* <Box
                            key={e.income_id}
                            maxW={205}
                            borderWidth="2px"
                            borderRadius="lg"
                            overflow="hidden"
                            mx="2"
                            px="3"
                          >
                            <Box
                              mt="1"
                              fontWeight="semibold"
                              as="h4"
                              isTruncated
                            >
                              {e.bank_name}
                            </Box>
                            <Box fontSize="sm" isTruncated>
                              {e.description}
                            </Box>
                            <Box as="b">
                              {e.total_amount.toLocaleString("en-US", {
                                style: "currency",
                                currency: hardCodedCurrencyCode,
                              })}{" "}
                            </Box>

                            <Box as="span">
                              (from {e.transaction_count} txns)
                            </Box>
                          </Box> */}
                        </>
                      ))}
                    </Row>

                    <LinkLoader
                      buttonText={"Add bank income"}
                      income={true}
                      incomeType="bank"
                    ></LinkLoader>

                    <FormWrap
                      style={{ bottom: "0", position: positionBack }}
                      mt="0"
                    >
                      <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
                        <StyledForms>
                          <Form
                            layout="vertical"
                            // requiredMark={"optional"}
                            form={form}
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                          >
                            <Form.Item>
                              <Row
                                justify="space-between"
                                style={{ alignItems: "center" }}
                              >
                                <Col
                                  xs={10}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  xxl={12}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="back__btn"
                                >
                                  <IoIosArrowBack
                                    onClick={() => setSelectedBanking("")}
                                  />
                                  <h2 onClick={() => setSelectedBanking("")}>
                                    back
                                  </h2>
                                </Col>
                                <Col
                                  xs={14}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  xxl={12}
                                >
                                  <Row
                                    justify="end"
                                    style={{ alignItems: "center" }}
                                  >
                                    <Col>
                                      <p className="cancel__btn">Cancel</p>
                                    </Col>
                                    <Col
                                      xs={10}
                                      sm={8}
                                      md={8}
                                      lg={8}
                                      xl={6}
                                      xxl={6}
                                    >
                                      <StyledButton
                                        mt="0"
                                        loading={isSubmit}
                                        htmlType="submit"
                                      >
                                        next
                                      </StyledButton>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Item>
                          </Form>
                        </StyledForms>
                      </StyledForm>
                    </FormWrap>
                  </TabPane>
                </StyledTabs>
              </StyledTabWrapper>
            </>
          ) : selectedBanking === "Upload Manually" ? (
            <>
              <StyledTabWrapper>
                <StyledTabs
                  defaultActiveKey={"pay-stubs"}
                  activeKey={activeTabForManual}
                  // onChange={(tabKey) => {
                  //   setActiveTabForManual(tabKey);
                  // }}
                  tabPosition={tabPosition}
                  onTabClick={(key) => {
                    onTabChnageBack(key);
                  }}
                >
                  <TabPane
                    tab={
                      <div
                        className="change__method"
                        style={{ alignItems: "center", display: "flex" }}
                      >
                        <IoIosArrowBack />
                        Change Method
                      </div>
                    }
                    key="change-method"
                  ></TabPane>
                  <TabPane tab="Pay Stubs" key="pay-stubs">
                    <h1>Upload Paystubs</h1>
                    <p>Please provide your two recent paystubs</p>
                    <FormWrap mt="0">
                      <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
                        <StyledForms>
                          <Form
                            layout="vertical"
                            // requiredMark={"optional"}
                            form={form}
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                          >
                            <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                              <Form.Item
                                name="payStub1"
                                // label="Pay Stub 1"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Identification Picture is required.",
                                  },
                                ]}
                              >
                                <UploadBoxLen isPhoto={statePayStub1.length}>
                                  <UploadBox
                                    height="100px"
                                    heightSm="80px"
                                    mt="0"
                                    ref={drop}
                                    onClick={(e) => uploadFront.current.click()}
                                  >
                                    <UploadInput background="#ffffff">
                                      <p className="ant-upload-drag-icon">
                                        <AiOutlineUpload className="upload__icon" />
                                      </p>
                                      <p
                                        className="ant-upload-text"
                                        style={{ fontSize: "16px" }}
                                      >
                                        Upload
                                      </p>
                                    </UploadInput>
                                  </UploadBox>
                                </UploadBoxLen>

                                <input
                                  accept="image/*"
                                  id="frontImg"
                                  multiple={false}
                                  type="file"
                                  ref={uploadFront}
                                  style={{ display: "none" }}
                                  onChange={fileChangeHandlerFront}
                                />
                                <ShowUplaodImg isPhoto={statePayStub1?.length}>
                                  {statePayStub1?.map((img, idx) => (
                                    <ImagePreview
                                      isCoverDisProp={false}
                                      key={idx}
                                      src={img?.path ? img?.path : ""}
                                      height={"auto"}
                                      width={"auto"}
                                      onDelete={() => {
                                        const st = [...statePayStub1];
                                        st.splice(idx, 1);
                                        serStatePayStub1(st);
                                        // upload.current.value = null;
                                      }}
                                    />
                                  ))}
                                </ShowUplaodImg>
                              </Form.Item>
                              <Form.Item
                                name="payStub2"
                                // label="Pay Stub 1"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Identification Picture is required.",
                                  },
                                ]}
                              >
                                <UploadBoxLen isPhoto={statePayStub2.length}>
                                  <UploadBox
                                    height="100px"
                                    heightSm="80px"
                                    mt="0"
                                    ref={drop}
                                    onClick={(e) => uploadBack.current.click()}
                                  >
                                    <UploadInput background="#ffffff">
                                      <p className="ant-upload-drag-icon">
                                        <AiOutlineUpload className="upload__icon" />
                                      </p>
                                      <p
                                        className="ant-upload-text"
                                        style={{ fontSize: "16px" }}
                                      >
                                        Upload
                                      </p>
                                    </UploadInput>
                                  </UploadBox>
                                </UploadBoxLen>

                                <input
                                  accept="image/*"
                                  id="backImg"
                                  multiple={false}
                                  type="file"
                                  ref={uploadBack}
                                  style={{ display: "none" }}
                                  onChange={fileChangeHandlerBack}
                                />

                                <ShowUplaodImg isPhoto={statePayStub2?.length}>
                                  {statePayStub2.map((img, idx) => (
                                    <ImagePreview
                                      isCoverDisProp={false}
                                      key={idx}
                                      src={img?.path ? img?.path : ""}
                                      height={"auto"}
                                      width={"auto"}
                                      onDelete={() => {
                                        const st = [...statePayStub2];
                                        st.splice(idx, 1);
                                        serStatePayStub2(st);
                                        // upload.current.value = null;
                                      }}
                                    />
                                  ))}
                                </ShowUplaodImg>
                              </Form.Item>
                            </StyledFormItem>

                            <Form.Item>
                              <Row
                                justify="space-between"
                                style={{ alignItems: "center" }}
                              >
                                <Col
                                  xs={10}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  xxl={12}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="back__btn"
                                >
                                  <IoIosArrowBack
                                    onClick={() => setSelectedBanking("")}
                                  />
                                  <h2 onClick={() => setSelectedBanking("")}>
                                    back
                                  </h2>
                                </Col>
                                <Col
                                  xs={14}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  xxl={12}
                                >
                                  <Row
                                    justify="end"
                                    style={{ alignItems: "center" }}
                                  >
                                    <Col>
                                      <p className="cancel__btn">Cancel</p>
                                    </Col>
                                    <Col
                                      xs={10}
                                      sm={8}
                                      md={8}
                                      lg={8}
                                      xl={6}
                                      xxl={6}
                                    >
                                      <StyledButton
                                        mt="0"
                                        loading={isSubmit}
                                        htmlType="submit"
                                      >
                                        next
                                      </StyledButton>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Item>
                          </Form>
                        </StyledForms>
                      </StyledForm>
                    </FormWrap>
                  </TabPane>
                  <TabPane tab="Employer Details" key="employer-details">
                    <EmployerDetails
                      onMainTabNext={onMainTabNext}
                      onBackClicked={onBackClicked}
                    />
                  </TabPane>
                </StyledTabs>
              </StyledTabWrapper>
            </>
          ) : (
            <>
              <h1>
                Select the method that you would like to verify your income and
                employment.
              </h1>

              <Row gutter={[20, 20]} justify="space-between">
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <VerificationBtn
                    onClick={() => setSelectedBanking("Instant e-verification")}
                  >
                    <EVerification />
                    <VerificationBtnRight>
                      <h2>Instant Bank Verification</h2>
                      <p>
                        Securely login to your online bank account for a
                        one-time income and employment check.
                      </p>
                      <span>Bank credentials are not saved.</span>
                    </VerificationBtnRight>
                  </VerificationBtn>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <VerificationBtn
                    onClick={() => setSelectedBanking("Upload Manually")}
                  >
                    <UploadManually />
                    <VerificationBtnRight>
                      <h2>Manual Verification</h2>
                      <p>
                        Upload two recent paystubs and provide your employer's
                        details.
                      </p>
                      <span>
                        This will increase the time needed to review your
                        application.
                      </span>
                    </VerificationBtnRight>
                  </VerificationBtn>
                </Col>
              </Row>

              <Row
                justify="space-between"
                style={{ alignItems: "center", marginTop: "20px" }}
              >
                <Col
                  xs={10}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="back__btn"
                >
                  <IoIosArrowBack
                    onClick={() => onBackClick("banking-profile")}
                  />
                  <h2 onClick={() => onBackClick("banking-profile")}>back</h2>
                </Col>
                {/* <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Row justify="end" style={{ alignItems: "center" }}>
                    <Col>
                      <p className="cancel__btn">Cancel</p>
                    </Col>
                    <Col xs={10} sm={8} md={8} lg={8} xl={6} xxl={6}>
                      <StyledButton mt="0" loading={isSubmit} htmlType="submit">
                        next
                      </StyledButton>
                    </Col>
                  </Row>
                </Col> */}
              </Row>
            </>
          )}
        </>
      )}
    </BankingProfileWrap>
  );
}

export default BankingProfile;

const BankIncomeBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 100%;
  padding: 20px 30px;
  h4 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #f67c36;
    margin-bottom: 15px;
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 0;
  }
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding: 8px 100px 8px 24px !important;
    font-weight: 400 !important;
    font-size: 18px !important;

    @media (max-width: 1200px) {
      padding: 8px 10px 8px 24px !important;
    }
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 10px 8px 24px !important;
  }
  .ant-tabs-tab-btn {
    font-size: 16px !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.secondary} !important;
    font-size: 16px !important;
  }
  .ant-tabs-ink-bar {
    background: #ffffff !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background: #ffffff !important;
    border-right: 2px solid #4160ac !important;
  }

  @media (max-width: 1550px) {
    .ant-tabs-tab {
      padding: 6px 50px 6px 18px !important;
      font-size: 14px !important;
    }
    .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
      padding: 8px 10px 8px 24px !important;
    }
  }
  @media (max-width: 993px) {
    .ant-tabs-tab.ant-tabs-tab-active {
      border-right: none !important;
      border-bottom: 2px solid #4160ac !important;
    }

    .ant-tabs-tab {
      padding: 8px 24px 8px 24px !important;
    }
  }

  @media (max-width: 769px) {
    .ant-tabs-tab.ant-tabs-tab-active {
      border-bottom: 2px solid #4160ac !important;
      margin-bottom: 2px;
    }

    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before {
      bottom: 0;
      margin-bottom: 2px;
    }
  }
`;

const StyledTabWrapper = styled.div`
  margin-left: 45px !important;
  .change__method,
  .change__method svg {
    font-weight: 400;
    font-size: 16px;
    line-height: 50px;
    color: #000000;
  }

  svg {
    margin-left: 0px !important;
  }

  .ant-tabs > .ant-tabs-nav {
    background: none !important;
    box-shadow: none !important;
    border-radius: 5px;
    min-height: calc(100vh - 260px);
    padding-top: 0 !important;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 74px 8px 8px !important;
  }

  .ant-divider-vertical {
    margin-top: 0px !important;
    height: 13.9em !important;
    margin: 0 263px !important;
    @media (max-width: 1540px) {
      height: 8.9em !important;
    }

    @media (max-width: 993px) {
      display: none !important;
    }
  }
  @media (max-width: 992px) {
    ${"" /* margin-left: 30px; */}
    padding: 15px !important;
  }
  .ant-tabs-left
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    padding: 0 25px !important;
  }

  .ant-tabs-tab:hover {
    color: ${(props) => props.theme.colors.secondary} !important;
  }

  @media (max-width: 1550px) {
    margin-left: 0 !important;
    .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
      padding: 8px 60px 8px 8px !important;
    }
  }
  @media (max-width: 1200px) {
    margin-left: 0 !important;
  }
  @media (max-width: 993px) {
    .ant-tabs > .ant-tabs-nav {
      min-height: auto !important;
    }
  }

  @media (max-width: 769px) {
    padding: 0 !important;

    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before {
      bottom: 0;
      margin-bottom: 2px;
    }
  }
`;

const BankingProfileWrap = styled.div`
  .cancel__btn {
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #848687;
    margin-bottom: 0;
    margin-right: 30px;
    margin-top: ${({ mt }) => mt || "10px"};
  }

  .back__btn h2,
  svg {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #777777;
    margin-left: 10px;
    cursor: pointer;
  }
  h1 {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 30px;
  }

  @media (max-width: 425.98px) {
    svg {
      margin-left: 0;
    }
  }
`;

const VerificationBtnRight = styled.div`
  padding: 0 0 0 20px;
  h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 10px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 10px;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #777777;
  }

  @media (max-width: 425.98px) {
    padding: 0;
  }
`;

const VerificationBtn = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 191px;
  @media (max-width: 1550px) {
    padding: 15px;
  }
  @media (max-width: 425.98px) {
    flex-direction: column;
    align-items: start;
  }
`;
