import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import Content from "../content/Content";
import Header from "./header/Header";

function Layout() {
  const openSider = (e) => {
    e.preventDefault();
    let x = document.getElementsByClassName("openSider");
    document.body.classList.toggle("openSider");
  };
  const { width } = useWindowSize();
  const location = useLocation();

  useEffect(() => {
    if (width < 992) {
      document.body.classList.add("openSider");
    }
  }, [width, location.pathname]);

  return (
    <div className="content-wrap">
      <div className="back-drop" onClick={openSider}></div>
      <Header />
      <Content />
    </div>
  );
}

export default Layout;
