import {
  Col,
  Row,
  Form,
  Input,
  DatePicker,
  BackTop,
  Divider,
  Select,
  Tabs,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { Container } from "../../components/container/Container";
import PropertyType from "../../components/propertyType/PropertyType";
import TopBusinessCities from "../../components/topBusinessCities/TopBusinessCities";
import {
  DashboardFilter,
  DashboardFilterWrap,
  DashboardWrap,
  MobileTabs,
  RangePickerWrap,
  StyledButton,
  StyledFormItem,
  StyledFormItemd,
} from "./Dashboard.styles";
import { MdKeyboardArrowUp } from "react-icons/md";
import ConnectingBuyersAndSellers from "../../components/connectingBuyersAndSellers/ConnectingBuyersAndSellers";
import ImproveOuService from "../../components/improveOuService/ImproveOuService";
import Cards from "../../components/featuredProperties/Cards/Cards";
import LogoSection from "../../components/logoSection/LogoSection";
import AboutAvenew from "../../components/aboutAvenew/AboutAvenew";
import Footer from "../../components/footer/Footer";
import { IoIosArrowDown, IoIosClose } from "react-icons/io";
import {
  getUnits,
  me,
  updateApplicationInvitation,
  updateInvitation,
} from "../../store/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import InvitationModal from "../../components/invitationModal/InvitationModal";
// import Fade from "react-fade-in";
import { useLocation, useNavigate } from "react-router-dom";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import HeropartDashboard from "./HeropartDashboard";
import ApplicationInvitationModal from "../../components/invitationModal/ApplicationInvitationModal";
import { Capitalize } from "../../utils/fn";

function Dashboard() {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { TabPane } = Tabs;
  const [allUnits, setAllUnits] = useState([]);
  const [invitaionsData, setInvitaionsData] = useState([]);
  const [applicationInvitaionsData, setApplicationInvitaionsData] = useState(
    []
  );
  const [dataModal, setDataModal] = useState({});
  const [applicationDataModal, setApplicationDataModal] = useState({});
  const [unitName, setUnitName] = useState("");
  const [viewNotification, setViewNotification] = useState(false);
  const [isLoadingDeny, setIsLoadingDeny] = useState(false);
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingCansel, setIsLoadingCansel] = useState(false);
  const [applicationModalState, setApplicationModalState] = useState(false);

  const [seeView, setSeeView] = useState(true);
  const { user } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (width < 768) {
      setSeeView(false);
    } else {
      setSeeView(true);
    }
  }, [width, location.pathname]);

  const style = {
    height: 40,
    width: 40,
    lineHeight: "40px",
    borderRadius: "50%",
    backgroundColor: "#4160AC90",
    color: "#fff",
    textAlign: "center",
    fontSize: 40,
  };

  const onFinish = (value) => {
    // console.log(value);
  };

  const onValueChange = (value) => {
    // console.log(value);
  };

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onChange = (key) => {
    console.log(key);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getUnits()).then(({ payload }) => {
      if (payload) {
        setAllUnits(Object.values(payload));
      }
    });
  }, []);

  useEffect(() => {
    if (user) {
      // setDataModal(user.invitations[0]);
      setUnitName(user.invitations[0]?.unit_name);
    }
    if (
      user &&
      !user?.isSeen &&
      invitaionsData?.length &&
      (!invitaionsData[0]?.invitaionRequest === "cancel" ||
        invitaionsData[0]?.invitaionRequest === "sent")
    ) {
      setModalState(true);
    }
  }, [user, invitaionsData]);

  useEffect(() => {
    if (user) {
      const data = user?.invitations.filter((item) => {
        return (
          item.invitaionRequest === "sent" || item.invitaionRequest === "cancel"
        );
      });
      setInvitaionsData(data);
      setViewNotification(true);
    }
  }, [user]);

  useEffect(() => {
    if (invitaionsData) {
      setDataModal(invitaionsData[0]);
    }
  }, [invitaionsData]);

  const [modalState, setModalState] = useState(false);
  const [dismiss, setDismiss] = useState(true);
  const [modalStates, setModalStates] = useState(false);

  useEffect(() => {
    if (
      user?.invitations[0]?.invitaionRequest === "cancel" ||
      user?.invitations[0]?.invitaionRequest === "sent"
    ) {
      setViewNotification(true);
    } else {
      // setViewNotification(false);
    }
  }, [user, modalState]);

  const statusBtn = (status, dataModal) => {
    const objCopy = { ...dataModal };
    objCopy.invitaionRequest = status;
    objCopy.prospect_email = user?.email;
    objCopy.prospect_id = user?.id;
    objCopy.unitObj = {};

    if (status === "denied" || status === "approved") {
      setViewNotification(false);
    }
    if (status === "denied") {
      setIsLoadingDeny(true);
    } else if (status === "approved") {
      setIsLoadingApprove(true);
    } else if (status === "cancel") {
      setIsLoadingCansel(true);
    }
    dispatch(updateInvitation(objCopy)).then((res) => {
      setModalState(false);
      setIsLoadingDeny(false);
      setIsLoadingApprove(false);
      setIsLoadingCansel(false);
      if (status === "approved") {
        navigate("/my-rentals");
      }

      if (status === "denied" || status === "approved") {
        dispatch(me());
      }
    });
  };

  const applicationStatusBtn = (status, dataModal) => {
    let objCopy = { ...dataModal };
    objCopy.invitationStatus = status;
    if (status === "reject") {
      setIsLoadingDeny(true);
    } else if (status === "accept") {
      setIsLoadingApprove(true);
    } else if (status === "cancel") {
      setIsLoadingCansel(true);
    }
    dispatch(updateApplicationInvitation(objCopy)).then((res) => {
      setApplicationModalState(false);
      if (status === "accept") {
        navigate("/application-process");
      }
      if (status === "reject" || status === "cancel") {
        dispatch(me());
      }
    });
  };

  useEffect(() => {
    if (user?.applicationInvitations && user?.applicationInvitations?.length) {
      const data = user?.applicationInvitations.filter((item) => {
        return (
          item.invitationStatus === "send" || item.invitationStatus === "cancel"
        );
      });
      setApplicationInvitaionsData(data);
    }
  }, [user]);

  useEffect(() => {
    if (applicationInvitaionsData?.length) {
      if (
        !user?.applicationInvitations[0]?.invitationStatus === "cancel" ||
        user?.applicationInvitations[0]?.invitationStatus === "send"
      ) {
        setApplicationModalState(true);
      }
      setApplicationDataModal(applicationInvitaionsData[0]);
    }
  }, [applicationInvitaionsData]);

  useEffect(() => {
    if (
      user?.applicationInvitations[0]?.invitationStatus === "cancel" ||
      user?.applicationInvitations[0]?.invitationStatus === "send"
    ) {
      setViewNotification(true);
    } else {
      // setViewNotification(false);
    }
  }, [user, applicationModalState]);

  return (
    <>
      {loading ? (
        <Container padding="0 50px 0px 330px">
          <div
            style={{
              position: "relative",
              minHeight: "calc(100vh - 80px)",
            }}
          >
            <Spin className="ant__spin" size="large" />
          </div>
        </Container>
      ) : (
        <DashboardWrap>
          {viewNotification &&
          localStorage.getItem("dismiss") != "dismiss" &&
          dismiss ? (
            <>
              {invitaionsData?.map((item, id) => (
                <ViewNotification key={id}>
                  <ViewNotificationWrap>
                    {seeView ? (
                      <h3>Invitation Pending from {item.unit_name}</h3>
                    ) : (
                      <h3>Invitation Pending </h3>
                    )}
                    <h2
                      onClick={() => {
                        setDataModal(item);
                        setModalState(true);
                      }}
                    >
                      View
                    </h2>
                    {seeView ? (
                      <h2
                        onClick={() => {
                          localStorage.setItem("dismiss", "dismiss");
                          setDismiss(false);
                        }}
                      >
                        Dismiss
                      </h2>
                    ) : (
                      <IoIosClose
                        onClick={() => {
                          localStorage.setItem("dismiss", "dismiss");
                          setDismiss(false);
                        }}
                        className="modal_close_icon_header"
                      />
                    )}
                  </ViewNotificationWrap>
                </ViewNotification>
              ))}

              {applicationInvitaionsData?.map((item, id) => (
                <ViewNotification key={id}>
                  <ViewNotificationWrap>
                    {seeView ? (
                      <h3>
                        Invitation Pending from {Capitalize(item.invitedName)}
                      </h3>
                    ) : (
                      <h3>Invitation Pending </h3>
                    )}
                    <h2
                      onClick={() => {
                        setApplicationDataModal(item);
                        setApplicationModalState(true);
                      }}
                    >
                      View
                    </h2>
                    {seeView ? (
                      <h2
                        onClick={() => {
                          localStorage.setItem("dismiss", "dismiss");
                          setDismiss(false);
                        }}
                      >
                        Dismiss
                      </h2>
                    ) : (
                      <IoIosClose
                        onClick={() => {
                          localStorage.setItem("dismiss", "dismiss");
                          setDismiss(false);
                        }}
                        className="modal_close_icon_header"
                      />
                    )}
                  </ViewNotificationWrap>
                </ViewNotification>
              ))}
            </>
          ) : (
            ""
          )}
          <HeropartDashboard />

          {/* <DashboardFilterWrap>
            <DashboardFilter>
              <Row>
                <Col
                  className="sell__property"
                  xs={24}
                  sm={15}
                  md={16}
                  lg={13}
                  xl={12}
                  xxl={13}
                >
                  <h2>Sell or rent your property at the best price</h2>
                </Col>
              </Row>

              <Row className="find__property">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form
                    onFinish={onFinish}
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    validateMessages={validateMessages}
                  >
                    <StyledFormItem smwh="100%">
                      <StyledFormItemd
                        mb="24px"
                        wh="29"
                        name="location"
                        label="Where you want?"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input placeholder="Enter city, or zipcode" />
                      </StyledFormItemd>
                      <StyledFormItemd
                        wh="12"
                        name="minPrice"
                        label="Min Price"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Min Price"
                          onChange={onValueChange}
                          suffixIcon={<IoIosArrowDown />}
                        >
                          <Option value="15.00">$15.00</Option>
                          <Option value="20.00">$20.00</Option>
                          <Option value="25.00">$25.00</Option>
                        </Select>
                      </StyledFormItemd>
                      <StyledFormItemd
                        wh="12"
                        name="maxPrice"
                        label="Max Price"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Max Price"
                          onChange={onValueChange}
                          suffixIcon={<IoIosArrowDown />}
                        >
                          <Option value="150.00">$150.00</Option>
                          <Option value="200.00">$200.00</Option>
                          <Option value="250.00">$250.00</Option>
                        </Select>
                      </StyledFormItemd>
                      <StyledFormItemd
                        wh="12"
                        name="beds"
                        label="Beds"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Beds"
                          onChange={onValueChange}
                          suffixIcon={<IoIosArrowDown />}
                        >
                          <Option value="1 Bed">1 Bed</Option>
                          <Option value="2 Bed">2 Bed</Option>
                          <Option value="3 Bed">3 Bed</Option>
                        </Select>
                      </StyledFormItemd>
                      <StyledFormItemd
                        wh="12"
                        name="baths"
                        label="Baths"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Baths"
                          onChange={onValueChange}
                          suffixIcon={<IoIosArrowDown />}
                        >
                          <Option value="1 Bath">1 Bath</Option>
                          <Option value="2 Bath">2 Bath</Option>
                          <Option value="3 Bath">3 Bath</Option>
                        </Select>
                      </StyledFormItemd>

                      <StyledFormItemd wh="13" sWh="17%">
                        <StyledButton htmlType="submit">
                          Find Property
                        </StyledButton>
                      </StyledFormItemd>
                    </StyledFormItem>
                  </Form>
                </Col>
              </Row>
            </DashboardFilter>
            <MobileTabs>
              <Tabs defaultActiveKey="1" onChange={onChange}>
                <TabPane tab="Location" key="1">
                  <StyledFormItem smwh="100%">
                    <StyledFormItemd
                      mb="24px"
                      wh="29"
                      name="location"
                      label="Where you want?"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Enter city, or zipcode" />
                    </StyledFormItemd>
                  </StyledFormItem>
                </TabPane>
                <TabPane tab="Price" key="2">
                  <StyledFormItem fd="row">
                    <StyledFormItemd
                      mb="24px"
                      wh="12"
                      name="minPrice"
                      label="Min Price"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Min Price"
                        onChange={onValueChange}
                        suffixIcon={<IoIosArrowDown />}
                      >
                        <Option value="15.00">$15.00</Option>
                        <Option value="20.00">$20.00</Option>
                        <Option value="25.00">$25.00</Option>
                      </Select>
                    </StyledFormItemd>
                    <StyledFormItemd
                      wh="12"
                      name="maxPrice"
                      label="Max Price"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Max Price"
                        onChange={onValueChange}
                        suffixIcon={<IoIosArrowDown />}
                      >
                        <Option value="150.00">$150.00</Option>
                        <Option value="200.00">$200.00</Option>
                        <Option value="250.00">$250.00</Option>
                      </Select>
                    </StyledFormItemd>
                  </StyledFormItem>
                </TabPane>
                <TabPane tab="Beds & Bath" key="3">
                  <StyledFormItem fd="row" smwh="35%">
                    <StyledFormItemd
                      mb="24px"
                      name="beds"
                      label="Beds"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Beds"
                        onChange={onValueChange}
                        suffixIcon={<IoIosArrowDown />}
                      >
                        <Option value="1 Bed">1 Bed</Option>
                        <Option value="2 Bed">2 Bed</Option>
                        <Option value="3 Bed">3 Bed</Option>
                      </Select>
                    </StyledFormItemd>
                    <StyledFormItemd
                      name="baths"
                      label="Baths"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Baths"
                        onChange={onValueChange}
                        suffixIcon={<IoIosArrowDown />}
                      >
                        <Option value="1 Bath">1 Bath</Option>
                        <Option value="2 Bath">2 Bath</Option>
                        <Option value="3 Bath">3 Bath</Option>
                      </Select>
                    </StyledFormItemd>

                    <StyledFormItemd
                      mb="24px"
                      wh="13"
                      sWh="17%"
                      xsWh="17% !important"
                    >
                      <StyledButton htmlType="submit">Find</StyledButton>
                    </StyledFormItemd>
                  </StyledFormItem>
                </TabPane>
              </Tabs>
            </MobileTabs>
          </DashboardFilterWrap>
          <Cards allUnits={allUnits} />

          <ConnectingBuyersAndSellers />
          <ImproveOuService />
          <LogoSection />
          <AboutAvenew />
          <Footer />
          <BackTop>
            <div style={style}>
              <MdKeyboardArrowUp />
            </div>
          </BackTop>
           */}
          <Cards allUnits={allUnits} />

          <InvitationModal
            onCancel={(value) => setModalState(value)}
            modalState={modalState}
            onSubmit={() => setModalStates(true)}
            dataModal={dataModal}
            statusBtn={statusBtn}
            isLoadingApprove={isLoadingApprove}
            isLoadingDeny={isLoadingDeny}
            isLoadingCansel={isLoadingCansel}
          />

          <ApplicationInvitationModal
            onCancel={(value) => setApplicationModalState(value)}
            modalState={applicationModalState}
            statusBtn={applicationStatusBtn}
            dataModal={applicationDataModal}
            isLoadingApprove={isLoadingApprove}
            isLoadingDeny={isLoadingDeny}
            isLoadingCansel={isLoadingCansel}
          />
        </DashboardWrap>
      )}
    </>
  );
}

export default Dashboard;

const ViewNotification = styled.div`
  height: 50px;
  background: #4160ac;

  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
`;
const ViewNotificationWrap = styled.div`
  max-width: 1400px;
  margin: auto;
  display: flex;
  h3,
  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    padding-top: 13px;
    color: #ffffff;
    margin-bottom: 0;
  }
  h2 {
    margin-left: 30px;
    cursor: pointer;
  }

  @media (max-width: 1440px) {
    padding: 0 30px;
  }
`;
