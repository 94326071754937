import { Modal } from "antd";
import React from "react";
import { IoIosClose } from "react-icons/io";
import styled from "styled-components";
import { StyledButton } from "../property-details/PropertyDetails";

function AutomaticVerificationModal({ modalState, onCancel }) {
  return (
    <Modal
      getContainer={() => document.querySelector("#modal-container")}
      visible={modalState}
      onCancel={() => {
        onCancel(false);
      }}
      width={"600px"}
      footer={null}
      // style={{top: "50px" }}
      className="modal_wrapper"
      closeIcon={false}
      maskStyle={{
        background: "rgba(0, 0, 0, 0.3)",
        backdropFilter: "blur(20px)",
      }}
      focusTriggerAfterClose={false}
      destroyOnClose={true}
    >
      <ModalWrap>
        <p>
          Automatic income verification is managed by third party provider
          Plaid. Avenew will receive information pertaining to verifying your
          income only this one time with your consent.
        </p>
      </ModalWrap>
      <StyledButton>Continue</StyledButton>
      <ModalWrapMain>
        <span
          onClick={() => {
            onCancel(false);
          }}
        >
          Cancel
        </span>
      </ModalWrapMain>
    </Modal>
  );
}

export default AutomaticVerificationModal;

export const ModalWrapMain = styled.div`
  margin-top: 35px;
  text-align: center;
  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #777777;
    cursor: pointer;
  }
`;

export const ModalWrap = styled.div`
  padding: 10px;
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #2b353f;
    margin-bottom: 20px;
  }
`;
