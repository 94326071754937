import React from "react";

const payCashLockSm = ({
  wSize = "18",
  hSize = "23",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox="0 0 18 23"
      {...props}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0C7.4087 0 5.88258 0.632141 4.75736 1.75736C3.63214 2.88258 3 4.4087 3 6V7H5V6C5 4.93913 5.42143 3.92172 6.17157 3.17157C6.92172 2.42143 7.93913 2 9 2C10.0609 2 11.0783 2.42143 11.8284 3.17157C12.5786 3.92172 13 4.93913 13 6V7H15V6C15 4.4087 14.3679 2.88258 13.2426 1.75736C12.1174 0.632141 10.5913 0 9 0V0Z"
        fill="#A1A2A3"
      />
      <path
        d="M15 9H3C2.20435 9 1.44129 9.31607 0.87868 9.87868C0.316071 10.4413 0 11.2044 0 12V20C0 20.7956 0.316071 21.5587 0.87868 22.1213C1.44129 22.6839 2.20435 23 3 23H15C15.7956 23 16.5587 22.6839 17.1213 22.1213C17.6839 21.5587 18 20.7956 18 20V12C18 11.2044 17.6839 10.4413 17.1213 9.87868C16.5587 9.31607 15.7956 9 15 9ZM10 16.73V19C10 19.2652 9.89464 19.5196 9.70711 19.7071C9.51957 19.8946 9.26522 20 9 20C8.73478 20 8.48043 19.8946 8.29289 19.7071C8.10536 19.5196 8 19.2652 8 19V16.73C7.61872 16.5099 7.32072 16.1701 7.15224 15.7633C6.98376 15.3566 6.9542 14.9056 7.06815 14.4803C7.1821 14.055 7.43319 13.6793 7.78248 13.4112C8.13177 13.1432 8.55973 12.9979 9 12.9979C9.44027 12.9979 9.86823 13.1432 10.2175 13.4112C10.5668 13.6793 10.8179 14.055 10.9319 14.4803C11.0458 14.9056 11.0162 15.3566 10.8478 15.7633C10.6793 16.1701 10.3813 16.5099 10 16.73Z"
        fill="#A1A2A3"
      />
    </svg>
  );
};

export default payCashLockSm;
