import React from "react";

const SendMail = ({
  wSize = "22",
  hSize = "21",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 18L21 9L0 0V7L15 9L0 11V18Z"
        fill="#F67C36"
      />
    </svg>
  );
};

export default SendMail;
