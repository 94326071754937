import React from "react";

const noApplications = ({
  wSize = "213",
  hSize = "147",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox="0 0 213 147"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M190.584 75.8301C192.898 76.3209 195.01 77.497 196.646 79.2051C198.282 80.9132 199.366 83.0744 199.757 85.4072C200.099 87.7071 199.757 90.1313 200.538 92.3202C201.378 94.629 203.349 96.3162 204.756 98.3231C206.073 100.246 206.889 102.467 207.131 104.785C207.373 107.103 207.033 109.445 206.142 111.599C210.964 113.206 213.401 119.222 211.963 124.093C210.524 128.964 205.991 132.489 201.085 133.812C196.178 135.135 190.952 134.54 186.02 133.333"
        fill="#EBEBEB"
      />
      <path
        d="M195.282 146.681C195.215 146.681 195.166 138.977 195.166 129.471C195.166 119.965 195.215 112.262 195.282 112.262C195.348 112.262 195.397 119.965 195.397 129.471C195.397 138.977 195.344 146.681 195.282 146.681Z"
        fill="#E0E0E0"
      />
      <path
        d="M200.405 115.164C199.675 116.168 198.882 117.125 198.03 118.028C197.232 118.981 196.376 119.884 195.468 120.732C196.199 119.727 196.994 118.771 197.848 117.868C198.644 116.915 199.498 116.012 200.405 115.164Z"
        fill="#E0E0E0"
      />
      <path
        d="M121.986 26.6577H152.729C152.729 26.6577 150.011 21.7071 142.099 22.4264C134.551 23.1146 134.156 19.9933 130.311 19.6114C126.466 19.2296 123.265 22.1644 121.986 26.6577Z"
        fill="#F5F5F5"
      />
      <path
        d="M175.262 23.1155H206.009C206.009 23.1155 203.287 18.1694 195.375 18.8887C187.827 19.5769 187.432 16.4555 183.587 16.0693C179.742 15.683 176.54 18.6134 175.262 23.1155Z"
        fill="#F5F5F5"
      />
      <path
        d="M12.096 32.6333H35.8012C35.8012 32.6333 33.7055 28.8193 27.6049 29.3743C21.7796 29.9027 21.4822 27.4962 18.5207 27.2032C15.5592 26.9101 13.0861 29.1745 12.096 32.6333Z"
        fill="#EBEBEB"
      />
      <path
        d="M34.5269 17.8444H61.8462C61.8462 17.8444 59.4309 13.4443 52.3979 14.0881C45.6846 14.6964 45.3428 11.9214 41.9284 11.584C38.514 11.2465 35.668 13.8572 34.5269 17.8444Z"
        fill="#F5F5F5"
      />
      <path
        d="M26.7169 65.4857C21.2069 64.8241 15.5459 68.9577 14.4981 74.4056C13.872 77.6557 14.6668 81.261 13.0506 84.1736C12.1626 85.7853 10.6131 86.9264 9.34321 88.2628C7.4201 90.336 6.08522 92.885 5.47641 95.6465C4.8676 98.408 5.00693 101.282 5.88001 103.972C3.21601 104.86 1.37341 107.524 0.99601 110.307C0.61861 113.091 1.51549 115.933 2.96293 118.344C4.4107 120.72 6.36603 122.746 8.6888 124.277C11.0116 125.809 13.6443 126.807 16.3984 127.202C19.157 127.558 21.9603 127.3 24.6076 126.447C27.2549 125.593 29.6809 124.165 31.7119 122.264"
        fill="#E0E0E0"
      />
      <path
        d="M17.8192 146.765C17.757 146.765 17.7037 136.172 17.7037 123.105C17.7037 110.038 17.757 99.4395 17.8192 99.4395C17.8813 99.4395 17.9346 110.033 17.9346 123.105C17.9346 136.176 17.868 146.765 17.8192 146.765Z"
        fill="#EBEBEB"
      />
      <path
        d="M17.8014 116.804C16.9333 115.716 16.1269 114.581 15.3861 113.403C14.5807 112.269 13.8395 111.09 13.1661 109.873C14.0342 110.961 14.8406 112.096 15.5814 113.274C16.3868 114.408 17.128 115.587 17.8014 116.804Z"
        fill="#EBEBEB"
      />
      <path
        d="M98.1388 9.11112H136.927C136.927 9.11112 133.495 2.86848 123.513 3.78312C113.976 4.67112 113.492 0.710636 108.644 0.231116C103.795 -0.248405 99.755 3.45456 98.1388 9.11112Z"
        fill="#EBEBEB"
      />
      <path
        d="M206.848 146.68C206.848 146.746 161.387 146.795 105.318 146.795C49.2499 146.795 3.77991 146.746 3.77991 146.68C3.77991 146.613 49.2322 146.564 105.318 146.564C161.404 146.564 206.848 146.618 206.848 146.68Z"
        fill="#263238"
      />
      <path
        d="M83.4246 14.6953H58.978V24.5654H83.4246V14.6953Z"
        fill="#B0BEC5"
      />
      <g opacity="0.7">
        <path
          d="M83.4246 14.6953H58.978V24.5654H83.4246V14.6953Z"
          fill="black"
        />
      </g>
      <path
        d="M92.1847 14.6953H67.7381V24.5654H92.1847V14.6953Z"
        fill="#B0BEC5"
      />
      <g opacity="0.4">
        <path
          d="M92.1847 14.6953H67.7381V24.5654H92.1847V14.6953Z"
          fill="black"
        />
      </g>
      <path
        d="M84.2549 24.1035H39.7661V146.763H84.2549V24.1035Z"
        fill="#B0BEC5"
      />
      <g opacity="0.7">
        <path
          d="M56.607 24.1035H39.7661V146.763H56.607V24.1035Z"
          fill="black"
        />
      </g>
      <path
        d="M112.915 24.1035H68.4263V146.763H112.915V24.1035Z"
        fill="#B0BEC5"
      />
      <g opacity="0.4">
        <path
          d="M112.915 24.1035H56.6071V146.763H112.915V24.1035Z"
          fill="black"
        />
      </g>
      <path
        d="M94.236 24.1311C94.236 24.1977 86.3151 24.2465 76.5515 24.2465C66.788 24.2465 58.867 24.1977 58.867 24.1311C58.867 24.0645 66.7835 24.0156 76.5515 24.0156C86.3195 24.0156 94.236 24.0689 94.236 24.1311Z"
        fill="#263238"
      />
      <path
        d="M79.6595 32.6504H64.679V67.4911H79.6595V32.6504Z"
        fill="#FAFAFA"
      />
      <path
        d="M79.6595 67.4904C79.6595 67.4904 79.6595 67.4327 79.6595 67.3172C79.6595 67.2018 79.6595 67.0286 79.6595 66.8111C79.6595 66.3671 79.6595 65.71 79.6595 64.8708C79.6595 63.1747 79.6595 60.715 79.6285 57.6247C79.6285 51.4442 79.5974 42.7507 79.5752 32.6497L79.6862 32.7607H64.679L64.7944 32.6453C64.7944 46.2228 64.7678 58.526 64.7589 67.486L64.679 67.406L75.6147 67.4504H78.6073H79.3887H79.6595C79.6595 67.4504 79.5752 67.4504 79.4065 67.4504H78.6383L75.668 67.4726L64.679 67.517H64.5946V67.4327C64.5946 58.4728 64.5946 46.1695 64.5635 32.592V32.4766H79.7705V32.5876C79.7705 42.7196 79.7306 51.4398 79.7173 57.638C79.7173 60.715 79.6951 63.1658 79.6862 64.8575C79.6862 65.6878 79.6862 66.3316 79.6862 66.7756C79.6862 66.9887 79.6862 67.1485 79.6862 67.2684C79.6862 67.3883 79.6595 67.4904 79.6595 67.4904Z"
        fill="#263238"
      />
      <path
        d="M79.8993 39.7775C79.8993 39.8441 76.445 39.893 72.187 39.893C67.929 39.893 64.4703 39.8441 64.4703 39.7775C64.4703 39.7109 67.9246 39.6621 72.187 39.6621C76.4494 39.6621 79.8993 39.7154 79.8993 39.7775Z"
        fill="#263238"
      />
      <path
        d="M72.3291 67.2293C72.2669 67.2293 72.2136 61.1065 72.2136 53.5541C72.2136 46.0017 72.2669 39.8789 72.3291 39.8789C72.3912 39.8789 72.4445 46.0017 72.4445 53.5541C72.4445 61.1065 72.3912 67.2293 72.3291 67.2293Z"
        fill="#263238"
      />
      <path
        d="M106.686 32.6504H91.7053V67.4911H106.686V32.6504Z"
        fill="#FAFAFA"
      />
      <path
        d="M106.668 67.4904C106.668 67.4904 106.668 67.4327 106.668 67.3172C106.668 67.2018 106.668 67.0286 106.668 66.8111C106.668 66.3671 106.668 65.71 106.668 64.8708C106.668 63.1747 106.668 60.715 106.637 57.6247C106.637 51.4442 106.606 42.7507 106.584 32.6497L106.695 32.7607H91.7052L91.8206 32.6453C91.8206 46.2228 91.794 58.526 91.7851 67.486L91.7052 67.406L102.641 67.4504H105.629H106.415H106.686C106.686 67.4504 106.601 67.4504 106.428 67.4504H105.66L102.69 67.4726L91.7052 67.517H91.6208V67.4327C91.6208 58.4728 91.5986 46.1695 91.5853 32.592V32.4766H106.797V32.5876C106.797 42.7196 106.757 51.4398 106.743 57.638C106.743 60.715 106.743 63.1658 106.712 64.8575C106.712 65.6878 106.712 66.3316 106.712 66.7756C106.712 66.9887 106.712 67.1485 106.712 67.2684C106.712 67.3883 106.668 67.4904 106.668 67.4904Z"
        fill="#263238"
      />
      <path
        d="M106.926 39.7775C106.926 39.8441 103.471 39.893 99.2089 39.893C94.9465 39.893 91.4966 39.8441 91.4966 39.7775C91.4966 39.7109 94.9509 39.6621 99.2089 39.6621C103.467 39.6621 106.926 39.7154 106.926 39.7775Z"
        fill="#263238"
      />
      <path
        d="M99.3554 67.2293C99.2888 67.2293 99.2399 61.1065 99.2399 53.5541C99.2399 46.0017 99.2888 39.8789 99.3554 39.8789C99.422 39.8789 99.4708 46.0017 99.4708 53.5541C99.4708 61.1065 99.4175 67.2293 99.3554 67.2293Z"
        fill="#263238"
      />
      <path
        d="M79.6595 75.0703H64.679V109.911H79.6595V75.0703Z"
        fill="#FAFAFA"
      />
      <path
        d="M79.6595 109.916C79.6595 109.916 79.6595 109.854 79.6595 109.738C79.6595 109.623 79.6595 109.45 79.6595 109.232C79.6595 108.788 79.6595 108.131 79.6595 107.292C79.6595 105.596 79.6595 103.136 79.6285 100.046C79.6285 93.8698 79.5974 85.1719 79.5752 75.0709L79.6862 75.1819H64.679L64.7944 75.0664C64.7944 88.644 64.7678 100.947 64.7589 109.912L64.679 109.827L75.6147 109.872L78.6073 109.894H79.3887C79.5663 109.894 79.6595 109.894 79.6595 109.894H79.4065H78.6383L75.668 109.916L64.679 109.96H64.5946V109.88C64.5946 100.916 64.5946 88.6129 64.5635 75.0354V74.9199H64.679H79.6595H79.7705V75.0265C79.7705 85.1586 79.7306 93.8787 79.7173 100.077C79.7173 103.154 79.6951 105.605 79.6862 107.296C79.6862 108.127 79.6862 108.77 79.6862 109.214C79.6862 109.428 79.6862 109.587 79.6862 109.707C79.6862 109.827 79.6595 109.916 79.6595 109.916Z"
        fill="#263238"
      />
      <path
        d="M79.8993 82.1975C79.8993 82.2641 76.445 82.3129 72.187 82.3129C67.929 82.3129 64.4703 82.2641 64.4703 82.1975C64.4703 82.1309 67.9246 82.082 72.187 82.082C76.4494 82.082 79.8993 82.1353 79.8993 82.1975Z"
        fill="#263238"
      />
      <path
        d="M72.3291 109.632C72.2669 109.632 72.2136 103.509 72.2136 95.9565C72.2136 88.404 72.2669 82.2812 72.3291 82.2812C72.3912 82.2812 72.4445 88.404 72.4445 95.9565C72.4445 103.509 72.3912 109.632 72.3291 109.632Z"
        fill="#263238"
      />
      <path
        d="M106.686 75.0703H91.7053V109.911H106.686V75.0703Z"
        fill="#FAFAFA"
      />
      <path
        d="M106.668 109.916C106.668 109.916 106.668 109.854 106.668 109.738C106.668 109.623 106.668 109.45 106.668 109.232C106.668 108.788 106.668 108.131 106.668 107.292C106.668 105.596 106.668 103.136 106.637 100.046C106.637 93.8698 106.606 85.1719 106.584 75.0709L106.695 75.1819H91.7052L91.8206 75.0664C91.8206 88.644 91.794 100.947 91.7851 109.912L91.7052 109.827L102.641 109.872L105.629 109.894H106.415C106.588 109.894 106.686 109.894 106.686 109.894H106.428H105.66L102.69 109.916L91.7052 109.96H91.6208V109.88C91.6208 100.916 91.5986 88.6129 91.5853 75.0354V74.9199H91.7052H106.686H106.797V75.0265C106.797 85.1586 106.757 93.8787 106.743 100.077C106.743 103.154 106.743 105.605 106.712 107.296C106.712 108.127 106.712 108.77 106.712 109.214C106.712 109.428 106.712 109.587 106.712 109.707C106.712 109.827 106.668 109.916 106.668 109.916Z"
        fill="#263238"
      />
      <path
        d="M106.926 82.1975C106.926 82.2641 103.471 82.3129 99.2089 82.3129C94.9465 82.3129 91.4966 82.2641 91.4966 82.1975C91.4966 82.1309 94.9509 82.082 99.2089 82.082C103.467 82.082 106.926 82.1353 106.926 82.1975Z"
        fill="#263238"
      />
      <path
        d="M99.3554 109.632C99.2888 109.632 99.2399 103.509 99.2399 95.9565C99.2399 88.404 99.2888 82.2812 99.3554 82.2812C99.422 82.2812 99.4708 88.404 99.4708 95.9565C99.4708 103.509 99.4175 109.632 99.3554 109.632Z"
        fill="#263238"
      />
      <path
        d="M86.0886 146.763C86.0886 146.763 86.0886 146.714 86.0886 146.621C86.0886 146.528 86.0886 146.39 86.0886 146.221C86.0886 145.857 86.0886 145.342 86.0886 144.676C86.0886 143.322 86.0886 141.364 86.0575 138.904C86.0575 133.984 86.0264 127.049 86.0087 118.973L86.1153 119.079H72.7953C72.7686 119.102 73.0128 118.862 72.9107 118.968V122.964C72.9107 124.274 72.9107 125.571 72.9107 126.841C72.9107 129.385 72.9107 131.844 72.9107 134.193C72.9107 138.891 72.9107 143.149 72.9107 146.772L72.8263 146.687L82.5366 146.732H85.2006H85.8977H86.1419H85.9154H85.2361L82.5721 146.754L72.8041 146.798H72.7198V146.714C72.7198 143.091 72.7198 138.833 72.6976 134.135C72.6976 131.787 72.6976 129.327 72.6976 126.783V118.977L72.813 118.857H86.1996V118.955C86.1774 127.063 86.1596 134.02 86.1463 138.962C86.1463 141.408 86.1241 143.357 86.1153 144.707C86.1153 145.364 86.1153 145.87 86.1153 146.23C86.1153 146.394 86.1153 146.523 86.1153 146.616C86.1124 146.666 86.1034 146.715 86.0886 146.763Z"
        fill="#263238"
      />
      <path
        d="M99.3997 146.763C99.3959 146.716 99.3959 146.668 99.3997 146.621C99.3997 146.523 99.3997 146.39 99.3997 146.222C99.3997 145.857 99.3997 145.342 99.3997 144.676C99.3997 143.322 99.3997 141.364 99.3687 138.904C99.3687 133.985 99.3376 127.05 99.3154 118.973L99.4264 119.08H86.1064C86.0753 119.102 86.3239 118.862 86.2174 118.969V126.823C86.2174 129.367 86.2174 131.827 86.2174 134.176C86.2174 138.873 86.2174 143.131 86.2174 146.754L86.133 146.67L95.8433 146.714H98.5073H99.2044H99.4486H99.2221H98.5384L95.901 146.737L86.133 146.781H86.0487V146.697C86.0487 143.074 86.0487 138.816 86.0265 134.118C86.0265 131.769 86.0265 129.31 86.0265 126.765C86.0265 125.496 86.0265 124.199 86.0265 122.889V118.893C85.9243 118.995 86.1641 118.747 86.1463 118.773H99.5596V118.956C99.5374 127.063 99.5196 134.02 99.5063 138.962C99.5063 141.409 99.4841 143.358 99.4797 144.708C99.4797 145.365 99.4797 145.871 99.4797 146.23V146.617C99.4564 146.667 99.4297 146.716 99.3997 146.763Z"
        fill="#263238"
      />
      <path
        d="M87.838 132.404C87.8113 132.404 88.0111 132.227 88.3308 132.36C88.426 132.4 88.5078 132.467 88.5668 132.552C88.6257 132.636 88.6594 132.736 88.6638 132.84C88.6723 132.969 88.639 133.099 88.5686 133.208C88.4983 133.318 88.3947 133.402 88.2731 133.448C88.1484 133.485 88.0149 133.481 87.893 133.435C87.7711 133.389 87.6675 133.305 87.5982 133.195C87.5404 133.106 87.513 133.001 87.5202 132.895C87.5274 132.789 87.5688 132.689 87.6382 132.609C87.679 132.556 87.7306 132.512 87.7895 132.481C87.8485 132.45 87.9134 132.431 87.9801 132.427C88.0733 132.427 88.1177 132.453 88.1133 132.467C88.1088 132.48 87.9135 132.467 87.7714 132.706C87.7324 132.761 87.7114 132.826 87.7114 132.893C87.7114 132.96 87.7324 133.025 87.7714 133.079C87.8191 133.149 87.8882 133.202 87.9682 133.229C88.0483 133.256 88.135 133.257 88.2154 133.23C88.2905 133.199 88.3547 133.146 88.3997 133.078C88.4448 133.01 88.4687 132.93 88.4685 132.848C88.4695 132.779 88.452 132.711 88.4176 132.651C88.3832 132.591 88.3333 132.541 88.2731 132.507C88.02 132.369 87.8424 132.436 87.838 132.404Z"
        fill="#263238"
      />
      <path
        d="M83.136 132.404C83.1138 132.404 83.3092 132.227 83.6333 132.36C83.7279 132.401 83.8091 132.468 83.8679 132.553C83.9267 132.637 83.9608 132.737 83.9663 132.84C83.9734 132.969 83.9395 133.098 83.8693 133.207C83.7992 133.316 83.6964 133.4 83.5756 133.448C83.4503 133.486 83.3157 133.483 83.1929 133.437C83.0701 133.391 82.9658 133.306 82.8962 133.195C82.8404 133.105 82.8146 133 82.8226 132.895C82.8306 132.789 82.8719 132.689 82.9406 132.609C82.9814 132.556 83.033 132.512 83.092 132.481C83.1509 132.45 83.2159 132.431 83.2825 132.427C83.3758 132.427 83.4202 132.453 83.4157 132.467C83.4113 132.48 83.2115 132.467 83.0738 132.706C83.036 132.761 83.0158 132.826 83.0158 132.893C83.0158 132.959 83.036 133.025 83.0738 133.079C83.123 133.148 83.1921 133.199 83.2717 133.226C83.3513 133.253 83.4373 133.254 83.5178 133.23C83.5907 133.196 83.6528 133.142 83.6974 133.075C83.742 133.007 83.7674 132.929 83.7709 132.848C83.7711 132.779 83.7531 132.711 83.7189 132.651C83.6846 132.591 83.6352 132.542 83.5756 132.507C83.3358 132.369 83.136 132.436 83.136 132.404Z"
        fill="#263238"
      />
      <path
        d="M178.547 9.63477H146.122V146.755H178.547V9.63477Z"
        fill="#B0BEC5"
      />
      <path
        d="M162.021 19.2422H152.165V38.2321H162.021V19.2422Z"
        fill="#E0E0E0"
      />
      <path
        d="M157.368 38.2365C157.306 38.2365 157.253 33.983 157.253 28.7393C157.253 23.4957 157.306 19.2422 157.368 19.2422C157.431 19.2422 157.484 23.4957 157.484 28.7393C157.484 33.983 157.431 38.2365 157.368 38.2365Z"
        fill="#B0BEC5"
      />
      <path
        d="M162.017 25.84C162.017 25.9066 159.797 25.9555 157.093 25.9555C154.389 25.9555 152.165 25.9066 152.165 25.84C152.165 25.7734 154.385 25.7246 157.093 25.7246C159.801 25.7246 162.017 25.7779 162.017 25.84Z"
        fill="#B0BEC5"
      />
      <path
        d="M162.07 32.633C162.07 32.6952 159.85 32.7485 157.102 32.7485C154.354 32.7485 152.134 32.6952 152.134 32.633C152.134 32.5709 154.354 32.5176 157.102 32.5176C159.85 32.5176 162.07 32.5709 162.07 32.633Z"
        fill="#B0BEC5"
      />
      <path
        d="M192.333 9.63477H168.708V146.755H192.333V9.63477Z"
        fill="#B0BEC5"
      />
      <path
        opacity="0.1"
        d="M192.333 146.751L168.624 146.68V9.99414H192.249L192.333 146.751Z"
        fill="black"
      />
      <path
        d="M185.309 19.2422H175.453V38.2321H185.309V19.2422Z"
        fill="#E0E0E0"
      />
      <path
        d="M180.661 38.2365C180.594 38.2365 180.545 33.983 180.545 28.7393C180.545 23.4957 180.594 19.2422 180.661 19.2422C180.727 19.2422 180.776 23.4957 180.776 28.7393C180.776 33.983 180.723 38.2365 180.661 38.2365Z"
        fill="#B0BEC5"
      />
      <path
        d="M185.309 25.84C185.309 25.9066 183.089 25.9555 180.381 25.9555C177.673 25.9555 175.453 25.9066 175.453 25.84C175.453 25.7734 177.673 25.7246 180.381 25.7246C183.089 25.7246 185.309 25.7779 185.309 25.84Z"
        fill="#B0BEC5"
      />
      <path
        d="M185.363 32.633C185.363 32.6952 183.143 32.7485 180.394 32.7485C177.646 32.7485 175.426 32.6952 175.426 32.633C175.426 32.5709 177.646 32.5176 180.394 32.5176C183.143 32.5176 185.363 32.5709 185.363 32.633Z"
        fill="#B0BEC5"
      />
      <path
        d="M185.309 42.6113H175.453V61.6012H185.309V42.6113Z"
        fill="#E0E0E0"
      />
      <path
        d="M180.661 61.6012C180.594 61.6012 180.545 57.3521 180.545 52.1085C180.545 46.8648 180.594 42.6113 180.661 42.6113C180.727 42.6113 180.776 46.8604 180.776 52.1085C180.776 57.3566 180.723 61.6012 180.661 61.6012Z"
        fill="#B0BEC5"
      />
      <path
        d="M185.309 49.2092C185.309 49.2714 183.089 49.3246 180.381 49.3246C177.673 49.3246 175.453 49.2714 175.453 49.2092C175.453 49.147 177.673 49.0938 180.381 49.0938C183.089 49.0938 185.309 49.1426 185.309 49.2092Z"
        fill="#B0BEC5"
      />
      <path
        d="M185.363 56.0022C185.363 56.0643 183.143 56.1176 180.394 56.1176C177.646 56.1176 175.426 56.0643 175.426 56.0022C175.426 55.94 177.646 55.8867 180.394 55.8867C183.143 55.8867 185.363 55.9356 185.363 56.0022Z"
        fill="#B0BEC5"
      />
      <path
        opacity="0.3"
        d="M168.531 9.63477H146.122V146.755H168.531V9.63477Z"
        fill="black"
      />
      <path
        d="M133.597 46.3184C133.741 46.6316 133.755 46.9891 133.637 47.3129C133.514 47.6341 133.481 47.9822 133.539 48.3208C133.597 48.5339 133.739 48.716 133.796 48.9291C133.854 49.135 133.835 49.3547 133.744 49.5481C133.653 49.7414 133.495 49.8952 133.299 49.9814L131.523 49.1777C131.976 48.3846 132.266 47.5091 132.376 46.6025"
        fill="#B0BEC5"
      />
      <g opacity="0.5">
        <path
          d="M133.597 46.3184C133.741 46.6316 133.755 46.9891 133.637 47.3129C133.514 47.6341 133.481 47.9822 133.539 48.3208C133.597 48.5339 133.739 48.716 133.796 48.9291C133.854 49.135 133.835 49.3547 133.744 49.5481C133.653 49.7414 133.495 49.8952 133.299 49.9814L131.523 49.1777C131.976 48.3846 132.266 47.5091 132.376 46.6025"
          fill="black"
        />
      </g>
      <path
        d="M128.948 45.9052C129.476 46.0295 128.597 46.9175 128.637 47.4592C128.737 47.9948 128.703 48.5468 128.539 49.0665C127.471 49.0806 126.415 48.8402 125.458 48.3649C125.707 47.3127 125.951 46.2559 126.2 45.1992C126.204 45.1992 128.477 45.7942 128.948 45.9052Z"
        fill="#B0BEC5"
      />
      <path
        d="M157.368 37.123H141.105V60.6284H157.368V37.123Z"
        fill="#263238"
      />
      <path
        d="M171.998 37.123H155.734V60.6284H171.998V37.123Z"
        fill="#455A64"
      />
      <path
        d="M168.113 55.8818C168.108 55.7931 168.108 55.7042 168.113 55.6154C168.113 55.429 168.113 55.1803 168.113 54.8695C168.113 54.2035 168.113 53.2622 168.082 52.0901C168.082 49.7102 168.051 46.3802 168.029 42.4864L168.14 42.5974H160.761C160.729 42.6196 160.978 42.3798 160.872 42.4819V46.2293C160.872 47.4503 160.872 48.6269 160.872 49.7813C160.872 52.0412 160.872 54.1058 160.849 55.8907L160.761 55.8019L166.129 55.8508L167.611 55.873H168.002H168.144H168.02H167.647L166.164 55.9085L160.743 55.9573H160.658V55.8685C160.658 54.0925 160.658 52.019 160.658 49.7591C160.658 48.6269 160.658 47.4503 160.658 46.2071C160.658 45.5988 160.658 44.9816 160.658 44.3556V42.4597L160.778 42.3398H168.158H168.264V42.4464C168.242 46.3714 168.224 49.7236 168.211 52.1212C168.211 53.2844 168.189 54.2168 168.184 54.874C168.184 55.1803 168.184 55.4201 168.184 55.6021C168.17 55.6974 168.146 55.7911 168.113 55.8818Z"
        fill="#263238"
      />
      <path
        d="M166.635 49.9058C166.635 49.9369 166.546 49.9946 166.524 50.1411C166.512 50.1795 166.512 50.2203 166.523 50.2588C166.534 50.2973 166.555 50.332 166.585 50.3589C166.615 50.3857 166.651 50.4037 166.691 50.4108C166.73 50.4178 166.771 50.4136 166.808 50.3987C166.847 50.3881 166.882 50.3664 166.909 50.3363C166.936 50.3061 166.953 50.2687 166.96 50.2288C166.966 50.1888 166.96 50.1479 166.943 50.111C166.927 50.0742 166.9 50.043 166.866 50.0213C166.759 49.9191 166.644 49.9413 166.635 49.9058C166.626 49.8703 166.75 49.777 166.959 49.9058C167.018 49.9401 167.068 49.9876 167.106 50.0446C167.144 50.1016 167.168 50.1666 167.176 50.2344C167.174 50.3233 167.144 50.4094 167.092 50.4815C167.04 50.5535 166.967 50.6082 166.883 50.6384C166.81 50.6692 166.73 50.6774 166.652 50.6618C166.575 50.6463 166.503 50.6078 166.448 50.5514C166.392 50.4949 166.355 50.4231 166.341 50.3452C166.327 50.2673 166.336 50.1869 166.368 50.1145C166.461 49.8792 166.639 49.8747 166.635 49.9058Z"
        fill="#263238"
      />
      <path
        d="M188.741 56.4777C188.741 56.4777 188.741 56.3712 188.741 56.1714C188.741 55.9716 188.741 55.6563 188.741 55.2612C188.741 54.4487 188.741 53.2676 188.715 51.7092L188.768 51.7625C181.873 51.798 167.456 51.8246 150.779 51.8246C133.654 51.8246 118.882 51.798 112.151 51.758L112.2 51.7092C112.2 53.0412 112.2 54.0757 112.178 54.7861C112.178 55.128 112.178 55.39 112.178 55.5764C112.178 55.7629 112.178 55.8428 112.178 55.8428C112.178 55.8428 112.178 55.754 112.178 55.5764C112.178 55.3988 112.178 55.1324 112.178 54.7861C112.178 54.0757 112.178 53.0456 112.178 51.7092V51.6603H112.227C118.94 51.6204 133.712 51.5938 150.855 51.5938C167.514 51.5938 181.935 51.6204 188.844 51.6559H188.808V51.7092C188.808 53.2499 188.786 54.4309 188.781 55.2612C188.781 55.6563 188.781 55.9538 188.781 56.1714C188.781 56.3889 188.741 56.4777 188.741 56.4777Z"
        fill="#263238"
      />
      <path
        d="M114.598 56.3519C114.484 55.6044 114.445 54.8473 114.482 54.092C114.445 53.3367 114.484 52.5796 114.598 51.832C114.714 52.5793 114.753 53.3367 114.713 54.092C114.753 54.8473 114.714 55.6047 114.598 56.3519Z"
        fill="#263238"
      />
      <path
        d="M117.533 56.3519C117.419 55.6044 117.38 54.8473 117.417 54.092C117.38 53.3367 117.419 52.5796 117.533 51.832C117.647 52.5796 117.685 53.3367 117.648 54.092C117.685 54.8473 117.647 55.6044 117.533 56.3519Z"
        fill="#263238"
      />
      <path
        d="M123.398 56.353C123.381 56.2577 123.374 56.161 123.376 56.0644C123.376 55.8557 123.376 55.5804 123.376 55.243C123.376 54.5015 123.376 53.467 123.34 52.1971C123.34 49.5953 123.314 46.0211 123.3 41.9851L123.416 42.0961H121.959H120.485L120.596 41.9807C120.596 45.9767 120.57 49.5642 120.561 52.1616C120.561 53.4314 120.534 54.4615 120.525 55.1986C120.525 55.5404 120.525 55.8113 120.503 56.02C120.51 56.116 120.51 56.2125 120.503 56.3086C120.484 56.2137 120.476 56.1167 120.481 56.02C120.481 55.8113 120.481 55.5404 120.481 55.1986C120.481 54.4615 120.459 53.4226 120.445 52.1616C120.445 49.5642 120.445 45.99 120.41 41.9807V41.8652H120.525H122.004H123.46H123.575V41.9851C123.575 45.9811 123.549 49.5731 123.54 52.1971C123.54 53.4714 123.513 54.5015 123.504 55.243C123.504 55.5804 123.504 55.8557 123.504 56.0644C123.479 56.1639 123.443 56.2606 123.398 56.353Z"
        fill="#263238"
      />
      <path
        d="M126.333 56.3519C126.216 55.6047 126.177 54.8473 126.217 54.092C126.177 53.3367 126.216 52.5793 126.333 51.832C126.447 52.5796 126.485 53.3367 126.448 54.092C126.485 54.8473 126.447 55.6044 126.333 56.3519Z"
        fill="#263238"
      />
      <path
        d="M129.263 56.3519C129.149 55.6044 129.111 54.8473 129.148 54.092C129.111 53.3367 129.149 52.5796 129.263 51.832C129.38 52.5793 129.418 53.3367 129.379 54.092C129.418 54.8473 129.38 55.6047 129.263 56.3519Z"
        fill="#263238"
      />
      <path
        d="M132.198 56.3519C132.084 55.6044 132.045 54.8473 132.083 54.092C132.045 53.3367 132.084 52.5796 132.198 51.832C132.312 52.5796 132.351 53.3367 132.313 54.092C132.351 54.8473 132.312 55.6044 132.198 56.3519Z"
        fill="#263238"
      />
      <path
        d="M135.133 56.3519C135.016 55.6047 134.978 54.8473 135.017 54.092C134.978 53.3367 135.016 52.5793 135.133 51.832C135.247 52.5796 135.285 53.3367 135.248 54.092C135.285 54.8473 135.247 55.6044 135.133 56.3519Z"
        fill="#263238"
      />
      <path
        d="M138.063 56.3519C137.949 55.6044 137.911 54.8473 137.948 54.092C137.911 53.3367 137.949 52.5796 138.063 51.832C138.18 52.5793 138.219 53.3367 138.179 54.092C138.219 54.8473 138.18 55.6047 138.063 56.3519Z"
        fill="#263238"
      />
      <path
        d="M140.998 56.3519C140.884 55.6044 140.845 54.8473 140.883 54.092C140.845 53.3367 140.884 52.5796 140.998 51.832C141.112 52.5796 141.151 53.3367 141.113 54.092C141.151 54.8473 141.112 55.6044 140.998 56.3519Z"
        fill="#263238"
      />
      <path
        d="M143.933 56.3519C143.813 55.605 143.773 54.8474 143.813 54.092C143.773 53.3365 143.813 52.579 143.933 51.832C144.047 52.5796 144.086 53.3367 144.048 54.092C144.086 54.8473 144.047 55.6044 143.933 56.3519Z"
        fill="#263238"
      />
      <path
        d="M146.863 56.3519C146.749 55.6044 146.711 54.8473 146.748 54.092C146.711 53.3367 146.749 52.5796 146.863 51.832C146.98 52.5793 147.019 53.3367 146.979 54.092C147.019 54.8473 146.98 55.6047 146.863 56.3519Z"
        fill="#263238"
      />
      <path
        d="M149.798 56.3519C149.681 55.6047 149.643 54.8473 149.683 54.092C149.643 53.3367 149.681 52.5793 149.798 51.832C149.912 52.5796 149.951 53.3367 149.914 54.092C149.951 54.8473 149.912 55.6044 149.798 56.3519Z"
        fill="#263238"
      />
      <path
        d="M152.728 56.3519C152.615 55.6044 152.576 54.8473 152.613 54.092C152.576 53.3367 152.615 52.5796 152.728 51.832C152.845 52.5793 152.884 53.3367 152.844 54.092C152.884 54.8473 152.845 55.6047 152.728 56.3519Z"
        fill="#263238"
      />
      <path
        d="M155.663 56.3519C155.549 55.6044 155.511 54.8473 155.548 54.092C155.511 53.3367 155.549 52.5796 155.663 51.832C155.777 52.5796 155.816 53.3367 155.779 54.092C155.816 54.8473 155.777 55.6044 155.663 56.3519Z"
        fill="#263238"
      />
      <path
        d="M158.616 56.3519C158.499 55.6047 158.461 54.8473 158.501 54.092C158.461 53.3367 158.499 52.5793 158.616 51.832C158.73 52.5796 158.769 53.3367 158.731 54.092C158.769 54.8473 158.73 55.6044 158.616 56.3519Z"
        fill="#263238"
      />
      <path
        d="M161.529 56.3519C161.415 55.6044 161.376 54.8473 161.413 54.092C161.376 53.3367 161.415 52.5796 161.529 51.832C161.645 52.5793 161.684 53.3367 161.644 54.092C161.684 54.8473 161.645 55.6047 161.529 56.3519Z"
        fill="#263238"
      />
      <path
        d="M164.464 56.3519C164.347 55.6047 164.308 54.8473 164.348 54.092C164.308 53.3367 164.347 52.5793 164.464 51.832C164.577 52.5796 164.616 53.3367 164.579 54.092C164.616 54.8473 164.577 55.6044 164.464 56.3519Z"
        fill="#263238"
      />
      <path
        d="M167.398 56.3519C167.279 55.605 167.239 54.8474 167.278 54.092C167.239 53.3365 167.279 52.579 167.398 51.832C167.512 52.5796 167.551 53.3367 167.514 54.092C167.551 54.8473 167.512 55.6044 167.398 56.3519Z"
        fill="#263238"
      />
      <path
        d="M170.329 56.3519C170.215 55.6044 170.176 54.8473 170.213 54.092C170.176 53.3367 170.215 52.5796 170.329 51.832C170.445 52.5793 170.484 53.3367 170.444 54.092C170.484 54.8473 170.445 55.6047 170.329 56.3519Z"
        fill="#263238"
      />
      <path
        d="M173.268 56.3519C173.151 55.6047 173.113 54.8473 173.153 54.092C173.113 53.3367 173.151 52.5793 173.268 51.832C173.382 52.5796 173.421 53.3367 173.383 54.092C173.421 54.8473 173.382 55.6044 173.268 56.3519Z"
        fill="#263238"
      />
      <path
        d="M176.194 56.3519C176.08 55.6044 176.041 54.8473 176.079 54.092C176.041 53.3367 176.08 52.5796 176.194 51.832C176.311 52.5793 176.349 53.3367 176.309 54.092C176.349 54.8473 176.311 55.6047 176.194 56.3519Z"
        fill="#263238"
      />
      <path
        d="M179.129 56.3519C179.015 55.6044 178.976 54.8473 179.013 54.092C178.976 53.3367 179.015 52.5796 179.129 51.832C179.243 52.5796 179.281 53.3367 179.244 54.092C179.281 54.8473 179.243 55.6044 179.129 56.3519Z"
        fill="#263238"
      />
      <path
        d="M182.064 56.3519C181.947 55.6047 181.908 54.8473 181.948 54.092C181.908 53.3367 181.947 52.5793 182.064 51.832C182.178 52.5796 182.216 53.3367 182.179 54.092C182.216 54.8473 182.178 55.6044 182.064 56.3519Z"
        fill="#263238"
      />
      <path
        d="M184.994 56.3519C184.88 55.6044 184.841 54.8473 184.879 54.092C184.841 53.3367 184.88 52.5796 184.994 51.832C185.111 52.5793 185.149 53.3367 185.109 54.092C185.149 54.8473 185.111 55.6047 184.994 56.3519Z"
        fill="#263238"
      />
      <path
        d="M187.92 56.3519C187.803 55.6047 187.765 54.8473 187.804 54.092C187.765 53.3367 187.803 52.5793 187.92 51.832C188.034 52.5796 188.073 53.3367 188.035 54.092C188.073 54.8473 188.034 55.6044 187.92 56.3519Z"
        fill="#263238"
      />
      <path
        d="M188.741 55.883C188.741 55.9452 179.688 55.9985 168.526 55.9985C157.364 55.9985 148.311 55.9452 148.311 55.883C148.311 55.8209 157.36 55.7676 168.526 55.7676C179.693 55.7676 188.741 55.8209 188.741 55.883Z"
        fill="#263238"
      />
      <path
        d="M188.741 55.8828H121.307V146.774H188.741V55.8828Z"
        fill="#455A64"
      />
      <path
        d="M148.311 55.8828H112.143V146.774H148.311V55.8828Z"
        fill="#263238"
      />
      <path
        d="M183.795 105.197H171.461V117.532H183.795V105.197Z"
        fill="#EBEBEB"
      />
      <path
        d="M178.099 118.771C178.037 118.771 177.983 115.583 177.983 111.667C177.983 107.75 178.037 104.562 178.099 104.562C178.161 104.562 178.214 107.75 178.214 111.667C178.214 115.583 178.152 118.771 178.099 118.771Z"
        fill="#455A64"
      />
      <path
        d="M186.109 109.33C186.109 109.397 182.525 109.446 178.117 109.446C173.708 109.446 170.125 109.397 170.125 109.33C170.125 109.264 173.708 109.215 178.117 109.215C182.525 109.215 186.109 109.268 186.109 109.33Z"
        fill="#455A64"
      />
      <path
        d="M166.204 105.197H153.87V117.532H166.204V105.197Z"
        fill="#EBEBEB"
      />
      <path
        d="M160.507 118.771C160.445 118.771 160.392 115.583 160.392 111.667C160.392 107.75 160.445 104.562 160.507 104.562C160.57 104.562 160.623 107.75 160.623 111.667C160.623 115.583 160.574 118.771 160.507 118.771Z"
        fill="#455A64"
      />
      <path
        d="M168.522 109.33C168.522 109.397 164.934 109.446 160.508 109.446C156.081 109.446 152.516 109.397 152.516 109.33C152.516 109.264 156.103 109.215 160.508 109.215C164.912 109.215 168.522 109.268 168.522 109.33Z"
        fill="#455A64"
      />
      <path
        d="M183.795 85.7109H171.461V98.0453H183.795V85.7109Z"
        fill="#EBEBEB"
      />
      <path
        d="M178.099 99.2822C178.037 99.2822 177.983 96.0943 177.983 92.1782C177.983 88.2621 178.037 85.0742 178.099 85.0742C178.161 85.0742 178.214 88.2577 178.214 92.1782C178.214 96.0987 178.152 99.2822 178.099 99.2822Z"
        fill="#455A64"
      />
      <path
        d="M186.109 89.8479C186.109 89.91 182.525 89.9633 178.117 89.9633C173.708 89.9633 170.125 89.91 170.125 89.8479C170.125 89.7857 173.708 89.7324 178.117 89.7324C182.525 89.7324 186.109 89.7813 186.109 89.8479Z"
        fill="#455A64"
      />
      <path
        d="M166.204 85.7109H153.87V98.0453H166.204V85.7109Z"
        fill="#EBEBEB"
      />
      <path
        d="M160.507 99.2822C160.445 99.2822 160.392 96.0943 160.392 92.1782C160.392 88.2621 160.445 85.0742 160.507 85.0742C160.57 85.0742 160.623 88.2577 160.623 92.1782C160.623 96.0987 160.574 99.2822 160.507 99.2822Z"
        fill="#455A64"
      />
      <path
        d="M168.522 89.8479C168.522 89.91 164.934 89.9633 160.508 89.9633C156.081 89.9633 152.516 89.91 152.516 89.8479C152.516 89.7857 156.103 89.7324 160.508 89.7324C164.912 89.7324 168.522 89.7813 168.522 89.8479Z"
        fill="#455A64"
      />
      <path
        d="M183.795 66.1777H171.461V78.5121H183.795V66.1777Z"
        fill="#EBEBEB"
      />
      <path
        d="M178.099 79.751C178.037 79.751 177.983 76.563 177.983 72.647C177.983 68.7309 178.037 65.543 178.099 65.543C178.161 65.543 178.214 68.7309 178.214 72.647C178.214 76.563 178.152 79.751 178.099 79.751Z"
        fill="#455A64"
      />
      <path
        d="M186.109 70.3166C186.109 70.3788 182.525 70.4321 178.117 70.4321C173.708 70.4321 170.125 70.3788 170.125 70.3166C170.125 70.2544 173.708 70.2012 178.117 70.2012C182.525 70.2012 186.109 70.2544 186.109 70.3166Z"
        fill="#455A64"
      />
      <path
        d="M166.204 66.1777H153.87V78.5121H166.204V66.1777Z"
        fill="#EBEBEB"
      />
      <path
        d="M160.507 79.751C160.445 79.751 160.392 76.563 160.392 72.647C160.392 68.7309 160.445 65.543 160.507 65.543C160.57 65.543 160.623 68.7309 160.623 72.647C160.623 76.563 160.574 79.751 160.507 79.751Z"
        fill="#455A64"
      />
      <path
        d="M168.522 70.3166C168.522 70.3788 164.934 70.4321 160.508 70.4321C156.081 70.4321 152.516 70.3788 152.516 70.3166C152.516 70.2544 156.103 70.2012 160.508 70.2012C164.912 70.2012 168.522 70.2544 168.522 70.3166Z"
        fill="#455A64"
      />
      <g opacity="0.7">
        <path
          d="M135.923 66.1777H123.589V78.5121H135.923V66.1777Z"
          fill="white"
        />
      </g>
      <path
        d="M129.756 79.751C129.689 79.751 129.641 76.563 129.641 72.647C129.641 68.7309 129.689 65.543 129.756 65.543C129.823 65.543 129.871 68.7309 129.871 72.647C129.871 76.563 129.818 79.751 129.756 79.751Z"
        fill="#263238"
      />
      <path
        d="M138.241 70.3166C138.241 70.3788 134.653 70.4321 130.227 70.4321C125.8 70.4321 122.235 70.3788 122.235 70.3166C122.235 70.2544 125.822 70.2012 130.227 70.2012C134.631 70.2012 138.241 70.2544 138.241 70.3166Z"
        fill="#263238"
      />
      <g opacity="0.7">
        <path
          d="M135.923 85.9062H123.589V98.2406H135.923V85.9062Z"
          fill="white"
        />
      </g>
      <path
        d="M129.756 99.4736C129.689 99.4736 129.641 96.2857 129.641 92.3696C129.641 88.4535 129.689 85.2656 129.756 85.2656C129.823 85.2656 129.871 88.4535 129.871 92.3696C129.871 96.2857 129.818 99.4736 129.756 99.4736Z"
        fill="#263238"
      />
      <path
        d="M138.241 90.0393C138.241 90.1014 134.653 90.1547 130.227 90.1547C125.8 90.1547 122.235 90.1014 122.235 90.0393C122.235 89.9771 125.822 89.9238 130.227 89.9238C134.631 89.9238 138.241 89.9771 138.241 90.0393Z"
        fill="#263238"
      />
      <g opacity="0.7">
        <path
          d="M135.923 105.627H123.589V117.961H135.923V105.627Z"
          fill="white"
        />
      </g>
      <path
        d="M129.756 119.196C129.689 119.196 129.641 116.013 129.641 112.092C129.641 108.172 129.689 104.988 129.756 104.988C129.823 104.988 129.871 108.176 129.871 112.092C129.871 116.008 129.818 119.196 129.756 119.196Z"
        fill="#263238"
      />
      <path
        d="M138.241 109.762C138.241 109.824 134.653 109.877 130.227 109.877C125.8 109.877 122.235 109.824 122.235 109.762C122.235 109.7 125.822 109.646 130.227 109.646C134.631 109.646 138.241 109.7 138.241 109.762Z"
        fill="#263238"
      />
      <path
        d="M174.6 146.751C174.593 146.715 174.593 146.677 174.6 146.64V146.316C174.6 146.014 174.6 145.592 174.6 145.055C174.6 143.945 174.6 142.351 174.569 140.362C174.569 136.366 174.542 130.749 174.529 124.338L174.64 124.449H161.959L162.07 124.338C162.07 130.803 162.044 136.455 162.03 140.504C162.03 142.511 162.008 144.123 161.999 145.246C161.999 145.792 161.999 146.214 161.999 146.52C161.999 146.654 161.999 146.765 161.999 146.849C162.006 146.886 162.006 146.923 161.999 146.96C161.992 146.923 161.992 146.886 161.999 146.849C161.999 146.765 161.999 146.654 161.999 146.52C161.999 146.214 161.999 145.792 161.999 145.246C161.999 144.123 161.999 142.511 161.968 140.504C161.968 136.455 161.942 130.803 161.928 124.338V124.223H174.804V124.338C174.804 130.749 174.778 136.348 174.764 140.362C174.764 142.351 174.742 143.945 174.733 145.055C174.733 145.592 174.733 146.014 174.733 146.316V146.64C174.701 146.69 174.654 146.728 174.6 146.751Z"
        fill="#263238"
      />
      <path
        d="M167.856 125.666H163.549V135.98H167.856V125.666Z"
        fill="#E0E0E0"
      />
      <path
        d="M167.869 135.993H163.54V125.656H167.869V135.993ZM163.562 135.966H167.847V125.678H163.562V135.966Z"
        fill="#263238"
      />
      <path
        d="M173.006 125.666H168.699V135.98H173.006V125.666Z"
        fill="#E0E0E0"
      />
      <path
        d="M173.019 135.993H168.69V125.656H173.019V135.993ZM168.713 135.966H172.997V125.678H168.713V135.966Z"
        fill="#263238"
      />
      <path
        d="M173.099 137.046C173.099 137.046 173.144 137.01 173.237 137.019C173.304 137.03 173.368 137.057 173.424 137.097C173.479 137.137 173.525 137.189 173.557 137.25C173.609 137.337 173.64 137.436 173.647 137.538C173.654 137.64 173.637 137.742 173.597 137.836C173.54 137.955 173.446 138.052 173.329 138.113C173.212 138.174 173.078 138.195 172.948 138.173C172.818 138.142 172.702 138.07 172.616 137.967C172.53 137.865 172.478 137.738 172.469 137.605C172.459 137.501 172.478 137.397 172.525 137.304C172.571 137.21 172.642 137.132 172.731 137.077C173.033 136.89 173.255 137.023 173.233 137.041C173.21 137.059 173.028 137.041 172.828 137.205C172.774 137.25 172.732 137.307 172.706 137.373C172.681 137.438 172.672 137.509 172.682 137.578C172.696 137.66 172.732 137.736 172.787 137.798C172.842 137.86 172.913 137.906 172.993 137.929C173.075 137.94 173.158 137.925 173.231 137.888C173.305 137.851 173.366 137.793 173.406 137.72C173.435 137.656 173.451 137.585 173.451 137.514C173.451 137.443 173.435 137.372 173.406 137.308C173.308 137.081 173.09 137.081 173.099 137.046Z"
        fill="#263238"
      />
      <path
        d="M186.357 124.333C186.357 124.333 186.357 124.28 186.357 124.178C186.357 124.076 186.357 123.912 186.357 123.734C186.357 123.317 186.357 122.775 186.357 122.109L186.388 122.14H179.191C179.213 122.118 179.142 122.184 179.235 122.091V124.311L179.191 124.267L184.324 124.289H185.833H186.388H186.255H185.855H184.355H179.191H179.147V122.033C179.24 121.94 179.173 122.007 179.195 121.989H186.393H186.424V122.016C186.424 122.686 186.424 123.237 186.424 123.659C186.424 123.823 186.424 123.965 186.424 124.103C186.424 124.24 186.357 124.333 186.357 124.333Z"
        fill="#263238"
      />
      <path
        d="M158.359 119.747C158.359 119.747 158.359 119.693 158.359 119.591C158.359 119.489 158.359 119.325 158.359 119.147C158.359 118.734 158.359 118.193 158.359 117.527L158.385 117.558H151.188C151.21 117.558 151.144 117.607 151.237 117.513V118.637C151.237 119.005 151.237 119.369 151.237 119.72L151.192 119.676L156.329 119.698H157.839H158.39C158.345 119.703 158.301 119.703 158.256 119.698H157.857H156.361H151.192H151.148V117.447L151.192 117.402H158.39H158.421V117.433C158.421 118.104 158.421 118.654 158.421 119.072C158.421 119.241 158.421 119.383 158.421 119.516C158.421 119.649 158.359 119.747 158.359 119.747Z"
        fill="#263238"
      />
      <path
        d="M153.87 101.711C153.87 101.711 153.87 101.653 153.87 101.556C153.87 101.458 153.87 101.285 153.87 101.112C153.87 100.699 153.87 100.153 153.87 99.491L153.901 99.5176H146.704L146.748 99.4732V101.693L146.704 101.649H151.836H153.346H153.754H153.359H151.858L146.695 101.671H146.628V101.627C146.628 101.276 146.628 100.912 146.628 100.543V99.4199L146.677 99.3711H153.874H153.901V99.4022C153.901 100.073 153.901 100.623 153.901 101.041C153.901 101.209 153.901 101.351 153.901 101.485C153.901 101.618 153.87 101.711 153.87 101.711Z"
        fill="#263238"
      />
      <path
        d="M179.742 82.2991C179.742 82.2991 179.742 82.2414 179.742 82.1437C179.742 82.0461 179.742 81.8729 179.742 81.6997C179.742 81.2868 179.742 80.7407 179.742 80.0791L179.773 80.1058H172.575L172.624 80.0614V80.6208C172.624 80.8117 172.624 80.9982 172.624 81.1847V82.2681L172.58 82.2237H177.712H179.222H179.631H179.244H177.744L172.58 82.2459H172.535V79.9948C172.629 79.9015 172.562 79.9682 172.58 79.946H179.777H179.808V79.977C179.808 80.6475 179.808 81.198 179.808 81.6154C179.808 81.7841 179.808 81.9262 179.808 82.0594C179.808 82.1926 179.742 82.2991 179.742 82.2991Z"
        fill="#263238"
      />
      <path
        d="M159.26 61.5836C159.26 61.5836 159.26 61.5259 159.26 61.4238V60.9798C159.26 60.5624 159.26 60.0208 159.26 59.3548L159.286 59.3858H152.094C152.094 59.3858 152.045 59.4302 152.138 59.337V61.557L152.094 61.5082L157.226 61.5304H158.736H159.144H159.286H158.758H157.257H152.094H152.045V59.3725L152.094 59.3281H159.286H159.318V59.3548C159.318 60.0296 159.318 60.5758 159.318 60.9976C159.318 61.1618 159.318 61.3039 159.318 61.4416C159.318 61.5792 159.26 61.5836 159.26 61.5836Z"
        fill="#263238"
      />
      <path
        d="M184.102 84.4444C184.102 84.4444 184.102 84.3912 184.102 84.289C184.102 84.1869 184.102 84.0226 184.102 83.845C184.102 83.4277 184.102 82.886 184.102 82.22L184.128 82.2511H176.931C176.953 82.2511 176.887 82.2999 176.98 82.2067V83.33C176.98 83.6985 176.98 84.0626 176.98 84.4134L176.935 84.369L182.072 84.3912H183.582H184.133C184.088 84.3954 184.044 84.3954 183.999 84.3912H183.6H182.104H176.935H176.891V82.2377L176.935 82.1934H184.133H184.164V82.2244C184.164 82.8949 184.164 83.4454 184.164 83.8672C184.164 84.0315 184.164 84.1736 184.164 84.3112C184.164 84.4489 184.102 84.4444 184.102 84.4444Z"
        fill="#263238"
      />
      <path
        d="M150.779 103.884C150.779 103.884 150.779 103.831 150.779 103.728C150.779 103.626 150.779 103.462 150.779 103.284C150.779 102.872 150.779 102.325 150.779 101.664L150.806 101.69H143.609C143.631 101.69 143.564 101.739 143.658 101.646V102.769C143.658 103.138 143.658 103.502 143.658 103.853L143.613 103.808H148.75H150.26H150.668H150.269H148.773H143.604H143.52V101.557C143.613 101.464 143.547 101.531 143.564 101.508H150.762H150.793V101.54C150.793 102.21 150.793 102.761 150.793 103.178C150.793 103.347 150.793 103.489 150.793 103.622C150.793 103.755 150.779 103.884 150.779 103.884Z"
        fill="#263238"
      />
      <path
        d="M153.87 63.7994C153.87 63.7994 153.87 63.7417 153.87 63.644C153.87 63.5463 153.87 63.3732 153.87 63.2C153.87 62.7826 153.87 62.241 153.87 61.5794L153.901 61.606H146.704C146.704 61.606 146.655 61.6504 146.748 61.5616V63.7816L146.704 63.7372H151.836H153.346H153.732H153.879H153.346H151.845L146.681 63.7594H146.628V63.715C146.628 63.3598 146.628 63.0002 146.628 62.6272V61.5039L146.677 61.4551H153.874H153.901V61.4862C153.901 62.1551 153.901 62.7012 153.901 63.1245C153.901 63.2932 153.901 63.4353 153.901 63.5685C153.901 63.7017 153.87 63.7994 153.87 63.7994Z"
        fill="#263238"
      />
      <path
        d="M188.693 126.554C188.693 126.554 188.693 126.501 188.693 126.399C188.693 126.297 188.693 126.133 188.693 125.955C188.693 125.542 188.693 125 188.693 124.334L188.724 124.366H181.526L181.571 124.321V126.541L181.526 126.497L186.659 126.519H188.169H188.577H188.182H186.681H181.518H181.473V126.475C181.473 126.124 181.473 125.76 181.473 125.391V124.268C181.566 124.175 181.5 124.241 181.522 124.223H188.719H188.746V124.255C188.746 124.925 188.746 125.476 188.746 125.893C188.746 126.062 188.746 126.204 188.746 126.337C188.746 126.47 188.693 126.554 188.693 126.554Z"
        fill="#263238"
      />
      <path
        d="M146.757 42.588C146.688 42.6542 146.612 42.7123 146.53 42.7611C146.375 42.8677 146.153 43.032 145.851 43.2051C144.997 43.7345 144.107 44.2033 143.187 44.6082C140.158 45.9617 136.871 46.6376 133.554 46.5885C130.237 46.5394 126.971 45.7666 123.984 44.324C123.081 43.8933 122.21 43.3993 121.378 42.8455C121.08 42.6546 120.867 42.4859 120.716 42.3749C120.635 42.3248 120.561 42.2651 120.494 42.1973C120.579 42.2376 120.661 42.2851 120.738 42.3393C120.898 42.4326 121.125 42.5924 121.427 42.7833C122.273 43.3054 123.151 43.7754 124.055 44.1908C127.033 45.5798 130.272 46.3227 133.558 46.371C136.844 46.4192 140.103 45.7715 143.12 44.4706C144.034 44.0804 144.924 43.6356 145.784 43.1386C146.095 42.9698 146.322 42.8189 146.486 42.7301C146.571 42.6728 146.661 42.6251 146.757 42.588Z"
        fill="#263238"
      />
      <path
        d="M145.833 41.8828C145.774 41.9361 145.708 41.9823 145.638 42.0205L145.043 42.3801L144.599 42.6332C144.439 42.722 144.257 42.8063 144.066 42.904C143.68 43.0861 143.254 43.3214 142.734 43.5079L141.948 43.8231C141.673 43.9297 141.376 44.0096 141.06 44.1117C140.418 44.3207 139.766 44.497 139.107 44.64C136.072 45.3054 132.932 45.3266 129.889 44.7022C129.192 44.5424 128.531 44.4047 127.927 44.2005C127.625 44.1073 127.327 44.0273 127.039 43.9252L126.248 43.6189C125.795 43.4468 125.35 43.2512 124.916 43.0328C124.721 42.9395 124.539 42.8596 124.379 42.7753L123.935 42.5222L123.336 42.1714C123.262 42.1362 123.195 42.0898 123.136 42.0338C123.215 42.0575 123.291 42.0902 123.362 42.1315L123.975 42.4423L124.419 42.6731C124.583 42.7531 124.765 42.8285 124.961 42.9173C125.352 43.0816 125.787 43.2992 126.293 43.4679L127.083 43.7565C127.358 43.8542 127.656 43.9252 127.971 44.0185C128.571 44.2138 129.232 44.3426 129.92 44.4935C131.428 44.7805 132.959 44.9291 134.494 44.9375C136.025 44.9141 137.552 44.7491 139.053 44.4447C139.742 44.2804 140.385 44.1428 140.998 43.943C141.296 43.8453 141.589 43.7698 141.864 43.6677L142.65 43.3702C143.156 43.1926 143.587 42.9706 143.982 42.7975C144.173 42.7087 144.355 42.6332 144.519 42.5488L144.963 42.3135L145.571 41.9938C145.652 41.9423 145.74 41.9048 145.833 41.8828Z"
        fill="#263238"
      />
      <path
        d="M133.632 46.3619C133.364 46.5666 133.147 46.8308 132.998 47.1335C132.85 47.4363 132.773 47.7695 132.775 48.1068C132.796 48.5284 132.731 48.9499 132.584 49.3456C132.461 49.5303 132.292 49.6811 132.096 49.7843C131.899 49.8874 131.679 49.9396 131.457 49.9361C131.012 49.921 130.573 49.8294 130.16 49.6652C130.636 49.1304 130.962 48.4795 131.106 47.7782C131.144 47.4773 131.207 47.1802 131.297 46.8902C131.34 46.7477 131.416 46.6169 131.517 46.5076C131.619 46.3984 131.744 46.3137 131.883 46.2598L133.632 46.3619Z"
        fill="#B0BEC5"
      />
      <path
        d="M136.802 46.1002C136.618 46.1849 136.458 46.3141 136.336 46.4761C136.215 46.6381 136.135 46.8278 136.105 47.0282C136.056 47.4367 136.056 47.8495 136.105 48.258C136.149 48.6679 136.077 49.0818 135.896 49.4524C136.846 49.6107 137.821 49.4148 138.636 48.9018C138.318 47.8718 138.409 46.7589 138.889 45.7938C138.889 45.7938 138.916 45.7317 138.782 45.7228C138.649 45.7139 136.802 46.1002 136.802 46.1002Z"
        fill="#B0BEC5"
      />
      <path
        d="M87.4383 103.465H68.5639V146.773H87.4383V103.465Z"
        fill="#B0BEC5"
      />
      <g opacity="0.3">
        <path
          d="M87.4383 103.465H68.5639V146.773H87.4383V103.465Z"
          fill="black"
        />
      </g>
      <path
        d="M64.1506 103.286H110.713L119.415 90.9343L128.535 103.637L139.253 103.464L127.7 87.9062L78.4607 88.1105L64.1506 103.286Z"
        fill="#B0BEC5"
      />
      <g opacity="0.3">
        <path
          d="M64.1506 103.286H110.713L119.415 90.9343L128.535 103.637L139.253 103.464L127.7 87.9062L78.4607 88.1105L64.1506 103.286Z"
          fill="black"
        />
      </g>
      <path
        d="M87.4339 103.463V146.771H133.934V103.463H128.606L119.415 90.9336L110.686 103.272L87.4339 103.463Z"
        fill="#B0BEC5"
      />
      <path
        d="M104.892 146.773C104.886 146.742 104.886 146.71 104.892 146.679C104.892 146.608 104.892 146.515 104.892 146.408C104.892 146.151 104.892 145.8 104.892 145.352C104.892 144.415 104.892 143.083 104.856 141.422C104.856 138.057 104.834 133.368 104.817 127.996L104.932 128.107H94.0539L94.1649 127.996C94.1649 133.368 94.1383 138.057 94.125 141.422C94.125 143.083 94.125 144.415 94.0939 145.352C94.0939 145.796 94.0939 146.151 94.0939 146.408C94.0939 146.515 94.0939 146.608 94.0939 146.679C94.0939 146.75 94.0939 146.773 94.0939 146.773C94.0901 146.742 94.0901 146.71 94.0939 146.679V146.408C94.0939 146.151 94.0939 145.8 94.0717 145.352C94.0717 144.415 94.0717 143.083 94.0406 141.422C94.0406 138.057 94.014 133.368 94.0007 127.996V127.885H105.101V127.996C105.101 133.368 105.07 138.057 105.061 141.422C105.061 143.083 105.034 144.415 105.025 145.352C105.025 145.796 105.025 146.151 105.025 146.408V146.679C104.892 146.741 104.892 146.773 104.892 146.773Z"
        fill="#263238"
      />
      <path
        d="M128.535 106.227H111.401V116.838H128.535V106.227Z"
        fill="white"
      />
      <path
        d="M103.746 106.318H92.9395V116.548H103.746V106.318Z"
        fill="white"
      />
      <path
        d="M116.347 116.936C116.285 116.936 116.232 114.538 116.232 111.581C116.232 108.624 116.285 106.227 116.347 106.227C116.409 106.227 116.463 108.624 116.463 111.581C116.463 114.538 116.436 116.936 116.347 116.936Z"
        fill="#B0BEC5"
      />
      <path
        d="M123.473 116.936C123.411 116.936 123.358 114.538 123.358 111.581C123.358 108.624 123.411 106.227 123.473 106.227C123.536 106.227 123.589 108.624 123.589 111.581C123.589 114.538 123.54 116.936 123.473 116.936Z"
        fill="#B0BEC5"
      />
      <path
        d="M123.473 111.904C122.293 112.018 121.107 112.056 119.921 112.019C118.736 112.056 117.55 112.018 116.369 111.904C117.55 111.788 118.736 111.749 119.921 111.789C121.107 111.749 122.293 111.787 123.473 111.904Z"
        fill="#B0BEC5"
      />
      <path
        d="M98.343 116.553C98.2808 116.553 98.2275 114.262 98.2275 111.433C98.2275 108.605 98.2808 106.318 98.343 106.318C98.4051 106.318 98.4584 108.609 98.4584 111.433C98.4584 114.257 98.4096 116.553 98.343 116.553Z"
        fill="#B0BEC5"
      />
      <path
        d="M103.746 111.688C103.746 111.75 101.327 111.803 98.343 111.803C95.3593 111.803 92.9395 111.75 92.9395 111.688C92.9395 111.626 95.3593 111.572 98.343 111.572C101.327 111.572 103.746 111.626 103.746 111.688Z"
        fill="#B0BEC5"
      />
      <path
        d="M121.68 99.2017C121.679 99.6547 121.544 100.097 121.291 100.473C121.039 100.85 120.681 101.142 120.262 101.315C119.844 101.488 119.383 101.533 118.939 101.444C118.495 101.355 118.087 101.136 117.767 100.816C117.447 100.495 117.229 100.087 117.141 99.6426C117.053 99.1983 117.099 98.7379 117.272 98.3195C117.446 97.9011 117.74 97.5436 118.116 97.2921C118.493 97.0405 118.936 96.9063 119.389 96.9063C119.69 96.9062 119.988 96.9657 120.266 97.0811C120.545 97.1965 120.797 97.3656 121.01 97.5788C121.223 97.7921 121.392 98.0452 121.507 98.3236C121.621 98.6021 121.68 98.9005 121.68 99.2017Z"
        fill="white"
      />
      <path
        d="M103.045 96.392L105.935 92.8222V84.2441H99.8837V96.4586L103.045 96.392Z"
        fill="#B0BEC5"
      />
      <g opacity="0.3">
        <path
          d="M103.045 96.392V84.2441H105.935V92.8222L103.045 96.392Z"
          fill="black"
        />
      </g>
      <path
        d="M106.348 82.9824H99.2088V84.2434H106.348V82.9824Z"
        fill="#B0BEC5"
      />
      <path
        d="M64.1506 103.287V104.543H110.686V103.273L64.1506 103.287Z"
        fill="#B0BEC5"
      />
      <path
        d="M110.686 104.542L119.389 92.3233L119.415 90.9336L110.713 103.286L110.686 104.542Z"
        fill="#B0BEC5"
      />
      <path
        d="M139.031 103.465H128.606V104.273H139.031V103.465Z"
        fill="#B0BEC5"
      />
      <path
        d="M101.171 125.117H97.3262V126.578H101.171V125.117Z"
        fill="#263238"
      />
      <path
        d="M104.004 137.47C103.968 137.47 103.977 137.226 103.72 137.026C103.647 136.977 103.564 136.947 103.478 136.938C103.391 136.928 103.304 136.94 103.223 136.972C103.126 137.01 103.043 137.076 102.985 137.162C102.927 137.247 102.895 137.348 102.895 137.452C102.895 137.556 102.927 137.657 102.985 137.742C103.043 137.828 103.126 137.894 103.223 137.932C103.304 137.963 103.392 137.974 103.478 137.964C103.565 137.954 103.648 137.923 103.72 137.874C103.977 137.692 103.968 137.43 104.004 137.452C104.04 137.474 104.115 137.718 103.813 137.998C103.722 138.073 103.614 138.124 103.498 138.145C103.382 138.166 103.263 138.157 103.152 138.118C103.014 138.07 102.894 137.979 102.81 137.86C102.725 137.741 102.68 137.598 102.68 137.452C102.68 137.306 102.725 137.163 102.81 137.044C102.894 136.925 103.014 136.834 103.152 136.786C103.263 136.747 103.382 136.738 103.498 136.759C103.614 136.78 103.722 136.831 103.813 136.906C104.115 137.199 104.026 137.488 104.004 137.47Z"
        fill="#263238"
      />
      <path
        d="M95.2838 120.864C95.2805 120.819 95.2805 120.775 95.2838 120.731C95.2838 120.62 95.2838 120.495 95.2838 120.353V118.955L95.3149 118.986H89.0989C89.1211 118.963 89.0545 119.03 89.1433 118.937V120.837L89.0989 120.793H93.5123H94.8132H95.164H94.8354H93.5434H89.1034H89.059V118.955L89.1034 118.91H95.3194H95.346V118.955C95.346 119.527 95.346 119.998 95.346 120.371C95.346 120.509 95.346 120.633 95.346 120.739C95.346 120.846 95.2838 120.864 95.2838 120.864Z"
        fill="#263238"
      />
      <path
        d="M74.7533 114.919C74.7533 114.919 74.7533 114.87 74.7533 114.786C74.7533 114.701 74.7533 114.55 74.7533 114.408C74.7533 114.04 74.7533 113.573 74.7533 113.01L74.7799 113.041H68.5906C68.5906 113.018 68.5417 113.085 68.635 112.992V114.892L68.5906 114.848H73.0039H74.3049H74.6556C74.6969 114.843 74.7386 114.843 74.7799 114.848H74.3359H73.0439L68.6039 114.87H68.5595V114.826C68.5595 114.524 68.5595 114.208 68.5595 113.893V112.925L68.6083 112.881H74.7977H74.8288V112.907C74.8288 113.48 74.8288 113.951 74.8288 114.324C74.8288 114.461 74.8288 114.586 74.8288 114.692C74.818 114.772 74.7924 114.849 74.7533 114.919Z"
        fill="#263238"
      />
      <path
        d="M78.4607 113.005C78.4568 112.961 78.4568 112.917 78.4607 112.872C78.4607 112.761 78.4607 112.637 78.4607 112.495C78.4607 112.131 78.4607 111.665 78.4607 111.101L78.4918 111.132H72.298C72.298 111.132 72.2536 111.181 72.3468 111.087V112.046C72.3468 112.366 72.3468 112.677 72.3468 112.979L72.3024 112.934L76.7202 112.957H78.0167H78.4962H78.3808H78.0389H76.7469H72.3069H72.2625V111.016C72.3557 110.923 72.2891 110.99 72.3069 110.967H78.5007H78.5317V110.999C78.5317 111.571 78.5317 112.042 78.5317 112.41C78.5317 112.553 78.5317 112.672 78.5317 112.783C78.5159 112.86 78.4921 112.934 78.4607 113.005Z"
        fill="#263238"
      />
      <path
        d="M97.2907 122.774C97.2907 122.774 97.2907 122.725 97.2907 122.637C97.2907 122.548 97.2907 122.406 97.2907 122.264C97.2907 121.895 97.2907 121.429 97.2907 120.865L97.3218 120.896H91.128L91.1768 120.847V121.815C91.1768 122.13 91.1768 122.446 91.1768 122.748L91.1324 122.703H95.5502H96.8467H97.2019C97.2432 122.699 97.2849 122.699 97.3262 122.703H96.8822H95.5946L91.1546 122.725H91.1102V120.781L91.1546 120.736H97.3484H97.344V120.763C97.344 121.336 97.344 121.806 97.344 122.179C97.344 122.317 97.344 122.441 97.344 122.548C97.344 122.654 97.2907 122.774 97.2907 122.774Z"
        fill="#263238"
      />
      <path
        d="M133.934 126.108C133.934 126.108 133.934 126.059 133.934 125.975C133.934 125.891 133.934 125.74 133.934 125.598C133.934 125.229 133.934 124.767 133.934 124.204L133.961 124.23H127.771L127.816 124.186V126.086L127.771 126.042L132.185 126.064H133.486H133.961C133.922 126.068 133.884 126.068 133.845 126.064H133.503H132.216H127.776H127.727V124.119L127.776 124.07H133.965H133.996V124.101V125.513C133.996 125.655 133.996 125.775 133.996 125.882C133.991 125.961 133.97 126.038 133.934 126.108Z"
        fill="#263238"
      />
      <path
        d="M133.943 127.995C133.94 127.947 133.94 127.9 133.943 127.853V127.466C133.943 127.071 133.921 126.618 133.912 126.108L133.952 126.148H131.647L131.696 126.099V126.596C131.696 126.76 131.696 126.92 131.696 127.08C131.696 127.391 131.696 127.693 131.696 127.968L131.656 127.928H133.308H133.779H133.956C133.903 127.932 133.85 127.932 133.796 127.928H133.352H131.674H131.634V127.888C131.634 127.604 131.634 127.302 131.634 127C131.634 126.84 131.634 126.681 131.634 126.516V126.019L131.679 125.975H133.983H134.018V126.01C134.018 126.53 134.018 126.991 134.018 127.391C134.018 127.529 134.018 127.653 134.018 127.768C134.008 127.848 133.983 127.925 133.943 127.995Z"
        fill="#263238"
      />
      <path
        d="M118.572 122.77C118.568 122.726 118.568 122.681 118.572 122.637C118.572 122.526 118.572 122.401 118.572 122.259C118.572 121.895 118.572 121.429 118.572 120.865L118.598 120.896H112.409C112.431 120.896 112.36 120.945 112.453 120.852V122.752L112.409 122.708L116.822 122.73H118.123H118.603C118.564 122.734 118.526 122.734 118.487 122.73H118.145H116.853H112.413H112.369V122.685C112.369 122.384 112.369 122.073 112.369 121.753V120.785L112.418 120.736H118.607H118.638V120.767V122.179C118.638 122.321 118.638 122.441 118.638 122.552C118.638 122.663 118.572 122.774 118.572 122.77Z"
        fill="#263238"
      />
      <g opacity="0.2">
        <path
          d="M121.666 118.955H115.473V120.86H121.666V118.955Z"
          fill="white"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M134.12 109.867H130.626V111.772H134.12V109.867Z"
          fill="white"
        />
      </g>
      <path
        d="M88.7748 134.078C88.7748 134.078 88.7748 134.03 88.7748 133.941C88.7748 133.852 88.7748 133.71 88.7748 133.563C88.7748 133.199 88.7748 132.733 88.7748 132.169L88.8059 132.2H82.6166C82.6166 132.178 82.5677 132.245 82.661 132.151V134.052L82.6121 134.007H87.0299H88.3308H88.6816H88.3486H87.061L82.621 134.03H82.5766V132.085C82.6698 131.992 82.6032 132.063 82.6254 132.04H88.8148H88.8459V132.067V133.479C88.8459 133.621 88.8459 133.745 88.8459 133.852C88.8364 133.931 88.8123 134.008 88.7748 134.078Z"
        fill="#263238"
      />
      <path
        d="M86.0708 136.054C86.0708 136.054 86.0708 136.005 86.0708 135.921V135.544C86.0708 135.175 86.0708 134.713 86.0708 134.15L86.0975 134.176H79.9081L79.9525 134.132V136.032L79.9081 135.988H84.3215H85.6224H86.0975C86.0607 135.993 86.0233 135.993 85.9865 135.988H85.6446H84.3526H79.9126H79.8682V135.943C79.8682 135.641 79.8682 135.331 79.8682 135.011V134.043L79.917 133.994H86.1064H86.1374V134.025C86.1374 134.594 86.1374 135.064 86.1374 135.437C86.1374 135.579 86.1374 135.699 86.1374 135.806C86.1339 135.892 86.1112 135.977 86.0708 136.054Z"
        fill="#263238"
      />
      <g opacity="0.2">
        <path
          d="M93.9385 141.488H90.9726V143.393H93.9385V141.488Z"
          fill="white"
        />
      </g>
      <path
        d="M104.892 131.995H105.074H105.589H107.365L107.321 132.04V130.135L107.365 130.179H105.589H105.074H104.892C104.952 130.172 105.014 130.172 105.074 130.179H105.589H107.365H107.41V130.224C107.41 130.525 107.41 130.845 107.41 131.178C107.41 131.511 107.41 131.826 107.41 132.128V132.173H107.365H105.589H105.074C105.004 132.125 104.942 132.064 104.892 131.995Z"
        fill="#263238"
      />
      <path
        d="M103.649 111.527C103.709 111.52 103.77 111.52 103.831 111.527H104.346H106.122L106.077 111.571V109.631L106.122 109.675H104.346H103.831C103.77 109.682 103.709 109.682 103.649 109.675C103.709 109.67 103.77 109.67 103.831 109.675H104.346H106.122H106.166V109.72C106.166 110.022 106.166 110.341 106.166 110.674C106.166 111.007 106.166 111.327 106.166 111.629V111.673H106.122H104.346H103.831L103.649 111.527Z"
        fill="#263238"
      />
      <path
        d="M128.535 127.506H111.401V138.117H128.535V127.506Z"
        fill="white"
      />
      <path
        d="M116.347 138.22C116.285 138.22 116.232 135.822 116.232 132.86C116.232 129.899 116.285 127.506 116.347 127.506C116.409 127.506 116.463 129.903 116.463 132.86C116.463 135.818 116.436 138.22 116.347 138.22Z"
        fill="#B0BEC5"
      />
      <path
        d="M123.473 138.22C123.411 138.22 123.358 135.822 123.358 132.86C123.358 129.899 123.411 127.506 123.473 127.506C123.536 127.506 123.589 129.903 123.589 132.86C123.589 135.818 123.54 138.22 123.473 138.22Z"
        fill="#B0BEC5"
      />
      <path
        d="M123.473 133.186C122.293 133.302 121.107 133.341 119.921 133.301C118.736 133.341 117.55 133.302 116.369 133.186C117.55 133.072 118.736 133.033 119.921 133.07C121.107 133.033 122.293 133.072 123.473 133.186Z"
        fill="#B0BEC5"
      />
      <path
        d="M56.0076 35.5508H49.0057L23.9508 59.7932V146.773H40.3211L56.0076 35.5508Z"
        fill="#263238"
      />
      <path
        d="M40.7651 106.146C40.7613 106.108 40.7613 106.069 40.7651 106.031C40.7651 105.947 40.7651 105.836 40.7651 105.702C40.7651 105.4 40.7651 104.983 40.7651 104.446C40.7651 103.345 40.7651 101.782 40.734 99.8016C40.734 95.8545 40.7029 90.3533 40.6807 84.0663L40.7961 84.1817H34.8643C35.0952 83.9508 34.9309 84.124 34.9797 84.0752V87.3963C34.9797 88.4885 34.9797 89.563 34.9797 90.6108C34.9797 92.711 34.9797 94.7178 34.9797 96.6004C34.9797 100.357 34.9575 103.62 34.9487 106.142L34.8776 106.071L39.2066 106.111H40.3699H40.6718H40.7784C40.7474 106.115 40.7161 106.115 40.6851 106.111H40.3921H39.2466L34.8776 106.151H34.8066V106.08C34.8066 103.558 34.8066 100.308 34.7755 96.5382C34.7755 94.6557 34.7755 92.6488 34.7755 90.5487C34.7755 89.5008 34.7755 88.4264 34.7755 87.3341V84.013C34.8288 83.9597 34.6601 84.1196 34.8909 83.8887H40.8761V83.9997C40.8761 90.3178 40.8405 95.85 40.8272 99.815C40.8272 101.769 40.8006 103.336 40.7961 104.433C40.7961 104.956 40.7961 105.369 40.7961 105.662C40.7961 105.791 40.7961 105.898 40.7961 105.978C40.7961 106.058 40.7651 106.151 40.7651 106.146Z"
        fill="#455A64"
      />
      <path
        d="M40.6452 86.1761C39.6833 86.2901 38.7139 86.3287 37.7459 86.2915C36.7794 86.3288 35.8115 86.2902 34.851 86.1761C35.8115 86.0619 36.7794 86.0233 37.7459 86.0606C38.7139 86.0234 39.6833 86.062 40.6452 86.1761Z"
        fill="#455A64"
      />
      <path
        d="M40.6452 87.9866C39.6837 88.1063 38.714 88.1464 37.7459 88.1065C36.7793 88.1465 35.811 88.1064 34.851 87.9866C35.8115 87.8725 36.7794 87.8339 37.7459 87.8712C38.7139 87.834 39.6833 87.8726 40.6452 87.9866Z"
        fill="#455A64"
      />
      <path
        d="M40.6452 89.8026C39.6833 89.9166 38.7139 89.9552 37.7459 89.918C36.7794 89.9553 35.8115 89.9167 34.851 89.8026C35.8113 89.6858 36.7794 89.6472 37.7459 89.6871C38.7139 89.6473 39.6835 89.6859 40.6452 89.8026Z"
        fill="#455A64"
      />
      <path
        d="M40.6452 91.6155C39.6835 91.7322 38.7139 91.7708 37.7459 91.731C36.7794 91.771 35.8113 91.7324 34.851 91.6155C35.8115 91.5014 36.7794 91.4628 37.7459 91.5001C38.7139 91.4629 39.6833 91.5015 40.6452 91.6155Z"
        fill="#455A64"
      />
      <path
        d="M40.6452 93.4315C39.6833 93.5455 38.7139 93.5841 37.7459 93.5469C36.7794 93.5842 35.8115 93.5457 34.851 93.4315C35.8113 93.3147 36.7794 93.2761 37.7459 93.316C38.7139 93.2762 39.6835 93.3148 40.6452 93.4315Z"
        fill="#455A64"
      />
      <path
        d="M40.6452 95.2425C39.6833 95.3565 38.7139 95.3951 37.7459 95.3579C36.7794 95.3953 35.8115 95.3567 34.851 95.2425C35.8115 95.1283 36.7794 95.0897 37.7459 95.127C38.7139 95.0898 39.6833 95.1284 40.6452 95.2425Z"
        fill="#455A64"
      />
      <path
        d="M40.6452 97.053C39.6835 97.1697 38.7139 97.2083 37.7459 97.1685C36.7794 97.2085 35.8113 97.1699 34.851 97.053C35.8115 96.9389 36.7794 96.9003 37.7459 96.9376C38.7139 96.9004 39.6833 96.939 40.6452 97.053Z"
        fill="#455A64"
      />
      <path
        d="M40.6452 98.869C39.6833 98.983 38.7139 99.0216 37.7459 98.9844C36.7794 99.0217 35.8115 98.9832 34.851 98.869C35.8113 98.7522 36.7794 98.7136 37.7459 98.7535C38.7139 98.7137 39.6835 98.7523 40.6452 98.869Z"
        fill="#455A64"
      />
      <path
        d="M40.6452 100.682C39.6835 100.799 38.7139 100.837 37.7459 100.797C36.7794 100.837 35.8113 100.799 34.851 100.682C35.8115 100.568 36.7794 100.529 37.7459 100.566C38.7139 100.529 39.6833 100.568 40.6452 100.682Z"
        fill="#455A64"
      />
      <path
        d="M40.6452 102.496C39.6833 102.61 38.7139 102.649 37.7459 102.612C36.7794 102.649 35.8115 102.61 34.851 102.496C35.811 102.376 36.7793 102.336 37.7459 102.376C38.714 102.336 39.6837 102.377 40.6452 102.496Z"
        fill="#455A64"
      />
      <path
        d="M40.6452 104.305C39.6833 104.419 38.7139 104.458 37.7459 104.42C36.7794 104.458 35.8115 104.419 34.851 104.305C35.8115 104.191 36.7794 104.152 37.7459 104.19C38.7139 104.152 39.6833 104.191 40.6452 104.305Z"
        fill="#455A64"
      />
      <path
        d="M40.4809 128.885C40.4771 128.846 40.4771 128.808 40.4809 128.769C40.4809 128.685 40.4809 128.574 40.4809 128.441C40.4809 128.139 40.4809 127.721 40.4809 127.184C40.4809 126.083 40.4809 124.52 40.4498 122.54C40.4498 118.593 40.4187 113.092 40.401 106.805L40.512 106.92H34.5846C34.811 106.689 34.6467 106.862 34.6956 106.813V113.349C34.6956 115.449 34.6956 117.456 34.6956 119.339C34.6956 123.095 34.6689 126.358 34.66 128.88L34.589 128.809L38.918 128.849H40.0813H40.3832H40.4898C40.4898 128.849 40.4587 128.849 40.3965 128.849H40.1079H38.9624L34.589 128.889H34.518V128.818C34.518 126.296 34.518 123.046 34.4869 119.277C34.4869 117.394 34.4869 115.387 34.4869 113.287C34.4869 112.239 34.4869 111.165 34.4869 110.072V106.751C34.5402 106.698 34.3714 106.858 34.6068 106.627H40.5963V106.738C40.5963 113.061 40.5564 118.588 40.543 122.553C40.543 124.507 40.5164 126.074 40.512 127.171C40.512 127.695 40.512 128.108 40.512 128.401C40.512 128.534 40.512 128.636 40.512 128.716C40.512 128.796 40.4853 128.889 40.4809 128.885Z"
        fill="#455A64"
      />
      <path
        d="M40.361 108.912C39.3993 109.029 38.4297 109.068 37.4617 109.028C36.4952 109.068 35.5271 109.029 34.5668 108.912C35.5273 108.798 36.4952 108.76 37.4617 108.797C38.4297 108.76 39.3991 108.798 40.361 108.912Z"
        fill="#455A64"
      />
      <path
        d="M40.361 110.729C39.3991 110.843 38.4297 110.881 37.4617 110.844C36.4952 110.881 35.5273 110.843 34.5668 110.729C35.5268 110.609 36.4951 110.569 37.4617 110.609C38.4298 110.569 39.3996 110.609 40.361 110.729Z"
        fill="#455A64"
      />
      <path
        d="M40.361 112.539C39.3991 112.654 38.4297 112.692 37.4617 112.655C36.4952 112.692 35.5273 112.654 34.5668 112.539C35.5273 112.425 36.4952 112.387 37.4617 112.424C38.4297 112.387 39.3991 112.425 40.361 112.539Z"
        fill="#455A64"
      />
      <path
        d="M40.361 114.352C39.3993 114.469 38.4297 114.507 37.4617 114.467C36.4952 114.507 35.5271 114.469 34.5668 114.352C35.5273 114.238 36.4952 114.199 37.4617 114.236C38.4297 114.199 39.3991 114.238 40.361 114.352Z"
        fill="#455A64"
      />
      <path
        d="M40.361 116.168C39.3991 116.282 38.4297 116.321 37.4617 116.283C36.4952 116.321 35.5273 116.282 34.5668 116.168C35.5271 116.051 36.4952 116.012 37.4617 116.052C38.4297 116.012 39.3993 116.051 40.361 116.168Z"
        fill="#455A64"
      />
      <path
        d="M40.361 117.979C39.3991 118.093 38.4297 118.132 37.4617 118.094C36.4952 118.132 35.5273 118.093 34.5668 117.979C35.5273 117.865 36.4952 117.826 37.4617 117.863C38.4297 117.826 39.3991 117.865 40.361 117.979Z"
        fill="#455A64"
      />
      <path
        d="M40.361 119.791C39.3993 119.908 38.4297 119.947 37.4617 119.907C36.4952 119.947 35.5271 119.908 34.5668 119.791C35.5273 119.677 36.4952 119.639 37.4617 119.676C38.4297 119.639 39.3991 119.677 40.361 119.791Z"
        fill="#455A64"
      />
      <path
        d="M40.361 121.621C39.3991 121.735 38.4297 121.774 37.4617 121.736C36.4952 121.774 35.5273 121.735 34.5668 121.621C35.5271 121.504 36.4952 121.466 37.4617 121.506C38.4297 121.465 39.3993 121.504 40.361 121.621Z"
        fill="#455A64"
      />
      <path
        d="M40.361 123.418C39.3993 123.535 38.4297 123.574 37.4617 123.534C36.4952 123.574 35.5271 123.535 34.5668 123.418C35.5273 123.304 36.4952 123.266 37.4617 123.303C38.4297 123.265 39.3991 123.304 40.361 123.418Z"
        fill="#455A64"
      />
      <path
        d="M40.361 125.234C39.3991 125.349 38.4297 125.387 37.4617 125.35C36.4952 125.387 35.5273 125.348 34.5668 125.234C35.5271 125.117 36.4952 125.079 37.4617 125.119C38.4297 125.079 39.3993 125.117 40.361 125.234Z"
        fill="#455A64"
      />
      <path
        d="M40.361 127.045C39.3991 127.16 38.4297 127.198 37.4617 127.161C36.4952 127.198 35.5273 127.159 34.5668 127.045C35.5273 126.931 36.4952 126.893 37.4617 126.93C38.4297 126.892 39.3991 126.931 40.361 127.045Z"
        fill="#455A64"
      />
      <path
        d="M72.2446 146.724V59.7934L55.9676 38.1973L40.321 59.7934V146.773H72.2446"
        fill="#455A64"
      />
      <path
        d="M63.9863 146.483C63.9863 146.483 63.9863 146.448 63.9863 146.372C63.9863 146.297 63.9863 146.181 63.9863 146.044C63.9863 145.742 63.9863 145.316 63.9863 144.769C63.9863 143.646 63.9863 142.039 63.9507 140.032C63.9507 135.978 63.9285 130.331 63.9108 123.862L64.0218 123.973H51.3456L51.461 123.862C51.461 130.331 51.4299 135.983 51.4211 140.032C51.4211 142.039 51.3944 143.646 51.3855 144.774C51.3855 145.316 51.3855 145.742 51.3855 146.044V146.372C51.3914 146.409 51.3914 146.447 51.3855 146.483C51.3855 146.483 51.3855 146.448 51.3855 146.372C51.3855 146.297 51.3855 146.181 51.3855 146.044C51.3855 145.742 51.3855 145.316 51.3855 144.774C51.3855 143.646 51.3855 142.039 51.35 140.032C51.35 135.983 51.35 130.331 51.3101 123.862V123.746H64.1861V123.862C64.1861 130.331 64.155 135.978 64.1461 140.032C64.1461 142.039 64.1195 143.646 64.1106 144.769C64.1106 145.316 64.1106 145.742 64.1106 146.044C64.1106 146.181 64.1106 146.288 64.1106 146.372C64.0787 146.419 64.036 146.457 63.9863 146.483Z"
        fill="#263238"
      />
      <path
        d="M62.2413 135.575C62.2635 135.601 62.1481 135.699 62.1614 135.899C62.1637 135.952 62.1772 136.004 62.2009 136.051C62.2246 136.098 62.2581 136.14 62.2991 136.174C62.3489 136.208 62.4077 136.225 62.4678 136.225C62.5279 136.225 62.5867 136.208 62.6365 136.174C62.6891 136.146 62.7325 136.103 62.7617 136.052C62.7908 136 62.8044 135.94 62.8008 135.881C62.79 135.829 62.7686 135.78 62.7381 135.736C62.7075 135.693 62.6684 135.656 62.6232 135.628C62.4545 135.521 62.3124 135.575 62.2991 135.543C62.2857 135.512 62.4323 135.384 62.6942 135.481C62.7715 135.512 62.8405 135.561 62.8953 135.623C62.9501 135.686 62.9892 135.76 63.0095 135.841C63.0237 135.945 63.0071 136.05 62.9617 136.145C62.9162 136.239 62.844 136.318 62.754 136.371C62.664 136.425 62.5602 136.451 62.4556 136.446C62.351 136.441 62.2502 136.405 62.1659 136.343C62.105 136.287 62.0583 136.218 62.0297 136.141C62.0011 136.063 61.9915 135.98 62.0016 135.899C62.0371 135.614 62.2369 135.543 62.2413 135.575Z"
        fill="#263238"
      />
      <path
        d="M60.4698 120.395H54.9864V122.099H60.4698V120.395Z"
        fill="#263238"
      />
      <path
        d="M55.5103 94.1445H45.6535V113.134H55.5103V94.1445Z"
        fill="#E0E0E0"
      />
      <path
        d="M50.8572 115.262C50.795 115.262 50.7418 110.303 50.7418 104.189C50.7418 98.0752 50.795 93.1113 50.8572 93.1113C50.9194 93.1113 50.9726 98.0708 50.9726 104.189C50.9726 110.307 50.9238 115.262 50.8572 115.262Z"
        fill="#455A64"
      />
      <path
        d="M56.3406 100.752C56.3406 100.819 53.8187 100.868 50.7107 100.868C47.6027 100.868 45.0807 100.819 45.0807 100.752C45.0807 100.686 47.5982 100.637 50.7107 100.637C53.8231 100.637 56.3406 100.681 56.3406 100.752Z"
        fill="#455A64"
      />
      <path
        d="M56.3406 107.536C56.3406 107.599 53.8187 107.652 50.7107 107.652C47.6027 107.652 45.0807 107.599 45.0807 107.536C45.0807 107.474 47.616 107.412 50.724 107.412C53.832 107.412 56.3406 107.47 56.3406 107.536Z"
        fill="#455A64"
      />
      <path
        d="M68.2576 94.1445H58.4008V113.134H68.2576V94.1445Z"
        fill="#E0E0E0"
      />
      <path
        d="M63.6 115.262C63.5334 115.262 63.4846 110.303 63.4846 104.189C63.4846 98.0752 63.5334 93.1113 63.6 93.1113C63.6666 93.1113 63.7154 98.0708 63.7154 104.189C63.7154 110.307 63.671 115.262 63.6 115.262Z"
        fill="#455A64"
      />
      <path
        d="M69.0878 100.752C69.0878 100.819 66.5659 100.868 63.4579 100.868C60.3499 100.868 57.828 100.81 57.828 100.752C57.828 100.694 60.3499 100.637 63.4579 100.637C66.5659 100.637 69.0878 100.681 69.0878 100.752Z"
        fill="#455A64"
      />
      <path
        d="M69.0878 107.537C69.0878 107.599 66.5659 107.653 63.4579 107.653C60.3499 107.653 57.828 107.599 57.828 107.537C57.828 107.475 60.3499 107.422 63.4579 107.422C66.5659 107.422 69.0878 107.471 69.0878 107.537Z"
        fill="#455A64"
      />
      <path
        d="M55.5103 67.1016H45.6535V86.0914H55.5103V67.1016Z"
        fill="#E0E0E0"
      />
      <path
        d="M50.8572 88.2183C50.795 88.2183 50.7418 83.2588 50.7418 77.1449C50.7418 71.031 50.795 66.0449 50.8572 66.0449C50.9194 66.0449 50.9726 71.0044 50.9726 77.1449C50.9726 83.2854 50.9238 88.2183 50.8572 88.2183Z"
        fill="#455A64"
      />
      <path
        d="M56.3406 73.6994C56.3406 73.766 53.8187 73.8149 50.7107 73.8149C47.6027 73.8149 45.0807 73.766 45.0807 73.6994C45.0807 73.6328 47.5982 73.584 50.7107 73.584C53.8231 73.584 56.3406 73.6373 56.3406 73.6994Z"
        fill="#455A64"
      />
      <path
        d="M56.3406 80.4924C56.3406 80.5546 53.8187 80.6078 50.7107 80.6078C47.6027 80.6078 45.0807 80.5546 45.0807 80.4924C45.0807 80.4302 47.5982 80.377 50.7107 80.377C53.8231 80.377 56.3406 80.4302 56.3406 80.4924Z"
        fill="#455A64"
      />
      <path
        d="M68.2576 67.1016H58.4008V86.0914H68.2576V67.1016Z"
        fill="#E0E0E0"
      />
      <path
        d="M63.6 88.2183C63.5334 88.2183 63.4846 83.2588 63.4846 77.1449C63.4846 71.031 63.5334 66.0449 63.6 66.0449C63.6666 66.0449 63.7154 71.0044 63.7154 77.1449C63.7154 83.2854 63.671 88.2183 63.6 88.2183Z"
        fill="#455A64"
      />
      <path
        d="M69.0878 73.6994C69.0878 73.766 66.5659 73.8149 63.4579 73.8149C60.3499 73.8149 57.828 73.766 57.828 73.6994C57.828 73.6328 60.3499 73.584 63.4579 73.584C66.5659 73.584 69.0878 73.6373 69.0878 73.6994Z"
        fill="#455A64"
      />
      <path
        d="M69.0878 80.4924C69.0878 80.5546 66.5659 80.6078 63.4579 80.6078C60.3499 80.6078 57.828 80.5546 57.828 80.4924C57.828 80.4302 60.3499 80.377 63.4579 80.377C66.5659 80.377 69.0878 80.4302 69.0878 80.4924Z"
        fill="#455A64"
      />
      <path
        d="M40.3166 105.703H32.5511V114.321H40.3166V105.703Z"
        fill="#455A64"
      />
      <path
        d="M40.3166 78.0508H32.5511V86.6688H40.3166V78.0508Z"
        fill="#455A64"
      />
      <path
        d="M60.5717 58.8375C62.4552 56.6701 62.225 53.3862 60.0575 51.5027C57.8901 49.6192 54.6062 49.8494 52.7227 52.0169C50.8392 54.1843 51.0694 57.4683 53.2369 59.3517C55.4043 61.2352 58.6883 61.005 60.5717 58.8375Z"
        fill="#E0E0E0"
      />
      <path
        d="M61.8684 55.4279C61.8684 55.4901 59.3953 55.5434 56.3406 55.5434C53.2859 55.5434 50.8083 55.4901 50.8083 55.4279C50.8083 55.3658 53.2859 55.3125 56.3406 55.3125C59.3953 55.3125 61.8684 55.3613 61.8684 55.4279Z"
        fill="#455A64"
      />
      <path
        d="M56.5848 61.8971C56.5226 61.8971 56.4694 59.0777 56.4694 55.6011C56.4694 52.1246 56.5226 49.3008 56.5848 49.3008C56.647 49.3008 56.7002 52.1202 56.7002 55.6011C56.7002 59.0821 56.6514 61.8971 56.5848 61.8971Z"
        fill="#455A64"
      />
      <path
        d="M40.3211 59.7932H37.8125L56.0076 35.5508L74.2027 59.7932H72.2447L55.9677 38.197L40.3211 59.7932Z"
        fill="#263238"
      />
      <path
        d="M32.551 78.0508H20.3011V86.6688H32.551V78.0508Z"
        fill="#263238"
      />
      <path
        d="M32.551 105.703H20.3011V114.321H32.551V105.703Z"
        fill="#263238"
      />
      <path
        d="M32.551 59.7932H20.3011L39.1844 35.5508H56.0076L32.551 59.7932Z"
        fill="#263238"
      />
      <path
        d="M33.1993 114.449C33.1993 114.512 30.2467 114.565 26.6059 114.565C22.9651 114.565 20.0125 114.512 20.0125 114.449C20.0125 114.387 22.9607 114.334 26.6059 114.334C30.2512 114.334 33.1993 114.383 33.1993 114.449Z"
        fill="#455A64"
      />
      <path
        d="M33.1993 105.703C33.1993 105.77 30.2467 105.819 26.6059 105.819C22.9651 105.819 20.0125 105.77 20.0125 105.703C20.0125 105.637 22.9607 105.588 26.6059 105.588C30.2512 105.588 33.1993 105.637 33.1993 105.703Z"
        fill="#455A64"
      />
      <path
        d="M33.1993 86.8596C33.1993 86.9262 30.2467 86.975 26.6059 86.975C22.9651 86.975 20.0125 86.9262 20.0125 86.8596C20.0125 86.793 22.9607 86.7441 26.6059 86.7441C30.2512 86.7441 33.1993 86.7974 33.1993 86.8596Z"
        fill="#455A64"
      />
      <path
        d="M33.1993 78.2111C33.1993 78.2733 30.2467 78.3266 26.6059 78.3266C22.9651 78.3266 20.0125 78.2733 20.0125 78.2111C20.0125 78.149 22.9607 78.0957 26.6059 78.0957C30.2512 78.0957 33.1993 78.1445 33.1993 78.2111Z"
        fill="#455A64"
      />
      <path
        d="M49.8582 121.097C49.8582 121.097 49.8582 121.044 49.8582 120.942C49.8582 120.839 49.8582 120.675 49.8582 120.498C49.8582 120.085 49.8582 119.543 49.8582 118.877L49.8893 118.908H42.6921C42.6921 118.908 42.6477 118.957 42.7409 118.864V119.987C42.7409 120.356 42.7409 120.72 42.7409 121.07L42.6965 121.026H47.8291H49.3432H49.7472H49.8937C49.8495 121.031 49.8048 121.031 49.7605 121.026H49.3609H47.8602H42.6965H42.6521V118.775L42.6965 118.73H49.8937H49.9248V118.762C49.9248 119.432 49.9248 119.983 49.9248 120.4C49.9248 120.569 49.9248 120.711 49.9248 120.844C49.9248 120.977 49.8582 121.097 49.8582 121.097Z"
        fill="#263238"
      />
      <path
        d="M53.1926 118.877C53.1926 118.877 53.1926 118.824 53.1926 118.722C53.1926 118.62 53.1926 118.455 53.1926 118.278C53.1926 117.86 53.1926 117.319 53.1926 116.653L53.2192 116.684H46.0264C46.0264 116.662 45.9776 116.728 46.0708 116.635V118.855L46.022 118.81H51.1591H52.6687H53.2192C53.1749 118.815 53.1303 118.815 53.086 118.81H52.6864H51.1902H46.022H45.9776V116.555C46.0708 116.462 46.0042 116.528 46.0264 116.511H53.2192H53.2503V116.537C53.2503 117.212 53.2503 117.758 53.2503 118.18C53.2503 118.344 53.2503 118.486 53.2503 118.624C53.2503 118.762 53.1926 118.877 53.1926 118.877Z"
        fill="#263238"
      />
      <path
        d="M50.5908 94.1462C50.5908 94.1462 50.5908 94.093 50.5908 93.9908C50.5908 93.8887 50.5908 93.7244 50.5908 93.5468C50.5908 93.1339 50.5908 92.5922 50.5908 91.9262L50.6219 91.9573H43.4246L43.4691 91.9129V94.1329L43.4246 94.0885L48.5573 94.1107H50.0669H50.4842H50.0891H48.5884H43.4246H43.3802V94.0663C43.3802 93.7156 43.3802 93.3515 43.3802 92.983V91.8596C43.4735 91.7664 43.4069 91.833 43.4291 91.8152H50.6263H50.653V91.8463C50.653 92.5168 50.653 93.0673 50.653 93.4847C50.653 93.6534 50.653 93.7955 50.653 93.9287C50.653 94.0619 50.5908 94.1462 50.5908 94.1462Z"
        fill="#263238"
      />
      <path
        d="M69.7405 62.8443C69.7405 62.8443 69.7405 62.7866 69.7405 62.6889C69.7405 62.5912 69.7405 62.4181 69.7405 62.2449C69.7405 61.8276 69.7405 61.2859 69.7405 60.6243L69.7716 60.651H62.5744L62.6188 60.6066V62.8266L62.5744 62.7822H67.707H69.2166H69.6251H69.7716H69.2388H67.7381L62.5744 62.8044H62.53V62.76C62.53 62.4048 62.53 62.0451 62.53 61.6766V60.5488L62.5788 60.5H69.7761H69.8027V60.5311C69.8027 61.2 69.8027 61.7462 69.8027 62.1694C69.8027 62.3382 69.8027 62.4802 69.8027 62.6134C69.8027 62.7466 69.7405 62.8443 69.7405 62.8443Z"
        fill="#263238"
      />
      <path
        d="M51.9938 50.3585C51.9938 50.3585 51.9938 50.3007 51.9938 50.2031C51.9938 50.1054 51.9938 49.9322 51.9938 49.7591C51.9938 49.3461 51.9938 48.8 51.9938 48.1385L52.0205 48.1651H44.8232C44.8232 48.1651 44.7788 48.2139 44.8721 48.1207V49.244C44.8721 49.6125 44.8721 49.9766 44.8721 50.3274L44.8277 50.283H49.9647H51.4743H51.8828H51.4921H49.9958H44.8277H44.7833V48.0319C44.8765 47.9387 44.8099 48.0053 44.8277 47.9831H52.0249H52.056V48.0141C52.056 48.6846 52.056 49.2351 52.056 49.6525C52.056 49.8212 52.056 49.9633 52.056 50.0965C52.056 50.2297 51.9938 50.3585 51.9938 50.3585Z"
        fill="#263238"
      />
      <path
        d="M45.6535 84.0883C45.6535 84.0883 45.6535 84.0351 45.6535 83.9329C45.6535 83.8308 45.6535 83.6665 45.6535 83.4889C45.6535 83.0716 45.6535 82.5299 45.6535 81.8639L45.6846 81.895H38.4873C38.4873 81.895 38.4385 81.9394 38.5317 81.8461V84.0661L38.4873 84.0217L43.62 84.0439H45.1296H45.6846H45.5514H45.1518H43.6511L38.4873 84.0661H38.4429V81.8817C38.5362 81.7884 38.4696 81.8595 38.4918 81.8373H45.689H45.7201V81.8639C45.7201 82.5388 45.7201 83.0849 45.7201 83.5067C45.7201 83.671 45.7201 83.8131 45.7201 83.9507C45.7201 84.0883 45.6535 84.0883 45.6535 84.0883Z"
        fill="#263238"
      />
      <path
        d="M42.7809 81.8374C42.7809 81.8374 42.7809 81.7841 42.7809 81.682C42.7809 81.5799 42.7809 81.4156 42.7809 81.238C42.7809 80.8206 42.7809 80.279 42.7809 79.613L42.8075 79.644H35.6103C35.6103 79.644 35.5659 79.6929 35.6591 79.5996V81.8196L35.6103 81.7752L40.7473 81.7974H42.2569H42.8075C42.7632 81.8026 42.7185 81.8026 42.6743 81.7974H42.2747H40.7784H35.6103H35.5659V79.5463L35.6103 79.502H42.8075H42.8386V79.533C42.8386 80.2035 42.8386 80.754 42.8386 81.1714C42.8386 81.3401 42.8386 81.4822 42.8386 81.6154C42.8386 81.7486 42.7809 81.8374 42.7809 81.8374Z"
        fill="#263238"
      />
      <path
        d="M72.1781 126.86C72.1781 126.86 72.1781 126.802 72.1781 126.705C72.1781 126.607 72.1781 126.434 72.1781 126.261C72.1781 125.848 72.1781 125.302 72.1781 124.64L72.2092 124.667H65.0119L65.0563 124.622V126.842L65.0119 126.798H70.1446H71.6542H72.0626H71.6675H70.1668H65.003H64.932V124.547L64.9808 124.498H72.1781H72.2047V124.529C72.2047 125.2 72.2047 125.75 72.2047 126.167C72.2047 126.336 72.2047 126.478 72.2047 126.611C72.2047 126.745 72.1781 126.86 72.1781 126.86Z"
        fill="#263238"
      />
      <path
        d="M70.5264 90.2033C70.5264 90.2033 70.5264 90.1456 70.5264 90.0435C70.5264 89.9414 70.5264 89.7771 70.5264 89.5995C70.5264 89.1821 70.5264 88.6405 70.5264 87.9745L70.5575 88.0055H63.3602L63.4091 87.9567V88.5206C63.4091 88.7071 63.4091 88.898 63.4091 89.08V90.1678L63.3647 90.119L68.4973 90.1412H70.0069H70.4154H70.5619H70.0291H68.5284H63.3647H63.3203V87.8857C63.4135 87.7969 63.3469 87.8635 63.3647 87.8413H70.5619H70.593V87.8679C70.593 88.5428 70.593 89.0889 70.593 89.5107C70.593 89.675 70.593 89.8171 70.593 89.9547C70.593 90.0923 70.5264 90.2033 70.5264 90.2033Z"
        fill="#263238"
      />
      <path
        d="M72.1781 129.062C72.1714 129.008 72.1714 128.953 72.1781 128.898C72.1781 128.759 72.1781 128.611 72.1781 128.454C72.1781 128.01 72.1781 127.473 72.1781 126.856L72.218 126.896H69.4297L69.4785 126.847V128.001C69.4785 128.37 69.4785 128.72 69.4785 129.053L69.4341 129.013L71.4366 129.036H72.0093H72.2225H72.0271H71.4632L69.4341 129.058H69.372V129.013C69.372 128.68 69.372 128.33 69.372 127.961V126.807C69.332 126.851 69.4297 126.753 69.4164 126.762H72.2047H72.2402V126.798C72.2402 127.424 72.2402 127.966 72.2402 128.418C72.2402 128.583 72.2402 128.729 72.2402 128.862C72.2402 128.996 72.1781 129.067 72.1781 129.062Z"
        fill="#263238"
      />
      <path
        d="M72.1781 92.4182C72.1719 92.3621 72.1719 92.3056 72.1781 92.2495C72.1781 92.1119 72.1781 91.9653 72.1781 91.8055C72.1781 91.3615 72.1781 90.8243 72.1781 90.2115L72.218 90.247H69.4297C69.4297 90.247 69.5185 90.1582 69.4785 90.2026V91.3526C69.4785 91.7211 69.4785 92.0719 69.4785 92.4093L69.4341 92.3649L71.4366 92.3871H72.0093H72.2225H72.0271H71.4632L69.4341 92.4093H69.372V92.3694C69.372 92.0319 69.372 91.6812 69.372 91.3126V90.1627C69.332 90.2026 69.4297 90.105 69.4164 90.1139H72.2047H72.2402V90.1538C72.2402 90.7754 72.2402 91.3215 72.2402 91.7744C72.2402 91.9343 72.2402 92.0808 72.2402 92.2184C72.2402 92.3561 72.1781 92.4182 72.1781 92.4182Z"
        fill="#263238"
      />
      <path
        d="M85.2361 140.357H72.2447V146.773H85.2361V140.357Z"
        fill="#455A64"
      />
      <path
        d="M41.2268 59.7922C41.2268 59.8588 36.5648 59.8855 30.8195 59.8544C25.0741 59.8233 20.4121 59.7478 20.4121 59.6857C20.4121 59.6235 25.0697 59.5924 30.8195 59.6235C36.5693 59.6546 41.2268 59.7301 41.2268 59.7922Z"
        fill="#455A64"
      />
      <path
        d="M30.824 146.679C29.7913 146.679 28.7818 146.373 27.9231 145.799C27.0644 145.225 26.3952 144.41 26 143.456C25.6048 142.502 25.5014 141.452 25.7029 140.439C25.9043 139.426 26.4016 138.496 27.1319 137.766C27.8621 137.035 28.7925 136.538 29.8053 136.337C30.8182 136.135 31.868 136.239 32.8221 136.634C33.7762 137.029 34.5917 137.698 35.1655 138.557C35.7392 139.416 36.0454 140.425 36.0454 141.458C36.0442 142.842 35.4938 144.17 34.5148 145.149C33.5358 146.128 32.2084 146.678 30.824 146.679ZM30.824 136.711C29.884 136.711 28.9649 136.989 28.1829 137.51C27.401 138.032 26.7914 138.774 26.4312 139.642C26.0711 140.51 25.9766 141.466 26.1598 142.388C26.343 143.31 26.7955 144.157 27.4602 144.822C28.1249 145.486 28.9718 145.939 29.8938 146.122C30.8158 146.305 31.7714 146.211 32.6396 145.851C33.5079 145.49 34.2499 144.881 34.7715 144.099C35.2932 143.317 35.5712 142.398 35.5703 141.458C35.568 140.2 35.067 138.995 34.1773 138.106C33.2876 137.217 32.0817 136.717 30.824 136.716V136.711Z"
        fill="#455A64"
      />
      <path
        d="M23.0939 142.229L30.5709 141.426L30.6597 142.056L23.0939 142.807V142.229Z"
        fill="#B0BEC5"
      />
      <path
        d="M15.6036 146.679C14.5709 146.679 13.5614 146.373 12.7028 145.799C11.8441 145.225 11.1749 144.41 10.7797 143.456C10.3845 142.502 10.2811 141.452 10.4825 140.439C10.684 139.426 11.1813 138.496 11.9115 137.766C12.6418 137.035 13.5721 136.538 14.585 136.337C15.5979 136.135 16.6477 136.239 17.6018 136.634C18.5559 137.029 19.3714 137.698 19.9451 138.557C20.5189 139.416 20.8251 140.425 20.8251 141.458C20.8239 142.842 20.2734 144.17 19.2945 145.149C18.3155 146.128 16.9881 146.678 15.6036 146.679ZM15.6036 136.711C14.6636 136.711 13.7445 136.989 12.9626 137.51C12.1806 138.032 11.571 138.774 11.2109 139.642C10.8508 140.51 10.7563 141.466 10.9395 142.388C11.1226 143.31 11.5752 144.157 12.2399 144.822C12.9046 145.486 13.7515 145.939 14.6735 146.122C15.5954 146.305 16.551 146.211 17.4193 145.851C18.2876 145.49 19.0295 144.881 19.5512 144.099C20.0729 143.317 20.3509 142.398 20.35 141.458C20.3465 140.2 19.8452 138.996 18.9557 138.107C18.0662 137.218 16.861 136.718 15.6036 136.716V136.711Z"
        fill="#455A64"
      />
      <path
        d="M27.2631 133.812L26.8502 132.582L27.1654 132.098L29.0835 131.574L29.2078 131.894L27.4895 132.347L27.303 132.817L27.5872 133.714L27.2631 133.812Z"
        fill="#B0BEC5"
      />
      <path
        d="M30.4377 141.169L27.9069 135.406L22.8231 141.719L22.3702 141.267L27.6804 135.046L27.5028 134.682L19.9371 136.276L19.7772 135.694L27.3252 134.238L27.1654 133.75L27.6804 133.59L30.8239 140.88L30.4377 141.169Z"
        fill="#B0BEC5"
      />
      <path
        d="M15.8523 141.719L20.5587 142.479L20.5809 141.972L15.9944 141.275L15.8523 141.719Z"
        fill="#B0BEC5"
      />
      <path
        d="M15.6081 141.106L19.129 136.373L19.7773 136.666L16.0477 141.43L15.6081 141.106Z"
        fill="#B0BEC5"
      />
      <path
        d="M21.7886 143.927C22.5806 143.927 23.2227 143.285 23.2227 142.493C23.2227 141.701 22.5806 141.059 21.7886 141.059C20.9965 141.059 20.3544 141.701 20.3544 142.493C20.3544 143.285 20.9965 143.927 21.7886 143.927Z"
        fill="#B0BEC5"
      />
      <path
        d="M19.7773 135.695L19.4265 134.492H18.7294L19.129 136.375L19.7773 136.668L19.9371 136.277L19.7773 135.695Z"
        fill="#B0BEC5"
      />
      <path
        d="M17.7615 134.468C17.8148 134.637 18.059 135.356 18.4142 135.356C19.4809 135.264 20.542 135.116 21.5932 134.912C21.8241 134.855 21.9617 134.468 21.4023 134.468C20.6031 134.468 17.6416 134.096 17.7615 134.468Z"
        fill="#B0BEC5"
      />
      <path
        d="M16.2386 141.48C16.2386 141.605 16.2016 141.727 16.1323 141.83C16.063 141.934 15.9646 142.015 15.8494 142.063C15.7341 142.11 15.6074 142.123 15.4851 142.098C15.3628 142.074 15.2504 142.014 15.1623 141.926C15.0741 141.838 15.014 141.725 14.9897 141.603C14.9654 141.481 14.9779 141.354 15.0256 141.239C15.0733 141.124 15.1541 141.025 15.2578 140.956C15.3615 140.887 15.4834 140.85 15.6081 140.85C15.6909 140.85 15.7729 140.866 15.8494 140.898C15.9258 140.929 15.9954 140.976 16.0539 141.034C16.1124 141.093 16.1589 141.162 16.1906 141.239C16.2223 141.315 16.2386 141.397 16.2386 141.48Z"
        fill="#B0BEC5"
      />
      <path
        d="M31.45 141.48C31.45 141.605 31.413 141.727 31.3437 141.83C31.2745 141.934 31.176 142.015 31.0608 142.063C30.9456 142.11 30.8188 142.123 30.6965 142.098C30.5742 142.074 30.4619 142.014 30.3737 141.926C30.2855 141.838 30.2255 141.725 30.2011 141.603C30.1768 141.481 30.1893 141.354 30.237 141.239C30.2847 141.124 30.3655 141.025 30.4692 140.956C30.5729 140.887 30.6948 140.85 30.8195 140.85C30.9025 140.849 30.9847 140.865 31.0615 140.896C31.1382 140.928 31.208 140.974 31.2666 141.033C31.3253 141.092 31.3717 141.161 31.4032 141.238C31.4347 141.315 31.4506 141.397 31.45 141.48Z"
        fill="#B0BEC5"
      />
      <path
        opacity="0.5"
        d="M27.2631 133.812L26.8502 132.582L27.1654 132.098L29.0835 131.574L29.2078 131.894L27.4895 132.347L27.303 132.817L27.5872 133.714L27.2631 133.812Z"
        fill="black"
      />
      <path
        opacity="0.5"
        d="M21.7886 143.927C22.5806 143.927 23.2227 143.285 23.2227 142.493C23.2227 141.701 22.5806 141.059 21.7886 141.059C20.9965 141.059 20.3544 141.701 20.3544 142.493C20.3544 143.285 20.9965 143.927 21.7886 143.927Z"
        fill="black"
      />
      <path
        opacity="0.5"
        d="M17.7615 134.468C17.8148 134.637 18.059 135.356 18.4142 135.356C19.4809 135.264 20.542 135.116 21.5932 134.912C21.8241 134.855 21.9617 134.468 21.4023 134.468C20.6031 134.468 17.6416 134.096 17.7615 134.468Z"
        fill="black"
      />
      <path
        opacity="0.5"
        d="M16.2386 141.48C16.2386 141.605 16.2016 141.727 16.1323 141.83C16.063 141.934 15.9646 142.015 15.8494 142.063C15.7341 142.11 15.6074 142.123 15.4851 142.098C15.3628 142.074 15.2504 142.014 15.1623 141.926C15.0741 141.838 15.014 141.725 14.9897 141.603C14.9654 141.481 14.9779 141.354 15.0256 141.239C15.0733 141.124 15.1541 141.025 15.2578 140.956C15.3615 140.887 15.4834 140.85 15.6081 140.85C15.6909 140.85 15.7729 140.866 15.8494 140.898C15.9258 140.929 15.9954 140.976 16.0539 141.034C16.1124 141.093 16.1589 141.162 16.1906 141.239C16.2223 141.315 16.2386 141.397 16.2386 141.48Z"
        fill="black"
      />
      <path
        opacity="0.5"
        d="M31.45 141.48C31.45 141.605 31.413 141.727 31.3437 141.83C31.2745 141.934 31.176 142.015 31.0608 142.063C30.9456 142.11 30.8188 142.123 30.6965 142.098C30.5742 142.074 30.4619 142.014 30.3737 141.926C30.2855 141.838 30.2255 141.725 30.2011 141.603C30.1768 141.481 30.1893 141.354 30.237 141.239C30.2847 141.124 30.3655 141.025 30.4692 140.956C30.5729 140.887 30.6948 140.85 30.8195 140.85C30.9025 140.849 30.9847 140.865 31.0615 140.896C31.1382 140.928 31.208 140.974 31.2666 141.033C31.3253 141.092 31.3717 141.161 31.4032 141.238C31.4347 141.315 31.4506 141.397 31.45 141.48Z"
        fill="black"
      />
      <path
        d="M21.6065 142.807V144.818H20.5809V145.32H22.2059V142.807H21.6065Z"
        fill="#B0BEC5"
      />
      <path
        d="M48.1444 146.771H43.8464L42.9584 139.982H49.0057L48.1444 146.771Z"
        fill="#263238"
      />
      <path
        d="M46.9234 137.962C47.3676 137.676 47.8923 137.54 48.4197 137.576C48.7034 137.564 48.9831 137.646 49.2144 137.811C49.3246 137.898 49.3971 138.024 49.4169 138.163C49.4368 138.302 49.4024 138.443 49.321 138.557C49.199 138.668 49.0418 138.733 48.877 138.739C48.4702 138.721 48.0683 138.834 47.7315 139.063C47.5672 139.223 47.4739 139.467 47.2875 139.565C47.1812 139.606 47.0647 139.61 46.9556 139.578C46.8465 139.546 46.751 139.479 46.6836 139.387C46.5523 139.2 46.4982 138.97 46.5327 138.744C46.5353 138.592 46.5718 138.444 46.6394 138.309C46.707 138.173 46.804 138.055 46.9234 137.962Z"
        fill="#B0BEC5"
      />
      <path
        d="M45.5958 136.058C45.6726 136.127 45.7631 136.179 45.8615 136.211C45.9599 136.243 46.0638 136.253 46.1666 136.242C46.2693 136.231 46.3685 136.198 46.4576 136.145C46.5467 136.093 46.6237 136.022 46.6836 135.938C46.8806 135.638 46.9969 135.293 47.021 134.935C47.2051 134.135 47.1929 133.304 46.9855 132.51C46.9138 132.275 46.7786 132.065 46.5948 131.902C46.5027 131.821 46.391 131.767 46.2709 131.743C46.1508 131.72 46.0266 131.729 45.911 131.769C45.7855 131.841 45.6766 131.938 45.5916 132.056C45.5066 132.173 45.4475 132.307 45.4182 132.448C45.1532 133.259 45.0713 134.119 45.1784 134.966C45.2053 135.363 45.3506 135.744 45.5958 136.058Z"
        fill="#B0BEC5"
      />
      <path
        d="M44.8987 136.742C45.0149 136.536 45.0804 136.306 45.0897 136.069C45.099 135.833 45.0517 135.598 44.952 135.383C44.7356 134.951 44.4056 134.586 43.9974 134.327C43.5296 134.01 43.0049 133.787 42.4523 133.67C42.2342 133.623 42.0088 133.623 41.7907 133.67C41.6816 133.691 41.5793 133.738 41.4926 133.808C41.406 133.878 41.3376 133.967 41.2934 134.069C41.2661 134.228 41.2768 134.392 41.3247 134.546C41.3725 134.701 41.4561 134.841 41.5687 134.957C41.9107 135.421 42.2878 135.857 42.6965 136.263C43.0056 136.613 43.4048 136.873 43.8509 137.013C44.0395 137.086 44.2465 137.096 44.4415 137.043C44.6365 136.989 44.8092 136.874 44.9342 136.715"
        fill="#B0BEC5"
      />
      <path
        d="M46.3817 142.145C46.3922 142.083 46.3922 142.02 46.3817 141.959C46.3817 141.825 46.3817 141.657 46.3817 141.453C46.3689 141.204 46.3689 140.955 46.3817 140.707C46.4092 140.407 46.4596 140.11 46.5326 139.819C46.6061 139.523 46.7212 139.239 46.8745 138.975C46.9973 138.759 47.1673 138.574 47.3718 138.433C47.5092 138.343 47.6587 138.273 47.8158 138.225L47.9978 138.176C47.935 138.175 47.8723 138.183 47.8113 138.198C47.6426 138.232 47.4815 138.297 47.3363 138.389C47.1209 138.528 46.9402 138.715 46.8079 138.935C46.6506 139.204 46.5324 139.494 46.4571 139.796C46.3816 140.097 46.3311 140.403 46.3062 140.711C46.2904 140.964 46.2904 141.217 46.3062 141.47C46.3062 141.683 46.3328 141.857 46.3461 141.976C46.3526 142.034 46.3645 142.09 46.3817 142.145Z"
        fill="#263238"
      />
      <path
        d="M46.3683 141.76C46.3704 141.731 46.3704 141.701 46.3683 141.671L46.3373 141.432C46.3062 141.219 46.2662 140.912 46.2307 140.544C46.1463 139.784 46.1064 138.967 46.0797 137.804C46.0531 136.712 46.0487 135.438 46.1064 134.603C46.1064 134.412 46.133 134.239 46.1508 134.088C46.1685 133.937 46.1508 133.808 46.1863 133.702C46.2218 133.595 46.1863 133.524 46.2129 133.457C46.2396 133.391 46.2129 133.373 46.2129 133.373C46.2129 133.373 46.2129 133.4 46.2129 133.457C46.2129 133.515 46.2129 133.599 46.173 133.697C46.133 133.795 46.1419 133.932 46.1241 134.083C46.1064 134.234 46.0886 134.408 46.0709 134.598C45.9916 135.589 45.965 136.584 45.9909 137.578C46.0091 138.571 46.0773 139.562 46.1952 140.548C46.2396 140.93 46.2884 141.232 46.3239 141.436C46.3239 141.534 46.3595 141.614 46.3728 141.676C46.3659 141.703 46.3644 141.732 46.3683 141.76Z"
        fill="#263238"
      />
      <path
        d="M46.1064 139.744C46.1001 139.659 46.0882 139.575 46.0709 139.491C46.0398 139.327 45.9865 139.096 45.9022 138.812C45.8002 138.481 45.6801 138.157 45.5425 137.839C45.3767 137.449 45.1792 137.074 44.952 136.716C44.5719 136.036 44.0673 135.434 43.4646 134.94C43.3618 134.856 43.2535 134.779 43.1405 134.709C43.0971 134.676 43.051 134.646 43.0028 134.62L42.883 134.558C42.8073 134.516 42.7287 134.478 42.6476 134.447C42.9204 134.608 43.1829 134.786 43.4335 134.98C44.0145 135.478 44.5069 136.071 44.8898 136.734C45.1053 137.094 45.2981 137.468 45.467 137.853C45.6224 138.208 45.7423 138.537 45.8356 138.816C45.9288 139.096 45.9865 139.322 46.0265 139.482C46.0452 139.572 46.0719 139.659 46.1064 139.744Z"
        fill="#263238"
      />
      <path
        d="M49.1345 139.982H42.6609V140.733H49.1345V139.982Z"
        fill="#263238"
      />
      <path
        d="M48.9746 140.711C48.9746 140.738 47.6426 140.755 46.0442 140.755C44.4458 140.755 43.1138 140.755 43.1138 140.711C43.1138 140.667 44.4458 140.662 46.0442 140.662C47.6426 140.662 48.9746 140.684 48.9746 140.711Z"
        fill="#E0E0E0"
      />
      <path
        d="M48.5839 143.322C48.5839 143.322 48.5218 143.375 48.4019 143.45C48.2292 143.562 48.0426 143.65 47.8469 143.712C47.55 143.797 47.2317 143.764 46.9589 143.619C46.6481 143.464 46.3728 143.202 46.0354 143.015C45.8881 142.916 45.7173 142.858 45.5404 142.847C45.3634 142.835 45.1865 142.87 45.0275 142.949C44.73 143.1 44.5036 143.313 44.2727 143.437C44.0952 143.545 43.8899 143.599 43.6822 143.592C43.6059 143.592 43.531 143.572 43.4646 143.535C43.4646 143.535 43.5445 143.557 43.6822 143.557C43.8817 143.552 44.0758 143.491 44.2416 143.379C44.4592 143.255 44.6856 143.033 44.9875 142.873C45.1524 142.785 45.3381 142.744 45.5248 142.753C45.7209 142.772 45.9105 142.834 46.0798 142.935C46.4305 143.131 46.7014 143.379 46.9988 143.539C47.1253 143.612 47.265 143.659 47.4099 143.678C47.5548 143.696 47.7018 143.685 47.8424 143.646C48.0973 143.556 48.3451 143.448 48.5839 143.322Z"
        fill="#E0E0E0"
      />
      <path
        d="M43.7887 142.674C43.7887 142.674 43.8508 142.625 43.9396 142.674C43.9684 142.696 43.9904 142.725 44.0033 142.758C44.0161 142.792 44.0193 142.828 44.0123 142.864C44.0053 142.899 43.9886 142.931 43.964 142.958C43.9393 142.984 43.9078 143.002 43.873 143.011C43.8346 143.023 43.7937 143.023 43.7551 143.012C43.7165 143.001 43.6819 142.98 43.6555 142.949C43.6377 142.923 43.6261 142.893 43.6215 142.861C43.6169 142.83 43.6194 142.798 43.6288 142.767C43.6644 142.665 43.7443 142.656 43.7443 142.67C43.7443 142.683 43.7043 142.71 43.6954 142.785C43.6901 142.805 43.6905 142.827 43.6965 142.847C43.7025 142.867 43.7138 142.885 43.7294 142.899C43.7449 142.914 43.764 142.923 43.7847 142.927C43.8053 142.931 43.8266 142.93 43.8464 142.923C43.8662 142.917 43.8841 142.905 43.8983 142.89C43.9125 142.875 43.9225 142.857 43.9274 142.837C43.9324 142.816 43.932 142.795 43.9263 142.776C43.9207 142.756 43.91 142.737 43.8952 142.723C43.8464 142.683 43.7887 142.687 43.7887 142.674Z"
        fill="#E0E0E0"
      />
      <path
        d="M45.4271 143.35C45.4271 143.35 45.3827 143.376 45.356 143.434C45.3473 143.449 45.3427 143.467 45.3429 143.484C45.3431 143.502 45.348 143.519 45.357 143.534C45.3661 143.549 45.3791 143.561 45.3945 143.569C45.4099 143.577 45.4273 143.581 45.4448 143.581C45.4629 143.582 45.481 143.578 45.4968 143.569C45.5127 143.561 45.5257 143.547 45.5341 143.531C45.5426 143.515 45.5462 143.497 45.5446 143.479C45.5429 143.461 45.536 143.444 45.5248 143.43C45.4981 143.372 45.4493 143.363 45.4493 143.35C45.4493 143.336 45.5159 143.319 45.578 143.394C45.5982 143.415 45.6123 143.441 45.6185 143.469C45.6248 143.497 45.6231 143.527 45.6135 143.554C45.5999 143.588 45.5766 143.616 45.5466 143.637C45.5165 143.657 45.4811 143.668 45.4448 143.668C45.4086 143.668 45.3732 143.657 45.3431 143.637C45.313 143.616 45.2897 143.588 45.2761 143.554C45.2643 143.529 45.2581 143.502 45.2581 143.474C45.2581 143.446 45.2643 143.419 45.2761 143.394C45.3605 143.323 45.4315 143.341 45.4271 143.35Z"
        fill="#E0E0E0"
      />
      <path
        d="M47.4739 142.874C47.4739 142.874 47.4251 142.909 47.4073 142.985C47.4014 143.006 47.4012 143.028 47.4066 143.049C47.412 143.07 47.4229 143.09 47.4381 143.105C47.4534 143.121 47.4725 143.132 47.4935 143.138C47.5145 143.144 47.5367 143.144 47.5578 143.139C47.5789 143.134 47.5983 143.123 47.6139 143.107C47.6295 143.092 47.6408 143.073 47.6467 143.052C47.6526 143.031 47.6529 143.009 47.6475 142.988C47.6421 142.967 47.6313 142.947 47.616 142.932C47.5761 142.869 47.5183 142.861 47.5183 142.847C47.5183 142.834 47.5894 142.812 47.6649 142.892C47.6871 142.914 47.7035 142.942 47.7128 142.972C47.7221 143.002 47.7239 143.034 47.7181 143.065C47.7075 143.103 47.6861 143.137 47.6566 143.164C47.6271 143.191 47.5906 143.208 47.5514 143.215C47.5123 143.221 47.4721 143.216 47.4355 143.201C47.399 143.185 47.3677 143.16 47.3452 143.127C47.33 143.099 47.3221 143.068 47.3221 143.036C47.3221 143.004 47.33 142.973 47.3452 142.945C47.394 142.865 47.4739 142.861 47.4739 142.874Z"
        fill="#E0E0E0"
      />
      <path
        d="M129.339 140.979H109.874V146.755H129.339V140.979Z"
        fill="#455A64"
      />
      <path
        d="M130.049 140.637H109.061V141.747H130.049V140.637Z"
        fill="#455A64"
      />
      <path
        d="M129.254 141.728C129.254 141.791 124.921 141.844 119.575 141.844C114.229 141.844 109.891 141.791 109.891 141.728C109.891 141.666 114.216 141.6 119.575 141.6C124.934 141.6 129.254 141.666 129.254 141.728Z"
        fill="#263238"
      />
      <path
        d="M129.432 143.242C129.432 143.282 125.036 143.313 119.619 143.313C114.203 143.313 109.812 143.282 109.812 143.242C109.812 143.202 114.203 143.176 119.619 143.176C125.036 143.176 129.432 143.202 129.432 143.242Z"
        fill="#FAFAFA"
      />
      <path
        d="M128.97 140.636C128.708 139.695 128.295 139.247 127.363 139.087C127.397 138.228 127.126 137.384 126.598 136.705C126.07 136.027 125.318 135.557 124.477 135.379C123.636 135.202 122.758 135.328 122.001 135.735C121.244 136.142 120.655 136.804 120.339 137.604C119.815 137.165 119.189 136.866 118.518 136.736C117.847 136.606 117.155 136.649 116.505 136.861C115.856 137.073 115.271 137.447 114.806 137.947C114.341 138.448 114.011 139.059 113.847 139.722C112.476 139.078 110.859 139.464 110.295 140.654L128.97 140.636Z"
        fill="#263238"
      />
      <path
        d="M185.114 124.958C185.114 124.958 184.306 124.727 183.995 126.588C183.822 127.54 183.712 128.503 183.667 129.469C183.667 129.469 183.56 129.873 183.258 129.727C182.956 129.58 182.446 129.056 182.09 129.283C181.735 129.509 181.731 130.02 182.019 130.664C182.308 131.308 182.907 132.737 182.938 132.884C182.969 133.03 182.974 133.226 182.756 133.159C182.539 133.092 181.127 132.271 180.772 132.955C180.416 133.639 181.518 135.299 181.633 135.486C181.748 135.672 181.74 135.765 181.66 135.845C181.58 135.925 181.131 135.601 180.723 135.401C180.314 135.201 179.799 134.997 179.626 135.401C179.453 135.805 179.799 137.208 181.802 139.02L182.787 139.797L183.964 139.353C186.424 138.247 187.179 137.026 187.143 136.578C187.108 136.129 186.566 136.134 186.104 136.227C185.642 136.32 185.123 136.485 185.074 136.382C185.025 136.28 185.038 136.187 185.207 136.045C185.376 135.903 186.925 134.669 186.801 133.901C186.677 133.132 185.083 133.492 184.852 133.488C184.621 133.483 184.674 133.274 184.763 133.168C184.852 133.061 185.842 131.88 186.313 131.356C186.783 130.832 186.943 130.366 186.668 130.024C186.393 129.683 185.753 130.024 185.425 130.078C185.096 130.131 185.114 129.705 185.114 129.705C185.365 128.771 185.557 127.822 185.687 126.863C185.962 124.998 185.096 124.972 185.096 124.972"
        fill="#B0BEC5"
      />
      <path
        d="M184.834 126.623C184.835 126.636 184.835 126.65 184.834 126.663V126.778C184.834 126.881 184.799 127.032 184.772 127.222C184.719 127.609 184.634 128.164 184.532 128.847C184.324 130.215 184.022 132.106 183.68 134.175C183.338 136.245 183.032 138.149 182.819 139.503C182.712 140.183 182.623 140.738 182.565 141.12C182.534 141.311 182.512 141.462 182.494 141.564V141.675C182.495 141.688 182.495 141.701 182.494 141.715C182.493 141.701 182.493 141.688 182.494 141.675V141.559C182.494 141.457 182.53 141.311 182.557 141.115C182.61 140.729 182.69 140.178 182.796 139.49C183.005 138.123 183.307 136.236 183.649 134.162C183.991 132.089 184.297 130.166 184.51 128.834C184.617 128.15 184.706 127.595 184.763 127.214C184.794 127.023 184.816 126.876 184.834 126.77V126.654C184.834 126.644 184.834 126.633 184.834 126.623Z"
        fill="#263238"
      />
      <path
        d="M182.778 130.055C182.8 130.075 182.818 130.099 182.832 130.126C182.867 130.17 182.916 130.241 182.974 130.33C183.089 130.503 183.24 130.747 183.418 131.018L183.831 131.715L183.955 131.928C183.974 131.952 183.989 131.979 183.999 132.008C183.979 131.986 183.959 131.962 183.942 131.937L183.804 131.733C183.693 131.56 183.542 131.311 183.36 131.04L182.943 130.343L182.814 130.135C182.8 130.109 182.788 130.082 182.778 130.055Z"
        fill="#263238"
      />
      <path
        d="M184.102 131.995C184.102 131.995 184.586 131.649 185.194 131.245C185.802 130.84 186.304 130.516 186.313 130.53C186.322 130.543 185.829 130.876 185.22 131.28C184.612 131.684 184.11 132.004 184.102 131.995Z"
        fill="#263238"
      />
      <path
        d="M183.436 135.175L183.52 135.144L183.76 135.069C183.964 135.002 184.244 134.913 184.546 134.802C184.847 134.691 185.123 134.589 185.323 134.509L185.553 134.416L185.642 134.385L185.562 134.429L185.331 134.536C185.141 134.625 184.865 134.736 184.563 134.847C184.261 134.958 183.973 135.037 183.769 135.095L183.524 135.157C183.496 135.167 183.466 135.173 183.436 135.175Z"
        fill="#263238"
      />
      <path
        d="M181.851 133.684L181.917 133.732L182.095 133.888L182.663 134.398L183.223 134.922L183.391 135.087L183.449 135.149C183.449 135.149 183.422 135.149 183.378 135.1L183.205 134.945C183.054 134.816 182.854 134.634 182.632 134.434L182.073 133.91L181.904 133.746C181.868 133.706 181.846 133.684 181.851 133.684Z"
        fill="#263238"
      />
      <path
        d="M180.9 136.414L180.989 136.481L181.216 136.676L181.948 137.329C182.237 137.586 182.49 137.817 182.676 137.99L182.894 138.194L182.969 138.274L182.881 138.208L182.654 138.012L181.922 137.36L181.193 136.716L180.976 136.512L180.9 136.414Z"
        fill="#263238"
      />
      <path
        d="M182.929 138.217L183.04 138.172L183.342 138.066C183.595 137.977 183.946 137.848 184.328 137.702C184.71 137.555 185.052 137.409 185.3 137.302L185.593 137.178C185.628 137.167 185.664 137.161 185.7 137.16L185.598 137.213C185.531 137.249 185.434 137.298 185.309 137.351C185.065 137.467 184.723 137.613 184.337 137.764C183.951 137.915 183.604 138.035 183.347 138.115C183.218 138.159 183.111 138.19 183.04 138.208L182.929 138.217Z"
        fill="#263238"
      />
      <path
        d="M185.207 146.771H180.909L180.017 139.982H186.064L185.207 146.771Z"
        fill="#263238"
      />
      <path
        d="M186.197 139.982H179.724V140.733H186.197V139.982Z"
        fill="#263238"
      />
      <path
        d="M186.033 140.711C186.033 140.738 184.723 140.755 183.107 140.755C181.491 140.755 180.177 140.755 180.177 140.711C180.177 140.667 181.486 140.662 183.107 140.662C184.728 140.662 186.033 140.684 186.033 140.711Z"
        fill="#E0E0E0"
      />
      <path
        d="M185.647 143.321C185.591 143.372 185.528 143.416 185.46 143.45C185.289 143.563 185.102 143.652 184.905 143.712C184.608 143.798 184.29 143.765 184.017 143.619C183.702 143.464 183.427 143.202 183.094 143.015C182.946 142.916 182.774 142.858 182.596 142.847C182.419 142.835 182.241 142.87 182.081 142.949C181.788 143.099 181.562 143.313 181.331 143.437C181.154 143.545 180.948 143.599 180.741 143.592C180.664 143.592 180.589 143.572 180.523 143.535C180.523 143.535 180.598 143.557 180.741 143.557C180.94 143.551 181.134 143.489 181.3 143.379C181.518 143.255 181.744 143.033 182.046 142.873C182.209 142.786 182.394 142.744 182.579 142.753C182.776 142.771 182.968 142.834 183.138 142.935C183.489 143.131 183.76 143.379 184.057 143.539C184.183 143.612 184.322 143.659 184.466 143.677C184.61 143.696 184.756 143.685 184.896 143.646C185.154 143.557 185.405 143.448 185.647 143.321Z"
        fill="#E0E0E0"
      />
      <path
        d="M180.847 142.674C180.847 142.674 180.914 142.625 180.998 142.674C181.023 142.692 181.044 142.716 181.058 142.744C181.072 142.772 181.079 142.803 181.078 142.834C181.075 142.874 181.06 142.913 181.034 142.944C181.009 142.976 180.975 143 180.937 143.012C180.898 143.024 180.857 143.025 180.818 143.014C180.779 143.003 180.744 142.98 180.718 142.949C180.701 142.923 180.689 142.893 180.684 142.861C180.68 142.83 180.682 142.798 180.692 142.767C180.727 142.665 180.807 142.656 180.807 142.67C180.807 142.683 180.763 142.71 180.754 142.785C180.75 142.806 180.751 142.827 180.758 142.847C180.764 142.867 180.776 142.885 180.792 142.899C180.808 142.913 180.827 142.922 180.847 142.926C180.868 142.931 180.889 142.929 180.909 142.923C180.93 142.917 180.948 142.906 180.963 142.891C180.977 142.876 180.987 142.857 180.992 142.836C180.997 142.816 180.995 142.794 180.989 142.774C180.982 142.754 180.97 142.736 180.954 142.723C180.905 142.683 180.852 142.687 180.847 142.674Z"
        fill="#E0E0E0"
      />
      <path
        d="M182.49 143.35C182.49 143.35 182.441 143.376 182.419 143.434C182.41 143.449 182.406 143.466 182.405 143.483C182.405 143.5 182.41 143.517 182.418 143.532C182.427 143.547 182.439 143.559 182.454 143.568C182.469 143.576 182.486 143.581 182.503 143.581C182.52 143.581 182.537 143.576 182.552 143.568C182.567 143.559 182.579 143.547 182.588 143.532C182.596 143.517 182.601 143.5 182.601 143.483C182.601 143.466 182.596 143.449 182.588 143.434C182.556 143.376 182.508 143.367 182.512 143.354C182.516 143.341 182.574 143.323 182.641 143.399C182.662 143.423 182.676 143.453 182.681 143.485C182.685 143.518 182.681 143.551 182.667 143.58C182.653 143.61 182.632 143.635 182.604 143.652C182.577 143.669 182.545 143.679 182.512 143.678C182.474 143.681 182.437 143.672 182.405 143.652C182.373 143.632 182.348 143.602 182.334 143.567C182.326 143.54 182.323 143.512 182.328 143.484C182.333 143.456 182.344 143.43 182.361 143.407C182.423 143.323 182.494 143.341 182.49 143.35Z"
        fill="#E0E0E0"
      />
      <path
        d="M184.532 142.874C184.532 142.874 184.488 142.909 184.47 142.985C184.463 143.005 184.462 143.026 184.466 143.047C184.471 143.067 184.48 143.086 184.494 143.102C184.508 143.118 184.526 143.13 184.546 143.136C184.566 143.143 184.587 143.144 184.608 143.14C184.629 143.138 184.649 143.13 184.666 143.117C184.683 143.105 184.696 143.087 184.704 143.068C184.712 143.048 184.714 143.026 184.71 143.006C184.707 142.985 184.697 142.965 184.683 142.949C184.643 142.887 184.59 142.878 184.59 142.865C184.59 142.852 184.661 142.829 184.737 142.909C184.759 142.932 184.775 142.959 184.784 142.989C184.794 143.019 184.796 143.051 184.79 143.082C184.78 143.121 184.758 143.157 184.728 143.183C184.698 143.21 184.661 143.228 184.621 143.233C184.581 143.24 184.541 143.236 184.504 143.22C184.467 143.204 184.435 143.178 184.412 143.145C184.399 143.116 184.392 143.085 184.392 143.054C184.392 143.022 184.399 142.991 184.412 142.963C184.457 142.865 184.537 142.86 184.532 142.874Z"
        fill="#E0E0E0"
      />
    </svg>
  );
};

export default noApplications;
