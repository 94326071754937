import React from "react";

const emailLogo = ({
  wSize = "25",
  hSize = "16",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={wSize}
      height={hSize}
      viewBox="0 0 25 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0V16H25V0H0ZM12.5 8.74375L1.90104 1H23.099L12.5 8.74375ZM1.04167 15V1.63125L8.53516 7.10625L4.10156 11.9375L4.23177 12.0625L9.36849 7.7125L12.5 10L15.6315 7.7125L20.7682 12.0625L20.8984 11.9375L16.4648 7.1L23.9583 1.63125V15H1.04167Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};

export default emailLogo;
