import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
} from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteBtn,
  InformationWrap,
  ViewAllRental,
} from "../../Profile-verification/PriorRentalInformation";
import { FormWrap, GeneralProfileWrap } from "./GeneralProfile";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../../Profile-verification/BackgroundCheck";
import { PlusSvg, LessSvg } from "../../../assets/svgs";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import { StyledButton } from "../../property-details/apply-for-property-modal/ApplyForPropertyModal";
import data from "../../Profile-verification/state.json";
import styled from "styled-components";
import moment from "moment";
import { applicationProcess } from "../../../store/reducers/user.reducer";

function AddReference({ onClickBack, onMainTabNext }) {
  const { Option } = Select;
  const monthFormat = "YYYY/MM/DD";
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { applicationProcessData } = useSelector((state) => state.user);

  const [lenOfCharge, setLenOfCharge] = useState(1);
  const [stateValue, setstateValue] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isNewAdd, setIsNewAdd] = useState(true);
  const [citesValue, setCitesValue] = useState([]);
  const [oldReference, setOldReference] = useState([]);
  const [newSetalFields, setNewSetalFields] = useState([]);
  const [openIndexs, setOpenIndexs] = useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  ]);

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  useEffect(() => {
    if (applicationProcessData?.rentalHistory?.references?.length) {
      setNewSetalFields(applicationProcessData?.rentalHistory?.references);
    }
  }, [applicationProcessData]);

  const onFinish = (value) => {
    setIsSubmit(true);

    if (value.propertyType) {
      setIsSubmit(false);

      // setOldReference((val) => [...val, value]);
      setNewSetalFields((val) => [...val, value]);
    } else {
      value.reference = newSetalFields;

      let data = {};
      data.rentalHistory = {};
      data.rentalHistory.isPreviousRented = true;
      data.rentalHistory.references = newSetalFields;

      dispatch(applicationProcess(data)).then(({ payload }) => {
        setIsSubmit(false);
        onMainTabNext("payment");
      });
    }

    form.resetFields();
  };

  useEffect(() => {
    if (newSetalFields?.length) {
      setIsNewAdd(false);
    } else {
      setIsNewAdd(true);
    }
  }, [newSetalFields]);

  const addChanges = () => {
    setIsNewAdd(true);
  };

  const openIndexFun = (idx) => {
    if (openIndexs?.includes(idx)) {
      var filteredArray = openIndexs?.filter((e) => {
        return e !== idx;
      });
      setOpenIndexs(filteredArray);
    } else {
      setOpenIndexs((val) => [...val, idx]);
    }
  };

  const onChange = (newValue) => {
    setstateValue(newValue);
    form.setFieldsValue({ city: [] });
  };

  useEffect(() => {
    setCitesValue(data[stateValue]);
  }, [stateValue]);

  function formatDate(date = new Date()) {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .formatToParts(date)
      .reduce((acc, part) => {
        if (part.type != "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    return `${day} ${month}, ${year}`;
  }

  const dateConverter = (dateValue, month) => {
    var date = new Date(dateValue);

    return formatDate(date);
  };

  return (
    <GeneralProfileWrap>
      <h1>Add Reference</h1>

      <InformationWrap>
        <FormWrap mt="0">
          <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
            <StyledForms>
              <Form
                layout="vertical"
                name="dynamic_form_nest_item"
                onFinish={onFinish}
                autoComplete="off"
                form={form}
              >
                {newSetalFields?.map(
                  (
                    {
                      propertyType,
                      propertyName,
                      address,
                      city,
                      state,
                      zipcode,
                      currentRent,
                      managerName,
                      phone,
                      dateMovedIn,
                    },
                    idx
                  ) => (
                    <>
                      {/* {oldReference?.length ? ( */}
                      <ViewAllRental
                        mb={openIndexs?.includes(idx) ? "0" : "25px"}
                        key={idx}
                      >
                        <AddedReference
                          bb={
                            openIndexs?.includes(idx)
                              ? "none"
                              : "1px solid #dddddd"
                          }
                          onClick={() => openIndexFun(idx)}
                        >
                          <Row
                            justify="space-between"
                            style={{ alignItems: "center" }}
                          >
                            <Col>
                              <span>{propertyType}</span>
                              <h5>{propertyName}</h5>
                            </Col>
                            <Col>
                              {openIndexs?.includes(idx) ? (
                                <>
                                  <LessSvg
                                    style={{ marginBottom: "5px" }}
                                    className="less__svg"
                                  />
                                </>
                              ) : (
                                <>
                                  <PlusSvg />
                                </>
                              )}
                            </Col>
                          </Row>
                        </AddedReference>
                      </ViewAllRental>
                      {/* ) : ( */}
                      {/* "" */}
                      {/* )} */}

                      {openIndexs?.includes(idx) ? (
                        <ViewAddedReference>
                          <Row justify="space-between" gutter={[15, 15]}>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={12}
                              xxl={12}
                            >
                              <h2>Address</h2>
                              <Row justify="space-between" gutter={[2, 2]}>
                                <Col span={8}>
                                  <DetailsBox>
                                    <p>Address</p>
                                  </DetailsBox>
                                </Col>
                                <Col span={16}>
                                  <DetailsBox color="#000000">
                                    <p>{address}</p>
                                  </DetailsBox>
                                </Col>

                                <Col span={8}>
                                  <DetailsBox>
                                    <p>City</p>
                                  </DetailsBox>
                                </Col>
                                <Col span={16}>
                                  <DetailsBox color="#000000">
                                    <p>{city}</p>
                                  </DetailsBox>
                                </Col>

                                <Col span={8}>
                                  <DetailsBox>
                                    <p>State</p>
                                  </DetailsBox>
                                </Col>
                                <Col span={16}>
                                  <DetailsBox color="#000000">
                                    <p>{state}</p>
                                  </DetailsBox>
                                </Col>

                                <Col span={8}>
                                  <DetailsBox>
                                    <p>Zipcode</p>
                                  </DetailsBox>
                                </Col>
                                <Col span={16}>
                                  <DetailsBox color="#000000">
                                    <p>{zipcode ? zipcode : `${" "}`}</p>
                                  </DetailsBox>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={12}
                              xxl={12}
                            >
                              <h2>Contact Details</h2>
                              <Row justify="space-between" gutter={[2, 2]}>
                                <Col span={8}>
                                  <DetailsBox>
                                    <p>Current rent</p>
                                  </DetailsBox>
                                </Col>
                                <Col span={16}>
                                  <DetailsBox color="#000000">
                                    <p>{currentRent}</p>
                                  </DetailsBox>
                                </Col>

                                <Col span={8}>
                                  <DetailsBox>
                                    <p>Manager’s name</p>
                                  </DetailsBox>
                                </Col>
                                <Col span={16}>
                                  <DetailsBox color="#000000">
                                    <p>{managerName}</p>
                                  </DetailsBox>
                                </Col>

                                <Col span={8}>
                                  <DetailsBox>
                                    <p>Phone</p>
                                  </DetailsBox>
                                </Col>
                                <Col span={16}>
                                  <DetailsBox color="#000000">
                                    <p>{phone}</p>
                                  </DetailsBox>
                                </Col>

                                <Col span={8}>
                                  <DetailsBox>
                                    <p>Date moved in</p>
                                  </DetailsBox>
                                </Col>
                                <Col span={16}>
                                  <DetailsBox color="#000000">
                                    <p>{dateConverter(dateMovedIn)}</p>
                                  </DetailsBox>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </ViewAddedReference>
                      ) : (
                        ""
                      )}
                    </>
                  )
                )}
                {isNewAdd ? (
                  <>
                    <StyledFormItem
                      style={{ marginTop: "25px" }}
                      wh="49"
                      fd={"column"}
                      smwh={"100"}
                    >
                      <Form.Item
                        name="propertyType"
                        label="Property Type"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          //   defaultValue={item.propertyType}
                          //   onChange={(value) => {
                          //     onNameOfComplexChange(value, "propertyType", idx);
                          //   }}
                          //   placeholder="Select Property Type"
                          allowClear
                          suffixIcon={<IoIosArrowDown />}
                          // disabled={selectedApartment}
                        >
                          <Option value="Single Family Home">
                            Single-Family Home
                          </Option>
                          <Option value="Condo">Condo</Option>
                          <Option value="Townhouse">Townhouse</Option>
                          <Option value="Apartment">Apartment</Option>
                          <Option value="Farm/Ranch">Farm/Ranch</Option>
                          <Option value="Multi-Family">Multi-Family</Option>
                          <Option value="Commercial">Commercial</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="propertyName"
                        label="Property Name"
                        //   rules={[
                        //     {
                        //       required: true,
                        //     },
                        //   ]}
                      >
                        <Input
                        //   defaultValue={item.propertyName}
                        //   onChange={(e) => {
                        //     onNameOfComplexChange(
                        //       e.target.value,
                        //       "propertyName",
                        //       idx
                        //     );
                        //   }}
                        // placeholder="Phone Number of Complex"
                        />
                      </Form.Item>
                    </StyledFormItem>
                    <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                      <Form.Item
                        name="address"
                        label="Address"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        //   onChange={(e) => {
                        //     onNameOfComplexChange(e.target.value, "address", idx);
                        //   }}
                        //   defaultValue={item.address}
                        // placeholder="Address Line 1"
                        />
                      </Form.Item>
                      <Form.Item
                        name="state"
                        label="State"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          // placeholder="State"
                          onChange={(value) => {
                            onChange(value);
                            // onNameOfComplexChange(value, "state", idx);
                          }}
                          suffixIcon={<IoIosArrowDown />}
                          //   defaultValue={item.state}
                        >
                          {states.length &&
                            states.map((state, idx) => (
                              <Option key={idx} value={state}>
                                {state}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </StyledFormItem>
                    <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                      <Form.Item
                        name="city"
                        label="City"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          //   onChange={(value) => {
                          //     onNameOfComplexChange(value, "city", idx);
                          //   }}
                          showSearch
                          // placeholder="City"
                          // onChange={}
                          suffixIcon={<IoIosArrowDown />}
                          //   defaultValue={item.city}
                        >
                          {citesValue
                            ? citesValue.map((cites, idx) => (
                                <Option key={idx} value={cites}>
                                  {cites}
                                </Option>
                              ))
                            : ""}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="zipcode"
                        label="Zip code"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          //   defaultValue={item.zipcode}
                          //   onChange={(e) => {
                          //     onNameOfComplexChange(e.target.value, "zipcode", idx);
                          //   }}
                          type="number"
                          pattern="[0-9]*"
                          // placeholder="Zip code"
                        />
                      </Form.Item>
                    </StyledFormItem>
                    <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                      <Form.Item
                        className="input__affix"
                        name="currentRent"
                        label="Current rent"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input type="number" prefix={`$`} pattern="[0-9]*" />
                      </Form.Item>
                      <Form.Item
                        name="managerName"
                        label="Manager’s name"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        //   onChange={(e) => {
                        //     onNameOfComplexChange(
                        //       e.target.value,
                        //       "managerName",
                        //       idx
                        //     );
                        //   }}
                        //   defaultValue={item.address}
                        // placeholder="Address Line 1"
                        />
                      </Form.Item>
                    </StyledFormItem>
                    <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                      <Form.Item
                        className="input__affix"
                        name="phone"
                        label="Phone"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                        pattern="[0-9]*"
                          type="number"
                          prefix={`+1`}
                          onKeyDown={(e) =>
                            e.key === "." ? e.preventDefault() : ""
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name="dateMovedIn"
                        label="Date moved in"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <DatePicker
                          placeholder=""
                          format={monthFormat}
                          // picker="day"
                          // disabledDate={(current) => {
                          //   return moment().add(-1, "days") <= current;
                          // }}
                          allowClear={false}
                        />
                      </Form.Item>
                    </StyledFormItem>
                  </>
                ) : (
                  <Form.Item style={{ marginTop: "25px" }}>
                    <Row justify="space-between">
                      <Col>
                        <h5>
                          <Checkbox>
                            Terms and Condition | Permission to connect
                          </Checkbox>
                        </h5>
                      </Col>
                      <Col>
                        <Row
                          className="icon__text"
                          block="true"
                          // onClick={() => setIsAdd(!isAdd)}
                          onClick={() => addChanges()}
                        >
                          <Col>
                            <PlusSvg />
                          </Col>
                          <Col>
                            <p>Add Another Reference</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Item>
                )}

                <Form.Item>
                  <Row justify="space-between" style={{ alignItems: "center" }}>
                    <Col
                      xs={10}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="back__btn"
                    >
                      <IoIosArrowBack
                        onClick={() => {
                          onClickBack();
                        }}
                      />
                      <h2
                        onClick={() => {
                          onClickBack();
                        }}
                      >
                        back
                      </h2>
                    </Col>
                    <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Row justify="end" style={{ alignItems: "center" }}>
                        <Col>
                          <p className="cancel__btn">Cancel</p>
                        </Col>
                        <Col xs={10} sm={8} md={8} lg={8} xl={6} xxl={6}>
                          <StyledButton
                            mt="0"
                            loading={isSubmit}
                            htmlType="submit"
                          >
                            next
                          </StyledButton>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </StyledForms>
          </StyledForm>
        </FormWrap>
      </InformationWrap>
    </GeneralProfileWrap>
  );
}

export default AddReference;

const AddedReference = styled.div`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-bottom: ${({ bb }) => bb || "1px solid #dddddd"};
  padding: 20px;
  cursor: pointer;
  span {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;
    background: #4160ac;
    padding: 2px 5px;
  }

  h5 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 10px;
  }
`;

const ViewAddedReference = styled.div`
  background: #fafafa;
  border: 1px solid #dddddd;
  border-top: none;
  padding: 20px;

  h2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #777777;
    margin-bottom: 5px;
  }
`;

const DetailsBox = styled.div`
  background: #ffffff;
  padding: 10px 12px;
  min-height: 37px;
  max-height: 37px;
  overflow: auto;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${({ color }) => color || "#777777"};
    margin-bottom: 0;
  }
`;
