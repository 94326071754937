export const cardDummyData = [
  {
    _id: 1,
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card1.png",
  },
  {
    _id: 2,
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card2.png",
  },
  {
    _id: 3,
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card3.png",
  },
  {
    _id: 4,
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card4.png",
  },
  {
    _id: 5,
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card5.png",
  },
  {
    _id: 6,
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card6.png",
  },
  {
    _id: 7,
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card3.png",
  },
  {
    _id: 8,
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card1.png",
  },
  {
    _id: 9,
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card2.png",
  },
  {
    _id: 10,
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card4.png",
  },
  {
    _id: 11,
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card6.png",
  },
  {
    _id: 12,
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card5.png",
  },
  {
    _id: 13,
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card1.png",
  },
  {
    _id: 14,
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card6.png",
  },
  {
    _id: 15,
    rating: "4",
    title: "Merrick in Spring Way",
    Price: "969.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card2.png",
  },
];

export const applicationsData = [
  {
    _id: 1,
    rating: 4.7,
    title: "Merrick in Spring Way",
    Price: "669.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/property1.png",
    status: "Approved",
  },
  {
    _id: 2,
    rating: 3.1,
    title: "Merrick in Spring Way",
    Price: "169.00",
    bed: "3",
    bath: "1",
    sqft: "1800",
    img: "/images/card2.png",
    status: "Pending",
  },
  {
    _id: 3,
    rating: 3.8,
    title: "Merrick in Spring Way",
    Price: "969.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card3.png",
    status: "Approved",
  },
  {
    _id: 4,
    rating: 3.8,
    title: "Merrick in Spring Way",
    Price: "1569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card3.png",
    status: "Pending",
  },
  {
    _id: 5,
    rating: 3.8,
    title: "Merrick in Spring Way",
    Price: "4169.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card3.png",
    status: "Approved",
  },
  {
    _id: 6,
    rating: 3.8,
    title: "Merrick in Spring Way",
    Price: "9569.00",
    bed: "2",
    bath: "2",
    sqft: "1500",
    img: "/images/card3.png",
    status: "Pending",
  },
];
