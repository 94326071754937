import React from "react";
import { Container } from "./MarketingSection";
import styled from "styled-components";
import { Col, Row } from "antd";
import { RectangleWrap } from "./FeatureSection";
import { HeaderWrap, MainContainer, StyledButton } from "./TopSearchSection";

const PlanSection = () => {
  return (
    <>
      <MainContainer containterHeight="auto">
        <HeaderWrap>
          <PlanSectionWrap>
            <Row justify="space-between">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={9}
                xxl={9}
                className="plan_descrip"
              >
                <RectangleWrap />

                <h1>Pick a plan that’s right for you</h1>
                <span>
                  Avenew offers a range of flexible plans designed to
                  accommodate various property management requirements. Whether
                  you're an individual landlord with a single property or a
                  property management company handling multiple units, we have a
                  plan that fits. Find the perfect match for your rental
                  property management journey with Avenew's pricing options.
                </span>
                <Row>
                  <StyledButton className="style_btn">
                    <p>choose Plan</p>
                  </StyledButton>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <img src="./images/Ave_Plan_img.png" alt="Plan Image" />
              </Col>
            </Row>
          </PlanSectionWrap>
        </HeaderWrap>
      </MainContainer>
    </>
  );
};

export default PlanSection;

const PlanSectionWrap = styled.div`
  width: 1400px;
  margin: 0 auto;
  margin-top: 40px;
  h1 {
    padding-top: 25px;
    color: #2b353f;
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 30px;
  }
  .style_btn {
    margin-left: 0px;
    margin-top: 30px;
    padding-top: 6px;
  }

  span {
    color: #848687;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    word-wrap: normal;
    padding-bottom: 30px;
  }
  img {
    display: flex;
    width: 100%;
  }

  .plan_descrip {
    padding: 50px;
  }

  @media screen and (min-width: 1550px) {
    .style_btn {
      padding-top: 10px;
    }
  }

  @media (max-width: 1400px) {
    margin: 0;
    width: 100%;
  }

  @media (max-width: 991px) {
    .style_btn {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .plan_descrip {
      padding: 0;
      padding-top: 50px;
    }
    h1 {
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }
  }
`;
