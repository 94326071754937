import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../../Profile-verification/BackgroundCheck";
import { StyledButton } from "../../property-details/apply-for-property-modal/ApplyForPropertyModal";
import data from "../../Profile-verification/state.json";
import moment from "moment";
import { applicationProcess } from "../../../store/reducers/user.reducer";

function GeneralProfile({ isLoading, onGeneralProfileNext, onBackClickTabs }) {
  const dispatch = useDispatch();
  const { applicationProcessData } = useSelector((state) => state.user);
  const monthFormat = "YYYY/MM/DD";
  const [form] = Form.useForm();
  const { Option } = Select;
  const [state, setState] = useState([]);
  const [stateValue, setstateValue] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [citesValue, setCitesValue] = useState([]);
  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  useEffect(() => {
    setCitesValue(data[stateValue]);
  }, [stateValue]);

  const onFinish = (value) => {
    value.socialSecurityNumber = `+1${value.socialSecurityNumber}`;
    setIsSubmit(true);
    let data = {};
    data.generalProfile = value;
    dispatch(applicationProcess(data)).then(({ payload }) => {
      if (payload) {
        onGeneralProfileNext("id-proof");
      }
      setIsSubmit(false);
    });
  };

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onChange = (newValue) => {
    setstateValue(newValue);
    form.setFieldsValue({ city: [] });
  };

  useEffect(() => {
    if (applicationProcessData && applicationProcessData?.id) {
      if (
        applicationProcessData?.userProfile &&
        applicationProcessData?.userProfile?.generalProfile
      ) {
        form.setFieldsValue({
          firstLegalName:
            applicationProcessData?.userProfile?.generalProfile?.firstLegalName,
          MiddleLegalName:
            applicationProcessData?.userProfile?.generalProfile
              ?.MiddleLegalName,
          lastLegalName:
            applicationProcessData?.userProfile?.generalProfile?.lastLegalName,
          birthdate: moment(
            applicationProcessData?.userProfile?.generalProfile?.birthdate
          ),
          socialSecurityNumber:
            applicationProcessData?.userProfile?.generalProfile?.socialSecurityNumber?.slice(
              2
            ),
          address: applicationProcessData?.userProfile?.generalProfile?.address,
          state: applicationProcessData?.userProfile?.generalProfile?.state,
          city: applicationProcessData?.userProfile?.generalProfile?.city,
          zipcode: applicationProcessData?.userProfile?.generalProfile?.zipcode,
        });
      }
    }
  }, [applicationProcessData]);

  return (
    <GeneralProfileWrap>
      <h1>Great let’s start working on your profile</h1>
      <FormWrap mt="0">
        <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
          <StyledForms>
            <Form
              layout="vertical"
              // requiredMark={"optional"}
              form={form}
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="firstLegalName"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="MiddleLegalName"
                  label="Middle Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="lastLegalName"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="49" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="birthdate"
                  label="Birthdate"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  {/* <Input /> */}
                  <DatePicker
                    placeholder=""
                    format={monthFormat}
                    // picker="day"
                    disabledDate={(current) => {
                      return moment().add(-1, "days") <= current;
                    }}
                    allowClear={false}
                  />
                </Form.Item>
                <Form.Item
                  className="input__affix"
                  name="socialSecurityNumber"
                  label="Social Security Number"
                  //   rules={[
                  //     {
                  //       required: true,
                  //     },
                  //   ]}
                >
                  <Input
                    type="number"
                    prefix={`+1`}
                    onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
                  />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="100" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="address"
                  label="Address"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder=""
                    onChange={onChange}
                    suffixIcon={<IoIosArrowDown />}
                  >
                    {states.length &&
                      states.map((state) => (
                        <Option value={state}>{state}</Option>
                      ))}
                    {/* <Option value="3BHK">Texas</Option>
                        <Option value="4BHK">4BHk</Option> */}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder=""
                    // onChange={}
                    suffixIcon={<IoIosArrowDown />}
                  >
                    {citesValue
                      ? citesValue.map((cites) => (
                          <Option value={cites}>{cites}</Option>
                        ))
                      : ""}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="zipcode"
                  label="Zip code"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input type="number" pattern="[0-9]*" />
                </Form.Item>
              </StyledFormItem>
              <Form.Item>
                <Row
                  justify="space-between"
                  style={{ alignItems: "center", marginTop: "20px" }}
                >
                  <Col
                    xs={10}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="back__btn"
                  >
                    <IoIosArrowBack
                      onClick={() => onBackClickTabs("general-profile")}
                    />
                    <h2 onClick={() => onBackClickTabs("general-profile")}>
                      back
                    </h2>
                  </Col>
                  <Col xs={14} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Row justify="end" style={{ alignItems: "center" }}>
                      <Col>
                        <p className="cancel__btn">Cancel</p>
                      </Col>
                      <Col xs={10} sm={8} md={8} lg={8} xl={6} xxl={6}>
                        <StyledButton
                          mt="0"
                          loading={isSubmit}
                          htmlType="submit"
                        >
                          next
                        </StyledButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </StyledForms>
        </StyledForm>
      </FormWrap>
    </GeneralProfileWrap>
  );
}

export default GeneralProfile;

export const GeneralProfileWrap = styled.div`
  .back__btn h2,
  svg {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #777777;
    margin-left: 10px;
    cursor: pointer;
  }
  h1 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: ${({ mb }) => mb || "30px"};
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #4160ac;
  }
  .ant-radio-inner::after {
    background-color: #4160ac;
  }

  .ant-radio-inner {
    background-color: #eeeeee;
  }

  @media (max-width: 769px) {
    h1 {
      display: ${({ display }) => display || "visible"};
      margin-bottom: ${({ mb }) => mb || "20px"};
    }
  }
`;

export const FormWrap = styled.div`
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop || "0"};

  .position__bottom {
    right: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
  }
  .cancel__btn {
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #848687;
    margin-bottom: 0;
    margin-right: 30px;
    margin-top: ${({ mt }) => mt || "10px"};
  }
  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #848687;
  }
`;
